import { Suspense } from 'react';

import {
  Html,
  OrbitControls,
  PresentationControls,
  Stage,
  useProgress,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Spin } from 'antd';

interface SceneProps {
  model: JSX.Element;
}

export const Loader = () => {
  const { active } = useProgress();
  return active ? (
    <Html center>
      <div>
        <Spin />
        Loading Scene...
      </div>
    </Html>
  ) : null;
};

const ThreeScene = ({ model }: SceneProps) => {
  return (
    <Canvas>
      <PresentationControls>
        <Suspense fallback={<Loader />}>
          <Stage shadows={false} intensity={0} adjustCamera={false}>
            <ambientLight intensity={1} />
            {model}
          </Stage>
          {/* {model} */}
        </Suspense>
      </PresentationControls>
      <OrbitControls makeDefault />
    </Canvas>
  );
};

export default ThreeScene;
