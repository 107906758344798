import { FC, ReactNode, useCallback, useMemo, memo } from 'react';

import './ViewWrapper.less';
import { Space, Tooltip, Spin, Typography } from 'antd';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BackArrowIcon } from '../../../assets/svgs/back-arrow-icon.svg';
import { propsAreEqual } from '../../../util';

const { Title } = Typography;

interface ViewWrapperOptions {
  goBack?: boolean | string;
  padded?: boolean;
  maxWidth?: boolean;
  loading?: boolean;
}

interface ViewWrapperProps {
  headerTitle?: string | ReactNode;
  children: ReactNode;
  headerSectionRight?: ReactNode;
  headerExtra?: ReactNode;
  options?: ViewWrapperOptions;
}

const ViewWrapper: FC<ViewWrapperProps> = (props) => {
  const { headerTitle, headerSectionRight, children, headerExtra, options } =
    props;
  const {
    padded = true,
    loading = false,
    goBack = false,
    maxWidth = true,
  } = options!;

  const history = useHistory();

  const viewWrapperClass = cx(
    'view-wrapper',
    { padded: padded },
    { loading: loading }
  );

  const viewWrapperMainClass = cx('view-wrapper-main', {
    'max-width': maxWidth,
  });

  const onGoBack = useCallback(() => {
    if (typeof goBack === 'string') {
      return history.push(goBack);
    }
    history.goBack();
  }, [goBack, history]);

  const hasHeader = useMemo(
    () => !!headerTitle || !!headerSectionRight || !!headerExtra,
    [headerExtra, headerSectionRight, headerTitle]
  );

  return loading ? (
    <div className={viewWrapperClass}>
      <Spin />
    </div>
  ) : (
    <div className={viewWrapperClass}>
      <div className={viewWrapperMainClass}>
        {hasHeader && (
          <header className="view-wrapper__header">
            <div className="view-wrapper__header--section-top">
              <div className="view-wrapper__header--section-left">
                <Space direction="horizontal" size="middle" align="center">
                  {goBack && (
                    <Tooltip title="Go back" mouseEnterDelay={0.6}>
                      <BackArrowIcon
                        style={{
                          fontSize: '18px',
                          marginTop: '8px',
                          cursor: 'pointer',
                        }}
                        onClick={onGoBack}
                      />
                    </Tooltip>
                  )}
                  {typeof headerTitle === 'string' ? (
                    <Title level={3} data-cy="view-header-title">
                      {headerTitle}
                    </Title>
                  ) : (
                    headerTitle
                  )}
                </Space>
              </div>
              <div className="view-wrapper__header--section-right">
                {headerSectionRight}
              </div>
            </div>
            {!!headerExtra && (
              <div className="view-wrapper__header--section-bottom">
                {headerExtra}
              </div>
            )}
          </header>
        )}

        <main className="view-wrapper__content">{children}</main>
      </div>
    </div>
  );
};

ViewWrapper.defaultProps = {
  options: {
    goBack: false,
    padded: true,
    loading: false,
  },
  headerSectionRight: undefined,
  headerExtra: undefined,
  headerTitle: '',
};

export default memo(ViewWrapper, propsAreEqual);
