import { PermissionModel } from '../permission';

export interface RoleModel {
  id: string;
  name: string;
  display_name: string;
  description: string;
  updated_at: string;
  permissions: PermissionModel[];
}

export const initialNewRoleModel = {
  display_name: '',
  description: '',
  permissions: [],
};
