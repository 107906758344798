import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { BackTop, Badge, Empty, Input, Space, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import { ListUsersParams } from '../../../hooks/user';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  fetchUsers,
  loadMoreUsers,
  searchUsersByName,
  setUserSearchQuery,
} from '../../../store/features/users/usersSlice';
import { getMePermissions, UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import UserTableList from './components/UserTableList';
import './UsersView.less';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const UsersView = () => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const {
    value: users,
    fetchingUsers,
    searchingUsers,
    totalCount,
    pagination,
    allUsers,
  } = useAppSelector((state) => state.users);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingUsers && !!searchQuery && users.length === 0,
    [fetchingUsers, searchQuery, users.length]
  );

  const hasUsers = !(!fetchingUsers && allUsers.length === 0);

  const hasMoreUsers =
    hasUsers && pagination?.offset! + pagination?.offset! < totalCount!;

  const isPermittedToLoadMore =
    !fetchingUsers && !searchingUsers && !searchQuery && hasMoreUsers;

  const listUsersParams = useMemo(
    (): ListUsersParams => ({
      _limit: 2000,
      _order_by: 'updated_at:desc',
      _columns: 'roles.display_name,roles.name,roles.id|asArray',
      _offset: 0,
    }),
    []
  );

  const createNewUserButton = useMemo(
    (): JSX.Element => (
      <Link to={DrawerHashRoute.UserForm}>
        <Button type="primary" data-cy="create-new-user-btn">
          Create New User
          <UserAddOutlined />
        </Button>
      </Link>
    ),
    []
  );

  const updateSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
      dispatch(setUserSearchQuery(query));
    },
    [dispatch]
  );

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingUsers}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a user..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={searchingUsers && <Spin indicator={antSpinIcon} />}
        />
        {currentUserPermissions.includes(UserPermissions.UsersCreate)
          ? createNewUserButton
          : null}
      </Space>
    ),
    [
      createNewUserButton,
      searchQuery,
      searchingUsers,
      currentUserPermissions,
      updateSearchQuery,
    ]
  );

  useEffect(() => {
    dispatch(fetchUsers({ params: listUsersParams }));
  }, [dispatch, listUsersParams]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(searchUsersByName(debouncedSearchQuery));
    }
  }, [debouncedSearchQuery, dispatch]);

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Users</Title>
          <Badge
            size="default"
            count={totalCount as number}
            className="total-count-badge"
          />
        </Space>
      }
      headerSectionRight={headerSectionRight}
    >
      {hasUsers ? (
        <UserTableList
          loading={fetchingUsers}
          users={users}
          onLoadMore={() => dispatch(loadMoreUsers())}
          hasMore={isPermittedToLoadMore}
        />
      ) : hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Empty
          description="Currently no users."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {createNewUserButton}
        </Empty>
      )}

      <BackTop />
    </ViewWrapper>
  );
};

export default UsersView;
