import { BASE_API_URL } from '../../config';
import { ListApiResponse, del, get, patch, post, postFile } from '../base';
import {
  BulkDeleteRequest,
  BulkEditRquest,
  CancelModelDownloadCronJobResponse,
  CreateModelDownloadCronJobRequest,
  CreateModelDownloadCronJobResponse,
  ModelDownloadCronJobsResponse,
  ProductLibraryResponse,
  ReplaceResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from './reqres';

export default function ProductLibraryService() {
  return Object.freeze({
    listProductLibrary(query?: string): Promise<ProductLibraryResponse> {
      return get(
        `${BASE_API_URL()}/library/${query}`
      ) as Promise<ListApiResponse>;
    },

    searchProductLibrary(query: string): Promise<ProductLibraryResponse> {
      return get(
        `${BASE_API_URL()}/items/search${query}`
      ) as Promise<ListApiResponse>;
    },

    bulkEdit(req: BulkEditRquest): Promise<ProductLibraryResponse> {
      return post(
        `${BASE_API_URL()}/library/bulk/edit`,
        req
      ) as Promise<ListApiResponse>;
    },

    bulkDelete(data: BulkDeleteRequest): Promise<ReplaceResponse> {
      return post(`${BASE_API_URL()}/library/bulk/delete`, data);
    },

    createModelDownloadCronJob({
      fileType,
      req,
    }: CreateModelDownloadCronJobRequest): Promise<CreateModelDownloadCronJobResponse> {
      return post(
        `${BASE_API_URL()}/library/product/download/${fileType}`,
        req
      ) as Promise<ListApiResponse>;
    },

    cancelModelDownloadCronJob(
      jobId: string
    ): Promise<CancelModelDownloadCronJobResponse> {
      return del(
        `${BASE_API_URL()}/job/model_dowload/${jobId}`
      ) as Promise<CancelModelDownloadCronJobResponse>;
    },

    listModelDownloadCronJobs(): Promise<ModelDownloadCronJobsResponse> {
      return get(
        `${BASE_API_URL()}/job/model_download/`
      ) as Promise<ListApiResponse>;
    },

    updateProduct(
      productId: string,
      req: UpdateProductRequest | {}
    ): Promise<UpdateProductResponse> {
      return patch(`${BASE_API_URL()}/library/${productId}`, req);
    },

    checkPOG(fileData: FormData): Promise<any[]> {
      return postFile(`${BASE_API_URL()}/library/pog-checker`, fileData);
    },
  });
}
