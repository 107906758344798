import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import OvrFileService, { CreateOvrFileRequest } from '../../../api/ovrFile';
import { OvrFileModel } from '../../../models/ovr-file';
import { convertParamsToQuery } from '../../../util';
export interface OvrFileState {
  ovrFiles: OvrFileModel[];
  failedOvrFiles: OvrFileModel[];
  processingOvrFiles: OvrFileModel[];
  pagination: PaginationModel | null;
  ovrFileSearchQuery: string;
  totalCount: number;
  failedOvrFilesTotalCount: number;
  fetchingFailedOvrFiles: boolean;
  processingOvrFilesTotalCount: number;
  fetchingProcessingOvrFiles: boolean;
  loading: boolean;
  fetchingOvrFiles: boolean;
  searchingOvrFiles: boolean;
  error: { code: number; details?: string; message: string } | null;
  count: number;
  current: number;
}

export interface ListOvrFilesParams {
  _limit?: number;
  _offset?: number;
  name?: string;
  _columns?: string;
  _order_by?: string;
  status?: string;
}

interface UploadFileToS3Props {
  signedUrl: string;
  file: any;
  source: any;
}

const fetchOvrFilesParams: ListOvrFilesParams = {
  _limit: 20,
  _offset: 0,
  _columns: 'createdByUser.*',
  _order_by: 'created_at:desc',
};

const initialState: OvrFileState = {
  ovrFiles: [],
  failedOvrFiles: [],
  processingOvrFiles: [],
  pagination: null,
  ovrFileSearchQuery: '',
  failedOvrFilesTotalCount: 0,
  fetchingFailedOvrFiles: false,
  processingOvrFilesTotalCount: 0,
  fetchingProcessingOvrFiles: false,
  totalCount: 0,
  loading: false,
  fetchingOvrFiles: false,
  searchingOvrFiles: false,
  error: null,
  count: 0,
  current: 1,
};

export const getRSOVRSignedUploadUrl = createAsyncThunk(
  'ovrFiles/getRSOVRSignedUploadUrl',
  async ({ fileName }: { fileName: string }) => {
    const resp = await OvrFileService().getRSOVRSignedUploadUrl(fileName);
    return resp.data;
  }
);

export const uploadFileToS3 = createAsyncThunk(
  'ovrFiles/uploadToS3',
  async (
    { signedUrl, file, source }: UploadFileToS3Props,
    { rejectWithValue }
  ) => {
    try {
      await axios.put(signedUrl, file, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        cancelToken: source.token,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveRsgFile = createAsyncThunk(
  'ovrFiles/saveRsgFile',
  async (
    {
      filesReadyForQueue,
      isNewRSGFile,
      fileId,
    }: {
      filesReadyForQueue: CreateOvrFileRequest[];
      isNewRSGFile: boolean;
      fileId?: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (isNewRSGFile) {
        const requests = filesReadyForQueue.map(
          (payload: CreateOvrFileRequest) =>
            OvrFileService().createOvrFile(payload)
        );
        await Promise.all(requests);
      } else {
        const payload = {
          id: fileId as string,
          ...filesReadyForQueue[0],
          progress: filesReadyForQueue[0].progress ?? 0,
        };
        await OvrFileService().updateOvrFile(payload.id, payload);
      }
      await dispatch(fetchProcessingOvrFiles());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRsgFile = createAsyncThunk(
  'ovrFiles/createRsgFile',
  async (
    { rsgFile }: { rsgFile: CreateOvrFileRequest },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await OvrFileService().createOvrFile(rsgFile);
      await dispatch(fetchProcessingOvrFiles());
      await dispatch(fetchFailedOvrFiles());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRsgFile = createAsyncThunk(
  'ovrFiles/updateRsgFile',
  async (
    { fileId, payload }: { fileId: string; payload: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await OvrFileService().updateOvrFile(fileId, payload);
      await dispatch(fetchProcessingOvrFiles());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOvrFile = createAsyncThunk(
  'ovrFiles/deleteOvrFile',
  async (ovrFile: OvrFileModel, { dispatch, rejectWithValue }) => {
    try {
      await OvrFileService().deleteOvrFile(ovrFile.uuid);
      await dispatch(fetchFailedOvrFiles());
      await dispatch(fetchProcessingOvrFiles());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateOvrFiles = createAsyncThunk(
  'ovrFiles/paginateOvrFiles',
  async (
    {
      page,
      pageSize,
      query = '',
      statuses = ['new', 'locked'],
    }: { page: number; pageSize: number; query?: string; statuses?: string[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const params = {
        _limit: pageSize,
        _offset: (page - 1) * pageSize,
        _columns: 'createdByUser.*',
        _order_by: 'created_at:desc',
        name: query && query.length ? `*${query.toLowerCase()}*` : undefined,
      };

      const results = [];

      if (statuses.includes('failed')) {
        results.push(dispatch(fetchFailedOvrFiles()));
      }

      if (statuses.includes('new') || statuses.includes('locked')) {
        results.push(dispatch(fetchProcessingOvrFiles()));
      }

      const resolvedResults = await Promise.all(results);

      const combinedData = resolvedResults.reduce(
        (acc: any, result) => {
          const { payload } = result;
          acc.data.push(...(payload as any).data);
          acc.pagination.count += (payload as any).pagination.count;
          acc.pagination.limit = Math.max(
            acc.pagination.limit,
            (payload as any).pagination.limit
          );
          return acc;
        },
        {
          data: [],
          pagination: {
            offset: params._offset,
            limit: params._limit,
            count: 0,
          },
        }
      );

      combinedData.loadMore =
        combinedData.pagination.count >
        combinedData.pagination.offset + combinedData.pagination.limit;

      return combinedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFailedOvrFiles = createAsyncThunk(
  'ovrFiles/fetchFailedOvrFiles',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await OvrFileService().listOvrFiles(
        convertParamsToQuery({
          ...fetchOvrFilesParams,
          status: 'failed',
        })
      );

      return { data: resp.data, pagination: resp.pagination, loadMore: false };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProcessingOvrFiles = createAsyncThunk(
  'ovrFiles/fetchProcessingOvrFiles',
  async (_, { rejectWithValue }) => {
    try {
      const [respNew, respLocked] = await Promise.all([
        OvrFileService().listOvrFiles(
          convertParamsToQuery({
            ...fetchOvrFilesParams,
            status: 'new',
          })
        ),
        OvrFileService().listOvrFiles(
          convertParamsToQuery({
            ...fetchOvrFilesParams,
            status: 'locked',
          })
        ),
      ]);

      const combinedData = [...respNew.data, ...respLocked.data];
      const combinedPagination = {
        offset: Math.min(
          respNew.pagination.offset,
          respLocked.pagination.offset
        ),
        limit: Math.max(respNew.pagination.limit, respLocked.pagination.limit),
        count: respNew.pagination.count + respLocked.pagination.count,
      };

      return {
        data: combinedData,
        pagination: combinedPagination,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchOvrFilesByName = createAsyncThunk(
  'ovrFiles/searchOvrFilesByName',
  async (query: string, { rejectWithValue }) => {
    try {
      const commonParams: ListOvrFilesParams = {
        _limit: 20,
        _offset: 0,
        _columns: 'createdByUser.*',
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        commonParams.name = `*${query.toLowerCase()}*`;
      }

      const searches = ['new', 'locked'].map((status) => {
        const params = { ...commonParams, status };
        const queryString = convertParamsToQuery(params);
        return OvrFileService().listOvrFiles(queryString);
      });

      const results = await Promise.all(searches);

      const combinedData: any = results.reduce(
        (acc: any, resp) => {
          acc.data.push(...resp.data);
          acc.pagination.count += resp.pagination.count;
          acc.pagination.limit = Math.max(
            acc.pagination.limit,
            resp.pagination.limit
          );
          return acc;
        },
        { data: [], pagination: { offset: 0, limit: 20, count: 0 } }
      );

      combinedData.loadMore =
        combinedData.pagination.count > combinedData.pagination.limit;

      return combinedData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ovrFilesSlice = createSlice({
  name: 'ovrFiles',
  initialState,
  reducers: {
    setOvrFileSearchQuery(state, { payload }: { payload: string }) {
      state.searchingOvrFiles = true;
      state.ovrFileSearchQuery = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFileToS3.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(uploadFileToS3.fulfilled, (state) => {
      state.loading = false;
    });
    builder
      .addCase(uploadFileToS3.rejected, (state, action) => {
        state.error = action.payload as any;
        state.loading = false;
      })

      // FETCHING PROCESSING OVR FILES
      .addCase(fetchProcessingOvrFiles.pending, (state) => {
        state.fetchingProcessingOvrFiles = true;
      })

      .addCase(fetchProcessingOvrFiles.fulfilled, (state, action) => {
        state.fetchingProcessingOvrFiles = false;
        state.processingOvrFiles = action.payload.loadMore
          ? [...state.processingOvrFiles, ...action.payload.data]
          : action.payload.data;
        state.processingOvrFilesTotalCount = action.payload.pagination.count;
      })

      .addCase(fetchProcessingOvrFiles.rejected, (state, action) => {
        state.fetchingProcessingOvrFiles = false;
        state.error = action.payload as any;
      })

      // FETCHING FAILED OVR FILES
      .addCase(fetchFailedOvrFiles.pending, (state) => {
        state.fetchingFailedOvrFiles = true;
      })

      .addCase(fetchFailedOvrFiles.fulfilled, (state, action) => {
        state.fetchingFailedOvrFiles = false;
        state.failedOvrFiles = action.payload.loadMore
          ? [...state.failedOvrFiles, ...action.payload.data]
          : action.payload.data;
        state.failedOvrFilesTotalCount = action.payload.pagination.count;
      })

      .addCase(fetchFailedOvrFiles.rejected, (state, action) => {
        state.fetchingFailedOvrFiles = false;
        state.error = action.payload as any;
      })

      .addCase(saveRsgFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveRsgFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveRsgFile.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.loading = false;
      })

      .addCase(createRsgFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRsgFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createRsgFile.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.loading = false;
      })

      .addCase(deleteOvrFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOvrFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteOvrFile.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.loading = false;
      })

      .addCase(getRSOVRSignedUploadUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRSOVRSignedUploadUrl.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getRSOVRSignedUploadUrl.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.loading = false;
      })

      .addCase(searchOvrFilesByName.pending, (state) => {
        state.searchingOvrFiles = true;
      })
      .addCase(searchOvrFilesByName.fulfilled, (state, action) => {
        state.searchingOvrFiles = false;
        state.totalCount = action.payload.pagination.count;
        state.ovrFiles = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchOvrFilesByName.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.searchingOvrFiles = false;
      })

      .addCase(paginateOvrFiles.pending, (state) => {
        state.fetchingOvrFiles = true;
      })
      .addCase(paginateOvrFiles.fulfilled, (state, action) => {
        state.fetchingOvrFiles = false;
        state.ovrFiles = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(paginateOvrFiles.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.fetchingOvrFiles = false;
      });
  },
});

export const { setOvrFileSearchQuery } = ovrFilesSlice.actions;

export default ovrFilesSlice.reducer;
