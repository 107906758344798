import { FC, memo } from 'react';

import './SidePanelFixtureItem.less';
import { propsAreEqual } from '../../../../../../../util';
import { FigureModel } from '../../../../figures';
interface SidePanelFixtureItemProps {
  item: FigureModel;
  size?: 'sm' | 'md' | 'lg';
}

const SidePanelFixtureItem: FC<SidePanelFixtureItemProps> = (props) => {
  const { item, size } = props;

  return (
    <div
      draggable
      id={`${item.id}`}
      className="fixture-item-wrapper draw2d_droppable"
    >
      <img
        className={`fixture-item-image ${size}`}
        src={item.renders.front}
        alt={item.name}
      />
      <div className="fixture-item-label">{item.name}</div>
    </div>
  );
};

SidePanelFixtureItem.defaultProps = {
  size: 'md',
};

export default memo(SidePanelFixtureItem, propsAreEqual);
