import { useCallback, useEffect, useMemo } from 'react';

import { Input, Tag, Typography } from 'antd';
import { History } from 'history';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '../../../store';
import Button from '../../elements/Button/Button';
import ViewWrapper from '../../elements/ViewWrapper';

import { useParams } from 'react-router-dom';
import {
  getApiLogs,
  loadMoreApiLogs,
} from '../../../store/features/api-log/apiLogsSlice';
import { fetchClients } from '../../../store/features/clients/clientsSlice';
import { getUser } from '../../../store/features/users/usersSlice';
import { isUserAuthorized, UserRoles } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import './UserDetailsView.less';
import ApiLogTableList from './components/ApiLogTableList/ApiLogTableList';

const { Text, Title } = Typography;

interface UserDetailsViewProps {
  history: History;
}

const UserDetailsView = ({ history }: UserDetailsViewProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { user, fetchingUser } = useAppSelector((state) => state.users);
  const { clients } = useAppSelector((state) => state.clients);

  const {
    value: apiLogs,
    fetchingApiLogs,
    pagination,
    totalCount,
  } = useAppSelector((state) => state.apiLogs);

  const hasUsers = !(!fetchingApiLogs && apiLogs.length === 0);
  const hasMoreUsers =
    hasUsers && pagination?.offset! + pagination?.offset! < totalCount!;
  const isPermittedToLoadMore = !fetchingApiLogs && hasMoreUsers;

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const getClientName = useCallback(
    (clientId: string): string => {
      const client = clients.find((c) => c.uuid === clientId);
      return client?.name || '';
    },
    [clients]
  );

  const renderClientName = (clientId?: string) => {
    if (!clientId) {
      return <Input disabled value="" />;
    }
    return <Input disabled value={getClientName(clientId)} />;
  };

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Button
        type="primary"
        onClick={() =>
          history.push({
            hash: DrawerHashRoute.UserForm,
            state: { data: user },
          })
        }
      >
        Edit User
      </Button>
    ),
    [history, user]
  );

  useEffect(() => {
    if (user?.uuid) {
      dispatch(getApiLogs(user?.uuid));
    }
  }, [dispatch, user?.uuid]);

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchClients({ query: '' }));
    }
  }, [dispatch, hasPermission]);

  return (
    <ViewWrapper
      options={{
        goBack: true,
        loading: fetchingUser,
      }}
      headerTitle={user?.name ? user?.name : 'User'}
      headerSectionRight={headerSectionRight}
    >
      <div className="card-container">
        <div className="card">
          <Title level={5}>{user?.name}</Title>
          <Text type="secondary" className="head">
            Username
          </Text>
          <Input disabled value={user?.name} />
          <Text type="secondary" className="head">
            Email
          </Text>
          <Input disabled value={user?.email} />
          <Text type="secondary" className="head">
            Client
          </Text>
          {renderClientName(user?.client_id || '')}
          <Text type="secondary" className="head">
            Role
          </Text>
          <div>
            {_.uniqBy(user?.roles, 'display_name').map(
              (p: { display_name: string }) => {
                if (!!p.display_name) {
                  return <Tag color="purple">{p.display_name}</Tag>;
                }
                return null;
              }
            )}
          </div>
        </div>
        <div className="card">
          <Title level={5}>Activity</Title>
          <ApiLogTableList
            totalCount={totalCount}
            loading={fetchingApiLogs}
            apiLogs={apiLogs}
            onLoadMore={() => dispatch(loadMoreApiLogs(user?.uuid))}
            hasMore={isPermittedToLoadMore}
          />
        </div>
      </div>
    </ViewWrapper>
  );
};

export default UserDetailsView;
