import React, { memo, useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  ConfigProvider,
  Modal,
  Space,
  Table,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { History } from 'history';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { ListProductLibraryModel } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { bulkDeleteModelsMasterList } from '../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import {
  ProductListState,
  addSelectedProductItemsToList,
  removeSelectedProductItemsFromList,
  searchProductsByName,
  setSelectedProducts,
} from '../../../../store/features/productList/productListSlice';
import {
  UploadState,
  bulkDeleteModels,
  removeProducts,
} from '../../../../store/features/uploadModel/uploadModel';
import { propsAreEqual } from '../../../../util';
import { DrawerHashRoute } from '../../Drawers/types';
import './ProductsTable.less';
import { customizeRenderEmpty } from './components/customizeRenderEmpty';

const { Title } = Typography;

interface ProductsTableProps {
  totalCount: number;
  // paginate: (page: number, pageSize: number) => void;
  paginationSetting: any;
  skeletonItemLength?: number;
  dataSource: ListProductLibraryModel[];
  loading: boolean;
  history: History;
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  disabledCheckbox: boolean;
  hasFooterDeleteButton?: boolean;
  hasFooterRemoveButton?: boolean;
  hasFooterAddButton?: boolean;
  hasFooterBulkEditButton?: boolean;
  hasFooterReplaceExistingButton?: boolean;
  replaceExistingProducts: (
    selectedProducts: ListProductLibraryModel[]
  ) => Promise<void>;
}

interface SelectedProduct {
  uuid: string;
  in_list: number | null;
}

type ProductTypeKeys =
  | 'Uploaded Products'
  | 'Replaced Products'
  | 'Existing Products'
  | 'Master Librarian List'
  | 'Recently Added';

type ProductTypeMapType = Record<ProductTypeKeys, keyof UploadState>;

const ProductsTable = ({
  totalCount,
  // paginate,
  paginationSetting,
  loading,
  dataSource,
  headerTitle,
  columns,
  hasFooterDeleteButton,
  hasFooterRemoveButton,
  hasFooterAddButton,
  hasFooterBulkEditButton,
  hasFooterReplaceExistingButton,
  noDataDescription,
  replaceExistingProducts,
}: ProductsTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const [selectionLoading, setSelectionLoading] = useState(false);
  const hasSelected = selectedProductRowKeys.length > 0;
  const dispatch = useAppDispatch();

  const { selectedProducts } = useAppSelector<ProductListState>(
    (state) => state.productList
  );

  const { processing } = useAppSelector((state) => state.uploadModel);
  const location = useLocation();

  const isProductListRoute = location.pathname === '/product-list';
  const isProductLibraryRoute = location.pathname === '/product-library';

  const showRemoveButton =
    hasFooterRemoveButton &&
    (isProductListRoute ||
      (isProductLibraryRoute &&
        selectedProducts.every((product) => product.in_list === 1)));

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, totalCount]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleRemoveSelectionFromProductList = (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    dispatch(removeSelectedProductItemsFromList(selectedProducts));
    dispatch(setSelectedProducts(selectedProducts));
  };

  const handleDeleteSelectedProducts = useCallback(
    async (productsToBeDeleted: any) => {
      const productTypeMap: Record<ProductTypeKeys, keyof UploadState> = {
        'Uploaded Products': 'uploadedProducts',
        'Replaced Products': 'replacedProducts',
        'Existing Products': 'existingProducts',
        'Master Librarian List': 'masterLibrarianList',
        'Recently Added': 'productList',
      };

      try {
        const uuidsOfProductsToDelete = productsToBeDeleted.map(
          (product: any) => product.uuid
        );

        const productTypeKey = productTypeMap[headerTitle as ProductTypeKeys];

        if (productTypeKey === 'masterLibrarianList') {
          await dispatch(bulkDeleteModelsMasterList(uuidsOfProductsToDelete));
        } else {
          await dispatch(bulkDeleteModels(uuidsOfProductsToDelete));
        }

        if (!productTypeKey) {
          throw new Error('Invalid header title');
        }

        await dispatch(
          removeProducts({
            uuids: uuidsOfProductsToDelete,
            productType: productTypeKey,
          })
        );
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        handleClearSelection();
      }
    },
    [headerTitle, dispatch, handleClearSelection]
  );

  const handleConfirmDeleteProduct = useCallback(
    (e: any, product: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the list or update it after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteSelectedProducts(product),
      });
    },
    [handleDeleteSelectedProducts]
  );

  const tableFooter = () => (
    <>
      {
        <div className="table-footer-wrapper">
          <div className="table-footer-left">
            <span>
              {hasSelected
                ? `${selectedProductRowKeys.length} Items selected: `
                : ''}
            </span>
            <Space size="middle">
              {hasFooterDeleteButton && (
                <Button
                  type="primary"
                  onClick={(e) =>
                    handleConfirmDeleteProduct(e, selectedProducts)
                  }
                  loading={processing}
                  style={{ minWidth: '200px' }}
                  icon={processing ? <LoadingOutlined /> : null}
                >
                  {processing ? 'Deleting...' : 'Delete Selected Product(s)'}
                </Button>
              )}

              {showRemoveButton && (
                <Button
                  type="primary"
                  onClick={() =>
                    handleRemoveSelectionFromProductList(selectedProducts)
                  }
                  loading={selectionLoading}
                >
                  Remove from List
                </Button>
              )}

              {hasFooterReplaceExistingButton && (
                <Button
                  type="primary"
                  onClick={() =>
                    handleReplaceExistingProducts(selectedProducts)
                  }
                  loading={selectionLoading}
                >
                  Replace Existing
                </Button>
              )}

              {hasFooterAddButton &&
                selectedProducts.every((product) => product.in_list === 0) && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleAddSelectionToProductList(selectedProducts)
                    }
                    loading={selectionLoading}
                  >
                    Add to Product List
                  </Button>
                )}

              {hasFooterBulkEditButton && (
                <Link to={DrawerHashRoute.BulkEditProductsForm}>
                  <Button type="primary" loading={selectionLoading}>
                    Bulk Edit
                  </Button>
                </Link>
              )}
            </Space>
          </div>
          <div className="table-footer-right">
            <Button
              type="link"
              onClick={handleClearSelection}
              className="link-button"
            >
              Clear Selection
            </Button>
          </div>
        </div>
      }
    </>
  );

  // const paginationSetting = {
  //   hideOnSinglePage: false,
  //   showSizeChanger: true,
  //   defaultPageSize: 200,
  //   pageSizeOptions: ['10', '20', '50', '100'],
  //   onChange: paginate,
  //   onShowSizeChange: paginate,
  //   showTotal: (total: number, range: number[]) =>
  //     `${range[0]}-${range[1]} of ${total} items`,
  //   style: {
  //     marginTop: '0px',
  //     padding: '2rem 1rem',
  //     background: 'white',
  //     borderRadius: '0 0 4px 4px',
  //   },
  //   total: totalCount,
  // };

  const handleAddSelectionToProductList = async (
    selectedProducts: SelectedProduct[]
  ) => {
    const productTypeMap: ProductTypeMapType = {
      'Uploaded Products': 'uploadedProducts',
      'Replaced Products': 'replacedProducts',
      'Existing Products': 'existingProducts',
      'Master Librarian List': 'masterLibrarianList',
      'Recently Added': 'productList',
    };

    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    await dispatch(addSelectedProductItemsToList(selectedProducts));
    await dispatch(searchProductsByName(''));
    const uuidsOfProductsToDelete = selectedProducts.map(
      (product: any) => product.uuid
    );

    // Get the product type key from the mapping based on the header title
    const productTypeKey = productTypeMap[headerTitle as ProductTypeKeys];

    if (!productTypeKey) {
      throw new Error('Invalid header title');
    } else if (productTypeKey === 'masterLibrarianList') {
      await dispatch(bulkDeleteModelsMasterList(uuidsOfProductsToDelete));
    } else {
      await dispatch(bulkDeleteModels(uuidsOfProductsToDelete));
    }

    dispatch(
      removeProducts({
        uuids: uuidsOfProductsToDelete,
        productType: productTypeKey,
      })
    );
  };

  const handleReplaceExistingProducts = (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    setSelectionLoading(true);
    setSelectedRowKeys([]);
    setSelectionLoading(false);
    replaceExistingProducts(selectedProducts);
  };

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const footerContent = hasSelected ? tableFooter : undefined;
  const paginationContent = dataSource.length > 0 ? paginationSetting : false;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{ y: 240 }}
          rowSelection={rowSelection}
          loading={loading && dataSource.length > 0}
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={paginationContent}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(memo(ProductsTable, propsAreEqual));
