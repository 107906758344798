import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import ApiLogService from '../../../api/api-log';
import { ApiError, PaginationModel, handleError } from '../../../api/base';
import { ApiLog } from '../../../models/api-log';
import { convertParamsToQuery } from '../../../util';

export interface ApiLogState {
  value: ApiLog[];
  fetchingApiLogs: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  totalCount: number;
}

const initialState: ApiLogState = {
  value: [],
  fetchingApiLogs: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
};

export const getApiLogs = createAsyncThunk(
  'apiLogs/getApiLogs',
  async (userId: string, { rejectWithValue }) => {
    try {
      const params = {
        user_id: userId,
      };

      const queryString = convertParamsToQuery(params);

      const resp = await ApiLogService().getApiLogs(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const paginateApiLogs = createAsyncThunk(
  'apiLogs/paginateApiLogs',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      const nextParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await ApiLogService().getApiLogs(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreApiLogs = createAsyncThunk(
  'apiLogs/loadMoreApiLogs',
  async (userId: string | undefined, { getState, rejectWithValue }) => {
    try {
      const {
        apiLogs: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        user_id: userId,
        _limit: limit,
        _offset: nextOffset,
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await ApiLogService().getApiLogs(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const apiLogsSlice = createSlice({
  name: 'apiLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApiLogs.pending, (state) => {
        state.fetchingApiLogs = true;
      })
      .addCase(getApiLogs.fulfilled, (state, action) => {
        state.fetchingApiLogs = false;
        if (action.payload) {
          state.totalCount = action.payload.pagination.count;
          state.value = action.payload.data;
          state.pagination = action.payload.pagination;
        }
      })
      .addCase(getApiLogs.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingApiLogs = false;
      });

    builder
      .addCase(loadMoreApiLogs.pending, (state) => {
        state.fetchingApiLogs = true;
      })
      .addCase(loadMoreApiLogs.fulfilled, (state, action: any) => {
        state.fetchingApiLogs = false;
        state.pagination = action.payload.pagination;
        state.value = [...state.value, ...action.payload.data];
      })
      .addCase(loadMoreApiLogs.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingApiLogs = false;
      });

    builder.addCase(paginateApiLogs.fulfilled, (state, action: any) => {
      state.fetchingApiLogs = false;
      if (action.payload) {
        state.pagination = action.payload.pagination;
        state.value = [...action.payload.data];
      }
    });
    builder.addCase(paginateApiLogs.pending, (state) => {
      state.fetchingApiLogs = true;
    });
    builder.addCase(paginateApiLogs.rejected, (state, action) => {
      if ((action.payload as unknown as ApiError).error) {
        state.error = (action.payload as unknown as ApiError).error;
        handleError(action.payload as unknown as ApiError);
      }
      handleError(action.error as ApiError);
      state.fetchingApiLogs = false;
    });
  },
});

export default apiLogsSlice.reducer;
