import axios from 'axios';

import { BASE_API_URL } from '../../config';
import { get, post } from '../base';
import { MediaUploadedResponse } from '../media/reqres';
import {
  BulkDeleteRequest,
  ExistItems,
  MediaUploadedRequest,
  ReUploadResponse,
  ReplaceResponse,
  SignedUploadUrlResponse,
  UploadResponse,
} from './reqres';

export default function UploadModelService() {
  return Object.freeze({
    async upload(formData: any, query?: string): Promise<UploadResponse> {
      const response = await axios.post(
        `${BASE_API_URL()}/library-model/upload${query}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${window.localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    },
    replace(data: ExistItems): Promise<ReplaceResponse> {
      return post(`${BASE_API_URL()}/library-model/replace`, data);
    },
    bulkDelete(data: BulkDeleteRequest): Promise<ReplaceResponse> {
      return post(`${BASE_API_URL()}/library/bulk/delete`, data);
    },
    async reUpload(formData: any): Promise<ReUploadResponse> {
      const response = await axios.post(
        `${BASE_API_URL()}/library-model/reupload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${window.localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    },
    getSignedUploadUrl(
      fileName: string,
      cellUUID: string,
      mediaVersionId: string
    ): Promise<SignedUploadUrlResponse> {
      return get(
        `${BASE_API_URL()}/file-storage/upload-url/item?file=${fileName}&uuid=${cellUUID}${
          mediaVersionId ? `&media_version_id=${mediaVersionId}` : ''
        }`
      );
    },
    mediaUploaded(
      mediaData: MediaUploadedRequest
    ): Promise<MediaUploadedResponse> {
      return post(`${BASE_API_URL()}/media/item/uploaded`, mediaData);
    },
  });
}
