import { Space, Alert, Button } from 'antd';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  console.log('error', error);
  return (
    <div style={{ padding: '1rem' }}>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Alert
          message="Something went wrong:"
          description={error.message}
          type="error"
        />
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </Space>
    </div>
  );
};

export default ErrorFallback;
