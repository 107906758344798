import { useEffect, useRef } from 'react';

import { CustomEvents } from './types';

export const useEventListener = (
  eventName: string,
  handler: (event?: Event | CustomEvent<CustomEvents>) => void,
  element = window
) => {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener: EventListenerOrEventListenerObject = (
      event: Event | CustomEvent<CustomEvents>
    ) => {
      return savedHandler.current!(event);
    };

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
