import { FC, memo, useCallback, useMemo } from 'react';

import { Form, Input, message, Select, Space } from 'antd';
import { History, Location } from 'history';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  DATConfiguration,
  initalNewOVRProjectModel,
  OvrProjectModel,
} from '../../../../models/online-virtual-research';
import { RootState, useAppDispatch } from '../../../../store';
import {
  OvrProjectsState,
  saveOvrProject,
  searchOvrProjectsByName,
} from '../../../../store/features/ovrProjects/ovrProjectsSlice';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import { OVRFormLocationState } from './types';

const { TextArea } = Input;
const { Option } = Select;
interface OVRFormProps {
  history: History;
  location: Location<OVRFormLocationState>;
}

const OVRForm: FC<OVRFormProps> = (props) => {
  const { history, location } = props;

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { fetchingOvrProjects } = useSelector<RootState, OvrProjectsState>(
    (state) => state.ovrProjects
  );

  const isNewProject = useMemo(() => !location.state, [location.state]);
  const fields = useMemo(
    () =>
      Object.keys(
        isNewProject ? initalNewOVRProjectModel : location.state?.data
      ),
    [isNewProject, location.state?.data]
  );

  const onSaveProject = useCallback(
    async (project: OvrProjectModel) => {
      await dispatch(
        saveOvrProject({
          project,
          isNewProject,
          projectId: location.state?.data?.uuid!,
        })
      );

      if (!isNewProject) {
        message.success('Project saved.');
      } else {
        message.success('Project created.');
      }

      await dispatch(searchOvrProjectsByName(''));
      history.goBack();
    },
    [isNewProject, dispatch, location, history]
  );

  const onSubmit = useCallback(
    async (values: OvrProjectModel) => {
      await form.validateFields(fields);
      onSaveProject(values);
    },
    [fields, form, onSaveProject]
  );

  return (
    <FormWrapper
      title={`${isNewProject ? 'Create new' : 'Edit'} project`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewProject ? initalNewOVRProjectModel : location.state?.data
        }
        onFinish={onSubmit}
      >
        <Form.Item
          label="Project Name"
          name="name"
          rules={[{ required: true, message: 'Project name is required.' }]}
        >
          <Input data-cy="ovr-project-name-input" placeholder="Project name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={6} placeholder="Description" />
        </Form.Item>

        <Form.Item
          label="Dynamic Attention Tracking Requirement"
          name="dat_config"
        >
          <Select placeholder="Select requirement">
            <Option
              key={DATConfiguration.Required}
              value={DATConfiguration.Required}
            >
              Required
            </Option>
            <Option
              key={DATConfiguration.Disabled}
              value={DATConfiguration.Disabled}
            >
              Disabled
            </Option>
            <Option
              key={DATConfiguration.Optional}
              value={DATConfiguration.Optional}
            >
              Optional
            </Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={fetchingOvrProjects}
              data-cy="ovr-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {`${isNewProject ? 'Create' : 'Save'} Project`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(OVRForm, propsAreEqual));
