import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { FormatModel } from '../../../../../models/format';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  MetaDataState,
  paginateCustomFieldOptions,
  searchCustomFieldOptionsByName,
} from '../../../../../store/features/metaData/metaDataSlice';
import { getMePermissions, UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import { RoutePath } from '../../../AppRoot/types';
import './FormatsTab.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Text } = Typography;

const FormatsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    allCustomFieldOptions: allFormats,
    allCustomFieldOptionsValue: formats,
    fetchingCustomFieldOptions,
    searchingCustomFieldOptions,
    count,
    current,
  } = useAppSelector<MetaDataState>((state) => state.metaData);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingCustomFieldOptions && !!searchQuery && formats.length === 0,
    [fetchingCustomFieldOptions, searchQuery, formats.length]
  );

  const hasFormats = !(!fetchingCustomFieldOptions && allFormats.length === 0);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const columns: ColumnsType<FormatModel> = [
    {
      title: 'Format',
      dataIndex: 'name',
      width: '35%',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: '# in Processing',
      dataIndex: 'in_processing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.in_processing && b.in_processing) {
          return a.in_processing > b.in_processing ? 1 : -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: '# in Library',
      dataIndex: 'in_library',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.in_library && b.in_library) {
          return a.in_library > b.in_library ? 1 : -1;
        } else {
          return 0;
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.status && b.status) {
          return a.status > b.status ? 1 : -1;
        } else {
          return 0;
        }
      },
      render: (status: string) => {
        if (status === 'approved') {
          return <Text>{status}</Text>;
        } else {
          return <Text type="danger">Needs Approval</Text>;
        }
      },
    },
    {
      title: 'Actions',
      width: 100,
      render: (format: FormatModel) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(
              UserPermissions.CustomFieldsEdit
            ) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.FormatForm,
                    state: { data: format },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
            <Button
              className="link-button"
              type="link"
              onClick={() =>
                history.push({
                  state: { format },
                  pathname: RoutePath.ProductLibrary,
                })
              }
            >
              View in Library
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      searchCustomFieldOptionsByName({
        customFieldKey: 'format',
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateCustomFieldOptions({
          customFieldKey: 'format',
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current,
  };

  return (
    <div className="formats-tab-wrapper">
      <div className="search-bar">
        <div className="search-bar-left">
          <Search
            className="search-input"
            allowClear={!searchingCustomFieldOptions}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e.target.value)}
            enterButton
            placeholder="Search"
            suffix={
              searchingCustomFieldOptions && <Spin indicator={antSpinIcon} />
            }
          />
        </div>
        {currentUserPermissions.includes(UserPermissions.CustomFieldsCreate) ? (
          <Link to={DrawerHashRoute.FormatForm}>
            <Button type="primary">Add New Format</Button>
          </Link>
        ) : null}
      </div>
      {hasFormats && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          loading={searchingCustomFieldOptions}
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          dataSource={formats}
          pagination={paginationSetting}
        />
      )}
    </div>
  );
};

export default FormatsTab;
