import { memo, useCallback, useMemo, useState } from 'react';

import './CellGroup.less';

import {
  DeleteFilled,
  ExclamationCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Avatar, message, Modal, Tooltip, Typography } from 'antd';
import { ApiError, handleError } from '../../../../../api/base';
import { CellGroupModel } from '../../../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  deleteCellGroup,
  removeCellGroup,
  updateOvrProjectLocally,
} from '../../../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import { propsAreEqual } from '../../../../../util';
import ProjectCellsList from '../ProjectCellsList';

const { Title } = Typography;

interface CellGroupProps {
  cellGroup: CellGroupModel;
  cellGroupIndex: number;
}

const CellGroup = ({ cellGroup, cellGroupIndex }: CellGroupProps) => {
  const { projectCells, group_id } = cellGroup;
  const dispatch = useAppDispatch();
  const { ovrProject } = useAppSelector((state) => state.ovrProjectDetails);

  const [isDeletingGroup, setIsDeletingGroup] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const isSavedCellGroup = useMemo(() => {
    return cellGroup.projectCells.some((cell) => !!cell.uuid);
  }, [cellGroup.projectCells]);

  const onDeleteCellGroupSuccess = useCallback(() => {
    setIsDeletingGroup(false);
    message.success('Cell group deleted.');
    const nextCellGroups = ovrProject?.cellGroups.filter(
      (_, i) => i !== cellGroupIndex
    );
    dispatch(
      updateOvrProjectLocally({
        ...ovrProject!,
        cellGroups: nextCellGroups!,
      })
    );
  }, [cellGroupIndex, dispatch, ovrProject]);

  const onDeleteCellGroupError = useCallback((err: ApiError) => {
    setIsDeletingGroup(false);
    handleError(err);
  }, []);

  const handleRemoveCellGroup = useCallback(async () => {
    if (!isSavedCellGroup) {
      dispatch(removeCellGroup({ cellGroupId: cellGroupIndex }));
      return;
    }

    setIsDeletingGroup(true);
    try {
      await dispatch(
        deleteCellGroup({
          projectId: ovrProject?.uuid!,
          cellGroupId: group_id,
        })
      ).unwrap();
      onDeleteCellGroupSuccess();
    } catch (error) {
      onDeleteCellGroupError(error as ApiError);
    }

    return;
  }, [
    isSavedCellGroup,
    dispatch,
    cellGroupIndex,
    ovrProject?.uuid,
    group_id,
    onDeleteCellGroupSuccess,
    onDeleteCellGroupError,
  ]);

  const handleConfirmRemoveCellGroup = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete this cell group?`,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: 'Delete Cell Group',
        onOk: () => handleRemoveCellGroup(),
      });
    },
    [handleRemoveCellGroup]
  );

  return (
    <div className="cell-group-container">
      <div className="cell-group-header">
        <div className="cell-group-header-left">
          <Avatar size={54}>
            {cellGroupIndex !== 0 ? cellGroupIndex : cellGroup.group_id}
          </Avatar>
          <div>
            <Title level={5}>{`Group ${
              cellGroupIndex !== 0 ? cellGroupIndex : cellGroup.group_id
            }`}</Title>
            <p>Distributes respondents amongst cell(s) equally.</p>
          </div>
        </div>
        <div className="cell-group-header-right">
          <Tooltip title="Delete group">
            <DeleteFilled
              disabled={isDeletingGroup}
              style={{ fontSize: '24px', color: '#becada' }}
              onClick={(event: any) => handleConfirmRemoveCellGroup(event)}
            />
          </Tooltip>
          <Tooltip title={`${isExpanded ? 'Collapse' : 'Expand'}`}>
            <UpOutlined
              style={{
                fontSize: '24px',
                color: '#becada',
                marginRight: '16px',
              }}
              onClick={() => setIsExpanded(!isExpanded)}
              className={`expand-icon ${isExpanded ? 'expanded' : ''}`}
            />
          </Tooltip>
        </div>
      </div>

      {isExpanded ? (
        <ProjectCellsList
          projectCells={projectCells}
          groupId={group_id}
          cellGroup={cellGroup}
        />
      ) : null}
    </div>
  );
};

export default memo(CellGroup, propsAreEqual);
