import { useCallback, useState } from 'react';

import { ApiError, handleError } from '../../api/base';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  brandsParams,
  fetchBrands,
} from '../../store/features/brand/brandSlice';

export const useFormData = () => {
  const [loading, setLoading] = useState(false);
  const {
    value: brands,
    fetchingBrands,
    pagination,
    totalCount,
  } = useAppSelector((state) => state.brand);

  const hasBrands = !fetchingBrands && brands.length > 0;

  const hasMoreBrands =
    hasBrands && pagination?.offset! + pagination?.offset! <= totalCount!;

  const dispatch = useAppDispatch();

  const fetchFormInputData = useCallback(async () => {
    setLoading(true);
    try {
      dispatch(fetchBrands(brandsParams));
    } catch (err) {
      handleError(err as ApiError);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return {
    loading,
    fetchFormInputData,
    hasMoreBrands,
  };
};
