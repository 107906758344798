import { createRef, FC, memo, MouseEvent, useCallback, useMemo } from 'react';

import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  message,
  Modal,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { History } from 'history';
import { withRouter } from 'react-router-dom';
import './SystemConfigurationTableList.less';

import { ApiError, handleError } from '../../../../../api/base';
import {
  SystemConfigurationModel,
  SystemConfigurationScope,
} from '../../../../../models/system-configuration';
import { useAppDispatch } from '../../../../../store';
import { deleteSystemConfiguration } from '../../../../../store/features/systemConfigurations/systemConfigurationsSlice';
import {
  capitalizeFirstWord,
  formatDate,
  getMePermissions,
  propsAreEqual,
  UserPermissions,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import LazyLoad from '../../../../elements/LazyLoad';

const { Text } = Typography;

interface SystemConfigurationTableListProps {
  systemConfigurations: SystemConfigurationModel[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  history: History;
}

const SystemConfigurationTableList: FC<SystemConfigurationTableListProps> = (
  props
) => {
  const { systemConfigurations, loading, onLoadMore, hasMore, history } = props;
  const dispatch = useAppDispatch();

  const tableListWrapperEl = createRef<any>();
  const observerIndexFromLastItem = 3;

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-system-configuration-message');
    handleError(err);
  }, []);

  const handleDeleteSystemConfiguration = useCallback(
    async (systemConfiguration: SystemConfigurationModel) => {
      message.loading(
        {
          content: 'Deleting system configuration...',
          key: 'deleting-system-configuration-message',
        },
        0
      );

      const res: any = await dispatch(
        deleteSystemConfiguration(systemConfiguration.id)
      );
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-system-configuration-message');
        message.success(
          `System configuration '${systemConfiguration.name}' deleted.`
        );
      }
    },

    [onError, dispatch]
  );

  const handleConfirmDeleteSystemConfiguration = useCallback(
    (e: MouseEvent, systemConfiguration: SystemConfigurationModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete system configuration '${systemConfiguration.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this system configuration.',
        okText: 'Delete',
        onOk: () => handleDeleteSystemConfiguration(systemConfiguration),
      });
    },
    [handleDeleteSystemConfiguration]
  );

  const getTypeColor = (type: string) => {
    switch (type) {
      case 'int':
        return 'red';
      case 'string':
        return 'orange';
      case 'float':
        return 'gold';
      case 'enum':
        return 'green';
      case 'multi':
        return 'blue';
      case 'bool':
        return 'purple';
    }
  };

  const columns: ColumnsType<SystemConfigurationModel> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, _, index) => {
          if (
            hasMore &&
            index + 1 ===
              systemConfigurations.length - observerIndexFromLastItem
          ) {
            return (
              <>
                <Text style={{ fontWeight: 'bold' }}>{name}</Text>

                <LazyLoad
                  root={tableListWrapperEl.current!}
                  onIntersection={onLoadMore}
                  triggerOnce
                  threshold={1.0}
                />
              </>
            );
          }
          return <Text style={{ fontWeight: 'bold' }}>{name}</Text>;
        },
      },
      {
        title: 'Value',
        dataIndex: 'value_string',
        key: 'value_string',
        render: (value: string) => <Text code>{value}</Text>,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type) => (
          <Tag color={getTypeColor(type)}>{capitalizeFirstWord(type)}</Tag>
        ),
      },
      {
        title: 'Scope',
        dataIndex: 'scope_string',
        key: 'scope_string',
        filters: [
          {
            text: 'Default',
            value: SystemConfigurationScope.Default,
          },
          {
            text: 'System',
            value: SystemConfigurationScope.System,
          },
          {
            text: 'User',
            value: SystemConfigurationScope.User,
          },
        ],
        render: (scope: string) => <Text code>{scope}</Text>,
        onFilter: (value, config) => config.scope === value,
      },
      {
        title: 'Last updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (systemConfiguration) => (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(
              UserPermissions.SystemConfigurationEdit
            ) ? (
              <Tooltip title="Edit">
                <EditFilled
                  onClick={() =>
                    history.push({
                      hash: DrawerHashRoute.SystemConfigurationForm,
                      state: { data: systemConfiguration },
                    })
                  }
                  className="system-configuration-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
            {currentUserPermissions.includes(
              UserPermissions.SystemConfigurationDelete
            ) ? (
              <Tooltip title="Delete">
                <DeleteFilled
                  onClick={(e) =>
                    handleConfirmDeleteSystemConfiguration(
                      e,
                      systemConfiguration
                    )
                  }
                  className="system-configuration-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
          </Space>
        ),
      },
    ],
    [
      hasMore,
      systemConfigurations.length,
      tableListWrapperEl,
      onLoadMore,
      history,
      handleConfirmDeleteSystemConfiguration,
      currentUserPermissions,
    ]
  );

  return (
    <div ref={tableListWrapperEl}>
      <Table
        loading={loading && systemConfigurations.length === 0}
        locale={{ emptyText: <></> }}
        rowKey={(systemConfiguration) => `${systemConfiguration.id}-row-key`}
        className="system-configurations-table-list"
        pagination={false}
        columns={columns}
        dataSource={systemConfigurations}
      />

      {loading && systemConfigurations.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default withRouter<any, any>(
  memo(SystemConfigurationTableList, propsAreEqual)
);
