import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, PaginationModel, handleError } from '../../../../api/base';
import RolesService from '../../../../api/roles';
import {
  CreateRoleRequest,
  UpdateRoleRequest,
} from '../../../../api/roles/reqres';
import { RoleModel } from '../../../../models/role';
import { ListModelParams } from '../../../../types';
import { convertParamsToQuery } from '../../../../util';
export interface RolesState {
  value: RoleModel[];
  allRoles: RoleModel[];
  pagination: PaginationModel | null;
  fetchingRoles: boolean;
  error: { code: number; details?: string; message: string } | null;
  searchingRoles: boolean;
  savingRole: boolean;
}

const initialState: RolesState = {
  value: [],
  allRoles: [],
  pagination: null,
  error: null,
  fetchingRoles: false,
  searchingRoles: false,
  savingRole: false,
};

const rolesParams: ListModelParams = {
  _limit: 20,
  _order_by: 'updated_at:desc',
  _offset: 0,
  _columns: 'permissions.*',
};

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
  const queryString = convertParamsToQuery(rolesParams);
  const resp = await RolesService().listRoles(queryString);
  return resp;
});

export const createRole = createAsyncThunk(
  'roles/createRole',
  async (role: CreateRoleRequest, { dispatch, rejectWithValue }) => {
    try {
      const resp = await RolesService().createRole(role);

      await dispatch(fetchRoles());

      return resp.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateRole = createAsyncThunk(
  'roles/updateRole',
  async (
    { roleId, role }: { roleId: string; role: UpdateRoleRequest },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await RolesService().updateRole(roleId, role);

      await dispatch(fetchRoles());

      return resp.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteRole = createAsyncThunk(
  'role/deleteRole',
  async (roleId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await RolesService().deleteRole(roleId);

      dispatch(fetchRoles());

      return resp.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const searchRolesByName = createAsyncThunk(
  'roles/searchRolesByName',
  async (query: string, { rejectWithValue }) => {
    try {
      let params: ListModelParams = {
        ...rolesParams,
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);
      return await RolesService().listRoles(queryString);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.fetchingRoles = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.fetchingRoles = false;
        state.value = action.payload.data;
        state.allRoles = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingRoles = false;
      });

    builder
      .addCase(searchRolesByName.pending, (state) => {
        state.searchingRoles = true;
      })
      .addCase(searchRolesByName.fulfilled, (state, action) => {
        state.searchingRoles = false;
        if (action.payload) {
          state.value = action.payload?.data;
          state.pagination = action.payload?.pagination;
        }
      })
      .addCase(searchRolesByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.searchingRoles = false;
      });

    builder
      .addCase(createRole.pending, (state) => {
        state.savingRole = true;
      })
      .addCase(createRole.fulfilled, (state) => {
        state.savingRole = false;
      })
      .addCase(createRole.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingRole = false;
      });

    builder
      .addCase(updateRole.pending, (state) => {
        state.savingRole = true;
      })
      .addCase(updateRole.fulfilled, (state) => {
        state.savingRole = false;
      })
      .addCase(updateRole.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingRole = false;
      });
  },
});
