import { useCallback, useState, FC } from 'react';

import './BuilderSidePanel.less';
import { SettingFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Space, Tooltip, Collapse } from 'antd';
import cx from 'classnames';

import BuilderSettings, { BuildSettings } from './components/BuilderSettings';
import PreviewFixtureProperties from './components/PreviewFixtureProperties';
import SidePanelFixtureItem from './components/SidePanelFixtureItem';
import { camelCaseToSentenceCase } from '../../../../../util';
import {
  FigureModel,
  fixtureByIdMap,
  staticFixtureByIdMap,
} from '../../figures';
import {
  Draw2DFigureModel,
  QueueLineModel,
  ShopperType,
  ShopperUserDataModel,
} from '../BuilderLayoutEditor/types';

const { Panel } = Collapse;
interface BuilderSidePanelProps {
  disableDragNDropFixtures: boolean;
  selectedFigures: Draw2DFigureModel[];
  settings: BuildSettings;
  onUpdateTextLabel: (
    field: 'bgColor' | 'fontSize' | 'bold' | 'fontColor',
    value: string | boolean
  ) => void;
  onUpdateMeasureTool: (field: 'width' | 'height', value: number) => void;
  onUpdateSetting: (field: keyof BuildSettings, value: boolean) => void;
  onUpdateShopper: (
    shopper: ShopperUserDataModel,
    action: 'updateShopperType' | 'removeShopper' | 'updateShopperElaspedTime',
    value?: ShopperType | string
  ) => void;
  onUpdateCounter: (counterId: keyof typeof staticFixtureByIdMap) => void;
  selectedShopperQueueLine?: QueueLineModel;
  overlappingShoppers: string[];
}

type FixtureSidePanelItems = { [key: string]: FigureModel[] };

const BuilderSidePanel: FC<BuilderSidePanelProps> = (props) => {
  const {
    selectedFigures,
    onUpdateTextLabel,
    onUpdateSetting,
    settings,
    onUpdateMeasureTool,
    disableDragNDropFixtures,
    selectedShopperQueueLine,
    onUpdateShopper,
    overlappingShoppers,
    onUpdateCounter,
  } = props;

  const [displaySettings, setDisplaySettings] = useState<boolean>(false);
  const [showProperties, setShowProperties] = useState<boolean>(true);
  const [showFixtures, setShowFixtures] = useState<boolean>(true);

  const toggleShowProperties = useCallback(() => {
    setShowProperties(!showProperties);
  }, [showProperties]);

  const toggleShowFixtures = useCallback(() => {
    setShowFixtures(!showFixtures);
  }, [showFixtures]);

  const fixtureSectionClass = cx(
    'fixtures-side-panel-section',
    { hide: !showFixtures },
    { 'full-height': showFixtures && !showProperties }
  );

  const propertiesSectionClass = cx(
    'properties-side-panel-section',
    { hide: !showProperties },
    { 'full-height': showProperties && !showFixtures }
  );

  const fixturesItemWrapperClass = (colLen?: number) =>
    cx(
      'fixture-items-wrapper',
      { 'two-column': colLen === 2 },
      { 'one-column': colLen === 1 },
      { 'disable-drag-n-drop': disableDragNDropFixtures }
    );

  const fixtureSidePanelItems = (): FixtureSidePanelItems => {
    let fixtures: FixtureSidePanelItems = {};
    Object.keys(fixtureByIdMap).forEach((key: any) => {
      const field = fixtureByIdMap[key].category;
      const prevFieldValue = fixtures[fixtureByIdMap[key].category]!
        ? fixtures[fixtureByIdMap[key].category]
        : [];
      const nextFieldValue = [...prevFieldValue, fixtureByIdMap[key]];
      fixtures = { ...fixtures, [field]: nextFieldValue };
    });
    return fixtures;
  };

  const getColumnsLength = (key: keyof FixtureSidePanelItems) => {
    switch (true) {
      case key === 'traditionalBeltCheckouts' ||
        key === 'queueLineMerchandisers':
        return 2;
      case key === 'walmartTraditionalBeltCheckouts':
      case key === 'walmartMisc':
      case key === 'walmartOpenAirCoolers':
      case key === 'queueWireRack':
      case key === 'queueCornerWireRack':
      case key === 'lowes':
        return 1;
      default:
        return undefined;
    }
  };

  return (
    <div className="builder-side-panel">
      <section className={fixtureSectionClass}>
        <div className="side-panel-header">
          <div className="side-panel-header__section-left">
            <h1>Fixtures</h1>
          </div>
          <div className="side-panel-header__section-right">
            <Space direction="horizontal" size="middle">
              <Tooltip title="Settings" mouseEnterDelay={0.5}>
                <SettingFilled
                  onClick={() => setDisplaySettings(true)}
                  className="side-panel-icon"
                />
              </Tooltip>
              {showFixtures ? (
                <Tooltip title="Hide" mouseEnterDelay={0.5}>
                  <MinusOutlined
                    onClick={toggleShowFixtures}
                    className="side-panel-icon"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Show" mouseEnterDelay={0.5}>
                  <PlusOutlined
                    onClick={toggleShowFixtures}
                    className="side-panel-icon"
                  />
                </Tooltip>
              )}
            </Space>
          </div>
        </div>
        <div className={`fixtures-wrapper ${showFixtures ? 'visible' : ''}`}>
          <Collapse ghost defaultActiveKey={['1']}>
            {Object.keys(fixtureSidePanelItems()).map((key, index) => {
              return (
                <Panel
                  style={{ textTransform: 'capitalize' }}
                  header={camelCaseToSentenceCase(key)}
                  key={index + 1}
                  forceRender
                >
                  <div
                    className={fixturesItemWrapperClass(getColumnsLength(key))}
                    data-cy="fixture-items"
                  >
                    {fixtureSidePanelItems()[
                      key as keyof FixtureSidePanelItems
                    ].map((item) => {
                      return (
                        <SidePanelFixtureItem
                          key={item.id}
                          item={item}
                          size="md"
                        />
                      );
                    })}
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </section>

      <section id="fixturePropertiesSection" className={propertiesSectionClass}>
        <div
          className={`side-panel-header ${showFixtures ? '' : 'no-border-top'}`}
        >
          <div className="side-panel-header__section-left">
            <h1>Properties</h1>
          </div>
          <div className="side-panel-header__section-right">
            {showProperties ? (
              <Tooltip title="Hide" mouseEnterDelay={0.5}>
                <MinusOutlined
                  onClick={toggleShowProperties}
                  className="side-panel-icon"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Show" mouseEnterDelay={0.5}>
                <PlusOutlined
                  onClick={toggleShowProperties}
                  className="side-panel-icon"
                />
              </Tooltip>
            )}
          </div>
        </div>
        {showProperties && (
          <div className="properties-wrapper">
            <PreviewFixtureProperties
              selectedShopperQueueLine={selectedShopperQueueLine!}
              onUpdateMeasureTool={onUpdateMeasureTool}
              selectedFigures={selectedFigures}
              expanded={showProperties && !showFixtures}
              onUpdateTextLabel={onUpdateTextLabel}
              onUpdateShopper={onUpdateShopper}
              overlappingShoppers={overlappingShoppers}
              onUpdateCounter={onUpdateCounter}
            />
          </div>
        )}
      </section>

      {displaySettings && (
        <BuilderSettings
          onUpdateSetting={onUpdateSetting}
          settings={settings}
          onClose={() => setDisplaySettings(false)}
        />
      )}
    </div>
  );
};

export default BuilderSidePanel;
