import { FC, useEffect, useCallback, useMemo } from 'react';

import { Button, DatePicker, Form, Input, message, Space } from 'antd';
import moment from 'moment';

import { ApiError, handleError } from '../../../../api/base';
import {
  initialNewLicenseModel,
  LicenseModel,
} from '../../../../models/license';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createClientLicense,
  updateClientLicense,
} from '../../../../store/features/clients/clientsSlice';
import { getMePermissions, UserPermissions } from '../../../../util';

const { RangePicker } = DatePicker;

import './LicenseForm.less';

interface LicenseFormProps {
  clientId: string;
  data?: LicenseModel;
  closeModal?: () => void;
  setLicenseData: (license: LicenseModel | null) => void;
}

export interface LicenseProps {
  name: string;
  uuid: string;
  date_range: moment.Moment[];
  start_date: string;
  end_date: string;
}

const LicenseForm: FC<LicenseFormProps> = ({
  clientId,
  data,
  closeModal,
  setLicenseData,
}) => {
  const dispatch = useAppDispatch();

  const { savingLicenses } = useAppSelector((state) => state.clients);

  const [form] = Form.useForm();
  const isNewLicense = useMemo(() => !data, [data]);

  const fields = useMemo(
    () => Object.keys(!data ? initialNewLicenseModel : data),
    [data]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onSuccess = useCallback(() => {
    message.success('License Saved.');
    closeModal?.();
    form.resetFields();
    setLicenseData(null);
  }, [closeModal, form, setLicenseData]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveLicense = useCallback(
    async (license: LicenseProps) => {
      try {
        const [start_date, end_date] = license.date_range;
        const newLicense: LicenseProps = {
          ...license,
          uuid: data ? data.uuid : '',
          start_date: moment(start_date).format('YYYY-MM-DD'),
          end_date: moment(end_date).format('YYYY-MM-DD'),
        };

        const action = isNewLicense
          ? createClientLicense({
              clientId,
              license: newLicense,
            })
          : updateClientLicense({
              clientId,
              licenseId: newLicense.uuid,
              license: newLicense,
            });

        await dispatch(action);
        onSuccess();
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [clientId, data, dispatch, isNewLicense, onError, onSuccess]
  );

  const onSubmit = useCallback(
    (values: LicenseProps) => {
      form.validateFields(fields).then(() => saveLicense(values));
    },
    [fields, form, saveLicense]
  );

  useEffect(() => {
    const getInitialValues = () => {
      if (isNewLicense) {
        return { ...initialNewLicenseModel };
      }

      return {
        ...data,
        date_range:
          data?.start_date && data?.end_date
            ? [moment(data?.start_date), moment(data?.end_date)]
            : [undefined, undefined],
      };
    };

    form.setFieldsValue(getInitialValues());
  }, [data, form, isNewLicense]);

  return (
    <Form
      key={data?.uuid || 'new-license-form'}
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Form.Item
        label="License Name (be descriptive)"
        name="name"
        rules={[{ required: true, message: 'License Name is required.' }]}
      >
        <Input data-cy="license-form-input" placeholder="License name" />
      </Form.Item>
      <Form.Item
        label="License Date Range"
        name="date_range"
        rules={[{ required: true, message: 'License Date Range is required.' }]}
      >
        <RangePicker data-cy="license-form-input" />
      </Form.Item>
      <Form.Item
        label="Number of Seats"
        name="quantity"
        rules={[{ required: true, message: 'Number of Seats is required.' }]}
      >
        <Input data-cy="license-form-input" placeholder="" />
      </Form.Item>
      <Form.Item>
        {isNewLicense ? (
          <Space style={{ float: 'right' }}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              data-cy="license-form-submit-btn"
              htmlType="submit"
              type="primary"
              loading={savingLicenses}
              disabled={savingLicenses}
            >
              Add
            </Button>
          </Space>
        ) : (
          <Space style={{ float: 'right' }}>
            {currentUserPermissions.includes(UserPermissions.ClientsEdit) ? (
              <Button
                htmlType="submit"
                type="primary"
                loading={savingLicenses}
                disabled={savingLicenses}
              >
                Save
              </Button>
            ) : null}
          </Space>
        )}
      </Form.Item>
    </Form>
  );
};

export default LicenseForm;
