import { CSSProperties, FC, useEffect, useRef } from 'react';
import './FixtureProperties.less';

import { Space } from 'antd';

import { camelCaseToSentenceCase } from '../../../../../../../../../util';
import { fixtureByIdMap } from '../../../../../../figures';
import { Draw2DFigureModel } from '../../../../../BuilderLayoutEditor/types';
interface FixturePropertiesProps {
  fixture: Draw2DFigureModel;
  expanded: boolean;
}

const FixtureProperties: FC<FixturePropertiesProps> = (props) => {
  const { fixture, expanded } = props;
  const fixtureImageWrapperEl = useRef<HTMLDivElement>(null);
  const fixtureImageWrapperStyle = useRef<CSSProperties>({});

  useEffect(() => {
    if (!fixtureImageWrapperEl.current) return;

    const bound = fixtureImageWrapperEl.current.getBoundingClientRect();

    fixtureImageWrapperStyle.current = {
      height: bound.height,
      width: bound.width,
      maxHeight: bound.height,
      maxWidth: bound.width,
    };
  }, []);

  const item =
    fixtureByIdMap[fixture.userData.fixtureId as keyof typeof fixtureByIdMap];

  return (
    <div
      className="fixture-item-properties-wrapper"
      data-cy="fixture-properties"
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div className="fixture-item-detail-wrapper">
          <div className="fixture-item-detail-property">Category</div>
          <div
            className="fixture-item-detail-value"
            style={{ textTransform: 'capitalize' }}
          >
            {camelCaseToSentenceCase(item.category)}
          </div>
        </div>
        <div className="fixture-item-detail-wrapper">
          <div className="fixture-item-detail-property">Name</div>
          <div className="fixture-item-detail-value">{item.name}</div>
        </div>
        <div className="fixture-item-detail-wrapper">
          <div className="fixture-item-detail-property">Size</div>
          <div className="fixture-item-detail-value">
            {item.w}" W x {item.h}" H x {item.d}" D
          </div>
        </div>
      </Space>
      <div
        className="fixture-display-image-wrapper"
        ref={fixtureImageWrapperEl}
        style={fixtureImageWrapperStyle.current}
        data-cy="fixture-image"
      >
        <img
          className={`fixture-display-image ${expanded ? 'lg' : ''}`}
          src={item.renders.front}
          alt={item.name}
        />
      </div>
    </div>
  );
};

export default FixtureProperties;
