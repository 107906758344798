import { FC, useState, useEffect, useRef } from 'react';

import './BuilderLayoutToolbar.less';
import { DragOutlined } from '@ant-design/icons';
import { Tooltip, Space } from 'antd';
import cx from 'classnames';

import basketShopperImage from '../../../../../assets/fixtures/BasketShopper/front.png';
import cartShopperImage from '../../../../../assets/fixtures/CartShopper/front.png';
import { ReactComponent as AddTextIcon } from '../../../../../assets/svgs/add-text-icon.svg';
import { ReactComponent as AlignItemsBottomIcon } from '../../../../../assets/svgs/align-items-bottom-icon.svg';
import { ReactComponent as AlignItemsCenterHorizontalIcon } from '../../../../../assets/svgs/align-items-center-horizontal-icon.svg';
import { ReactComponent as AlignItemsCenterVerticalIcon } from '../../../../../assets/svgs/align-items-center-vertical-icon.svg';
import { ReactComponent as AlignItemsLeftIcon } from '../../../../../assets/svgs/align-items-left-icon.svg';
import { ReactComponent as AlignItemsRightIcon } from '../../../../../assets/svgs/align-items-right-icon.svg';
import { ReactComponent as AlignItemsTopIcon } from '../../../../../assets/svgs/align-items-top-icon.svg';
import { ReactComponent as CameraIcon } from '../../../../../assets/svgs/camera-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/svgs/download-icon.svg';
import { ReactComponent as EllipsisIcon } from '../../../../../assets/svgs/ellipsis-icon.svg';
import { ReactComponent as GroupItemsIcon } from '../../../../../assets/svgs/group-items-icon.svg';
import { ReactComponent as QueueLineIcon } from '../../../../../assets/svgs/queue-line-icon.svg';
import { ReactComponent as RectangleIcon } from '../../../../../assets/svgs/rectangle-icon.svg';
import { ReactComponent as RotateItemIcon } from '../../../../../assets/svgs/rotate-item-icon.svg';
import { ReactComponent as TapeMeasureIcon } from '../../../../../assets/svgs/tape-measure-icon.svg';
import { ReactComponent as UngroupItemsIcon } from '../../../../../assets/svgs/ungroup-items-icon.svg';
import { ReactComponent as ZoomInIcon } from '../../../../../assets/svgs/zoom-in-icon.svg';
import { ReactComponent as ZoomOutIcon } from '../../../../../assets/svgs/zoom-out-icon.svg';
import { ReactComponent as ZoomToFitIcon } from '../../../../../assets/svgs/zoom-to-fit-icon.svg';
import { dragElement } from '../../../../../util/drag';
import Button from '../../../../elements/Button';
import { isGroupFigure, isMeasureTool } from '../../util';
import { Draw2DFigureModel, ShopperType } from '../BuilderLayoutEditor/types';
export interface BuilderToolbarActions {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomToFit: () => void;
  onAlignItemsTop: () => void;
  onAlignItemsLeft: () => void;
  onAlignItemsRight: () => void;
  onAlignItemsBottom: () => void;
  onAlignItemsCenterHorizontal: () => void;
  onAlignItemsCenterVertical: () => void;
  onRotateItem: () => void;
  onAddTextBox: () => void;
  onTakeScreenShot: () => void;
  onGroupItems: () => void;
  onUngroupItems: () => void;
  onAddMeasuringTool: (type: 'rectangle' | 'ellipsis') => void;
  onDownloadRSFFile: () => void;
  onToggleQueueLineMode: () => void;
  onSelectShopperType: (type: ShopperType) => void;
}

interface BuilderLayoutToolbarProps {
  actions: BuilderToolbarActions;
  selectedFigures: Draw2DFigureModel[];
  sidebarCollapsed: boolean;
  isInQueueLineMode: boolean;
  disableQueueLineMode: boolean;
  selectedShopperType: ShopperType;
  currentQueueLineColor?: string;
}

const BuilderLayoutToolbar: FC<BuilderLayoutToolbarProps> = (props) => {
  const {
    actions,
    selectedFigures,
    sidebarCollapsed,
    isInQueueLineMode,
    disableQueueLineMode,
    selectedShopperType,
    currentQueueLineColor,
  } = props;
  const {
    onZoomIn,
    onZoomOut,
    onZoomToFit,
    onAlignItemsTop,
    onAlignItemsLeft,
    onAlignItemsBottom,
    onAlignItemsRight,
    onAlignItemsCenterHorizontal,
    onAlignItemsCenterVertical,
    onRotateItem,
    onAddTextBox,
    onTakeScreenShot,
    onGroupItems,
    onUngroupItems,
    onAddMeasuringTool,
    onDownloadRSFFile,
    onToggleQueueLineMode,
    onSelectShopperType,
  } = actions;

  const [displayMeasureTools, setDisplayMeasureTools] =
    useState<boolean>(false);
  const toolbarWrapperEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!toolbarWrapperEl.current) return;

    dragElement(toolbarWrapperEl.current, {
      top: 120,
      left: sidebarCollapsed ? 80 : 250,
      right: window.innerWidth - 400 - 852,
      bottom: window.innerHeight - 44,
    });
  }, [sidebarCollapsed]);

  const toolbarActionIconClass = (disabled = false) =>
    cx('toolbar-action-icon', {
      disabled: disabled,
    });

  const builderLayoutToolbarWrapperClass = cx(
    'builder-layout-toolbar-wrapper',
    {
      'collapsed-sidebar': sidebarCollapsed,
    }
  );

  const hasMultipleItemsSelected =
    !!selectedFigures && selectedFigures.length > 1;
  const hasAtLeastSingleItemSelected =
    !!selectedFigures && selectedFigures.length >= 1;
  const hasGroupItemSelected =
    !!selectedFigures && selectedFigures.some((item) => isGroupFigure(item));
  const hasMeasuringToolSelected =
    !!selectedFigures &&
    (selectedFigures.some((item) => isMeasureTool(item)) ||
      (hasGroupItemSelected &&
        selectedFigures[0]
          .getAssignedFigures()
          .data.some((item: Draw2DFigureModel) => isMeasureTool(item))));

  const basketShopperStyle = {
    background:
      selectedShopperType === ShopperType.WomanWithBasket
        ? currentQueueLineColor
        : 'inherit',
  };

  const cartShopperStyle = {
    background:
      selectedShopperType === ShopperType.WomanWithCart
        ? currentQueueLineColor
        : 'inherit',
  };

  return (
    <div
      id="toolbarWrapper"
      className={builderLayoutToolbarWrapperClass}
      ref={toolbarWrapperEl}
    >
      <div className="builder-layout-toolbar">
        <div className="toolbar-actions-group">
          <Tooltip title="Zoom in">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onZoomIn}
            >
              <ZoomInIcon />
            </div>
          </Tooltip>
          <Tooltip title="Zoom to fit">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onZoomToFit}
            >
              <ZoomToFitIcon />
            </div>
          </Tooltip>
          <Tooltip title="Zoom out">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onZoomOut}
            >
              <ZoomOutIcon />
            </div>
          </Tooltip>
        </div>

        <div className="toolbar-actions-group">
          <Tooltip title="Align left">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsLeft}
            >
              <AlignItemsLeftIcon data-cy="align-left" />
            </div>
          </Tooltip>
          <Tooltip title="Align horizontal centers">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsCenterVertical}
            >
              <AlignItemsCenterVerticalIcon data-cy="align-horizontal" />
            </div>
          </Tooltip>
          <Tooltip title="Align right">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsRight}
            >
              <AlignItemsRightIcon data-cy="align-right" />
            </div>
          </Tooltip>
          <Tooltip title="Align top">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsTop}
            >
              <AlignItemsTopIcon data-cy="align-top" />
            </div>
          </Tooltip>
          <Tooltip title="Align vertical centers">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsCenterHorizontal}
            >
              <AlignItemsCenterHorizontalIcon data-cy="align-vertical" />
            </div>
          </Tooltip>
          <Tooltip title="Align bottom">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onAlignItemsBottom}
            >
              <AlignItemsBottomIcon data-cy="align-bottom" />
            </div>
          </Tooltip>
        </div>

        <div className="toolbar-actions-group">
          <Tooltip title="Rotate 90&#176;">
            <div
              className={toolbarActionIconClass(
                !hasAtLeastSingleItemSelected ||
                  isInQueueLineMode ||
                  hasMeasuringToolSelected
              )}
              onClick={onRotateItem}
            >
              <RotateItemIcon data-cy="rotate" />
            </div>
          </Tooltip>
        </div>

        <div className="toolbar-actions-group">
          <Tooltip title="Ungroup">
            <div
              className={toolbarActionIconClass(
                !hasGroupItemSelected || isInQueueLineMode
              )}
              onClick={onUngroupItems}
            >
              <UngroupItemsIcon data-cy="un-group" />
            </div>
          </Tooltip>
          <Tooltip title="Group">
            <div
              className={toolbarActionIconClass(
                !hasMultipleItemsSelected || isInQueueLineMode
              )}
              onClick={onGroupItems}
            >
              <GroupItemsIcon data-cy="group" />
            </div>
          </Tooltip>
        </div>

        <div className="toolbar-actions-group">
          <Tooltip title="Text">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onAddTextBox}
            >
              <AddTextIcon />
            </div>
          </Tooltip>
        </div>

        <div
          onMouseEnter={() => setDisplayMeasureTools(true)}
          onMouseLeave={() => setDisplayMeasureTools(false)}
        >
          <Space direction="vertical" align="center">
            <div className="toolbar-actions-group">
              <div className={toolbarActionIconClass(isInQueueLineMode)}>
                <TapeMeasureIcon />
              </div>
            </div>
            {displayMeasureTools ? (
              <>
                <div className="toolbar-actions-group hover-effect">
                  <Tooltip title="Rectangle" placement="right">
                    <div
                      className={toolbarActionIconClass(false)}
                      onClick={() => onAddMeasuringTool('rectangle')}
                    >
                      <RectangleIcon />
                    </div>
                  </Tooltip>
                </div>
                <div className="toolbar-actions-group hover-effect">
                  <Tooltip title="Ellipsis" placement="right">
                    <div
                      className={toolbarActionIconClass(false)}
                      onClick={() => onAddMeasuringTool('ellipsis')}
                    >
                      <EllipsisIcon />
                    </div>
                  </Tooltip>
                </div>
              </>
            ) : null}
          </Space>
        </div>

        {isInQueueLineMode ? (
          <Space direction="vertical" align="center">
            <Button
              onClick={onToggleQueueLineMode}
              type="link"
              style={{ background: currentQueueLineColor!, color: '#ffffff' }}
              data-cy="done-select-shoppers"
            >
              Done
            </Button>
            <div
              className="toolbar-actions-group no-margin"
              data-cy="basket-shopper"
            >
              <Tooltip
                placement="right"
                title="Woman with Basket shopper"
                style={{ whiteSpace: 'nowrap' }}
              >
                <div
                  className={toolbarActionIconClass(false)}
                  style={basketShopperStyle}
                  onClick={() =>
                    onSelectShopperType(ShopperType.WomanWithBasket)
                  }
                >
                  <img
                    src={basketShopperImage}
                    alt="woman with basket shopper"
                    height={32}
                  />
                </div>
              </Tooltip>
            </div>
            <div
              className="toolbar-actions-group no-margin"
              data-cy="cart-shopper"
            >
              <Tooltip placement="right" title="Woman With Cart shopper">
                <div
                  className={toolbarActionIconClass(false)}
                  style={cartShopperStyle}
                  onClick={() => onSelectShopperType(ShopperType.WomanWithCart)}
                >
                  <img
                    src={cartShopperImage}
                    alt="woman with cart shopper"
                    height={32}
                  />
                </div>
              </Tooltip>
            </div>
          </Space>
        ) : (
          <div className="toolbar-actions-group">
            <Tooltip title="Draw queue line shoppers">
              <div
                className={toolbarActionIconClass(disableQueueLineMode)}
                onClick={onToggleQueueLineMode}
              >
                <QueueLineIcon data-cy="draw-queue-line-shoppers" />
              </div>
            </Tooltip>
          </div>
        )}

        <div className="toolbar-actions-group">
          <Tooltip title="Take screenshot">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onTakeScreenShot}
            >
              <CameraIcon data-cy="take-screenshot" />
            </div>
          </Tooltip>
        </div>

        <div className="toolbar-actions-group">
          <Tooltip title="Download RSF">
            <div
              className={toolbarActionIconClass(isInQueueLineMode)}
              onClick={onDownloadRSFFile}
            >
              <DownloadIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div id="toolbarWrapperDrag" className="toolbar-drag">
        <DragOutlined />
      </div>
    </div>
  );
};

export default BuilderLayoutToolbar;
