import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, PaginationModel, handleError } from '../../../api/base';
import StoreBuildService, {
  CopyStoreBuildRequest,
} from '../../../api/store-build';
import { ListStoreBuildsParams } from '../../../hooks/store-build';
import { ListStoreBuildModel } from '../../../models/store-build';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

export interface StoreBuildsState {
  value: ListStoreBuildModel[];
  allStoreBuilds: ListStoreBuildModel[];
  currentStoreBuild: ListStoreBuildModel | null;
  fetchingStoreBuild: boolean;
  fetchingStoreBuilds: boolean;
  searchingStoreBuilds: boolean;
  savingStoreBuild: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  totalCount: number;
  count: number;
  current: number;
}

const initialState: StoreBuildsState = {
  value: [],
  allStoreBuilds: [],
  currentStoreBuild: null,
  fetchingStoreBuild: false,
  fetchingStoreBuilds: false,
  searchingStoreBuilds: false,
  savingStoreBuild: false,
  error: { code: null, message: null },
  totalCount: 0,
  count: 0,
  current: 1,
  pagination: null,
};

const storeBuildsParams: ListStoreBuildsParams = {
  _limit: 20,
  _order_by: 'updated_at:desc',
  _columns: 'createdByUser.*',
  _offset: 0,
  status: 'active',
};

export const fetchStoreBuild = createAsyncThunk(
  'storeBuilds/fetchStoreBuild',
  async (
    {
      storeBuildId,
      params,
    }: {
      storeBuildId: string;
      params?: ListStoreBuildsParams;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);

      const resp = await StoreBuildService().getStoreBuild(
        storeBuildId,
        queryString
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchStoreBuildsByName = createAsyncThunk(
  'storeBuilds/searchStoreBuildsByName',
  async (
    {
      query,
      params = storeBuildsParams,
    }: {
      query?: string;
      params?: ListStoreBuildsParams;
    },
    { rejectWithValue }
  ) => {
    try {
      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await StoreBuildService().listStoreBuilds(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingStoreBuildsMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreStoreBuilds = createAsyncThunk(
  'storeBuilds/loadMoreStoreBuilds',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        storeBuilds: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        _limit: limit,
        _order_by: 'updated_at:desc',
        _columns: 'createdByUser.*',
        _offset: nextOffset,
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await StoreBuildService().listStoreBuilds(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createStoreBuild = createAsyncThunk(
  'storeBuilds/createStoreBuild',
  async (storeBuild: ListStoreBuildModel, { rejectWithValue }) => {
    try {
      const resp = await StoreBuildService().createStoreBuild(storeBuild);

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStoreBuild = createAsyncThunk(
  'storeBuilds/updateStoreBuild',
  async (
    {
      storeBuildId,
      storeBuild,
    }: { storeBuildId: string; storeBuild: ListStoreBuildModel },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(storeBuildsParams);

      const resp = await StoreBuildService().updateStoreBuild(
        storeBuildId,
        storeBuild,
        queryString
      );

      await dispatch(searchStoreBuildsByName({ query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStoreBuild = createAsyncThunk(
  'storeBuilds/deleteStoreBuild',
  async (storeBuildId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await StoreBuildService().deleteStoreBuild(storeBuildId);

      await dispatch(searchStoreBuildsByName({ query: '' }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const duplicateStoreBuild = createAsyncThunk(
  'storeBuilds/duplicateStoreBuild',
  async (
    {
      storeBuildId,
      payload,
    }: { storeBuildId: string; payload: CopyStoreBuildRequest },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await StoreBuildService().copyStoreBuild(
        storeBuildId,
        payload
      );

      dispatch(
        searchStoreBuildsByName({
          query: '',
        })
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const storeBuildsSlice = createSlice({
  name: 'storeBuilds',
  initialState,
  reducers: {
    clearCurrentStoreBuild: (state) => {
      state.currentStoreBuild = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStoreBuild.fulfilled, (state, action: any) => {
      state.fetchingStoreBuild = false;
      state.currentStoreBuild = action.payload;
    });
    builder.addCase(fetchStoreBuild.pending, (state) => {
      state.fetchingStoreBuild = true;
    });
    builder.addCase(fetchStoreBuild.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.fetchingStoreBuild = false;
    });

    builder.addCase(deleteStoreBuild.fulfilled, (state) => {
      state.currentStoreBuild = null;
      state.savingStoreBuild = false;
    });
    builder.addCase(deleteStoreBuild.pending, (state) => {
      state.savingStoreBuild = true;
    });
    builder.addCase(deleteStoreBuild.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.savingStoreBuild = false;
    });

    builder
      .addCase(searchStoreBuildsByName.pending, (state) => {
        state.searchingStoreBuilds = true;
        state.fetchingStoreBuilds = true;
      })
      .addCase(searchStoreBuildsByName.fulfilled, (state, action) => {
        state.searchingStoreBuilds = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
        state.totalCount = action.payload.pagination.count;
        state.fetchingStoreBuilds = false;
        state.allStoreBuilds = action.payload.data;
      })
      .addCase(searchStoreBuildsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingStoreBuilds = false;
        state.searchingStoreBuilds = false;
      });

    builder.addCase(loadMoreStoreBuilds.fulfilled, (state, action: any) => {
      state.fetchingStoreBuilds = false;
      state.pagination = action.payload.pagination;
      state.value = [...state.value, ...action.payload.data];
      state.allStoreBuilds = [...state.value, ...action.payload.data];
    });
    builder.addCase(loadMoreStoreBuilds.pending, (state) => {
      state.fetchingStoreBuilds = true;
    });
    builder.addCase(loadMoreStoreBuilds.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.fetchingStoreBuilds = false;
    });

    builder.addCase(updateStoreBuild.fulfilled, (state) => {
      state.savingStoreBuild = false;
    });
    builder.addCase(updateStoreBuild.pending, (state) => {
      state.savingStoreBuild = true;
    });
    builder.addCase(updateStoreBuild.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.savingStoreBuild = false;
    });

    builder.addCase(createStoreBuild.fulfilled, (state, action: any) => {
      state.currentStoreBuild = action.payload;
      state.savingStoreBuild = false;
    });
    builder.addCase(createStoreBuild.pending, (state) => {
      state.savingStoreBuild = true;
    });
    builder.addCase(createStoreBuild.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.savingStoreBuild = false;
    });

    builder.addCase(duplicateStoreBuild.fulfilled, (state, action: any) => {
      state.currentStoreBuild = action.payload;
    });
    builder.addCase(duplicateStoreBuild.pending, (state) => {
      state.savingStoreBuild = true;
    });
    builder.addCase(duplicateStoreBuild.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.savingStoreBuild = false;
    });
  },
});

export const { clearCurrentStoreBuild } = storeBuildsSlice.actions;

export default storeBuildsSlice.reducer;
