export function convertParamsToQuery<T extends { [key: string]: any }>(
  obj?: T
): string {
  if (!obj) return '';

  const isObjectKeyValueDefined = (key: string) =>
    (!!obj[key] || obj[key] === 0) && obj[key] !== '';
  const objectKeyToObjectKeyValue = (key: string) => {
    if (Array.isArray(obj[key])) {
      return (obj[key] as string[])
        .map((param) => `${key}[]=${param}`)
        .join('&');
    }

    return key + '=' + obj[key];
  };

  // generate the query params
  let queryParam = Object.keys(obj)
    .filter(isObjectKeyValueDefined)
    .map(objectKeyToObjectKeyValue)
    .join('&');
  // adds a `&` if queryParam is not empty
  queryParam = queryParam && queryParam !== '' ? `${queryParam}&` : queryParam;
  // removes a trailing `&` if its left over
  queryParam =
    queryParam[queryParam.length - 1] === '&'
      ? queryParam.substring(0, queryParam.length - 1)
      : queryParam;

  return '?' + queryParam;
}

const colorToHex = (color?: number) => {
  const hexadecimal = color?.toString(16);
  return hexadecimal?.length === 1 ? '0' + hexadecimal : hexadecimal;
};

export const rgbToHex = (r?: number, g?: number, b?: number) => {
  return '#' + colorToHex(r) + colorToHex(g) + colorToHex(b);
};

export const convertDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const convertNewLinesToHtml = (message: string): string => {
  return message
    .split(/<br><br>/g) // Split on double <br> tags
    .map((para) => {
      const lines = para.split(/<br>/g); // Split on single <br> tags within each paragraph
      const formattedLines = lines.map((line) => `<p>${line.trim()}</p>`); // Convert each single <br> back to <p> tags
      return formattedLines.join('');
    })
    .join('<p><br></p>'); // Join paragraphs with a double line break (restoring <p><br></p>)
};
