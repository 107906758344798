import './Thumbnail.less';

interface ThumbnailProps {
  thumbnailSrc: string;
  setSelectedImage: (image: string) => void;
  setThreeDView: (status: boolean) => void;
}

const Thumbnail = ({
  thumbnailSrc,
  setSelectedImage,
  setThreeDView,
}: ThumbnailProps) => {
  const toggleActive = () => {
    setSelectedImage(thumbnailSrc);
    setThreeDView(false);
  };

  return (
    <a href="#" onClick={toggleActive} className="thumbnail-image active">
      <img src={thumbnailSrc} width="54px" height="74px" />
    </a>
  );
};

export default Thumbnail;
