import { useCallback, useEffect, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import './SelectClientDropdown.less';

import AuthService from '../../../../../api/auth';
import { ApiError, handleError } from '../../../../../api/base';
import UserService, { UserResponse } from '../../../../../api/user';
import { ClientModel, TagModel } from '../../../../../models/client';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchClients } from '../../../../../store/features/clients/clientsSlice';
import {
  UserRoles,
  convertParamsToQuery,
  getMe,
  isUserAuthorized,
} from '../../../../../util';

interface SelectClientDropdownProps {
  collapsed: boolean;
}

const SelectClientDropdown = ({ collapsed }: SelectClientDropdownProps) => {
  const dispatch = useAppDispatch();
  const { clients, fetchingClients, pagination } = useAppSelector(
    (state) => state.clients
  );

  const me = useMemo(() => getMe(), []);

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const onSuccess = useCallback((resp: UserResponse) => {
    AuthService().setMe(resp.data);
    window.location.reload();
  }, []);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const getClientName = (): string => {
    const client = clients.find((client) => client.uuid === me.client_id);
    const clientName = client ? client.name : 'No Client';

    return collapsed && clientName ? clientName[0] : clientName;
  };

  const handleUpdateClient = useCallback(
    (clientId: string) => {
      const updateUserQueryParams = convertParamsToQuery({
        _columns: 'permissions.key|asArray,roles.*',
      });

      UserService()
        .updateUser(me?.uuid, { client_id: clientId }, updateUserQueryParams)
        .then(onSuccess)
        .catch(onError);
    },
    [me?.uuid, onError, onSuccess]
  );

  const filterByClientTag = (array: ClientModel[]): ClientModel[] => {
    return array
      .filter((item: ClientModel) => {
        return (
          item.tags &&
          item.tags.some(
            (tag: TagModel) => tag.name === 'Client' && tag.set === 1
          )
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const filteredClients = filterByClientTag(clients);

  const handleScroll = useCallback(
    (e: any) => {
      const target = e.target as HTMLDivElement;
      if (
        !fetchingClients &&
        target.scrollTop + target.offsetHeight >= target.scrollHeight - 1 &&
        pagination
      ) {
        dispatch(
          fetchClients({ offset: pagination?.offset + pagination?.limit })
        );
      }
    },
    [pagination, dispatch, fetchingClients]
  );

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchClients({}));
    }
  }, [dispatch, hasPermission]);

  const menu = (
    <Menu
      className="select-client-menu"
      inlineCollapsed={false}
      style={{ maxHeight: '300px', overflowY: 'auto' }}
      onScroll={handleScroll}
    >
      {filteredClients.map((client: ClientModel) => (
        <div
          key={client.uuid}
          className="select-client-menu-item"
          onClick={() => handleUpdateClient(client.uuid)}
        >
          {client.name}
        </div>
      ))}
      {fetchingClients && (
        <div className="select-client-menu-item">
          <LoadingOutlined style={{ color: 'white' }} />
        </div>
      )}
    </Menu>
  );

  const clientDropdownClass = cx('select-client-dropdown', {
    collapsed: collapsed,
  });

  const clientDropdownOverlayClass = cx('client-dropdown-menu-overlay', {
    collapsed: collapsed,
  });

  return (
    <Dropdown
      overlayClassName={clientDropdownOverlayClass}
      placement="bottom"
      overlay={menu}
      trigger={['click']}
      overlayStyle={{ position: 'fixed' }}
    >
      <div className={clientDropdownClass}>
        <div className="client-name-text">{getClientName()}</div>
      </div>
    </Dropdown>
  );
};

export default SelectClientDropdown;
