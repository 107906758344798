import { MouseEvent, memo, useCallback, useMemo } from 'react';

import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Modal, Space, Table, Tag, Tooltip, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { History } from 'history';
import { withRouter } from 'react-router-dom';
import './CustomFieldTableList.less';

import { ApiError, handleError } from '../../../../../api/base';
import {
  CustomFieldModel,
  ProductCustomFieldValueType,
} from '../../../../../models/custom-field';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  deleteCustomField,
  paginateCustomFields,
} from '../../../../../store/features/customFields/customFieldsSlice';
import {
  UserPermissions,
  capitalizeFirstWord,
  formatDate,
  getMePermissions,
  propsAreEqual,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';

import './CustomFieldTableList.less';

const { Text } = Typography;

interface CustomFieldTableListProps {
  customFields: CustomFieldModel[];
  loading: boolean;
  history: History;
}

const CustomFieldTableList = ({
  customFields,
  loading,
  history,
}: CustomFieldTableListProps) => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector((state) => state.customFields);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-custom-field-message');
    handleError(err);
  }, []);

  const handleDeleteCustomField = useCallback(
    async (customField: CustomFieldModel) => {
      message.loading(
        {
          content: 'Deleting custom field...',
          key: 'deleting-custom-field-message',
        },
        0
      );

      const res: any = await dispatch(deleteCustomField(customField.id!));

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-custom-field-message');
        message.success(`Custom field '${customField.name}' deleted.`);
      }
    },
    [dispatch, onError]
  );

  const handleConfirmDeleteCustomField = useCallback(
    (e: MouseEvent, customField: CustomFieldModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete custom field '${customField.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this custom field.',
        okText: 'Delete',
        onOk: () => handleDeleteCustomField(customField),
      });
    },
    [handleDeleteCustomField]
  );

  const getValueTypeColor = (valueType: ProductCustomFieldValueType) => {
    switch (valueType) {
      case 'int':
        return 'red';
      case 'string':
        return 'orange';
      case 'float':
        return 'gold';
      case 'enum':
        return 'green';
      case 'multi':
        return 'blue';
      case 'bool':
        return 'purple';
    }
  };

  const columns: ColumnsType<CustomFieldModel> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, _) => {
          return <Text style={{ fontWeight: 'bold' }}>{name}</Text>;
        },
      },
      {
        title: 'Attachment',
        dataIndex: 'type',
        key: 'type',
        render: (type) => <Text code>{capitalizeFirstWord(type)}</Text>,
      },
      {
        title: 'Value Type',
        dataIndex: 'value_type',
        key: 'value_type',
        render: (valueType) => (
          <Tag color={getValueTypeColor(valueType)}>
            {capitalizeFirstWord(valueType)}
          </Tag>
        ),
      },
      {
        title: 'Field Key',
        dataIndex: 'field_key',
        key: 'field_key',
        render: (fieldKey) =>
          !!fieldKey && <Text code>{capitalizeFirstWord(fieldKey)}</Text>,
      },
      {
        title: 'Last updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a, b) =>
          new Date(a.updated_at!).getTime() - new Date(b.updated_at!).getTime(),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (customField) => (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(
              UserPermissions.CustomFieldsEdit
            ) ? (
              <Tooltip title="Edit">
                <EditFilled
                  onClick={() =>
                    history.push({
                      hash: DrawerHashRoute.CustomFieldForm,
                      state: { data: customField },
                    })
                  }
                  className="custom-field-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
            {currentUserPermissions.includes(
              UserPermissions.CustomFieldsDelete
            ) ? (
              <Tooltip title="Delete">
                <DeleteFilled
                  onClick={(e) =>
                    handleConfirmDeleteCustomField(e, customField)
                  }
                  className="custom-field-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
          </Space>
        ),
      },
    ],
    [history, currentUserPermissions, handleConfirmDeleteCustomField]
  );

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(paginateCustomFields({ page, pageSize })),
    [dispatch]
  );

  return (
    <>
      <Table
        loading={loading}
        locale={{ emptyText: <></> }}
        rowKey={(customField) => `${customField.id}-row-key`}
        className="custom-fields-table-list"
        columns={columns}
        sortDirections={['ascend', 'descend', 'ascend']}
        dataSource={customFields}
        pagination={{
          defaultPageSize: 20,
          total: totalCount,
          onChange: paginate,
        }}
      />
    </>
  );
};

export default withRouter<any, any>(memo(CustomFieldTableList, propsAreEqual));
