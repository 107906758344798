import { StoreBuildsResponse, StoreBuildResponse } from '.';
import {
  CreateStoreBuildRequest,
  SaveBuildSnapshotRequest,
  SaveBuildSnapshotResponse,
  UpdateStoreBuildRequest,
  CopyStoreBuildRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import {
  get,
  post,
  ApiResponse,
  ListApiResponse,
  del,
  patch,
  copy,
} from '../base';

export default function StoreBuildService() {
  return Object.freeze({
    createStoreBuild(
      req: CreateStoreBuildRequest
    ): Promise<StoreBuildResponse> {
      return post(`${BASE_API_URL()}/store-builder`, req);
    },
    updateStoreBuild(
      projectId: string,
      req: UpdateStoreBuildRequest,
      query = ''
    ): Promise<StoreBuildResponse> {
      return patch(`${BASE_API_URL()}/store-builder/${projectId}${query}`, req);
    },
    listStoreBuilds(query?: string): Promise<StoreBuildsResponse> {
      return get(
        `${BASE_API_URL()}/store-builder${query}`
      ) as Promise<ListApiResponse>;
    },
    getStoreBuild(
      buildId: string,
      query?: string
    ): Promise<StoreBuildResponse> {
      return get(`${BASE_API_URL()}/store-builder/${buildId}${query}`);
    },
    copyStoreBuild(
      buildId: string,
      req: CopyStoreBuildRequest
    ): Promise<StoreBuildResponse> {
      return copy(
        `${BASE_API_URL()}/store-builder/${buildId}?_columns=createdByUser.*`,
        req
      );
    },
    deleteStoreBuild(buildId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/store-builder/${buildId}`);
    },
    getLastSnapshot(buildId: string): Promise<ApiResponse> {
      return get(`${BASE_API_URL()}/store-builder/${buildId}/listing`);
    },
    saveBuildSnapshot(
      buildId: string,
      req: SaveBuildSnapshotRequest
    ): Promise<SaveBuildSnapshotResponse> {
      return post(`${BASE_API_URL()}/store-builder/${buildId}/snapshot`, req);
    },
    downloadBuildRSFFile(buildId: string, query = '') {
      return fetch(
        `${BASE_API_URL()}/store-builder/${buildId}/download${query}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // "Authorization": `${window.localStorage.getItem('token')}`,
          },
        }
      );
    },
  });
}
