export const dragElement = (
  el: HTMLElement,
  boundaries?: { top: number; left: number; right: number; bottom: number }
) => {
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(el.id + 'Drag')) {
    // if present, the header is where you move the DIV from:
    document.getElementById(el.id + 'Drag')!.onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    el.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    let nextTop =
      boundaries && boundaries.top > el.offsetTop - pos2
        ? boundaries.top
        : el.offsetTop - pos2;
    nextTop =
      boundaries && boundaries.bottom < nextTop ? boundaries.bottom : nextTop;

    let nextLeft =
      boundaries && boundaries.left > el.offsetLeft - pos1
        ? boundaries.left
        : el.offsetLeft - pos1;
    nextLeft =
      boundaries && boundaries.right < nextLeft ? boundaries.right : nextLeft;

    // set the element's new position:
    el.style.top = nextTop + 'px';
    el.style.left = nextLeft + 'px';
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
};
