import { FC, memo } from 'react';

import { ListOvrProjectModel } from '../../../../../models/online-virtual-research';
import { propsAreEqual } from '../../../../../util';
import './OVRProjectItemsList.less';
import OVRGridList from './components/OVRGridList';
import OVRTableList from './components/OVRTableList';
interface OVRItemsListProps {
  skeletonItemLength?: number;
  projects: ListOvrProjectModel[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  viewType: OVRItemsListViewType;
}

export enum OVRItemsListViewType {
  Grid = 'grid',
  Table = 'table',
}

const OVRItemsList: FC<OVRItemsListProps> = (props) => {
  const {
    projects,
    loading,
    onLoadMore,
    hasMore,
    viewType,
    skeletonItemLength,
  } = props;

  return viewType === OVRItemsListViewType.Grid ? (
    <OVRGridList
      skeletonItemLength={skeletonItemLength}
      loading={loading}
      projects={projects}
      onLoadMore={onLoadMore}
      hasMore={hasMore}
    />
  ) : (
    <OVRTableList loading={loading} projects={projects} />
  );
};

export default memo(OVRItemsList, propsAreEqual);
