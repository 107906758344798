import { FC, useCallback } from 'react';
import './MeasuringToolProperties.less';

import { Space, InputNumber } from 'antd';

import { Draw2DFigureModel } from '../../../../../BuilderLayoutEditor/types';

interface MeasuringToolPropertiesProps {
  measuringTool: Draw2DFigureModel;
  onUpdateMeasureTool: (field: 'width' | 'height', value: number) => void;
}

const pixelsToInch = 2.54;

const MeasuringToolProperties: FC<MeasuringToolPropertiesProps> = (props) => {
  const { measuringTool, onUpdateMeasureTool } = props;

  const getToolType = useCallback((tool: any): string => {
    return tool.cssClass.includes('draw2d_shape_basic_Rectangle')
      ? 'Rectangle'
      : 'Ellipsis';
  }, []);

  const measureToolWidth = +(measuringTool.width / pixelsToInch).toFixed(1);
  const measureToolHeight = +(measuringTool.height / pixelsToInch).toFixed(1);

  return (
    <div className="fixture-properties">
      <Space direction="vertical" size="middle">
        <div className="measuring-tool-properties-title">
          {`${getToolType(measuringTool)}`} measure tool
        </div>

        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Width</div>
          <InputNumber
            formatter={(value) => `${value}"`}
            style={{ width: '70px' }}
            size="small"
            value={measureToolWidth}
            onChange={(value) =>
              onUpdateMeasureTool('width', (value as number) * pixelsToInch)
            }
          />
        </div>

        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Height</div>
          <InputNumber
            formatter={(value) => `${value}"`}
            style={{ width: '70px' }}
            size="small"
            value={measureToolHeight}
            onChange={(value) =>
              onUpdateMeasureTool('height', (value as number) * pixelsToInch)
            }
          />
        </div>
      </Space>
    </div>
  );
};

export default MeasuringToolProperties;
