import { FC, memo } from 'react';

import './BuilderSettings.less';
import { Space, Tooltip, Switch } from 'antd';
import cx from 'classnames';

import { ReactComponent as BackArrowIcon } from '../../../../../../../assets/svgs/back-arrow-icon.svg';
import { propsAreEqual } from '../../../../../../../util';

export interface BuildSettings {
  displayGrid: boolean;
  displayRenderedImages: boolean;
}

interface BuilderSettingsProps {
  settings: BuildSettings;
  onUpdateSetting: (field: keyof BuildSettings, value: boolean) => void;
  onClose: () => void;
}

const BuilderSettings: FC<BuilderSettingsProps> = (props) => {
  const { onUpdateSetting, settings, onClose } = props;

  const settingsSectionClass = cx('settings-side-panel-section');

  return (
    <section className={settingsSectionClass}>
      <div className="side-panel-header">
        <div className="side-panel-header__section-left">
          <Space direction="horizontal" size="middle">
            <Tooltip title="Back">
              <BackArrowIcon
                style={{
                  paddingTop: '2px',
                  cursor: 'pointer',
                  marginTop: '6px',
                }}
                onClick={onClose}
              />
            </Tooltip>
            <h1>Settings</h1>
          </Space>
        </div>
      </div>
      <div className="settings-wrapper">
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div className="setting-item-wrapper">
            <div className="setting-item-name">Display rendered images</div>
            <Switch
              checked={settings.displayRenderedImages}
              onChange={(value: boolean) =>
                onUpdateSetting('displayRenderedImages', value)
              }
            />
          </div>

          <div className="setting-item-wrapper">
            <div className="setting-item-name">Display grid</div>
            <Switch
              checked={settings.displayGrid}
              onChange={(value: boolean) =>
                onUpdateSetting('displayGrid', value)
              }
            />
          </div>
        </Space>
      </div>
    </section>
  );
};

export default memo(BuilderSettings, propsAreEqual);
