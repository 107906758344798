import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';

import UploadModelService, {
  ExistItems,
  UploadResponse,
} from '../../../api/upload-model';
import { ProductModel } from '../../../models/upload';
import { convertParamsToQuery } from '../../../util';

export interface UploadState {
  // fileList: UploadFile[];
  existingItems: ExistItems;
  notExistingItems: any;
  notExistingProducts: any[];
  existingProducts: ProductModel[];
  uploadStatus?: 'idle' | 'loading' | 'succeeded' | 'failed';
  replacedProducts: ProductModel[];
  uploadedProducts: ProductModel[];
  masterLibrarianList: ProductModel[];
  productList: ProductModel[];
  processing: boolean;
  // replacing: boolean;
  // currentFileIndex: number;
  // totalFiles: number;
  // totalReplacingCount: number;
  // replacedCount: number;
  error: string | null;
}

const initialState: UploadState = {
  // fileList: [],
  existingItems: {},
  notExistingItems: {},
  notExistingProducts: [],
  existingProducts: [],
  masterLibrarianList: [],
  productList: [],
  replacedProducts: [],
  uploadedProducts: [],
  processing: false,
  // replacing: false,
  // currentFileIndex: 0,
  // totalFiles: 0,
  // totalReplacingCount: 0,
  // replacedCount: 0,
  error: null,
};

interface UploadFileProps {
  fileName: string;
  uuid: string;
  mediaVersionId: string;
  file: UploadFile<any>;
}

interface RemoveProductsActionPayload {
  uuids: string[];
  productType: keyof UploadState;
}

// categorize model as not existing or existing
export const categorizeModel = createAsyncThunk(
  'uploadModel/categorizeModel',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const params = {
        _order_by: 'created_at:desc',
        _limit: 100,
      };

      const queryString = convertParamsToQuery(params);

      const response = (await UploadModelService().upload(
        formData,
        queryString
      )) as UploadResponse;
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'uploadModel/uploadFile',
  async (
    { fileName, uuid, mediaVersionId, file }: UploadFileProps,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: { signedUrl, objectKey, media_id, media_version_id },
      } = await UploadModelService().getSignedUploadUrl(
        fileName,
        uuid,
        mediaVersionId
      );

      await axios.put(signedUrl, file.originFileObj, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      const mediaUploadResponse = await UploadModelService().mediaUploaded({
        objectKey,
        name: file.name,
        uuid,
        media_id,
        media_version_id,
      });

      return mediaUploadResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const replaceExistingProducts = createAsyncThunk(
//   'uploadModel/replaceExistingProducts',
//   async (formData: ExistItems, { rejectWithValue }) => {
//     try {
//       const response = await UploadModelService().replace(formData);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// Handles replacing existing models and adding new models
export const reuploadModel = createAsyncThunk(
  'uploadModel/reuploadModel',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await UploadModelService().reUpload(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkDeleteModels = createAsyncThunk(
  'uploadModel/bulkDeleteModels',
  async (uuids: string[], { rejectWithValue }) => {
    const modelsToDelete = {
      uuids,
    };
    try {
      const response = await UploadModelService().bulkDelete(modelsToDelete);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadModelSlice = createSlice({
  name: 'uploadModel',
  initialState,
  reducers: {
    clearUploadData: (state) => {
      // state.fileList = [];
      // state.existingItems = {};
      state.existingProducts = [];
      state.notExistingProducts = [];
      state.replacedProducts = [];
      state.uploadedProducts = [];
      state.processing = false;
      // state.replacing = false;
      // state.currentFileIndex = 0;
      // state.totalFiles = 0;
      // state.totalReplacingCount = 0;
      // state.replacedCount = 0;
      state.error = null;
    },
    // setExistingItems: (state, action) => {
    //   state.existingItems = action.payload;
    // },

    resetNotExistingProducts: (state) => {
      state.notExistingProducts = [];
    },
    resetExistingProducts: (state) => {
      state.existingProducts = [];
    },
    setExistingProducts: (state, action) => {
      state.existingProducts = [...state.existingProducts, ...action.payload];
    },
    setUploadedProducts: (state, action) => {
      state.uploadedProducts = [...state.uploadedProducts, ...action.payload];
    },
    setReplacedProducts: (state, action) => {
      state.replacedProducts = [...state.replacedProducts, ...action.payload];
    },
    removeProducts(state, action: PayloadAction<RemoveProductsActionPayload>) {
      const { uuids, productType } = action.payload;
      state[productType] = state[productType].filter(
        (product: ProductModel) => !uuids.includes(product.uuid)
      );
    },
  },
  extraReducers: (builder) => {
    // Upload File
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploadStatus = 'loading';
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.uploadStatus = 'succeeded';
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.error = action.payload as string;
      });

    // Categorize Model
    builder
      .addCase(categorizeModel.pending, (state) => {
        state.processing = true;
      })
      .addCase(categorizeModel.fulfilled, (state) => {
        // state.notExistingItems = action.payload.not_exists_items;
        // state.existingItems = action.payload.exists_items;

        // const existingItems = Object.values(action.payload.exists_items).map(
        //   (item) => item.item
        // );

        // const notExistingItems = Object.keys(action.payload.not_exists_items);

        // state.existingProducts = [...state.existingProducts, ...existingItems];

        // state.notExistingProducts = [
        //   ...state.notExistingProducts,
        //   ...notExistingItems,
        // ];

        state.processing = false;
      })
      .addCase(categorizeModel.rejected, (state, action) => {
        state.error = action.payload as string;
        state.processing = false;
      });
    // Bulk Delete Models
    builder
      .addCase(bulkDeleteModels.pending, (state) => {
        state.processing = true;
      })
      .addCase(bulkDeleteModels.fulfilled, (state) => {
        state.processing = false;
      })
      .addCase(bulkDeleteModels.rejected, (state, action) => {
        state.error = action.payload as string;
        state.processing = false;
      });
  },
});

export const {
  clearUploadData,
  setUploadedProducts,
  // setExistingItems,
  resetNotExistingProducts,
  resetExistingProducts,
  setExistingProducts,
  setReplacedProducts,
  removeProducts,
} = uploadModelSlice.actions;

export default uploadModelSlice.reducer;
