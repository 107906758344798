import { BASE_API_URL } from '../../config';
import { ApiResponse, ListApiResponse, del, get, patch, post } from '../base';
import { UploadUrlResponse } from '../file-storage';
import { PermissionsResponse } from '../permission';
import {
  CreateOvrFileRequest,
  OvrFileResponse,
  OvrFilesResponse,
  UpdateOvrFileClientRequest,
  UpdateOvrFilePermissionsRequest,
  UpdateOvrFileRequest,
} from './reqres';

export default function OvrFileService() {
  return Object.freeze({
    getRSOVRSignedUploadUrl(fileName: string): Promise<UploadUrlResponse> {
      return get(
        `${BASE_API_URL()}/file-storage/upload-url/rsovr-automation-queue?key=${fileName}`
      );
    },
    createOvrFile(req: CreateOvrFileRequest): Promise<OvrFileResponse> {
      return post(`${BASE_API_URL()}/automation-queue`, req);
    },
    updateOvrFile(
      ovrFileId: string,
      req: UpdateOvrFileRequest,
      query = ''
    ): Promise<OvrFileResponse> {
      return patch(
        `${BASE_API_URL()}/automation-queue/${ovrFileId}${query}`,
        req
      );
    },
    updateOvrFileClient(
      ovrFileId: string,
      req: UpdateOvrFileClientRequest
    ): Promise<OvrFileResponse> {
      return patch(`${BASE_API_URL()}/automation-queue/${ovrFileId}`, req);
    },
    listOvrFiles(query?: string): Promise<OvrFilesResponse> {
      return get(
        `${BASE_API_URL()}/automation-queue${query}`
      ) as Promise<ListApiResponse>;
    },
    getOvrFile(ovrFileId: string): Promise<OvrFileResponse> {
      return get(`${BASE_API_URL()}/automation-queue/${ovrFileId}`);
    },
    deleteOvrFile(ovrFileId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/automation-queue/${ovrFileId}`);
    },
    updateOvrFilePermissions(
      ovrFileId: string,
      req: UpdateOvrFilePermissionsRequest
    ): Promise<PermissionsResponse> {
      return post(
        `${BASE_API_URL()}/automation-queue/${ovrFileId}/permission`,
        req
      ) as Promise<ListApiResponse>;
    },
  });
}
