import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Badge, Empty, Input, Space, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import './CustomFieldsView.less';

import useDebounce from '../../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../store';
import { searchCustomFieldsByName } from '../../../store/features/customFields/customFieldsSlice';
import { UserPermissions, getMePermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import CustomFieldTableList from './components/CustomFieldTableList';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const CustomFieldsView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const {
    value: customFields,
    searchingCustomFields,
    fetchingCustomFields,
    totalCount,
  } = useAppSelector((state) => state.customFields);

  const hasSearchQuery = !!searchQuery;
  const hasNoResults = customFields.length === 0;
  const hasCustomFields = !searchingCustomFields && customFields.length > 0;

  const dispatch = useAppDispatch();

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    dispatch(searchCustomFieldsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const hasNoSearchResultsFound = useMemo(
    () => searchingCustomFields === false && hasSearchQuery && hasNoResults,
    [searchingCustomFields, hasSearchQuery, hasNoResults]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const createNewCustomFieldButton = useMemo(
    () => (
      <Link to={DrawerHashRoute.CustomFieldForm}>
        <Button type="primary" data-cy="create-new-custom-field-btn">
          Create New Custom Field
          <PlusOutlined />
        </Button>
      </Link>
    ),
    []
  );

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const headerSectionRight = useMemo(
    () => (
      <Space direction="horizontal" size="middle">
        <Input
          allowClear={!searchingCustomFields}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a field..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={searchingCustomFields && <Spin indicator={antSpinIcon} />}
        />
        {currentUserPermissions.includes(UserPermissions.CustomFieldsCreate)
          ? createNewCustomFieldButton
          : null}
      </Space>
    ),
    [
      createNewCustomFieldButton,
      searchQuery,
      searchingCustomFields,
      currentUserPermissions,
      updateSearchQuery,
    ]
  );

  const renderContent = () => {
    if (searchingCustomFields) {
      return (
        <div className="custom-fields-view-container">
          <Spin indicator={antSpinIcon} />
        </div>
      );
    }

    if (hasCustomFields) {
      return (
        <CustomFieldTableList
          loading={fetchingCustomFields}
          customFields={customFields}
        />
      );
    }

    if (hasNoSearchResultsFound) {
      return (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      );
    }

    if (!hasCustomFields) {
      return (
        <div className="custom-fields-view-container">
          <Empty
            description="Currently no custom fields."
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          >
            {createNewCustomFieldButton}
          </Empty>
        </div>
      );
    }
  };

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Custom Fields</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
          />
        </Space>
      }
      headerSectionRight={headerSectionRight}
    >
      {renderContent()}
    </ViewWrapper>
  );
};

export default CustomFieldsView;
