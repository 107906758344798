import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Empty,
  Input,
  message,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as GridViewIcon } from '../../../assets/svgs/grid-view-icon.svg';
import { ReactComponent as TableViewIcon } from '../../../assets/svgs/list-view-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  loadMoreOvrProjects,
  searchOvrProjectsByName,
} from '../../../store/features/ovrProjects/ovrProjectsSlice';
import {
  debounce,
  DebouncedFunction,
  getMePermissions,
  UserPermissions,
} from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Button from '../../elements/Button';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import OVRProjectItemsList, {
  OVRItemsListViewType,
} from './components/OVRProjectItemsList';

import { clearOvrProjectDetails } from '../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import './OnlineVirtualResearchView.less';

const { Title } = Typography;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
const ovrListViewTypeKey = 'ovr-list-view-type';
const lastOvrProjectsItemLengthKey = 'last-ovr-projects-item-length-key';

const OnlineVirtualResearchView = () => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [clearingSearch, setClearingSearch] = useState(false);
  const [viewType, setViewType] = useState<OVRItemsListViewType>(
    (localStorage.getItem(ovrListViewTypeKey) as OVRItemsListViewType) ||
      OVRItemsListViewType.Grid
  );

  const {
    value: ovrProjects,
    fetchingOvrProjects,
    totalCount,
    pagination,
    searchingOvrProjects,
    allOvrProjects,
  } = useAppSelector((state) => state.ovrProjects);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingOvrProjects && !!searchQuery && ovrProjects.length === 0,
    [fetchingOvrProjects, searchQuery, ovrProjects.length]
  );

  const hasOvrProjects = !(!fetchingOvrProjects && allOvrProjects.length === 0);

  const hasNoProjects = useMemo(
    () =>
      !fetchingOvrProjects && allOvrProjects.length === 0 && !clearingSearch,
    [fetchingOvrProjects, allOvrProjects.length, clearingSearch]
  );

  const hasMoreOvrProjects =
    hasOvrProjects && pagination?.offset! + pagination?.limit! < totalCount!;

  const isPermittedToLoadMore =
    !fetchingOvrProjects &&
    !fetchingOvrProjects &&
    !searchQuery &&
    hasMoreOvrProjects;

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const fetchOvrProjects = useCallback(async () => {
    try {
      await dispatch(searchOvrProjectsByName('')).unwrap();
    } catch (error) {
      message.error('Failed to fetch ovr projects');
    }
  }, [dispatch]);

  useEffect(() => {
    if (!searchQuery) {
      fetchOvrProjects();
      dispatch(clearOvrProjectDetails());
    }
  }, [dispatch, fetchOvrProjects, searchQuery]);

  const createNewProjectButton = useMemo(
    (): JSX.Element => (
      <Link to={DrawerHashRoute.OVRForm}>
        <Button type="primary" data-cy="create-new-ovr-project-btn">
          Create New Project
          <PlusOutlined />
        </Button>
      </Link>
    ),
    []
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string, clearing: boolean) => {
        if (clearing) {
          setClearingSearch(true);
        } else {
          setClearingSearch(false);
        }
        await dispatch(searchOvrProjectsByName(query));
      }, 700) as DebouncedFunction,
    [dispatch]
  );

  const viewTypeIconClass = useCallback(
    (type: OVRItemsListViewType) =>
      cx('ovr-view-type-icon', { selected: type === viewType }),
    [viewType]
  );

  const updateSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
      debouncedSearch(query, query === '');
    },
    [debouncedSearch]
  );

  const updateOVRListViewType = useCallback((type: OVRItemsListViewType) => {
    setViewType(type);
    localStorage.setItem(ovrListViewTypeKey, type);
  }, []);

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Space direction="horizontal" size="middle">
        <Tooltip title="Grid view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <GridViewIcon
            className={viewTypeIconClass(OVRItemsListViewType.Grid)}
            onClick={() => updateOVRListViewType(OVRItemsListViewType.Grid)}
          />
        </Tooltip>
        <Tooltip title="Table view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <TableViewIcon
            className={viewTypeIconClass(OVRItemsListViewType.Table)}
            onClick={() => updateOVRListViewType(OVRItemsListViewType.Table)}
          />
        </Tooltip>
        <Input
          allowClear={!searchingOvrProjects}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          placeholder="Search for a project..."
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          suffix={searchingOvrProjects && <Spin indicator={antSpinIcon} />}
        />
        {currentUserPermissions.includes(UserPermissions.OvrProjectsCreate)
          ? createNewProjectButton
          : null}
      </Space>
    ),
    [
      viewTypeIconClass,
      searchingOvrProjects,
      searchQuery,
      currentUserPermissions,
      createNewProjectButton,
      updateOVRListViewType,
      updateSearchQuery,
    ]
  );

  useEffect(() => {
    if (!!searchQuery || ovrProjects.length > 20) return;

    localStorage.setItem(lastOvrProjectsItemLengthKey, `${ovrProjects.length}`);
  }, [ovrProjects.length, searchQuery]);

  const renderContent = () => {
    if (searchingOvrProjects) {
      return (
        <div className="custom-fields-view-container">
          <Spin indicator={antSpinIcon} />
        </div>
      );
    }

    if (!hasNoProjects) {
      return (
        <OVRProjectItemsList
          skeletonItemLength={
            +localStorage.getItem(lastOvrProjectsItemLengthKey)! as number
          }
          viewType={viewType}
          loading={fetchingOvrProjects}
          projects={ovrProjects}
          onLoadMore={() => {
            dispatch(loadMoreOvrProjects());
          }}
          hasMore={isPermittedToLoadMore}
        />
      );
    }

    if (hasNoSearchResultsFound) {
      return (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      );
    }

    if (!hasMoreOvrProjects) {
      return (
        <div className="custom-fields-view-container">
          <Empty
            description="Currently no ovr projects."
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          >
            {createNewProjectButton}
          </Empty>
        </div>
      );
    }
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <ViewWrapper
      headerTitle={
        <Space direction="horizontal">
          <Title level={3}>Projects</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
            overflowCount={999}
          />
        </Space>
      }
      headerSectionRight={headerSectionRight}
    >
      {renderContent()}
    </ViewWrapper>
  );
};

export default OnlineVirtualResearchView;
