import { BASE_API_URL } from '../../config';
import { get, ListApiResponse } from '../base';
import { PermissionsResponse } from './reqres';

export default function PermissionService() {
  return Object.freeze({
    listPermissions(): Promise<PermissionsResponse> {
      return get(`${BASE_API_URL()}/permission`) as Promise<ListApiResponse>;
    },
    listAclPermissions(query?: string): Promise<PermissionsResponse> {
      return get(
        `${BASE_API_URL()}/acl/permission${query ?? ''}`
      ) as Promise<ListApiResponse>;
    },
  });
}
