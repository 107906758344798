import { createContext, FC, useCallback, useMemo, useState, memo } from 'react';

import './CustomFieldForm.less';
import { Form, Input, message, Space, Select, Divider } from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import OptionsInput from './components/OptionsInput';
import {
  customFieldFieldTypes,
  CustomFieldFormLocationState,
  ProductCustomFieldValueTypes,
} from './types';
import { ApiError, handleError } from '../../../../api/base';
import CustomFieldService, {
  CustomFieldOptionResponse,
  CustomFieldResponse,
} from '../../../../api/custom-field';
import {
  initialNewCustomFieldModel,
  CustomFieldModel,
  CustomFieldOption,
  ProductCustomFieldValueType,
} from '../../../../models/custom-field';
import { useAppDispatch } from '../../../../store';
import {
  createCustomField,
  updateCustomField,
} from '../../../../store/features/customFields/customFieldsSlice';
import { capitalizeFirstWord, propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';

const { Option } = Select;
interface CustomFieldFormProps {
  history: History;
  location: Location<CustomFieldFormLocationState>;
}

export const CustomFieldContext = createContext<
  Partial<{ customField: CustomFieldModel }>
>({});

const CustomFieldForm: FC<CustomFieldFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();

  const isNewCustomField = useMemo(() => !location.state, [location.state]);
  const customField = isNewCustomField
    ? initialNewCustomFieldModel
    : location.state?.data;

  const [form] = Form.useForm();
  const [savingCustomField, setSavingCustomField] = useState<boolean>(false);
  const [valueType, setValueType] =
    useState<ProductCustomFieldValueType | null>(customField.value_type);
  const [options, setOptions] = useState<CustomFieldOption[]>(
    customField.options
  );

  const fields = useMemo(() => Object.keys(customField), [customField]);
  const isEnumOrMultiType = valueType === 'enum' || valueType === 'multi';

  const handleAddOption = useCallback(() => {
    const initilaNewOption = {
      name: '',
      option_key: '',
    } as CustomFieldOption;

    setOptions([...options, initilaNewOption]);
  }, [options]);

  const handleChangeOption = useCallback(
    (index: number, field: string, value: any) => {
      const nextOptions = options.map((option, i) => {
        if (i === index) {
          return {
            ...option,
            [field]: value,
          };
        }
        return option;
      });

      setOptions(nextOptions);
    },
    [options]
  );

  const handleRemoveOption = useCallback(
    (index: number) => {
      if (options.length === 1) {
        message.error(
          'You must have at least one option. Try adding another option first, then deleting the current one.',
          6
        );
        return;
      }

      const nextOptions = options.filter((_, i) => i !== index);

      setOptions(nextOptions);
    },
    [options]
  );

  const saveOptions = useCallback(
    async (resp: CustomFieldResponse): Promise<CustomFieldModel> => {
      const filteredOptions = options.filter(
        (opt) => !!opt.name && !!opt.option_key
      );

      if (
        isNewCustomField &&
        isEnumOrMultiType &&
        filteredOptions.length >= 1
      ) {
        const createOptionCalls = () =>
          Promise.all(
            filteredOptions.map((option) => {
              return CustomFieldService().createCustomFieldOption(
                resp.data.id!,
                option
              );
            })
          );

        const onCreateOptionsSuccess = (
          responses: CustomFieldOptionResponse[]
        ): CustomFieldModel => {
          return {
            ...resp.data,
            options: responses.map(({ data }) => data),
          };
        };

        return await createOptionCalls().then(onCreateOptionsSuccess);
      }

      return resp.data;
    },
    [isEnumOrMultiType, isNewCustomField, options]
  );

  const onError = useCallback((err: ApiError) => {
    setSavingCustomField(false);
    handleError(err);
  }, []);

  const saveCustomField = useCallback(
    async (customField: CustomFieldModel) => {
      setSavingCustomField(true);
      const res: any = isNewCustomField
        ? await dispatch(
            createCustomField({
              customField,
            })
          )
        : await dispatch(
            updateCustomField({
              customFieldId: location.state?.data?.id!,
              customField,
            })
          );

      await saveOptions(res);

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Custom field saved.');
        history.push(RoutePath.CustomFields);
      }
    },
    [
      isNewCustomField,
      dispatch,
      location.state?.data?.id,
      saveOptions,
      onError,
      history,
    ]
  );

  const onSubmit = useCallback(
    (values: CustomFieldModel) => {
      form.validateFields(fields).then(() => saveCustomField(values));
    },
    [fields, form, saveCustomField]
  );

  return (
    <FormWrapper
      title={`${isNewCustomField ? 'Create new' : 'Edit'} custom field`}
      onClose={() => history.push(RoutePath.CustomFields)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewCustomField ? initialNewCustomFieldModel : location.state?.data
        }
        onFinish={onSubmit}
      >
        <Form.Item
          label="Custom Field Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Custom field name is required.',
            },
          ]}
        >
          <Input
            data-cy="custom-field-form-input"
            placeholder="Custom field name"
          />
        </Form.Item>
        <Form.Item
          label="Attachment"
          name="type"
          rules={[{ required: true, message: 'Attachment is required.' }]}
        >
          <Select placeholder="Select an attachment">
            {customFieldFieldTypes.map((option) => {
              return (
                <Option key={option} value={option}>
                  {capitalizeFirstWord(option)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Value Type"
          name="value_type"
          rules={[{ required: true, message: 'Value type is required.' }]}
        >
          <Select
            placeholder="Select a value type"
            onChange={(value: ProductCustomFieldValueType) =>
              setValueType(value)
            }
          >
            {ProductCustomFieldValueTypes.map((option) => {
              return (
                <Option key={option} value={option}>
                  {capitalizeFirstWord(option)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {isEnumOrMultiType ? (
          <>
            <Divider orientation="left">{`${capitalizeFirstWord(
              valueType!
            )} Options`}</Divider>
            <div
              className="options-card-section"
              style={{ marginBottom: '24px' }}
            >
              <CustomFieldContext.Provider
                value={{ customField: customField! }}
              >
                <OptionsInput
                  onAddOption={handleAddOption}
                  onChangeOption={handleChangeOption}
                  onRemoveOption={handleRemoveOption}
                  options={options}
                />
              </CustomFieldContext.Provider>
            </div>
            <Divider />
          </>
        ) : null}

        <Form.Item
          label="Field Key"
          name="field_key"
          rules={[{ required: true, message: 'Field key is required.' }]}
        >
          <Input data-cy="custom-field-form-input" placeholder="Field Key" />
        </Form.Item>
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={savingCustomField}
              data-cy="custom-field-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {`${isNewCustomField ? 'Create' : 'Save'} Custom Field`}
            </Button>
            <Button
              onClick={() => history.push(RoutePath.CustomFields)}
              style={{ width: '100%' }}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(CustomFieldForm, propsAreEqual));
