import CStoreWGrid from '../../../assets/stores/CStore/lined-w-grid.png';
import CStore from '../../../assets/stores/CStore/lined.png';
import CStoreRenderedWGrid from '../../../assets/stores/CStore/rendered-w-grid.png';
import CStoreRendered from '../../../assets/stores/CStore/rendered.png';
import CStoreLCounterWGrid from '../../../assets/stores/CStoreLCounter/lined-w-grid.png';
import CStoreLCounter from '../../../assets/stores/CStoreLCounter/lined.png';
import CStoreLCounterRenderedWGrid from '../../../assets/stores/CStoreLCounter/rendered-w-grid.png';
import CStoreLCounterRendered from '../../../assets/stores/CStoreLCounter/rendered.png';
import CStoreNoSelfCheckoutWGrid from '../../../assets/stores/CStoreNoSelfCheckout/lined-w-grid.png';
import CStoreNoSelfCheckout from '../../../assets/stores/CStoreNoSelfCheckout/lined.png';
import CStoreNoSelfCheckoutRenderedWGrid from '../../../assets/stores/CStoreNoSelfCheckout/rendered-w-grid.png';
import CStoreNoSelfCheckoutRendered from '../../../assets/stores/CStoreNoSelfCheckout/rendered.png';
import FullSizeGroceryFrontEndSpaceWGrid from '../../../assets/stores/FullSizeGroceryFrontEndSpace/lined-w-grid.png';
import FullSizeGroceryFrontEndSpace from '../../../assets/stores/FullSizeGroceryFrontEndSpace/lined.png';
import FullSizeGroceryFrontEndSpaceRenderedWGrid from '../../../assets/stores/FullSizeGroceryFrontEndSpace/rendered-w-grid.png';
import FullSizeGroceryFrontEndSpaceRendered from '../../../assets/stores/FullSizeGroceryFrontEndSpace/rendered.png';
import GroceryLeftEndSpaceWPharmacyWGrid from '../../../assets/stores/GroceryLeftEndSpaceWPharmacy/lined-w-grid.png';
import GroceryLeftEndSpaceWPharmacy from '../../../assets/stores/GroceryLeftEndSpaceWPharmacy/lined.png';
import GroceryLeftEndSpaceWPharmacyRenderedWGrid from '../../../assets/stores/GroceryLeftEndSpaceWPharmacy/rendered-w-grid.png';
import GroceryLeftEndSpaceWPharmacyRendered from '../../../assets/stores/GroceryLeftEndSpaceWPharmacy/rendered.png';
import GroceryRightFrontEndSpaceWCustomerServiceWGrid from '../../../assets/stores/GroceryRightFrontEndSpaceWCustomerService/lined-w-grid.png';
import GroceryRightFrontEndSpaceWCustomerService from '../../../assets/stores/GroceryRightFrontEndSpaceWCustomerService/lined.png';
import GroceryRightFrontEndSpaceWCustomerServiceRenderedWGrid from '../../../assets/stores/GroceryRightFrontEndSpaceWCustomerService/rendered-w-grid.png';
import GroceryRightFrontEndSpaceWCustomerServiceRendered from '../../../assets/stores/GroceryRightFrontEndSpaceWCustomerService/rendered.png';
import GrocerySpaceOpenWGrid from '../../../assets/stores/GrocerySpaceOpen/lined-w-grid.png';
import GrocerySpaceOpen from '../../../assets/stores/GrocerySpaceOpen/lined.png';
import GrocerySpaceOpenRenderedWGrid from '../../../assets/stores/GrocerySpaceOpen/rendered-w-grid.png';
import GrocerySpaceOpenRendered from '../../../assets/stores/GrocerySpaceOpen/rendered.png';
import GrocerySpaceOpenWCustomerServiceWGrid from '../../../assets/stores/GrocerySpaceOpenWCustomerService/lined-w-grid.png';
import GrocerySpaceOpenWCustomerService from '../../../assets/stores/GrocerySpaceOpenWCustomerService/lined.png';
import GrocerySpaceOpenWCustomerServiceRenderedWGrid from '../../../assets/stores/GrocerySpaceOpenWCustomerService/rendered-w-grid.png';
import GrocerySpaceOpenWCustomerServiceRendered from '../../../assets/stores/GrocerySpaceOpenWCustomerService/rendered.png';
import LowesWGrid from '../../../assets/stores/Lowes/lined-w-grid.png';
import Lowes from '../../../assets/stores/Lowes/lined.png';
import LowesRenderedWGrid from '../../../assets/stores/Lowes/rendered-w-grid.png';
import LowesRendered from '../../../assets/stores/Lowes/rendered.png';
import WalmartFrontEndWGrid from '../../../assets/stores/WalmartFrontEnd/lined-w-grid.png';
import WalmartFrontEnd from '../../../assets/stores/WalmartFrontEnd/lined.png';
import WalmartFrontEndRenderedWGrid from '../../../assets/stores/WalmartFrontEnd/rendered-w-grid.png';
import WalmartFrontEndRendered from '../../../assets/stores/WalmartFrontEnd/rendered.png';
import WalmartFrontEndSpaceWGrid from '../../../assets/stores/WalmartFrontEndSpace/lined-w-gird.png';
import WalmartFrontEndSpace from '../../../assets/stores/WalmartFrontEndSpace/lined.png';
import WalmartFrontEndSpaceRenderedWGrid from '../../../assets/stores/WalmartFrontEndSpace/rendered-w-grid.png';
import WalmartFrontEndSpaceRendered from '../../../assets/stores/WalmartFrontEndSpace/rendered.png';
import { BuildSpace } from '../../../models/store-build';

const RED_BORDER_LINE_WIDTH = 3;
export interface EnvironmentModel {
  name: string;
  id: BuildSpace;
  width: number;
  height: number;
  space: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  renders: {
    lined: string;
    linedWithGrid: string;
    rendered: string;
    renderedWithGrid: string;
  };
}

export const environmentsByIdMap: { [key in BuildSpace]: EnvironmentModel } = {
  1: {
    name: `Grocery Left Front End Space with Pharmacy 415" W x 306" H`,
    id: BuildSpace.LeftWithPharmacy,
    width: 3960,
    height: 2227.5,
    space: {
      height: 777.24 + RED_BORDER_LINE_WIDTH,
      width: 1054.1 + RED_BORDER_LINE_WIDTH,
      x: 153.05 - RED_BORDER_LINE_WIDTH,
      y: 654 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: GroceryLeftEndSpaceWPharmacy,
      linedWithGrid: GroceryLeftEndSpaceWPharmacyWGrid,
      rendered: GroceryLeftEndSpaceWPharmacyRendered,
      renderedWithGrid: GroceryLeftEndSpaceWPharmacyRenderedWGrid,
    },
  },
  2: {
    name: `Grocery Right Front End Space with Customer Service 387" W x 306" H`,
    id: BuildSpace.RightWithCustomerService,
    width: 3960,
    height: 2228,
    space: {
      height: 777.24 + RED_BORDER_LINE_WIDTH,
      width: 982.98 + RED_BORDER_LINE_WIDTH,
      x: 2861.3 - RED_BORDER_LINE_WIDTH,
      y: 651 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: GroceryRightFrontEndSpaceWCustomerService,
      linedWithGrid: GroceryRightFrontEndSpaceWCustomerServiceWGrid,
      rendered: GroceryRightFrontEndSpaceWCustomerServiceRendered,
      renderedWithGrid: GroceryRightFrontEndSpaceWCustomerServiceRenderedWGrid,
    },
  },
  3: {
    name: `Grocery Front End Space Open 1,456" W x 306"`,
    id: BuildSpace.Open,
    width: 3960,
    height: 2227.5,
    space: {
      height: 777.24 + RED_BORDER_LINE_WIDTH,
      width: 3698.24 + RED_BORDER_LINE_WIDTH,
      x: 154.95 - RED_BORDER_LINE_WIDTH,
      y: 651.26 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: GrocerySpaceOpen,
      linedWithGrid: GrocerySpaceOpenWGrid,
      rendered: GrocerySpaceOpenRendered,
      renderedWithGrid: GrocerySpaceOpenRenderedWGrid,
    },
  },
  4: {
    name: `Grocery Front End Space with Customer Service 1,071" W x 306" H`,
    id: BuildSpace.OpenWithCustomerService,
    width: 3960,
    height: 2227.5,
    space: {
      height: 777.24 + RED_BORDER_LINE_WIDTH,
      width: 2720.34 + RED_BORDER_LINE_WIDTH,
      x: 1150.83 - RED_BORDER_LINE_WIDTH,
      y: 648.52 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: GrocerySpaceOpenWCustomerService,
      linedWithGrid: GrocerySpaceOpenWCustomerServiceWGrid,
      rendered: GrocerySpaceOpenWCustomerServiceRendered,
      renderedWithGrid: GrocerySpaceOpenWCustomerServiceRenderedWGrid,
    },
  },
  5: {
    name: `Full Size Grocery Front End Space 1,350" W x 306" H`,
    id: BuildSpace.FullSizeGrocery,
    width: 4002,
    height: 2251.13,
    space: {
      height: 777.24 + RED_BORDER_LINE_WIDTH,
      width: 3429 + RED_BORDER_LINE_WIDTH,
      x: 330.21 - RED_BORDER_LINE_WIDTH,
      y: 702.54 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: FullSizeGroceryFrontEndSpace,
      linedWithGrid: FullSizeGroceryFrontEndSpaceWGrid,
      rendered: FullSizeGroceryFrontEndSpaceRendered,
      renderedWithGrid: FullSizeGroceryFrontEndSpaceRenderedWGrid,
    },
  },
  6: {
    name: `Walmart Front End Space 2,251" W x 410" H`,
    id: BuildSpace.Walmart,
    width: 7209.24,
    height: 3751.94,
    space: {
      height: 1041.4 + RED_BORDER_LINE_WIDTH,
      width: 5717.54 + RED_BORDER_LINE_WIDTH,
      x: 745.7 - RED_BORDER_LINE_WIDTH,
      y: 1326.56 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: WalmartFrontEnd,
      linedWithGrid: WalmartFrontEndWGrid,
      rendered: WalmartFrontEndRendered,
      renderedWithGrid: WalmartFrontEndRenderedWGrid,
    },
  },
  7: {
    name: `C-Store Straight Counter 219" W x 447" H`,
    id: BuildSpace.CStore,
    width: 1987,
    height: 1622,
    space: {
      height: 1135.38 + RED_BORDER_LINE_WIDTH,
      width: 556.26 + RED_BORDER_LINE_WIDTH,
      x: 465.02 - RED_BORDER_LINE_WIDTH,
      y: 391.33 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: CStore,
      linedWithGrid: CStoreWGrid,
      rendered: CStoreRendered,
      renderedWithGrid: CStoreRenderedWGrid,
    },
  },
  8: {
    name: `C-Store L Counter 308.6" W x 447" H`,
    id: BuildSpace.CStoreLCounter,
    width: 1870,
    height: 1479,
    space: {
      height: 1135.38 + RED_BORDER_LINE_WIDTH,
      width: 783.84 + RED_BORDER_LINE_WIDTH,
      x: 191.48 - RED_BORDER_LINE_WIDTH,
      y: 313.92 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: CStoreLCounter,
      linedWithGrid: CStoreLCounterWGrid,
      rendered: CStoreLCounterRendered,
      renderedWithGrid: CStoreLCounterRenderedWGrid,
    },
  },
  9: {
    name: `Lowes Washer and Dryers 442.1" W x 282.7" H`,
    id: BuildSpace.Lowes,
    width: 2083,
    height: 1084,
    space: {
      height: 718.05 + RED_BORDER_LINE_WIDTH,
      width: 1072.3 + RED_BORDER_LINE_WIDTH,
      x: 515.35 - RED_BORDER_LINE_WIDTH,
      y: 194.98 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: Lowes,
      linedWithGrid: LowesWGrid,
      rendered: LowesRendered,
      renderedWithGrid: LowesRenderedWGrid,
    },
  },
  10: {
    name: `Walmart Front End Space 3149.4" W x 879.5" H`,
    id: BuildSpace.WalmartFrontEndSpace,
    width: 8664.89,
    height: 4509.55,
    space: {
      height: 2234.13 + RED_BORDER_LINE_WIDTH,
      width: 7999.62 + RED_BORDER_LINE_WIDTH,
      x: 314.17 - RED_BORDER_LINE_WIDTH,
      y: 1014.58 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: WalmartFrontEndSpace,
      linedWithGrid: WalmartFrontEndSpaceWGrid,
      rendered: WalmartFrontEndSpaceRendered,
      renderedWithGrid: WalmartFrontEndSpaceRenderedWGrid,
    },
  },
  11: {
    name: `C-Store U Area, No Self Check 248.6" W x 447`,
    id: BuildSpace.CStoreNoSelfCheckout,
    width: 1725,
    height: 1473,
    space: {
      height: 1135.38 + RED_BORDER_LINE_WIDTH,
      width: 631.44 + RED_BORDER_LINE_WIDTH,
      x: 193.87 - RED_BORDER_LINE_WIDTH,
      y: 311.16 - RED_BORDER_LINE_WIDTH,
    },
    renders: {
      lined: CStoreNoSelfCheckout,
      linedWithGrid: CStoreNoSelfCheckoutWGrid,
      rendered: CStoreNoSelfCheckoutRendered,
      renderedWithGrid: CStoreNoSelfCheckoutRenderedWGrid,
    },
  },
};
