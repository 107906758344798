import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, useHistory } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import { ClientModel } from '../../../models/client';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  paginateClients,
  searchClientsByName,
} from '../../../store/features/clients/clientsSlice';
import { getMePermissions, UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import './ClientsView.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Text } = Typography;

export interface ClientModelWithExtensions extends ClientModel {
  markets: string;
  client_type: string;
}

const ClientsView = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const { clients, fetchingClients, searchingClients, count, current } =
    useAppSelector((state) => state.clients);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingClients && !!searchQuery && clients.length === 0,
    [clients.length, fetchingClients, searchQuery]
  );

  const hasClients = fetchingClients || (clients && clients.length > 0);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const getLicenseStatusColor = (licenseStatus: string) => {
    switch (licenseStatus) {
      case 'active':
        return 'green';
      case 'inactive':
        return 'orange';
    }
  };

  const columns: ColumnsType<ClientModel> = [
    {
      title: 'Name',
      width: '35%',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (client: ClientModelWithExtensions) => (
        <Link to={`/clients/${client?.uuid}`}>
          <Button type="link" className="link-button">
            {client?.name}
          </Button>
        </Link>
      ),
    },
    {
      title: 'Type',
      width: '20%',
      render: (client: ClientModelWithExtensions) => (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          {client?.client_type}
        </Text>
      ),
    },
    {
      title: 'License Status',
      dataIndex: 'license_status',
      width: '20%',
      render: () => {
        return <Tag color={getLicenseStatusColor('active')}>Active</Tag>;
      },
    },
    {
      title: 'Action',
      width: 100,
      render: (client: ClientModelWithExtensions) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(UserPermissions.ClientsEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.ClientForm,
                    state: { client },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(searchClientsByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateClients({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current,
  };

  return (
    <ViewWrapper>
      <div className="search-bar">
        <div className="search-bar-left">
          <Search
            className="search-input"
            allowClear={!searchingClients}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e.target.value)}
            enterButton
            placeholder="Search"
            suffix={searchingClients && <Spin indicator={antSpinIcon} />}
          />
        </div>
        {currentUserPermissions.includes(UserPermissions.ClientsCreate) ? (
          <Link to={DrawerHashRoute.ClientForm}>
            <Button type="primary">Add New Client</Button>
          </Link>
        ) : null}
      </div>
      <div className="clients-table-wrapper">
        {hasClients && hasNoSearchResultsFound ? (
          <NoResultsFound
            searchQuery={searchQuery}
            onClear={() => updateSearchQuery('')}
          />
        ) : (
          <Table
            sortDirections={['ascend', 'descend', 'ascend']}
            columns={columns}
            rowKey={(client: ClientModel) => `${client.uuid}-row-key`}
            dataSource={clients}
            pagination={paginationSetting}
          />
        )}
      </div>
    </ViewWrapper>
  );
};

export default ClientsView;
