import { BASE_API_URL } from '../../config';
import { get } from '../base';
import { FiltersResponse, SearchItemsResponse } from './reqres';

export default function FiltersService() {
  return Object.freeze({
    getFilters(filters?: string): Promise<FiltersResponse> {
      return get(
        `${BASE_API_URL()}/items/filters${filters ? `/${filters}` : ''}`
      );
    },
    searchItems(filters?: string): Promise<SearchItemsResponse> {
      return get(
        `${BASE_API_URL()}/items/search${filters ? `/${filters}` : ''}`
      );
    },
  });
}
