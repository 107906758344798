import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Html, useGLTF } from '@react-three/drei';
import { Spin } from 'antd';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import {
  MaterialProperty,
  getMaterialPropertiesFromRSS,
  preloadTextures,
} from './utils';

interface ModelGlbProps {
  modelSrc: string;
  modelRSS?: string;
  textureSrcs: string[];
}

type GLTFResult = GLTF & {
  nodes: {
    [key: string]: THREE.Mesh;
  };
  materials: {
    [key: string]: THREE.MeshStandardMaterial;
  };
};

const ThreeModel = ({
  modelSrc,
  modelRSS,
  textureSrcs,
  ...props
}: ModelGlbProps) => {
  const [textureLoaded, setTextureLoaded] = useState(false);
  const [materialProperties, setMaterialProperties] = useState<
    MaterialProperty[] | null
  >([]);
  const { materials, scene } = useGLTF(modelSrc) as unknown as GLTFResult;

  useEffect(() => {
    if (!modelRSS) return;
    fetch(modelRSS)
      .then((response) => {
        return response.text();
      })
      .then((txt) => {
        setMaterialProperties(getMaterialPropertiesFromRSS(txt));
      });
  }, [modelRSS]);

  // updates materials based on rss info
  useLayoutEffect(() => {
    if (materialProperties?.length === 0) return;

    let map: THREE.Texture | null = null;
    let normalMap: THREE.Texture | null = null;

    if (materials && typeof materials === 'object') {
      for (const key in materials) {
        const material = materials[key];
        const materialName = material.name;

        materialProperties?.forEach((property) => {
          if (materialName.includes(property.name)) {
            const color =
              property.color && property.color.length > 0
                ? property.color
                : undefined;

            if (property.shader === 'cutout') {
              // todo: after maping alphamap, see if we still need alphaTest.
              material.alphaTest = 0.002;
              material.depthTest = true;
              material.depthWrite = true;
            }

            if (
              property.shader === 'transparent' ||
              property.shader === 'fade'
            ) {
              material.depthTest = true;
              material.depthWrite = true;
              material.transparent = true;
            }

            if (property.map) {
              map = textureSrcs
                ? preloadTextures(property.map, textureSrcs, setTextureLoaded)
                : null;
              material.map = map;
              // todo: get alpha map and map it here instead of map.
              material.alphaMap = map;
            }

            if (property.normalMap) {
              normalMap = textureSrcs
                ? preloadTextures(
                    property.normalMap,
                    textureSrcs,
                    setTextureLoaded
                  )
                : null;
              material.normalMap = normalMap;
            }

            material.setValues({
              color: color && new THREE.Color().fromArray(color),
              roughness: property.roughness && property.roughness,
              metalness: property.metalness && property.metalness,
              normalScale: new THREE.Vector2(0.5, 0.5),
              opacity: color && color[3],
            });
          }
        });
      }
    }
  }, [materials, materialProperties, textureSrcs]);

  if (!textureLoaded)
    return (
      <Html center>
        <div>
          <Spin />
          Loading Model...
        </div>
      </Html>
    );

  return (
    <group {...props} scale={0.5}>
      <primitive object={scene} />
    </group>
  );
};

export default React.memo(ThreeModel);
