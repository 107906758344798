import { Space, Tag, Typography } from 'antd';

import { ListProductLibraryModel } from '../../../../../models/product-library';

import './HeaderSectionLeft.less';

interface HeaderSectionLeftProps {
  productItem: ListProductLibraryModel | null;
}

const { Title } = Typography;

const HeaderSectionLeft = ({ productItem }: HeaderSectionLeftProps) => {
  const renderTag = (status?: string) => {
    switch (status) {
      case 'approved':
        return (
          <Tag color="green" className="product-tag">
            Approved
          </Tag>
        );
      case 'rejected':
        return (
          <Tag color="red" className="product-tag">
            Rejected
          </Tag>
        );
      case 'needs_approval':
        return (
          <Tag color="orange" className="product-tag">
            Needs Approval
          </Tag>
        );
      case 'unknown':
        return (
          <Tag color="purple" className="product-tag">
            Unknown
          </Tag>
        );
    }
  };

  return (
    <Space align="center">
      <Title level={4}>
        {productItem?.upc ? productItem?.upc + ' UPC' : 'No UPC'}
      </Title>
      {renderTag(productItem?.status)}
    </Space>
  );
};

export default HeaderSectionLeft;
