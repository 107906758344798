import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import MetaDataService from '../../../api/meta-data';
import { CustomFieldModel, CustomFieldOption } from '../../../models/meta-data';
import { convertParamsToQuery } from '../../../util';

export interface MetaDataState {
  value: CustomFieldOption | null;
  customField: CustomFieldModel | null;
  allCustomFieldOptions: CustomFieldOption[];
  allCustomFieldOptionsValue: CustomFieldOption[];
  fetchingCustomFieldOptions: boolean;
  searchingCustomFieldOptions: boolean;
  savingCustomFieldOption: boolean;
  deletingCustomFieldOption: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  count: number;
  current: number;
}

interface CreateCustomFieldOptionProps {
  customFieldKey: string;
  customFieldOption: CustomFieldOption;
}

interface UpdateCustomFieldOptionProps {
  customFieldKey: string;
  optionId: number;
  customFieldOption: CustomFieldOption;
}
interface DeleteCustomFieldOptionProps {
  customFieldKey: string;
  optionId: number;
}

const initialState: MetaDataState = {
  value: null,
  customField: null,
  allCustomFieldOptions: [],
  allCustomFieldOptionsValue: [],
  fetchingCustomFieldOptions: false,
  searchingCustomFieldOptions: false,
  savingCustomFieldOption: false,
  deletingCustomFieldOption: false,
  error: { code: null, message: null },
  count: 0,
  current: 1,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
};
export interface CustomFieldOptionsParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
  status?: string;
}

interface searchCustomFieldOptionsByNameProps {
  customFieldKey: string;
  query: string;
}

interface fetchCustomFieldOptionsProps {
  customFieldKey: string;
  params?: CustomFieldOptionsParams;
}

export const fetchCustomFieldOptionsByKey = createAsyncThunk(
  'metaData/fetchCustomFieldOptionsByKey',
  async (
    { customFieldKey }: fetchCustomFieldOptionsProps,
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      const nextParams: CustomFieldOptionsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      const queryString = convertParamsToQuery(nextParams);
      let resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        queryString
      );

      const { limit, count, offset } = resp.pagination;
      let current = offset / limit + 1;
      if (offset >= count && count > 0) {
        current = current - 1;
        const nextParams = {
          _limit: limit,
          _offset: offset - limit,
          _order_by: 'created_at:desc',
        };

        const queryString = convertParamsToQuery(nextParams);
        resp = await MetaDataService().listCustomFieldOptionsByKey(
          customFieldKey,
          queryString
        );
      }

      return {
        current,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createCustomFieldOption = createAsyncThunk(
  'metaData/createCustomFieldOption',
  async (
    { customFieldKey, customFieldOption }: CreateCustomFieldOptionProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().createCustomFieldOption(
        customFieldKey,
        customFieldOption
      );

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateCustomFieldOption = createAsyncThunk(
  'metaData/updateCustomFieldOption',
  async (
    {
      customFieldKey,
      optionId,
      customFieldOption,
    }: UpdateCustomFieldOptionProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().updateCustomFieldOption(
        customFieldKey,
        optionId,
        customFieldOption
      );

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomField = createAsyncThunk(
  'metaData/fetchCustomField',
  async (customFieldKey: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await MetaDataService().getCustomField(customFieldKey);

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomFieldOption = createAsyncThunk(
  'metaData/deleteCustomFieldOption',
  async (
    { customFieldKey, optionId }: DeleteCustomFieldOptionProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await MetaDataService().deleteCustomFieldOption(
        customFieldKey,
        optionId
      );

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return resp.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const searchCustomFieldOptionsByName = createAsyncThunk(
  'metaData/searchCustomFieldOptionsByName',
  async (
    { customFieldKey, query }: searchCustomFieldOptionsByNameProps,
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        metaData: { pagination },
      } = getState() as RootState;

      let params: CustomFieldOptionsParams = {
        _limit: pagination?.limit,
        _offset: pagination?.offset,
        _order_by: 'created_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingCustomFieldsMore: false,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const paginateCustomFieldOptions = createAsyncThunk(
  'productList/paginateCustomFieldOptions',
  async (
    {
      customFieldKey,
      page,
      pageSize,
      queryStr,
    }: {
      customFieldKey: string;
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: CustomFieldOptionsParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await MetaDataService().listCustomFieldOptionsByKey(
        customFieldKey,
        query
      );

      return {
        current: page,
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const metaDataSlice = createSlice({
  name: 'metaData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomField.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(fetchCustomField.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.customField = action.payload;
      })
      .addCase(fetchCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.savingCustomFieldOption = false;
      })

      .addCase(updateCustomFieldOption.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(updateCustomFieldOption.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.value = action.payload;
      })
      .addCase(updateCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomFieldOption = false;
      })

      .addCase(deleteCustomFieldOption.pending, (state) => {
        state.deletingCustomFieldOption = true;
      })

      .addCase(deleteCustomFieldOption.fulfilled, (state) => {
        state.deletingCustomFieldOption = false;
      })

      .addCase(deleteCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.deletingCustomFieldOption = false;
      })

      .addCase(createCustomFieldOption.pending, (state) => {
        state.savingCustomFieldOption = true;
      })
      .addCase(createCustomFieldOption.fulfilled, (state, action) => {
        state.savingCustomFieldOption = false;
        state.value = action.payload;
      })
      .addCase(createCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomFieldOption = false;
      })

      .addCase(fetchCustomFieldOptionsByKey.pending, (state) => {
        state.fetchingCustomFieldOptions = true;
      })
      .addCase(fetchCustomFieldOptionsByKey.fulfilled, (state, action) => {
        state.fetchingCustomFieldOptions = false;
        if (action.payload) {
          state.count = action.payload.pagination.count;
          state.current = action.payload.current;
          state.allCustomFieldOptionsValue = action.payload.data;
          state.allCustomFieldOptions = action.payload.data;
          state.pagination = action.payload.pagination;
        }
      })
      // REJECTED
      .addCase(fetchCustomFieldOptionsByKey.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
        state.searchingCustomFieldOptions = false;
      })

      .addCase(searchCustomFieldOptionsByName.pending, (state) => {
        state.searchingCustomFieldOptions = true;
      })
      .addCase(searchCustomFieldOptionsByName.fulfilled, (state, action) => {
        if (action.payload) {
          state.count = action.payload.pagination.count;
          state.fetchingCustomFieldOptions = false;
          state.searchingCustomFieldOptions = false;
          state.allCustomFieldOptionsValue = action.payload.data;
          state.pagination = action.payload.pagination;
          state.current = 1;
        }
      })
      .addCase(searchCustomFieldOptionsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
        state.searchingCustomFieldOptions = false;
      })

      .addCase(paginateCustomFieldOptions.pending, (state) => {
        state.fetchingCustomFieldOptions = true;
      })
      .addCase(paginateCustomFieldOptions.fulfilled, (state, action) => {
        state.fetchingCustomFieldOptions = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.allCustomFieldOptionsValue = [...action.payload.data];
          state.fetchingCustomFieldOptions = false;
          state.current = action.payload.current;
        }
      })
      .addCase(paginateCustomFieldOptions.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFieldOptions = false;
      });
  },
});

export default metaDataSlice.reducer;
