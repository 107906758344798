import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Modal, Result } from 'antd';

import OnlineVirtualResearchService from '../../../../../api/online-virtual-research';
import {
  ListOvrProjectModel,
  OvrProjectModel,
} from '../../../../../models/online-virtual-research';
import { getMe, propsAreEqual } from '../../../../../util';
import Button from '../../../../elements/Button';

interface ExportRespondentDataModalProps {
  visible: boolean;
  project: ListOvrProjectModel | OvrProjectModel;
  onClose: () => void;
}

const ExportRespondentDataModal: FC<ExportRespondentDataModalProps> = (
  props
) => {
  const { visible, onClose, project } = props;

  const [hasAttemptedExport, setHasAttemptedExport] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const exportResult = useRef<ReactNode>();
  const me = useMemo(() => getMe(), []);

  const handleClose = useCallback(() => {
    setHasAttemptedExport(false);
    exportResult.current = null;
    onClose();
  }, [onClose]);

  const successResult: JSX.Element = useMemo(
    () => (
      <Result
        style={{ padding: 0 }}
        status="success"
        title="Project submitted successfully!"
        subTitle={`Please allow up to 1 hour for processing. Once completed, you will receive an email to ${me.email} with a link to your download.`}
        extra={
          <Button variant="success" onClick={handleClose}>
            Okay, great!
          </Button>
        }
      />
    ),
    [handleClose, me.email]
  );

  const errorResult: JSX.Element = useMemo(
    () => (
      <Result
        status="error"
        title="It seems an error occurred trying to export respondent data."
        subTitle="Please try again. We apologize for any inconvenience."
        extra={
          <Button type="primary" danger onClick={handleClose}>
            Okay, will do!
          </Button>
        }
      />
    ),
    [handleClose]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const exportResultsMap = {
    success: successResult,
    error: errorResult,
  };

  const showExportResult = useCallback(
    (result: 'success' | 'error') => {
      exportResult.current = exportResultsMap[result];
      setHasAttemptedExport(true);
    },
    [exportResultsMap]
  );

  const onSuccess = useCallback(() => {
    setIsLoading(false);
    showExportResult('success');
  }, [showExportResult]);

  const onError = useCallback(() => {
    setIsLoading(false);
    showExportResult('error');
  }, [showExportResult]);

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    OnlineVirtualResearchService()
      .exportOVRProjectData(project.uuid)
      .then(onSuccess)
      .catch(onError);
  }, [onError, onSuccess, project?.uuid]);

  return (
    <Modal
      visible={visible}
      title={
        !hasAttemptedExport && `Export Respondent Data for '${project?.name!}'`
      }
      onOk={onSubmit}
      onCancel={handleClose}
      okText="Begin Export"
      footer={hasAttemptedExport ? null : undefined}
      closable={!hasAttemptedExport}
      okButtonProps={{ loading: isLoading }}
    >
      {hasAttemptedExport ? (
        exportResult.current
      ) : (
        <>
          <p>
            {`Exports usually take a few minutes to be generated. Once completed, you will receive an email at ${me.email} with a link to your download.`}
          </p>
          <p>
            Please note some larger projects may take up to an hour to complete.
          </p>
        </>
      )}
    </Modal>
  );
};

export default memo(ExportRespondentDataModal, propsAreEqual);
