export const isTabletScreenSizeOrSmaller = (): boolean => {
  return window.innerWidth <= 768;
};

export const isMobileScreenSize = (): boolean => {
  return window.innerWidth <= 600;
};

export const enterFullScreenMode = (element: any) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
    return;
  }
  if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
    return;
  }
  if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
    return;
  }
  if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
    return;
  }

  throw new Error('unsupported browser for entering fullscreen mode');
};

export const isBrowserZoomLevelScaled = () => {
  const screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
  return screenCssPixelRatio > 0.993 && screenCssPixelRatio < 0.996;
};
