import { useEffect, useMemo, useState } from 'react';

import { ZoomInOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { Link, useParams } from 'react-router-dom';

import imagePlaceholder from '../../../../../assets/images/product-image-placeholder.png';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { fetchProductItemMediaFilesAndImageUrl } from '../../../../../store/features/productItem/productItemSlice';
import './ImageSelection.less';
import ResponsiveSVG from './ResponsiveSVG/ResponsiveSGV';
import ThreeModel from './ThreeModel/ThreeModel';
import ThreeScene from './ThreeScene';
import Thumbnail from './Thumbnail';

const ImageSelection = () => {
  const params = useParams<{ id: string; selectedImage: string }>();
  const { id } = params;

  const [selectedImage, setSelectedImage] = useState<string>(imagePlaceholder);
  const [modelView, setModelView] = useState(true);

  const [modelSrc, setModelSrc] = useState<string | undefined>('');
  const [modelRSS, setModelRSS] = useState<string | undefined>('');
  const [textureSrcs, setTextureSrcs] = useState<any>([]);
  const [thumbnailImagesSrc, setThumbnailImagesSrc] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { mediaFiles, imageUrl } = useAppSelector((state) => state.productItem);

  useEffect(() => {
    dispatch(fetchProductItemMediaFilesAndImageUrl(id));
  }, [dispatch, id]);

  const computedTextureSrcs = useMemo(() => {
    const allTextures = mediaFiles?.filter((mediaFile) =>
      mediaFile?.filename?.includes('tex')
    );
    return allTextures?.map((texture) => texture.media_url);
  }, [mediaFiles]);

  useEffect(() => {
    const sortImages = (allImages: string[]) => {
      const validImageSuffixes = [
        '_1.png',
        '_2.png',
        '_3.png',
        '_7.png',
        '_8.png',
        '_9.png',
      ];

      return allImages
        .filter((url) => {
          const parts = url?.split('_');
          const suffix = parts[1]?.split('.')[0];
          return (
            parts.length === 2 &&
            !isNaN(parseInt(suffix)) &&
            validImageSuffixes.includes(`_${suffix}.png`)
          );
        })
        .sort((a, b) => {
          const aNum = parseInt(a.split('_')[1]?.split('.')[0]);
          const bNum = parseInt(b.split('_')[1]?.split('.')[0]);
          const order = [1, 7, 8, 2, 3, 9];
          return order.indexOf(aNum) - order.indexOf(bNum);
        });
    };

    // get model images
    const allImages = mediaFiles
      ?.filter((mediaFile) => mediaFile.type === 'png')
      .map((fileObj) => fileObj.media_url);

    if (allImages) {
      const sorted = sortImages(allImages);
      setThumbnailImagesSrc(sorted);
    }

    const model = mediaFiles?.find(
      (mediaFile) => mediaFile.type === 'gltf'
    )?.media_url;

    setModelSrc(model);

    // get model textures
    setTextureSrcs(computedTextureSrcs);

    // get model RSS
    const rss = mediaFiles?.find(
      (mediaFile) => mediaFile.type === 'rss'
    )?.media_url;

    setModelRSS(rss);
  }, [computedTextureSrcs, mediaFiles]);

  useEffect(() => {
    setModelView(true);
    setModelView(false);
    setTimeout(() => {
      setModelView(true);
    }, 1000);
  }, []);

  return (
    <div className="image-selection">
      <div className="current-image-wrapper">
        <div className="current-image">
          {modelSrc && modelView ? (
            <ThreeScene
              model={
                <ThreeModel
                  modelSrc={modelSrc}
                  modelRSS={modelRSS}
                  textureSrcs={textureSrcs}
                />
              }
            />
          ) : (
            <Link to={{ pathname: `${id}/image`, state: { selectedImage } }}>
              <Image
                src={selectedImage ? selectedImage : imageUrl}
                preview={false}
                style={{
                  width: '100%',
                  objectFit: 'contain',
                  maxHeight: '300px',
                }}
              />
            </Link>
          )}
        </div>
        {modelView ? null : (
          <Link
            to={{ pathname: `${id}/image`, state: { selectedImage } }}
            className="magnifying-glass"
          >
            <ZoomInOutlined
              style={{
                fontSize: '1.5rem',
                color: '#becada',
              }}
            />
          </Link>
        )}
      </div>
      <div className="thumbnail-selection">
        <div
          className={`${
            modelView ? 'three-d-thumbnail-active' : 'three-d-thumbnail'
          }`}
          onClick={() => {
            setModelView(true);
          }}
        >
          <ResponsiveSVG />
        </div>

        {thumbnailImagesSrc?.length === 0
          ? Array.from({ length: 6 }, (_, index) => (
              <Thumbnail
                key={index}
                thumbnailSrc={imagePlaceholder}
                setSelectedImage={setSelectedImage}
                setThreeDView={setModelView}
              />
            ))
          : thumbnailImagesSrc?.map((thumbnailSrc: string, index) => (
              <Thumbnail
                key={index}
                thumbnailSrc={thumbnailSrc}
                setSelectedImage={setSelectedImage}
                setThreeDView={setModelView}
              />
            ))}
      </div>
    </div>
  );
};

export default ImageSelection;
