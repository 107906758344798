import { BrandModel } from '../brand';
import { ParentCompanyModel } from '../parent-company';

export interface ManufacturerModel {
  key?: string;
  name: string;
  status?: string | null;
  short_name?: string;
  client_type: string;
  statusCheckValue?: boolean | undefined;
  in_processing: number;
  in_library: number;
  in_use?: boolean;
  uuid: string;
  parent?: ParentCompanyModel;
  brands?: BrandModel[];
  parent_company?: string | false;
}

export const initialNewManufacturerModel = {
  name: '',
};
