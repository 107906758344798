import { FC, memo } from 'react';

import './Button.less';
import { Button as AntdButton } from 'antd';
import { BaseButtonProps, NativeButtonProps } from 'antd/lib/button/button';
import cx from 'classnames';

import { propsAreEqual } from '../../../util';

/**
 * Customized Button to match ReadySet design. Either pass a variant or a type, not both.
 **/

type ButtonVariants = 'success' | 'warning' | undefined;

interface ButtonProps extends BaseButtonProps, NativeButtonProps {
  variant?: ButtonVariants;
}

const Button: FC<ButtonProps> = (props) => {
  const { children, variant } = props;

  const buttonClass = cx('button', variant);

  return (
    <AntdButton className={buttonClass} {...props}>
      {children}
    </AntdButton>
  );
};

Button.defaultProps = {
  variant: undefined,
  onClick: undefined,
  style: undefined,
};

export default memo(Button, propsAreEqual);
