import { Typography } from 'antd';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

import { ListProductLibraryModel } from '../../../../../models/product-library';
import { renderField } from '../Row';

import './Status.less';

const { Title } = Typography;

interface PackageInfoProps {
  productItem: ListProductLibraryModel | undefined;
}

interface ModelInfo {
  quality: string | null;
  template: string | null;
  poly_count: number | null;
  smoothness_opaq: string | null;
  smoothness_tran: string | null;
}

const Status = ({ productItem }: PackageInfoProps) => {
  const [modelInfo, setModelInfo] = useState<ModelInfo | null>(null);

  const extractModelInfo = (jsonContent: string) => {
    try {
      const data = JSON.parse(jsonContent);
      setModelInfo({
        quality: data.quality || null,
        template: data.template || null,
        poly_count: data.poly_count || null,
        smoothness_opaq: data.smoothness_opaq || null,
        smoothness_tran: data.smoothness_tran || null,
      });
    } catch (error) {
      console.error('Error parsing JSON: ', error);
      setModelInfo(null);
    }
  };

  const extractQualityFromCsv = (csvContent: string) => {
    Papa.parse(csvContent, {
      header: true,
      complete: (results) => {
        const data = results.data as any[];
        if (data.length > 0) {
          setModelInfo((prevState) => ({
            quality: data[0]['quality'] || null,
            template: prevState?.template ?? null,
            poly_count: prevState?.poly_count ?? null,
            smoothness_opaq: prevState?.smoothness_opaq ?? null,
            smoothness_tran: prevState?.smoothness_tran ?? null,
          }));
        }
      },
      error: (error: any) => {
        console.error('Error parsing CSV: ', error);
        setModelInfo((prevState) => ({
          quality: null,
          template: prevState?.template ?? null,
          poly_count: prevState?.poly_count ?? null,
          smoothness_opaq: prevState?.smoothness_opaq ?? null,
          smoothness_tran: prevState?.smoothness_tran ?? null,
        }));
      },
    });
  };

  useEffect(() => {
    const loadJsonFile = async () => {
      const jsonFile = productItem?.media_files?.find((file) =>
        file.filename.endsWith('info.json')
      );

      if (jsonFile) {
        try {
          const response = await fetch(jsonFile.media_url);
          const jsonContent = await response.text();
          extractModelInfo(jsonContent);
        } catch (error) {
          console.error('Error loading JSON file: ', error);
          setModelInfo(null);
        }
      } else {
        // Fall back to CSV if JSON is not available
        loadCsvFile();
      }
    };

    const loadCsvFile = async () => {
      const mediaFile = productItem?.media_files?.find((file) =>
        file.filename.endsWith('.csv')
      );

      if (mediaFile) {
        try {
          const response = await fetch(mediaFile.media_url);
          const csvContent = await response.text();
          extractQualityFromCsv(csvContent);
        } catch (error) {
          console.error('Error loading CSV file: ', error);
          setModelInfo((prevState) => ({
            quality: null,
            template: prevState?.template ?? null,
            poly_count: prevState?.poly_count ?? null,
            smoothness_opaq: prevState?.smoothness_opaq ?? null,
            smoothness_tran: prevState?.smoothness_tran ?? null,
          }));
        }
      }
    };

    if (productItem) {
      loadJsonFile();
    }
  }, [productItem]);

  return (
    <div className="status">
      <Title className="title" level={4}>
        Status
      </Title>
      <div className="row-container">
        <div style={{ marginRight: '4rem' }}>
          {modelInfo?.quality ? (
            <>
              <p className="quality-label">Quality</p>
              <p className="quality-value">{modelInfo.quality}</p>
            </>
          ) : (
            productItem && renderField(productItem, 'old_quality')
          )}
        </div>
        <div className="row">
          {productItem && renderField(productItem, 'old_modified_date')}
        </div>
        <div className="row">
          {productItem && renderField(productItem, 'reuploaded')}
        </div>
      </div>
      {modelInfo && (
        <div className="model-info">
          <div className="row">
            <div className="row-item">
              <p className="row-label">Template</p>
              <p className="row-item-data">{modelInfo.template}</p>
            </div>
            <div className="row-item">
              <p className="row-label">Smoothness Opaque</p>
              <p className="row-item-data">{modelInfo.smoothness_opaq}</p>
            </div>
          </div>
          <div className="row">
            <div className="row-item">
              <p className="row-label">Poly Count</p>
              <p className="row-item-data">{modelInfo.poly_count}</p>
            </div>

            <div className="row-item">
              <p className="row-label">Smoothness Transparent</p>
              <p className="row-item-data">{modelInfo.smoothness_tran}</p>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        {productItem && renderField(productItem, 'old_vr_notes')}
      </div>
    </div>
  );
};

export default Status;
