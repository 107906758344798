import DownloadItemRenderer from './DownloadItemRenderer';
import { CronJobModel } from '../../../models/cron-job';
import { useAppDispatch } from '../../../store';
import { cancelOrDeleteDownload } from '../../../store/features/modelDownload/modelDownloadSlice';
import Spinner from '../../elements/Spinner/Spinner';

import './DownloadItem.less';

export interface DownloadItemProps {
  cronJob: CronJobModel;
}

const DownloadItem = ({ cronJob }: DownloadItemProps) => {
  const dispatch = useAppDispatch();
  const { result, status } = cronJob;

  const handleDownload = () => {
    const downloadLinkEl = document.createElement('a');
    downloadLinkEl.href = result?.download_url ? result?.download_url : '';
    downloadLinkEl.click();
    downloadLinkEl.remove();
  };

  // cancel or delete is determined by cron job status.
  const handleCancelOrDeleteDownload = () => {
    dispatch(cancelOrDeleteDownload({ cronJobId: cronJob?.job_id }));
  };

  return (
    <div style={{ maxWidth: '300px' }}>
      {status === 'completed' ? (
        <DownloadItemRenderer
          cronJob={cronJob}
          handleDeleteClick={handleCancelOrDeleteDownload}
          handleCancelClick={handleCancelOrDeleteDownload}
          handleDownloadClick={handleDownload}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default DownloadItem;
