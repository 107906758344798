import { FC, ReactNode, memo } from 'react';

import './FormWrapper.less';
import { CloseOutlined } from '@ant-design/icons';

import { propsAreEqual } from '../../../util';

interface FormWrapperProps {
  title: string;
  children: ReactNode;
  onClose: () => void;
}

const FormWrapper: FC<FormWrapperProps> = (props: FormWrapperProps) => {
  const { title, children, onClose } = props;

  return (
    <div className="form-wrapper">
      <div className="form-wrapper__header">
        <div className="form-wrapper__title">{title}</div>
        <div className="form-wrapper__actions">
          <CloseOutlined
            className="form-actions__close-icon"
            onClick={onClose}
          />
        </div>
      </div>

      <div className="form-wrapper__content">{children}</div>
    </div>
  );
};

export default memo(FormWrapper, propsAreEqual);
