import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

// Docs for 'format' argument here
// https://day.js.org/docs/en/display/format

export const relativeTimeFromNow = (date: string | Date): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).fromNow();
};

export const formatDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format = 'MMMM D, YYYY, h:mm A'
) => {
  return dayjs(date).format(format);
};
