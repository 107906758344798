import { memo, useCallback } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../../../../store';
import { generateUuidv4, propsAreEqual } from '../../../../../util';

import { Button } from 'antd';
import { CellGroupModel } from '../../../../../models/online-virtual-research';
import { updateCellGroupsLocally } from '../../../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import CellGroup from '../CellGroup/CellGroup';
import './CellGroupsList.less';

const CellGroupsList = () => {
  const dispatch = useAppDispatch();
  const { ovrProject } = useAppSelector((state) => state.ovrProjectDetails);
  const cellGroups = useAppSelector(
    (state) => state.ovrProjectDetails.ovrProject?.cellGroups
  );

  const handleAddCellGroup = useCallback(() => {
    if (ovrProject && cellGroups) {
      const newCellGroup = {
        group_id: ovrProject?.cellGroups?.length,
        projectCells: [
          {
            group_id: ovrProject?.cellGroups?.length,
            cell: undefined,
            message: '',
            startLocation: undefined,
          },
        ],
      };

      dispatch(
        updateCellGroupsLocally([...ovrProject.cellGroups, newCellGroup])
      );
    }
  }, [cellGroups, dispatch, ovrProject]);

  const hasImportedCell = cellGroups?.some(
    (group) => group.group_id === 0 && group.projectCells?.length > 0
  );

  if (!cellGroups || !hasImportedCell) {
    return null;
  } else {
    return (
      <div className="cell-groups-container">
        {cellGroups?.length >= 1 ? (
          <div className="cell-groups-list">
            {cellGroups?.map((cellGroup: CellGroupModel, index: number) => {
              return cellGroup.group_id === 0 ? null : (
                <CellGroup
                  key={`cell-group-${generateUuidv4()}`}
                  cellGroup={cellGroup as CellGroupModel}
                  cellGroupIndex={index}
                />
              );
            })}
          </div>
        ) : null}

        {hasImportedCell && (
          <Button
            type="dashed"
            className={`add-cell-group-btn ${
              cellGroups.length >= 1 ? 'mt' : ''
            }`}
            onClick={handleAddCellGroup}
          >
            Add Cell Group <PlusOutlined />
          </Button>
        )}
      </div>
    );
  }
};

export default memo(CellGroupsList, propsAreEqual);
