import { memo, useCallback, useEffect, useState } from 'react';

import {
  CheckCircleFilled,
  CheckCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Form, Input, Select, Space, Spin, message } from 'antd';
import { History } from 'history';
import { Link, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { useFormData } from '../../../../hooks/editable-information-form';
import { BrandModel } from '../../../../models/brand';
import { CustomFieldOption } from '../../../../models/custom-field';
import {
  ListProductLibraryModel,
  ProductCategoryModel,
  ProductStatusEnum,
} from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  fetchBrands,
  loadMoreBrands,
} from '../../../../store/features/brand/brandSlice';
import { bulkCreateCustomFieldOptions } from '../../../../store/features/customFields/customFieldsSlice';
import {
  fetchProductItem,
  removeCategoryFromProduct,
  removeDrcFromProduct,
  setUpdatingProductId,
  updateProductItem,
} from '../../../../store/features/productItem/productItemSlice';
import {
  removeProductUPC,
  resetProductUPCs,
  searchProductsByUPC,
  updateProduct,
} from '../../../../store/features/productLibrary/productLibrarySlice';
import { capitalizeFirstWord, debounce, propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import MultiLevelSelect, {
  SelectDataItem,
} from '../../../elements/MultiLevelSelect/MultiLevelSelect';
import './EditableInformationForm.less';
import {
  checkIfTagExists,
  convertToSnakeCase,
  determineOldDrcValue,
  extractBrandId,
  fetchCategoryFilters,
  fetchFieldOptions,
  fetchResource,
  findFieldValue,
} from './helpers';
import { createDropdownVisibleChangeHandler } from './withDataFetching';

const { Option } = Select;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

interface EditableInformationFormProps {
  productItem: ListProductLibraryModel;
  history: History;
  setDisplayEditableForm: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
}

interface FormProps {
  name: string;
  brand_id: { key: string; label: string };
  categories: ProductCategoryModel;
  checkboxGroup: string[];
  old_count: string;
  count_unit: string;
  format: number;
  market: number;
  old_size: string;
  size_unit: string;
  tags: string[];
  drcChecked: boolean;
  master_librarian_notes: string;
  item_upcs: string[] | [];
}

interface CreateNewTagsProps {
  name: string;
  option_key?: string;
  visible?: 1;
  status?: string;
  parent_id?: string | null;
}
interface FormFieldsStateProps {
  categoryFilters: SelectDataItem[] | null;
  formats: CustomFieldOption[] | null;
  markets: CustomFieldOption[] | null;
  tagOptions: CustomFieldOption[] | null;
  countUnits: CustomFieldOption[] | null;
  sizeUnits: CustomFieldOption[] | null;
}

interface SearchMadeState {
  [key: number]: boolean;
}

const EditableInformationForm = ({
  productItem,
  setDisplayEditableForm,
}: EditableInformationFormProps) => {
  const [categoriesToAdd, setCategoriesToAdd] = useState<string[]>([]);
  const [categoriesToRemove, setCategoriesToRemove] = useState<string[]>([]);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const [drcsToAdd, setDrcsToAdd] = useState<string[]>([]);
  const [drcsToRemove, setDrcsToRemove] = useState<string[]>([]);
  const [drcSearchingKey, setDrcSearchingKey] = useState<number | null>(null);
  const [searchMade, setSearchMade] = useState<SearchMadeState>({});
  const [drcChecked, setDrcChecked] = useState(
    productItem?.drc?.length > 0 || false
  );

  const [creatingTags, setCreatingTags] = useState(false);
  const [tagsToRemove, setTagsToRemove] = useState<string[]>([]);

  const [loadingFormInput, setLoadingFormInput] = useState(false);
  const initialState: FormFieldsStateProps = {
    categoryFilters: null,
    formats: null,
    markets: null,
    tagOptions: null,
    countUnits: null,
    sizeUnits: null,
  };

  const [formFieldsState, setFormFieldsState] =
    useState<FormFieldsStateProps>(initialState);
  const dispatch = useAppDispatch();
  const { hasMoreBrands } = useFormData();
  const { value: brands, fetchingBrands } = useAppSelector(
    (state) => state.brand
  );

  const { productUPCs, savingProductLibrary, fetchingProductLibraryItems } =
    useAppSelector((state) => state.productLibrary);
  const { categories, updatingProductId } = useAppSelector(
    (state) => state.productItem
  );
  const { customFieldOptions: createdTags } = useAppSelector(
    (state) => state.customFields
  );
  const [form] = Form.useForm();
  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const getProduct = useCallback(
    (productId: string) => {
      dispatch(fetchProductItem(productId));
    },
    [dispatch]
  );

  const productTags = useCallback(() => {
    const productTags = productItem?.tags
      .filter((tag) => tag.set === 1)
      .map((tag) => tag.name);

    return productTags;
  }, [productItem]);

  // fetch form input data
  useEffect(() => {
    const fetchFormInputData = async () => {
      setLoadingFormInput(true);
      try {
        const [categoryFilters, formats, markets, countUnits, sizeUnits] =
          await Promise.all([
            fetchCategoryFilters(),
            fetchResource('format'),
            fetchResource('market'),
            fetchResource('count_unit'),
            fetchResource('size_unit'),
          ]);

        const filteredApprovedMarket =
          markets?.filter((market) => market.status === 'approved') || [];

        const filteredApprovedFormat =
          formats?.filter((format) => format.status === 'approved') || [];

        const filteredApprovedCountUnits =
          countUnits?.filter((countUnit) => countUnit.status === 'approved') ||
          [];

        const filteredApprovedSizeUnits =
          sizeUnits?.filter((sizeUnit) => sizeUnit.status === 'approved') || [];

        setFormFieldsState((prevState) => ({
          ...prevState,
          categoryFilters,
          formats: filteredApprovedFormat,
          markets: filteredApprovedMarket,
          countUnits: filteredApprovedCountUnits,
          sizeUnits: filteredApprovedSizeUnits,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingFormInput(false);
      }
    };

    fetchFormInputData();
  }, []);

  // fetch tags
  useEffect(() => {
    const fetchTags = async () => {
      const fetchedTags = await fetchFieldOptions('tags');

      setFormFieldsState((prevState) => ({
        ...prevState,
        tagOptions: fetchedTags,
      }));
    };

    fetchTags();
  }, [creatingTags, tagsToRemove]);

  // set form initial values
  useEffect(() => {
    form.setFieldsValue({
      name: productItem?.name,
      brand_id: {
        key: productItem?.brand_id || '',
        label: productItem?.brand?.name || 'No Brand',
      },
      market: findFieldValue({
        productItem,
        key: 'market',
        defaultValue: null,
      }),
      format: findFieldValue({
        productItem,
        key: 'format',
        defaultValue: null,
      }),
      old_count: findFieldValue({
        productItem,
        key: 'old_count',
        defaultValue: '',
      }),
      count_unit: findFieldValue({
        productItem,
        key: 'count_unit',
        defaultValue: null,
      }),
      old_size: findFieldValue({
        productItem,
        key: 'old_size',
        defaultValue: '',
      }),
      size_unit: findFieldValue({
        productItem,
        key: 'size_unit',
        defaultValue: null,
      }),
      drcChecked: drcChecked,
      tags: productTags(),
      master_librarian_notes: findFieldValue({
        productItem,
        key: 'master_librarian_notes',
        defaultValue: '',
      }),
    });
    setInitialValuesSet(true);
  }, [drcChecked, form, productItem, productTags]);

  // CATEGORIES
  const updateCategoriesToAddList = useCallback(
    (category: SelectDataItem | 'All' = 'All') => {
      if (category !== 'All') {
        const categoryId = category?.key;
        const categoryInProduct = categories.find(
          (category) => category.uuid === categoryId
        );
        if (!categoryInProduct) {
          const categoryInToAddList = categoriesToAdd.find(
            (category) => category === categoryId
          );

          if (!categoryInToAddList) {
            setCategoriesToAdd((prevCategories) => [
              ...prevCategories,
              categoryId,
            ]);
          } else {
            console.log('category already in the to add list');
          }
        } else {
          console.log('category already part of the product');
        }
      }
      form.validateFields(['categories']);
    },
    []
  );

  const updateCategoryRemovalList = (fieldKey: any) => {
    const categoryToRemove = categories[fieldKey]?.uuid;
    if (categoryToRemove) {
      if (categoriesToRemove.includes(categoryToRemove)) {
        setCategoriesToAdd((prev) =>
          prev.filter((drc) => drc !== categoryToRemove)
        );
      } else if (!drcsToRemove.includes(categoryToRemove)) {
        setCategoriesToRemove((prev) => [...prev, categoryToRemove]);
      }
    }
  };

  const handleRemoveCategory = async (categoryId: string) => {
    dispatch(setUpdatingProductId(categoryId));
    await dispatch(
      removeCategoryFromProduct({ categoryId, productId: productItem?.uuid })
    );
    dispatch(setUpdatingProductId(null));
  };

  // TAGS
  const handleSelectTagChange = async (selectedTagOptions: string[]) => {
    const tagsToCreate = selectedTagOptions.filter((tag) => {
      return !checkIfTagExists({
        currentProductTags: formFieldsState?.tagOptions,
        submittedTag: tag,
      });
    });

    if (tagsToCreate?.length > 0) {
      await createTags(tagsToCreate);
    }

    removeTags(selectedTagOptions);
  };

  const createTags = async (tagsToCreate: string[]) => {
    setCreatingTags(true);

    try {
      const formattedTagsToCreate: CreateNewTagsProps[] = tagsToCreate.map(
        (tag) => {
          return { name: tag, status: 'approved' };
        }
      );

      await dispatch(
        bulkCreateCustomFieldOptions({
          customFieldKey: 'tags',
          bulkCustomFieldOptions: formattedTagsToCreate,
        })
      );
    } catch (error) {
      onError(error as ApiError);
    } finally {
      setCreatingTags(false);
    }
  };

  const removeTags = (selectedTagOptions: string[]) => {
    const tagOptionsDefined =
      formFieldsState?.tagOptions && formFieldsState?.tagOptions?.length > 0;

    if (tagOptionsDefined) {
      const allTagOptions = formFieldsState?.tagOptions;
      const currentProductTags = productTags();

      const tagNamesAndOptionKeys = allTagOptions?.flatMap((option) => [
        option.name,
        option.option_key.toLowerCase(),
      ]);

      const tagsToRemove = currentProductTags.filter((productTag) => {
        const isTagNotSelected = !selectedTagOptions.some((selectedTag) => {
          const selectedTagLower = selectedTag.toLowerCase();
          return (
            productTag === selectedTag ||
            tagNamesAndOptionKeys?.includes(selectedTagLower)
          );
        });
        return isTagNotSelected;
      });

      const tagsToRemoveFormatted = convertToSnakeCase(tagsToRemove);
      setTagsToRemove(tagsToRemoveFormatted);
    }
  };

  // DRC
  const handleRemoveDrc = async (drcId: string) => {
    dispatch(setUpdatingProductId(drcId));
    await dispatch(
      removeDrcFromProduct({ drcId, productId: productItem?.uuid })
    );
    dispatch(setUpdatingProductId(null));
  };

  const handleAddDrc = (fieldKey: number) => {
    const drcToAdd = productUPCs[fieldKey]?.uuid;
    if (drcToAdd) {
      if (drcsToRemove.includes(drcToAdd)) {
        setDrcsToRemove((prev) => prev.filter((drc) => drc !== drcToAdd));
      } else if (!drcsToAdd.includes(drcToAdd)) {
        setDrcsToAdd((prev) => [...prev, drcToAdd]);
      }
    }
  };

  const updateDrcRemovalList = (fieldKey: number) => {
    const drcToRemove = productUPCs[fieldKey]?.uuid;
    if (drcToRemove) {
      if (drcsToAdd.includes(drcToRemove)) {
        setDrcsToAdd((prev) => prev.filter((drc) => drc !== drcToRemove));
      } else if (!drcsToRemove.includes(drcToRemove)) {
        setDrcsToRemove((prev) => [...prev, drcToRemove]);
      }
    }
  };

  const saveProduct = async (formData: FormProps) => {
    const {
      name,
      brand_id,
      format,
      market,
      old_count,
      count_unit,
      old_size,
      size_unit,
      drcChecked,
      tags,
      master_librarian_notes,
    } = formData;

    let updatedInformation: any = {};
    let fields = {};

    fields = {
      market,
      format,
      old_count,
      old_size,
      size_unit,
      count_unit,
      master_librarian_notes,
    };

    const newTagsToAdd = createdTags.map((tag) => tag.option_key);
    const tagsToAdd = [...tags, ...newTagsToAdd];

    const brandId = extractBrandId(brand_id);
    updatedInformation = {
      name,
      status: ProductStatusEnum.approved,
      categories_set: categoriesToAdd,
      // categories_delete: categoriesToRemove || [],
      fields,
      old_drc: determineOldDrcValue({
        drcChecked,
        drcItems: productItem.drc,
      }),
      tags_set: tagsToAdd || [],
      tags_delete: tagsToRemove || [],
      drc_set: drcsToAdd || [],
      drc_delete: drcsToRemove || [],
    };

    if (brandId !== null) {
      updatedInformation.brand_id = brandId;
    }

    const res = await dispatch(
      updateProduct({
        productId: productItem?.uuid,
        product: updatedInformation,
      })
    );

    if (res.error) {
      onError({ error: res.payload.error });
    } else {
      message.success('Product saved.');
      setDisplayEditableForm(false);
      dispatch(resetProductUPCs());
      getProduct(productItem?.uuid);
    }
  };

  const handleReject = useCallback(
    async (product: ListProductLibraryModel) => {
      try {
        const updatedProduct = { status: ProductStatusEnum.rejected };
        await dispatch(
          updateProductItem({
            productId: product.uuid,
            product: updatedProduct,
          })
        );
        await dispatch(resetProductUPCs());
        setDisplayEditableForm(false);
        getProduct(productItem?.uuid);
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [dispatch, getProduct, onError, productItem?.uuid, setDisplayEditableForm]
  );

  const handleItemSearch = useCallback(
    (query = '', fieldKey: number) => {
      setDrcSearchingKey(fieldKey);
      setSearchMade((prev) => ({ ...prev, [fieldKey]: true }));

      if (query.length >= 10) {
        debounce(async () => {
          await dispatch(
            searchProductsByUPC({
              query,
              fieldKey,
            })
          );
        }, 500)();
      } else if (productUPCs[fieldKey]) {
        dispatch(removeProductUPC({ fieldKey, action: 'UNSET' }));
      }
    },
    [dispatch, productUPCs]
  );

  const onSubmit = (values: FormProps) => {
    form.validateFields().then(() => saveProduct(values));
  };

  const handleBrandScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      dispatch(
        loadMoreBrands({
          hasMoreBrands,
        })
      );
    }
  };

  const handleBrandsDropdownChange = createDropdownVisibleChangeHandler(() =>
    dispatch(
      fetchBrands({
        params: {
          _limit: 20,
          _order_by: 'updated_at:desc',
          _offset: 0,
          status: 'approved',
        },
      })
    )
  );

  const handleFormClose = () => {
    setDisplayEditableForm(false);
    dispatch(resetProductUPCs());
  };

  return (
    <FormWrapper title="Editable Information" onClose={handleFormClose}>
      {!loadingFormInput ? (
        <Form
          requiredMark
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{ item_upcs: [] }}
          onFinishFailed={(errorInfo) =>
            console.error('Submission failed:', errorInfo)
          }
        >
          <Form.Item
            label="Item Name"
            name="name"
            rules={[{ required: true, message: 'Item name is required.' }]}
          >
            <Input placeholder="Item Name" />
          </Form.Item>
          <Space direction="vertical" style={{ width: '100%' }}>
            {initialValuesSet && (
              <>
                {productItem?.categories &&
                productItem?.categories?.length > 0 ? (
                  <div className="existing-categories">
                    <div className="category-title">Exisiting Categories</div>

                    {productItem.categories.map((category) => {
                      return (
                        <div className="category-item">
                          {updatingProductId === category.uuid ? (
                            <p className="category-item-text">Removing...</p>
                          ) : (
                            <p className="category-item-text">
                              {category.name}
                            </p>
                          )}
                          {updatingProductId === category.uuid ? (
                            <Spin indicator={antSpinIcon} />
                          ) : (
                            <MinusCircleOutlined
                              onClick={() => {
                                if (!updatingProductId) {
                                  handleRemoveCategory(category.uuid);
                                }
                              }}
                              style={{
                                color: updatingProductId ? 'grey' : 'inherit',
                                cursor: updatingProductId
                                  ? 'not-allowed'
                                  : 'pointer',
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <Form.List
                  name="categories"
                  rules={[
                    {
                      validator: async (_, categories) => {
                        if (productItem?.categories?.length > 0) {
                          return Promise.resolve();
                        } else if (!categories || categories?.length < 1) {
                          return Promise.reject(
                            new Error('Add at least 1 category')
                          );
                        } else if (categoriesToAdd?.length < 1) {
                          return Promise.reject(
                            new Error('Add at least 1 category')
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <div className="category-list">
                      {fields.map((field, index) => {
                        return (
                          <Form.Item {...field} required={true} key={field.key}>
                            <div className="category-item">
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                className="category-select"
                              >
                                <MultiLevelSelect
                                  selected={categoriesToAdd[index]}
                                  data={formFieldsState?.categoryFilters || []}
                                  loading={loadingFormInput}
                                  onChange={updateCategoriesToAddList}
                                />
                              </Form.Item>
                              {fields?.length > 0 ? (
                                <MinusCircleOutlined
                                  className="remove-category-btn"
                                  onClick={() => {
                                    remove(field.name);
                                    updateCategoryRemovalList(field?.key);
                                  }}
                                />
                              ) : null}
                            </div>
                          </Form.Item>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          className="add-category-btn"
                          icon={<PlusOutlined />}
                        >
                          Add another category
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </>
            )}

            <Space size="small">
              <Form.Item
                label="Brand"
                name="brand_id"
                className="form-item-select"
                rules={[{ required: true, message: 'Brand is required.' }]}
              >
                <Select
                  id="brand-select"
                  labelInValue
                  className="edit-form-selection"
                  placeholder="Select a brand"
                  onPopupScroll={handleBrandScroll}
                  onDropdownVisibleChange={handleBrandsDropdownChange}
                  loading={fetchingBrands}
                >
                  {brands?.length > 0 ? (
                    <>
                      {brands?.map((brand: BrandModel) => {
                        return (
                          <Option key={brand?.uuid} value={brand?.uuid}>
                            {capitalizeFirstWord(brand?.name)}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {brands?.length > 0 && fetchingBrands ? (
                        <Spin indicator={antSpinIcon} />
                      ) : null}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Space>
            <Space size="small">
              <Form.Item
                label="Market"
                name="market"
                className="form-item-select"
                rules={[{ required: true, message: 'Market is required.' }]}
              >
                <Select placeholder="Select a market">
                  {formFieldsState?.markets?.map((market) => {
                    return (
                      <Option key={market?.option_key} value={market?.id}>
                        {market?.name === 'Undefined'
                          ? 'All Markets'
                          : capitalizeFirstWord(market?.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
            <Space size="small">
              <Form.Item
                label="Format"
                name="format"
                className="form-item-select"
                rules={[{ required: true, message: 'Format is required.' }]}
              >
                <Select placeholder="Select a format">
                  {formFieldsState?.formats?.map((format) => {
                    return (
                      <Option key={format?.option_key} value={format?.id}>
                        {capitalizeFirstWord(format?.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
          </Space>
          <Space direction="vertical">
            <Space size="small" align="end">
              <Form.Item label="Count" name="old_count">
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item label="Count Unit of Measurement" name="count_unit">
                <Select placeholder="Select a unit">
                  {formFieldsState?.countUnits?.map((countUnit) => {
                    return (
                      <Option key={countUnit?.option_key} value={countUnit?.id}>
                        {capitalizeFirstWord(countUnit?.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
            <Space size="small" align="end">
              <Form.Item
                label="Size"
                name="old_size"
                rules={[{ required: true, message: 'Size is required.' }]}
              >
                <Input placeholder="Size" />
              </Form.Item>
              <Form.Item label="Size Unit of Measurement" name="size_unit">
                <Select placeholder="Select a unit">
                  {formFieldsState?.sizeUnits?.map((sizeUnit) => {
                    return (
                      <Option key={sizeUnit?.option_key} value={sizeUnit?.id}>
                        {capitalizeFirstWord(sizeUnit?.name)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
          </Space>
          <Form.Item
            name="drcChecked"
            valuePropName="checked"
            style={{ marginBottom: '0' }}
          >
            <div className="checkbox-container">
              <input
                checked={drcChecked}
                type="checkbox"
                onChange={() => setDrcChecked((prev) => !prev)}
              />
              <label htmlFor="drcChecked">Display Ready Container</label>
            </div>
          </Form.Item>

          <div className={drcChecked ? '' : 'hidden'}>
            {productItem.drc && productItem?.drc?.length > 0 ? (
              <div className="existing-drc">
                <div className="drc-title">Exisiting DRCs</div>
                <div className="drc-list">
                  {productItem.drc.map((drc) => (
                    <div className="drc-item">
                      {updatingProductId === drc.drc_item_id ? (
                        <p className="drc-item-text">Removing...</p>
                      ) : (
                        <p className="drc-item-text">{drc.drc_name}</p>
                      )}
                      {updatingProductId === drc.drc_item_id ? (
                        <Spin indicator={antSpinIcon} />
                      ) : (
                        <MinusCircleOutlined
                          onClick={() => handleRemoveDrc(drc.drc_item_id)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <Form.List
              name="item_upcs"
              rules={[
                {
                  validator: async (_, items) => {
                    if (
                      !drcChecked ||
                      (productItem?.drc && productItem?.drc?.length > 0)
                    ) {
                      return;
                    }
                    if (!items || items?.length < 1) {
                      return Promise.reject(new Error('Add at least 1 item'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map((field) => {
                    const isAdded = drcsToAdd.includes(
                      productUPCs[field?.key]?.uuid!
                    );

                    return (
                      <Form.Item required={false} key={field?.key}>
                        <div className="drc-search-container">
                          <div className="drc-search">
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  len: 10,
                                  message:
                                    'Please enter a 10 character UPC or delete this field.',
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                name={`itemUPC${field?.key}`}
                                placeholder="Single Item UPC"
                                className="drc-search-input"
                                disabled={isAdded}
                                onChange={(e) =>
                                  handleItemSearch(e.target.value, field?.key)
                                }
                                onPaste={(e) => {
                                  handleItemSearch(
                                    e.clipboardData.getData('Text'),
                                    field?.key
                                  );
                                }}
                              />
                            </Form.Item>
                            <div className="drc-icons-container">
                              <MinusCircleOutlined
                                className="drc-icon"
                                onClick={() => {
                                  remove(field?.name);
                                  dispatch(
                                    removeProductUPC({
                                      fieldKey: `${field?.key}`,
                                      action: 'DELETE',
                                    })
                                  );
                                  updateDrcRemovalList(field?.key);
                                }}
                                title="Remove DRC item"
                              />
                              {productUPCs[field?.key]?.name && (
                                <>
                                  {isAdded ? (
                                    <CheckCircleFilled
                                      className="drc-icon"
                                      style={{ color: '#1FAB89' }}
                                      title="Item Added"
                                    />
                                  ) : (
                                    <CheckCircleOutlined
                                      className="drc-icon"
                                      title="Add Product to this DRC"
                                      onClick={() => {
                                        handleAddDrc(field?.key);
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="drc-result">
                            {fetchingProductLibraryItems &&
                            drcSearchingKey === field.key ? (
                              <Spin indicator={antSpinIcon} />
                            ) : (
                              <>
                                {productUPCs[field?.key]?.name ? (
                                  <Link
                                    to={`/product-library/${
                                      productUPCs[field?.key]?.uuid
                                    }`}
                                  >
                                    <Button type="link" className="link-button">
                                      {productUPCs[field?.key]?.name}
                                    </Button>
                                  </Link>
                                ) : (
                                  <>
                                    {searchMade[field?.key] && (
                                      <span>No product found</span>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </Form.Item>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{
                        width: '60%',
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add another item
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <Form.Item
            label="Tags (type to create new tags)"
            name="tags"
            className="form-item-tags"
          >
            <Select
              loading={creatingTags}
              mode="tags"
              allowClear
              disabled={creatingTags}
              placeholder="Please select tag(s)"
              onChange={handleSelectTagChange}
            >
              {formFieldsState?.tagOptions?.map((tag) => {
                // const associatedProductTags = productTags();
                // const tagExists = associatedProductTags?.includes(tag.name);
                // if (!tagExists) {
                //   return (
                //     <Option key={tag.option_key} value={tag.option_key}>
                //       {capitalizeFirstWord(tag.name)}
                //     </Option>
                //   );
                // }
                return (
                  <Option key={tag.option_key} value={tag.option_key}>
                    {capitalizeFirstWord(tag.name)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Master Librarian Notes"
            name="master_librarian_notes"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item className="form-buttons-wrapper">
            <Space>
              <Button
                loading={savingProductLibrary}
                key="approve"
                htmlType="submit"
                type="primary"
                className="approve-button"
              >
                Approve
              </Button>
              <Button
                key="reject"
                onClick={() => handleReject(productItem)}
                className="reject-button"
                type="primary"
                danger
                disabled={savingProductLibrary}
              >
                Reject
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <div className="editable-info-form-loading-wrapper">
          <p>Loading form input data...</p>
          <Spin indicator={antSpinIcon} />
        </div>
      )}
    </FormWrapper>
  );
};

export default withRouter<any, any>(
  memo(EditableInformationForm, propsAreEqual)
);
