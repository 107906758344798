import {
  AddProductItemToListResponse,
  ClearProductListResponse,
  CountProductItemsInListResponse,
  ProductResponse,
  RemoveProductItemFromListResponse,
  ProductListResponse,
  ProductBrandResponse,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { get, del, ApiResponse, ListApiResponse } from '../base';

export default function ProductListService() {
  return Object.freeze({
    listProductList(query?: string): Promise<ProductListResponse> {
      return get(
        `${BASE_API_URL()}/product-list/${query}`
      ) as Promise<ListApiResponse>;
    },
    searchProductList(query: string): Promise<ProductListResponse> {
      return get(
        `${BASE_API_URL()}/product-list/${query}`
      ) as Promise<ListApiResponse>;
    },
    addProductItemToList(
      productId: string
    ): Promise<AddProductItemToListResponse> {
      return get(`${BASE_API_URL()}/list/${productId}/add`);
    },
    removeProductItemFromList(
      productId: string
    ): Promise<RemoveProductItemFromListResponse> {
      return get(`${BASE_API_URL()}/list/${productId}/remove`);
    },
    clearProductList(): Promise<ClearProductListResponse> {
      return get(`${BASE_API_URL()}/list/clear`);
    },
    countProductItemsInList(): Promise<CountProductItemsInListResponse> {
      return get(`${BASE_API_URL()}/list/count`);
    },
    getProduct(productId: string): Promise<ProductResponse> {
      return get(`${BASE_API_URL()}/library/${productId}`);
    },
    deleteProduct(productId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/library/${productId}`);
    },
    getBrands(): Promise<ProductBrandResponse> {
      return get(`${BASE_API_URL()}/brand/`) as Promise<ProductBrandResponse>;
    },
  });
}
