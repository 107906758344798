import { Tag, Typography } from 'antd';

import {
  ListProductLibraryModel,
  ProductCategoryModel,
} from '../../../../../models/product-library';
import { ProductFieldType, renderField } from '../Row';

import './BasicInfo.less';

const { Title } = Typography;

interface BasicInfoProps {
  productItem: ListProductLibraryModel | undefined;
}

const BasicInfo = ({ productItem }: BasicInfoProps) => {
  // render categories
  const renderCategories = (product: ListProductLibraryModel) => {
    if (product?.categories?.length === 0)
      return (
        <>
          <p className="category">N/A</p>
          <hr />
        </>
      );
    return product.categories?.map((category: ProductCategoryModel) => (
      <>
        <p className="category">{category.display_name}</p>
        <hr />
      </>
    ));
  };

  // render tags
  const renderTags = (product: ListProductLibraryModel) => {
    if (product.tags?.every((tag) => tag.set === 0))
      return (
        <>
          <p>N/A</p>
          <hr />
        </>
      );
    return product.tags?.map((tag) => (
      <>{tag.set === 1 && <Tag className="product-tag">{tag?.name}</Tag>}</>
    ));
  };

  return (
    <div className="basic-info">
      <Title className="title" level={4}>
        Basic Information
      </Title>

      {productItem &&
        renderField(productItem, 'name', ProductFieldType.Property)}

      {productItem && (
        <div className="row">
          <div className="row-item">
            <p className="row-label">Categories</p>
            {renderCategories(productItem)}
          </div>
        </div>
      )}

      {productItem &&
        renderField(productItem, 'upc', ProductFieldType.Property)}

      {productItem &&
        renderField(productItem, 'brand', ProductFieldType.Property)}

      {productItem && renderField(productItem, 'market')}

      <div className="row">
        <div className="row-item">
          <p className="row-label">Tags</p>
          <div className="product-tag-wrapper">
            {productItem && renderTags(productItem)}
          </div>
        </div>
      </div>

      {productItem && renderField(productItem, 'modelname')}
    </div>
  );
};

export default BasicInfo;
