import * as THREE from 'three';

THREE.Cache.enabled = true;

export interface MaterialProperty {
  name: string;
  shader: string | null;
  color: number[] | null;
  map: string | null;
  normalMap: string | null;
  hasNormal: boolean | null;
  roughness: number | undefined;
  metalness: number | undefined;
}

export const getMaterialPropertiesFromRSS = (rssString: string) => {
  const materials: MaterialProperty[] = [];
  const lines = rssString.split(/\r?\n/);
  let currentIndex = -1;

  lines.forEach((line) => {
    const tokens = line.split(' ');

    if (tokens[0] === 'material') {
      currentIndex++;
      materials[currentIndex] = {
        name: '',
        shader: null,
        color: null,
        map: null,
        normalMap: null,
        hasNormal: null,
        roughness: undefined,
        metalness: undefined,
      };
    }

    const result = materials[currentIndex];

    switch (tokens[0]) {
      case 'material':
        result.name = tokens[1];
        break;

      case 'shader':
        switch (tokens[1]) {
          case 'RealtimeProductOpaque':
          case 'FillLiquidOpaque':
            result.shader = 'opaque';
            break;
          case 'RealtimeProductFade':
            result.shader = 'fade';
            break;
          case 'RealtimeProductCutout':
            result.shader = 'cutout';
            break;
          case 'RealtimeProductTransparent':
          case 'FillLiquidTransparentRefractive':
            result.shader = 'transparent';
            break;
          default:
            result.shader = '';
        }
        break;
      case 'color':
        const colorValueStr = tokens
          .slice(2)
          .map((n) => parseFloat(n).toFixed(2));
        const colorValue = colorValueStr.map((n) => parseFloat(n));
        result.color = colorValue;
        break;
      case 'texture':
        if (tokens[1] === '_BaseMap') {
          result.map = tokens[2];
        } else if (tokens[1] === '_BumpMap') {
          result.hasNormal = tokens[2] ? true : false;
          result.normalMap = tokens[2];
        }
        break;
      case 'int':
        if (tokens[1] === '_EnableNormalMap') {
          result.hasNormal = parseInt(tokens[2]) === 1;
        }
        break;
      case 'float':
        if (tokens[1] === '_Smoothness') {
          result.roughness = +(1 - parseFloat(tokens[2])).toFixed(1);
        } else if (tokens[1] === '_Metallic') {
          result.metalness = Number(parseFloat(tokens[2]).toFixed(1));
        }
        break;
      default:
        break;
    }
  });

  return materials;
};

export const preloadTextures = (
  textureName: string,
  textureList: string[],
  setTextureLoaded: React.Dispatch<React.SetStateAction<boolean>>
): THREE.Texture | null => {
  const texUrl = textureList.find((texture) => texture.includes(textureName));

  if (!texUrl) return null;

  // Check if the texture is already in the cache
  const cachedTexture = THREE.Cache.get(texUrl) as THREE.Texture;
  if (cachedTexture) {
    setTextureLoaded(true);
    return cachedTexture;
  }

  // Texture not in cache, load it
  const textureLoader = new THREE.TextureLoader().setCrossOrigin('anonymous');
  return textureLoader.load(
    texUrl,
    (texture) => {
      // On load
      texture.flipY = false;
      (texture as any).colorSpace = (THREE as any).SRGBColorSpace;
      setTextureLoaded(true);

      // Add the loaded texture to the cache
      THREE.Cache.add(texUrl, texture);
    },
    undefined,
    (error) => {
      // On error
      console.error('Error loading texture:', error);
    }
  );
};

// example of rss string:

// material 3124800301
// shader RealtimeProductOpaque
// color _BaseColor 1.0 1.0 1.0 1.0
// texture _BaseMap 3124800301_Dramamine.png
// int _EnableNormalMap 0
// float _Smoothness 0.2
// float _Metallic 0

// material transparent_EmptyPlastic
// shader RealtimeProductTransparent
// color _BaseColor 0.0 0.0 0.0 0.8
// int _EnableNormalMap 0
// float _Smoothness 0.9
// float _Metallic 0
