import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Drawer, Input, message, Space, Spin, Switch, Table, Tag } from 'antd';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { useParams } from 'react-router-dom';
import { ApiError, handleError } from '../../../../../api/base';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  CellModel,
  CellStatus,
  OvrProjectModel,
  ProjectCellModel,
} from '../../../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  fetchOvrCells,
  importCellToProject,
  paginateOvrCells,
  removeCellFromProject,
  searchOvrCellsByName,
  setCellsDrawerVisible,
  setCellSearchQuery,
  setImportedACell,
  setSearchingOvrCells,
} from '../../../../../store/features/cells/cellsSlice';
import {
  fetchOvrProject,
  updateOvrProjectLocally,
} from '../../../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import { formatBytes, formatDate, propsAreEqual } from '../../../../../util';
import FormWrapper from '../../../../elements/FormWrapper';

interface CellsDrawerProps {
  visible: boolean;
}
interface ParamsState {
  id: string;
}

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const CellsDrawer = ({ visible }: CellsDrawerProps) => {
  const { id: projectId } = useParams<ParamsState>();

  const [selectedCell, setSelectedCell] = useState<CellModel | undefined>(
    undefined
  );

  const [pageSize, setPageSize] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch = useAppDispatch();

  const {
    loading,
    cellSearchQuery,
    ovrCells,
    searchingOvrCells,
    totalCount,
    fetchingOvrCells,
    importedACell,
  } = useAppSelector((state) => state.cells);

  const debouncedSearchQuery = useDebounce(cellSearchQuery, 500);

  const { ovrProject: project } = useAppSelector(
    (state) => state.ovrProjectDetails
  );

  const getInProjectCells = useMemo(() => {
    if (!project) return [];

    const { cellGroups } = project!;
    const group0 = cellGroups.find((group) => group.group_id === 0);

    return group0?.projectCells.map((pCell) => pCell) as ProjectCellModel[];
  }, [project]);

  const onImportCellSuccess = useCallback(
    async (
      cell: CellModel,
      importCellResp: CellModel,
      project: OvrProjectModel
    ) => {
      const newCell = {
        ...importCellResp,
        cell,
        message: `Cell ${cell.name} added to project ${project.name}.`,
      };

      const updatedProject: OvrProjectModel = {
        ...project,
        cellGroups: project?.cellGroups?.map((group) => {
          if (group.group_id === 0) {
            return {
              ...group,
              projectCells: [...group?.projectCells, newCell],
            };
          }
          return group;
        }),
      };

      dispatch(setImportedACell(true));
      dispatch(updateOvrProjectLocally(updatedProject));
      if (debouncedSearchQuery) {
        await dispatch(
          searchOvrCellsByName({
            query: debouncedSearchQuery,
            options: { approved: 1, status: 'completed' },
          })
        );
      } else {
        await dispatch(
          paginateOvrCells({
            page,
            pageSize,
            query: cellSearchQuery,
            options: {
              approved: 1,
              status: 'completed',
            },
          })
        );
      }

      message.success(`Cell added to project.`);
    },
    [cellSearchQuery, debouncedSearchQuery, dispatch, page, pageSize]
  );

  const onRemoveCellSuccess = useCallback(
    async (cell: CellModel, project: OvrProjectModel) => {
      const updatedProject: OvrProjectModel = {
        ...project,
        cellGroups: project?.cellGroups?.map((group) => {
          if (group.group_id === 0) {
            return {
              ...group,
              projectCells: group.projectCells.filter(
                (pCell) => pCell.cell?.uuid !== cell.uuid
              ),
            };
          }
          return group;
        }),
      };

      dispatch(updateOvrProjectLocally(updatedProject));
      if (debouncedSearchQuery) {
        await dispatch(
          searchOvrCellsByName({
            query: debouncedSearchQuery,
            options: { approved: 1, status: 'completed' },
          })
        );
      } else {
        await dispatch(
          paginateOvrCells({
            page,
            pageSize,
            query: cellSearchQuery,
            options: {
              approved: 1,
              status: 'completed',
            },
          })
        );
      }

      message.success(`Cell removed from project.`);
    },
    [cellSearchQuery, debouncedSearchQuery, dispatch, page, pageSize]
  );

  const getCellStatus = useCallback(
    (cell: CellModel) => {
      if (project) {
        const { cellGroups } = project;
        const group0 = cellGroups.find((group) => group.group_id === 0);

        const projectCell = group0?.projectCells.find(
          (projectCell) => projectCell.cell?.uuid === cell.uuid
        );

        if (projectCell) {
          return CellStatus.InProject;
        }

        return CellStatus.Available;
      }
    },
    [project]
  );

  const handleAddCellToProject = useCallback(
    async (cellToAdd: CellModel, project: OvrProjectModel) => {
      setSelectedCell(cellToAdd);
      try {
        const resultAction = await dispatch(
          importCellToProject({ cell: cellToAdd, project })
        );

        if (importCellToProject.fulfilled.match(resultAction)) {
          const importedCellPayload = resultAction.payload;
          onImportCellSuccess(cellToAdd, importedCellPayload, project);
        } else {
          throw new Error('Import cell to project failed');
        }
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        setSelectedCell(undefined);
      }
    },
    [dispatch, onImportCellSuccess]
  );

  const handleRemoveCellFromProject = useCallback(
    (cellToRemove: CellModel) => {
      getInProjectCells?.forEach(async (projectCell) => {
        if (projectCell?.cell?.uuid === cellToRemove.uuid) {
          const projectCellToRemoveId = projectCell.uuid;
          if (projectCellToRemoveId && project) {
            try {
              setSelectedCell(cellToRemove);
              await dispatch(
                removeCellFromProject({
                  projectCellId: projectCellToRemoveId,
                  projectId: project.uuid,
                })
              ).unwrap();
              onRemoveCellSuccess(cellToRemove, project);
            } catch (error) {
              handleError(error as ApiError);
            } finally {
              setSelectedCell(undefined);
            }
          }
        }
      });
    },
    [dispatch, getInProjectCells, onRemoveCellSuccess, project]
  );

  const handleToggleSwitch = useCallback(
    (checked: boolean, cell: CellModel) => {
      if (checked && cell && project) {
        handleAddCellToProject(cell, project);
      } else {
        handleRemoveCellFromProject(cell);
      }
    },
    [handleAddCellToProject, handleRemoveCellFromProject, project]
  );

  const handleClearSearch = useCallback(async () => {
    try {
      dispatch(setCellSearchQuery(''));
      await dispatch(
        fetchOvrCells({ params: { approved: 1, status: 'completed' } })
      ).unwrap();
      setCurrentPage(1);
    } catch (error) {
      handleError(error as ApiError);
    }
  }, [dispatch]);

  const updateSearchQuery = useCallback(
    async (query: string) => {
      try {
        if (query === '') {
          await handleClearSearch();
          dispatch(setSearchingOvrCells(false));
        } else {
          dispatch(setCellSearchQuery(query));
        }
      } catch (error) {
        handleError(error as ApiError);
      }
    },
    [dispatch, handleClearSearch]
  );

  const handleCellDrawerClose = async () => {
    try {
      if (importedACell) {
        await dispatch(fetchOvrProject({ projectId })).unwrap();
        dispatch(setImportedACell(false));
      }
      dispatch(setCellSearchQuery(''));
      dispatch(fetchOvrCells({ params: { approved: 1, status: 'completed' } }));
      dispatch(setCellsDrawerVisible(false));
    } catch (error) {
      console.error(error);
      handleError(error as ApiError);
    }
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: (size: number) => <div>{formatBytes(size)}</div>,
      },
      {
        title: 'Status',
        key: 'status',
        render: (cell: CellModel) => {
          if (getCellStatus(cell) === CellStatus.InProject) {
            return <Tag color="processing">In Project</Tag>;
          }
          return <Tag color="success">Available</Tag>;
        },
        sortDirections: [],
        sortOrder: 'descend',
        showSorterTooltip: false,
      },
      {
        title: 'Upload Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        defaultSortOrder: 'descend',
        sorter: (
          a: { created_at: string | number | Date },
          b: { created_at: string | number | Date }
        ) => {
          if (!a.created_at || !b.created_at) {
            return 0;
          }
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        },
      },
      {
        title: 'Add',
        key: 'add',
        render: (cell: CellModel) => {
          return (
            <Switch
              defaultChecked={getCellStatus(cell) === CellStatus.InProject}
              loading={selectedCell?.name === cell.name && loading}
              onChange={(checked: boolean) => handleToggleSwitch(checked, cell)}
            />
          );
        },
      },
    ],
    [getCellStatus, selectedCell?.name, loading, handleToggleSwitch]
  );

  useEffect(() => {
    if (debouncedSearchQuery) {
      setCurrentPage(1);
      dispatch(
        searchOvrCellsByName({
          query: debouncedSearchQuery,
          options: { approved: 1, status: 'completed' },
        })
      );
    }
  }, [debouncedSearchQuery, dispatch]);

  useEffect(() => {
    dispatch(fetchOvrCells({ params: { approved: 1, status: 'completed' } }));
  }, [dispatch]);

  const paginate = useCallback(
    (page: number, pageSize: number) => {
      setPage(page);
      setPageSize(pageSize);
      setCurrentPage(page);

      return dispatch(
        paginateOvrCells({
          page,
          pageSize,
          query: cellSearchQuery,
          options: {
            approved: 1,
            status: 'completed',
          },
        })
      );
    },
    [cellSearchQuery, dispatch]
  );

  return (
    <Drawer
      closable={false}
      width={'60%'}
      onClose={handleCellDrawerClose}
      placement="right"
      visible={visible}
    >
      <FormWrapper
        title={`Cells for: ${project?.name}`}
        onClose={handleCellDrawerClose}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Input
            allowClear={!searchingOvrCells}
            value={cellSearchQuery}
            disabled={fetchingOvrCells || selectedCell !== undefined}
            onChange={(e) => updateSearchQuery(e.target.value)}
            placeholder="Search for a cell..."
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
            suffix={searchingOvrCells && <Spin indicator={antSpinIcon} />}
          />
          <Table
            rowKey={(cell) => `${cell.name}-row-key-${cell.uuid}`}
            sortDirections={['ascend', 'descend', 'ascend']}
            loading={fetchingOvrCells || selectedCell !== undefined}
            pagination={{
              defaultPageSize: 20,
              total: totalCount,
              onChange: paginate,
              current: currentPage,
            }}
            columns={columns}
            dataSource={ovrCells}
          />
        </Space>
      </FormWrapper>
    </Drawer>
  );
};

export default memo(CellsDrawer, propsAreEqual);
