import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Typography,
  Upload,
  message,
} from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface';
import axios from 'axios';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import CustomFieldService from '../../../../api/custom-field/service';
import { MarketModel, initialNewMarketModel } from '../../../../models/market';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createCustomFieldOption,
  deleteCustomFieldOption,
  fetchCustomFieldOptionsByKey,
  updateCustomFieldOption,
} from '../../../../store/features/metaData/metaDataSlice';
import {
  UserPermissions,
  getMePermissions,
  propsAreEqual,
} from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './MarketForm.less';
import { MarketFormLocationState } from './types';

interface MarketFormProps {
  history: History;
  location: Location<MarketFormLocationState>;
}

interface MarketProps {
  name: string;
  status: string;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const MarketForm: FC<MarketFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { deletingCustomFieldOption, savingCustomFieldOption } = useAppSelector(
    (state) => state.metaData
  );
  const [form] = Form.useForm();

  const [imageUrl, setImageUrl] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const marketLocationData = location.state?.data;
  const isNewMarket = useMemo(() => !marketLocationData, [marketLocationData]);
  const fields = useMemo(
    () => Object.keys(isNewMarket ? initialNewMarketModel : marketLocationData),
    [isNewMarket, marketLocationData]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const handleBeforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return false;
    }

    setFileList([file]);
    return false;
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    getBase64(info.file as RcFile, (url) => {
      setImageUrl(url);
    });
  };

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveMarket = useCallback(
    async (market: MarketModel) => {
      const { name, statusCheckValue } = market;

      let status: string;
      let newMarket: MarketProps = { name: '', status: '' };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newMarket = {
        name,
        status,
      };

      try {
        const res: any = isNewMarket
          ? await dispatch(
              createCustomFieldOption({
                customFieldKey: 'market',
                customFieldOption: newMarket,
              })
            )
          : await dispatch(
              updateCustomFieldOption({
                customFieldKey:
                  marketLocationData.custom_field_id as unknown as string,
                optionId: marketLocationData.id,
                customFieldOption: newMarket,
              })
            );

        await Promise.all(
          fileList.map(async (file) => {
            // Upload file to S3
            const {
              data: { signedUrl, objectKey, media_id, media_version_id },
            } = await CustomFieldService().getSignedUploadUrl(
              file.name,
              `${res.payload.id}`
            );

            await axios.put(signedUrl, file, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            });

            // Associate file with ovr cell
            await CustomFieldService().mediaUploaded({
              objectKey,
              name: file.name,
              size: file.size as number,
              uuid: `${res.payload.id}`,
              media_id,
              media_version_id,
            });
          })
        );
        dispatch(
          fetchCustomFieldOptionsByKey({
            customFieldKey: 'market',
          })
        );

        message.success('Market saved.');
        history.goBack();
      } catch (error: any) {
        onError(error);
      }
    },
    [
      dispatch,
      fileList,
      history,
      isNewMarket,
      marketLocationData?.custom_field_id,
      marketLocationData?.id,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: MarketModel) => {
      form.validateFields(fields).then(() => saveMarket(values));
    },
    [fields, form, saveMarket]
  );

  const getInitialValues = () => {
    if (isNewMarket) {
      return { ...initialNewMarketModel };
    }
    const { status } = marketLocationData;
    return {
      ...marketLocationData,
      statusCheckValue: status === ProductStatusEnum.approved,
    };
  };

  useEffect(() => {
    const { media_files } = marketLocationData || {};
    if (media_files) {
      setImageUrl(media_files[0]?.media_url);
    }
  }, [marketLocationData]);

  const handleDeleteMarket = useCallback(async () => {
    message.loading(
      {
        content: 'Deleting market...',
        key: 'deleting-market-message',
      },
      0
    );

    const res: any = await dispatch(
      deleteCustomFieldOption({
        customFieldKey: 'market',
        optionId: marketLocationData.id,
      })
    );
    if (res.error) {
      onError({ error: res.payload.error });
    } else {
      message.destroy('deleting-market-message');
      message.success(`Market '${marketLocationData.name}' deleted.`);
      history.goBack();
    }
  }, [
    dispatch,
    marketLocationData?.id,
    marketLocationData?.name,
    onError,
    history,
  ]);

  const handleConfirmDeleteMarket = useCallback(
    (e: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the market "${marketLocationData.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the "${marketLocationData.name}" market after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: handleDeleteMarket,
      });
    },
    [handleDeleteMarket, marketLocationData?.name]
  );

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <FormWrapper
      title={`${isNewMarket ? 'Create new' : 'Edit'} market`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Market"
          name="name"
          rules={[{ required: true, message: 'Market name is required.' }]}
        >
          <Input data-cy="market-form-input" placeholder="Market name" />
        </Form.Item>
        <Form.Item label="Images" name="images">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            fileList={fileList}
            multiple={false}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="Status">
          <div className="market-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue" valuePropName="checked">
              <Switch
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.MetaDataApprove
                  )
                }
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewMarket ? (
          <Form.Item label="Details">
            <div className="market-form-details">
              <Typography>Number of models in library</Typography>
              <Typography>{marketLocationData.in_library}</Typography>
            </div>
            <div className="market-form-details">
              <Typography>
                Number of models in processing / master librarian list
              </Typography>
              <Typography>{marketLocationData.in_processing}</Typography>
            </div>
            <Button className="link-button" type="link">
              View in Library
            </Button>
          </Form.Item>
        ) : null}
        <Form.Item>
          {isNewMarket ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingCustomFieldOption}
                data-cy="market-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.CustomFieldsCreate
                  )
                }
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!marketLocationData.in_use ? (
                <Button
                  loading={deletingCustomFieldOption}
                  danger
                  type="primary"
                  onClick={handleConfirmDeleteMarket}
                  disabled={
                    !currentUserPermissions.includes(
                      UserPermissions.CustomFieldsDelete
                    )
                  }
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingCustomFieldOption}
                type="primary"
                htmlType="submit"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.CustomFieldsEdit
                  )
                }
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(MarketForm, propsAreEqual));
