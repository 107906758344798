import { memo, useCallback, useMemo, useState } from 'react';

import { FileExclamationOutlined } from '@ant-design/icons';
import { Form, Input, message, Space, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import axios, { CancelTokenSource } from 'axios';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';
import './RSGFileForm.less';

import { CreateOvrFileRequest } from '../../../../api/ovrFile';
import { initialNewRSGFileModel } from '../../../../models/rsg-file';
import { useAppDispatch } from '../../../../store';
import {
  createRsgFile,
  getRSOVRSignedUploadUrl,
  updateRsgFile,
  uploadFileToS3,
} from '../../../../store/features/ovrFiles/ovrFilesSlice';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import { RSGFileFormLocationState } from './types';

const { TextArea } = Input;
const { Dragger } = Upload;

interface RSGFileFormProps {
  history: History;
  location: Location<RSGFileFormLocationState>;
}

const RSGFileForm = (props: RSGFileFormProps) => {
  const { history, location } = props;
  const { name, description } = location.state?.data || {};
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  const [form] = Form.useForm();

  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useAppDispatch();
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const isNewRSGFile = useMemo(() => !location.state, [location.state]);
  const fields = useMemo(
    () =>
      Object.keys(isNewRSGFile ? initialNewRSGFileModel : location.state?.data),
    [isNewRSGFile, location.state?.data]
  );

  const onSuccess = useCallback(async () => {
    message.success('Files uploaded.');
  }, []);

  const onError = useCallback((error: any) => {
    message.error({
      content: error.message || 'An error occurred.',
      duration: 5,
    });
  }, []);

  const onSaveRsgFile = useCallback(
    async (source: CancelTokenSource) => {
      setUploadingFile(true);
      let hasError = false;
      try {
        if (isNewRSGFile) {
          for (const file of fileList) {
            try {
              // get signed url for each file
              const signedUrlResponse = await dispatch(
                getRSOVRSignedUploadUrl({ fileName: file.name })
              ).unwrap();

              // upload each file to s3
              await dispatch(
                uploadFileToS3({
                  signedUrl: signedUrlResponse.signedUrl,
                  file: file.originFileObj,
                  source,
                })
              ).unwrap();

              const { uuid, objectUrl, objectKey, name } =
                signedUrlResponse || {};

              // create the rsg file payload
              const newRsgFile: CreateOvrFileRequest = {
                payload_size: file?.size?.toString()!,
                uuid,
                data: {
                  objectUrl,
                  objectKey,
                  uuid,
                },
                name,
              };

              // create a new rsg file for each file
              await dispatch(createRsgFile({ rsgFile: newRsgFile })).unwrap();
            } catch (error) {
              onError(error);
              hasError = true;
              break;
            }
          }
          if (!hasError) {
            onSuccess();
          }
        } else {
          const fileId = location.state?.data?.uuid;

          const payload = {
            name: form.getFieldValue('name'),
            description: form.getFieldValue('description'),
          };

          await dispatch(updateRsgFile({ fileId, payload }));
        }
      } catch (error) {
        onError(error);
      } finally {
        setUploadingFile(false);
        history.goBack();
      }
    },
    [
      isNewRSGFile,
      fileList,
      dispatch,
      onError,
      onSuccess,
      location.state?.data?.uuid,
      form,
      history,
    ]
  );

  const onSubmit = useCallback(async () => {
    await form.validateFields(fields);
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    onSaveRsgFile(source);
  }, [fields, form, onSaveRsgFile]);

  const handleCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User cancelled the upload');
    } else {
      history.goBack();
    }
  };

  const onChange = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
  };

  const onRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const beforeUpload = (): boolean => {
    return false;
  };

  return (
    <FormWrapper
      title={`${isNewRSGFile ? 'Upload' : 'Edit'} rsg or rssegment File`}
      onClose={handleCancelUpload}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewRSGFile ? initialNewRSGFileModel : location.state?.data
        }
        onFinish={onSubmit}
      >
        {isNewRSGFile && (
          <Form.Item
            name="file_name"
            rules={[{ required: true, message: 'A .RSG file is required.' }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Dragger
              method="put"
              accept=".rsg, .rssegment"
              fileList={fileList}
              onChange={onChange}
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              multiple
            >
              <Space size="middle" direction="vertical">
                <p>
                  <FileExclamationOutlined style={{ fontSize: '52px' }} />
                </p>
                <p className="dragger-input-text">
                  Click or drag .rsg or .rssegment files to this area for upload
                </p>
              </Space>
            </Dragger>
          </Form.Item>
        )}
        {!isNewRSGFile && (
          <>
            <Form.Item
              label="File Name"
              name="name"
              initialValue={name}
              rules={[
                { required: true, message: 'RSG file name is required.' },
              ]}
            >
              <Input disabled placeholder="File name" />
            </Form.Item>
            <Form.Item
              label="File Description"
              name="description"
              initialValue={description}
            >
              <TextArea rows={6} placeholder="Description" />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              disabled={uploadingFile}
              loading={uploadingFile}
            >
              {`${isNewRSGFile ? 'Add to Queue' : 'Save'}`}
            </Button>
            <Button onClick={handleCancelUpload} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(RSGFileForm, propsAreEqual));
