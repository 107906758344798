import { memo } from 'react';

import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { ListOvrProjectModel } from '../../../../../../../models/online-virtual-research';
import { propsAreEqual } from '../../../../../../../util';
import OVRProjectItem from '../../../OVRProjectItem';

interface OvrGridListProps {
  skeletonItemLength?: number;
  projects: ListOvrProjectModel[];
  loading: boolean;
  onLoadMore?: () => void;
  hasMore: boolean;
}

const OVRGridList = ({
  projects,
  loading,
  onLoadMore,
  hasMore,
  skeletonItemLength = 5,
}: OvrGridListProps) => {
  return (
    <InfiniteScroll
      loadMore={!!onLoadMore ? onLoadMore : () => {}}
      hasMore={hasMore}
    >
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 5 }}
        itemLayout="vertical"
        dataSource={projects}
        rowKey={(project) => `${project.uuid}-row-key`}
        renderItem={(project, index) => (
          <List.Item key={`ovr-project-item-${index}`}>
            <OVRProjectItem project={project} />
          </List.Item>
        )}
      >
        {loading && projects.length === 0 && (
          <div className="loading-skeleton-cards-wrapper">
            {new Array(skeletonItemLength).fill(1).map((_, index) => (
              <List.Item key={`${index}-project-item-skeleton-key`}>
                <div
                  className="ovr-grid-item"
                  style={{
                    height: 238,
                    marginRight: 16,
                    marginBottom: 16,
                  }}
                >
                  <div className="ovr-grid-item-section-top">
                    <div className="ovr-grid-item-top-section-left">
                      <Skeleton
                        active
                        round
                        title={{ width: 200 }}
                        paragraph={{ rows: 1, width: 200 }}
                      />
                    </div>
                  </div>
                  <div className="ovr-grid-item-section-bottom">
                    <Skeleton
                      round
                      active
                      title={false}
                      paragraph={{ rows: 5, width: 150 }}
                    />
                  </div>
                </div>
              </List.Item>
            ))}
          </div>
        )}
      </List>
    </InfiniteScroll>
  );
};

export default memo(OVRGridList, propsAreEqual);
