import { memo, useCallback } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Tooltip } from 'antd';
import { History } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../api/base';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import {
  deleteProductItem,
  fetchProductItem,
} from '../../../../../store/features/productItem/productItemSlice';
import {
  addProductItemToList,
  removeProductItemFromList,
} from '../../../../../store/features/productList/productListSlice';
import { useAppDispatch } from '../../../../../store/index';
import { propsAreEqual } from '../../../../../util';

import './HeaderSectionRight.less';
import DropdownMenu from '../../../../containers/DropdownMenu';

interface HeaderSectionRightProps {
  productItem: ListProductLibraryModel;
  displayEditableForm: boolean;
  history: History;
  setDisplayEditableForm: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
}

const HeaderSectionRight = ({
  productItem,
  displayEditableForm,
  history,
  setDisplayEditableForm,
}: HeaderSectionRightProps) => {
  const dispatch = useAppDispatch();

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-product-item-message');
    handleError(err);
  }, []);

  const handleDeleteProductItem = useCallback(
    async (productItem: ListProductLibraryModel) => {
      await message.loading(
        {
          content: 'Deleting product..',
          key: 'deleting-productItem-message',
        },
        0
      );

      const resp: any = await dispatch(deleteProductItem(productItem.uuid));
      if (resp.error) {
        onError({ error: resp.payload.error });
      } else {
        await message.destroy('deleting-product-item-message');
        await history.push('/product-library');
        message.success(`product '${productItem.name}' deleted.`);
      }
    },
    [dispatch, onError, history]
  );

  const handleConfirmDeleteProductItem = useCallback(
    (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      ProductItem: ListProductLibraryModel
    ) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete product '${ProductItem.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover this product.',
        okText: 'Delete',
        onOk: () => handleDeleteProductItem(productItem),
      });
    },
    [handleDeleteProductItem, productItem]
  );

  const handleButtonClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productItem: ListProductLibraryModel
  ) => {
    if (!displayEditableForm) {
      setDisplayEditableForm(true);
    } else {
      handleConfirmDeleteProductItem(e, productItem);
    }
  };

  return (
    <Space size="middle">
      <Button
        type="primary"
        danger
        style={{ minWidth: '90px' }}
        onClick={(e) => handleButtonClick(e, productItem)}
      >
        {displayEditableForm ? 'Delete' : 'Edit'}
      </Button>

      <DropdownMenu requireSelectedProduct={false} productItem={productItem} />

      {productItem && productItem?.in_list === 1 ? (
        <Tooltip title="Remove from Product List">
          <Button
            onClick={async () => {
              await dispatch(removeProductItemFromList(productItem.uuid));
              dispatch(fetchProductItem(productItem.uuid));
            }}
          >
            Remove from List
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Add to Product List">
          <Button
            type="primary"
            onClick={async () => {
              await dispatch(addProductItemToList(productItem));
              dispatch(fetchProductItem(productItem.uuid));
            }}
          >
            Add to List
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};

export default withRouter<any, any>(memo(HeaderSectionRight, propsAreEqual));
