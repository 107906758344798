import { useCallback, useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Image, Button } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  fetchProductItem,
  getFetchingProductItem,
  getProductItem,
} from '../../../../store/features/productItem/productItemSlice';
import ViewWrapper from '../../../elements/ViewWrapper';

import './CurrentImageView.less';

interface ParamsState {
  id: string;
  selectedImage?: string;
}

const CurrentImageView = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const productItem = useAppSelector(getProductItem);
  const fetchingProductItem = useAppSelector(getFetchingProductItem);
  const { selectedImage } = location.state as ParamsState;

  const { id } = useParams<ParamsState>();

  const getProduct = useCallback(() => {
    dispatch(fetchProductItem(id));
  }, [dispatch, id]);

  useEffect(() => {
    getProduct();
  }, [dispatch, getProduct]);

  const headerSectionRight = () => {
    return (
      <Button
        type="link"
        className="link-button"
        onClick={() => history.goBack()}
      >
        Back to Product Page
      </Button>
    );
  };

  return (
    <ViewWrapper
      options={{
        goBack: true,
      }}
      headerTitle={productItem?.upc ? productItem?.upc + ' UPC' : 'No UPC'}
      headerSectionRight={headerSectionRight()}
    >
      <div className="current-image-container">
        {fetchingProductItem ? (
          <LoadingOutlined className="loading-spinner" spin />
        ) : (
          <Image src={selectedImage} preview={false} />
        )}
      </div>
    </ViewWrapper>
  );
};

export default CurrentImageView;
