import { memo, useCallback } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import {
  CellGroupModel,
  ProjectCellModel,
} from '../../../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { updateProjectCellsLocally } from '../../../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import { propsAreEqual } from '../../../../../util';
import ProjectCell from '../ProjectCell/ProjectCell';
import './ProjectCellsList.less';

interface ProjectCellsListProps {
  projectCells: any;
  groupId: number;
  cellGroup: CellGroupModel;
}

const ProjectCellsList = ({
  projectCells,
  groupId,
  cellGroup,
}: ProjectCellsListProps) => {
  const dispatch = useAppDispatch();

  const { isAddingProjectCell, isUpdatingProjectCell, selectedProjectCellId } =
    useAppSelector((state) => state.ovrProjectDetails);
  const {
    isGettingOvrProjectCellSignedUrl,
    isUploadingToS3,
    isOvrProjectCellMediaServiceUploading,
  } = useAppSelector((state) => state.media);

  const isProcessingUpload =
    isGettingOvrProjectCellSignedUrl ||
    isUploadingToS3 ||
    isOvrProjectCellMediaServiceUploading ||
    isAddingProjectCell ||
    isUpdatingProjectCell;

  const getOdds = useCallback(() => {
    return `1 in ${projectCells.length} (${(
      (1 / projectCells.length) *
      100
    ).toFixed(2)}%)`;
  }, [projectCells.length]);

  const handleAddCell = useCallback(() => {
    const newCell = {
      group_id: groupId,
      cell: undefined,
      message: '',
    };

    dispatch(
      updateProjectCellsLocally({
        groupId,
        updatedProjectCells: [...projectCells, newCell],
      })
    );
  }, [dispatch, groupId, projectCells]);

  return (
    <div className="project-cells-list-container">
      <div className="project-cells-list">
        {projectCells?.map((projectCell: ProjectCellModel, index: number) => {
          return (
            <>
              {isProcessingUpload &&
              selectedProjectCellId === projectCell.uuid ? (
                <div
                  key={`${index + 1}-cell-item`}
                  className="process-upload-wrapper"
                >
                  <Spin />
                  {isGettingOvrProjectCellSignedUrl ? (
                    <p>Preparing image for upload...</p>
                  ) : null}
                  {isUploadingToS3 ? <p>Uploading image...</p> : null}
                  {isOvrProjectCellMediaServiceUploading ? (
                    <p>Processing image...</p>
                  ) : null}
                  {isAddingProjectCell || isUpdatingProjectCell ? (
                    <p>Saving cell...</p>
                  ) : null}
                </div>
              ) : (
                <ProjectCell
                  key={`${index}-cell-item`}
                  projectCell={projectCell}
                  projectCellIndex={index}
                  cellGroup={cellGroup}
                  odds={getOdds()}
                />
              )}
            </>
          );
        })}
      </div>

      <Button type="dashed" className="add-cell-btn" onClick={handleAddCell}>
        Add Cell <PlusOutlined />
      </Button>
    </div>
  );
};

export default memo(ProjectCellsList, propsAreEqual);
