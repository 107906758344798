import {
  CategoryListResponse,
  CategoryResponse,
  CreateCategoryRequest,
  UpdateCategoryRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { ApiResponse, del, get, ListApiResponse, patch, post } from '../base';

export default function CategoryService() {
  return Object.freeze({
    getCategory(query?: string): Promise<CategoryListResponse> {
      return get(
        `${BASE_API_URL()}/category${query ?? ''}`
      ) as Promise<ListApiResponse>;
    },
    getSubCategory(id: string): Promise<CategoryListResponse> {
      return get(
        `${BASE_API_URL()}/category/${id}/categories`
      ) as Promise<ListApiResponse>;
    },
    createCategory(req: CreateCategoryRequest): Promise<CategoryResponse> {
      return post(`${BASE_API_URL()}/category`, req);
    },
    updateCategory(
      id: string,
      req: UpdateCategoryRequest
    ): Promise<CategoryResponse> {
      return patch(`${BASE_API_URL()}/category/${id}`, req);
    },
    deleteCategory(id: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/category/${id}`);
    },
  });
}
