import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { ApiError, PaginationModel, handleError } from '../../../api/base';
import OnlineVirtualResearchService, {
  OvrProjectCellsResponse,
} from '../../../api/online-virtual-research';
import OVRCellService from '../../../api/ovr-cell';
import {
  CellModel,
  CellStatus,
  OvrProjectModel,
} from '../../../models/online-virtual-research';
import { ListModelParams } from '../../../types';
import { convertParamsToQuery } from '../../../util';

export interface CellState {
  importedCell: CellModel | undefined;
  importedACell: boolean;
  ovrCells: CellModel[];
  cellSearchQuery: string;
  fetchingOvrProjectCells: boolean;
  fetchingOvrCells: boolean;
  searchingOvrCells: boolean;
  loading: boolean;
  error: { code: number; details?: string; message: string } | null;
  pagination: PaginationModel | null;
  totalCount: number;
  count: number;
  current: number;
  cellsDrawerVisible: boolean;
}

const fetchOvrCellsParams: ListModelParams = {
  _limit: 20,
  _offset: 0,
  _columns: 'createdByUser.*,media_files.*',
  _order_by: 'created_at:desc',
};

const initialState: CellState = {
  importedCell: undefined,
  importedACell: false,
  ovrCells: [],
  cellSearchQuery: '',
  fetchingOvrProjectCells: false,
  fetchingOvrCells: false,
  searchingOvrCells: false,
  loading: false,
  error: null,
  pagination: null,
  totalCount: 0,
  count: 0,
  current: 1,
  cellsDrawerVisible: false,
};

const formatCells = (resp: OvrProjectCellsResponse): CellModel[] => {
  const inprojectCellsNames = resp.data.inproject.map((cell) => cell.name);

  const availableCells = resp.data.available.reduce((memo: any[], cell) => {
    if (inprojectCellsNames.includes(cell.name)) return memo;
    return [
      ...memo,
      {
        name: cell.name,
        size: cell.filesize,
        status: CellStatus.Available,
      },
    ];
  }, []);

  const inprojectCells = resp.data.inproject.map((cell) => ({
    ...cell,
    status: CellStatus.InProject,
  }));

  return uniqBy([...availableCells, ...inprojectCells], 'name');
};

export const fetchOvrProjectCells = createAsyncThunk(
  'cells/fetchOvrProjectCells',
  async (projectId: string, { rejectWithValue }) => {
    try {
      const resp = await OnlineVirtualResearchService().listOvrProjectCells(
        projectId
      );

      return formatCells(resp);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOvrCells = createAsyncThunk(
  'cells/fetchOvrCells',
  async (
    {
      params,
      searching = false,
      fetchingOvrCellsMore = false,
    }: {
      params?: ListModelParams;
      searching?: boolean;
      fetchingOvrCellsMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      let combinedParams = fetchOvrCellsParams;

      if (params) {
        combinedParams = {
          ...combinedParams,
          ...params,
        };
      }
      const queryString = convertParamsToQuery(combinedParams);
      const resp = await OnlineVirtualResearchService().listOVRCells(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        fetchingOvrCellsMore,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOvrCell = createAsyncThunk(
  'cells/deleteOvrCell',
  async (cellUUID: string, { dispatch, rejectWithValue }) => {
    try {
      await OVRCellService().deleteOvrCell(cellUUID);
      await dispatch(searchOvrCellsByName({ query: '' }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const showOvrCellInProjects = createAsyncThunk(
  'cells/showOVRCellInProject',
  async (
    { cellUUID, approved }: { cellUUID: string; approved: boolean },
    { rejectWithValue }
  ) => {
    try {
      await OVRCellService().toggleOVRCell(cellUUID, approved);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchOvrCellsByName = createAsyncThunk(
  'cells/searchOvrCellsByName',
  async (
    { query, options }: { query: string; options?: any },
    { rejectWithValue }
  ) => {
    try {
      let params: any = {
        _limit: 20,
        _offset: 0,
        _columns: 'createdByUser.*,media_files.*',
        _order_by: 'created_at:desc',
        ...options,
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await OnlineVirtualResearchService().listOVRCells(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateOvrCells = createAsyncThunk(
  'cells/paginateOvrCells',
  async (
    {
      page,
      pageSize,
      query = '',
      options,
    }: { page: number; pageSize: number; query?: string; options?: any },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: any = {
        _limit: limit,
        _offset: nextOffset,
        _columns: 'createdByUser.*,media_files.*',
        _order_by: 'created_at:desc',
        ...options,
      };

      if (query && query.length) {
        nextParams = {
          ...nextParams,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(nextParams);

      const resp = await OnlineVirtualResearchService().listOVRCells(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadOvrCell = createAsyncThunk(
  'cells/downloadOvrCell',
  async (cellUUID: string, { rejectWithValue }) => {
    try {
      const resp = await OVRCellService().downloadOvrCell(cellUUID);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const importCellToProject = createAsyncThunk(
  'cells/importCellToProject',
  async (
    { cell, project }: { cell: CellModel; project: OvrProjectModel },
    { rejectWithValue }
  ) => {
    try {
      const res = await OnlineVirtualResearchService().importCellToProject(
        project.uuid,
        {
          cell: cell.uuid!,
          group_id: 0,
          message: `Cell ${cell.name} imported to project ${project?.name}`,
        }
      );

      // const nextCell = {
      //   ...resp.data,
      //   status: CellStatus.InProject,
      // };

      // dispatch(updateOvrProjectCell(nextCell));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCellFromProject = createAsyncThunk(
  'cells/removeCellFromProject',
  async (
    { projectCellId, projectId }: { projectCellId: string; projectId: string },
    { rejectWithValue }
  ) => {
    try {
      await OnlineVirtualResearchService().deleteProjectCell(
        projectId,
        projectCellId
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOvrCell = createAsyncThunk(
  'cells/createOvrCell',
  async (cell: CellModel, { rejectWithValue }) => {
    try {
      const resp = await OVRCellService().createOVRCell(cell);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOvrCell = createAsyncThunk(
  'cells/updateOvrCell',
  async (
    { cellId, cell }: { cellId: string; cell: CellModel },
    { rejectWithValue }
  ) => {
    try {
      const resp = await OVRCellService().updateOVRCell(cellId, cell);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cellsSlice = createSlice({
  name: 'cells',
  initialState,
  reducers: {
    setCellsDrawerVisible(state, { payload }: { payload: boolean }) {
      state.cellsDrawerVisible = payload;
    },
    setImportedACell(state, { payload }: { payload: boolean }) {
      state.importedACell = payload;
    },
    setCellSearchQuery(state, { payload }: { payload: string }) {
      state.cellSearchQuery = payload;
    },
    setSearchingOvrCells(state, { payload }: { payload: boolean }) {
      state.searchingOvrCells = payload;
    },
    updateOvrProjectCell(state, { payload }: { payload: CellModel }) {
      const nextCells = state.ovrCells.map((cell) => {
        if (cell.name === payload.name) {
          return payload;
        }

        return cell;
      });

      state.ovrCells = nextCells;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOvrProjectCells.pending, (state) => {
        state.fetchingOvrProjectCells = true;
      })
      .addCase(fetchOvrProjectCells.fulfilled, (state, action) => {
        state.fetchingOvrProjectCells = false;
        state.ovrCells = action.payload;
      })
      .addCase(fetchOvrProjectCells.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingOvrProjectCells = false;
      });

    builder
      .addCase(fetchOvrCells.pending, (state) => {
        state.fetchingOvrCells = true;
      })
      .addCase(fetchOvrCells.fulfilled, (state, action) => {
        if (action.payload) {
          state.totalCount = action.payload?.pagination?.count as number;
          state.fetchingOvrCells = false;
          state.ovrCells = action.payload.data;
          state.pagination = action.payload.pagination as PaginationModel;
        }
      })
      .addCase(fetchOvrCells.rejected, (state, action) => {
        state.fetchingOvrCells = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      });

    builder
      .addCase(showOvrCellInProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(showOvrCellInProjects.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(showOvrCellInProjects.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.loading = false;
      });

    builder
      .addCase(searchOvrCellsByName.pending, (state) => {
        state.searchingOvrCells = true;
      })
      .addCase(searchOvrCellsByName.fulfilled, (state, action) => {
        state.searchingOvrCells = false;
        state.ovrCells = action.payload.data;
        if (action.payload.pagination) {
          state.totalCount = action.payload.pagination.count;
          state.pagination = action.payload.pagination;
        }
      })
      .addCase(searchOvrCellsByName.rejected, (state, action) => {
        state.searchingOvrCells = false;
        if (action.payload) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        } else {
          state.error = {
            code: (action.error.code as unknown as number) || 500,
            message:
              action.error.message ||
              'Oops! Something went wrong. Please refresh and try again.',
          };
          handleError({ error: state.error });
        }
      });

    builder.addCase(paginateOvrCells.fulfilled, (state, action: any) => {
      state.fetchingOvrCells = false;
      if (action.payload) {
        state.pagination = action.payload.pagination;
        state.ovrCells = [...action.payload.data];
      }
    });
    builder.addCase(paginateOvrCells.pending, (state) => {
      state.fetchingOvrCells = true;
    });
    builder.addCase(paginateOvrCells.rejected, (state, action) => {
      state.fetchingOvrCells = false;
      if (action.payload) {
        state.error = (action.payload as ApiError).error;
        handleError(action.payload as ApiError);
      } else {
        state.error = {
          code: (action.error.code as unknown as number) || 500,
          message:
            action.error.message ||
            'Oops! Something went wrong. Please refresh and try again.',
        };
        handleError({ error: state.error });
      }
    });

    builder.addCase(importCellToProject.fulfilled, (state, action) => {
      state.loading = false;
      state.importedCell = action.payload;
    });
    builder.addCase(importCellToProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(importCellToProject.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = (action.payload as ApiError).error;
        handleError(action.payload as ApiError);
      } else {
        state.error = {
          code: (action.error.code as unknown as number) || 500,
          message:
            action.error.message ||
            'Oops! Something went wrong. Please refresh and try again.',
        };
        handleError({ error: state.error });
      }
    });
    builder.addCase(removeCellFromProject.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(removeCellFromProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeCellFromProject.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = (action.payload as ApiError).error;
        handleError(action.payload as ApiError);
      } else {
        state.error = {
          code: (action.error.code as unknown as number) || 500,
          message:
            action.error.message ||
            'Oops! Something went wrong. Please refresh and try again.',
        };
        handleError({ error: state.error });
      }
    });
  },
});

export const {
  setSearchingOvrCells,
  setCellSearchQuery,
  updateOvrProjectCell,
  setImportedACell,
  setCellsDrawerVisible,
} = cellsSlice.actions;
