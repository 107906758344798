import { ProductStatusResponse } from './reqres';
import { BASE_API_URL } from '../../config';
import { get, ListApiResponse } from '../base';

export default function ProductStatusService() {
  return Object.freeze({
    listProductStatus(): Promise<ProductStatusResponse> {
      return get(
        `${BASE_API_URL()}/library/product/status`
      ) as Promise<ListApiResponse>;
    },
  });
}
