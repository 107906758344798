import { FC, memo } from 'react';

import { Space } from 'antd';
import './NoResultsFound.less';

import { propsAreEqual } from '../../../util';
import Button from '../Button';

interface NoResultsFoundProps {
  searchQuery: string;
  onClear: () => void;
}

const NoResultsFound: FC<NoResultsFoundProps> = (props) => {
  const { searchQuery, onClear } = props;

  return (
    <div className="no-results-found">
      <Space size="middle" direction="vertical">
        <h3>{`No results for '${searchQuery}'.`}</h3>
        <Button type="primary" onClick={onClear}>
          Clear Search
        </Button>
      </Space>
    </div>
  );
};

export default memo(NoResultsFound, propsAreEqual);
