import { FC, useState } from 'react';
import './TextLabelProperties.less';

import { Select, Space, Switch } from 'antd';
import { TwitterPicker } from 'react-color';

import { rgbToHex } from '../../../../../../../../../util';
import { Draw2DFigureModel } from '../../../../../BuilderLayoutEditor/types';

const { Option } = Select;
interface TextLabelPropertiesProps {
  textLabel: Draw2DFigureModel;
  onUpdateTextLabel: (
    field: 'bgColor' | 'fontSize' | 'bold' | 'fontColor',
    value: string | boolean
  ) => void;
}

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36];
const colors = [
  '#FFFFFF',
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#000000',
];

const TextLabelProperties: FC<TextLabelPropertiesProps> = (props) => {
  const { textLabel, onUpdateTextLabel } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [displayBgColorPicker, setDisplayBgColorPicker] =
    useState<boolean>(false);

  return (
    <div className="fixture-properties" id="fixtureProperties">
      <Space direction="vertical">
        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Font size</div>
          <Select
            getPopupContainer={() =>
              document.getElementById('fixtureProperties')!
            }
            size="small"
            value={textLabel.fontSize}
            onChange={(value) => onUpdateTextLabel('fontSize', value)}
          >
            {fontSizes.map((size) => (
              <Option key={size} value={size}>
                {size}
              </Option>
            ))}
          </Select>
        </div>

        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Color</div>
          <div className="fixture-property-value">
            <div
              style={{
                padding: '3px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                marginRight: '6px',
              }}
              onClick={() => setDisplayColorPicker(!displayColorPicker)}
            >
              <div
                style={{
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                  background: `${rgbToHex(
                    textLabel.fontColor?.red,
                    textLabel.fontColor?.green,
                    textLabel.fontColor?.blue
                  )}`,
                }}
              />
            </div>
            {rgbToHex(
              textLabel.fontColor?.red,
              textLabel.fontColor?.green,
              textLabel.fontColor?.blue
            )}
          </div>

          {displayColorPicker ? (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => setDisplayColorPicker(false)}
              />
              <TwitterPicker
                triangle="hide"
                width="240px"
                colors={colors}
                color={rgbToHex(
                  textLabel.fontColor?.red!,
                  textLabel.fontColor?.green!,
                  textLabel.fontColor?.blue!
                )}
                onChangeComplete={(value: any) =>
                  onUpdateTextLabel('fontColor', value.hex)
                }
              />
            </div>
          ) : null}
        </div>

        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Background color</div>
          <div className="fixture-property-value">
            <div
              style={{
                padding: '3px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                marginRight: '6px',
              }}
              onClick={() => setDisplayBgColorPicker(!displayColorPicker)}
            >
              <div
                style={{
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                  background: `${
                    !!textLabel.bgColor?.hashString
                      ? textLabel.bgColor.hashString
                      : rgbToHex(
                          textLabel.bgColor?.red,
                          textLabel.bgColor?.green,
                          textLabel.bgColor?.blue
                        )
                  }`,
                }}
              />
            </div>
            {!!textLabel.bgColor?.hashString
              ? textLabel.bgColor?.hashString
              : rgbToHex(
                  textLabel.bgColor?.red,
                  textLabel.bgColor?.green,
                  textLabel.bgColor?.blue
                )}
          </div>

          {displayBgColorPicker ? (
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => setDisplayBgColorPicker(false)}
              />
              <TwitterPicker
                triangle="hide"
                width="240px"
                colors={colors}
                color={
                  !!textLabel.bgColor?.hashString
                    ? textLabel.bgColor.hashString
                    : rgbToHex(
                        textLabel.bgColor?.red,
                        textLabel.bgColor?.green,
                        textLabel.bgColor?.blue
                      )
                }
                onChangeComplete={(value: any) =>
                  onUpdateTextLabel('bgColor', value.hex)
                }
              />
            </div>
          ) : null}
        </div>

        <div className="fixture-property-wrapper">
          <div className="fixture-property-name">Bold</div>
          <Switch
            checked={textLabel.bold}
            onChange={(value) => onUpdateTextLabel('bold', value)}
          />
        </div>
      </Space>
    </div>
  );
};

export default TextLabelProperties;
