import { BASE_API_URL } from '../../config';
import { ApiResponse, ListApiResponse, del, get, patch, post } from '../base';
import {
  AddProjectCellRequest,
  CellResponse,
  CreateOVRProjectRequest,
  ImportCellToProjectRequest,
  OVRCellsInProject,
  OVRProjectResponse,
  OVRProjectsResponse,
  OvrProjectCellsResponse,
  ProjectCellResponse,
  UpdateOVRProjectRequest,
  UpdateProjectCellRequest,
} from './reqres';

export default function OnlineVirtualResearchService() {
  return Object.freeze({
    createOVRProject(
      req: CreateOVRProjectRequest
    ): Promise<OVRProjectResponse> {
      return post(`${BASE_API_URL()}/ovr/project`, req);
    },
    updateOvrProject(
      projectId: string,
      req: UpdateOVRProjectRequest,
      query = ''
    ): Promise<OVRProjectResponse> {
      return patch(`${BASE_API_URL()}/ovr/project/${projectId}${query}`, req);
    },
    listOVRProjects(query?: string): Promise<OVRProjectsResponse> {
      return get(
        `${BASE_API_URL()}/ovr/project${query}`
      ) as Promise<ListApiResponse>;
    },

    getOVRProject(
      projectId: string,
      query?: string
    ): Promise<OVRProjectResponse> {
      return get(`${BASE_API_URL()}/ovr/project/${projectId}${query}`);
    },
    getOVRProjectStats(projectId: string, query?: string): Promise<any> {
      return get(`${BASE_API_URL()}/ovr/project/${projectId}/stats${query}`);
    },
    deleteOvrProject(projectId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/ovr/project/${projectId}`);
    },
    importCellToProject(
      projectId: string,
      req: ImportCellToProjectRequest
    ): Promise<CellResponse> {
      return post(`${BASE_API_URL()}/ovr/project/${projectId}/cellgroups`, req);
    },
    listOvrProjectCells(projectId: string): Promise<OvrProjectCellsResponse> {
      return get(`${BASE_API_URL()}/ovr/project/${projectId}/cells`);
    },
    listOvrProjectCellGroups(
      projectId: string
    ): Promise<OvrProjectCellsResponse> {
      return get(`${BASE_API_URL()}/ovr/project/${projectId}/cellgroups`);
    },
    getProjectCell(
      projectId: string,
      projectCellId: string
    ): Promise<ProjectCellResponse> {
      return get(
        `${BASE_API_URL()}/ovr/project/${projectId}/cellgroups/${projectCellId}`
      );
    },
    addProjectCell(
      projectId: string,
      req: AddProjectCellRequest,
      query?: string
    ): Promise<ProjectCellResponse> {
      return post(
        `${BASE_API_URL()}/ovr/project/${projectId}/cellgroups${query}`,
        req
      );
    },
    updateProjectCell(
      projectId: string,
      cellId: string,
      req: UpdateProjectCellRequest,
      query?: string
    ): Promise<ProjectCellResponse> {
      return patch(
        `${BASE_API_URL()}/ovr/project/${projectId}/cellgroups/${cellId}${query}`,
        req
      );
    },
    deleteProjectCell(projectId: string, cellId: string): Promise<ApiResponse> {
      return del(
        `${BASE_API_URL()}/ovr/project/${projectId}/cellgroups/${cellId}`
      );
    },
    exportOVRProjectData(projectId: string): Promise<ApiResponse> {
      return get(
        `${BASE_API_URL()}/ovr/project/${projectId}/exportCompositorData`
      );
    },
    deleteCellGroup(projectId: string, groupId: number): Promise<ApiResponse> {
      return del(
        `${BASE_API_URL()}/ovr/project/${projectId}/cellgroups/group/${groupId}`
      );
    },
    listOVRCells(query?: string): Promise<ApiResponse> {
      return get(`${BASE_API_URL()}/ovr/cell${query}`);
    },
    listOVRCellsInProjects(cellIds: string[]): Promise<OVRCellsInProject> {
      return post(`${BASE_API_URL()}/ovr/checker/cell-in-any-project`, cellIds);
    },
  });
}
