import { FC, useEffect, useCallback, useMemo, useState, memo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, message, Space } from 'antd';
import { History, Location } from 'history';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { RoleFormLocationState } from './types';
import { ApiError, handleError } from '../../../../api/base';
import { PermissionModel } from '../../../../models/permission';
import { initialNewRoleModel, RoleModel } from '../../../../models/role';
import {
  fetchPermissions,
  PermissionsState,
} from '../../../../store/features/acl/permissions/permissionsSlice';
import {
  createRole,
  RolesState,
  updateRole,
} from '../../../../store/features/acl/roles/rolesSlice';
import { RootState, useAppDispatch } from '../../../../store/index';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import PermissionTableList from '../../../views/RolesView/components/PermissionTableList';

interface UserFormProps {
  history: History;
  location: Location<RoleFormLocationState>;
}

const { TextArea } = Input;

const UserForm: FC<UserFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();

  const { savingRole } = useSelector<RootState, RolesState>(
    (state) => state.roles
  );

  const isNewRole = useMemo(() => !location.state, [location.state]);
  const fields = useMemo(
    () => Object.keys(isNewRole ? initialNewRoleModel : location.state?.data),
    [isNewRole, location.state?.data]
  );

  const [form] = Form.useForm();
  const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionModel[]
  >(location.state?.data.permissions || []);

  const { value: permissions, fetchingPermissions } = useSelector<
    RootState,
    PermissionsState
  >((state) => state.permissions);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveRole = useCallback(
    async (role: Omit<RoleModel, 'name'>) => {
      const res: any = isNewRole
        ? await dispatch(
            createRole({
              ...role,
              permissions: selectedPermissions.map(({ name }) => name),
            })
          )
        : await dispatch(
            updateRole({
              roleId: location.state?.data?.id!,
              role: {
                ...role,
                permissions: selectedPermissions.map(({ name }) => name),
              },
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Role saved.');
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewRole,
      selectedPermissions,
      location.state?.data?.id,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: Omit<RoleModel, 'name'>) => {
      form.validateFields(fields).then(() => saveRole(values));
    },
    [fields, form, saveRole]
  );

  const getInitialValues = () => {
    if (isNewRole) {
      return { ...initialNewRoleModel };
    }
    return { ...location.state.data };
  };

  return (
    <FormWrapper
      title={`${isNewRole ? 'Create new' : 'Edit'} Role`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Role Name"
          name="display_name"
          rules={[{ required: true, message: 'Role name is required.' }]}
        >
          <Input data-cy="role-form-input" placeholder="Role name" />
        </Form.Item>
        <Form.Item label="Role Description" name="description">
          <TextArea
            data-cy="role-form-input"
            rows={4}
            placeholder="Description"
          />
        </Form.Item>
        {fetchingPermissions ? (
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        ) : (
          <Form.Item label="Add Permissions">
            <PermissionTableList
              permissions={permissions}
              selectedPermissions={selectedPermissions}
              loading={fetchingPermissions}
              onPermissionsSelected={setSelectedPermissions}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={savingRole}
              data-cy="role-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {`${isNewRole ? 'Create' : 'Save'} Role`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(UserForm, propsAreEqual));
