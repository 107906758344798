import { ManufacturerModel } from '../manufacturer';

export interface ParentCompanyModel {
  key?: string;
  name: string;
  status?: string | null;
  statusCheckValue?: boolean | undefined;
  in_processing: number;
  in_library: number;
  manufacturers?: ManufacturerModel[];
  short_name?: string;
  client_type: string;
  in_use?: boolean;
  uuid: string;
}

export const initialNewParentCompanyModel = {
  name: '',
};
