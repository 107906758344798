import React, { createRef, CSSProperties } from 'react';

import { message, Modal } from 'antd';
import cx from 'classnames';
import draw2d from 'draw2d';
import { History } from 'history';
import { duration } from 'moment';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../api/base';
import StoreBuildService from '../../../../../api/store-build';
import { BASE_API_URL } from '../../../../../config';
import { BuildSpace, StoreBuildModel } from '../../../../../models/store-build';
import {
  convertParamsToQuery,
  generateRandomInt,
  generateUuidv4,
  getAuthToken,
  getMe,
  rgbToHex,
} from '../../../../../util';
import { RoutePath } from '../../../AppRoot/types';
import {
  AUTO_SAVE_COUNT,
  COPY_PASTED_ITEM_OFFSET,
  DEFAULT_BUILD_EDITOR_SETTINGS,
  DEFAULT_COUNTER_FIXTURE_ID,
  DEFAULT_L_COUNTER_FIXTURE_ID,
  DEFAULT_NO_SELF_CHECK_COUNTER_FIXTURE_ID,
  DEFAULT_SELECTED_SHOPPER,
  DEFAULT_SHOPPER_ELAPSED_TIME,
  ENV_BG_IMAGE_ID,
  MAX_QUEUE_LINE_SHOPPERS,
  MAX_QUEUE_LINES,
} from '../../config';
import { EnvironmentModel, environmentsByIdMap } from '../../environments';
import {
  FigureModel,
  fixtureByIdMap,
  helperByIdMap,
  shopperByIdMap,
  staticFixtureByIdMap,
} from '../../figures';
import {
  generateShopperAttributesFromFigure,
  getFigureDimensions,
  getMeasureToolAttributes,
  getNextRenderType,
  getNextRotatedRender,
  getTextAttributes,
  isBackgroundEnvironmentFigure,
  isFigureRotatedSideways,
  isFixture,
  isFixtureId,
  isGiftCardCenterFixture,
  isGroupFigure,
  isHelperId,
  isLockerFixture,
  isLottoKioskFixture,
  isLoweFixture,
  isMeasureTool,
  isMeasureToolId,
  isOpenAirCoolerFixture,
  isRackFixture,
  isShopper,
  isShopperId,
  isStaticFixture,
  isStaticFixtureId,
  isText,
  isTextId,
  isTobaccoCaseFixture,
  renderTypeToRotationAngle,
  rotationAngleToRenderType,
  scaleCanvasFigure,
} from '../../util';
import BuilderLayoutToolbar, {
  BuilderToolbarActions,
} from '../BuilderLayoutToolbar';
import BuilderSidePanel from '../BuilderSidePanel';
import { BuildSettings } from '../BuilderSidePanel/components/BuilderSettings';
import { SaveStatus } from '../StoreBuilder';
import './BuilderLayoutEditor.less';
import {
  Draw2DFigureModel,
  EnvironmentRenderType,
  FigureAttributesModel,
  FixtureId,
  HelperId,
  initialQueueLineState,
  MeasureToolAttributesModel,
  QueueLineModel,
  RenderType,
  RSFFixtureDataModel,
  RSFQueueLineModel,
  ShopperId,
  ShopperType,
  ShopperUserDataModel,
  StaticItemId,
  TextAttributesModel,
} from './types';

interface BuilderLayoutEditorProps {
  build: StoreBuildModel;
  sidebarCollapsed: boolean;
  history: History;
  router: any;
  onSaveStatusChange: (status: SaveStatus) => void;
}
interface BuilderLayoutEditorState {
  settings: BuildSettings;
  selectedFigures: Draw2DFigureModel[];
  isFirstView: boolean;
  isBuildSaved: boolean;
  isInQueueLineMode: boolean;
  isAutoSaveOn: boolean;
  isAutoTakeScreenShotOn: boolean;
  selectedShopperType: ShopperType;
  currentQueueLine: QueueLineModel | null;
  overlappingShoppers: string[];
  copiedFigures: Draw2DFigureModel[];
  environment: EnvironmentModel;
}

const initialBuilderLayoutEditorState = (
  environment: EnvironmentModel
): BuilderLayoutEditorState => ({
  settings: DEFAULT_BUILD_EDITOR_SETTINGS,
  selectedFigures: [],
  isFirstView: true,
  isBuildSaved: true,
  isInQueueLineMode: false,
  isAutoSaveOn: true,
  isAutoTakeScreenShotOn: true,
  selectedShopperType: DEFAULT_SELECTED_SHOPPER,
  currentQueueLine: null,
  overlappingShoppers: [],
  copiedFigures: [],
  environment,
});

class BuilderLayoutEditor extends React.Component<
  BuilderLayoutEditorProps,
  BuilderLayoutEditorState
> {
  private canvas: any = null;
  private buildData = JSON.parse(this.props.build.data);
  private timerId?: NodeJS.Timeout = undefined;
  private environment =
    environmentsByIdMap[this.props.build.feb_design_area_id];
  private canvasWrapperEl = createRef<HTMLDivElement>();

  state: BuilderLayoutEditorState = initialBuilderLayoutEditorState(
    this.environment
  );

  componentDidMount = () => {
    this.initStoreBuildEditor();
    this.handleAddEventListeners();
    if (this.state.isFirstView && this.state.isAutoSaveOn) {
      this.handleSaveBuild();
      this.setState({ isFirstView: false });
    }
  };

  componentWillUnmount = () => {
    this.handleRemoveEventListeners();
  };

  get rectangleSelectionPolicy() {
    const rectangleSelectionPolicy =
      new draw2d.policy.figure.RectangleSelectionFeedbackPolicy();
    rectangleSelectionPolicy.moved = this.rectangleSelectionPolicyMoved();
    return rectangleSelectionPolicy;
  }

  get rectangleSelectionWithWarningPolicy() {
    const rectangleSelectionPolicy =
      new draw2d.policy.figure.RectangleSelectionFeedbackPolicy();
    rectangleSelectionPolicy.moved = this.rectangleSelectionPolicyMoved(true);
    return rectangleSelectionPolicy;
  }

  get environmentBackgroundFigure(): Draw2DFigureModel | undefined {
    return this.canvas
      .getFigures()
      .data.find((fig: Draw2DFigureModel) => fig.id === ENV_BG_IMAGE_ID);
  }

  get allFiguresWithoutBackground(): Draw2DFigureModel[] {
    return this.canvas
      .getFigures()
      .data.filter(
        (fig: Draw2DFigureModel) => !isBackgroundEnvironmentFigure(fig)
      );
  }

  private initStoreBuildEditor = () => {
    if (!this) {
      throw new Error('this is null or undefined');
    }
    const hasFigureData = this?.buildData?.data?.length > 0;

    this?.initCanvas();
    this?.initStoreEnvironment();
    this?.scrollToBuildSpace();
    hasFigureData && this?.addInitialFigures();
  };

  private initCanvas = () => {
    this.canvas = new draw2d.Canvas('canvas');
    this.setCanvasDimensions();
    this.setCanvasPolicies();
    this.setCanvasEventListeners();
  };

  private getEnvBgImageAttrs = (renderType: EnvironmentRenderType) => {
    return {
      id: ENV_BG_IMAGE_ID,
      path: this.environment.renders[renderType],
      x: 0,
      y: 0,
      width: this.environment.width,
      height: this.environment.height,
      resizeable: false,
      selectable: false,
      draggable: false,
    };
  };

  private initStoreEnvironment = () => {
    const envAttrs = this.getEnvBgImageAttrs('linedWithGrid');
    const envBg = new draw2d.shape.basic.Image(envAttrs);
    this.canvas.add(envBg);
    envBg.toBack();

    this.addDefaultStaticFixtures();
  };

  private addDefaultStaticFixtures = () => {
    const hasDefaultStaticFixture =
      this.environment.id === BuildSpace.CStore ||
      this.environment.id === BuildSpace.CStoreLCounter ||
      this.environment.id === BuildSpace.CStoreNoSelfCheckout;
    const hasFigureData = this.buildData.data?.length > 0;

    if (hasFigureData || !hasDefaultStaticFixture) return;

    console.log(DEFAULT_NO_SELF_CHECK_COUNTER_FIXTURE_ID);

    switch (this.environment.id) {
      case BuildSpace.CStore:
        this.addCounterFixture(DEFAULT_COUNTER_FIXTURE_ID, 'topdown_l');
        break;
      case BuildSpace.CStoreLCounter:
        this.addCounterFixture(DEFAULT_L_COUNTER_FIXTURE_ID, 'topdown_l');
        break;
      case BuildSpace.CStoreNoSelfCheckout:
        this.addCounterFixture(
          DEFAULT_NO_SELF_CHECK_COUNTER_FIXTURE_ID,
          'topdown_l'
        );
        break;
      default:
        throw new Error('Unknown static build env');
    }
  };

  private scrollToBuildSpace = () => {
    setTimeout(() => {
      if (this.canvasWrapperEl && this.canvasWrapperEl.current) {
        this.canvasWrapperEl.current.scrollTo({
          left:
            this.environment.space.x > 30
              ? this.environment.space.x - 30
              : this.environment.space.x,
          top:
            this.environment.space.y > 90
              ? this.environment.space.y - 90
              : this.environment.space.y,
          behavior: 'smooth',
        });
      }
    }, 50);
  };

  private addCounterFixture = (
    counterId: keyof typeof staticFixtureByIdMap,
    renderType: 'topdown_l' | 'topdown_r'
  ) => {
    const counter = this.generateCounterFixture(counterId, renderType);
    this.canvas.add(counter, counter.x, counter.y);
    this.canvas.setCurrentSelection(counter);
    this.setState({ selectedFigures: [counter] });
    this.startAutoSaveTimer();
  };

  private generateCounterFixture = (
    fixtureId: keyof typeof staticFixtureByIdMap,
    renderType: 'topdown_l' | 'topdown_r'
  ) => {
    const isLCounterFixture = fixtureId === 104 || fixtureId === 105;
    const isNoSelfCheckCounterFixture =
      fixtureId === 999106 || fixtureId === 999107 || fixtureId === 999108;
    const fixture = staticFixtureByIdMap[fixtureId];

    const width = scaleCanvasFigure(fixture.d, fixture.som);
    const height = scaleCanvasFigure(fixture.w, fixture.som);

    const counterY = this.environment.space.height - height;
    let xVal;
    let yVal;

    switch (true) {
      case isLCounterFixture:
        xVal = 192;
        yVal = 538.47;
        break;
      case isNoSelfCheckCounterFixture:
        xVal = 432;
        yVal = 530.09;
        break;
      default:
        xVal = this.environment.space.x;
        yVal = counterY + this.environment.space.y;
        break;
    }

    const attrs = {
      path: fixture.renders[renderType] as RenderType,
      x: xVal,
      y: yVal,
      width,
      height,
      resizeable: false,
      selectable: true,
      rotation: 270,
      userData: {
        id: generateUuidv4(),
        fixtureId,
        renderType,
        h: fixture.h,
        name: fixture?.name,
        key: fixture.key,
      },
    };

    const counter = new draw2d.shape.basic.Image(attrs);
    counter.installEditPolicy(this.rectangleSelectionPolicy);
    counter.setDraggable(false);
    counter.on('click', () => {
      this.canvas.setCurrentSelection(counter);
      this.setState({
        selectedFigures: [counter],
      });
    });

    return counter;
  };

  private setCanvasDimensions = () => {
    this.canvas.setDimension({
      width: this.environment.width,
      height: this.environment.height,
    });
  };

  // TODO: Test to if this is still needed, may change it for panning policy
  // private setCanvasCoordinates = () => {
  // this.canvas.fromDocumentToCanvasCoordinate = $.proxy((x: any, y: any) => {
  //   return new draw2d.geo.Point(
  //     (x + window.pageXOffset - this.canvas.getAbsoluteX() + this.canvas.getScrollLeft()) * this.canvas.zoomFactor,
  //     (y + window.pageYOffset - this.canvas.getAbsoluteY() + this.canvas.getScrollTop()) * this.canvas.zoomFactor);
  // }, this.canvas);
  // }

  private setCanvasPolicies = () => {
    this.canvas.installEditPolicy(new draw2d.policy.canvas.WheelZoomPolicy());
    this.canvas.installEditPolicy(
      new draw2d.policy.canvas.SnapToGeometryEditPolicy()
    );
    this.canvas.installEditPolicy(
      new draw2d.policy.canvas.SnapToInBetweenEditPolicy()
    );
    this.canvas.installEditPolicy(
      new draw2d.policy.canvas.SnapToCenterEditPolicy()
    );
    this.canvas.installEditPolicy(new draw2d.policy.canvas.ZoomPolicy());
  };

  private setCanvasEventListeners = () => {
    this.canvas.getCommandStack().addEventListener((event: any) => {
      this.environmentBackgroundFigure?.toBack(); // might not need this anymore
      if (
        event &&
        event.command?.label !== 'Resize Shape' &&
        event.command?.label !== 'Execute Commands'
      )
        return;
      this.canvas.setCurrentSelection(this.state.selectedFigures[0]);
    });
    this.canvas!.onDrop = this.handleOnFixtureDropped;
    this.canvas!.on('select', this.handleCanvasFiguresSelected);
  };

  private handleAddEventListeners = () => {
    document.addEventListener('keydown', this.handleKeyPressed);
  };

  private handleRemoveEventListeners = () => {
    document.removeEventListener('keydown', this.handleKeyPressed);
  };

  private startAutoSaveTimer = () => {
    const { onSaveStatusChange } = this.props;

    onSaveStatusChange(SaveStatus.Unsaved);
    this.setState({ isBuildSaved: false });

    if (!this.state.isAutoSaveOn) return;

    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.handleSaveBuild();
    }, AUTO_SAVE_COUNT * 1000);
  };

  private renderNextRenderedFigures = (figs: Draw2DFigureModel[]) => {
    if (figs.length === 0) return;
    figs.forEach((fig: Draw2DFigureModel) => {
      if (isBackgroundEnvironmentFigure(fig)) return this.canvas.add(fig);
      if (isStaticFixture(fig)) {
        this.addCounterFixture(
          fig.userData.fixtureId as StaticItemId,
          fig.userData.renderType as 'topdown_l' | 'topdown_r'
        );
        return;
      }
      const isShopper = isShopperId(fig.userData.fixtureId);
      const extras = isShopper ? fig.userData.shopper : undefined;
      this.handleAddFigure(
        fig.userData.fixtureId,
        fig.x,
        fig.y,
        fig.userData.renderType,
        extras
      );
    });
  };

  private rectangleSelectionPolicyMoved =
    (withWarning?: boolean) => (_: any, figure: any) => {
      if (figure.selectionHandles.isEmpty()) return;

      const objHeight = figure.getHeight();
      const objWidth = figure.getWidth();
      const xPos = figure.getAbsoluteX();
      const yPos = figure.getAbsoluteY();

      const r1 = figure.selectionHandles.find(function (handle: any) {
        return handle.type === 1;
      });
      const r3 = figure.selectionHandles.find(function (handle: any) {
        return handle.type === 3;
      });
      const r5 = figure.selectionHandles.find(function (handle: any) {
        return handle.type === 5;
      });
      const r7 = figure.selectionHandles.find(function (handle: any) {
        return handle.type === 7;
      });

      r1.setPosition(
        xPos - (r1.getWidth() - 2.5),
        yPos - (r1.getHeight() - 2.5)
      );
      r3.setPosition(xPos + objWidth - 2.5, yPos - (r3.getHeight() - 2.5));
      r5.setPosition(xPos + objWidth - 2.5, yPos + objHeight - 2.5);
      r7.setPosition(xPos - (r7.getWidth() - 2.5), yPos + objHeight - 2.5);

      if (!figure.getKeepAspectRatio() && figure.isResizeable()) {
        const r2 = figure.selectionHandles.find(function (handle: any) {
          return handle.type === 2;
        });
        const r4 = figure.selectionHandles.find(function (handle: any) {
          return handle.type === 4;
        });
        const r6 = figure.selectionHandles.find(function (handle: any) {
          return handle.type === 6;
        });
        const r8 = figure.selectionHandles.find(function (handle: any) {
          return handle.type === 8;
        });

        r2.setPosition(
          xPos + objWidth / 2 - r2.getWidth() / 2,
          yPos - r2.getHeight()
        );
        r4.setPosition(
          xPos + objWidth,
          yPos + objHeight / 2 - r4.getHeight() / 2
        );
        r6.setPosition(
          xPos + objWidth / 2 - r6.getWidth() / 2,
          yPos + objHeight
        );
        r8.setPosition(
          xPos - r8.getWidth(),
          yPos + objHeight / 2 - r8.getHeight() / 2
        );
      }
      const box = figure.selectionHandles.last();
      box.setPosition(figure.getAbsolutePosition());
      box.setDimension(figure.getWidth(), figure.getHeight());
      box.setRotationAngle(figure.getRotationAngle());
      if (withWarning) {
        box.setColor(`#EB5757`);
      }
      box.setStroke(1.5);
    };

  private handleOnFixtureDropped = (event: any, x: number, y: number) => {
    const droppedFixture = event[0];
    const calculatedX = x + droppedFixture.offsetWidth / 3 - window.pageXOffset;
    const calculatedY =
      y + droppedFixture.offsetHeight / 3 - window.pageYOffset;
    const isRackingOTBLeftFixture =
      +droppedFixture.id === 40 ||
      +droppedFixture.id === 42 ||
      +droppedFixture.id === 44;
    const isRackingOTBRightFixture =
      +droppedFixture.id === 41 ||
      +droppedFixture.id === 43 ||
      +droppedFixture.id === 45;

    const renderType = (): RenderType => {
      if (isRackingOTBRightFixture) {
        return this.state.settings.displayRenderedImages
          ? 'topdown_rR90'
          : 'topdown_lR90';
      }
      if (isRackingOTBLeftFixture) {
        return this.state.settings.displayRenderedImages
          ? 'topdown_rR270'
          : 'topdown_lR270';
      }
      return this.state.settings.displayRenderedImages
        ? 'topdown_r'
        : 'topdown_l';
    };

    this.handleAddFigure(
      droppedFixture.id,
      calculatedX,
      calculatedY,
      renderType()
    );
  };

  private handleAddFigure = (
    id: FixtureId | ShopperId | HelperId,
    x: number,
    y: number,
    renderType?: RenderType,
    extras?: any,
    focusOnFigure = true
  ): Draw2DFigureModel => {
    const figure = this.generateCanvasFigure(id, x, y, renderType, extras);
    this.addFigureToCanvas(figure);
    focusOnFigure && this.selectNewlyAddedFigure(figure);
    figure.toFront();
    this.startAutoSaveTimer();
    if (isShopperId(id) && this.isQueueLineMaxedOut())
      this.handleToggleQueueLineMode();
    return figure;
  };

  private isQueueLineMaxedOut = (): boolean => {
    const { currentQueueLine } = this.state;
    return currentQueueLine?.shoppers?.length! + 1 === MAX_QUEUE_LINE_SHOPPERS;
  };

  private generateCanvasFigure = (
    id: FixtureId | ShopperId | HelperId,
    x: number,
    y: number,
    renderType?: RenderType,
    extras?: any
  ) => {
    switch (true) {
      case isFixtureId(id):
        return this.generateFixture(id as FixtureId, x, y, renderType!);
      case isShopperId(id):
        return this.generateShopper(id as ShopperId, x, y, renderType!, extras);
      case isTextId(id):
        return this.generateText(x, y, extras);
      case isMeasureToolId(id):
        return this.generateMeasureTool(id as 21 | 24, x, y, extras);
    }
  };

  private generateMeasureTool = (
    id: 21 | 24,
    x: number,
    y: number,
    attrs?: MeasureToolAttributesModel
  ) => {
    const measureTool = helperByIdMap[id];
    const attributes = getMeasureToolAttributes(measureTool, x, y, attrs);
    const figure =
      measureTool.key === 'RectangleMeasureTool'
        ? new draw2d.shape.basic.Rectangle(attributes)
        : new draw2d.shape.basic.Oval(attributes);
    figure.setColor('#5196F7');
    figure.setBackgroundColor('rgba(81, 150, 247, 0.35)');
    figure.on('dragend', () => {
      this.startAutoSaveTimer();
      this.checkForFiguresOutsideOfBoundaries();
    });
    return figure;
  };

  private generateText = (
    x: number,
    y: number,
    attrs?: TextAttributesModel
  ) => {
    const attributes = getTextAttributes(x, y, attrs);
    const figure = new draw2d.shape.basic.Label(attributes);
    figure.installEditor(new draw2d.ui.LabelInplaceEditor());
    figure.setRotationAngle(!!attrs ? attrs.rotationAngle : 360);
    figure.on('dblclick', this.styleInplaceEditor);
    return figure;
  };

  private applyLabel = (selection: Draw2DFigureModel) => {
    if (
      isRackFixture(selection) ||
      isLoweFixture(selection) ||
      isTobaccoCaseFixture(selection) ||
      isOpenAirCoolerFixture(selection) ||
      isLottoKioskFixture(selection) ||
      isGiftCardCenterFixture(selection) ||
      isLockerFixture(selection)
    ) {
      // Remove previous label before adding new one.
      selection.remove(selection.getChildren().data[0]);

      const rotationAngle = renderTypeToRotationAngle(
        selection.userData.renderType
      );

      if (rotationAngle === 90) {
        selection.add(
          new draw2d.shape.basic.Label({ text: 'Front' }),
          new draw2d.layout.locator.LeftLocator()
        );
        return;
      }
      if (rotationAngle === 180) {
        selection.add(
          new draw2d.shape.basic.Label({ text: 'Front' }),
          new draw2d.layout.locator.TopLocator()
        );
        return;
      }
      if (rotationAngle === 270) {
        selection.add(
          new draw2d.shape.basic.Label({ text: 'Front' }),
          new draw2d.layout.locator.RightLocator()
        );
        return;
      }
      selection.add(
        new draw2d.shape.basic.Label({ text: 'Front' }),
        new draw2d.layout.locator.BottomLocator()
      );
    }
  };

  private generateFixture = (
    id: FixtureId,
    x: number,
    y: number,
    renderType: RenderType
  ) => {
    const fixture = fixtureByIdMap[id];
    const dim = getFigureDimensions(fixture, renderType);
    const attrs = this.getFigureAttributes(fixture, dim, x, y, renderType);
    const figure = new draw2d.shape.basic.Image(attrs);

    // Add Label and Apply Rotation
    this.applyLabel(figure);

    figure.installEditPolicy(this.rectangleSelectionPolicy);
    figure.on('dragend', () => {
      this.startAutoSaveTimer();
      this.checkForFiguresOutsideOfBoundaries();
    });
    return figure;
  };

  private generateShopper = (
    id: ShopperId,
    x: number,
    y: number,
    renderType: RenderType,
    shopperData?: any
  ) => {
    const shopper = shopperByIdMap[id];
    const dim = getFigureDimensions(shopper, renderType);
    const attrs = this.getFigureAttributes(
      shopper,
      dim,
      x,
      y,
      renderType,
      shopperData
    );
    const figure = new draw2d.shape.basic.Image(attrs);
    const figureChild = this.getShopperFigureChild(
      attrs.userData.shopper!,
      renderType
    );
    figure.installEditPolicy(this.rectangleSelectionPolicy);
    figure.add(figureChild, new draw2d.layout.locator.CenterLocator());
    figure.on('dragend', () => {
      this.startAutoSaveTimer();
      this.checkForOverlappingShoppers();
      this.checkForFiguresOutsideOfBoundaries();
    });
    return figure;
  };

  private checkForFiguresOutsideOfBoundaries = () => {
    this.allFiguresWithoutBackground.forEach((fig: Draw2DFigureModel) => {
      if (this.isFigureWithinBoundaries(fig.x, fig.y, fig.height, fig.width)) {
        fig.installEditPolicy(this.rectangleSelectionPolicy);
        return;
      }
      fig.installEditPolicy(this.rectangleSelectionWithWarningPolicy);
    });
  };

  private getFigureAttributes = (
    figure: FigureModel,
    dim: { height: number; width: number },
    x: number,
    y: number,
    renderType: RenderType,
    shopperData?: any
  ): FigureAttributesModel => {
    if (isShopperId(figure.id)) {
      return {
        path: figure.renders[renderType] as RenderType,
        x,
        y,
        width: dim.width,
        height: dim.height,
        resizeable: false,
        userData: {
          id: generateUuidv4(),
          fixtureId: figure.id,
          renderType,
          h: figure.h,
          name: figure?.name,
          key: figure.key,
          shopper: this.getShopperData(shopperData),
        },
      };
    }
    return {
      path: figure.renders[renderType] as RenderType,
      x,
      y,
      width: dim.width,
      height: dim.height,
      resizeable: false,
      userData: {
        id: generateUuidv4(),
        fixtureId: figure.id,
        renderType,
        h: figure.h,
        name: figure?.name,
        key: figure.key,
      },
    };
  };

  private getShopperData = (shopperData?: any): ShopperUserDataModel => {
    const { selectedShopperType, currentQueueLine } = this.state;
    return shopperData!
      ? shopperData
      : {
          orderInLine: currentQueueLine?.shoppers?.length! + 1,
          lineId: currentQueueLine?.lineId!,
          color: currentQueueLine?.color!,
          type: selectedShopperType,
          elapsedWaitTime: DEFAULT_SHOPPER_ELAPSED_TIME,
        };
  };

  private addFigureToCanvas = (figure: any) => {
    const command = new draw2d.command.CommandAdd(
      this.canvas,
      figure,
      figure.x,
      figure.y
    );
    this.canvas.getCommandStack().execute(command);
  };

  private selectNewlyAddedFigure = (figure: any) => {
    this.canvas.setCurrentSelection(figure);
    this.setState({ selectedFigures: [figure] });
  };

  private getInitialFigureExtras = (
    figureId: number,
    figure: RSFFixtureDataModel
  ) => {
    switch (true) {
      case isTextId(figureId):
        return {
          rotationAngle: figure.r,
          text: figure.text,
          bgColor: figure.bgColor,
          bold: figure.bold,
          fontSize: figure.fontSize,
          fontColor: figure.fontColor,
        };
      case isMeasureToolId(figureId):
        return {
          height: figure.height,
          width: figure.width,
        };
      case isShopperId(figureId):
        return {
          orderInLine: figure.orderInLine,
          lineId: figure.lineId,
          color: figure.lineColor,
          type: figureId,
          elapsedWaitTime: figure.elapsedWaitTime,
        };
      default:
        return undefined;
    }
  };

  private addInitialFigures = () => {
    const { originData, data: figureData } = this.buildData;
    const { displayRenderedImages } = this.state.settings;

    figureData.forEach((fig: RSFFixtureDataModel) => {
      const figureId = this.buildData.library[fig.key].id;

      if (isStaticFixtureId(figureId)) {
        this.addCounterFixture(figureId, 'topdown_l');
        return;
      }

      const x = fig.x + this.environment.space.x + originData.x;
      const y = fig.y + this.environment.space.y + originData.y;
      const renderType =
        isShopperId(figureId) || isFixtureId(figureId)
          ? rotationAngleToRenderType(fig.r, displayRenderedImages)
          : undefined;
      const extras = this.getInitialFigureExtras(figureId, fig);
      this.handleAddFigure(figureId, x, y, renderType, extras);
    });
  };

  private generateQueueLineStateFromCanvas = (): QueueLineModel[] => {
    if (!this.canvas) return [];

    const shoppers = this.allFiguresWithoutBackground.filter(
      (fig: Draw2DFigureModel) => isShopper(fig)
    );

    let queueLines: QueueLineModel[] = initialQueueLineState;

    if (shoppers.length === 0) return queueLines;

    shoppers.forEach((shopper: Draw2DFigureModel) => {
      const itemAttributes = generateShopperAttributesFromFigure(shopper);

      queueLines = queueLines.map((l: QueueLineModel) => {
        if (l.lineId === shopper.userData.shopper?.lineId) {
          return {
            ...l,
            shoppers: [...l.shoppers, itemAttributes],
          };
        }
        return l;
      });
    });

    return queueLines;
  };

  private removeShopperFigures = (shoppers: Draw2DFigureModel[]) => {
    let nextQueueLinesValue = this.generateQueueLineStateFromCanvas();
    let editedQueueLines: number[] = [];

    shoppers.forEach((shopper: Draw2DFigureModel) => {
      // Keep track of edited queue lines
      if (!editedQueueLines.includes(shopper.userData.shopper?.lineId!)) {
        editedQueueLines = [
          ...editedQueueLines,
          shopper.userData.shopper?.lineId!,
        ];
      }
      // Get current queue line
      const currentQueueLine = nextQueueLinesValue.find(
        (line: QueueLineModel) =>
          line.lineId === shopper.userData.shopper?.lineId!
      );
      // Remove shopper from queueLine.shopper value
      const updatedQueueLineShoppers = currentQueueLine?.shoppers.filter(
        (s: FigureAttributesModel) => {
          if (
            s.userData.shopper?.lineId === shopper.userData.shopper?.lineId &&
            s.userData.shopper?.orderInLine ===
              shopper.userData.shopper?.orderInLine
          ) {
            return false;
          }
          return true;
        }
      );
      // Update nextQueueLinesValue with updatedQueueLineShoppers value
      nextQueueLinesValue = nextQueueLinesValue.map((line: QueueLineModel) => {
        if (line.lineId === currentQueueLine?.lineId) {
          return {
            ...line,
            shoppers: updatedQueueLineShoppers!,
          };
        }
        return line;
      });
    });

    // Resequence newly edited queue lines
    editedQueueLines.forEach((lineId: number) => {
      // Get current queue line
      const currentQueueLine = nextQueueLinesValue.find(
        (line: QueueLineModel) => line.lineId === lineId
      );
      // Sort queue line shoppers in order
      const orderedQueueLineShoppers = currentQueueLine?.shoppers.sort(
        (a: FigureAttributesModel, b: FigureAttributesModel) => {
          if (
            a.userData.shopper?.orderInLine! > b.userData.shopper?.orderInLine!
          )
            return 1;
          return -1;
        }
      );
      // Reorder queue line shoppers by sorted index value
      const updatedQueueLineShoppers = orderedQueueLineShoppers?.map(
        (shopper: FigureAttributesModel, index: number) => {
          return {
            ...shopper,
            userData: {
              ...shopper.userData,
              shopper: {
                ...shopper.userData.shopper!,
                orderInLine: index + 1,
              },
            },
          };
        }
      );
      // Update nextQueueLinesValue with resequenced updatedQueueLineShoppers value
      nextQueueLinesValue = nextQueueLinesValue.map((line: QueueLineModel) => {
        if (line.lineId === currentQueueLine?.lineId) {
          return {
            ...line,
            shoppers: updatedQueueLineShoppers!,
          };
        }
        return line;
      });
    });

    this.updateCanvasQueueLines(nextQueueLinesValue);
    this.startAutoSaveTimer();
  };

  private updateCanvasQueueLines = (nextQueueLinesValue: QueueLineModel[]) => {
    nextQueueLinesValue.forEach((line: QueueLineModel) => {
      const prevShoppers = this.allFiguresWithoutBackground.filter(
        (fig: Draw2DFigureModel) => fig.userData.shopper?.lineId === line.lineId
      );
      line.shoppers.forEach((nextShopper: FigureAttributesModel) => {
        const { userData, x, y } = nextShopper;
        this.handleAddFigure(
          userData.fixtureId,
          x,
          y,
          userData.renderType,
          userData.shopper,
          false
        );
      });
      prevShoppers.forEach((prevShopper: any) =>
        this.removeFigure(prevShopper)
      );
    });
  };

  private removeFigure = (figure: Draw2DFigureModel) => {
    if (isGroupFigure(figure)) {
      const deleteGroup = new draw2d.command.CommandDeleteGroup(figure);
      this.canvas.getCommandStack().execute(deleteGroup);
      return;
    }
    const deleteFigure = new draw2d.command.CommandDelete(figure);
    this.canvas.getCommandStack().execute(deleteFigure);
  };

  private handleKeyPressed = (event: any) => {
    const { selectedFigures } = this.state;
    const hasSelectedFigures = !!selectedFigures && selectedFigures.length > 0;
    const isTargetingBody = event.target.nodeName === 'BODY';

    if (!hasSelectedFigures || !isTargetingBody) return;

    // Checking for 'Backspace' or 'Delete'.
    if (event.keyCode === 8 || event.keyCode === 46) {
      let shoppers: Draw2DFigureModel[] = [];

      selectedFigures.forEach((fig) => {
        if (isShopper(fig)) {
          shoppers = [...shoppers, fig];
          return;
        }

        if (isGroupFigure(fig)) {
          fig.getAssignedFigures().data.forEach((assignedFig) => {
            if (isShopper(assignedFig)) {
              shoppers = [...shoppers, assignedFig];
              return;
            }
            this.removeFigure(assignedFig);
          });
          return;
        }

        this.removeFigure(fig);
      });

      if (shoppers.length > 0) this.removeShopperFigures(shoppers);
      this.startAutoSaveTimer();
      this.updateSelection();
    }
    // Checking for arrow keys
    if (
      event.keyCode === 37 ||
      event.keyCode === 38 ||
      event.keyCode === 39 ||
      event.keyCode === 40
    ) {
      event.preventDefault();

      selectedFigures.forEach((fig) => {
        // Left arrow
        if (event.keyCode === 37) {
          fig.setX(fig.x - 1);
          return;
        }
        // Up arrow
        if (event.keyCode === 38) {
          fig.setY(fig.y - 1);
          return;
        }
        // Right arrow
        if (event.keyCode === 39) {
          fig.setX(fig.x + 1);
          return;
        }
        // Down arrow
        if (event.keyCode === 40) {
          fig.setY(fig.y + 1);
          return;
        }
      });
    }

    // Checking Ctrl+C or Cmd+C (Copy)
    if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
      this.setState({ copiedFigures: this.state.selectedFigures });
      return;
    }

    // Checking Ctrl+V or Cmd+V (Paste)
    if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
      if (this.state.copiedFigures.some((copiedFig) => isShopper(copiedFig))) {
        message.error('You cannot copy/paste queue line shoppers');
        return;
      }

      this.state.copiedFigures.forEach((copiedFig) => {
        if (isGroupFigure(copiedFig)) {
          let groupedFigures: Draw2DFigureModel[] = [];
          copiedFig.getAssignedFigures().data.forEach((groupFig) => {
            if (isText(groupFig)) {
              const extras = {
                rotationAngle: groupFig.rotationAngle,
                text: groupFig.text!,
                bgColor: groupFig.bgColor!,
                bold: groupFig.bold!,
                fontSize: groupFig.fontSize!,
                fontColor: groupFig.fontColor!,
              };
              const pastedTextFigure = this.handleAddFigure(
                20,
                groupFig.x + COPY_PASTED_ITEM_OFFSET,
                groupFig.y + COPY_PASTED_ITEM_OFFSET,
                undefined,
                extras
              );
              groupedFigures = [...groupedFigures, pastedTextFigure];
              return;
            }
            if (isMeasureTool(groupFig)) {
              const extras = {
                height: groupFig.height,
                width: groupFig.width,
              };
              const pastedMeasureToolFigure = this.handleAddFigure(
                groupFig.userData.fixtureId,
                groupFig.x + COPY_PASTED_ITEM_OFFSET,
                groupFig.y + COPY_PASTED_ITEM_OFFSET,
                undefined,
                extras
              );
              groupedFigures = [...groupedFigures, pastedMeasureToolFigure];
              return;
            }
            const pastedFigured = this.handleAddFigure(
              groupFig.userData.fixtureId,
              groupFig.x + COPY_PASTED_ITEM_OFFSET,
              groupFig.y + COPY_PASTED_ITEM_OFFSET,
              groupFig.userData.renderType
            );
            groupedFigures = [...groupedFigures, pastedFigured];
          });
          this.handleGroupItems(groupedFigures);
          return;
        }
        if (isText(copiedFig)) {
          const extras = {
            rotationAngle: copiedFig.rotationAngle,
            text: copiedFig.text!,
            bgColor: copiedFig.bgColor!,
            bold: copiedFig.bold!,
            fontSize: copiedFig.fontSize!,
            fontColor: copiedFig.fontColor!,
          };
          this.handleAddFigure(
            20,
            copiedFig.x + COPY_PASTED_ITEM_OFFSET,
            copiedFig.y + COPY_PASTED_ITEM_OFFSET,
            undefined,
            extras
          );
          return;
        }
        if (isMeasureTool(copiedFig)) {
          const extras = {
            height: copiedFig.height,
            width: copiedFig.width,
          };
          this.handleAddFigure(
            copiedFig.userData.fixtureId,
            copiedFig.x + COPY_PASTED_ITEM_OFFSET,
            copiedFig.y + COPY_PASTED_ITEM_OFFSET,
            undefined,
            extras
          );
          return;
        }
        this.handleAddFigure(
          copiedFig.userData.fixtureId,
          copiedFig.x,
          copiedFig.y,
          copiedFig.userData.renderType
        );
      });
      return;
    }
  };

  private updateSelection = () => {
    const { selectedFigures } = this.state;
    // Display any previously selected shoppers as unselected
    selectedFigures.forEach((fig: Draw2DFigureModel, index: number) => {
      if (isShopper(fig)) {
        const rectangle = selectedFigures[index].getChildren().data[0];
        const circle = rectangle.getChildren().data[0];
        const label = circle.getChildren().data[0];

        circle.setBackgroundColor('#ffffff');
        label.setFontColor(selectedFigures[0].userData.shopper?.color!);
      }
    });

    setTimeout(() => {
      const selections: Draw2DFigureModel[] = this.canvas
        .getSelection()
        .getAll().data;
      this.setState({
        selectedFigures: selections,
      });

      selections.forEach((selection) => {
        // Add Label and Apply Rotation
        this.applyLabel(selection);

        // Display a selected shopper as selected
        if (isShopper(selection)) {
          const rectangle = selection.getChildren().data[0];
          const circle = rectangle.getChildren().data[0];
          const label = circle.getChildren().data[0];

          circle.setBackgroundColor(selection.userData.shopper?.color!);
          label.setFontColor('#ffffff');
        }
      });
    }, 1);
  };

  private handleCanvasFiguresSelected = (event: any) => {
    if (!event.figure && !event.figures) return;
    this.updateSelection();
  };

  private handleZoomIn = () => {
    this.setState({ isAutoTakeScreenShotOn: false });
    this.canvas.setZoom(this.canvas.getZoom() * 0.8, true);

    const dim = new draw2d.geo.Rectangle(
      0,
      0,
      this.canvas.getWidth() + this.canvas.getWidth() * 0.3,
      this.canvas.getHeight() + this.canvas.getHeight() * 0.3
    );
    this.canvas.setDimension(dim);
  };
  private handleZoomOut = () => {
    this.setState({ isAutoTakeScreenShotOn: false });

    this.canvas.setZoom(this.canvas.getZoom() / 0.8, true);
  };

  private handleZoomToFit = () => {
    this.setState({ isAutoTakeScreenShotOn: true });

    this.canvas.setZoom(1.0, true);
  };

  private getTopMostYValue = (selection: any[]) => {
    let topMostYValue = Infinity;

    selection.forEach((item: any) => {
      if (item.y < topMostYValue) {
        topMostYValue = item.y;
      }
    });

    return topMostYValue;
  };

  private getBottomMostYValue = (selection: any[]) => {
    let bottomMostYValue = 0;

    selection.forEach((item: any) => {
      const bottom = item.y + item.height;
      if (bottom > bottomMostYValue) {
        bottomMostYValue = bottom;
      }
    });

    return bottomMostYValue;
  };

  private getLeftMostXValue = (selection: any[]) => {
    let leftMostXValue = Infinity;

    selection.forEach((item: any) => {
      if (item.x < leftMostXValue) {
        leftMostXValue = item.x;
      }
    });

    return leftMostXValue;
  };

  private getRightMostXValue = (selection: any[]) => {
    let rightMostXValue = 0;

    selection.forEach((item: any) => {
      const right = item.x + item.width;
      if (right > rightMostXValue) {
        rightMostXValue = right;
      }
    });

    return rightMostXValue;
  };

  private getMedianYValue = (selection: any[]) => {
    const topMostYValue = this.getTopMostYValue(selection);
    const bottomMostYValue = this.getBottomMostYValue(selection);

    return (topMostYValue + bottomMostYValue) / 2;
  };

  private getMedianXValue = (selection: any[]) => {
    const leftMostXValue = this.getLeftMostXValue(selection);
    const rightMostXValue = this.getRightMostXValue(selection);

    return (leftMostXValue + rightMostXValue) / 2;
  };

  private handleAlignItemsTop = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const topMostYValue = this.getTopMostYValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(item.x, topMostYValue);
      this.applyLabel(item);
    });
  };

  private handleAlignItemsLeft = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const leftMostXValue = this.getLeftMostXValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(leftMostXValue, item.y);
      this.applyLabel(item);
    });
  };

  private handleAlignItemsCenterHorizontal = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const medianYValue = this.getMedianYValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(item.x, medianYValue - item.height / 2);
      this.applyLabel(item);
    });
  };

  private handleAlignItemsCenterVertical = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const medianXValue = this.getMedianXValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(medianXValue - item.width / 2, item.y);
      this.applyLabel(item);
    });
  };

  private handleAlignItemsRight = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const rightMostXValue = this.getRightMostXValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(rightMostXValue - item.width, item.y);
      this.applyLabel(item);
    });
  };

  private handleAlignItemsBottom = () => {
    const selection = this.canvas.getSelection().getAll().data;

    const bottomMostYValue = this.getBottomMostYValue(selection);

    selection.forEach((item: any) => {
      item.setPosition(item.x, bottomMostYValue - item.height);
      this.applyLabel(item);
    });
  };

  private updateDisplayRenderedImages = (value: boolean) => {
    this.canvas.getFigures().data.forEach((fig: Draw2DFigureModel) => {
      if (isGroupFigure(fig)) {
        const ungroup = new draw2d.command.CommandUngroup(this.canvas, fig);
        this.canvas.getCommandStack().execute(ungroup);
      }
    });

    const nextFigures: Draw2DFigureModel[] = this.canvas
      .getFigures()
      .data.map((fig: Draw2DFigureModel) => {
        if (isStaticFixture(fig)) {
          const nextRender = value ? 'topdown_r' : 'topdown_l';
          return {
            ...fig,
            userData: {
              ...fig.userData,
              renderType: nextRender,
            },
          };
        }
        if (isBackgroundEnvironmentFigure(fig)) {
          const newSettings: BuildSettings = {
            ...this.state.settings,
            displayRenderedImages: value,
          };
          const nextBgEnv = this.generateEnvBgFigure(newSettings);
          return nextBgEnv;
        }
        return isTextId(fig.userData.fixtureId) ||
          isMeasureToolId(fig.userData.fixtureId)
          ? fig
          : {
              ...fig,
              userData: {
                ...fig.userData,
                renderType: getNextRenderType(fig.userData.renderType, value),
              },
            };
      });

    this.canvas.clear();

    this.renderNextRenderedFigures(nextFigures);

    this.setState({
      settings: {
        ...this.state.settings,
        displayRenderedImages: value,
      },
    });
  };

  private updateDisplayGrid = (value: boolean) => {
    this.canvas.getFigures().data.forEach((fig: Draw2DFigureModel) => {
      if (isGroupFigure(fig)) {
        const ungroup = new draw2d.command.CommandUngroup(this.canvas, fig);
        this.canvas.getCommandStack().execute(ungroup);
      }
    });

    const nextFigures: Draw2DFigureModel[] = this.canvas
      .getFigures()
      .data.map((fig: Draw2DFigureModel) => {
        if (isStaticFixture(fig)) {
          const nextRender = this.state.settings.displayRenderedImages
            ? 'topdown_r'
            : 'topdown_l';
          return {
            ...fig,
            userData: {
              ...fig.userData,
              renderType: nextRender,
            },
          };
        }
        if (isBackgroundEnvironmentFigure(fig)) {
          const newSettings: BuildSettings = {
            ...this.state.settings,
            displayGrid: value,
          };
          const nextBgEnv = this.generateEnvBgFigure(newSettings);
          return nextBgEnv;
        }
        return isTextId(fig.userData.fixtureId) ||
          isMeasureToolId(fig.userData.fixtureId)
          ? fig
          : {
              ...fig,
              userData: {
                ...fig.userData,
                renderType: getNextRenderType(fig.userData.renderType, value),
              },
            };
      });

    this.canvas.clear();

    this.renderNextRenderedFigures(nextFigures);

    this.setState({
      settings: {
        ...this.state.settings,
        displayGrid: value,
      },
    });
  };

  private getEnvBgRenderType = (
    settings: BuildSettings
  ): EnvironmentRenderType => {
    const { displayGrid, displayRenderedImages } = settings;

    switch (true) {
      case displayGrid === true && displayRenderedImages === true:
        return 'renderedWithGrid';
      case displayGrid === true && displayRenderedImages === false:
        return 'linedWithGrid';
      case displayGrid === false && displayRenderedImages === true:
        return 'rendered';
      case displayGrid === false && displayRenderedImages === false:
        return 'lined';
      default:
        throw new Error('Unhandled bg env render type');
    }
  };

  private generateEnvBgFigure = (settings: BuildSettings) => {
    const renderType = this.getEnvBgRenderType(settings);
    const attrs = this.getEnvBgImageAttrs(renderType);
    const envBg = new draw2d.shape.basic.Image(attrs);
    envBg.toBack();
    return envBg;
  };

  private handleUpdateBuildSettings = (
    field: keyof BuildSettings,
    value: boolean
  ) => {
    switch (field) {
      case 'displayRenderedImages':
        this.updateDisplayRenderedImages(value);
        break;
      case 'displayGrid':
        this.updateDisplayGrid(value);
        break;
    }
  };

  private handleRotateItem = () => {
    const selection = this.canvas.getSelection().getAll().data;

    if (!selection || selection.length === 0) return;

    selection[0].setRotationAngle(selection[0].getRotationAngle() + 90);
    const selectedFigures = new draw2d.util.ArrayList();

    selection.forEach((fig: Draw2DFigureModel) => {
      if (isText(fig)) {
        fig.setRotationAngle(fig.rotationAngle + 90);
        selectedFigures.add(fig);
        return;
      }
      if (isGroupFigure(fig)) {
        fig.setRotationAngle(fig.rotationAngle + 90);
        const { a, b, c, d, e, f } = fig.shape.next.matrix;
        const transformVal = `matrix(${a},${b},${c},${d},${e},${f})`;

        const groupElClass = fig.getCssClass().split(' ')[1];
        const groupEl = document.querySelector<HTMLElement>(
          `.${groupElClass}`
        )!;
        groupEl.style.transform = transformVal;

        const assignedFigures = fig.getAssignedFigures().data;

        let nextAssignedFigures: any[] = [];

        assignedFigures.forEach((figure: Draw2DFigureModel) => {
          const uniqueFigureElClass = figure.getCssClass();
          const figureEl = document.querySelector<HTMLElement>(
            `.${uniqueFigureElClass}`
          )!;
          figureEl.style.transform = transformVal;

          const x = figure.getX();
          const y = figure.getY();

          const height = figure.getHeight();
          const width = figure.getWidth();

          const nextX = e - x - height;
          const nextY = f - y - width;

          const nextRender = getNextRotatedRender(figure.userData.renderType);

          const extras =
            isShopper(figure) || isText(figure)
              ? isShopper(figure)
                ? figure.userData.shopper
                : {
                    rotationAngle: figure.rotationAngle + 90,
                    text: figure.text!,
                    bgColor: figure.bgColor!,
                    bold: figure.bold!,
                    fontSize: figure.fontSize!,
                    fontColor: figure.fontColor!,
                  }
              : undefined;

          const nextFigure = this.generateCanvasFigure(
            figure.userData.fixtureId,
            nextX,
            nextY,
            nextRender,
            extras
          );

          this.canvas.remove(figure);
          this.canvas.add(nextFigure);

          nextAssignedFigures = [...nextAssignedFigures, nextFigure];
        });

        const nextGroup = new draw2d.shape.composite.Group();
        nextGroup.setUserData({ rotation: fig.userData.rotation! + 1 });
        nextGroup.addCssClass(`group-item-${generateRandomInt(1, 20)}`);

        nextAssignedFigures.forEach((fig) => {
          if (!isText(fig)) {
            fig.setCssClass(`group-child-item-${generateRandomInt(1, 1000)}`);
          }
          nextGroup.assignFigure(fig);
        });

        this.canvas.add(nextGroup);
        nextGroup.setRotationAngle(360);
        selectedFigures.add(nextGroup);
        this.canvas.remove(fig);
        return;
      }

      const nextRender = getNextRotatedRender(fig.userData.renderType);
      const extras = isShopper(fig) ? fig.userData.shopper : undefined;
      const nextFigure = this.generateCanvasFigure(
        fig.userData.fixtureId,
        fig.x,
        fig.y,
        nextRender,
        extras
      );
      this.canvas.add(nextFigure);
      selectedFigures.add(nextFigure);
      this.canvas.remove(fig);
    });

    // Send env bg to back after every addition
    this.environmentBackgroundFigure?.toBack();

    // Reselect new renders that were previously selected
    this.canvas.setCurrentSelection(selectedFigures);

    // Start autosave timer
    this.startAutoSaveTimer();
  };

  private styleInplaceEditor = (e: Draw2DFigureModel) => {
    setTimeout(() => {
      const input = document.querySelector('#inplaceeditor')! as HTMLElement;

      input.style.fontSize = `${e.fontSize!}px`;
      input.style.color = `${rgbToHex(
        e.fontColor?.red!,
        e.fontColor?.green!,
        e.fontColor?.blue!
      )}`;
      input.classList.add('inplaceeditor');
      const hasBgColor = e.bgColor?.red && e.bgColor?.green && e.bgColor?.blue;
      if (hasBgColor)
        input.style.backgroundColor = `${rgbToHex(
          e.bgColor?.red!,
          e.bgColor?.green!,
          e.bgColor?.blue!
        )}`;
      if (e.bold) input.style.fontWeight = 'bold';

      e.svgNodes[0].next.attr('stroke-width', 0);
    }, 0);
  };

  private handleAddTextBox = () => {
    const builderEl = document.getElementById('builderLayoutEditor');
    const dropAreaMinX = 200 + builderEl?.scrollLeft!;
    const dropAreaMinY = 200 + builderEl?.scrollTop!;
    const x = generateRandomInt(dropAreaMinX, dropAreaMinX + 50);
    const y = generateRandomInt(dropAreaMinY, dropAreaMinY + 50);
    const textFigureId = 20;
    this.handleAddFigure(textFigureId, x, y);
  };

  private handleTakeScreenShot = () => {
    const { build } = this.props;

    const writer = new draw2d.io.png.Writer();

    writer.marshal(
      this.canvas,
      (png: string) => {
        const aTagEl = document.createElement('a');
        aTagEl.href = png;
        aTagEl.download = `${build?.name}_screenshot.png`;
        aTagEl.click();
        aTagEl.remove();

        const base64 = png.split(',')[1];
        StoreBuildService()
          .saveBuildSnapshot(build.uuid, { png: base64 })
          .catch(this.onError);
      },
      new draw2d.geo.Rectangle(
        this.environment.space.x,
        this.environment.space.y,
        this.environment.space.width,
        this.environment.space.height
      )
    );
  };

  private saveScreenShot = () => {
    const { isAutoTakeScreenShotOn } = this.state;
    if (!isAutoTakeScreenShotOn) return;

    const { build } = this.props;

    const writer = new draw2d.io.png.Writer();

    writer.marshal(
      this.canvas,
      (png: string) => {
        const base64 = png.split(',')[1];
        StoreBuildService()
          .saveBuildSnapshot(build.uuid, { png: base64 })
          .catch(this.onError);
      },
      new draw2d.geo.Rectangle(
        this.environment.space.x,
        this.environment.space.y,
        this.environment.space.width,
        this.environment.space.height
      )
    );
  };

  private handleUpdateTextLabel = (
    field: 'bgColor' | 'fontSize' | 'bold' | 'fontColor',
    value: string | boolean
  ) => {
    const label = this.state.selectedFigures[0];

    switch (field) {
      case 'bgColor':
        label.setBackgroundColor(value as string);
        break;
      case 'fontSize':
        label.setFontSize(value as string);
        break;
      case 'fontColor':
        label.setFontColor(value as string);
        break;
      case 'bold':
        label.setBold(value as boolean);
        break;
    }

    this.canvas.setCurrentSelection(label);
  };

  private handleUpdateMeasureTool = (
    field: 'height' | 'width',
    value: number
  ) => {
    const rectangle = this.state.selectedFigures[0];

    switch (field) {
      case 'height':
        rectangle.setHeight(value);
        break;
      case 'width':
        rectangle.setWidth(value);
        break;
    }

    this.canvas.setCurrentSelection(rectangle);
  };

  private handleUngroupItems = () => {
    const { selectedFigures } = this.state;

    selectedFigures.forEach((item) => {
      if (!isGroupFigure(item)) return;

      const ungroup = new draw2d.command.CommandUngroup(this.canvas, item);
      this.canvas.getCommandStack().execute(ungroup);
    });
  };

  private handleGroupItems = (selectedFigures: Draw2DFigureModel[]) => {
    const group = new draw2d.shape.composite.Group();
    group.setUserData({ rotation: 0 });
    group.addCssClass(`group-item-${generateRandomInt(1, 20)}`);
    group.installEditPolicy(this.rectangleSelectionPolicy);

    selectedFigures.forEach((fig) => {
      fig.setCssClass(`group-child-item-${generateRandomInt(1, 1000)}`);
      group.assignFigure(fig);
    });

    group.setRotationAngle(360);

    this.addFigureToCanvas(group);
    group.toFront();
    this.selectNewlyAddedFigure(group);
  };

  private handleAddMeasuringTool = (type: 'rectangle' | 'ellipsis') => {
    const builderEl = document.getElementById('builderLayoutEditor');
    const dropAreaMinX = 150 + builderEl?.scrollLeft!;
    const dropAreaMinY = 150 + builderEl?.scrollTop!;
    const x = generateRandomInt(dropAreaMinX, dropAreaMinX + 50);
    const y = generateRandomInt(dropAreaMinY, dropAreaMinY + 50);
    const id = type === 'rectangle' ? 21 : 24;
    this.handleAddFigure(id, x, y);
  };

  private handleDownloadRSFFile = () => {
    const { build } = this.props;

    const request = { design: this.getRSFJSONFile() };

    StoreBuildService()
      .updateStoreBuild(build.uuid, request)
      .then(this.downloadRSFFile)
      .catch(this.onError);
  };

  private downloadRSFFile = (resp: any) => {
    const queryStr = convertParamsToQuery({
      _token: getAuthToken(),
    });

    const downloadLinkEl = document.createElement('a');
    downloadLinkEl.href = `${BASE_API_URL()}/store-builder/${
      resp.data.uuid
    }/download${queryStr}`;
    downloadLinkEl.click();
    downloadLinkEl.remove();
  };

  private onError = (err: ApiError) => {
    message.destroy();
    handleError(err);
  };

  private generateRSFQueueLines = (originData: {
    x: number;
    y: number;
  }): RSFQueueLineModel[] => {
    const sortShoppersFromLeastToGreatest = (
      a: FigureAttributesModel,
      b: FigureAttributesModel
    ) => {
      if (a.userData.shopper?.orderInLine! > b.userData.shopper?.orderInLine!)
        return 1;
      return -1;
    };

    const queueLines = this.generateQueueLineStateFromCanvas().reduce(
      (queueLines: RSFQueueLineModel[], line: QueueLineModel) => {
        if (line.shoppers.length === 0) return queueLines;

        const sortedShoppers = line.shoppers.sort(
          sortShoppersFromLeastToGreatest
        );

        return [
          ...queueLines,
          {
            ...line,
            shoppers: sortedShoppers.map((shopper) => {
              const trueX = shopper.x - this.environment.space.x - originData.x;
              const trueY = shopper.y - this.environment.space.y - originData.y;

              return {
                key: shopper.userData.key,
                id: shopper.userData.id,
                name: shopper.userData?.name,
                r: renderTypeToRotationAngle(shopper.userData.renderType),
                x: trueX,
                y: trueY,
                w: shopper.width,
                d: shopper.height,
                h: shopper.userData.h,
                gid: undefined,
                orderInLine: shopper.userData.shopper?.orderInLine!,
                lineId: shopper.userData.shopper?.lineId!,
                lineColor: shopper.userData.shopper?.color!,
                elapsedWaitTime: duration(
                  `00:${shopper.userData.shopper?.elapsedWaitTime}`
                )
                  .asSeconds()
                  .toString(),
              };
            }),
            positions: sortedShoppers.map((shopper) => {
              const trueX = shopper.x - this.environment.space.x - originData.x;
              const trueY = shopper.y - this.environment.space.y - originData.y;

              return {
                id: generateUuidv4(),
                r: renderTypeToRotationAngle(shopper.userData.renderType),
                x: trueX,
                y: trueY,
                orderInLine: shopper.userData.shopper?.orderInLine!,
                lineId: shopper.userData.shopper?.lineId!,
              };
            }),
          },
        ];
      },
      []
    );
    return queueLines;
  };

  private isFigureWithinBoundaries = (
    x: number,
    y: number,
    height: number,
    width: number
  ): boolean => {
    const { space } = this.environment;

    const boundaryX = space.x;
    const boundaryY = space.y;
    const spaceHeight = space.height;
    const spaceWidth = space.width;

    return (
      x >= boundaryX &&
      x + width <= boundaryX + spaceWidth &&
      y >= boundaryY &&
      y + height <= boundaryY + spaceHeight
    );
  };

  private getRSFOriginData = (
    figures: Draw2DFigureModel[]
  ): { x: number; y: number } => {
    let minX = Infinity;
    let minY = Infinity;
    figures.forEach((item: Draw2DFigureModel) => {
      const trueX = item.x - this.environment.space.x;
      const trueY = item.y - this.environment.space.y;
      if (trueX <= minX) minX = trueX;
      if (trueY <= minY) minY = trueY;
    });
    return { x: minX, y: minY };
  };

  private getRSFJSONFile = () => {
    const { build } = this.props;
    const me = getMe();

    const sanitizedFigures = this.allFiguresWithoutBackground.reduce(
      (allFigs: Draw2DFigureModel[], fig: Draw2DFigureModel) => {
        if (isGroupFigure(fig)) {
          return [...allFigs, ...fig.getAssignedFigures().data];
        }
        return [...allFigs, fig];
      },
      []
    );

    // Get all saveable figures within boundaries
    const fixtures = sanitizedFigures.filter((fig: Draw2DFigureModel) => {
      const x = fig.getX();
      const width = fig.getWidth();
      const y = fig.getY();
      const height = fig.getHeight();
      const isSaveableItem =
        isFixture(fig) ||
        isShopper(fig) ||
        isText(fig) ||
        isMeasureTool(fig) ||
        isStaticFixture(fig);
      const isWithinBoundaries = this.isFigureWithinBoundaries(
        x,
        y,
        height,
        width
      );

      if (isSaveableItem && isWithinBoundaries) return true;
      return false;
    });

    let usedFixtureIds: (FixtureId | ShopperId | HelperId)[] = [];
    const usedFixtures: { [key: string]: any } = {};
    const originData = this.getRSFOriginData(fixtures);

    // Generate list of RSF data objects for each figure
    const data = fixtures.map((fig: Draw2DFigureModel) => {
      const trueX = fig.x - this.environment.space.x - originData.x;
      const trueY = fig.y - this.environment.space.y - originData.y;

      const unityCounterXOffset = -4;

      if (!usedFixtureIds.includes(fig.userData.fixtureId))
        usedFixtureIds = [...usedFixtureIds, fig.userData.fixtureId];

      switch (true) {
        case isShopperId(fig.userData.fixtureId):
          return {
            key: fig.userData.key,
            id: fig.id,
            name: fig.userData?.name,
            r: renderTypeToRotationAngle(fig.userData.renderType),
            x: trueX,
            y: trueY,
            w: fig.width,
            d: fig.height,
            h: fig.userData.h,
            orderInLine: fig.userData.shopper?.orderInLine,
            lineId: fig.userData.shopper?.lineId,
            lineColor: fig.userData.shopper?.color!,
            elapsedWaitTime: fig.userData.shopper?.elapsedWaitTime,
          };
        case isTextId(fig.userData.fixtureId):
          return {
            key: fig.userData.key,
            id: fig.id,
            name: fig.userData?.name,
            r: fig.rotationAngle,
            x: trueX,
            y: trueY,
            w: fig.width,
            d: fig.height,
            text: fig.text,
            bold: fig.bold,
            bgColor: fig.bgColor,
            fontColor: fig.fontColor,
            fontSize: fig.fontSize,
          };
        case isMeasureToolId(fig.userData.fixtureId):
          return {
            key: fig.userData.key,
            id: fig.id,
            name: fig.userData?.name,
            r: fig.rotationAngle,
            x: trueX,
            y: trueY,
            height: fig.height,
            width: fig.width,
          };
        case isStaticFixtureId(fig.userData.fixtureId):
          return {
            key: fig.userData.key,
            id: fig.id,
            name: fig.userData?.name,
            r: 270,
            x:
              +fig.id < 132 || +fig.id > 135
                ? trueX + unityCounterXOffset
                : trueX + unityCounterXOffset * 2,
            y: trueY,
            w: fig.width,
            d: fig.height,
            h: fig.userData.h,
          };
        default:
          return {
            key: fig.userData.key,
            id: fig.id,
            name: fig.userData?.name,
            r: renderTypeToRotationAngle(fig.userData.renderType),
            x: trueX,
            y: trueY,
            w: fig.width,
            d: fig.height,
            h: fig.userData.h,
          };
      }
    });

    // Generate list figures for each unique figure used in build
    usedFixtureIds.forEach((id: FixtureId | ShopperId | HelperId) => {
      if (isShopperId(id)) {
        return (usedFixtures[shopperByIdMap[id].key] = shopperByIdMap[id]);
      }
      if (isHelperId(id)) {
        return (usedFixtures[helperByIdMap[id as HelperId].key] =
          helperByIdMap[id as HelperId]);
      }
      if (isStaticFixtureId(id)) {
        return (usedFixtures[staticFixtureByIdMap[id as StaticItemId].key] =
          staticFixtureByIdMap[id as StaticItemId]);
      }
      usedFixtures[fixtureByIdMap[id].key] = fixtureByIdMap[id];
    });

    const rsf = {
      data: data,
      pid: build.uuid,
      pname: build?.name,
      purl: `${window.location.href}`,
      rsfhash: '',
      uid: me.uuid,
      uname: me?.name,
      origin: 'topleft',
      originData: originData,
      library: usedFixtures,
      queueLines: this.generateRSFQueueLines(originData),
    };

    return rsf;
  };

  private handleSaveBuild = (closeOnSave = false) => {
    const { build, onSaveStatusChange } = this.props;

    onSaveStatusChange(SaveStatus.Saving);
    if (closeOnSave) {
      message.destroy();
      message.loading({
        content: 'Saving build...',
        key: 'saving-build-msg-key',
        duration: 0,
      });
    }

    const request = { design: this.getRSFJSONFile() };
    StoreBuildService()
      .updateStoreBuild(build.uuid, request)
      .then(this.saveScreenShot)
      .then(() => this.onSaveBuildSuccess(closeOnSave))
      .catch(this.onError);
  };

  private onSaveBuildSuccess = (closeOnSave: boolean) => {
    const { onSaveStatusChange } = this.props;

    onSaveStatusChange(SaveStatus.Saved);
    this.setState({ isBuildSaved: true });

    if (closeOnSave) {
      message.destroy();
      message.success('Build saved!');
      this.props.history.push(RoutePath.StoreBuilder);
    }
  };

  private handleConfirmExitWithoutSave = () => {
    Modal.confirm({
      title: `Do you want to save your changes?`,
      okText: 'Save',
      cancelText: `Don't Save`,
      onOk: () => this.handleSaveBuild(true),
      onCancel: () => this.props.history.push(RoutePath.StoreBuilder),
    });
  };

  private handleUpdateCounter = (
    counterId: keyof typeof staticFixtureByIdMap
  ) => {
    const prevCounter: FigureModel = this.canvas
      .getFigures()
      .data.find((fig: Draw2DFigureModel) => isStaticFixture(fig));
    const { displayRenderedImages } = this.state.settings;

    const nextRender = displayRenderedImages ? 'topdown_r' : 'topdown_l';

    this.canvas.remove(prevCounter);
    this.addCounterFixture(counterId, nextRender);
  };

  private applyIsSelectableToAllFigures = (isSelectable: boolean) => {
    this.allFiguresWithoutBackground.forEach((fig: any) => {
      fig.setSelectable(isSelectable);
      fig.setDraggable(isSelectable);

      if (isSelectable) {
        fig.removeCssClass('opacity');
        return;
      }
      fig.setCssClass('opacity');
    });
  };

  private isShopperOverlappingOtherFigures = (
    shopper: Draw2DFigureModel,
    allFigures: Draw2DFigureModel[]
  ) => {
    const { x, y, height, width } = shopper;

    const shopperCoordinates = { x: x, y: y } as const;

    const isOverlapping = allFigures.some((f: Draw2DFigureModel) => {
      const startX = f.x;
      const endX = f.x + f.width;
      const startY = f.y;
      const endY = f.y + f.height;

      const hasTopLeftOverlap =
        shopperCoordinates.x >= startX &&
        shopperCoordinates.x <= endX &&
        shopperCoordinates.y >= startY &&
        shopperCoordinates.y <= endY;

      const hasTopRightOverlap =
        shopperCoordinates.x + width >= startX &&
        shopperCoordinates.x + width <= endX &&
        shopperCoordinates.y >= startY &&
        shopperCoordinates.y <= endY;

      const hasBottomLeftOverlap =
        shopperCoordinates.x >= startX &&
        shopperCoordinates.x <= endX &&
        shopperCoordinates.y + height >= startY &&
        shopperCoordinates.y + height <= endY;

      const hasBottomRightOverlap =
        shopperCoordinates.x + width >= startX &&
        shopperCoordinates.x + width <= endX &&
        shopperCoordinates.y + height >= startY &&
        shopperCoordinates.y + height <= endY;

      if (
        shopper.id !== f.id &&
        (hasTopLeftOverlap ||
          hasTopRightOverlap ||
          hasBottomLeftOverlap ||
          hasBottomRightOverlap)
      ) {
        return true;
      }
      return false;
    });

    return isOverlapping;
  };

  private checkForOverlappingShoppers = () => {
    const allFigures = this.allFiguresWithoutBackground;

    // Get all ids of figures that shopper overlaps
    const overlappingShoppers = allFigures.reduce(
      (overlappedShoppers: string[], f: Draw2DFigureModel) => {
        if (!isShopperId(f.userData.fixtureId)) return overlappedShoppers;

        if (this.isShopperOverlappingOtherFigures(f, allFigures))
          return [...overlappedShoppers, f.id];
        return overlappedShoppers;
      },
      []
    );

    this.setState({ overlappingShoppers: overlappingShoppers });
  };

  private addShopper = (event: any) => {
    const { sidebarCollapsed } = this.props;
    const { selectedShopperType, settings, currentQueueLine } = this.state;
    const { displayRenderedImages } = settings;

    const builderEl = document.getElementById('builderLayoutEditor');
    const shopperX =
      event.mouseDownX - (sidebarCollapsed ? 80 : 250) + builderEl?.scrollLeft!;
    const shopperY = event.mouseDownY - 120 + builderEl?.scrollTop!;
    const renderType = displayRenderedImages
      ? 'topdown_rR180'
      : 'topdown_lR180';

    const shopper = this.handleAddFigure(
      selectedShopperType,
      shopperX,
      shopperY,
      renderType
    );
    this.checkForOverlappingShoppers();

    this.setState({
      currentQueueLine: {
        ...currentQueueLine!,
        shoppers: [
          ...currentQueueLine?.shoppers!,
          generateShopperAttributesFromFigure(shopper),
        ],
      },
    });
  };

  private handleToggleQueueLineMode = () => {
    const { isInQueueLineMode } = this.state;

    const nextIsInQueueLineModeValue = !isInQueueLineMode;
    const nextAutoSaveOnValue = !nextIsInQueueLineModeValue;

    const queueLines = this.generateQueueLineStateFromCanvas();

    const availableQueueLines = queueLines.filter((line: QueueLineModel) => {
      if (line.shoppers.length > 0) return false;
      return true;
    });

    this.setState({
      isInQueueLineMode: nextIsInQueueLineModeValue,
      isAutoSaveOn: nextAutoSaveOnValue,
      currentQueueLine: availableQueueLines[0],
    });

    if (nextIsInQueueLineModeValue) {
      this.handleZoomToFit();
      this.applyIsSelectableToAllFigures(false);
      this.canvas.on('click', this.addShopper);
      return;
    }
    this.applyIsSelectableToAllFigures(true);
    this.canvas.off('click', this.addShopper);
    this.startAutoSaveTimer();
  };

  private getShopperFigureChild = (
    shopperData: ShopperUserDataModel,
    renderType: RenderType
  ) => {
    const { color, orderInLine } = shopperData;
    const isSideways = isFigureRotatedSideways(renderType);
    const calculateDimensions = () => {
      switch (shopperData.type) {
        case ShopperType.WomanWithBasket:
          return {
            height: isSideways ? 68 : 43.2,
            width: isSideways ? 43.2 : 68,
          };
        case ShopperType.WomanWithoutBasket:
          return {
            height: isSideways ? 40 : 30,
            width: isSideways ? 30 : 40,
          };
        case ShopperType.WomanWithCart:
          return {
            height: isSideways ? 50 : 136,
            width: isSideways ? 136 : 50,
          };
        case ShopperType.SmallManWithBasket:
          return {
            height: isSideways ? 76 : 55.6,
            width: isSideways ? 55.6 : 76,
          };
        case ShopperType.SmallManWithoutBasket:
          return {
            height: isSideways ? 55 : 45,
            width: isSideways ? 45 : 55,
          };
        case ShopperType.LargeManWithBasket:
          return {
            height: isSideways ? 95 : 60,
            width: isSideways ? 60 : 95,
          };
        case ShopperType.LargeManWithoutBasket:
          return {
            height: isSideways ? 76 : 50.3,
            width: isSideways ? 50.3 : 76,
          };
        default:
          return {
            height: isSideways ? 68 : 43.2,
            width: isSideways ? 43.2 : 68,
          };
      }
    };
    const dims = calculateDimensions();

    const label = new draw2d.shape.basic.Label({
      text: `${orderInLine}`,
      cssClass: 'label-opacity',
      fontColor: `${color}`,
      fontSize: 14,
      bold: true,
    });
    const circle = new draw2d.shape.basic.Circle({
      bgColor: '#ffffff',
      color: `${color}`,
      height: 26,
      width: 26,
      stroke: 2.5,
    }).add(label, new draw2d.layout.locator.CenterLocator());

    const container = new draw2d.shape.basic.Rectangle({
      ...dims,
      bgColor: `${color}`,
      opacity: 0.3,
      color: `${color}`,
    }).add(circle, new draw2d.layout.locator.CenterLocator());
    return container;
  };

  private handleSelectShopperType = (type: ShopperType) => {
    this.setState({
      selectedShopperType: type,
    });
  };

  private getSelectedShopperQueueLine = () => {
    const { selectedFigures } = this.state;

    if (
      selectedFigures.length === 0 ||
      selectedFigures.length > 1 ||
      !isShopperId(selectedFigures[0].userData.fixtureId)
    )
      return;

    const queueLines = this.generateQueueLineStateFromCanvas();
    const selectedShopper = selectedFigures[0];
    const shopperQueueLine = queueLines.find(
      (line: QueueLineModel) =>
        line.lineId === selectedShopper.userData?.shopper?.lineId
    );

    return shopperQueueLine;
  };

  private handleUpdateShopper = (
    shopper: ShopperUserDataModel,
    action: 'updateShopperType' | 'removeShopper' | 'updateShopperElaspedTime',
    value?: ShopperType | string
  ) => {
    const shopperFigure: Draw2DFigureModel =
      this.allFiguresWithoutBackground.find(
        (fig: Draw2DFigureModel) =>
          fig.userData.shopper?.lineId === shopper.lineId &&
          fig.userData.shopper?.orderInLine === shopper.orderInLine
      )!;
    switch (action) {
      case 'updateShopperType':
        const queueLines = this.generateQueueLineStateFromCanvas();

        const nextQueueLinesValue = queueLines.map((line: QueueLineModel) => {
          if (line.lineId === shopper.lineId) {
            return {
              ...line,
              shoppers: line.shoppers.map((s) => {
                if (s.userData.shopper?.orderInLine === shopper.orderInLine) {
                  return {
                    ...s,
                    userData: {
                      ...s.userData,
                      fixtureId: value! as ShopperType,
                      shopper: {
                        ...s.userData.shopper,
                        type: value! as ShopperType,
                      },
                    },
                  };
                }
                return s;
              }),
            };
          }
          return line;
        });
        this.updateCanvasQueueLines(nextQueueLinesValue);
        break;
      case 'removeShopper':
        this.removeShopperFigures([shopperFigure]);

        const line = this.generateQueueLineStateFromCanvas().find(
          (line) => line.lineId === shopper.lineId
        );

        const isLastShopperInLine = line?.shoppers.length === 0;
        if (isLastShopperInLine) this.setState({ selectedFigures: [] });
        break;
      case 'updateShopperElaspedTime':
        this.allFiguresWithoutBackground.forEach((fig: Draw2DFigureModel) => {
          if (
            isShopperId(fig.userData.fixtureId) &&
            fig.userData.shopper?.lineId === shopper.lineId
          ) {
            fig.setUserData({
              ...fig.userData,
              shopper: {
                ...fig.userData.shopper,
                elapsedWaitTime: !!value ? (value as string) : '00:00',
              },
            });
          }
        });

        this.setState({ selectedFigures: [shopperFigure] });
        break;
    }
  };

  private hasNoAvailableQueueLines = () => {
    const queueLines = this.generateQueueLineStateFromCanvas();
    const unavailableQueueLines = queueLines.filter(
      (line: QueueLineModel) => line.shoppers.length > 0
    );
    return unavailableQueueLines.length === MAX_QUEUE_LINES;
  };

  render() {
    const { sidebarCollapsed } = this.props;
    const {
      selectedFigures,
      settings,
      isBuildSaved,
      isInQueueLineMode,
      selectedShopperType,
      currentQueueLine,
      overlappingShoppers,
    } = this.state;

    const toolbarActions: BuilderToolbarActions = {
      onZoomIn: this.handleZoomIn,
      onZoomOut: this.handleZoomOut,
      onZoomToFit: this.handleZoomToFit,
      onAlignItemsTop: this.handleAlignItemsTop,
      onAlignItemsLeft: this.handleAlignItemsLeft,
      onAlignItemsRight: this.handleAlignItemsRight,
      onAlignItemsBottom: this.handleAlignItemsBottom,
      onAlignItemsCenterHorizontal: this.handleAlignItemsCenterHorizontal,
      onAlignItemsCenterVertical: this.handleAlignItemsCenterVertical,
      onRotateItem: this.handleRotateItem,
      onAddTextBox: this.handleAddTextBox,
      onTakeScreenShot: this.handleTakeScreenShot,
      onUngroupItems: this.handleUngroupItems,
      onGroupItems: () => this.handleGroupItems(selectedFigures),
      onAddMeasuringTool: this.handleAddMeasuringTool,
      onDownloadRSFFile: this.handleDownloadRSFFile,
      onToggleQueueLineMode: this.handleToggleQueueLineMode,
      onSelectShopperType: this.handleSelectShopperType,
    };

    const builderLayoutEditorClass = cx('builder-layout-editor', {
      'collapsed-sidebar': sidebarCollapsed,
    });

    const builderCanvasClass = cx('builder-canvas', {
      'queue-line-mode': isInQueueLineMode,
    });

    const builderCanvasStyle: CSSProperties = {
      width: `${this.environment.width}px`,
      height: `${this.environment.height}px`,
    };

    return (
      <main id="storeBuilder" className="store-builder">
        <div
          id="builderLayoutEditor"
          className={builderLayoutEditorClass}
          ref={this.canvasWrapperEl}
        >
          <BuilderLayoutToolbar
            actions={toolbarActions}
            selectedFigures={selectedFigures}
            sidebarCollapsed={sidebarCollapsed}
            isInQueueLineMode={isInQueueLineMode}
            disableQueueLineMode={this.hasNoAvailableQueueLines()}
            selectedShopperType={selectedShopperType}
            currentQueueLineColor={currentQueueLine?.color!}
          />
          <div
            key="builder-canvas"
            id="canvas"
            className={builderCanvasClass}
            style={builderCanvasStyle}
            data-cy="canvas"
          />
        </div>
        <BuilderSidePanel
          disableDragNDropFixtures={isInQueueLineMode}
          settings={settings}
          selectedFigures={selectedFigures}
          onUpdateShopper={this.handleUpdateShopper}
          onUpdateSetting={this.handleUpdateBuildSettings}
          onUpdateTextLabel={this.handleUpdateTextLabel}
          onUpdateMeasureTool={this.handleUpdateMeasureTool}
          onUpdateCounter={this.handleUpdateCounter}
          selectedShopperQueueLine={this.getSelectedShopperQueueLine()!}
          overlappingShoppers={overlappingShoppers}
        />
        {isBuildSaved ? null : (
          <div
            className={`exit-build-editor-button ${
              sidebarCollapsed ? 'sidebar-collapsed' : ''
            }`}
            onClick={this.handleConfirmExitWithoutSave}
          />
        )}
      </main>
    );
  }
}

export default withRouter<any, any>(BuilderLayoutEditor);
