export interface CategoryModel {
  key: string;
  name: string;
  parent_id: string;
  display_name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  count: number;
  uuid: string;
  deleted_at: Date;
  created_by: Date;
  has_child: boolean;
  in_library: number;
  in_processing: number;
  in_use?: boolean;
  children?: CategoryModel[];
  statusCheckValue?: boolean | undefined;
}

export const initialNewCategoryModel = {
  name: '',
};
