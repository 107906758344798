import {
  ClientsListResponse,
  CreateClientEmailNotificationRequest,
  CreateClientLicenseRequest,
  CreateClientRequest,
  LicenseListResponse,
  UpdateClientLicenseRequest,
  UpdateClientRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { ClientModel } from '../../models/client';
import { del, get, ListApiResponse, patch, post } from '../base';
import { UsersResponse } from '../user';

export default function ClientService() {
  return Object.freeze({
    listClientUsers(clientId: string, query?: string): Promise<UsersResponse> {
      return get(
        `${BASE_API_URL()}/client/${clientId}/users${query ?? ''}`
      ) as Promise<ListApiResponse>;
    },
    listClients(query?: string): Promise<ClientsListResponse> {
      return get(
        `${BASE_API_URL()}/client${query ?? ''}`
      ) as Promise<ListApiResponse>;
    },
    getClient(id: string): Promise<{ data: ClientModel }> {
      return get(`${BASE_API_URL()}/client/${id}`);
    },
    createClient(req: CreateClientRequest) {
      return post(`${BASE_API_URL()}/client/`, req);
    },
    deleteClient(clientId: string) {
      return del(`${BASE_API_URL()}/client/${clientId}`);
    },
    updateClient(clientId: string, req: UpdateClientRequest) {
      return patch(`${BASE_API_URL()}/client/${clientId}`, req);
    },
    getClientLicense(clientId: string) {
      return get(`${BASE_API_URL()}/client/${clientId}/lic`);
    },
    createClientLicense(clientId: string, req: CreateClientLicenseRequest) {
      return post(`${BASE_API_URL()}/client/${clientId}/license`, req);
    },
    deleteClientLicense(clientId: string, licenseId: string) {
      return del(`${BASE_API_URL()}/client/${clientId}/license/${licenseId}`);
    },
    updateClientLicense(
      clientId: string,
      licenseId: string,
      req: UpdateClientLicenseRequest
    ) {
      return patch(
        `${BASE_API_URL()}/client/${clientId}/license/${licenseId}`,
        req
      );
    },
    listClientLicenses(
      clientId: string,
      query?: string
    ): Promise<LicenseListResponse> {
      return get(
        `${BASE_API_URL()}/client/${clientId}/license${query ?? ''}`
      ) as Promise<ListApiResponse>;
    },
    updateClientEmailNotification(
      clientId: string,
      req: CreateClientEmailNotificationRequest
    ) {
      return patch(`${BASE_API_URL()}/client/${clientId}`, req);
    },
  });
}
