import { useEffect, useState, useMemo } from 'react';

import { Col, Row, Typography } from 'antd';

import ProductStatusService, {
  ProductStatusResponse,
} from '../../../api/product-status';
import { getMePermissions, UserPermissions } from '../../../util';
import ViewWrapper from '../../elements/ViewWrapper';
import CategoriesTab from '../MetaDataView/components/CategoriesTab/CategoriesTab';

import './StatusView.less';

const { Text, Title } = Typography;

const Status = () => {
  const [statusData, setStatusData] = useState<ProductStatusResponse>();

  const getStatusData = async () => {
    const data = await ProductStatusService().listProductStatus();
    setStatusData(data);
  };

  useEffect(() => {
    getStatusData();
  }, []);

  const {
    total_products,
    needs_processing,
    past_24_hours,
    past_30_days,
    past_7_days,
    past_90_days,
  } = statusData?.data || {};

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  return (
    <ViewWrapper>
      <div className="status-view-wrapper">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="status-card">
              <Typography>Total Products</Typography>
              <div className="card-digit product-count">
                {total_products?.total}
              </div>
              <div className="card-line">
                <Typography>Total Products</Typography>
                <Text strong>{total_products?.processed_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Needs Processing</Typography>
                <Text strong>{total_products?.needs_processing}</Text>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="status-card">
              <Typography>Needs Processing</Typography>
              <div className="card-digit processing-count">
                {needs_processing?.total}
              </div>
              <div className="card-line">
                <Typography>New Products</Typography>
                <Text strong>{needs_processing?.new_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Modified Products</Typography>
                <Text strong>{needs_processing?.modified_products}</Text>
              </div>
            </div>
          </Col>

          <Col span={6}>
            <div className="status-card">
              <Typography>Past 24 Hours</Typography>
              <div className="card-digit">{past_24_hours?.total}</div>
              <div className="card-line">
                <Typography>Processed Products</Typography>
                <Text strong>{past_24_hours?.processed_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Modified Products</Typography>
                <Text strong>{past_24_hours?.modified_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Uploaded Products</Typography>
                <Text strong>{past_24_hours?.uploaded_products}</Text>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="status-card">
              <Typography>Past 7 Days</Typography>
              <div className="card-digit">{past_7_days?.total}</div>
              <div className="card-line">
                <Typography>Processed Products</Typography>
                <Text strong>{past_7_days?.processed_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Modified Products</Typography>
                <Text strong>{past_7_days?.modified_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Uploaded Products</Typography>
                <Text strong>{past_7_days?.uploaded_products}</Text>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="status-card">
              <Typography>Past 30 Days</Typography>
              <div className="card-digit">{past_30_days?.total}</div>
              <div className="card-line">
                <Typography>Processed Products</Typography>
                <Text strong>{past_30_days?.processed_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Modified Products</Typography>
                <Text strong>{past_30_days?.modified_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Uploaded Products</Typography>
                <Text strong>{past_30_days?.uploaded_products}</Text>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="status-card">
              <Typography>Past 90 Days</Typography>
              <div className="card-digit">{past_90_days?.total}</div>
              <div className="card-line">
                <Typography>Processed Products</Typography>
                <Text strong>{past_90_days?.processed_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Modified Products</Typography>
                <Text strong>{past_90_days?.modified_products}</Text>
              </div>
              <div className="card-line">
                <Typography>Uploaded Products</Typography>
                <Text strong>{past_90_days?.uploaded_products}</Text>
              </div>
            </div>
          </Col>
        </Row>
        {currentUserPermissions.includes(UserPermissions.MetatDataView) ? (
          <div className="categories">
            <Title level={5}>Products by Category</Title>
            <CategoriesTab hideAdd hideEdit />
          </div>
        ) : null}
      </div>
    </ViewWrapper>
  );
};

export default Status;
