const ResponsiveSVG = () => (
  <div style={{ width: '3vw', height: '3vw' }}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 33 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2331_15039)">
        <path
          d="M30.8316 6.00276L12.8656 0.885742L1.93989 8.82309L0.385742 9.95329V30.2814L1.21462 30.5199L17.5643 35.2066L18.8905 35.585L19.9214 35.8806L20.4447 35.5021L21.9056 34.4445L24.1125 32.8425L26.2727 31.2768L31.3393 27.6062L32.3857 26.8493V6.44862L30.8316 6.00795V6.00276ZM18.8905 21.6701L18.1963 21.4731L17.7715 22.9714L18.8905 23.2928V33.9727L15.8651 33.1069L13.1505 32.3292L7.45196 30.6961L5.22434 30.0585L3.09515 29.4467L2.63408 29.3171L4.58195 27.9017L3.67018 26.6419L1.93989 27.9017V11.659L12.2439 14.6141V16.0346H13.7981V15.0548L18.8905 16.5168V21.6701ZM19.621 15.1118L18.8905 14.9044L13.7981 13.4424V12.7321H12.2439V12.9965L2.63408 10.2384L12.2439 3.26021V3.92381H13.7981V2.76769L30.2099 7.44403L20.4499 14.5156L19.6262 15.1118H19.621ZM30.8316 25.071L28.6506 24.4489L28.2258 25.9472L30.2099 26.5123L30.1944 26.5227L20.4447 33.5839V23.7335L20.8954 23.8631L21.3202 22.3648L20.4447 22.1159V16.439L30.8316 8.9164V25.0762V25.071Z"
          fill="#8E24AA"
        />
        <path
          d="M13.7979 20.2185V18.7876H12.2437V20.4051L10.8812 21.3954L10.1353 21.9397L11.047 23.1995L11.8086 22.6448L13.1659 21.6598L14.7614 22.1108L15.2795 22.2612L15.7043 20.7629L15.2225 20.6281L13.7979 20.2185Z"
          fill="#8E24AA"
        />
        <path
          d="M23.8137 23.0754L23.3882 24.5713L25.73 25.2384L26.1555 23.7425L23.8137 23.0754Z"
          fill="#8E24AA"
        />
        <path
          d="M8.03974 23.4605L5.76758 25.1113L6.68065 26.3699L8.95281 24.7191L8.03974 23.4605Z"
          fill="#8E24AA"
        />
        <path
          d="M13.7983 6.67676H12.2441V9.97923H13.7983V6.67676Z"
          fill="#8E24AA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2331_15039">
          <rect
            width="32"
            height="35"
            fill="white"
            transform="translate(0.385742 0.885742)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default ResponsiveSVG;
