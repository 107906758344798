import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, message, Modal, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../api/base';
import { CopyStoreBuildRequest } from '../../../../../api/store-build';
import {
  BuildSpace,
  ListStoreBuildModel,
} from '../../../../../models/store-build';
import { useAppDispatch } from '../../../../../store';
import {
  deleteStoreBuild,
  duplicateStoreBuild,
} from '../../../../../store/features/storeBuilds/storeBuildsSlice';
import {
  formatDate,
  getAuthToken,
  getMePermissions,
  propsAreEqual,
  UserPermissions,
} from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import './StoreBuildItem.less';

const { Text, Title } = Typography;

interface StoreBuildItemProps {
  build: ListStoreBuildModel;
}

const StoreBuildItem = ({ build }: StoreBuildItemProps) => {
  const {
    name,
    updated_at,
    uuid,
    createdByUser,
    image_url,
    feb_design_area_id,
  } = build;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const url = !!image_url ? `${image_url}?_token=${getAuthToken()}` : '';

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  useEffect(() => {
    onImageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageLoad = () => {
    if (!url) return setHasImageLoaded(true);

    const img = new Image();
    img.src = url;
    img.onload = () => setHasImageLoaded(true);
  };

  const onDuplicateSuccess = useCallback((build: ListStoreBuildModel) => {
    message.destroy('duplicating-build-message');
    message.success(`Build '${build?.name}' duplicated.`);
  }, []);

  const onDuplicateError = useCallback((err: ApiError) => {
    message.destroy('duplicating-build-message');
    handleError(err);
  }, []);

  const handleDuplicateBuild = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      try {
        message.loading({
          content: 'Duplicating build...',
          key: 'duplicating-build-message',
        });

        const payload: CopyStoreBuildRequest = { name: `${name} copy` };

        dispatch(duplicateStoreBuild({ storeBuildId: uuid, payload }));
        onDuplicateSuccess(build);
      } catch (error) {
        onDuplicateError(error as ApiError);
      }
    },

    [name, dispatch, uuid, onDuplicateSuccess, build, onDuplicateError]
  );

  const onDeleteSuccess = useCallback((build: ListStoreBuildModel) => {
    message.destroy('deleting-project-message');
    message.success(`Project '${build?.name}' deleted.`);
  }, []);

  const onDeleteError = useCallback((err: ApiError) => {
    message.destroy('deleting-build-message');
    handleError(err);
  }, []);

  const handleDeleteBuild = useCallback(
    async (build: ListStoreBuildModel) => {
      try {
        message.loading({
          content: 'Deleting build...',
          key: 'deleting-build-message',
        });

        await dispatch(deleteStoreBuild(uuid));
        onDeleteSuccess(build);
      } catch (error) {
        onDeleteError(error as ApiError);
      }
    },
    [dispatch, onDeleteError, onDeleteSuccess, uuid]
  );

  const handleConfirmDeleteBuild = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete build '${name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover it.',
        okText: 'Delete',
        onOk: async () => {
          await handleDeleteBuild(build);
        },
      });
    },
    [build, handleDeleteBuild, name]
  );

  const menu = useMemo(
    (): JSX.Element => (
      <Menu
        onClick={() => setIsMenuOpen(false)}
        className="store-build-item-menu"
      >
        {currentUserPermissions.includes(UserPermissions.FebDesignsEdit) ? (
          <Menu.Item key="0">
            <Link
              to={{
                hash: DrawerHashRoute.BuildForm,
                state: { data: build },
              }}
            >
              Edit
            </Link>
          </Menu.Item>
        ) : null}
        {currentUserPermissions.includes(UserPermissions.FebDesignsCreate) ? (
          <Menu.Item key="1">
            <div onClick={handleDuplicateBuild}>Duplicate</div>
          </Menu.Item>
        ) : null}
        {currentUserPermissions.includes(UserPermissions.FebDesignsDelete) ? (
          <Menu.Item key="2">
            <div onClick={handleConfirmDeleteBuild}>Delete</div>
          </Menu.Item>
        ) : null}
      </Menu>
    ),
    [
      build,
      currentUserPermissions,
      handleConfirmDeleteBuild,
      handleDuplicateBuild,
    ]
  );

  const moreMenuIconClass = cx('more-menu-icon', { active: isMenuOpen });

  const thumbnailClass = useMemo((): string => {
    switch (feb_design_area_id) {
      case BuildSpace.Open:
        return 'open-space';
      case BuildSpace.OpenWithCustomerService:
        return 'open-space-w-customer-service';
      case BuildSpace.LeftWithPharmacy:
        return '';
      case BuildSpace.RightWithCustomerService:
        return '';
      case BuildSpace.FullSizeGrocery:
        return 'full-size-grocery-space';
      case BuildSpace.Walmart:
        return 'walmart-space';
      case BuildSpace.CStore:
        return 'c-store-space';
      case BuildSpace.CStoreLCounter:
        return 'c-store-l-counter-space';
      case BuildSpace.CStoreNoSelfCheckout:
        return 'c-store-no-self-checkout-space';
      case BuildSpace.Lowes:
        return 'lowes';
      case BuildSpace.WalmartFrontEndSpace:
        return 'walmart-frontend-space';
    }
  }, [feb_design_area_id]);

  const menuTrigger = useMemo(
    (): JSX.Element => (
      <Dropdown
        overlay={menu}
        visible={isMenuOpen}
        trigger={['click']}
        onVisibleChange={(value) => setIsMenuOpen(value)}
      >
        <Tooltip title="More" mouseEnterDelay={1}>
          <MoreOutlined className={moreMenuIconClass} />
        </Tooltip>
      </Dropdown>
    ),
    [isMenuOpen, menu, moreMenuIconClass]
  );

  return (
    <Link to={`/store-builder/${uuid}`}>
      <div className="store-build-grid-item" data-cy="build">
        <div className="build-grid-item-section-top" data-cy="thumbnail">
          {hasImageLoaded ? (
            !!url ? (
              <div
                className={`build-grid-item-thumbnail ${thumbnailClass}`}
                style={{ backgroundImage: `url(${url})` }}
              />
            ) : (
              <div className="build-grid-item-thumbnail no-image">
                No preview image
              </div>
            )
          ) : (
            <div className="build-grid-item-thumbnail no-image">
              <LoadingOutlined style={{ color: '#8E24AA' }} />
            </div>
          )}
        </div>
        <div className="build-grid-item-section-bottom">
          <div className="build-grid-item-bottom-section-left">
            <Title level={5} ellipsis data-cy="build-name">
              {name}
            </Title>
            <Text ellipsis type="secondary">
              {formatDate(updated_at)}
            </Text>
            <Text ellipsis type="secondary">
              {createdByUser?.name}
            </Text>
          </div>
          <div className="build-grid-item-bottom-section-right">
            {menuTrigger}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(StoreBuildItem, propsAreEqual);
