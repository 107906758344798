import {
  CustomFieldModel,
  ProductCustomFieldValueType,
} from '../../../../models/custom-field';

export interface CustomFieldFormLocationState {
  data: CustomFieldModel;
}

export const ProductCustomFieldValueTypes: ProductCustomFieldValueType[] = [
  'int',
  'string',
  'float',
  'enum',
  'multi',
  'bool',
];

export const customFieldFieldTypes = [
  'item',
  'brands',
  'clients',
  'users',
  'categories',
  'tests',
  'models',
];
