import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { BackTop, Image, Input, Space, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { Location } from 'history';
import './ProductLibraryView.less';

import FiltersService from '../../../api/filters';
import heroImageLarge from '../../../assets/images/product-library-hero-image-large.png';
import heroImage from '../../../assets/images/product-library-hero-image.png';
import { ReactComponent as GridViewIcon } from '../../../assets/svgs/grid-view-icon.svg';
import { ReactComponent as TableViewIcon } from '../../../assets/svgs/list-view-icon.svg';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import useDebounce from '../../../hooks/useDebounce';
import {
  filterProducts,
  loadMoreProductLibraryItems,
  ProductLibraryState,
  setSearchQuery,
  setSelectedCategory,
  setSelectedFilters,
} from '../../../store/features/productLibrary/productLibrarySlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { isTabletScreenSizeOrSmaller } from '../../../util';
import Button from '../../elements/Button';
import MultiLevelSelect from '../../elements/MultiLevelSelect';
import { SelectDataItem } from '../../elements/MultiLevelSelect/MultiLevelSelect';
import NoResultsFound from '../../elements/NoResultsFound';
import ViewWrapper from '../../elements/ViewWrapper';
import ProductLibraryItemsList from './components/ProductLibraryItemsList';
import { ProductLibraryListViewType } from './components/ProductLibraryItemsList/ProductLibraryItemsList';
import ProductsFilters from './components/ProductsFilters';
import {
  FiltersType,
  SelectedFilters,
} from './components/ProductsFilters/ProductsFilters';
import { ProductLibraryViewLocationState } from './types';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
const { Search } = Input;

export const productLibraryListViewTypeKey = 'product-library-list-view-type';

interface ProductLibraryViewProps {
  location: Location<ProductLibraryViewLocationState>;
}

const SearchBar = ({
  searchingProducts,
  searchQuery,
  updateSearchQuery,
  viewType,
  setViewType,
  productLibraryListViewTypeKey,
  categoryFilters = [],
  loading = false,
  onSelectCategoryFilter,
  selected,
}: {
  searchingProducts: boolean;
  searchQuery: string;
  updateSearchQuery: (query: string) => void;
  viewType: ProductLibraryListViewType;
  setViewType: (viewType: ProductLibraryListViewType) => void;
  productLibraryListViewTypeKey: string;
  categoryFilters: SelectDataItem[];
  loading: boolean;
  onSelectCategoryFilter: (
    selectedCategory: SelectDataItem | 'All' | undefined
  ) => void;
  selected?: string;
}) => {
  const updateProductLibraryListViewType = useCallback(
    (type: ProductLibraryListViewType) => {
      setViewType(type);
      localStorage.setItem(productLibraryListViewTypeKey, type);
    },
    [productLibraryListViewTypeKey, setViewType]
  );

  const viewTypeIconClass = useCallback(
    (type: ProductLibraryListViewType) =>
      cx('product-library-view-type-icon', {
        selected: type === viewType,
      }),
    [viewType]
  );

  return (
    <div className="search-bar">
      <div className="search-bar-left">
        <MultiLevelSelect
          data={categoryFilters}
          loading={loading}
          onChange={onSelectCategoryFilter}
          selected={selected}
        />
        <Search
          className="search-input"
          allowClear={!searchingProducts}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          enterButton
          placeholder="Search for products..."
          suffix={searchingProducts && <Spin indicator={antSpinIcon} />}
        />
      </div>
      <Space size="middle" className="search-bar-right">
        <Tooltip title="Grid view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <Button
            onClick={() =>
              updateProductLibraryListViewType(ProductLibraryListViewType.Grid)
            }
            icon={
              <GridViewIcon
                className={viewTypeIconClass(ProductLibraryListViewType.Grid)}
              />
            }
          ></Button>
        </Tooltip>
        <Tooltip title="Table view" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <Button
            onClick={() =>
              updateProductLibraryListViewType(ProductLibraryListViewType.Table)
            }
            icon={
              <TableViewIcon
                className={viewTypeIconClass(ProductLibraryListViewType.Table)}
              />
            }
          ></Button>
        </Tooltip>
      </Space>
    </div>
  );
};

const HeroImage = () => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Image
        preview={false}
        width={'100%'}
        placeholder
        alt="brand hero banner"
        src={isTabletScreenSizeOrSmaller() ? heroImage : heroImageLarge}
        style={{
          minHeight: '200px',
          objectFit: 'fill',
        }}
      />
    </div>
  );
};

const ProductLibraryView = ({ location }: ProductLibraryViewProps) => {
  const dispatch = useAppDispatch();
  const [categoryFilters, setCategoryFilters] = useState<SelectDataItem[]>([]);
  const [filters, setFilters] = useState<FiltersType>({});
  const [fetchingFilters, setFetchingFilters] = useState<boolean>(false);

  const [viewType, setViewType] = useState<ProductLibraryListViewType>(
    (localStorage.getItem(
      productLibraryListViewTypeKey
    ) as ProductLibraryListViewType) || ProductLibraryListViewType.Grid
  );
  const productListCount = useAppSelector((state) => state.productList.count);

  const {
    value: productLibrary,
    fetchingProductLibraryItems,
    totalCount,
    pagination,
    searchingProductLibrary,
    allProductLibraryItems,
    searchQuery,
    selectedCategory,
    selectedFilters,
  } = useAppSelector<ProductLibraryState>((state) => state.productLibrary);

  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const hasNoSearchResultsFound =
    !fetchingProductLibraryItems &&
    !!searchQuery &&
    productLibrary.length === 0;

  const hasProductLibraryItems = !(
    !fetchingProductLibraryItems && allProductLibraryItems.length === 0
  );

  const hasMoreProductLibraryItems =
    hasProductLibraryItems &&
    pagination?.offset! + pagination?.offset! <= totalCount!;

  const isPermittedToLoadMore =
    !fetchingProductLibraryItems &&
    !searchingProductLibrary &&
    hasMoreProductLibraryItems;

  const fetchFilters = useCallback(async () => {
    setFetchingFilters(true);

    const res = await FiltersService().getFilters();
    const { categories, ...restFilters } = res.data;
    setCategoryFilters(categories || []);
    setFilters(restFilters);

    setFetchingFilters(false);
  }, []);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const updateSearchQuery = useCallback(
    (query: string) => {
      dispatch(setSearchQuery(query));
    },
    [dispatch]
  );

  const onFilterProducts = useCallback(() => {
    let newParams: ListProductLibraryParams = {
      _limit: viewType === ProductLibraryListViewType.Grid ? 30 : 10,
      _offset: 0,
      _order_by: 'updated_at:desc',
      status: 'approved',
    };

    if (selectedFilters) {
      Object.keys(selectedFilters).forEach((filterGroup) => {
        const filterKey = filterGroup as keyof FiltersType;

        if (selectedFilters[filterKey]?.length) {
          newParams = {
            ...newParams,
            [filterGroup]: selectedFilters[filterKey],
          };
        }
      });
    }

    if (selectedCategory && selectedCategory !== 'All') {
      newParams = {
        ...newParams,
        category: selectedCategory.key,
      };
    }

    dispatch(
      filterProducts({ params: newParams, query: debouncedSearchQuery })
    );
  }, [
    debouncedSearchQuery,
    dispatch,
    selectedCategory,
    selectedFilters,
    viewType,
  ]);

  const handleSelectCategoryFilter = useCallback(
    (data: SelectDataItem | 'All' | undefined) => {
      dispatch(setSelectedCategory(data));
    },
    [dispatch]
  );

  const handleFiltersSelected = useCallback(
    (data: SelectedFilters) => {
      dispatch(setSelectedFilters(data));
    },
    [dispatch]
  );

  const handleLoadMore = useCallback(() => {
    dispatch(
      loadMoreProductLibraryItems({
        hasMoreProductLibraryItems,
      })
    );
  }, [dispatch, hasMoreProductLibraryItems]);

  useEffect(() => {
    onFilterProducts();
  }, [onFilterProducts, productListCount]);

  const selected: string | undefined = useMemo(() => {
    if (!location.state?.category) return undefined;

    const { key } = location.state.category;
    return key;
  }, [location.state?.category]);

  const selectedFilter: SelectedFilters | undefined = useMemo(() => {
    const tagId = location.state?.tag?.id as unknown as string;
    const formatId = location.state?.format?.id as unknown as string;
    const marketId = location.state?.market?.id as unknown as string;
    const brandId = location.state?.brand?.uuid;
    const manufacturerId = location.state?.manufacturer?.uuid;
    const parentcoId = location.state?.parentco?.uuid;

    return {
      tags: tagId ? [tagId] : [],
      format: formatId ? [formatId] : [],
      market: marketId ? [marketId] : [],
      brands: brandId ? [brandId] : [],
      manufacturers: manufacturerId ? [manufacturerId] : [],
      parentco: parentcoId ? [parentcoId] : [],
    };
  }, [location.state]);

  return (
    <ViewWrapper>
      <div className="search-bar-wrapper">
        <SearchBar
          searchQuery={searchQuery}
          searchingProducts={fetchingProductLibraryItems}
          updateSearchQuery={updateSearchQuery}
          viewType={viewType}
          setViewType={setViewType}
          productLibraryListViewTypeKey={productLibraryListViewTypeKey}
          categoryFilters={categoryFilters}
          loading={fetchingFilters}
          onSelectCategoryFilter={handleSelectCategoryFilter}
          selected={selected}
        />
      </div>
      <div className="content-section">
        <div className="content-section-left">
          <ProductsFilters
            selectedFilter={selectedFilter}
            filters={filters}
            loading={fetchingFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </div>
        <div className="content-section-right">
          <HeroImage />
          <div className="product-library-items-list-wrapper">
            {hasProductLibraryItems && hasNoSearchResultsFound ? (
              <NoResultsFound
                searchQuery={searchQuery}
                onClear={() => updateSearchQuery('')}
              />
            ) : (
              <ProductLibraryItemsList
                viewType={viewType}
                loading={fetchingProductLibraryItems}
                productLibrary={productLibrary}
                onLoadMore={handleLoadMore}
                hasMore={isPermittedToLoadMore}
              />
            )}
          </div>
        </div>
      </div>

      <BackTop />
    </ViewWrapper>
  );
};

export default ProductLibraryView;
