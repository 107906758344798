import { FC, useState, useCallback, useContext, memo } from 'react';

import './OptionItem.less';
import {
  DeleteFilled,
  EditFilled,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Tooltip, Row, Col, Input, Typography, Form, message } from 'antd';

import { ApiError, handleError } from '../../../../../../api/base';
import CustomFieldService, {
  CustomFieldOptionResponse,
} from '../../../../../../api/custom-field';
import { CustomEvents } from '../../../../../../hooks/event-listener';
import { CustomFieldOption } from '../../../../../../models/custom-field';
import { propsAreEqual } from '../../../../../../util';
import { CustomFieldContext } from '../../CustomFieldForm';

const { Text } = Typography;

interface OptionItemProps {
  index: number;
  option: CustomFieldOption;
  onRemove: (index: number) => void;
  onChange: (index: number, field: string, value: any) => void;
}

const OptionItem: FC<OptionItemProps> = (props) => {
  const { onRemove, onChange, index, option } = props;

  const isNewOption = !option.id;

  const { customField } = useContext(CustomFieldContext);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState<boolean>(
    isNewOption ? true : false
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onSaveSuccess = useCallback(
    (resp: CustomFieldOptionResponse) => {
      setIsSaving(false);
      message.success('Option saved.');

      onChange(index, 'id', resp.data.id);

      const nextCustomField = {
        ...customField,
        options: isNewOption
          ? [...customField?.options!, resp.data]
          : customField?.options.map((opt) =>
              opt.id === resp.data.id ? resp.data : opt
            ),
      };

      dispatchEvent(
        new CustomEvent(CustomEvents.CustomFieldUpdated, {
          detail: nextCustomField,
        })
      );

      setIsEditing(false);
    },
    [customField, index, isNewOption, onChange]
  );

  const onSaveError = useCallback((err: ApiError) => {
    setIsSaving(false);
    handleError(err);
    setIsEditing(false);
  }, []);

  const saveOption = useCallback(() => {
    setIsSaving(true);

    const save = isNewOption
      ? CustomFieldService().createCustomFieldOption(customField?.id!, option)
      : CustomFieldService().updateCustomFieldOption(
          customField?.id!,
          option.id,
          option
        );

    save.then(onSaveSuccess).catch(onSaveError);
  }, [isNewOption, customField?.id, option, onSaveSuccess, onSaveError]);

  const handleSaveOption = useCallback(() => {
    form.validateFields(['name', 'option_key']).then(saveOption);
  }, [form, saveOption]);

  const onDeleteSuccess = useCallback(() => {
    setIsDeleting(false);
    message.success('Option deleted.');
    onRemove(index);
  }, [index, onRemove]);

  const onDeleteError = useCallback((err: ApiError) => {
    setIsDeleting(false);
    handleError(err);
  }, []);

  const handleRemoveOption = () => {
    if (isNewOption) {
      onRemove(index);
      return;
    }

    setIsDeleting(true);

    CustomFieldService()
      .deleteCustomFieldOption(customField?.id!, option.id!)
      .then(onDeleteSuccess)
      .catch(onDeleteError);
    onDeleteSuccess();
  };

  return (
    <Form initialValues={option} form={form} requiredMark={false}>
      <Row gutter={[40, 8]}>
        <Col span={12} className="option-item-section">
          {isEditing ? (
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Name is required.' }]}
            >
              <Input
                onChange={(e) => onChange(index, 'name', e.target.value)}
                placeholder="Name"
              />
            </Form.Item>
          ) : (
            <Text style={{ lineHeight: '42px' }}>{option.name}</Text>
          )}
        </Col>
        <Col span={10} className="option-item-section">
          {isEditing ? (
            <Form.Item
              name="option_key"
              rules={[{ required: true, message: 'Key is required.' }]}
            >
              <Input
                onChange={(e) => onChange(index, 'option_key', e.target.value)}
                placeholder="Value"
              />
            </Form.Item>
          ) : (
            <Text style={{ lineHeight: '42px' }}>{option.option_key}</Text>
          )}
        </Col>
        <Col
          span={2}
          className={`option-actions-section ${isEditing ? 'editing' : ''}`}
        >
          {!!customField?.id ? (
            isEditing ? (
              isSaving ? (
                <LoadingOutlined
                  style={{
                    fontSize: '20px',
                    marginRight: '1.2rem',
                    color: '#1890ff',
                  }}
                />
              ) : (
                <Tooltip title="Save option">
                  <CheckOutlined
                    className="height-40px align-center justify-center"
                    style={{
                      fontSize: '20px',
                      marginRight: '1.2rem',
                      color: '#52c41a',
                    }}
                    onClick={handleSaveOption}
                  />
                </Tooltip>
              )
            ) : (
              <Tooltip title="Edit option">
                <EditFilled
                  style={{
                    fontSize: '20px',
                    marginRight: '1.2rem',
                    color: '#becada',
                  }}
                  onClick={() => setIsEditing(true)}
                />
              </Tooltip>
            )
          ) : null}

          {isDeleting ? (
            <LoadingOutlined
              className="height-40px align-center justify-center"
              style={{ fontSize: '20px', color: '#1890ff' }}
            />
          ) : (
            <Tooltip title="Delete option" placement="left">
              <DeleteFilled
                style={{ fontSize: '20px', color: '#becada' }}
                onClick={handleRemoveOption}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default memo(OptionItem, propsAreEqual);
