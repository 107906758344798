import { BASE_API_URL } from '../../config';
import { del, get, patch, post } from '../base';
import {
  CreateUpdateOVRCellRequest,
  CreateUpdateOVRCellResponse,
  OVRCellsResponse,
} from './reqres';

export default function OVRCellService() {
  return Object.freeze({
    fetchOvrCells(query = ''): Promise<OVRCellsResponse> {
      return get(`${BASE_API_URL()}/ovr/cell${query}`);
    },
    createOVRCell(
      cellData: CreateUpdateOVRCellRequest
    ): Promise<CreateUpdateOVRCellResponse> {
      return post(`${BASE_API_URL()}/ovr/cell`, cellData);
    },
    updateOVRCell(
      cellUUID: string,
      cellData: CreateUpdateOVRCellRequest
    ): Promise<CreateUpdateOVRCellResponse> {
      return patch(`${BASE_API_URL()}/ovr/cell/${cellUUID}`, cellData);
    },
    downloadOvrCell(cellUUID: string) {
      return get(`${BASE_API_URL()}/ovr/cell/${cellUUID}/`);
    },
    deleteOvrCell(cellUUID: string) {
      return del(`${BASE_API_URL()}/ovr/cell/${cellUUID}`);
    },
    toggleOVRCell(
      cellUUID: string,
      approved: boolean
    ): Promise<CreateUpdateOVRCellResponse> {
      return patch(`${BASE_API_URL()}/ovr/cell/${cellUUID}`, {
        approved,
      });
    },
  });
}
