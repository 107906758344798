import { memo, useMemo } from 'react';

import { Badge, ConfigProvider, List, Spin, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { ListProductLibraryModel } from '../../../../../../../models/product-library';
import { useAppSelector } from '../../../../../../../store';
import { propsAreEqual } from '../../../../../../../util';
import { customizeRenderEmpty } from '../../../../../../containers/Tables/ProductsTable/components/customizeRenderEmpty';
import ProductLibraryItem from '../../../ProductLibraryItem';

import './ProductLibraryGridList.less';

interface ProductLibraryGridListProps {
  loading: boolean;
  onLoadMore?: () => void;
  hasMore: boolean;
  productLibrary: ListProductLibraryModel[];
}

const { Title } = Typography;

const ProductLibraryGridList = ({
  loading,
  onLoadMore,
  hasMore,
  productLibrary,
}: ProductLibraryGridListProps) => {
  const { totalCount } = useAppSelector((state) => state.productLibrary);

  const showLoadMoreSpinner = loading && hasMore;

  const headerTitle = useMemo(
    (): JSX.Element => (
      <div className="product-library-table-header">
        <Title level={3}>Recently Added</Title>
        <Badge
          size="default"
          count={totalCount}
          className="total-count-badge"
        />
      </div>
    ),
    [totalCount]
  );

  return (
    <div className="product-library-grid-list-wrapper">
      {headerTitle}
      <InfiniteScroll
        loadMore={!!onLoadMore ? onLoadMore : () => {}}
        hasMore={hasMore}
      >
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <List
            className="product-library-grid-list"
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 3,
              xxl: 4,
            }}
            itemLayout="vertical"
            dataSource={productLibrary}
            rowKey={(productLibrary) => `${productLibrary.uuid}-row-key`}
            renderItem={(product, index) => (
              <List.Item key={`product-library-item-${index}`}>
                <ProductLibraryItem product={product} />
              </List.Item>
            )}
          >
            {showLoadMoreSpinner && (
              <div className="loading-more-spin-wrapper">
                <Spin />
              </div>
            )}
          </List>
        </ConfigProvider>
      </InfiniteScroll>
    </div>
  );
};

export default memo(ProductLibraryGridList, propsAreEqual);
