import { MediaFile } from '../../api/media/reqres';

export interface CustomFieldModel {
  id?: number;
  name: string;
  type: string;
  value_type: ProductCustomFieldValueType;
  created_by?: string;
  created_at?: string;
  updated_at?: string;
  field_key: string;
  is_used?: boolean;
  options: CustomFieldOption[];
}

export interface CustomFieldOption {
  created_at: string;
  custom_field_id: number;
  deleted_at: string;
  id: number;
  name: string;
  option_key: string;
  parent_id: string;
  status: string | null;
  updated_at: string;
  visible: number;
  media_files: MediaFile[];
  in_processing: number;
  in_library: number;
  in_use?: boolean;
}

export const initialNewCustomFieldModel: CustomFieldModel = {
  id: undefined,
  name: '',
  type: '',
  value_type: '',
  field_key: '',
  options: [] as CustomFieldOption[],
};

export type ProductCustomFieldValueType =
  | 'int'
  | 'string'
  | 'float'
  | 'enum'
  | 'multi'
  | 'bool'
  | '';
