import {
  AuthenticateResponse,
  AuthenticateRequest,
  MeResponse,
  SendPasswordResetVerificationCodeResponse,
  VerifyResetCodeResponse,
  VerifyResetCodeRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  TwoFactorAuthRequiredResponse,
  Send2FACodeResponse,
  Verify2FACodeRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { UserModel } from '../../models/user';
import { get, post, postAuth } from '../base';

const onAuthSuccess = (resp: AuthenticateResponse): Promise<MeResponse> => {
  AuthService().setToken(resp.data.token);
  return AuthService().getMe();
};

const onMeSuccess = (resp: MeResponse): Promise<MeResponse> => {
  AuthService().setMe(resp.data);
  return Promise.resolve(resp);
};

export default function AuthService() {
  return Object.freeze({
    async authenticate(
      req: AuthenticateRequest
    ): Promise<MeResponse | TwoFactorAuthRequiredResponse> {
      const authResp = await postAuth(`${BASE_API_URL()}/auth/login`, req);
      if (authResp.data.two_factor_required) {
        return authResp;
      }
      const meResp = await onAuthSuccess(authResp);
      return onMeSuccess(meResp);
    },
    send2FACode(email: string): Promise<Send2FACodeResponse> {
      // console.log('Sending code...');
      return post(`${BASE_API_URL()}/send2FACode`, { email });
    },
    verify2FACode(body: Verify2FACodeRequest): Promise<MeResponse> {
      return post(`${BASE_API_URL()}/verify2FACode`, body)
        .then(onAuthSuccess)
        .then(onMeSuccess);
    },
    getMe(): Promise<MeResponse> {
      return get(`${BASE_API_URL()}/user/me?_columns=permissions.*,roles.*`);
    },
    setToken(token: string): void {
      localStorage.setItem('token', token);
    },
    setMe(me: UserModel): void {
      localStorage.setItem('me', JSON.stringify(me));
    },
    logout(): void {
      localStorage.clear();
      const ENV = window.location.origin;
      window.location.replace(`${ENV}`);
    },
    isAuthenticated(): boolean {
      const token = localStorage.getItem('token');
      const me = localStorage.getItem('me');
      if (!token) return false;
      if (!me) return false;
      // since this is a new prop thats needed for app to function
      if (!JSON.parse(me).permissions) return false;
      return true;
    },
    sendPasswordResetVerificationCode(
      email: string
    ): Promise<SendPasswordResetVerificationCodeResponse> {
      return post(`${BASE_API_URL()}/sendResetPasswordCode`, { email });
    },
    verifyResetCode(
      body: VerifyResetCodeRequest
    ): Promise<VerifyResetCodeResponse> {
      return post(`${BASE_API_URL()}/verifyResetCode`, body);
    },
    resetPassword(body: ResetPasswordRequest): Promise<ResetPasswordResponse> {
      return post(`${BASE_API_URL()}/resetPassword`, body);
    },
  });
}
