import { generateUuidv4 } from '../../../../util';
import {
  Draw2DFigureModel,
  FigureAttributesModel,
  QueueLineModel,
  RenderType,
  RSFFixtureDataModel,
  FixtureId,
  MeasureToolAttributesModel,
  TextAttributesModel,
} from '../components/BuilderLayoutEditor/types';
import { ENV_BG_IMAGE_ID } from '../config';
import {
  shopperByIdMap,
  fixtureByIdMap,
  helperByIdMap,
  FigureModel,
  staticFixtureByIdMap,
} from '../figures';

const reverseSidewaysFixtures = [95, 96, 97, 98];

export const isFigureRotatedSideways = (
  type: RenderType,
  fixtureId?: number
): boolean => {
  if (fixtureId && reverseSidewaysFixtures.includes(+fixtureId)) {
    return (
      type === 'topdown_l' || type === 'topdown_r' || type.includes('R180')
    );
  }
  return type.includes('R90') || type.includes('R270');
};

export const getFigureDimensions = (
  figure: FigureModel,
  renderType: RenderType
): { width: number; height: number } => {
  const isSideways = isFigureRotatedSideways(renderType, figure.id);
  const width = scaleCanvasFigure(isSideways ? figure.d : figure.w, figure.som);
  const height = scaleCanvasFigure(
    isSideways ? figure.w : figure.d,
    figure.som
  );
  return { width, height };
};

export const scaleCanvasFigure = (
  units: number,
  systemOfMeasurement: string
) => {
  return systemOfMeasurement === 'imperial' ? units * 2.54 : units * 100.0;
};

export const isLoweFixture = (fig: Draw2DFigureModel): boolean => {
  return (
    +fig.userData?.fixtureId === 133 ||
    +fig.userData?.fixtureId === 134 ||
    +fig.userData?.fixtureId === 135
  );
};

export const isRackFixture = (fig: Draw2DFigureModel): boolean => {
  return (
    +fig.userData?.fixtureId === 31 ||
    +fig.userData?.fixtureId === 32 ||
    +fig.userData?.fixtureId === 33 ||
    +fig.userData?.fixtureId === 34 ||
    +fig.userData?.fixtureId === 35 ||
    +fig.userData?.fixtureId === 36 ||
    +fig.userData?.fixtureId === 129 ||
    +fig.userData?.fixtureId === 141 ||
    +fig.userData?.fixtureId === 142
  );
};

export const isTobaccoCaseFixture = (fig: Draw2DFigureModel): boolean => {
  return +fig.userData?.fixtureId === 55;
};

export const isOpenAirCoolerFixture = (fig: Draw2DFigureModel): boolean => {
  return (
    +fig.userData?.fixtureId === 60 ||
    +fig.userData?.fixtureId === 61 ||
    +fig.userData?.fixtureId === 62
  );
};

export const isLottoKioskFixture = (fig: Draw2DFigureModel): boolean => {
  return +fig.userData?.fixtureId === 51;
};

export const isGiftCardCenterFixture = (fig: Draw2DFigureModel): boolean => {
  return +fig.userData?.fixtureId === 50;
};

export const isLockerFixture = (fig: Draw2DFigureModel): boolean => {
  return +fig.userData?.fixtureId === 53 || +fig.userData?.fixtureId === 54;
};

export const isShopper = (figure: Draw2DFigureModel): boolean => {
  return !!figure.userData && isShopperId(figure.userData.fixtureId);
};

export const isMeasureTool = (figure: Draw2DFigureModel): boolean => {
  return !!figure.userData && isMeasureToolId(figure.userData.fixtureId);
};

export const isFixture = (figure: Draw2DFigureModel): boolean => {
  return !!figure.userData && isFixtureId(figure.userData.fixtureId);
};

export const isText = (figure: Draw2DFigureModel): boolean => {
  return !!figure.text;
};

export const isStaticFixture = (figure: Draw2DFigureModel): boolean => {
  return !!figure.userData && isStaticFixtureId(figure.userData.fixtureId);
};

export const isGroupFigure = (figure: Draw2DFigureModel): boolean => {
  return (
    !!figure.cssClass &&
    figure.cssClass.includes('draw2d_shape_composite_Group')
  );
};

export const isBackgroundEnvironmentFigure = (
  figure: Draw2DFigureModel
): boolean => {
  return figure.id === ENV_BG_IMAGE_ID;
};

export const isStaticFixtureId = (id: number): boolean => {
  return Object.keys(staticFixtureByIdMap).includes(`${id}`);
};

export const isShopperId = (id: number): boolean => {
  return Object.keys(shopperByIdMap).includes(`${id}`);
};

export const isHelperId = (id: number): boolean => {
  return Object.keys(helperByIdMap).includes(`${id}`);
};

export const isTextId = (id: number): boolean => {
  return id === 20;
};

export const isMeasureToolId = (id: number): boolean => {
  return id === 21 || id === 24;
};

export const isFixtureId = (id: number): id is FixtureId => {
  return Object.keys(fixtureByIdMap).includes(`${id}`);
};

export const rotationAngleToRenderType = (
  rotationAngle: number,
  isRenderedImage: boolean
): RenderType => {
  const type = isRenderedImage ? 'r' : 'l';

  switch (rotationAngle) {
    case 90:
      return `topdown_${type}R90` as RenderType;
    case 180:
      return `topdown_${type}R180` as RenderType;
    case 270:
      return `topdown_${type}R270` as RenderType;
    default:
      return `topdown_${type}` as RenderType;
  }
};

export const renderTypeToRotationAngle = (renderType: RenderType): number => {
  switch (true) {
    case renderType.includes('R90'):
      return 90;
    case renderType.includes('R180'):
      return 180;
    case renderType.includes('R270'):
      return 270;
    default:
      return 0;
  }
};

const generateAttributesFromShopperRSFData = (
  shopper: RSFFixtureDataModel,
  buildData: any
): FigureAttributesModel => {
  return {
    path: rotationAngleToRenderType(shopper.r, false),
    x: shopper.x,
    y: shopper.y,
    width: shopper.w,
    height: shopper.h,
    resizeable: false,
    userData: {
      id: shopper.id,
      fixtureId: buildData.library[shopper.key].id,
      renderType: rotationAngleToRenderType(shopper.r, false),
      h: shopper.h,
      name: shopper.name,
      key: shopper.key,
      shopper: {
        orderInLine: shopper.orderInLine!,
        lineId: shopper.lineId!,
        color: shopper.lineColor!,
        type: buildData.library[shopper.key].id,
        elapsedWaitTime: shopper.elapsedWaitTime!,
      },
    },
  };
};

export const generateShopperAttributesFromFigure = (
  figure: Draw2DFigureModel
): FigureAttributesModel => {
  return {
    path: figure.userData.renderType,
    x: figure.x,
    y: figure.y,
    width: figure.width,
    height: figure.height,
    resizeable: false,
    userData: {
      id: figure.id,
      fixtureId: figure.userData.fixtureId,
      renderType: figure.userData.renderType,
      h: figure.height,
      name: figure.userData.name,
      key: figure.userData.key,
      shopper: figure.userData.shopper!,
    },
  };
};

export const getInitialQueueLines = (buildData: any): QueueLineModel[] => {
  const initialFixtures = buildData.data;

  if (!initialFixtures) return [];

  let initialQueueLines: QueueLineModel[] = [];

  initialFixtures.forEach((fig: RSFFixtureDataModel) => {
    if (!fig.lineId) return;

    const hasRelativeQueueLine = initialQueueLines.some(
      (line: QueueLineModel) => line.lineId === fig.lineId
    );

    if (hasRelativeQueueLine) {
      initialQueueLines = initialQueueLines.map((line: QueueLineModel) => {
        if (line.lineId === fig.lineId) {
          const itemAttributes = generateAttributesFromShopperRSFData(
            fig,
            buildData
          );
          return {
            ...line,
            shoppers: [...line.shoppers, itemAttributes],
          };
        }
        return line;
      });

      return;
    }

    const itemAttributes = generateAttributesFromShopperRSFData(fig, buildData);

    initialQueueLines = [
      ...initialQueueLines,
      {
        lineId: fig.lineId!,
        color: fig.lineColor!,
        shoppers: [itemAttributes],
      },
    ];
  });

  return initialQueueLines;
};

export const getNextRotatedRender = (renderType: RenderType): RenderType => {
  switch (renderType) {
    case 'topdown_l':
      return 'topdown_lR90';
    case 'topdown_lR90':
      return 'topdown_lR180';
    case 'topdown_lR180':
      return 'topdown_lR270';
    case 'topdown_lR270':
      return 'topdown_l';
    case 'topdown_r':
      return 'topdown_rR90';
    case 'topdown_rR90':
      return 'topdown_rR180';
    case 'topdown_rR180':
      return 'topdown_rR270';
    case 'topdown_rR270':
      return 'topdown_r';
  }
};

export const getNextRenderType = (
  renderType: RenderType,
  isRenderedView: boolean
): RenderType => {
  const viewType = renderType.split('_')[0];
  const viewSpec = renderType.split('_')[1];
  const newViewSpec = isRenderedView
    ? `r${viewSpec.slice(1)}`
    : `l${viewSpec.slice(1)}`;

  return `${viewType}_${newViewSpec}` as RenderType;
};

export const getMeasureToolAttributes = (
  measureTool: any,
  x: number,
  y: number,
  attrs?: MeasureToolAttributesModel
) => {
  return !!attrs
    ? {
        ...attrs,
        x,
        y,
        userData: {
          id: generateUuidv4(),
          key: measureTool.key,
          fixtureId: measureTool.id,
          name: measureTool.name,
        },
      }
    : {
        x,
        y,
        userData: {
          id: generateUuidv4(),
          key: measureTool.key,
          fixtureId: measureTool.id,
          name: measureTool.name,
        },
      };
};

export const getTextAttributes = (
  x: number,
  y: number,
  attrs?: TextAttributesModel
) => {
  return !!attrs
    ? {
        ...attrs,
        x,
        y,
        resizeable: true,
        selectable: true,
        userData: {
          id: generateUuidv4(),
          key: 'Text',
          fixtureId: 20,
          name: 'Text',
        },
      }
    : {
        text: 'text',
        x,
        y,
        rotationAngle: 360,
        resizeable: true,
        selectable: true,
        userData: {
          id: generateUuidv4(),
          key: 'Text',
          fixtureId: 20,
          name: 'Text',
        },
      };
};
