interface FileData {
  objectUrl: string;
  objectKey: string;
  uuid: string;
}

export type AutomationQueueFileStatus =
  | 'new'
  | 'available'
  | 'locked'
  | 'complete';

export interface RSGFileModel {
  client_id: string;
  created_at: Date;
  created_by: string;
  data: FileData;
  description?: string;
  name: string;
  payload_size: string;
  progress: number;
  status: AutomationQueueFileStatus;
  updated_at: Date;
  uuid: string;
  permissions: { key: string }[];
}

export const initialNewRSGFileModel = {
  name: '',
  description: '',
};
