import { useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Space, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import { RootState, useAppDispatch } from '../../../store';
import {
  fetchRoles,
  RolesState,
  searchRolesByName,
} from '../../../store/features/acl/roles/rolesSlice';
import { getMePermissions, UserPermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import NoResultsFound from '../../elements/NoResultsFound';
import RoleTableList from './components/RoleTableList/RoleTableList';
import './RolesView.less';

const { Title } = Typography;
const { Search } = Input;

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const RolesView = () => {
  const dispatch = useAppDispatch();
  const {
    value: roles,
    allRoles,
    fetchingRoles,
    searchingRoles,
  } = useSelector<RootState, RolesState>((state) => state.roles);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const noSearchResultsFound = useMemo(
    () => !searchingRoles && !!searchQuery && roles.length === 0,
    [roles.length, searchQuery, searchingRoles]
  );

  const hasRoles = !fetchingRoles && allRoles.length !== 0;

  const createNewRoleButton = useMemo(
    (): JSX.Element => (
      <Link className="create-btn" to={DrawerHashRoute.RoleForm}>
        <Button type="primary" data-cy="create-new-role-btn">
          Create New Role
        </Button>
      </Link>
    ),
    []
  );

  useEffect(() => {
    dispatch(searchRolesByName(debouncedSearchQuery));
  }, [debouncedSearchQuery, dispatch]);

  const onSearch = (query: string) => {
    dispatch(searchRolesByName(query));
  };

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  return (
    <div className="main-wrapper">
      <Space direction="vertical" size="small">
        <Title level={5}>Roles</Title>
        <div className="actions-wrapper">
          <Search
            placeholder="Search"
            onSearch={(query) => onSearch(query)}
            onChange={(e) => updateSearchQuery(e.target.value)}
            enterButton
            suffix={searchingRoles && <Spin indicator={antSpinIcon} />}
          />
          {currentUserPermissions.includes(UserPermissions.RolesCreate)
            ? createNewRoleButton
            : null}
        </div>
        <div className="content-wrapper">
          {hasRoles ? (
            noSearchResultsFound ? (
              <NoResultsFound
                searchQuery={searchQuery}
                onClear={() => updateSearchQuery('')}
              />
            ) : (
              <RoleTableList
                roles={roles}
                loading={fetchingRoles}
                view="Roles"
              />
            )
          ) : (
            <Empty
              description="Currently, there are no roles"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              {createNewRoleButton}
            </Empty>
          )}
        </div>
      </Space>
    </div>
  );
};

export default RolesView;
