import { FC, useEffect, memo } from 'react';

import './OptionsInput.less';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { CustomFieldOption } from '../../../../../../models/custom-field';
import { propsAreEqual } from '../../../../../../util';
import Button from '../../../../../elements/Button';
import OptionItem from '../OptionItem';

interface OptionsInputProps {
  options: CustomFieldOption[];
  onAddOption: () => void;
  onRemoveOption: (index: number) => void;
  onChangeOption: (index: number, field: string, value: any) => void;
}

const OptionsInput: FC<OptionsInputProps> = (props) => {
  const { onAddOption, onRemoveOption, onChangeOption, options } = props;

  useEffect(() => {
    if (options.length !== 0) return;
    onAddOption();
  }, [onAddOption, options.length]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row gutter={[40, 8]}>
        <Col span={12} className="option-item-section">
          <div className="option-item-col-title">Name</div>
        </Col>
        <Col span={10} className="option-item-section">
          <div className="option-item-col-title">Value</div>
        </Col>
        <Col span={2} className="option-item-section" />
      </Row>
      {options.map((option, index) => (
        <OptionItem
          key={`${index}-option`}
          index={index}
          option={option}
          onChange={onChangeOption}
          onRemove={onRemoveOption}
        />
      ))}
      <Button onClick={onAddOption} style={{ width: '100%' }}>
        Add Option
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default memo(OptionsInput, propsAreEqual);
