import { memo } from 'react';

import { List, Skeleton, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { ListStoreBuildModel } from '../../../../../models/store-build';
import { propsAreEqual } from '../../../../../util';
import StoreBuildItem from '../StoreBuildItem';
interface StoreBuildItemsListProps {
  skeletonItemLength: number;
  builds: ListStoreBuildModel[];
  loading: boolean;
  onLoadMore?: () => void;
  hasMore: boolean;
}

const StoreBuildItemsList = ({
  builds,
  loading,
  skeletonItemLength,
  hasMore,
  onLoadMore,
}: StoreBuildItemsListProps) => {
  return (
    <InfiniteScroll
      loadMore={!!onLoadMore ? onLoadMore : () => {}}
      hasMore={hasMore}
    >
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }}
        itemLayout="horizontal"
        dataSource={builds}
        renderItem={(build, index) => (
          <List.Item key={`store-build-item-${index}`}>
            <StoreBuildItem build={build} />
          </List.Item>
        )}
      >
        {loading && builds.length === 0 && (
          <div className="loading-skeleton-cards-wrapper">
            {new Array(skeletonItemLength).fill(1).map((_, index) => (
              <List.Item key={`${index}-build-item-skeleton-key`}>
                <div
                  className="store-build-grid-item"
                  style={{
                    height: 280,
                    marginRight: 16,
                    marginBottom: 16,
                  }}
                >
                  <div className="build-grid-item-section-top">
                    <Skeleton.Image
                      className="build-grid-item-thumbnail loading"
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </div>
                  <div className="build-grid-item-section-bottom">
                    <Skeleton
                      active
                      round
                      title={{ width: 200 }}
                      paragraph={{ rows: 1, width: 200 }}
                    />
                  </div>
                </div>
              </List.Item>
            ))}
          </div>
        )}

        {loading && builds.length > 0 && (
          <div className="loading-more-spin-wrapper">
            <Spin />
          </div>
        )}
      </List>
    </InfiniteScroll>
  );
};

export default memo(StoreBuildItemsList, propsAreEqual);
