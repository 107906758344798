import { ApiLogsResponse } from './reqres';
import { BASE_API_URL } from '../../config';
import { get, ListApiResponse } from '../base';

export default function ApiLogService() {
  return Object.freeze({
    getApiLogs(query: string): Promise<ApiLogsResponse> {
      return get(
        `${BASE_API_URL()}/apilogs${query}`
      ) as Promise<ListApiResponse>;
    },
  });
}
