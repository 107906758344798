import { FC, Fragment } from 'react';
import './PreviewFixtureProperties.less';

import CounterProperties from './components/CounterProperties';
import FixtureProperties from './components/FixtureProperties';
import MeasuringToolProperties from './components/MeasuringToolProperties';
import ShopperProperties from './components/ShopperProperties';
import TextLabelProperties from './components/TextLabelProperties';
import { staticFixtureByIdMap } from '../../../../figures';
import {
  isFixture,
  isGroupFigure,
  isMeasureTool,
  isShopper,
  isText,
  isStaticFixture,
} from '../../../../util';
import {
  Draw2DFigureModel,
  QueueLineModel,
  ShopperType,
  ShopperUserDataModel,
} from '../../../BuilderLayoutEditor/types';
interface PreviewFixturePropertiesProps {
  selectedFigures: Draw2DFigureModel[];
  expanded: boolean;
  onUpdateTextLabel: (
    field: 'bgColor' | 'fontSize' | 'bold' | 'fontColor',
    value: string | boolean
  ) => void;
  onUpdateMeasureTool: (field: 'width' | 'height', value: number) => void;
  onUpdateShopper: (
    shopper: ShopperUserDataModel,
    action: 'updateShopperType' | 'removeShopper' | 'updateShopperElaspedTime',
    value?: ShopperType | string
  ) => void;
  selectedShopperQueueLine?: QueueLineModel;
  overlappingShoppers: string[];
  onUpdateCounter: (counterId: keyof typeof staticFixtureByIdMap) => void;
}

const PreviewFixtureProperties: FC<PreviewFixturePropertiesProps> = (props) => {
  const {
    selectedFigures,
    onUpdateTextLabel,
    onUpdateMeasureTool,
    selectedShopperQueueLine,
    onUpdateShopper,
    expanded,
    overlappingShoppers,
    onUpdateCounter,
  } = props;

  const hasNoSelectedItems = !selectedFigures || selectedFigures.length === 0;
  const hasMultipleSelectedItems =
    !hasNoSelectedItems &&
    (selectedFigures.length > 1 || isGroupFigure(selectedFigures[0]));

  switch (true) {
    case hasNoSelectedItems:
      return <Fragment>No item selected.</Fragment>;
    case hasMultipleSelectedItems:
      return <Fragment>Multiple items selected.</Fragment>;
    case isShopper(selectedFigures[0]):
      return (
        <ShopperProperties
          shopper={selectedFigures[0]}
          selectedShopperQueueLine={selectedShopperQueueLine!}
          onUpdateShopper={onUpdateShopper}
          overlappingShoppers={overlappingShoppers}
        />
      );
    case isMeasureTool(selectedFigures[0]):
      return (
        <MeasuringToolProperties
          measuringTool={selectedFigures[0]}
          onUpdateMeasureTool={onUpdateMeasureTool}
        />
      );
    case isText(selectedFigures[0]):
      return (
        <TextLabelProperties
          textLabel={selectedFigures[0]}
          onUpdateTextLabel={onUpdateTextLabel}
        />
      );
    case isFixture(selectedFigures[0]):
      return (
        <FixtureProperties fixture={selectedFigures[0]} expanded={expanded} />
      );
    case isStaticFixture(selectedFigures[0]):
      return (
        <CounterProperties
          expanded={expanded}
          counter={selectedFigures[0]}
          onUpdateCounter={onUpdateCounter}
        />
      );
    default:
      return (
        <Fragment>
          No item selected. (This is an unhandled unique scenario)
        </Fragment>
      );
  }
};

export default PreviewFixtureProperties;
