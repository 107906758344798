import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, PaginationModel, handleError } from '../../../api/base';
import CustomFieldService, {
  BulkCreateCustomFieldOptions,
} from '../../../api/custom-field';
import { ListCustomFieldsParams } from '../../../hooks/custom-field';
import {
  CustomFieldModel,
  CustomFieldOption,
} from '../../../models/custom-field';
import { convertParamsToQuery } from '../../../util';
import { CustomFieldOptionsParams } from '../metaData/metaDataSlice';

export interface CustomFieldState {
  customField: CustomFieldModel | null;
  value: CustomFieldModel[];
  customFieldOption: CustomFieldOption | null;
  customFieldOptions: CustomFieldOption[];
  fetchingCustomFields: boolean;
  searchingCustomFields: boolean;
  savingCustomField: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  totalCount: number;
}

interface CreateCustomFieldProps {
  customField: CustomFieldModel;
}

interface UpdateCustomFieldProps {
  customFieldId: number;
  customField: CustomFieldModel;
}
interface BulkCreateCustomFieldProps {
  customFieldKey: string;
  bulkCustomFieldOptions: BulkCreateCustomFieldOptions[];
}

interface BulkCreateCustomFieldProps {
  customFieldKey: string;
  bulkCustomFieldOptions: BulkCreateCustomFieldOptions[];
}

interface FetchCustomFieldOptionsByKeyProps {
  customFieldKey: string;
  params?: CustomFieldOptionsParams;
}

const initialState: CustomFieldState = {
  customField: null,
  value: [],
  customFieldOption: null,
  customFieldOptions: [],
  fetchingCustomFields: false,
  searchingCustomFields: false,
  savingCustomField: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
};

const customFieldsParams = {
  params: {
    _limit: 20,
    _order_by: 'updated_at:desc',
    _offset: 0,
  },
};

export const createCustomField = createAsyncThunk(
  'customFields/createCustomField',
  async (
    { customField }: CreateCustomFieldProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await CustomFieldService().createCustomField(customField);

      await dispatch(fetchCustomFields(customFieldsParams));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkCreateCustomFieldOptions = createAsyncThunk(
  'customFields/bulkCreateCustomFieldOptions',
  async (
    { customFieldKey, bulkCustomFieldOptions }: BulkCreateCustomFieldProps,
    { rejectWithValue }
  ) => {
    try {
      const resp = await CustomFieldService().bulkCreateCustomFieldOptions(
        customFieldKey,
        bulkCustomFieldOptions
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomField = createAsyncThunk(
  'customFields/deleteCustomField',
  async (customFieldId: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await CustomFieldService().deleteCustomField(customFieldId);

      await dispatch(fetchCustomFields(customFieldsParams));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomField = createAsyncThunk(
  'customFields/updateCustomField',
  async (
    { customFieldId, customField }: UpdateCustomFieldProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await CustomFieldService().updateCustomField(
        customFieldId,
        customField
      );

      dispatch(fetchCustomFields(customFieldsParams));
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomField = createAsyncThunk(
  'customFields/fetchCustomField',
  async (customFieldKey: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await CustomFieldService().getCustomField(customFieldKey);

      await dispatch(fetchCustomFields(customFieldsParams));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomFieldOption = createAsyncThunk(
  'customFields/fetchCustomFieldOption',
  async (
    { customFieldKey, optionId }: { customFieldKey: string; optionId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await CustomFieldService().getCustomFieldOption(
        customFieldKey,
        optionId
      );

      await dispatch(fetchCustomFieldOptionsByKey({ customFieldKey }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomFieldOptionsByKey = createAsyncThunk(
  'customFields/fetchCustomFieldOptionsByKey',
  async (
    { customFieldKey, params }: FetchCustomFieldOptionsByKeyProps,
    { rejectWithValue }
  ) => {
    try {
      const query = convertParamsToQuery(params);

      const res = await CustomFieldService().listCustomFieldOptionsByKey(
        customFieldKey,
        query
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomFields = createAsyncThunk(
  'customFields/fetchCustomFields',
  async (
    {
      params,
      searching = false,
    }: {
      params: ListCustomFieldsParams;
      searching?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await CustomFieldService().listCustomFields(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchCustomFieldsByName = createAsyncThunk(
  'customFields/searchCustomFieldsByName',
  async (query: string, { rejectWithValue }) => {
    try {
      let params: ListCustomFieldsParams = {
        _limit: 20,
        _order_by: 'updated_at:desc',
        _offset: 0,
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await CustomFieldService().listCustomFields(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateCustomFields = createAsyncThunk(
  'customFields/paginateCustomFields',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      const nextParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await CustomFieldService().listCustomFields(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // CREATE CUSTOM FIELD
      .addCase(createCustomField.pending, (state) => {
        state.savingCustomField = true;
      })
      .addCase(createCustomField.fulfilled, (state) => {
        state.savingCustomField = false;
      })
      .addCase(createCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomField = false;
      })

      // DELETE CUSTOM FIELD
      .addCase(deleteCustomField.pending, (state) => {
        state.savingCustomField = true;
      })
      .addCase(deleteCustomField.fulfilled, (state) => {
        state.savingCustomField = false;
      })
      .addCase(deleteCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomField = false;
      })

      // UPDATE CUSTOM FIELD
      .addCase(updateCustomField.pending, (state) => {
        state.savingCustomField = true;
      })
      .addCase(updateCustomField.fulfilled, (state) => {
        state.savingCustomField = false;
      })
      .addCase(updateCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingCustomField = false;
      })

      // FETCH CUSTOM FIELD
      .addCase(fetchCustomField.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(fetchCustomField.fulfilled, (state, action) => {
        state.fetchingCustomFields = false;
        state.customField = action.payload;
      })
      .addCase(fetchCustomField.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFields = false;
      })

      // FETCH CUSTOM FIELDS
      .addCase(fetchCustomFields.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        state.fetchingCustomFields = false;
        state.totalCount = action.payload.pagination.count;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchCustomFields.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFields = false;
      })

      // FETCH CUSTOM FIELD OPTION
      .addCase(fetchCustomFieldOption.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(fetchCustomFieldOption.fulfilled, (state, action) => {
        state.fetchingCustomFields = false;
        state.customFieldOption = action.payload;
      })
      .addCase(fetchCustomFieldOption.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFields = false;
      })

      // FETCH CUSTOM FIELD OPTIONS BY KEY
      .addCase(fetchCustomFieldOptionsByKey.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(fetchCustomFieldOptionsByKey.fulfilled, (state, action) => {
        state.fetchingCustomFields = false;
        state.customFieldOptions = action.payload;
      })
      .addCase(fetchCustomFieldOptionsByKey.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.fetchingCustomFields = false;
      })

      // SEARCH CUSTOM FIELDS BY NAME
      .addCase(searchCustomFieldsByName.pending, (state) => {
        state.searchingCustomFields = true;
      })
      .addCase(searchCustomFieldsByName.fulfilled, (state, action) => {
        state.searchingCustomFields = false;
        state.totalCount = action.payload.pagination.count;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchCustomFieldsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.searchingCustomFields = false;
      })

      // PAGINATE CUSTOM FIELDS
      .addCase(paginateCustomFields.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(paginateCustomFields.fulfilled, (state, action: any) => {
        state.fetchingCustomFields = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
        }
      })
      .addCase(paginateCustomFields.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.fetchingCustomFields = false;
      })

      // BULK CREATE CUSTOM FIELD OPTIONS
      .addCase(bulkCreateCustomFieldOptions.pending, (state) => {
        state.fetchingCustomFields = true;
      })
      .addCase(bulkCreateCustomFieldOptions.fulfilled, (state, action: any) => {
        state.fetchingCustomFields = false;
        if (action.payload) {
          state.customFieldOptions = [...action.payload];
        }
      })
      .addCase(bulkCreateCustomFieldOptions.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingCustomFields = false;
      });
  },
});

export default customFieldsSlice.reducer;
