import { FC } from 'react';
import './CounterProperties.less';

import { Select, Space } from 'antd';

import { staticFixtureByIdMap } from '../../../../../../figures';
import { Draw2DFigureModel } from '../../../../../BuilderLayoutEditor/types';

const { Option } = Select;

interface CounterPropertiesProps {
  counter: Draw2DFigureModel;
  expanded: boolean;
  onUpdateCounter: (counterId: keyof typeof staticFixtureByIdMap) => void;
}

const CounterProperties: FC<CounterPropertiesProps> = (props) => {
  const { counter, onUpdateCounter, expanded } = props;
  const isLCounterFixture =
    counter.userData.fixtureId === 104 || counter.userData.fixtureId === 105;
  const isNoSelfCheckCounterFixture =
    counter.userData.fixtureId === 999106 ||
    counter.userData.fixtureId === 999107 ||
    counter.userData.fixtureId === 999108;
  const fixtureImageWrapperStyle = {
    marginTop: '2.5rem',
    width: '351px',
    maxWidth: '351px',
  };

  const item =
    staticFixtureByIdMap[
      counter.userData.fixtureId as keyof typeof staticFixtureByIdMap
    ];

  return (
    <div className="fixture-properties" id="fixtureProperties">
      <Space direction="vertical" style={{ width: '100%' }}>
        <div className="fixture-property-name">Counter</div>
        {isLCounterFixture ? (
          <Select
            getPopupContainer={() =>
              document.getElementById('fixtureProperties')!
            }
            size="small"
            value={
              counter.userData.fixtureId as keyof typeof staticFixtureByIdMap
            }
            onChange={(value: keyof typeof staticFixtureByIdMap) =>
              onUpdateCounter(value)
            }
            style={{ width: '100%' }}
          >
            <Option key={1} value={104}>
              L Counter Default
            </Option>
            <Option key={2} value={105}>
              L Counter Preset 3
            </Option>
          </Select>
        ) : (
          <Select
            getPopupContainer={() =>
              document.getElementById('fixtureProperties')!
            }
            size="small"
            value={
              counter.userData.fixtureId as keyof typeof staticFixtureByIdMap
            }
            onChange={(value: keyof typeof staticFixtureByIdMap) =>
              onUpdateCounter(value)
            }
            style={{ width: '100%' }}
          >
            {isNoSelfCheckCounterFixture ? (
              <>
                <Option key={1} value={999106}>
                  Counter Default
                </Option>
                <Option key={2} value={999107}>
                  Counter Preset 1
                </Option>
                <Option key={3} value={999108}>
                  Counter Preset 2
                </Option>
              </>
            ) : (
              <>
                <Option key={1} value={103}>
                  Counter Default
                </Option>
                <Option key={2} value={101}>
                  Counter Preset 1
                </Option>
                <Option key={3} value={102}>
                  Counter Preset 2
                </Option>
              </>
            )}
          </Select>
        )}
        <div className="fixture-item-detail-wrapper">
          <div className="fixture-item-detail-property">Name</div>
          <div className="fixture-item-detail-value">{item.name}</div>
        </div>
        <div className="fixture-item-detail-wrapper">
          <div className="fixture-item-detail-property">Size</div>
          <div className="fixture-item-detail-value">
            {item.w.toFixed(3)}" W x {item.h}" H x {item.d}" D
          </div>
        </div>
        <div
          className="fixture-display-image-wrapper"
          style={fixtureImageWrapperStyle}
          data-cy="fixture-image"
        >
          <img
            className={`fixture-display-image ${expanded ? 'lg' : ''}`}
            src={item.renders.front}
            alt={item.name}
          />
        </div>
      </Space>
    </div>
  );
};

export default CounterProperties;
