export enum DrawerHashRoute {
  BuildForm = '#build-form',
  OVRForm = '#ovr-form',
  UserForm = '#user-form',
  CustomFieldForm = '#custom-field-form',
  SystemConfigurationForm = '#system-configuration',
  CustomFileForm = '#custom-file-form',
  RSGFileForm = '#rsg-file-form',
  RoleForm = '#role-form',
  BulkEditProductsForm = '#bulk-edit-products-form',
  CategoryForm = '#category-form',
  TagForm = '#tag-form',
  FormatForm = '#format-form',
  MarketForm = '#market-form',
  BrandForm = '#brand-form',
  ManufacturerForm = '#manufacturer-form',
  ParentCompanyForm = '#parent-company-form',
  CountUnitForm = '#count-unit-form',
  SizeUnitForm = '#size-unit-form',
  ClientForm = '#client-form',
  LicenseForm = '#license-form',
  EmailNotificationForm = '#email-notification-form',
}
