import { useMemo } from 'react';

import RecentOVRProjectsList from './components/RecentOVRProjectsList';
import RecentStoreBuildsList from './components/RecentStoreBuildsList';
import { getMePermissions, UserPermissions } from '../../../util';
import ViewWrapper from '../../elements/ViewWrapper';

const DashboardView = () => {
  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  return (
    <ViewWrapper headerTitle="Dashboard">
      {currentUserPermissions.includes(UserPermissions.FebDesignsView) ? (
        <RecentStoreBuildsList />
      ) : null}
      {currentUserPermissions.includes(UserPermissions.OvrProjectsView) ? (
        <RecentOVRProjectsList />
      ) : null}
    </ViewWrapper>
  );
};

export default DashboardView;
