import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Progress, Typography } from 'antd';

import { CronJobModel } from '../../../models/cron-job';
import { convertDate } from '../../../util';
import Button from '../../elements/Button';
import Spinner from '../../elements/Spinner/Spinner';

import './DownloadItem.less';

interface DownloadItemRendererProps {
  cronJob: CronJobModel;
  handleAlertClose?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleDeleteClick: () => void;
  handleCancelClick: () => void;
  handleDownloadClick: () => void;
}

const { Text } = Typography;

const DownloadItemRenderer = ({
  cronJob,
  handleAlertClose,
  handleDeleteClick,
  handleCancelClick,
  handleDownloadClick,
}: DownloadItemRendererProps) => {
  const { error, status, file_type, progress, created_at, result } = cronJob;
  const { data_status, total_count, processed_count } = result!;
  const missing_downloads_count = total_count - processed_count;

  let fileNameDisplay = '';

  if (file_type === 'front_png') {
    fileNameDisplay = 'Front PNG';
  } else if (file_type === 'png') {
    fileNameDisplay = 'All PNG';
  } else if (file_type === 'obj') {
    fileNameDisplay = 'RSG';
  } else {
    fileNameDisplay = file_type?.toUpperCase();
  }

  const statusUpperCase = status?.charAt(0).toUpperCase() + status?.slice(1);

  switch (true) {
    case !!error:
      return (
        <div>
          <Alert
            style={{ margin: '1rem' }}
            message={`${fileNameDisplay} Download`}
            description={error?.message}
            type="error"
            closable
            onClose={handleAlertClose}
          />
        </div>
      );
    case status === 'processing':
      return (
        <div className="download-item">
          <div className="download-item-section-top">
            <Text className="status-text">
              {status ? `${fileNameDisplay} ${statusUpperCase}...` : null}
            </Text>
            <Text className="date-text">
              {created_at ? convertDate(created_at) : null}
            </Text>
          </div>
          <div className="download-item-section-bottom">
            <div className="download-item-section-bottom-left">
              <div className="progress-bar">
                {progress ? (
                  <Progress
                    percent={progress ? progress : 0}
                    showInfo={false}
                    strokeColor="#8E24AA"
                    trailColor="#C4C4C4"
                  />
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="cancel-percentage-wrapper">
                {progress === 100 ? (
                  <Text underline onClick={handleDeleteClick}>
                    Delete
                  </Text>
                ) : (
                  <Text underline onClick={handleCancelClick}>
                    Cancel
                  </Text>
                )}
                <Text strong>{progress ? `${progress}%` : null}</Text>
              </div>
            </div>
            <div className="download-item-section-bottom-right">
              <Button
                type="primary"
                disabled={progress !== 100}
                icon={<DownloadOutlined />}
                size="small"
                onClick={handleDownloadClick}
              />
            </div>
          </div>
        </div>
      );

    case data_status === 'no_data':
      return (
        <div className="download-item">
          <Alert
            style={{ fontSize: '14px !important' }}
            message={
              <div
                style={{ fontSize: '14px' }}
              >{`${fileNameDisplay} Download`}</div>
            }
            description="No data to download"
            type="error"
            closable
            onClose={handleDeleteClick}
          />
        </div>
      );

    case data_status === 'missing_files':
      return (
        <div className="download-item">
          <Alert
            style={{ marginBottom: '1rem', fontSize: '14px !important' }}
            message={<div style={{ fontSize: '14px' }}>Missing Files</div>}
            description={`${missing_downloads_count} items do not have the associated files and will not be a part of the download`}
            type="warning"
            closable
            onClose={handleAlertClose}
          />
          <div className="download-item-section-top">
            <Text className="status-text">
              {status ? `${fileNameDisplay} ${statusUpperCase}...` : null}
            </Text>
            <Text className="date-text">
              {created_at ? convertDate(created_at) : null}
            </Text>
          </div>
          <div className="download-item-section-bottom">
            <div className="download-item-section-bottom-left">
              <div className="progress-bar">
                {progress ? (
                  <Progress
                    percent={progress ? progress : 0}
                    showInfo={false}
                    strokeColor="#8E24AA"
                    trailColor="#C4C4C4"
                  />
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="cancel-percentage-wrapper">
                {progress === 100 ? (
                  <Text underline onClick={handleDeleteClick}>
                    Delete
                  </Text>
                ) : (
                  <Text underline onClick={handleCancelClick}>
                    Cancel
                  </Text>
                )}
                <Text strong>{progress ? `${progress}%` : null}</Text>
              </div>
            </div>
            <div className="download-item-section-bottom-right">
              <Button
                type="primary"
                disabled={progress !== 100}
                icon={<DownloadOutlined />}
                size="small"
                onClick={handleDownloadClick}
              />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="download-item">
          <div className="download-item-section-top">
            <Text className="status-text">
              {status ? `${fileNameDisplay} ${statusUpperCase}...` : null}
            </Text>
            <Text className="date-text">
              {created_at ? convertDate(created_at) : null}
            </Text>
          </div>
          <div className="download-item-section-bottom">
            <div className="download-item-section-bottom-left">
              <div className="progress-bar">
                {progress ? (
                  <Progress
                    percent={progress ? progress : 0}
                    showInfo={false}
                    strokeColor="#8E24AA"
                    trailColor="#C4C4C4"
                  />
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="cancel-percentage-wrapper">
                {progress === 100 ? (
                  <Text underline onClick={handleDeleteClick}>
                    Delete
                  </Text>
                ) : (
                  <Text underline onClick={handleCancelClick}>
                    Cancel
                  </Text>
                )}
                <Text strong>{progress ? `${progress}%` : null}</Text>
              </div>
            </div>
            <div className="download-item-section-bottom-right">
              <Button
                type="primary"
                disabled={progress !== 100}
                icon={<DownloadOutlined />}
                size="small"
                onClick={handleDownloadClick}
              />
            </div>
          </div>
        </div>
      );
  }
};

export default DownloadItemRenderer;
