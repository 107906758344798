export enum CustomEvents {
  OVRProjectUpdated = 'ovr-project-updated',
  OVRProjectDeleted = 'ovr-project-deleted',
  StoreBuildCreated = 'store-build-created',
  StoreBuildUpdated = 'store-build-updated',
  StoreBuildDeleted = 'store-build-deleted',
  UserCreated = 'user-created',
  UserUpdated = 'user-updated',
  UserDeleted = 'user-deleted',
  OvrFileCreated = 'ovr-file-created',
  OvrFileUpdated = 'ovr-file-updated',
  OvrFileDeleted = 'ovr-file-deleted',
  CustomFieldCreated = 'custom-field-created',
  CustomFieldUpdated = 'custom-field-updated',
  CustomFieldDeleted = 'custom-field-deleted',
  SystemConfigurationCreated = 'system-configuration-created',
  SystemConfigurationUpdated = 'system-configuration-updated',
  SystemConfigurationDeleted = 'system-configuration-deleted',
  RSGFileCreated = 'rsg-file-created',
  RSGFileUpdated = 'rsg-file-updated',
  RSGFileSystemConfigurationDeleted = 'rsg-file-deleted',
  AutomationQueueFileCreated = 'automation-queue-file-created',
  AutomationQueueFileUpdated = 'automation-queue-file-updated',
}
