import { CSSProperties, FC, ReactNode, useState } from 'react';
import { useRef } from 'react';
import './Tooltip.less';

interface TooltipProps {
  children: ReactNode;
  text: string;
  borderColor?: string;
  color?: string;
  offsetX?: number;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { children, text, offsetX } = props;

  const [placement, setPlacement] = useState<CSSProperties>({
    top: 0,
    left: 0,
    visibility: 'hidden',
  });
  const tooltipWrapperEl = useRef<HTMLDivElement>(null);
  const tooltipEl = useRef<HTMLDivElement>(null);

  const handleShowTooltip = (show: boolean) => {
    const tooltipWidth = tooltipEl?.current!.getBoundingClientRect().width;
    const tooltipHeight = tooltipEl?.current!.getBoundingClientRect().height;

    const top =
      tooltipWrapperEl?.current!.getBoundingClientRect().top -
      tooltipHeight -
      46;
    const left =
      tooltipWrapperEl?.current!.getBoundingClientRect().left -
      tooltipWidth +
      offsetX!;

    setPlacement({ top, left, visibility: show ? 'visible' : 'hidden' });
  };

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={() => handleShowTooltip(true)}
      onMouseLeave={() => handleShowTooltip(false)}
      ref={tooltipWrapperEl}
    >
      <div className="tooltip" style={{ ...placement }} ref={tooltipEl}>
        <div className="tooltip-text">{text}</div>
      </div>
      {children}
    </div>
  );
};

Tooltip.defaultProps = {
  offsetX: 0,
};

export default Tooltip;
