export interface LicenseModel {
  name: string;
  uuid: string;
  client_id: string;
  start_date: string;
  end_date: string;
  type: string;
  status: string;
  quantity: number;
  quantity_used: number;
  quantity_available: number;
  data: object;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  expired: boolean;
  is_active: boolean;
}

export const initialNewLicenseModel = {
  name: '',
  email: '',
  password: '',
};
