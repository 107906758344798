export interface SystemConfigurationModel {
  id: number;
  name: string;
  value_int: number;
  value_string: string;
  type: string;
  created_at: string;
  updated_at: string;
  scope_id: number;
  scope: SystemConfigurationScope;
  scope_string: string;
}

export enum SystemConfigurationScope {
  'Default' = 0,
  'System' = 1,
  'User' = 2,
}

export const initialNewSystemConfigurationModel = {
  name: '',
  value_string: '',
  scope: SystemConfigurationScope.Default,
};
