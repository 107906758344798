import { memo, useCallback, useEffect, useState } from 'react';

import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Spin } from 'antd';

import { propsAreEqual } from '../../../util';
import './MultiLevelSelect.less';

export interface SelectDataItem {
  key: string;
  parent_id: string | boolean | number;
  name: string;
  count: number;
  level: number;
  children?: SelectDataItem[];
}

interface MultiLevelSelectProps {
  selected?: string;
  data: SelectDataItem[];
  loading: boolean;
  onChange: (selected: SelectDataItem | 'All' | undefined) => void;
}

const MultiLevelSelect = ({
  selected,
  data,
  loading,
  onChange,
}: MultiLevelSelectProps) => {
  const [selectedItem, setSelectedItem] = useState<
    SelectDataItem | 'All' | undefined
  >({
    key: 'string',
    parent_id: 'string | boolean | number',
    name: 'chicken',
    count: 1,
    level: 1,
  });
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [currentMenuItems, setCurrentMenuItems] = useState<SelectDataItem[]>(
    []
  );
  const [parentItem, setParentItem] = useState<SelectDataItem>();

  useEffect(() => {
    setCurrentMenuItems(data);
  }, [data]);

  const getFlatItemList = useCallback((data: SelectDataItem[]) => {
    let list: SelectDataItem[] = [];
    for (const item of data) {
      list.push(item);

      if (item.children) {
        const childrenList = getFlatItemList(item.children);
        list = [...list, ...childrenList];
      }
    }
    return list;
  }, []);

  useEffect(() => {
    const list = getFlatItemList(data);
    const currentMenu = list.find((item) => item.key === selected);
    setSelectedItem(currentMenu);
    // onChange(currentMenu);
  }, [data, getFlatItemList, selected]);

  const handleMenuClick = (e: any) => {
    const currentMenu = currentMenuItems.find((item) => item.key === e.key);

    if (currentMenu?.children) {
      setCurrentMenuItems(currentMenu.children);
      setParentItem(currentMenu);

      if (selectedItem) {
        setSelectedItem(undefined);
        onChange(undefined);
      }
    } else {
      setSelectedItem(currentMenu);
      onChange(currentMenu);
      setMenuVisible(false);
    }
  };

  const handleViewAllBtnClick = () => {
    if (parentItem) {
      setSelectedItem(parentItem);
      onChange(parentItem);
    } else {
      setSelectedItem('All');
      onChange('All');
    }

    setMenuVisible(false);
  };

  const handleBackBtnClick = () => {
    if (parentItem?.parent_id === 0) {
      setCurrentMenuItems(data);
      setParentItem(undefined);
    } else {
      const list: SelectDataItem[] = [];
      const getFlatItemList = (data: SelectDataItem[]) => {
        for (const item of data) {
          list.push(item);

          if (item.children) {
            getFlatItemList(item.children);
          }
        }
      };

      getFlatItemList(data);
      const parent = list.find((item) => parentItem?.parent_id === item.key);
      setCurrentMenuItems(parent?.children as SelectDataItem[]);
      setParentItem(parent);
    }
  };

  const getParent = (itemKey: string): SelectDataItem | undefined => {
    const list = getFlatItemList(data);
    return list.find((item) => item.key === itemKey);
  };

  return (
    <Dropdown
      overlay={
        <Menu onClick={handleMenuClick} className="categories-filter-menu">
          {loading ? (
            <div className="spinner-wrapper">
              <Spin tip="Loading categories..." />
            </div>
          ) : (
            <>
              {parentItem ? (
                <div className="dropdown-back" onClick={handleBackBtnClick}>
                  <LeftOutlined />
                  {parentItem.parent_id === 0
                    ? 'All'
                    : getParent(parentItem.parent_id as string)?.name}
                </div>
              ) : null}
              <Menu.Item key="all-btn" disabled>
                <div className="dropdown-select-all">
                  <span style={{ fontWeight: 'bold' }}>
                    {!parentItem ? 'All' : parentItem.name}
                  </span>
                  <Button
                    type="primary"
                    className="dropdown-select-all-btn"
                    onClick={handleViewAllBtnClick}
                  >
                    View All
                  </Button>
                </div>
              </Menu.Item>
              {currentMenuItems.map((item) => (
                <Menu.Item key={item.key}>
                  <Space align="center" size="middle">
                    <span style={{ marginLeft: parentItem ? '1rem' : 0 }}>
                      {item.name}
                    </span>
                  </Space>
                </Menu.Item>
              ))}
            </>
          )}
        </Menu>
      }
      visible={menuVisible}
      trigger={['click']}
      className="dropdown-menu"
    >
      <Button
        className="dropdown-button"
        onClick={() => setMenuVisible(!menuVisible)}
      >
        <>
          Categories
          {selectedItem ? (
            <>
              {' '}
              -{' '}
              <span style={{ fontWeight: 'bold' }}>
                {typeof selectedItem === 'string'
                  ? selectedItem
                  : selectedItem.name}
              </span>
            </>
          ) : null}
        </>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default memo(MultiLevelSelect, propsAreEqual);
