import { PermissionModel } from '../models/permission';
import { RoleModel } from '../models/role';
import { UserModel } from '../models/user';

export enum UserRoles {
  admin = 'admin',
  client = 'client',
  employee = 'employee',
  employeeDeveloper = 'employeeDeveloper',
  employeeModeler = 'employeeModeler',
  employeeClientServices = 'employeeClientServices',
  employeeLibrarian = 'employeeLibrarian',
}

export enum UserPermissions {
  ClientsView = 'Clients:View',
  ClientsCreate = 'Clients:Create',
  ClientsEdit = 'Clients:Edit',
  ClientsDelete = 'Clients:Delete',
  FebDesignsView = 'FebDesigns:View',
  FebDesignsCreate = 'FebDesigns:Create',
  FebDesignsEdit = 'FebDesigns:Edit',
  FebDesignsDelete = 'FebDesigns:Delete',
  OvrProjectsView = 'OvrProjects:View',
  OvrProjectsCreate = 'OvrProjects:Create',
  OvrProjectsTest = 'OvrProjects:Test',
  OvrProjectsEdit = 'OvrProjects:Edit',
  OvrProjectsDelete = 'OvrProjects:Delete',
  OvrProjectsDataExport = 'OvrProjects:DataExport',
  UsersView = 'Users:View',
  UsersCreate = 'Users:Create',
  UsersEdit = 'Users:Edit',
  UsersDelete = 'Users:Delete',
  UserSwitchClients = 'User:SwitchClients',
  RolesView = 'Roles:View',
  RolesCreate = 'Roles:Create',
  RolesEdit = 'Roles:Edit',
  RolesDelete = 'Roles:Delete',
  CustomFieldsView = 'CustomFields:View',
  CustomFieldsCreate = 'CustomFields:Create',
  CustomFieldsEdit = 'CustomFields:Edit',
  CustomFieldsDelete = 'CustomFields:Delete',
  SystemConfigurationView = 'SystemConfiguration:View',
  SystemConfigurationCreate = 'SystemConfiguration:Create',
  SystemConfigurationEdit = 'SystemConfiguration:Edit',
  SystemConfigurationDelete = 'SystemConfiguration:Delete',
  ModelView = 'Model:View',
  ModelViewPrivilageMetaData = 'Model:ViewPrivilageMetaData',
  ModelCreate = 'Model:Create',
  ModelUpload = 'Model:Upload',
  ModelEdit = 'Model:Edit',
  ModelViewUnapproved = 'Model:ViewUnapproved ',
  ModelApprove = 'Model:Approve',
  ModelDownloadFrontPng = 'Model:DownloadFrontPng',
  ModelDownloadAllPng = 'Model:DownloadAllPng',
  ModelDownloadCsv = 'Model:DownloadCsv',
  ModelDownloadRsg = 'Model:DownloadRsg',
  ModelDownloadC4d = 'Model:DownloadC4d',
  ModelDelete = 'Model:Delete',
  ModelViewLibraryStatistics = 'Model:ViewLibraryStatistics',
  ModelViewProductModel = 'Model:ViewProductModel',
  PogCheckerView = 'PogChecker:View',
  MetatDataView = 'MetatData:View',
  MetaDataCreate = 'MetaData:Create',
  MetaDataEdit = 'MetaData:Edit',
  MetaDataDelete = 'MetaData:Delete',
  MetaDataApprove = 'MetaData:Approve',
  CustomtDataView = 'CustomtData:View',
  CustomDataCreate = 'CustomData:Create',
  CustomDataEdit = 'CustomData:Edit',
  CustomDataDelete = 'CustomData:Delete',
  CustomDataApprove = 'CustomData:Approve',
  OvrFileManagementView = 'OvrFileManagement:View',
  OvrFileManagementUpload = 'OvrFileManagement:Upload',
  OvrFileManagementEdit = 'OvrFileManagement:Edit',
  OvrFileManagementDelete = 'OvrFileManagement:Delete',
}

export const rolesPermissions = {
  admin: '*',
  client: [
    'FebDesigns:View',
    'FebDesigns:Create',
    'FebDesigns:Edit',
    'FebDesigns:Delete',
    'OvrProjects:View',
    'OvrProjects:Create',
    'OvrProjects:Edit',
    'OvrProjects:Delete',
    'OvrProjects:DataExport',
    'Model:View',
    'Model:DownloadCsv',
    'PogChecker:View',
  ],
  employee: [
    'FebDesigns:View',
    'FebDesigns:Create',
    'FebDesigns:Edit',
    'FebDesigns:Delete',
    'OvrProjects:View',
    'OvrProjects:Create',
    'OvrProjects:Edit',
    'OvrProjects:Delete',
    'OvrProjects:DataExport',
    'Model:View',
    'Model:ViewPrivilagedMetaData',
    'Model:ViewUnapproved',
    'Model:DownloadFrontPng',
    'Model:DownloadAllPng',
    'Model:DownloadCsv',
    'Model:DownloadRsg',
    'Model:DownloadC4d',
    'Model:ViewLibraryStatistics',
    'Model:ViewProductModel',
    'PogChecker:View',
    'MetatData:View',
    'CustomtData:View',
    'OvrFileManagement:View',
    'OvrFileManagement:Upload',
    'OvrFileManagement:Edit',
    'OvrFileManagement:Delete',
  ],
  employeeDeveloper: [
    'Clients:View',
    'Clients:Create',
    'Clients:Edit',
    'Clients:Delete',
    'Users:View',
    'Users:Create',
    'Users:Edit',
    'Users:Delete',
    'User:SwitchClients',
    'Roles:View',
    'CustomFields:View',
    'CustomFields:Create',
    'CustomFields:Edit',
    'CustomFields:Delete',
    'SystemConfiguration:View',
    'SystemConfiguration:Create',
    'SystemConfiguration:Edit',
    'SystemConfiguration:Delete',
  ],
  employeeModeler: ['Model:Create', 'Model:Upload', 'Model:Delete'],
  employeeClientServices: [
    'Clients:View',
    'Clients:Create',
    'Clients:Edit',
    'Clients:Delete',
    'Users:View',
    'Users:Create',
    'Users:Edit',
    'Users:Delete',
    'User:SwitchClients',
    'Roles:View',
    'MetaData:Create',
    'MetaData:Edit',
    'CustomData:Create',
    'CustomData:Edit',
  ],
  employeeLibrarian: [
    'Clients:View',
    'Clients:Create',
    'Clients:Edit',
    'Clients:Delete',
    'Users:View',
    'Users:Create',
    'Users:Edit',
    'Users:Delete',
    'User:SwitchClients',
    'Roles:View',
    'Model:Create',
    'Model:Upload',
    'Model:Edit',
    'Model:Approve',
    'Model:Delete',
    'MetaData:Create',
    'MetaData:Edit',
    'MetaData:Delete',
    'MetaData:Approve',
    'CustomData:Create',
    'CustomData:Edit',
    'CustomData:Delete',
    'CustomData:Approve',
  ],
};

export const getMe = (): UserModel => {
  return JSON.parse(localStorage.getItem('me')!);
};

export const getMeRoles = (): UserRoles[] => {
  const roles = JSON.parse(localStorage.getItem('me')!).roles?.map(
    (p: RoleModel) => {
      switch (p.name) {
        case 'admin':
          return UserRoles.admin;
        case 'client':
          return UserRoles.client;
        case 'employee':
          return UserRoles.employee;
        case 'employeeDeveloper':
          return [UserRoles.employeeDeveloper, UserRoles.employee];
        case 'employeeModeler':
          return [UserRoles.employeeModeler, UserRoles.employee];
        case 'employeeClientServices':
          return [UserRoles.employeeClientServices, UserRoles.employee];
        case 'employeeLibrarian':
          return [UserRoles.employeeLibrarian, UserRoles.employee];
      }
    }
  );
  return roles?.flat() || [];
};

export const getMePermissions = (): string[] => {
  let userPermissions =
    JSON.parse(localStorage.getItem('me')!)?.permissions?.map(
      (p: PermissionModel) => p.name
    ) || [];

  userPermissions = [
    ...userPermissions,
    ...getMeRoles()?.map((role) => {
      if (role === UserRoles?.admin) {
        return Object.values(UserPermissions);
      } else {
        return rolesPermissions[role];
      }
    }),
  ];

  return userPermissions?.flat() || [];
};

export const hasAdminPermission = () => getMeRoles()?.includes(UserRoles.admin);

export const hasDeveloperPermission = () =>
  getMePermissions()?.includes('DEVELOPER');

export const hasLibrarianPermission = () =>
  getMePermissions()?.includes('LIBRARIAN');

export const hasSuperAdminPermission = () => {
  return getMeRoles()?.includes(UserRoles.admin);
};

export const hasUploadPermission = () => getMePermissions()?.includes('UPLOAD');

export const isUserAuthorized = (authorizedRoles: UserRoles[]) => {
  return getMeRoles()?.some((role) => authorizedRoles.includes(role));
};

export const getAuthToken = (): string => {
  return localStorage.getItem('token')!;
};
