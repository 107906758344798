import { createRef, memo, useCallback, useMemo } from 'react';

import { Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { withRouter } from 'react-router-dom';
import './ApiLogTableList.less';

import { ApiLog } from '../../../../../models/api-log';
import { useAppDispatch } from '../../../../../store';
import { paginateOvrProjects } from '../../../../../store/features/ovrProjects/ovrProjectsSlice';
import { propsAreEqual, relativeTimeFromNow } from '../../../../../util';
import LazyLoad from '../../../../elements/LazyLoad';

const { Text } = Typography;

interface ApiLogTableListProps {
  apiLogs: ApiLog[];
  loading: boolean;
  onLoadMore: () => void;
  hasMore: boolean;
  totalCount: number;
}

const ApiLogTableList = ({
  apiLogs,
  loading,
  onLoadMore,
  hasMore,
  totalCount,
}: ApiLogTableListProps) => {
  const tableListWrapperEl = createRef<any>();
  const observerIndexFromLastItem = 3;
  const dispatch = useAppDispatch();

  const columns: ColumnsType<ApiLog> = useMemo(
    () => [
      {
        title: 'Time stamp',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at, _, index) => {
          if (
            hasMore &&
            index + 1 === apiLogs.length - observerIndexFromLastItem
          ) {
            return (
              <>
                <Text style={{ fontWeight: 'bold' }}>
                  {relativeTimeFromNow(created_at)}
                </Text>

                <LazyLoad
                  root={tableListWrapperEl.current!}
                  onIntersection={onLoadMore}
                  triggerOnce
                  threshold={1.0}
                />
              </>
            );
          }
          return (
            <Text style={{ fontWeight: 'bold' }}>
              {relativeTimeFromNow(created_at)}
            </Text>
          );
        },
      },
      {
        title: 'Plain Language Request',
        dataIndex: 'path',
        key: 'path',
        render: (path: string) => (
          <Text ellipsis style={{ maxWidth: '250px' }}>
            {path}
          </Text>
        ),
      },
      {
        title: 'API Call/Session/Request',
        dataIndex: 'query_string',
        key: 'query_string',
        render: (query_string: string) => (
          <Text ellipsis style={{ maxWidth: '400px' }}>
            {query_string || 'N/A'}
          </Text>
        ),
      },
      {
        title: 'IP',
        dataIndex: 'request_headers',
        key: 'request_headers',
        render: (request_headers: string) => {
          const headers = JSON.parse(request_headers);
          const ip = headers['cf-connecting-ip'];
          return (
            <Text ellipsis style={{ maxWidth: '150px' }}>
              {ip}
            </Text>
          );
        },
      },
    ],
    [hasMore, apiLogs.length, tableListWrapperEl, onLoadMore]
  );

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(paginateOvrProjects({ page, pageSize })),
    [dispatch]
  );

  return (
    <div ref={tableListWrapperEl}>
      <Table
        loading={loading && apiLogs.length === 0}
        locale={{ emptyText: <></> }}
        rowKey={(user) => `${user.uuid}-row-key`}
        className="apiLogs-table-list"
        pagination={{
          defaultPageSize: 20,
          total: totalCount,
          onChange: paginate,
        }}
        columns={columns}
        dataSource={apiLogs}
      />

      {loading && apiLogs.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default withRouter<any, any>(memo(ApiLogTableList, propsAreEqual));
