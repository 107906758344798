export interface DebouncedFunction {
  (...args: any[]): void;
  cancel: () => void;
}

export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;

  const debounced = function (...args: any) {
    const later = () => {
      timeout = null;
      func.apply(null, args);
    };
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  return debounced;
};
