import { ManufacturerModel } from '../manufacturer';

export interface BrandModel {
  key?: string;
  name: string;
  status?: string;
  statusCheckValue?: boolean | undefined;
  in_processing: number;
  in_library: number;
  in_use?: boolean;
  uuid: string;
  manufacturers?: ManufacturerModel[];
  manufacturer_set?: string[] | null;
  manufactuer_delete?: string[] | null;
}

export const initialNewBrandModel = {
  name: '',
};
