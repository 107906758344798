import { FC, memo, useEffect, useRef } from 'react';

import { propsAreEqual } from '../../../util';
import './LazyLoad.less';

interface LazyLoadProps {
  root: Element;
  rootMargin?: string;
  threshold?: number;
  triggerOnce?: boolean;
  onIntersection: () => void;
}

const LazyLoad: FC<LazyLoadProps> = ({
  root,
  rootMargin,
  threshold = 0,
  triggerOnce,
  onIntersection,
}) => {
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        onIntersection();
        if (triggerOnce && intersectionObserver.current) {
          intersectionObserver.current.disconnect();
        }
      }
    };

    if (elementRef.current) {
      intersectionObserver.current = new IntersectionObserver(handleIntersect, {
        root,
        threshold,
        rootMargin,
      });

      intersectionObserver.current.observe(elementRef.current);
    }

    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, rootMargin, threshold, triggerOnce, onIntersection]);

  return <div className="lazy-load" ref={elementRef} />;
};

export default memo(LazyLoad, propsAreEqual);
