import { ClientModel } from '../client';
import { UserModel } from '../user';

export enum OVRProjectStatus {
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
}

export enum DATConfiguration {
  Required = 'Required',
  Optional = 'Optional',
  Disabled = 'Disabled',
}

export type ProductModelType = '3D' | '2D';

export type CellSide = 'Left' | 'Right' | 'Center';
export interface OvrProjectModel {
  uuid: string;
  name: string;
  client_id: number;
  status: OVRProjectStatus;
  created_at: string;
  updated_at: string;
  created_by: number;
  entry_url: string;
  description?: string;
  complete_redirect?: string;
  client: ClientModel;
  createdByUser: UserModel;
  cellGroups: CellGroupModel[];
  dat_config: DATConfiguration;
  product_model_type: ProductModelType;
  i18n_language: string;
  i18n_country: string;
}
export interface ProjectCellModel {
  cell?: CellModel;
  message: string;
  startLocation?: CellSide;
  group_id?: number;
  created_at?: string;
  updated_at?: string;
  uuid?: string;
  deleted_at?: string;
  media_files?: MediaFileModel[];
}

interface MediaMetadataModel {
  _extension: string;
}

export interface MediaFileModel {
  status: string;
  filename: string;
  type: string;
  metadata: MediaMetadataModel;
  created_by: string;
  updated_at: string;
  created_at: string;
  media_version_id: string;
  storage_path: string;
  content_type: string;
  size: number;
  media_url: string;
  id: string;
}

export interface CellGroupModel {
  group_id: number;
  projectCells: ProjectCellModel[];
}
export interface ListOvrProjectModel {
  uuid: string;
  name: string;
  client_id: number;
  status: OVRProjectStatus;
  created_at: string;
  updated_at: string;
  created_by: number;
  description?: string;
  complete_redirect?: string;
  client: ClientModel;
  createdByUser: UserModel;
  dat_config: DATConfiguration;
}

export interface OVRProjectStatsModel {
  totalCompletes: number;
  totalEntries: number;
  totalDropoffs: number;
  averageDuration: number;
  conversionRate: number;
  liveSessions: number;
}
export interface NewOVRProjectModel {
  name: string;
  description: string;
  dat_config: DATConfiguration;
}

export const initalNewOVRProjectModel = {
  name: '',
  description: '',
  dat_config: DATConfiguration.Disabled,
};

export enum CellStatus {
  Available = 'available',
  InProject = 'inproject',
  Active = 'active',
  New = 'new',
  Completed = 'new',
  Locked = 'locked',
}

export interface CellModel {
  uuid?: string;
  created_at?: string;
  updated_at?: string;
  deletedAt?: string;
  description?: string;
  client_id?: string;
  approved?: boolean;
  cell_preview_url?: string | null;
  name: string;
  size: number;
  status: CellStatus;
  in_use: boolean;
  media_files?: MediaFileModel[];
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface Stats {
  totalRespondents: number;
  totalCompletes: number;
  totalCompletesWithPurchase: number;
  totalCompletesWithDAT: number;
}

export interface OVRProjectStatsModel {
  dateRange: DateRange;
  stats: Stats;
}
