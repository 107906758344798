import {
  BrandsListResponse,
  BrandsResponse,
  CreateBrandRequest,
  UpdateBrandRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { del, get, ListApiResponse, patch, post } from '../base';

export default function BrandService() {
  return Object.freeze({
    listBrands(query?: string): Promise<BrandsListResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/brand${query}`
      ) as Promise<ListApiResponse>;
    },
    createBrand(req: CreateBrandRequest): Promise<BrandsResponse> {
      return post(`${BASE_API_URL()}/brand/`, req);
    },
    updateBrand(
      brandId: string,
      req: UpdateBrandRequest
    ): Promise<BrandsResponse> {
      return patch(`${BASE_API_URL()}/brand/${brandId}`, req);
    },
    deleteBrand(brandId: string): Promise<BrandsResponse> {
      return del(`${BASE_API_URL()}/brand/${brandId}`);
    },
  });
}
