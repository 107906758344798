import {
  ProductBrandResponse,
  ProductItemDeleteResponse,
  ProductItemResponse,
  UpdateProductItemRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { del, DeleteApiResponse, get, patch } from '../base';

export default function ProductItemService() {
  return Object.freeze({
    getProductItem(productId: string): Promise<ProductItemResponse> {
      return get(
        `${BASE_API_URL()}/library/${productId}`
      ) as Promise<ProductItemResponse>;
    },
    getBrands(): Promise<ProductBrandResponse> {
      return get(`${BASE_API_URL()}/brand/`) as Promise<ProductBrandResponse>;
    },
    updateProductItem(
      productId: string,
      req: UpdateProductItemRequest
    ): Promise<ProductItemResponse> {
      return patch(
        `${BASE_API_URL()}/library/${productId}`,
        req
      ) as Promise<ProductItemResponse>;
    },
    deleteProductItem(productId: string): Promise<ProductItemDeleteResponse> {
      return del(
        `${BASE_API_URL()}/library/${productId}`
      ) as Promise<DeleteApiResponse>;
    },
  });
}
