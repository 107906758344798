import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DownOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useHistory } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { ManufacturerModel } from '../../../../../models/manufacturer';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  paginateManufacturers,
  searchManufacturersByName,
} from '../../../../../store/features/manufacturers/manufacturerSlice';
import { getMePermissions, UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import NoResultsFound from '../../../../elements/NoResultsFound';
import { RoutePath } from '../../../AppRoot/types';
import './ManufacturersTab.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Text } = Typography;

const ManufacturersTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: manufacturers,
    allManufacturersValue,
    fetchingManufacturers,
    searchingManufacturers,
    count,
    current,
  } = useAppSelector((state) => state.manufacturer);

  const hasNoSearchResultsFound = useMemo(
    () =>
      !fetchingManufacturers &&
      !!searchQuery &&
      allManufacturersValue.length === 0,
    [fetchingManufacturers, searchQuery, allManufacturersValue.length]
  );

  const hasManufacturers = !(
    !fetchingManufacturers && manufacturers?.length === 0
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const columns: ColumnsType<ManufacturerModel> = [
    {
      title: 'Manufacturer',
      dataIndex: 'name',
      width: '35%',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: '# in Processing',
      dataIndex: 'in_processing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_processing - b.in_processing,
    },
    {
      title: '# in Library',
      dataIndex: 'in_library',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_library - b.in_library,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.status && b.status) {
          return a.status > b.status ? 1 : -1;
        } else {
          return 0;
        }
      },
      render: (status: string) => {
        if (status === 'approved') {
          return <Text>{status}</Text>;
        } else {
          return <Text type="danger">Needs Approval</Text>;
        }
      },
    },
    {
      title: 'Actions',
      width: 100,
      render: (manufacturer: ManufacturerModel) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(UserPermissions.ClientsEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.ManufacturerForm,
                    state: { data: manufacturer },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
            <Button
              className="link-button"
              type="link"
              onClick={() =>
                history.push({
                  state: { manufacturer },
                  pathname: RoutePath.ProductLibrary,
                })
              }
            >
              View in Library
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      searchManufacturersByName({
        query: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateManufacturers({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current,
  };

  return (
    <div className="manufacturers-tab-wrapper">
      <div className="search-bar">
        <div className="search-bar-left">
          <Search
            className="search-input"
            allowClear={!searchingManufacturers}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e.target.value)}
            enterButton
            placeholder="Search"
            suffix={searchingManufacturers && <Spin indicator={antSpinIcon} />}
          />
        </div>
        {currentUserPermissions.includes(UserPermissions.ClientsCreate) ? (
          <Link to={DrawerHashRoute.ManufacturerForm}>
            <Button type="primary">Add New Manufacturer</Button>
          </Link>
        ) : null}
      </div>
      {hasManufacturers && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          rowKey={(manufacturer: ManufacturerModel) =>
            `${manufacturer.uuid}-row-key`
          }
          expandable={{
            expandIcon: ({ expandable, expanded, onExpand, record }) =>
              expandable ? (
                <span className="anticon " onClick={(e) => onExpand(record, e)}>
                  {expanded ? (
                    <DownOutlined
                      style={{
                        fontSize: '10px',
                        marginRight: '18px',
                      }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ fontSize: '10px', marginRight: '18px' }}
                    />
                  )}
                </span>
              ) : null,
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>
                <Text>Brand Associations: </Text>
                <div>
                  {record.brands
                    ? record.brands.map((brand) => (
                        <Button className="link-button" type="link">
                          {brand.name}
                        </Button>
                      ))
                    : null}
                </div>
              </p>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
          dataSource={manufacturers}
          pagination={paginationSetting}
          loading={searchingManufacturers}
        />
      )}
    </div>
  );
};

export default ManufacturersTab;
