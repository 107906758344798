import { useState } from 'react';

import './LogInView.less';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import AuthService, {
  AuthenticateRequest,
  TwoFactorAuthRequiredResponse,
} from '../../../api/auth';
import { ApiError, handleError } from '../../../api/base';
import ReadySetLogo from '../../../assets/svgs/readyset-logo.svg';
import Button from '../../elements/Button';
import { RoutePath } from '../AppRoot/types';

const LogInView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentAuthStep, setCurrentAuthStep] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');

  const onSuccess = () => {
    const ENV = window.location.origin;
    window.location.replace(`${ENV}`);
  };

  const onError = (err: ApiError) => {
    handleError(err);
  };

  const onFinally = () => {
    setLoading(false);
  };

  const onSubmit = async (request: AuthenticateRequest) => {
    setLoading(true);
    try {
      const resp = await AuthService().authenticate(request);
      if ((resp as TwoFactorAuthRequiredResponse).data.two_factor_required) {
        setEmail(request.apiUser);
        setCurrentAuthStep(1);
      } else {
        onSuccess();
      }
    } catch (err: any) {
      onError(err);
    } finally {
      onFinally();
    }
  };

  const onSend2FACode = async () => {
    try {
      setLoading(true);
      await AuthService().send2FACode(email);
      setCurrentAuthStep(2);
    } catch (err: any) {
      onError(err);
    } finally {
      onFinally();
    }
  };

  const onVerify2FACode = async () => {
    try {
      setLoading(true);
      await AuthService().verify2FACode({ email, verificationCode });
      onSuccess();
    } catch (err: any) {
      onError(err);
    } finally {
      onFinally();
    }
  };

  const authSteps = [
    {
      content: (
        <>
          <Form
            layout="vertical"
            className="log-in-form"
            onFinish={onSubmit}
            requiredMark={false}
          >
            <Form.Item
              label="Email"
              name="apiUser"
              rules={[{ required: true, message: 'Email is required.' }]}
            >
              <Input
                autoFocus
                data-cy="log-in-email-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="apiPass"
              rules={[{ required: true, message: 'Password is required.' }]}
              className="log-in-password-input"
            >
              <Input.Password
                data-cy="log-in-password-input"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Link
              to={RoutePath.ForgotPassword}
              className="log-in-forgotten-label"
            >
              Forgot password?
            </Link>

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="log-in-form-button"
                data-cy="log-in-btn"
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">Verify your idenity</h3>
          <p style={{ marginTop: 12 }}>
            2-step verification is needed for enhanced security. Email
            verification code to: email@email.com
          </p>
          <Button
            loading={loading}
            type="primary"
            style={{ marginTop: 120 }}
            onClick={onSend2FACode}
          >
            Send me the code
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">
            Check your email, we sent you a verification code
          </h3>
          <p style={{ marginTop: 24 }}>
            Please enter the verification code sent to:
            <br />
            <strong>email@email.com</strong>
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label
              htmlFor="verification-code"
              className="forgot-password-input-label"
            >
              Verification Cdoe
            </label>
            <Input
              autoFocus
              name="verification-code"
              placeholder="xxxxxx"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
          <p>This code will expire in 10 minutes.</p>
          <Button loading={loading} type="primary" onClick={onVerify2FACode}>
            Submit my code
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="log-in-view">
      <Card style={{ width: '450px', marginBottom: '72px' }}>
        <div className="log-in-card-content-wrapper">
          <img
            src={ReadySetLogo}
            alt="ReadySet logo"
            className="readyset-logo"
          />
          {authSteps[currentAuthStep].content}
        </div>
      </Card>
    </div>
  );
};

export default LogInView;
