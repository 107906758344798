import { UserModel } from '../user';
export interface ListStoreBuildModel {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  feb_design_area_id: BuildSpace;
  createdByUser: UserModel;
  image_url: string;
  data: string;
}

export interface StoreBuildModel {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  feb_design_area_id: BuildSpace;
  data: string;
}

export enum BuildSpace {
  LeftWithPharmacy = 1,
  RightWithCustomerService = 2,
  Open = 3,
  OpenWithCustomerService = 4,
  FullSizeGrocery = 5,
  Walmart = 6,
  CStore = 7,
  CStoreLCounter = 8,
  Lowes = 9,
  WalmartFrontEndSpace = 10,
  CStoreNoSelfCheckout = 11,
}

export interface NewBuildModel {
  name: string;
  feb_design_area_id: BuildSpace;
}

export const initalNewBuildModel: NewBuildModel = {
  name: '',
  feb_design_area_id: BuildSpace.LeftWithPharmacy,
};

export interface BuildFixtureItem {
  key: string;
  id: number;
  name: string;
  manufacturer: string;
  som: string;
  w: number;
  d: number;
  h: number;
  blockcolor: string;
  category: string;
  renders: {
    front: string;
  };
}
