import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DownOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Spin, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as FolderOpenIcon } from '../../../../../assets/svgs/folder-open.svg';
import { ReactComponent as FolderIcon } from '../../../../../assets/svgs/folder.svg';
import useDebounce from '../../../../../hooks/useDebounce';
import { CategoryModel } from '../../../../../models/category';
import { ProductStatusEnum } from '../../../../../models/product-library';
import { RootState, useAppDispatch } from '../../../../../store';
import {
  CategoryState,
  fetchSubCategories,
  paginateCategory,
  searchCategoryByName,
} from '../../../../../store/features/category/categorySlice';
import { getMePermissions, UserPermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import { RoutePath } from '../../../AppRoot/types';
import './CategoriesTab.less';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Text } = Typography;

interface CategoriesTabProps {
  hideEdit?: boolean;
  hideAdd?: boolean;
}

const ExpandIcon = ({
  expandable,
  expanded,
  onExpand,
  record,
}: RenderExpandIconProps<CategoryModel>) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleExpand = async (record: CategoryModel) => {
    if (record.children?.length === 0) {
      setLoading(true);
      await dispatch(fetchSubCategories(record.uuid));
      setLoading(false);
    }
  };

  if (expandable) {
    return (
      <span
        className="anticon "
        onClick={(e) => {
          handleExpand(record);
          onExpand(record, e);
        }}
        style={{ cursor: 'pointer' }}
      >
        {loading ? (
          <LoadingOutlined style={{ fontSize: '10px', marginRight: '18px' }} />
        ) : (
          <>
            {expanded ? (
              <DownOutlined
                style={{
                  fontSize: '10px',
                  marginRight: '18px',
                }}
              />
            ) : (
              <RightOutlined
                style={{ fontSize: '10px', marginRight: '18px' }}
              />
            )}
          </>
        )}
        <FolderIcon style={{ marginRight: '18px', marginBottom: '-2px' }} />
      </span>
    );
  }

  return (
    <span className="anticon " onClick={(e) => onExpand(record, e)}>
      <FolderOpenIcon style={{ marginRight: '18px', marginBottom: '-5px' }} />
    </span>
  );
};

const CategoriesTab = ({ hideEdit, hideAdd }: CategoriesTabProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);

  const {
    value: category,
    fetchingCategories,
    count,
    current,
  } = useSelector<RootState, CategoryState>((state) => state.category);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const columns: ColumnsType<CategoryModel> = [
    {
      title: 'Categories',
      dataIndex: 'name',
      key: 'categories',
      width: '35%',
    },
    {
      title: 'Category Level',
      key: '',
      render: (category: CategoryModel) => {
        if (category.children) {
          return 'Parent';
        }
        return 'Child';
      },
    },
    {
      title: '# in Processing',
      dataIndex: 'in_processing',
      key: 'inProcessing',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_processing - b.in_processing,
    },
    {
      title: '# in Library',
      dataIndex: 'in_library',
      key: 'in_library',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.in_library - b.in_library,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.status > b.status ? 1 : -1),
      render: (status: ProductStatusEnum) => {
        if (status === ProductStatusEnum.approved) {
          return <Text>Approved</Text>;
        } else {
          return <Text type="danger">Needs Approval</Text>;
        }
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (category: CategoryModel) => {
        return (
          <Space direction="horizontal" size="middle">
            {!hideEdit &&
            currentUserPermissions.includes(UserPermissions.MetaDataEdit) ? (
              <Button
                className="link-button"
                type="link"
                onClick={() =>
                  history.push({
                    hash: DrawerHashRoute.CategoryForm,
                    state: { data: category },
                  })
                }
              >
                Edit
              </Button>
            ) : null}
            <Button
              className="link-button"
              type="link"
              onClick={() =>
                history.push({
                  state: { category },
                  pathname: RoutePath.ProductLibrary,
                })
              }
            >
              View in Library
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      searchCategoryByName({
        queryStr: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateCategory({
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      marginTop: '0px',
      padding: '2rem 1rem',
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: count,
    current,
  };

  return (
    <div className="categories-tab-wrapper">
      <div className="search-bar">
        <div className="search-bar-left">
          <Search
            className="search-input"
            allowClear={!fetchingCategories}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e.target.value)}
            enterButton
            placeholder="Search"
            suffix={fetchingCategories && <Spin indicator={antSpinIcon} />}
          />
        </div>
        {!hideAdd &&
        currentUserPermissions.includes(UserPermissions.MetaDataCreate) ? (
          <Link to={DrawerHashRoute.CategoryForm}>
            <Button type="primary">Add New Category</Button>
          </Link>
        ) : (
          <div />
        )}
      </div>
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        loading={fetchingCategories}
        expandable={{
          expandIcon: ExpandIcon,
        }}
        columns={columns}
        dataSource={category}
        pagination={paginationSetting}
      />
    </div>
  );
};

export default CategoriesTab;
