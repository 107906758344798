import { ShopperType } from '../components/BuilderLayoutEditor/types';
import { BuildSettings } from '../components/BuilderSidePanel/components/BuilderSettings';

// Default config settings
export const AUTO_SAVE_COUNT = 3; // seconds
export const DEFAULT_SELECTED_SHOPPER = ShopperType.WomanWithBasket;
export const MAX_QUEUE_LINE_SHOPPERS = 12; //per queue line
export const MAX_QUEUE_LINES = 5;
export const DEFAULT_SHOPPER_ELAPSED_TIME = '01:00'; // 'mm:ss'
export const DEFAULT_BUILD_EDITOR_SETTINGS: BuildSettings = {
  displayRenderedImages: false,
  displayGrid: true,
};
export const COPY_PASTED_ITEM_OFFSET = 15;
export const ENV_BG_IMAGE_ID = 'ENV_BG_IMAGE_ID';
export const DEFAULT_COUNTER_FIXTURE_ID = 103;
export const DEFAULT_L_COUNTER_FIXTURE_ID = 104;
export const DEFAULT_NO_SELF_CHECK_COUNTER_FIXTURE_ID = 999106;
