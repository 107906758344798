import { useCallback, useContext, useMemo, useState } from 'react';

import { Space, Typography } from 'antd';
import cx from 'classnames';
import { History } from 'history';

import { ReactComponent as BackArrowIcon } from '../../../../../assets/svgs/back-arrow-icon.svg';
import { StoreBuildModel } from '../../../../../models/store-build';
import { useAppDispatch } from '../../../../../store';
import { clearCurrentStoreBuild } from '../../../../../store/features/storeBuilds/storeBuildsSlice';
import { AppLayoutContext } from '../../../AppRoot/AppRoot';
import { RoutePath } from '../../../AppRoot/types';
import { environmentsByIdMap } from '../../environments';
import BuilderLayoutEditor from '../BuilderLayoutEditor';
import './StoreBuilder.less';

const { Title, Text } = Typography;
interface StoreBuilderProps {
  history: History;
  build: StoreBuildModel;
}

export enum SaveStatus {
  Saved = 'Saved.',
  Saving = 'Saving...',
  Unsaved = 'Unsaved changes.',
}

const StoreBuilder = ({ build, history }: StoreBuilderProps) => {
  const { sidebarCollapsed } = useContext(AppLayoutContext);
  const dispatch = useAppDispatch();

  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Saved);

  const getSpaceName = useMemo((): string => {
    return environmentsByIdMap[build?.feb_design_area_id!]?.name;
  }, [build?.feb_design_area_id]);

  const storeBuilderHeaderClass = cx('store-builder-header', {
    'collapsed-sidebar': sidebarCollapsed,
  });

  const handleSaveStatusChange = useCallback((status: SaveStatus) => {
    setSaveStatus(status);
  }, []);

  return (
    <div>
      <header className={storeBuilderHeaderClass}>
        <div
          className="store-builder-header__section-left"
          data-cy="builder-header"
        >
          <Space direction="horizontal" size="middle">
            <BackArrowIcon
              onClick={() => {
                dispatch(clearCurrentStoreBuild());
                return history.push(RoutePath.StoreBuilder);
              }}
              style={{ fontSize: '18px', marginTop: '6px', cursor: 'pointer' }}
            />
            <Title level={4}>{`${build?.name}`}</Title>
            <div
              style={{ fontWeight: 600, fontSize: '16px' }}
            >{`${getSpaceName}`}</div>
            <Text type="secondary" style={{ fontSize: '15px' }}>
              {saveStatus}
            </Text>
          </Space>
        </div>
        <div className="store-builder-header__section-right"></div>
      </header>

      <BuilderLayoutEditor
        build={build}
        sidebarCollapsed={sidebarCollapsed!}
        onSaveStatusChange={handleSaveStatusChange}
      />
    </div>
  );
};

export default StoreBuilder;
