import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../..';
import { ApiError, handleError } from '../../../../api/base';
import PermissionService from '../../../../api/permission';
import { PermissionModel } from '../../../../models/permission';
import { convertParamsToQuery } from '../../../../util';

export interface PermissionsState {
  value: PermissionModel[];
  fetchingPermissions: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
}

const initialState: PermissionsState = {
  value: [],
  fetchingPermissions: true,
  error: { code: null, message: null },
};

export const fetchPermissions = createAsyncThunk(
  'permissions/fetchPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const params = {
        _limit: 100,
      };
      const queryString = convertParamsToQuery(params);

      const resp = await PermissionService().listAclPermissions(queryString);
      return resp.data || [];
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.fetchingPermissions = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.value = action.payload ?? [];
        state.fetchingPermissions = false;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingPermissions = false;
      });
  },
});

export const getFetchingPermissions = (state: RootState) =>
  state.permissions.fetchingPermissions;
export const getAllPermissions = (state: RootState) => state.permissions.value;

export default permissionsSlice.reducer;
