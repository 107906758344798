/* eslint-disable import/order */
// import Front from '../../../assets/fixtures//front.png';
// import TopdownL from '../../../assets/fixtures//topdown_l.png';
// import TopdownLR90 from '../../../assets/fixtures//topdown_l.R90.png';
// import TopdownLR180 from '../../../assets/fixtures//topdown_l.R180.png';
// import TopdownLR270 from '../../../assets/fixtures//topdown_l.R270.png';
// import TopdownR from '../../../assets/fixtures//topdown_r.png';
// import TopdownRR90 from '../../../assets/fixtures//topdown_r.R90.png';
// import TopdownRR180 from '../../../assets/fixtures//topdown_r.R180.png';
// import TopdownRR270 from '../../../assets/fixtures//topdown_r.R270.png';

// Self Checkout
import NCR6SelfCheckoutFront from '../../../assets/fixtures/SelfCheckNCR6/front.png';
import NCR6SelfCheckoutTopdownL from '../../../assets/fixtures/SelfCheckNCR6/topdown_l.png';
import NCR6SelfCheckoutTopdownLR90 from '../../../assets/fixtures/SelfCheckNCR6/topdown_l.R90.png';
import NCR6SelfCheckoutTopdownLR180 from '../../../assets/fixtures/SelfCheckNCR6/topdown_l.R180.png';
import NCR6SelfCheckoutTopdownLR270 from '../../../assets/fixtures/SelfCheckNCR6/topdown_l.R270.png';
import NCR6SelfCheckoutTopdownR from '../../../assets/fixtures/SelfCheckNCR6/topdown_r.png';
import NCR6SelfCheckoutTopdownRR90 from '../../../assets/fixtures/SelfCheckNCR6/topdown_r.R90.png';
import NCR6SelfCheckoutTopdownRR180 from '../../../assets/fixtures/SelfCheckNCR6/topdown_r.R180.png';
import NCR6SelfCheckoutTopdownRR270 from '../../../assets/fixtures/SelfCheckNCR6/topdown_r.R270.png';

import WMExpressSCORegisterFront from '../../../assets/fixtures/WMExpressSCORegister/front.png';
import WMExpressSCORegisterTopdownL from '../../../assets/fixtures/WMExpressSCORegister/topdown_l.png';
import WMExpressSCORegisterTopdownLR90 from '../../../assets/fixtures/WMExpressSCORegister/topdown_l.R90.png';
import WMExpressSCORegisterTopdownLR180 from '../../../assets/fixtures/WMExpressSCORegister/topdown_l.R180.png';
import WMExpressSCORegisterTopdownLR270 from '../../../assets/fixtures/WMExpressSCORegister/topdown_l.R270.png';
import WMExpressSCORegisterTopdownR from '../../../assets/fixtures/WMExpressSCORegister/topdown_r.png';
import WMExpressSCORegisterTopdownRR90 from '../../../assets/fixtures/WMExpressSCORegister/topdown_r.R90.png';
import WMExpressSCORegisterTopdownRR180 from '../../../assets/fixtures/WMExpressSCORegister/topdown_r.R180.png';
import WMExpressSCORegisterTopdownRR270 from '../../../assets/fixtures/WMExpressSCORegister/topdown_r.R270.png';

import L2NCRR6CSelfCheckoutNoPoleFront from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/front.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownL from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_l.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownLR90 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_l.R90.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownLR180 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_l.R180.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownLR270 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_l.R270.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownR from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_r.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownRR90 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_r.R90.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownRR180 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_r.R180.png';
import L2NCRR6CSelfCheckoutNoPoleTopdownRR270 from '../../../assets/fixtures/L2NCRR6CSelfCheckoutNoPole/topdown_r.R270.png';

import SelfCheckFastLaneNCRR6CFront from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/front.png';
import SelfCheckFastLaneNCRR6CTopdownL from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_l.png';
import SelfCheckFastLaneNCRR6CTopdownLR90 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_l.R90.png';
import SelfCheckFastLaneNCRR6CTopdownLR180 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_l.R180.png';
import SelfCheckFastLaneNCRR6CTopdownLR270 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_l.R270.png';
import SelfCheckFastLaneNCRR6CTopdownR from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_r.png';
import SelfCheckFastLaneNCRR6CTopdownRR90 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_r.R90.png';
import SelfCheckFastLaneNCRR6CTopdownRR180 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_r.R180.png';
import SelfCheckFastLaneNCRR6CTopdownRR270 from '../../../assets/fixtures/SelfCheckFastLaneNCRR6C/topdown_r.R270.png';

import SelfCheckFastLaneL2NCRR6CFront from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/front.png';
import SelfCheckFastLaneL2NCRR6CTopdownL from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_l.png';
import SelfCheckFastLaneL2NCRR6CTopdownLR90 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_l.R90.png';
import SelfCheckFastLaneL2NCRR6CTopdownLR180 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_l.R180.png';
import SelfCheckFastLaneL2NCRR6CTopdownLR270 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_l.R270.png';
import SelfCheckFastLaneL2NCRR6CTopdownR from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_r.png';
import SelfCheckFastLaneL2NCRR6CTopdownRR90 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_r.R90.png';
import SelfCheckFastLaneL2NCRR6CTopdownRR180 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_r.R180.png';
import SelfCheckFastLaneL2NCRR6CTopdownRR270 from '../../../assets/fixtures/SelfCheckFastLaneL2NCRR6C/topdown_r.R270.png';

import SelfCheckToshiba20227Front from '../../../assets/fixtures/SelfCheckToshiba20227/front.png';
import SelfCheckToshiba20227TopdownL from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_l.png';
import SelfCheckToshiba20227TopdownLR90 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_l.R90.png';
import SelfCheckToshiba20227TopdownLR180 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_l.R180.png';
import SelfCheckToshiba20227TopdownLR270 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_l.R270.png';
import SelfCheckToshiba20227TopdownR from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_r.png';
import SelfCheckToshiba20227TopdownRR90 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_r.R90.png';
import SelfCheckToshiba20227TopdownRR180 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_r.R180.png';
import SelfCheckToshiba20227TopdownRR270 from '../../../assets/fixtures/SelfCheckToshiba20227/topdown_r.R270.png';

import SelfCheckToshibaL220228Front from '../../../assets/fixtures/SelfCheckToshibaL220228/front.png';
import SelfCheckToshibaL220228TopdownL from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_l.png';
import SelfCheckToshibaL220228TopdownLR90 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_l.R90.png';
import SelfCheckToshibaL220228TopdownLR180 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_l.R180.png';
import SelfCheckToshibaL220228TopdownLR270 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_l.R270.png';
import SelfCheckToshibaL220228TopdownR from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_r.png';
import SelfCheckToshibaL220228TopdownRR90 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_r.R90.png';
import SelfCheckToshibaL220228TopdownRR180 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_r.R180.png';
import SelfCheckToshibaL220228TopdownRR270 from '../../../assets/fixtures/SelfCheckToshibaL220228/topdown_r.R270.png';

// Gondolas
import Gondola24x60Front from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/front.png';
import Gondola24x60TopdownL from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_l.png';
import Gondola24x60TopdownLR90 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_l.R90.png';
import Gondola24x60TopdownLR180 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_l.R180.png';
import Gondola24x60TopdownLR270 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_l.R270.png';
import Gondola24x60TopdownR from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_r.png';
import Gondola24x60TopdownRR90 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_r.R90.png';
import Gondola24x60TopdownRR180 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_r.R180.png';
import Gondola24x60TopdownRR270 from '../../../assets/fixtures/FrontEndQueueGondola24x60Leading/topdown_r.R270.png';

import Gondola36x60Front from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/front.png';
import Gondola36x60TopdownL from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_l.png';
import Gondola36x60TopdownLR90 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_l.R90.png';
import Gondola36x60TopdownLR180 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_l.R180.png';
import Gondola36x60TopdownLR270 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_l.R270.png';
import Gondola36x60TopdownR from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_r.png';
import Gondola36x60TopdownRR90 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_r.R90.png';
import Gondola36x60TopdownRR180 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_r.R180.png';
import Gondola36x60TopdownRR270 from '../../../assets/fixtures/FrontEndQueueGondola36x60Leading/topdown_r.R270.png';

import Gondola48x60Front from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/front.png';
import Gondola48x60TopdownL from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_l.png';
import Gondola48x60TopdownLR90 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_l.R90.png';
import Gondola48x60TopdownLR180 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_l.R180.png';
import Gondola48x60TopdownLR270 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_l.R270.png';
import Gondola48x60TopdownR from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_r.png';
import Gondola48x60TopdownRR90 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_r.R90.png';
import Gondola48x60TopdownRR180 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_r.R180.png';
import Gondola48x60TopdownRR270 from '../../../assets/fixtures/FrontEndQueueGondola48x60Leading/topdown_r.R270.png';

// Coolers
import Cooler24SingleSwingFront from '../../../assets/fixtures/FrontEndCooler24SingleSwing/front.png';
import Cooler24SingleSwingTopdownL from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_l.png';
import Cooler24SingleSwingTopdownLR90 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_l.R90.png';
import Cooler24SingleSwingTopdownLR180 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_l.R180.png';
import Cooler24SingleSwingTopdownLR270 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_l.R270.png';
import Cooler24SingleSwingTopdownR from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_r.png';
import Cooler24SingleSwingTopdownRR90 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_r.R90.png';
import Cooler24SingleSwingTopdownRR180 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_r.R180.png';
import Cooler24SingleSwingTopdownRR270 from '../../../assets/fixtures/FrontEndCooler24SingleSwing/topdown_r.R270.png';

import Cooler36DoubleSwingFront from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/front.png';
import Cooler36DoubleSwingTopdownL from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_l.png';
import Cooler36DoubleSwingTopdownLR90 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_l.R90.png';
import Cooler36DoubleSwingTopdownLR180 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_l.R180.png';
import Cooler36DoubleSwingTopdownLR270 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_l.R270.png';
import Cooler36DoubleSwingTopdownR from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_r.png';
import Cooler36DoubleSwingTopdownRR90 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_r.R90.png';
import Cooler36DoubleSwingTopdownRR180 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_r.R180.png';
import Cooler36DoubleSwingTopdownRR270 from '../../../assets/fixtures/FrontEndCooler36DoubleSwing/topdown_r.R270.png';

import Cooler48DoubleSlideFront from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/front.png';
import Cooler48DoubleSlideTopdownL from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_l.png';
import Cooler48DoubleSlideTopdownLR90 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_l.R90.png';
import Cooler48DoubleSlideTopdownLR180 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_l.R180.png';
import Cooler48DoubleSlideTopdownLR270 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_l.R270.png';
import Cooler48DoubleSlideTopdownR from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_r.png';
import Cooler48DoubleSlideTopdownRR90 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_r.R90.png';
import Cooler48DoubleSlideTopdownRR180 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_r.R180.png';
import Cooler48DoubleSlideTopdownRR270 from '../../../assets/fixtures/FrontEndCooler48DoubleSlide/topdown_r.R270.png';

import CoolerDoubleDoorTopRackTVM30HCFront from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/front.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_l.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_l.R90.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_l.R180.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_l.R270.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_r.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_r.R90.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_r.R180.png';
import CoolerDoubleDoorTopRackTVM30HCTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM30HC/topdown_r.R270.png';

import CoolerDoubleDoorTopRackTVM36SLFront from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/front.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_l.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_l.R90.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_l.R180.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_l.R270.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_r.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_r.R90.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_r.R180.png';
import CoolerDoubleDoorTopRackTVM36SLTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM36SL/topdown_r.R270.png';

import CoolerDoubleDoorTopRackTVM48SLFront from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/front.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_l.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_l.R90.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_l.R180.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_l.R270.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_r.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_r.R90.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_r.R180.png';
import CoolerDoubleDoorTopRackTVM48SLTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTopRackTVM48SL/topdown_r.R270.png';

import CoolerDoubleDoorTVM30HCFront from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/front.png';
import CoolerDoubleDoorTVM30HCTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_l.png';
import CoolerDoubleDoorTVM30HCTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_l.R90.png';
import CoolerDoubleDoorTVM30HCTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_l.R180.png';
import CoolerDoubleDoorTVM30HCTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_l.R270.png';
import CoolerDoubleDoorTVM30HCTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_r.png';
import CoolerDoubleDoorTVM30HCTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_r.R90.png';
import CoolerDoubleDoorTVM30HCTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_r.R180.png';
import CoolerDoubleDoorTVM30HCTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM30HC/topdown_r.R270.png';

import CoolerDoubleDoorTVM36SLFront from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/front.png';
import CoolerDoubleDoorTVM36SLTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_l.png';
import CoolerDoubleDoorTVM36SLTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_l.R90.png';
import CoolerDoubleDoorTVM36SLTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_l.R180.png';
import CoolerDoubleDoorTVM36SLTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_l.R270.png';
import CoolerDoubleDoorTVM36SLTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_r.png';
import CoolerDoubleDoorTVM36SLTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_r.R90.png';
import CoolerDoubleDoorTVM36SLTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_r.R180.png';
import CoolerDoubleDoorTVM36SLTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM36SL/topdown_r.R270.png';

import CoolerSingleDoorCokeHeaderFront from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/front.png';
import CoolerSingleDoorCokeHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_l.png';
import CoolerSingleDoorCokeHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_l.R90.png';
import CoolerSingleDoorCokeHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_l.R180.png';
import CoolerSingleDoorCokeHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_l.R270.png';
import CoolerSingleDoorCokeHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_r.png';
import CoolerSingleDoorCokeHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_r.R90.png';
import CoolerSingleDoorCokeHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_r.R180.png';
import CoolerSingleDoorCokeHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorCokeHeader/topdown_r.R270.png';

import CoolerSingleDoorCokeWrapFront from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/front.png';
import CoolerSingleDoorCokeWrapTopdownL from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_l.png';
import CoolerSingleDoorCokeWrapTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_l.R90.png';
import CoolerSingleDoorCokeWrapTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_l.R180.png';
import CoolerSingleDoorCokeWrapTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_l.R270.png';
import CoolerSingleDoorCokeWrapTopdownR from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_r.png';
import CoolerSingleDoorCokeWrapTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_r.R90.png';
import CoolerSingleDoorCokeWrapTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_r.R180.png';
import CoolerSingleDoorCokeWrapTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorCokeWrap/topdown_r.R270.png';

import CoolerSingleDoorGDM10SSLFront from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/front.png';
import CoolerSingleDoorGDM10SSLTopdownL from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_l.png';
import CoolerSingleDoorGDM10SSLTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_l.R90.png';
import CoolerSingleDoorGDM10SSLTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_l.R180.png';
import CoolerSingleDoorGDM10SSLTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_l.R270.png';
import CoolerSingleDoorGDM10SSLTopdownR from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_r.png';
import CoolerSingleDoorGDM10SSLTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_r.R90.png';
import CoolerSingleDoorGDM10SSLTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_r.R180.png';
import CoolerSingleDoorGDM10SSLTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorGDM10SSL/topdown_r.R270.png';

import CoolerSingleDoorGDM12HCFront from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/front.png';
import CoolerSingleDoorGDM12HCTopdownL from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_l.png';
import CoolerSingleDoorGDM12HCTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_l.R90.png';
import CoolerSingleDoorGDM12HCTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_l.R180.png';
import CoolerSingleDoorGDM12HCTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_l.R270.png';
import CoolerSingleDoorGDM12HCTopdownR from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_r.png';
import CoolerSingleDoorGDM12HCTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_r.R90.png';
import CoolerSingleDoorGDM12HCTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_r.R180.png';
import CoolerSingleDoorGDM12HCTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorGDM12HC/topdown_r.R270.png';

import CoolerSingleDoorGDM0634EXTFront from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/front.png';
import CoolerSingleDoorGDM0634EXTTopdownL from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_l.png';
import CoolerSingleDoorGDM0634EXTTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_l.R90.png';
import CoolerSingleDoorGDM0634EXTTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_l.R180.png';
import CoolerSingleDoorGDM0634EXTTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_l.R270.png';
import CoolerSingleDoorGDM0634EXTTopdownR from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_r.png';
import CoolerSingleDoorGDM0634EXTTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_r.R90.png';
import CoolerSingleDoorGDM0634EXTTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_r.R180.png';
import CoolerSingleDoorGDM0634EXTTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorGDM0634EXT/topdown_r.R270.png';

import CoolerSingleDoorMonsterHeaderFront from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/front.png';
import CoolerSingleDoorMonsterHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_l.png';
import CoolerSingleDoorMonsterHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_l.R90.png';
import CoolerSingleDoorMonsterHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_l.R180.png';
import CoolerSingleDoorMonsterHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_l.R270.png';
import CoolerSingleDoorMonsterHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_r.png';
import CoolerSingleDoorMonsterHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_r.R90.png';
import CoolerSingleDoorMonsterHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_r.R180.png';
import CoolerSingleDoorMonsterHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorMonsterHeader/topdown_r.R270.png';

import CoolerSingleDoorMonsterWrapFront from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/front.png';
import CoolerSingleDoorMonsterWrapTopdownL from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_l.png';
import CoolerSingleDoorMonsterWrapTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_l.R90.png';
import CoolerSingleDoorMonsterWrapTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_l.R180.png';
import CoolerSingleDoorMonsterWrapTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_l.R270.png';
import CoolerSingleDoorMonsterWrapTopdownR from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_r.png';
import CoolerSingleDoorMonsterWrapTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_r.R90.png';
import CoolerSingleDoorMonsterWrapTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_r.R180.png';
import CoolerSingleDoorMonsterWrapTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorMonsterWrap/topdown_r.R270.png';

import CoolerSingleDoorPepsiHeaderFront from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/front.png';
import CoolerSingleDoorPepsiHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_l.png';
import CoolerSingleDoorPepsiHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_l.R90.png';
import CoolerSingleDoorPepsiHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_l.R180.png';
import CoolerSingleDoorPepsiHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_l.R270.png';
import CoolerSingleDoorPepsiHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_r.png';
import CoolerSingleDoorPepsiHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_r.R90.png';
import CoolerSingleDoorPepsiHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_r.R180.png';
import CoolerSingleDoorPepsiHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorPepsiHeader/topdown_r.R270.png';

import CoolerSingleDoorPepsiWrapFront from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/front.png';
import CoolerSingleDoorPepsiWrapTopdownL from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_l.png';
import CoolerSingleDoorPepsiWrapTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_l.R90.png';
import CoolerSingleDoorPepsiWrapTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_l.R180.png';
import CoolerSingleDoorPepsiWrapTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_l.R270.png';
import CoolerSingleDoorPepsiWrapTopdownR from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_r.png';
import CoolerSingleDoorPepsiWrapTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_r.R90.png';
import CoolerSingleDoorPepsiWrapTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_r.R180.png';
import CoolerSingleDoorPepsiWrapTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorPepsiWrap/topdown_r.R270.png';

import CoolerSingleDoorSaddleGDM10SSLFront from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/front.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownL from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_l.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_l.R90.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_l.R180.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_l.R270.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownR from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_r.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_r.R90.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_r.R180.png';
import CoolerSingleDoorSaddleGDM10SSLTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorSaddleGDM10SSL/topdown_r.R270.png';

import CoolerSingleDoorTopRackCokeHeaderFront from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/front.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_l.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_l.R90.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_l.R180.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_l.R270.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_r.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_r.R90.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_r.R180.png';
import CoolerSingleDoorTopRackCokeHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackCokeHeader/topdown_r.R270.png';

import CoolerSingleDoorTopRackGDM10SSLFront from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/front.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownL from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_l.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_l.R90.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_l.R180.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_l.R270.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownR from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_r.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_r.R90.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_r.R180.png';
import CoolerSingleDoorTopRackGDM10SSLTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM10SSL/topdown_r.R270.png';

import CoolerSingleDoorTopRackGDM12HCFront from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/front.png';
import CoolerSingleDoorTopRackGDM12HCTopdownL from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_l.png';
import CoolerSingleDoorTopRackGDM12HCTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_l.R90.png';
import CoolerSingleDoorTopRackGDM12HCTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_l.R180.png';
import CoolerSingleDoorTopRackGDM12HCTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_l.R270.png';
import CoolerSingleDoorTopRackGDM12HCTopdownR from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_r.png';
import CoolerSingleDoorTopRackGDM12HCTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_r.R90.png';
import CoolerSingleDoorTopRackGDM12HCTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_r.R180.png';
import CoolerSingleDoorTopRackGDM12HCTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackGDM12HC/topdown_r.R270.png';

import CoolerSingleDoorTopRackMonsterHeaderFront from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/front.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_l.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_l.R90.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_l.R180.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_l.R270.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_r.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_r.R90.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_r.R180.png';
import CoolerSingleDoorTopRackMonsterHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackMonsterHeader/topdown_r.R270.png';

import CoolerSingleDoorTopRackPepsiHeaderFront from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/front.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownL from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_l.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_l.R90.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_l.R180.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_l.R270.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownR from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_r.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_r.R90.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_r.R180.png';
import CoolerSingleDoorTopRackPepsiHeaderTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorTopRackPepsiHeader/topdown_r.R270.png';

import CoolerDoubleDoorTVM48SLFront from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/front.png';
import CoolerDoubleDoorTVM48SLTopdownL from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_l.png';
import CoolerDoubleDoorTVM48SLTopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_l.R90.png';
import CoolerDoubleDoorTVM48SLTopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_l.R180.png';
import CoolerDoubleDoorTVM48SLTopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_l.R270.png';
import CoolerDoubleDoorTVM48SLTopdownR from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_r.png';
import CoolerDoubleDoorTVM48SLTopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_r.R90.png';
import CoolerDoubleDoorTVM48SLTopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_r.R180.png';
import CoolerDoubleDoorTVM48SLTopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoorTVM48SL/topdown_r.R270.png';

import CoolerSingleDoorSaddle2GDM10SSLFront from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/front.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownL from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_l.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownLR90 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_l.R90.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownLR180 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_l.R180.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownLR270 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_l.R270.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownR from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_r.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownRR90 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_r.R90.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownRR180 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_r.R180.png';
import CoolerSingleDoorSaddle2GDM10SSLTopdownRR270 from '../../../assets/fixtures/CoolerSingleDoorSaddle2GDM10SSL/topdown_r.R270.png';

// Queue Line Belts
import QueueBelt24Front from '../../../assets/fixtures/FrontEndQueueBelt24Leading/front.png';
import QueueBelt24TopdownL from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_l.png';
import QueueBelt24TopdownLR90 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_l.R90.png';
import QueueBelt24TopdownLR180 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_l.R180.png';
import QueueBelt24TopdownLR270 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_l.R270.png';
import QueueBelt24TopdownR from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_r.png';
import QueueBelt24TopdownRR90 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_r.R90.png';
import QueueBelt24TopdownRR180 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_r.R180.png';
import QueueBelt24TopdownRR270 from '../../../assets/fixtures/FrontEndQueueBelt24Leading/topdown_r.R270.png';

import QueueBelt48Front from '../../../assets/fixtures/FrontEndQueueBelt48Leading/front.png';
import QueueBelt48TopdownL from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_l.png';
import QueueBelt48TopdownLR90 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_l.R90.png';
import QueueBelt48TopdownLR180 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_l.R180.png';
import QueueBelt48TopdownLR270 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_l.R270.png';
import QueueBelt48TopdownR from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_r.png';
import QueueBelt48TopdownRR90 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_r.R90.png';
import QueueBelt48TopdownRR180 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_r.R180.png';
import QueueBelt48TopdownRR270 from '../../../assets/fixtures/FrontEndQueueBelt48Leading/topdown_r.R270.png';

// Queue Line Merchandisers
import QueueSlat24x54Front from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/front.png';
import QueueSlat24x54TopdownL from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_l.png';
import QueueSlat24x54TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_l.R90.png';
import QueueSlat24x54TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_l.R180.png';
import QueueSlat24x54TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_l.R270.png';
import QueueSlat24x54TopdownR from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_r.png';
import QueueSlat24x54TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_r.R90.png';
import QueueSlat24x54TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_r.R180.png';
import QueueSlat24x54TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat24x54Leading/topdown_r.R270.png';

import QueueSlat24x40Front from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/front.png';
import QueueSlat24x40TopdownL from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_l.png';
import QueueSlat24x40TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_l.R90.png';
import QueueSlat24x40TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_l.R180.png';
import QueueSlat24x40TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_l.R270.png';
import QueueSlat24x40TopdownR from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_r.png';
import QueueSlat24x40TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_r.R90.png';
import QueueSlat24x40TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_r.R180.png';
import QueueSlat24x40TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat24x40Leading/topdown_r.R270.png';

import QueueSlat24x66Front from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/front.png';
import QueueSlat24x66TopdownL from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_l.png';
import QueueSlat24x66TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_l.R90.png';
import QueueSlat24x66TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_l.R180.png';
import QueueSlat24x66TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_l.R270.png';
import QueueSlat24x66TopdownR from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_r.png';
import QueueSlat24x66TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_r.R90.png';
import QueueSlat24x66TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_r.R180.png';
import QueueSlat24x66TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat24x66Leading/topdown_r.R270.png';

import QueueSlat36x40Front from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/front.png';
import QueueSlat36x40TopdownL from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_l.png';
import QueueSlat36x40TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_l.R90.png';
import QueueSlat36x40TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_l.R180.png';
import QueueSlat36x40TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_l.R270.png';
import QueueSlat36x40TopdownR from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_r.png';
import QueueSlat36x40TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_r.R90.png';
import QueueSlat36x40TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_r.R180.png';
import QueueSlat36x40TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat36x40Leading/topdown_r.R270.png';

import QueueSlat36x54Front from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/front.png';
import QueueSlat36x54TopdownL from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_l.png';
import QueueSlat36x54TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_l.R90.png';
import QueueSlat36x54TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_l.R180.png';
import QueueSlat36x54TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_l.R270.png';
import QueueSlat36x54TopdownR from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_r.png';
import QueueSlat36x54TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_r.R90.png';
import QueueSlat36x54TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_r.R180.png';
import QueueSlat36x54TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat36x54Leading/topdown_r.R270.png';

import QueueSlat36x66Front from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/front.png';
import QueueSlat36x66TopdownL from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_l.png';
import QueueSlat36x66TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_l.R90.png';
import QueueSlat36x66TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_l.R180.png';
import QueueSlat36x66TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_l.R270.png';
import QueueSlat36x66TopdownR from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_r.png';
import QueueSlat36x66TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_r.R90.png';
import QueueSlat36x66TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_r.R180.png';
import QueueSlat36x66TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat36x66Leading/topdown_r.R270.png';

import QueueSlat48x40Front from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/front.png';
import QueueSlat48x40TopdownL from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_l.png';
import QueueSlat48x40TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_l.R90.png';
import QueueSlat48x40TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_l.R180.png';
import QueueSlat48x40TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_l.R270.png';
import QueueSlat48x40TopdownR from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_r.png';
import QueueSlat48x40TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_r.R90.png';
import QueueSlat48x40TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_r.R180.png';
import QueueSlat48x40TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat48x40Leading/topdown_r.R270.png';

import QueueSlat48x54Front from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/front.png';
import QueueSlat48x54TopdownL from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_l.png';
import QueueSlat48x54TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_l.R90.png';
import QueueSlat48x54TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_l.R180.png';
import QueueSlat48x54TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_l.R270.png';
import QueueSlat48x54TopdownR from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_r.png';
import QueueSlat48x54TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_r.R90.png';
import QueueSlat48x54TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_r.R180.png';
import QueueSlat48x54TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat48x54Leading/topdown_r.R270.png';

import QueueSlat48x66Front from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/front.png';
import QueueSlat48x66TopdownL from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_l.png';
import QueueSlat48x66TopdownLR90 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_l.R90.png';
import QueueSlat48x66TopdownLR180 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_l.R180.png';
import QueueSlat48x66TopdownLR270 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_l.R270.png';
import QueueSlat48x66TopdownR from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_r.png';
import QueueSlat48x66TopdownRR90 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_r.R90.png';
import QueueSlat48x66TopdownRR180 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_r.R180.png';
import QueueSlat48x66TopdownRR270 from '../../../assets/fixtures/FrontEndQueueSlat48x66Leading/topdown_r.R270.png';

// Traditional Belt Checkouts

import FrontEndTraditionalCheckRight48Front from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/front.png';
import FrontEndTraditionalCheckRight48TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_l.png';
import FrontEndTraditionalCheckRight48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_l.R90.png';
import FrontEndTraditionalCheckRight48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_l.R180.png';
import FrontEndTraditionalCheckRight48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_l.R270.png';
import FrontEndTraditionalCheckRight48TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_r.png';
import FrontEndTraditionalCheckRight48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_r.R90.png';
import FrontEndTraditionalCheckRight48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_r.R180.png';
import FrontEndTraditionalCheckRight48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight48/topdown_r.R270.png';

import FrontEndTraditionalCheckLeft48Front from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/front.png';
import FrontEndTraditionalCheckLeft48TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_l.png';
import FrontEndTraditionalCheckLeft48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_l.R90.png';
import FrontEndTraditionalCheckLeft48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_l.R180.png';
import FrontEndTraditionalCheckLeft48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_l.R270.png';
import FrontEndTraditionalCheckLeft48TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_r.png';
import FrontEndTraditionalCheckLeft48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_r.R90.png';
import FrontEndTraditionalCheckLeft48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_r.R180.png';
import FrontEndTraditionalCheckLeft48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft48/topdown_r.R270.png';

import FrontEndTraditionalCheckRight36Front from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/front.png';
import FrontEndTraditionalCheckRight36TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_l.png';
import FrontEndTraditionalCheckRight36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_l.R90.png';
import FrontEndTraditionalCheckRight36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_l.R180.png';
import FrontEndTraditionalCheckRight36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_l.R270.png';
import FrontEndTraditionalCheckRight36TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_r.png';
import FrontEndTraditionalCheckRight36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_r.R90.png';
import FrontEndTraditionalCheckRight36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_r.R180.png';
import FrontEndTraditionalCheckRight36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight36/topdown_r.R270.png';

import FrontEndTraditionalCheckLeft36Front from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/front.png';
import FrontEndTraditionalCheckLeft36TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_l.png';
import FrontEndTraditionalCheckLeft36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_l.R90.png';
import FrontEndTraditionalCheckLeft36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_l.R180.png';
import FrontEndTraditionalCheckLeft36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_l.R270.png';
import FrontEndTraditionalCheckLeft36TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_r.png';
import FrontEndTraditionalCheckLeft36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_r.R90.png';
import FrontEndTraditionalCheckLeft36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_r.R180.png';
import FrontEndTraditionalCheckLeft36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft36/topdown_r.R270.png';

import FrontEndTraditionalCheckRight24Front from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/front.png';
import FrontEndTraditionalCheckRight24TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_l.png';
import FrontEndTraditionalCheckRight24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_l.R90.png';
import FrontEndTraditionalCheckRight24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_l.R180.png';
import FrontEndTraditionalCheckRight24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_l.R270.png';
import FrontEndTraditionalCheckRight24TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_r.png';
import FrontEndTraditionalCheckRight24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_r.R90.png';
import FrontEndTraditionalCheckRight24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_r.R180.png';
import FrontEndTraditionalCheckRight24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckRight24/topdown_r.R270.png';

import FrontEndTraditionalCheckLeft24Front from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/front.png';
import FrontEndTraditionalCheckLeft24TopdownL from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_l.png';
import FrontEndTraditionalCheckLeft24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_l.R90.png';
import FrontEndTraditionalCheckLeft24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_l.R180.png';
import FrontEndTraditionalCheckLeft24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_l.R270.png';
import FrontEndTraditionalCheckLeft24TopdownR from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_r.png';
import FrontEndTraditionalCheckLeft24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_r.R90.png';
import FrontEndTraditionalCheckLeft24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_r.R180.png';
import FrontEndTraditionalCheckLeft24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalCheckLeft24/topdown_r.R270.png';

// Rack

import FrontEndTraditionalRack24Front from '../../../assets/fixtures/FrontEndTraditionalRack24/front.png';
import FrontEndTraditionalRack24TopdownL from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_l.png';
import FrontEndTraditionalRack24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_l.R90.png';
import FrontEndTraditionalRack24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_l.R180.png';
import FrontEndTraditionalRack24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_l.R270.png';
import FrontEndTraditionalRack24TopdownR from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_r.png';
import FrontEndTraditionalRack24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_r.R90.png';
import FrontEndTraditionalRack24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_r.R180.png';
import FrontEndTraditionalRack24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalRack24/topdown_r.R270.png';

import FrontEndTraditionalRack36Front from '../../../assets/fixtures/FrontEndTraditionalRack36/front.png';
import FrontEndTraditionalRack36TopdownL from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_l.png';
import FrontEndTraditionalRack36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_l.R90.png';
import FrontEndTraditionalRack36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_l.R180.png';
import FrontEndTraditionalRack36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_l.R270.png';
import FrontEndTraditionalRack36TopdownR from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_r.png';
import FrontEndTraditionalRack36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_r.R90.png';
import FrontEndTraditionalRack36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_r.R180.png';
import FrontEndTraditionalRack36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalRack36/topdown_r.R270.png';

import FrontEndTraditionalRack48Front from '../../../assets/fixtures/FrontEndTraditionalRack48/front.png';
import FrontEndTraditionalRack48TopdownL from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_l.png';
import FrontEndTraditionalRack48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_l.R90.png';
import FrontEndTraditionalRack48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_l.R180.png';
import FrontEndTraditionalRack48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_l.R270.png';
import FrontEndTraditionalRack48TopdownR from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_r.png';
import FrontEndTraditionalRack48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_r.R90.png';
import FrontEndTraditionalRack48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_r.R180.png';
import FrontEndTraditionalRack48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalRack48/topdown_r.R270.png';

import RackEndPanelWithSides24x60Front from '../../../assets/fixtures/RackEndPanelWithSides24x60/front.png';
import RackEndPanelWithSides24x60TopdownL from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_l.png';
import RackEndPanelWithSides24x60TopdownLR90 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_l.R90.png';
import RackEndPanelWithSides24x60TopdownLR180 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_l.R180.png';
import RackEndPanelWithSides24x60TopdownLR270 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_l.R270.png';
import RackEndPanelWithSides24x60TopdownR from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_r.png';
import RackEndPanelWithSides24x60TopdownRR90 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_r.R90.png';
import RackEndPanelWithSides24x60TopdownRR180 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_r.R180.png';
import RackEndPanelWithSides24x60TopdownRR270 from '../../../assets/fixtures/RackEndPanelWithSides24x60/topdown_r.R270.png';

// Rack w/ Endcap

import RackEndcapWithSidesWingsFront from '../../../assets/fixtures/RackEndcapWithSidesWings/front.png';
import RackEndcapWithSidesWingsTopdownL from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_l.png';
import RackEndcapWithSidesWingsTopdownLR90 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_l.R90.png';
import RackEndcapWithSidesWingsTopdownLR180 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_l.R180.png';
import RackEndcapWithSidesWingsTopdownLR270 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_l.R270.png';
import RackEndcapWithSidesWingsTopdownR from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_r.png';
import RackEndcapWithSidesWingsTopdownRR90 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_r.R90.png';
import RackEndcapWithSidesWingsTopdownRR180 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_r.R180.png';
import RackEndcapWithSidesWingsTopdownRR270 from '../../../assets/fixtures/RackEndcapWithSidesWings/topdown_r.R270.png';

import FrontEndTraditionalEndcap24Front from '../../../assets/fixtures/FrontEndTraditionalEndcap24/front.png';
import FrontEndTraditionalEndcap24TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_l.png';
import FrontEndTraditionalEndcap24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_l.R90.png';
import FrontEndTraditionalEndcap24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_l.R180.png';
import FrontEndTraditionalEndcap24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_l.R270.png';
import FrontEndTraditionalEndcap24TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_r.png';
import FrontEndTraditionalEndcap24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_r.R90.png';
import FrontEndTraditionalEndcap24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_r.R180.png';
import FrontEndTraditionalEndcap24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap24/topdown_r.R270.png';

import FrontEndTraditionalEndcap36Front from '../../../assets/fixtures/FrontEndTraditionalEndcap36/front.png';
import FrontEndTraditionalEndcap36TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_l.png';
import FrontEndTraditionalEndcap36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_l.R90.png';
import FrontEndTraditionalEndcap36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_l.R180.png';
import FrontEndTraditionalEndcap36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_l.R270.png';
import FrontEndTraditionalEndcap36TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_r.png';
import FrontEndTraditionalEndcap36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_r.R90.png';
import FrontEndTraditionalEndcap36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_r.R180.png';
import FrontEndTraditionalEndcap36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap36/topdown_r.R270.png';

import FrontEndTraditionalEndcap48Front from '../../../assets/fixtures/FrontEndTraditionalEndcap48/front.png';
import FrontEndTraditionalEndcap48TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_l.png';
import FrontEndTraditionalEndcap48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_l.R90.png';
import FrontEndTraditionalEndcap48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_l.R180.png';
import FrontEndTraditionalEndcap48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_l.R270.png';
import FrontEndTraditionalEndcap48TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_r.png';
import FrontEndTraditionalEndcap48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_r.R90.png';
import FrontEndTraditionalEndcap48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_r.R180.png';
import FrontEndTraditionalEndcap48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcap48/topdown_r.R270.png';

// Endcap Sides

import FrontEndTraditionalEndcapSides24Front from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/front.png';
import FrontEndTraditionalEndcapSides24TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_l.png';
import FrontEndTraditionalEndcapSides24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_l.R90.png';
import FrontEndTraditionalEndcapSides24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_l.R180.png';
import FrontEndTraditionalEndcapSides24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_l.R270.png';
import FrontEndTraditionalEndcapSides24TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_r.png';
import FrontEndTraditionalEndcapSides24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_r.R90.png';
import FrontEndTraditionalEndcapSides24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_r.R180.png';
import FrontEndTraditionalEndcapSides24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides24/topdown_r.R270.png';

import FrontEndTraditionalEndcapSides36Front from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/front.png';
import FrontEndTraditionalEndcapSides36TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_l.png';
import FrontEndTraditionalEndcapSides36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_l.R90.png';
import FrontEndTraditionalEndcapSides36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_l.R180.png';
import FrontEndTraditionalEndcapSides36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_l.R270.png';
import FrontEndTraditionalEndcapSides36TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_r.png';
import FrontEndTraditionalEndcapSides36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_r.R90.png';
import FrontEndTraditionalEndcapSides36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_r.R180.png';
import FrontEndTraditionalEndcapSides36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides36/topdown_r.R270.png';

import FrontEndTraditionalEndcapSides48Front from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/front.png';
import FrontEndTraditionalEndcapSides48TopdownL from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_l.png';
import FrontEndTraditionalEndcapSides48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_l.R90.png';
import FrontEndTraditionalEndcapSides48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_l.R180.png';
import FrontEndTraditionalEndcapSides48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_l.R270.png';
import FrontEndTraditionalEndcapSides48TopdownR from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_r.png';
import FrontEndTraditionalEndcapSides48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_r.R90.png';
import FrontEndTraditionalEndcapSides48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_r.R180.png';
import FrontEndTraditionalEndcapSides48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalEndcapSides48/topdown_r.R270.png';

// Racking Over The Belt

import FrontEndTraditionalOTBRight48Front from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/front.png';
import FrontEndTraditionalOTBRight48TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_l.png';
import FrontEndTraditionalOTBRight48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_l.R90.png';
import FrontEndTraditionalOTBRight48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_l.R180.png';
import FrontEndTraditionalOTBRight48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_l.R270.png';
import FrontEndTraditionalOTBRight48TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_r.png';
import FrontEndTraditionalOTBRight48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_r.R90.png';
import FrontEndTraditionalOTBRight48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_r.R180.png';
import FrontEndTraditionalOTBRight48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight48/topdown_r.R270.png';

import FrontEndTraditionalOTBLeft48Front from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/front.png';
import FrontEndTraditionalOTBLeft48TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_l.png';
import FrontEndTraditionalOTBLeft48TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_l.R90.png';
import FrontEndTraditionalOTBLeft48TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_l.R180.png';
import FrontEndTraditionalOTBLeft48TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_l.R270.png';
import FrontEndTraditionalOTBLeft48TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_r.png';
import FrontEndTraditionalOTBLeft48TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_r.R90.png';
import FrontEndTraditionalOTBLeft48TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_r.R180.png';
import FrontEndTraditionalOTBLeft48TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft48/topdown_r.R270.png';

import FrontEndTraditionalOTBRight36Front from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/front.png';
import FrontEndTraditionalOTBRight36TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_l.png';
import FrontEndTraditionalOTBRight36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_l.R90.png';
import FrontEndTraditionalOTBRight36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_l.R180.png';
import FrontEndTraditionalOTBRight36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_l.R270.png';
import FrontEndTraditionalOTBRight36TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_r.png';
import FrontEndTraditionalOTBRight36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_r.R90.png';
import FrontEndTraditionalOTBRight36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_r.R180.png';
import FrontEndTraditionalOTBRight36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight36/topdown_r.R270.png';

import FrontEndTraditionalOTBLeft36Front from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/front.png';
import FrontEndTraditionalOTBLeft36TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_l.png';
import FrontEndTraditionalOTBLeft36TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_l.R90.png';
import FrontEndTraditionalOTBLeft36TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_l.R180.png';
import FrontEndTraditionalOTBLeft36TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_l.R270.png';
import FrontEndTraditionalOTBLeft36TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_r.png';
import FrontEndTraditionalOTBLeft36TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_r.R90.png';
import FrontEndTraditionalOTBLeft36TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_r.R180.png';
import FrontEndTraditionalOTBLeft36TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft36/topdown_r.R270.png';

import FrontEndTraditionalOTBRight24Front from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/front.png';
import FrontEndTraditionalOTBRight24TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_l.png';
import FrontEndTraditionalOTBRight24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_l.R90.png';
import FrontEndTraditionalOTBRight24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_l.R180.png';
import FrontEndTraditionalOTBRight24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_l.R270.png';
import FrontEndTraditionalOTBRight24TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_r.png';
import FrontEndTraditionalOTBRight24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_r.R90.png';
import FrontEndTraditionalOTBRight24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_r.R180.png';
import FrontEndTraditionalOTBRight24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBRight24/topdown_r.R270.png';

import FrontEndTraditionalOTBLeft24Front from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/front.png';
import FrontEndTraditionalOTBLeft24TopdownL from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_l.png';
import FrontEndTraditionalOTBLeft24TopdownLR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_l.R90.png';
import FrontEndTraditionalOTBLeft24TopdownLR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_l.R180.png';
import FrontEndTraditionalOTBLeft24TopdownLR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_l.R270.png';
import FrontEndTraditionalOTBLeft24TopdownR from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_r.png';
import FrontEndTraditionalOTBLeft24TopdownRR90 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_r.R90.png';
import FrontEndTraditionalOTBLeft24TopdownRR180 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_r.R180.png';
import FrontEndTraditionalOTBLeft24TopdownRR270 from '../../../assets/fixtures/FrontEndTraditionalOTBLeft24/topdown_r.R270.png';

// Shoppers
// TODO: rename folder
// Cart Female Sm Shopper
import CartShopperFront from '../../../assets/fixtures/CartShopper/front.png';
import CartShopperTopdownR from '../../../assets/fixtures/CartShopper/topdown_r.png';
import CartShopperTopdownRR90 from '../../../assets/fixtures/CartShopper/topdown_r.R90.png';
import CartShopperTopdownRR180 from '../../../assets/fixtures/CartShopper/topdown_r.R180.png';
import CartShopperTopdownRR270 from '../../../assets/fixtures/CartShopper/topdown_r.R270.png';
// TODO: rename folder
// Basket Female Sm Shopper
import BasketShopperFront from '../../../assets/fixtures/BasketShopper/front.png';
import BasketShopperTopdownR from '../../../assets/fixtures/BasketShopper/topdown_r.png';
import BasketShopperTopdownRR90 from '../../../assets/fixtures/BasketShopper/topdown_r.R90.png';
import BasketShopperTopdownRR180 from '../../../assets/fixtures/BasketShopper/topdown_r.R180.png';
import BasketShopperTopdownRR270 from '../../../assets/fixtures/BasketShopper/topdown_r.R270.png';

// Basket Male Sm Shopper
import BasketMaleSmShopperFront from '../../../assets/fixtures/BasketMaleSmShopper/front.png';
import BasketMaleSmShopperTopdownR from '../../../assets/fixtures/BasketMaleSmShopper/topdown_r.png';
import BasketMaleSmShopperTopdownRR90 from '../../../assets/fixtures/BasketMaleSmShopper/topdown_r.R90.png';
import BasketMaleSmShopperTopdownRR180 from '../../../assets/fixtures/BasketMaleSmShopper/topdown_r.R180.png';
import BasketMaleSmShopperTopdownRR270 from '../../../assets/fixtures/BasketMaleSmShopper/topdown_r.R270.png';

// No Basket Female Sm Shopper
import NoBasketFemaleSmShopperFront from '../../../assets/fixtures/NoBasketFemaleSmShopper/front.png';
import NoBasketFemaleSmShopperTopdownR from '../../../assets/fixtures/NoBasketFemaleSmShopper/topdown_r.png';
import NoBasketFemaleSmShopperTopdownRR90 from '../../../assets/fixtures/NoBasketFemaleSmShopper/topdown_r.R90.png';
import NoBasketFemaleSmShopperTopdownRR180 from '../../../assets/fixtures/NoBasketFemaleSmShopper/topdown_r.R180.png';
import NoBasketFemaleSmShopperTopdownRR270 from '../../../assets/fixtures/NoBasketFemaleSmShopper/topdown_r.R270.png';

// No Basket Male Sm Shopper
import NoBasketMaleSmShopperFront from '../../../assets/fixtures/NoBasketMaleSmShopper/front.png';
import NoBasketMaleSmShopperTopdownR from '../../../assets/fixtures/NoBasketMaleSmShopper/topdown_r.png';
import NoBasketMaleSmShopperTopdownRR90 from '../../../assets/fixtures/NoBasketMaleSmShopper/topdown_r.R90.png';
import NoBasketMaleSmShopperTopdownRR180 from '../../../assets/fixtures/NoBasketMaleSmShopper/topdown_r.R180.png';
import NoBasketMaleSmShopperTopdownRR270 from '../../../assets/fixtures/NoBasketMaleSmShopper/topdown_r.R270.png';

// Basket Male Lg Shopper
import BasketMaleLgShopperFront from '../../../assets/fixtures/BasketMaleLgShopper/front.png';
import BasketMaleLgShopperTopdownR from '../../../assets/fixtures/BasketMaleLgShopper/topdown_r.png';
import BasketMaleLgShopperTopdownRR90 from '../../../assets/fixtures/BasketMaleLgShopper/topdown_r.R90.png';
import BasketMaleLgShopperTopdownRR180 from '../../../assets/fixtures/BasketMaleLgShopper/topdown_r.R180.png';
import BasketMaleLgShopperTopdownRR270 from '../../../assets/fixtures/BasketMaleLgShopper/topdown_r.R270.png';

// No Basket Male Lg Shopper
import NoBasketMaleLgShopperFront from '../../../assets/fixtures/NoBasketMaleLgShopper/front.png';
import NoBasketMaleLgShopperTopdownR from '../../../assets/fixtures/NoBasketMaleLgShopper/topdown_r.png';
import NoBasketMaleLgShopperTopdownRR90 from '../../../assets/fixtures/NoBasketMaleLgShopper/topdown_r.R90.png';
import NoBasketMaleLgShopperTopdownRR180 from '../../../assets/fixtures/NoBasketMaleLgShopper/topdown_r.R180.png';
import NoBasketMaleLgShopperTopdownRR270 from '../../../assets/fixtures/NoBasketMaleLgShopper/topdown_r.R270.png';

// ATM

import ATMFront from '../../../assets/fixtures/ATM/front.png';
import ATMTopdownL from '../../../assets/fixtures/ATM/topdown_l.png';
import ATMTopdownLR90 from '../../../assets/fixtures/ATM/topdown_l.R90.png';
import ATMTopdownLR180 from '../../../assets/fixtures/ATM/topdown_l.R180.png';
import ATMTopdownLR270 from '../../../assets/fixtures/ATM/topdown_l.R270.png';
import ATMTopdownR from '../../../assets/fixtures/ATM/topdown_r.png';
import ATMTopdownRR90 from '../../../assets/fixtures/ATM/topdown_r.R90.png';
import ATMTopdownRR180 from '../../../assets/fixtures/ATM/topdown_r.R180.png';
import ATMTopdownRR270 from '../../../assets/fixtures/ATM/topdown_r.R270.png';

// BakeryRack

import BakeryRackFront from '../../../assets/fixtures/BakeryRack/front.png';
import BakeryRackTopdownL from '../../../assets/fixtures/BakeryRack/topdown_l.png';
import BakeryRackTopdownLR90 from '../../../assets/fixtures/BakeryRack/topdown_l.R90.png';
import BakeryRackTopdownLR180 from '../../../assets/fixtures/BakeryRack/topdown_l.R180.png';
import BakeryRackTopdownLR270 from '../../../assets/fixtures/BakeryRack/topdown_l.R270.png';
import BakeryRackTopdownR from '../../../assets/fixtures/BakeryRack/topdown_r.png';
import BakeryRackTopdownRR90 from '../../../assets/fixtures/BakeryRack/topdown_r.R90.png';
import BakeryRackTopdownRR180 from '../../../assets/fixtures/BakeryRack/topdown_r.R180.png';
import BakeryRackTopdownRR270 from '../../../assets/fixtures/BakeryRack/topdown_r.R270.png';

// ChickenWarmer

import ChickenWarmerFront from '../../../assets/fixtures/ChickenWarmer/front.png';
import ChickenWarmerTopdownL from '../../../assets/fixtures/ChickenWarmer/topdown_l.png';
import ChickenWarmerTopdownLR90 from '../../../assets/fixtures/ChickenWarmer/topdown_l.R90.png';
import ChickenWarmerTopdownLR180 from '../../../assets/fixtures/ChickenWarmer/topdown_l.R180.png';
import ChickenWarmerTopdownLR270 from '../../../assets/fixtures/ChickenWarmer/topdown_l.R270.png';
import ChickenWarmerTopdownR from '../../../assets/fixtures/ChickenWarmer/topdown_r.png';
import ChickenWarmerTopdownRR90 from '../../../assets/fixtures/ChickenWarmer/topdown_r.R90.png';
import ChickenWarmerTopdownRR180 from '../../../assets/fixtures/ChickenWarmer/topdown_r.R180.png';
import ChickenWarmerTopdownRR270 from '../../../assets/fixtures/ChickenWarmer/topdown_r.R270.png';

// CoinstarKiosk

import CoinstarKioskFront from '../../../assets/fixtures/CoinstarKiosk/front.png';
import CoinstarKioskTopdownL from '../../../assets/fixtures/CoinstarKiosk/topdown_l.png';
import CoinstarKioskTopdownLR90 from '../../../assets/fixtures/CoinstarKiosk/topdown_l.R90.png';
import CoinstarKioskTopdownLR180 from '../../../assets/fixtures/CoinstarKiosk/topdown_l.R180.png';
import CoinstarKioskTopdownLR270 from '../../../assets/fixtures/CoinstarKiosk/topdown_l.R270.png';
import CoinstarKioskTopdownR from '../../../assets/fixtures/CoinstarKiosk/topdown_r.png';
import CoinstarKioskTopdownRR90 from '../../../assets/fixtures/CoinstarKiosk/topdown_r.R90.png';
import CoinstarKioskTopdownRR180 from '../../../assets/fixtures/CoinstarKiosk/topdown_r.R180.png';
import CoinstarKioskTopdownRR270 from '../../../assets/fixtures/CoinstarKiosk/topdown_r.R270.png';

// GiftCardCenter

import GiftCardCenterFront from '../../../assets/fixtures/GiftCardCenter/front.png';
import GiftCardCenterTopdownL from '../../../assets/fixtures/GiftCardCenter/topdown_l.png';
import GiftCardCenterTopdownLR90 from '../../../assets/fixtures/GiftCardCenter/topdown_l.R90.png';
import GiftCardCenterTopdownLR180 from '../../../assets/fixtures/GiftCardCenter/topdown_l.R180.png';
import GiftCardCenterTopdownLR270 from '../../../assets/fixtures/GiftCardCenter/topdown_l.R270.png';
import GiftCardCenterTopdownR from '../../../assets/fixtures/GiftCardCenter/topdown_r.png';
import GiftCardCenterTopdownRR90 from '../../../assets/fixtures/GiftCardCenter/topdown_r.R90.png';
import GiftCardCenterTopdownRR180 from '../../../assets/fixtures/GiftCardCenter/topdown_r.R180.png';
import GiftCardCenterTopdownRR270 from '../../../assets/fixtures/GiftCardCenter/topdown_r.R270.png';

// LottoKiosk

import LottoKioskFront from '../../../assets/fixtures/LottoKiosk/front.png';
import LottoKioskTopdownL from '../../../assets/fixtures/LottoKiosk/topdown_l.png';
import LottoKioskTopdownLR90 from '../../../assets/fixtures/LottoKiosk/topdown_l.R90.png';
import LottoKioskTopdownLR180 from '../../../assets/fixtures/LottoKiosk/topdown_l.R180.png';
import LottoKioskTopdownLR270 from '../../../assets/fixtures/LottoKiosk/topdown_l.R270.png';
import LottoKioskTopdownR from '../../../assets/fixtures/LottoKiosk/topdown_r.png';
import LottoKioskTopdownRR90 from '../../../assets/fixtures/LottoKiosk/topdown_r.R90.png';
import LottoKioskTopdownRR180 from '../../../assets/fixtures/LottoKiosk/topdown_r.R180.png';
import LottoKioskTopdownRR270 from '../../../assets/fixtures/LottoKiosk/topdown_r.R270.png';

// PickUpKiosk

import PickUpKioskFront from '../../../assets/fixtures/PickUpKiosk/front.png';
import PickUpKioskTopdownL from '../../../assets/fixtures/PickUpKiosk/topdown_l.png';
import PickUpKioskTopdownLR90 from '../../../assets/fixtures/PickUpKiosk/topdown_l.R90.png';
import PickUpKioskTopdownLR180 from '../../../assets/fixtures/PickUpKiosk/topdown_l.R180.png';
import PickUpKioskTopdownLR270 from '../../../assets/fixtures/PickUpKiosk/topdown_l.R270.png';
import PickUpKioskTopdownR from '../../../assets/fixtures/PickUpKiosk/topdown_r.png';
import PickUpKioskTopdownRR90 from '../../../assets/fixtures/PickUpKiosk/topdown_r.R90.png';
import PickUpKioskTopdownRR180 from '../../../assets/fixtures/PickUpKiosk/topdown_r.R180.png';
import PickUpKioskTopdownRR270 from '../../../assets/fixtures/PickUpKiosk/topdown_r.R270.png';

// PickUpLockerBlock

import PickUpLockerBlockFront from '../../../assets/fixtures/PickUpLockerBlock/front.png';
import PickUpLockerBlockTopdownL from '../../../assets/fixtures/PickUpLockerBlock/topdown_l.png';
import PickUpLockerBlockTopdownLR90 from '../../../assets/fixtures/PickUpLockerBlock/topdown_l.R90.png';
import PickUpLockerBlockTopdownLR180 from '../../../assets/fixtures/PickUpLockerBlock/topdown_l.R180.png';
import PickUpLockerBlockTopdownLR270 from '../../../assets/fixtures/PickUpLockerBlock/topdown_l.R270.png';
import PickUpLockerBlockTopdownR from '../../../assets/fixtures/PickUpLockerBlock/topdown_r.png';
import PickUpLockerBlockTopdownRR90 from '../../../assets/fixtures/PickUpLockerBlock/topdown_r.R90.png';
import PickUpLockerBlockTopdownRR180 from '../../../assets/fixtures/PickUpLockerBlock/topdown_r.R180.png';
import PickUpLockerBlockTopdownRR270 from '../../../assets/fixtures/PickUpLockerBlock/topdown_r.R270.png';

// PickUpLockerSingle

import PickUpLockerSingleFront from '../../../assets/fixtures/PickUpLockerSingle/front.png';
import PickUpLockerSingleTopdownL from '../../../assets/fixtures/PickUpLockerSingle/topdown_l.png';
import PickUpLockerSingleTopdownLR90 from '../../../assets/fixtures/PickUpLockerSingle/topdown_l.R90.png';
import PickUpLockerSingleTopdownLR180 from '../../../assets/fixtures/PickUpLockerSingle/topdown_l.R180.png';
import PickUpLockerSingleTopdownLR270 from '../../../assets/fixtures/PickUpLockerSingle/topdown_l.R270.png';
import PickUpLockerSingleTopdownR from '../../../assets/fixtures/PickUpLockerSingle/topdown_r.png';
import PickUpLockerSingleTopdownRR90 from '../../../assets/fixtures/PickUpLockerSingle/topdown_r.R90.png';
import PickUpLockerSingleTopdownRR180 from '../../../assets/fixtures/PickUpLockerSingle/topdown_r.R180.png';
import PickUpLockerSingleTopdownRR270 from '../../../assets/fixtures/PickUpLockerSingle/topdown_r.R270.png';

// TobaccoCase

import TobaccoCaseFront from '../../../assets/fixtures/TobaccoCase/front.png';
import TobaccoCaseTopdownL from '../../../assets/fixtures/TobaccoCase/topdown_l.png';
import TobaccoCaseTopdownLR90 from '../../../assets/fixtures/TobaccoCase/topdown_l.R90.png';
import TobaccoCaseTopdownLR180 from '../../../assets/fixtures/TobaccoCase/topdown_l.R180.png';
import TobaccoCaseTopdownLR270 from '../../../assets/fixtures/TobaccoCase/topdown_l.R270.png';
import TobaccoCaseTopdownR from '../../../assets/fixtures/TobaccoCase/topdown_r.png';
import TobaccoCaseTopdownRR90 from '../../../assets/fixtures/TobaccoCase/topdown_r.R90.png';
import TobaccoCaseTopdownRR180 from '../../../assets/fixtures/TobaccoCase/topdown_r.R180.png';
import TobaccoCaseTopdownRR270 from '../../../assets/fixtures/TobaccoCase/topdown_r.R270.png';

// ExpressLeft

import ExpressLeftFront from '../../../assets/fixtures/ExpressLeft/front.png';
import ExpressLeftTopdownL from '../../../assets/fixtures/ExpressLeft/topdown_l.png';
import ExpressLeftTopdownLR90 from '../../../assets/fixtures/ExpressLeft/topdown_l.R90.png';
import ExpressLeftTopdownLR180 from '../../../assets/fixtures/ExpressLeft/topdown_l.R180.png';
import ExpressLeftTopdownLR270 from '../../../assets/fixtures/ExpressLeft/topdown_l.R270.png';
import ExpressLeftTopdownR from '../../../assets/fixtures/ExpressLeft/topdown_r.png';
import ExpressLeftTopdownRR90 from '../../../assets/fixtures/ExpressLeft/topdown_r.R90.png';
import ExpressLeftTopdownRR180 from '../../../assets/fixtures/ExpressLeft/topdown_r.R180.png';
import ExpressLeftTopdownRR270 from '../../../assets/fixtures/ExpressLeft/topdown_r.R270.png';

// ExpressRight

import ExpressRightFront from '../../../assets/fixtures/ExpressRight/front.png';
import ExpressRightTopdownL from '../../../assets/fixtures/ExpressRight/topdown_l.png';
import ExpressRightTopdownLR90 from '../../../assets/fixtures/ExpressRight/topdown_l.R90.png';
import ExpressRightTopdownLR180 from '../../../assets/fixtures/ExpressRight/topdown_l.R180.png';
import ExpressRightTopdownLR270 from '../../../assets/fixtures/ExpressRight/topdown_l.R270.png';
import ExpressRightTopdownR from '../../../assets/fixtures/ExpressRight/topdown_r.png';
import ExpressRightTopdownRR90 from '../../../assets/fixtures/ExpressRight/topdown_r.R90.png';
import ExpressRightTopdownRR180 from '../../../assets/fixtures/ExpressRight/topdown_r.R180.png';
import ExpressRightTopdownRR270 from '../../../assets/fixtures/ExpressRight/topdown_r.R270.png';

// ExpressPlusLeft

import ExpressPlusLeftFront from '../../../assets/fixtures/ExpressPlusLeft/front.png';
import ExpressPlusLeftTopdownL from '../../../assets/fixtures/ExpressPlusLeft/topdown_l.png';
import ExpressPlusLeftTopdownLR90 from '../../../assets/fixtures/ExpressPlusLeft/topdown_l.R90.png';
import ExpressPlusLeftTopdownLR180 from '../../../assets/fixtures/ExpressPlusLeft/topdown_l.R180.png';
import ExpressPlusLeftTopdownLR270 from '../../../assets/fixtures/ExpressPlusLeft/topdown_l.R270.png';
import ExpressPlusLeftTopdownR from '../../../assets/fixtures/ExpressPlusLeft/topdown_r.png';
import ExpressPlusLeftTopdownRR90 from '../../../assets/fixtures/ExpressPlusLeft/topdown_r.R90.png';
import ExpressPlusLeftTopdownRR180 from '../../../assets/fixtures/ExpressPlusLeft/topdown_r.R180.png';
import ExpressPlusLeftTopdownRR270 from '../../../assets/fixtures/ExpressPlusLeft/topdown_r.R270.png';

// ExpressPlusRight

import ExpressPlusRightFront from '../../../assets/fixtures/ExpressPlusRight/front.png';
import ExpressPlusRightTopdownL from '../../../assets/fixtures/ExpressPlusRight/topdown_l.png';
import ExpressPlusRightTopdownLR90 from '../../../assets/fixtures/ExpressPlusRight/topdown_l.R90.png';
import ExpressPlusRightTopdownLR180 from '../../../assets/fixtures/ExpressPlusRight/topdown_l.R180.png';
import ExpressPlusRightTopdownLR270 from '../../../assets/fixtures/ExpressPlusRight/topdown_l.R270.png';
import ExpressPlusRightTopdownR from '../../../assets/fixtures/ExpressPlusRight/topdown_r.png';
import ExpressPlusRightTopdownRR90 from '../../../assets/fixtures/ExpressPlusRight/topdown_r.R90.png';
import ExpressPlusRightTopdownRR180 from '../../../assets/fixtures/ExpressPlusRight/topdown_r.R180.png';
import ExpressPlusRightTopdownRR270 from '../../../assets/fixtures/ExpressPlusRight/topdown_r.R270.png';

// BeverageCoolerOpenAir148

import BeverageCoolerOpenAir148Front from '../../../assets/fixtures/BeverageCoolerOpenAir148/front.png';
import BeverageCoolerOpenAir148TopdownL from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_l.png';
import BeverageCoolerOpenAir148TopdownLR90 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_l.R90.png';
import BeverageCoolerOpenAir148TopdownLR180 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_l.R180.png';
import BeverageCoolerOpenAir148TopdownLR270 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_l.R270.png';
import BeverageCoolerOpenAir148TopdownR from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_r.png';
import BeverageCoolerOpenAir148TopdownRR90 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_r.R90.png';
import BeverageCoolerOpenAir148TopdownRR180 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_r.R180.png';
import BeverageCoolerOpenAir148TopdownRR270 from '../../../assets/fixtures/BeverageCoolerOpenAir148/topdown_r.R270.png';

// BeverageCoolerOpenAir196

import BeverageCoolerOpenAir196Front from '../../../assets/fixtures/BeverageCoolerOpenAir196/front.png';
import BeverageCoolerOpenAir196TopdownL from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_l.png';
import BeverageCoolerOpenAir196TopdownLR90 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_l.R90.png';
import BeverageCoolerOpenAir196TopdownLR180 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_l.R180.png';
import BeverageCoolerOpenAir196TopdownLR270 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_l.R270.png';
import BeverageCoolerOpenAir196TopdownR from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_r.png';
import BeverageCoolerOpenAir196TopdownRR90 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_r.R90.png';
import BeverageCoolerOpenAir196TopdownRR180 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_r.R180.png';
import BeverageCoolerOpenAir196TopdownRR270 from '../../../assets/fixtures/BeverageCoolerOpenAir196/topdown_r.R270.png';

// BeverageCoolerOpenAir244

import BeverageCoolerOpenAir244Front from '../../../assets/fixtures/BeverageCoolerOpenAir244/front.png';
import BeverageCoolerOpenAir244TopdownL from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_l.png';
import BeverageCoolerOpenAir244TopdownLR90 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_l.R90.png';
import BeverageCoolerOpenAir244TopdownLR180 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_l.R180.png';
import BeverageCoolerOpenAir244TopdownLR270 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_l.R270.png';
import BeverageCoolerOpenAir244TopdownR from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_r.png';
import BeverageCoolerOpenAir244TopdownRR90 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_r.R90.png';
import BeverageCoolerOpenAir244TopdownRR180 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_r.R180.png';
import BeverageCoolerOpenAir244TopdownRR270 from '../../../assets/fixtures/BeverageCoolerOpenAir244/topdown_r.R270.png';

// BullPenPerimiterPanel24

import BullPenPerimiterPanel24Front from '../../../assets/fixtures/BullPenPerimiterPanel24/front.png';
import BullPenPerimiterPanel24TopdownL from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_l.png';
import BullPenPerimiterPanel24TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_l.R90.png';
import BullPenPerimiterPanel24TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_l.R180.png';
import BullPenPerimiterPanel24TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_l.R270.png';
import BullPenPerimiterPanel24TopdownR from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_r.png';
import BullPenPerimiterPanel24TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_r.R90.png';
import BullPenPerimiterPanel24TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_r.R180.png';
import BullPenPerimiterPanel24TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterPanel24/topdown_r.R270.png';

// BullPenPerimiterPanel36

import BullPenPerimiterPanel36Front from '../../../assets/fixtures/BullPenPerimiterPanel36/front.png';
import BullPenPerimiterPanel36TopdownL from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_l.png';
import BullPenPerimiterPanel36TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_l.R90.png';
import BullPenPerimiterPanel36TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_l.R180.png';
import BullPenPerimiterPanel36TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_l.R270.png';
import BullPenPerimiterPanel36TopdownR from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_r.png';
import BullPenPerimiterPanel36TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_r.R90.png';
import BullPenPerimiterPanel36TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_r.R180.png';
import BullPenPerimiterPanel36TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterPanel36/topdown_r.R270.png';

// BullPenPerimiterPanel48

import BullPenPerimiterPanel48Front from '../../../assets/fixtures/BullPenPerimiterPanel48/front.png';
import BullPenPerimiterPanel48TopdownL from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_l.png';
import BullPenPerimiterPanel48TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_l.R90.png';
import BullPenPerimiterPanel48TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_l.R180.png';
import BullPenPerimiterPanel48TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_l.R270.png';
import BullPenPerimiterPanel48TopdownR from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_r.png';
import BullPenPerimiterPanel48TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_r.R90.png';
import BullPenPerimiterPanel48TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_r.R180.png';
import BullPenPerimiterPanel48TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterPanel48/topdown_r.R270.png';

// BullPenPerimiterWireChase24

import BullPenPerimiterWireChase24Front from '../../../assets/fixtures/BullPenPerimiterWireChase24/front.png';
import BullPenPerimiterWireChase24TopdownL from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_l.png';
import BullPenPerimiterWireChase24TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_l.R90.png';
import BullPenPerimiterWireChase24TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_l.R180.png';
import BullPenPerimiterWireChase24TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_l.R270.png';
import BullPenPerimiterWireChase24TopdownR from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_r.png';
import BullPenPerimiterWireChase24TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_r.R90.png';
import BullPenPerimiterWireChase24TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_r.R180.png';
import BullPenPerimiterWireChase24TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterWireChase24/topdown_r.R270.png';

// BullPenPerimiterWireChase36

import BullPenPerimiterWireChase36Front from '../../../assets/fixtures/BullPenPerimiterWireChase36/front.png';
import BullPenPerimiterWireChase36TopdownL from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_l.png';
import BullPenPerimiterWireChase36TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_l.R90.png';
import BullPenPerimiterWireChase36TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_l.R180.png';
import BullPenPerimiterWireChase36TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_l.R270.png';
import BullPenPerimiterWireChase36TopdownR from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_r.png';
import BullPenPerimiterWireChase36TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_r.R90.png';
import BullPenPerimiterWireChase36TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_r.R180.png';
import BullPenPerimiterWireChase36TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterWireChase36/topdown_r.R270.png';

// BullPenPerimiterWireChase48

import BullPenPerimiterWireChase48Front from '../../../assets/fixtures/BullPenPerimiterWireChase48/front.png';
import BullPenPerimiterWireChase48TopdownL from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_l.png';
import BullPenPerimiterWireChase48TopdownLR90 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_l.R90.png';
import BullPenPerimiterWireChase48TopdownLR180 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_l.R180.png';
import BullPenPerimiterWireChase48TopdownLR270 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_l.R270.png';
import BullPenPerimiterWireChase48TopdownR from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_r.png';
import BullPenPerimiterWireChase48TopdownRR90 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_r.R90.png';
import BullPenPerimiterWireChase48TopdownRR180 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_r.R180.png';
import BullPenPerimiterWireChase48TopdownRR270 from '../../../assets/fixtures/BullPenPerimiterWireChase48/topdown_r.R270.png';

// Fourway36

import Fourway36Front from '../../../assets/fixtures/Fourway36/front.png';
import Fourway36TopdownL from '../../../assets/fixtures/Fourway36/topdown_l.png';
import Fourway36TopdownLR90 from '../../../assets/fixtures/Fourway36/topdown_l.R90.png';
import Fourway36TopdownLR180 from '../../../assets/fixtures/Fourway36/topdown_l.R180.png';
import Fourway36TopdownLR270 from '../../../assets/fixtures/Fourway36/topdown_l.R270.png';
import Fourway36TopdownR from '../../../assets/fixtures/Fourway36/topdown_r.png';
import Fourway36TopdownRR90 from '../../../assets/fixtures/Fourway36/topdown_r.R90.png';
import Fourway36TopdownRR180 from '../../../assets/fixtures/Fourway36/topdown_r.R180.png';
import Fourway36TopdownRR270 from '../../../assets/fixtures/Fourway36/topdown_r.R270.png';

// Fourway48

import Fourway48Front from '../../../assets/fixtures/Fourway48/front.png';
import Fourway48TopdownL from '../../../assets/fixtures/Fourway48/topdown_l.png';
import Fourway48TopdownLR90 from '../../../assets/fixtures/Fourway48/topdown_l.R90.png';
import Fourway48TopdownLR180 from '../../../assets/fixtures/Fourway48/topdown_l.R180.png';
import Fourway48TopdownLR270 from '../../../assets/fixtures/Fourway48/topdown_l.R270.png';
import Fourway48TopdownR from '../../../assets/fixtures/Fourway48/topdown_r.png';
import Fourway48TopdownRR90 from '../../../assets/fixtures/Fourway48/topdown_r.R90.png';
import Fourway48TopdownRR180 from '../../../assets/fixtures/Fourway48/topdown_r.R180.png';
import Fourway48TopdownRR270 from '../../../assets/fixtures/Fourway48/topdown_r.R270.png';

// LargeBasketLeft

import LargeBasketLeftFront from '../../../assets/fixtures/LargeBasketLeft/front.png';
import LargeBasketLeftTopdownL from '../../../assets/fixtures/LargeBasketLeft/topdown_l.png';
import LargeBasketLeftTopdownLR90 from '../../../assets/fixtures/LargeBasketLeft/topdown_l.R90.png';
import LargeBasketLeftTopdownLR180 from '../../../assets/fixtures/LargeBasketLeft/topdown_l.R180.png';
import LargeBasketLeftTopdownLR270 from '../../../assets/fixtures/LargeBasketLeft/topdown_l.R270.png';
import LargeBasketLeftTopdownR from '../../../assets/fixtures/LargeBasketLeft/topdown_r.png';
import LargeBasketLeftTopdownRR90 from '../../../assets/fixtures/LargeBasketLeft/topdown_r.R90.png';
import LargeBasketLeftTopdownRR180 from '../../../assets/fixtures/LargeBasketLeft/topdown_r.R180.png';
import LargeBasketLeftTopdownRR270 from '../../../assets/fixtures/LargeBasketLeft/topdown_r.R270.png';

// LargeBasketRight

import LargeBasketRightFront from '../../../assets/fixtures/LargeBasketRight/front.png';
import LargeBasketRightTopdownL from '../../../assets/fixtures/LargeBasketRight/topdown_l.png';
import LargeBasketRightTopdownLR90 from '../../../assets/fixtures/LargeBasketRight/topdown_l.R90.png';
import LargeBasketRightTopdownLR180 from '../../../assets/fixtures/LargeBasketRight/topdown_l.R180.png';
import LargeBasketRightTopdownLR270 from '../../../assets/fixtures/LargeBasketRight/topdown_l.R270.png';
import LargeBasketRightTopdownR from '../../../assets/fixtures/LargeBasketRight/topdown_r.png';
import LargeBasketRightTopdownRR90 from '../../../assets/fixtures/LargeBasketRight/topdown_r.R90.png';
import LargeBasketRightTopdownRR180 from '../../../assets/fixtures/LargeBasketRight/topdown_r.R180.png';
import LargeBasketRightTopdownRR270 from '../../../assets/fixtures/LargeBasketRight/topdown_r.R270.png';

// TraditionalRegisterLeft

import TraditionalRegisterLeftFront from '../../../assets/fixtures/TraditionalRegisterLeft/front.png';
import TraditionalRegisterLeftTopdownL from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_l.png';
import TraditionalRegisterLeftTopdownLR90 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_l.R90.png';
import TraditionalRegisterLeftTopdownLR180 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_l.R180.png';
import TraditionalRegisterLeftTopdownLR270 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_l.R270.png';
import TraditionalRegisterLeftTopdownR from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_r.png';
import TraditionalRegisterLeftTopdownRR90 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_r.R90.png';
import TraditionalRegisterLeftTopdownRR180 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_r.R180.png';
import TraditionalRegisterLeftTopdownRR270 from '../../../assets/fixtures/TraditionalRegisterLeft/topdown_r.R270.png';

// TraditionalRegisterRight

import TraditionalRegisterRightFront from '../../../assets/fixtures/TraditionalRegisterRight/front.png';
import TraditionalRegisterRightTopdownL from '../../../assets/fixtures/TraditionalRegisterRight/topdown_l.png';
import TraditionalRegisterRightTopdownLR90 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_l.R90.png';
import TraditionalRegisterRightTopdownLR180 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_l.R180.png';
import TraditionalRegisterRightTopdownLR270 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_l.R270.png';
import TraditionalRegisterRightTopdownR from '../../../assets/fixtures/TraditionalRegisterRight/topdown_r.png';
import TraditionalRegisterRightTopdownRR90 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_r.R90.png';
import TraditionalRegisterRightTopdownRR180 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_r.R180.png';
import TraditionalRegisterRightTopdownRR270 from '../../../assets/fixtures/TraditionalRegisterRight/topdown_r.R270.png';

// TraditionalChecklaneWithCoolerEndNonShopable20219

import TraditionalChecklaneWithCoolerEndNonShopable20219Front from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/front.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownL from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR90 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R90.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR180 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R180.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR270 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R270.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownR from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR90 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R90.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR180 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R180.png';
import TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR270 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R270.png';

// TraditionalChecklaneWithCoolerEndNonShopable20220

import TraditionalChecklaneWithCoolerEndNonShopable20220Front from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/front.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownL from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR90 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R90.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR180 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R180.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR270 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_l.R270.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownR from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR90 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R90.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR180 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R180.png';
import TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR270 from '../../../assets/fixtures/TraditionalChecklaneWithCoolerEndNonShopable20219/topdown_r.R270.png';

// GondolaEndW24H48

import GondolaEndW24H48Front from '../../../assets/fixtures/GondolaEndW24H48/front.png';
import GondolaEndW24H48TopdownL from '../../../assets/fixtures/GondolaEndW24H48/topdown_l.png';
import GondolaEndW24H48TopdownLR90 from '../../../assets/fixtures/GondolaEndW24H48/topdown_l.R90.png';
import GondolaEndW24H48TopdownLR180 from '../../../assets/fixtures/GondolaEndW24H48/topdown_l.R180.png';
import GondolaEndW24H48TopdownLR270 from '../../../assets/fixtures/GondolaEndW24H48/topdown_l.R270.png';
import GondolaEndW24H48TopdownR from '../../../assets/fixtures/GondolaEndW24H48/topdown_r.png';
import GondolaEndW24H48TopdownRR90 from '../../../assets/fixtures/GondolaEndW24H48/topdown_r.R90.png';
import GondolaEndW24H48TopdownRR180 from '../../../assets/fixtures/GondolaEndW24H48/topdown_r.R180.png';
import GondolaEndW24H48TopdownRR270 from '../../../assets/fixtures/GondolaEndW24H48/topdown_r.R270.png';

// GondolaEndW36H48

import GondolaEndW36H48Front from '../../../assets/fixtures/GondolaEndW36H48/front.png';
import GondolaEndW36H48TopdownL from '../../../assets/fixtures/GondolaEndW36H48/topdown_l.png';
import GondolaEndW36H48TopdownLR90 from '../../../assets/fixtures/GondolaEndW36H48/topdown_l.R90.png';
import GondolaEndW36H48TopdownLR180 from '../../../assets/fixtures/GondolaEndW36H48/topdown_l.R180.png';
import GondolaEndW36H48TopdownLR270 from '../../../assets/fixtures/GondolaEndW36H48/topdown_l.R270.png';
import GondolaEndW36H48TopdownR from '../../../assets/fixtures/GondolaEndW36H48/topdown_r.png';
import GondolaEndW36H48TopdownRR90 from '../../../assets/fixtures/GondolaEndW36H48/topdown_r.R90.png';
import GondolaEndW36H48TopdownRR180 from '../../../assets/fixtures/GondolaEndW36H48/topdown_r.R180.png';
import GondolaEndW36H48TopdownRR270 from '../../../assets/fixtures/GondolaEndW36H48/topdown_r.R270.png';

// GondolaEndW48H48

import GondolaEndW48H48Front from '../../../assets/fixtures/GondolaEndW48H48/front.png';
import GondolaEndW48H48TopdownL from '../../../assets/fixtures/GondolaEndW48H48/topdown_l.png';
import GondolaEndW48H48TopdownLR90 from '../../../assets/fixtures/GondolaEndW48H48/topdown_l.R90.png';
import GondolaEndW48H48TopdownLR180 from '../../../assets/fixtures/GondolaEndW48H48/topdown_l.R180.png';
import GondolaEndW48H48TopdownLR270 from '../../../assets/fixtures/GondolaEndW48H48/topdown_l.R270.png';
import GondolaEndW48H48TopdownR from '../../../assets/fixtures/GondolaEndW48H48/topdown_r.png';
import GondolaEndW48H48TopdownRR90 from '../../../assets/fixtures/GondolaEndW48H48/topdown_r.R90.png';
import GondolaEndW48H48TopdownRR180 from '../../../assets/fixtures/GondolaEndW48H48/topdown_r.R180.png';
import GondolaEndW48H48TopdownRR270 from '../../../assets/fixtures/GondolaEndW48H48/topdown_r.R270.png';

// GondolaEndW24H60

import GondolaEndW24H60Front from '../../../assets/fixtures/GondolaEndW24H60/front.png';
import GondolaEndW24H60TopdownL from '../../../assets/fixtures/GondolaEndW24H60/topdown_l.png';
import GondolaEndW24H60TopdownLR90 from '../../../assets/fixtures/GondolaEndW24H60/topdown_l.R90.png';
import GondolaEndW24H60TopdownLR180 from '../../../assets/fixtures/GondolaEndW24H60/topdown_l.R180.png';
import GondolaEndW24H60TopdownLR270 from '../../../assets/fixtures/GondolaEndW24H60/topdown_l.R270.png';
import GondolaEndW24H60TopdownR from '../../../assets/fixtures/GondolaEndW24H60/topdown_r.png';
import GondolaEndW24H60TopdownRR90 from '../../../assets/fixtures/GondolaEndW24H60/topdown_r.R90.png';
import GondolaEndW24H60TopdownRR180 from '../../../assets/fixtures/GondolaEndW24H60/topdown_r.R180.png';
import GondolaEndW24H60TopdownRR270 from '../../../assets/fixtures/GondolaEndW24H60/topdown_r.R270.png';

// GondolaEndW36H60

import GondolaEndW36H60Front from '../../../assets/fixtures/GondolaEndW36H60/front.png';
import GondolaEndW36H60TopdownL from '../../../assets/fixtures/GondolaEndW36H60/topdown_l.png';
import GondolaEndW36H60TopdownLR90 from '../../../assets/fixtures/GondolaEndW36H60/topdown_l.R90.png';
import GondolaEndW36H60TopdownLR180 from '../../../assets/fixtures/GondolaEndW36H60/topdown_l.R180.png';
import GondolaEndW36H60TopdownLR270 from '../../../assets/fixtures/GondolaEndW36H60/topdown_l.R270.png';
import GondolaEndW36H60TopdownR from '../../../assets/fixtures/GondolaEndW36H60/topdown_r.png';
import GondolaEndW36H60TopdownRR90 from '../../../assets/fixtures/GondolaEndW36H60/topdown_r.R90.png';
import GondolaEndW36H60TopdownRR180 from '../../../assets/fixtures/GondolaEndW36H60/topdown_r.R180.png';
import GondolaEndW36H60TopdownRR270 from '../../../assets/fixtures/GondolaEndW36H60/topdown_r.R270.png';

// GondolaEndW48H60

import GondolaEndW48H60Front from '../../../assets/fixtures/GondolaEndW48H60/front.png';
import GondolaEndW48H60TopdownL from '../../../assets/fixtures/GondolaEndW48H60/topdown_l.png';
import GondolaEndW48H60TopdownLR90 from '../../../assets/fixtures/GondolaEndW48H60/topdown_l.R90.png';
import GondolaEndW48H60TopdownLR180 from '../../../assets/fixtures/GondolaEndW48H60/topdown_l.R180.png';
import GondolaEndW48H60TopdownLR270 from '../../../assets/fixtures/GondolaEndW48H60/topdown_l.R270.png';
import GondolaEndW48H60TopdownR from '../../../assets/fixtures/GondolaEndW48H60/topdown_r.png';
import GondolaEndW48H60TopdownRR90 from '../../../assets/fixtures/GondolaEndW48H60/topdown_r.R90.png';
import GondolaEndW48H60TopdownRR180 from '../../../assets/fixtures/GondolaEndW48H60/topdown_r.R180.png';
import GondolaEndW48H60TopdownRR270 from '../../../assets/fixtures/GondolaEndW48H60/topdown_r.R270.png';

// CoolerDoubleDoor31x55x27

import CoolerDoubleDoor31x55x27Front from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/front.png';
import CoolerDoubleDoor31x55x27TopdownL from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_l.png';
import CoolerDoubleDoor31x55x27TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_l.R90.png';
import CoolerDoubleDoor31x55x27TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_l.R180.png';
import CoolerDoubleDoor31x55x27TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_l.R270.png';
import CoolerDoubleDoor31x55x27TopdownR from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_r.png';
import CoolerDoubleDoor31x55x27TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_r.R90.png';
import CoolerDoubleDoor31x55x27TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_r.R180.png';
import CoolerDoubleDoor31x55x27TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor31x55x27/topdown_r.R270.png';

// CoolerDoubleDoor36x55x22

import CoolerDoubleDoor36x55x22Front from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/front.png';
import CoolerDoubleDoor36x55x22TopdownL from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_l.png';
import CoolerDoubleDoor36x55x22TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_l.R90.png';
import CoolerDoubleDoor36x55x22TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_l.R180.png';
import CoolerDoubleDoor36x55x22TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_l.R270.png';
import CoolerDoubleDoor36x55x22TopdownR from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_r.png';
import CoolerDoubleDoor36x55x22TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_r.R90.png';
import CoolerDoubleDoor36x55x22TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_r.R180.png';
import CoolerDoubleDoor36x55x22TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor36x55x22/topdown_r.R270.png';

// CoolerDoubleDoor37x60x23

import CoolerDoubleDoor37x60x23Front from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/front.png';
import CoolerDoubleDoor37x60x23TopdownL from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_l.png';
import CoolerDoubleDoor37x60x23TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_l.R90.png';
import CoolerDoubleDoor37x60x23TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_l.R180.png';
import CoolerDoubleDoor37x60x23TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_l.R270.png';
import CoolerDoubleDoor37x60x23TopdownR from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_r.png';
import CoolerDoubleDoor37x60x23TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_r.R90.png';
import CoolerDoubleDoor37x60x23TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_r.R180.png';
import CoolerDoubleDoor37x60x23TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor37x60x23/topdown_r.R270.png';

// CoolerDoubleDoor47x48x21

import CoolerDoubleDoor47x48x21Front from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/front.png';
import CoolerDoubleDoor47x48x21TopdownL from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_l.png';
import CoolerDoubleDoor47x48x21TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_l.R90.png';
import CoolerDoubleDoor47x48x21TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_l.R180.png';
import CoolerDoubleDoor47x48x21TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_l.R270.png';
import CoolerDoubleDoor47x48x21TopdownR from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_r.png';
import CoolerDoubleDoor47x48x21TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_r.R90.png';
import CoolerDoubleDoor47x48x21TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_r.R180.png';
import CoolerDoubleDoor47x48x21TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor47x48x21/topdown_r.R270.png';

// CoolerDoubleDoor48x48x24

import CoolerDoubleDoor48x48x24Front from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/front.png';
import CoolerDoubleDoor48x48x24TopdownL from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_l.png';
import CoolerDoubleDoor48x48x24TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_l.R90.png';
import CoolerDoubleDoor48x48x24TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_l.R180.png';
import CoolerDoubleDoor48x48x24TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_l.R270.png';
import CoolerDoubleDoor48x48x24TopdownR from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_r.png';
import CoolerDoubleDoor48x48x24TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_r.R90.png';
import CoolerDoubleDoor48x48x24TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_r.R180.png';
import CoolerDoubleDoor48x48x24TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor48x48x24/topdown_r.R270.png';

// CoolerDoubleDoor49x60x22

import CoolerDoubleDoor49x60x22Front from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/front.png';
import CoolerDoubleDoor49x60x22TopdownL from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_l.png';
import CoolerDoubleDoor49x60x22TopdownLR90 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_l.R90.png';
import CoolerDoubleDoor49x60x22TopdownLR180 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_l.R180.png';
import CoolerDoubleDoor49x60x22TopdownLR270 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_l.R270.png';
import CoolerDoubleDoor49x60x22TopdownR from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_r.png';
import CoolerDoubleDoor49x60x22TopdownRR90 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_r.R90.png';
import CoolerDoubleDoor49x60x22TopdownRR180 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_r.R180.png';
import CoolerDoubleDoor49x60x22TopdownRR270 from '../../../assets/fixtures/CoolerDoubleDoor49x60x22/topdown_r.R270.png';

// CoolerSingleDoor24x48x24

import CoolerSingleDoor24x48x24Front from '../../../assets/fixtures/CoolerSingleDoor24x48x24/front.png';
import CoolerSingleDoor24x48x24TopdownL from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_l.png';
import CoolerSingleDoor24x48x24TopdownLR90 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_l.R90.png';
import CoolerSingleDoor24x48x24TopdownLR180 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_l.R180.png';
import CoolerSingleDoor24x48x24TopdownLR270 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_l.R270.png';
import CoolerSingleDoor24x48x24TopdownR from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_r.png';
import CoolerSingleDoor24x48x24TopdownRR90 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_r.R90.png';
import CoolerSingleDoor24x48x24TopdownRR180 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_r.R180.png';
import CoolerSingleDoor24x48x24TopdownRR270 from '../../../assets/fixtures/CoolerSingleDoor24x48x24/topdown_r.R270.png';

// CoolerSingleDoor25x58x24

import CoolerSingleDoor25x58x24Front from '../../../assets/fixtures/CoolerSingleDoor25x58x24/front.png';
import CoolerSingleDoor25x58x24TopdownL from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_l.png';
import CoolerSingleDoor25x58x24TopdownLR90 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_l.R90.png';
import CoolerSingleDoor25x58x24TopdownLR180 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_l.R180.png';
import CoolerSingleDoor25x58x24TopdownLR270 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_l.R270.png';
import CoolerSingleDoor25x58x24TopdownR from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_r.png';
import CoolerSingleDoor25x58x24TopdownRR90 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_r.R90.png';
import CoolerSingleDoor25x58x24TopdownRR180 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_r.R180.png';
import CoolerSingleDoor25x58x24TopdownRR270 from '../../../assets/fixtures/CoolerSingleDoor25x58x24/topdown_r.R270.png';

// QueueSlimDS36

import QueueSlimDS36Front from '../../../assets/fixtures/QueueSlimDS36/front.png';
import QueueSlimDS36TopdownL from '../../../assets/fixtures/QueueSlimDS36/topdown_l.png';
import QueueSlimDS36TopdownLR90 from '../../../assets/fixtures/QueueSlimDS36/topdown_l.R90.png';
import QueueSlimDS36TopdownLR180 from '../../../assets/fixtures/QueueSlimDS36/topdown_l.R180.png';
import QueueSlimDS36TopdownLR270 from '../../../assets/fixtures/QueueSlimDS36/topdown_l.R270.png';
import QueueSlimDS36TopdownR from '../../../assets/fixtures/QueueSlimDS36/topdown_r.png';
import QueueSlimDS36TopdownRR90 from '../../../assets/fixtures/QueueSlimDS36/topdown_r.R90.png';
import QueueSlimDS36TopdownRR180 from '../../../assets/fixtures/QueueSlimDS36/topdown_r.R180.png';
import QueueSlimDS36TopdownRR270 from '../../../assets/fixtures/QueueSlimDS36/topdown_r.R270.png';

// QueueSlimDS72

import QueueSlimDS72Front from '../../../assets/fixtures/QueueSlimDS72/front.png';
import QueueSlimDS72TopdownL from '../../../assets/fixtures/QueueSlimDS72/topdown_l.png';
import QueueSlimDS72TopdownLR90 from '../../../assets/fixtures/QueueSlimDS72/topdown_l.R90.png';
import QueueSlimDS72TopdownLR180 from '../../../assets/fixtures/QueueSlimDS72/topdown_l.R180.png';
import QueueSlimDS72TopdownLR270 from '../../../assets/fixtures/QueueSlimDS72/topdown_l.R270.png';
import QueueSlimDS72TopdownR from '../../../assets/fixtures/QueueSlimDS72/topdown_r.png';
import QueueSlimDS72TopdownRR90 from '../../../assets/fixtures/QueueSlimDS72/topdown_r.R90.png';
import QueueSlimDS72TopdownRR180 from '../../../assets/fixtures/QueueSlimDS72/topdown_r.R180.png';
import QueueSlimDS72TopdownRR270 from '../../../assets/fixtures/QueueSlimDS72/topdown_r.R270.png';

// QueueSlimDSL1WithBasket36

import QueueSlimDSL1WithBasket36Front from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/front.png';
import QueueSlimDSL1WithBasket36TopdownL from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_l.png';
import QueueSlimDSL1WithBasket36TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_l.R90.png';
import QueueSlimDSL1WithBasket36TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_l.R180.png';
import QueueSlimDSL1WithBasket36TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_l.R270.png';
import QueueSlimDSL1WithBasket36TopdownR from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_r.png';
import QueueSlimDSL1WithBasket36TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_r.R90.png';
import QueueSlimDSL1WithBasket36TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_r.R180.png';
import QueueSlimDSL1WithBasket36TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL1WithBasket36/topdown_r.R270.png';

// QueueSlimDSL1WithBasket72

import QueueSlimDSL1WithBasket72Front from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/front.png';
import QueueSlimDSL1WithBasket72TopdownL from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_l.png';
import QueueSlimDSL1WithBasket72TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_l.R90.png';
import QueueSlimDSL1WithBasket72TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_l.R180.png';
import QueueSlimDSL1WithBasket72TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_l.R270.png';
import QueueSlimDSL1WithBasket72TopdownR from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_r.png';
import QueueSlimDSL1WithBasket72TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_r.R90.png';
import QueueSlimDSL1WithBasket72TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_r.R180.png';
import QueueSlimDSL1WithBasket72TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL1WithBasket72/topdown_r.R270.png';

// QueueSlimDSL2WithBasket72

import QueueSlimDSL2WithBasket72Front from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/front.png';
import QueueSlimDSL2WithBasket72TopdownL from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_l.png';
import QueueSlimDSL2WithBasket72TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_l.R90.png';
import QueueSlimDSL2WithBasket72TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_l.R180.png';
import QueueSlimDSL2WithBasket72TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_l.R270.png';
import QueueSlimDSL2WithBasket72TopdownR from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_r.png';
import QueueSlimDSL2WithBasket72TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_r.R90.png';
import QueueSlimDSL2WithBasket72TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_r.R180.png';
import QueueSlimDSL2WithBasket72TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL2WithBasket72/topdown_r.R270.png';

// QueueSlimDSL136

import QueueSlimDSL136Front from '../../../assets/fixtures/QueueSlimDSL136/front.png';
import QueueSlimDSL136TopdownL from '../../../assets/fixtures/QueueSlimDSL136/topdown_l.png';
import QueueSlimDSL136TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL136/topdown_l.R90.png';
import QueueSlimDSL136TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL136/topdown_l.R180.png';
import QueueSlimDSL136TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL136/topdown_l.R270.png';
import QueueSlimDSL136TopdownR from '../../../assets/fixtures/QueueSlimDSL136/topdown_r.png';
import QueueSlimDSL136TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL136/topdown_r.R90.png';
import QueueSlimDSL136TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL136/topdown_r.R180.png';
import QueueSlimDSL136TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL136/topdown_r.R270.png';

// QueueSlimDSL172

import QueueSlimDSL172Front from '../../../assets/fixtures/QueueSlimDSL172/front.png';
import QueueSlimDSL172TopdownL from '../../../assets/fixtures/QueueSlimDSL172/topdown_l.png';
import QueueSlimDSL172TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL172/topdown_l.R90.png';
import QueueSlimDSL172TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL172/topdown_l.R180.png';
import QueueSlimDSL172TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL172/topdown_l.R270.png';
import QueueSlimDSL172TopdownR from '../../../assets/fixtures/QueueSlimDSL172/topdown_r.png';
import QueueSlimDSL172TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL172/topdown_r.R90.png';
import QueueSlimDSL172TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL172/topdown_r.R180.png';
import QueueSlimDSL172TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL172/topdown_r.R270.png';

// QueueSlimDSL272

import QueueSlimDSL272Front from '../../../assets/fixtures/QueueSlimDSL272/front.png';
import QueueSlimDSL272TopdownL from '../../../assets/fixtures/QueueSlimDSL272/topdown_l.png';
import QueueSlimDSL272TopdownLR90 from '../../../assets/fixtures/QueueSlimDSL272/topdown_l.R90.png';
import QueueSlimDSL272TopdownLR180 from '../../../assets/fixtures/QueueSlimDSL272/topdown_l.R180.png';
import QueueSlimDSL272TopdownLR270 from '../../../assets/fixtures/QueueSlimDSL272/topdown_l.R270.png';
import QueueSlimDSL272TopdownR from '../../../assets/fixtures/QueueSlimDSL272/topdown_r.png';
import QueueSlimDSL272TopdownRR90 from '../../../assets/fixtures/QueueSlimDSL272/topdown_r.R90.png';
import QueueSlimDSL272TopdownRR180 from '../../../assets/fixtures/QueueSlimDSL272/topdown_r.R180.png';
import QueueSlimDSL272TopdownRR270 from '../../../assets/fixtures/QueueSlimDSL272/topdown_r.R270.png';

// QueueSlimDSWithBasket36

import QueueSlimDSWithBasket36Front from '../../../assets/fixtures/QueueSlimDSWithBasket36/front.png';
import QueueSlimDSWithBasket36TopdownL from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_l.png';
import QueueSlimDSWithBasket36TopdownLR90 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_l.R90.png';
import QueueSlimDSWithBasket36TopdownLR180 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_l.R180.png';
import QueueSlimDSWithBasket36TopdownLR270 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_l.R270.png';
import QueueSlimDSWithBasket36TopdownR from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_r.png';
import QueueSlimDSWithBasket36TopdownRR90 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_r.R90.png';
import QueueSlimDSWithBasket36TopdownRR180 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_r.R180.png';
import QueueSlimDSWithBasket36TopdownRR270 from '../../../assets/fixtures/QueueSlimDSWithBasket36/topdown_r.R270.png';

// QueueSlimDSWithBasket72

import QueueSlimDSWithBasket72Front from '../../../assets/fixtures/QueueSlimDSWithBasket72/front.png';
import QueueSlimDSWithBasket72TopdownL from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_l.png';
import QueueSlimDSWithBasket72TopdownLR90 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_l.R90.png';
import QueueSlimDSWithBasket72TopdownLR180 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_l.R180.png';
import QueueSlimDSWithBasket72TopdownLR270 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_l.R270.png';
import QueueSlimDSWithBasket72TopdownR from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_r.png';
import QueueSlimDSWithBasket72TopdownRR90 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_r.R90.png';
import QueueSlimDSWithBasket72TopdownRR180 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_r.R180.png';
import QueueSlimDSWithBasket72TopdownRR270 from '../../../assets/fixtures/QueueSlimDSWithBasket72/topdown_r.R270.png';

// QueueLineDoubleSided24

import QueueLineDoubleSided24Front from '../../../assets/fixtures/QueueLineDoubleSided/24/front.png';
import QueueLineDoubleSided24TopdownL from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_l.png';
import QueueLineDoubleSided24TopdownLR90 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_l.R90.png';
import QueueLineDoubleSided24TopdownLR180 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_l.R180.png';
import QueueLineDoubleSided24TopdownLR270 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_l.R270.png';
import QueueLineDoubleSided24TopdownR from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_r.png';
import QueueLineDoubleSided24TopdownRR90 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_r.R90.png';
import QueueLineDoubleSided24TopdownRR180 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_r.R180.png';
import QueueLineDoubleSided24TopdownRR270 from '../../../assets/fixtures/QueueLineDoubleSided/24/topdown_r.R270.png';

// QueueLineDoubleSided30

import QueueLineDoubleSided30Front from '../../../assets/fixtures/QueueLineDoubleSided/30/front.png';
import QueueLineDoubleSided30TopdownL from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_l.png';
import QueueLineDoubleSided30TopdownLR90 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_l.R90.png';
import QueueLineDoubleSided30TopdownLR180 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_l.R180.png';
import QueueLineDoubleSided30TopdownLR270 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_l.R270.png';
import QueueLineDoubleSided30TopdownR from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_r.png';
import QueueLineDoubleSided30TopdownRR90 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_r.R90.png';
import QueueLineDoubleSided30TopdownRR180 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_r.R180.png';
import QueueLineDoubleSided30TopdownRR270 from '../../../assets/fixtures/QueueLineDoubleSided/30/topdown_r.R270.png';

// QueueLineDoubleSided36

import QueueLineDoubleSided36Front from '../../../assets/fixtures/QueueLineDoubleSided/36/front.png';
import QueueLineDoubleSided36TopdownL from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_l.png';
import QueueLineDoubleSided36TopdownLR90 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_l.R90.png';
import QueueLineDoubleSided36TopdownLR180 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_l.R180.png';
import QueueLineDoubleSided36TopdownLR270 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_l.R270.png';
import QueueLineDoubleSided36TopdownR from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_r.png';
import QueueLineDoubleSided36TopdownRR90 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_r.R90.png';
import QueueLineDoubleSided36TopdownRR180 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_r.R180.png';
import QueueLineDoubleSided36TopdownRR270 from '../../../assets/fixtures/QueueLineDoubleSided/36/topdown_r.R270.png';

// QueueLineDoubleSided48

import QueueLineDoubleSided48Front from '../../../assets/fixtures/QueueLineDoubleSided/48/front.png';
import QueueLineDoubleSided48TopdownL from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_l.png';
import QueueLineDoubleSided48TopdownLR90 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_l.R90.png';
import QueueLineDoubleSided48TopdownLR180 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_l.R180.png';
import QueueLineDoubleSided48TopdownLR270 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_l.R270.png';
import QueueLineDoubleSided48TopdownR from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_r.png';
import QueueLineDoubleSided48TopdownRR90 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_r.R90.png';
import QueueLineDoubleSided48TopdownRR180 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_r.R180.png';
import QueueLineDoubleSided48TopdownRR270 from '../../../assets/fixtures/QueueLineDoubleSided/48/topdown_r.R270.png';

// QueueLine20236

import QueueLine20236Front from '../../../assets/fixtures/QueueLine20236/front.png';
import QueueLine20236TopdownL from '../../../assets/fixtures/QueueLine20236/topdown_l.png';
import QueueLine20236TopdownLR90 from '../../../assets/fixtures/QueueLine20236/topdown_l.R90.png';
import QueueLine20236TopdownLR180 from '../../../assets/fixtures/QueueLine20236/topdown_l.R180.png';
import QueueLine20236TopdownLR270 from '../../../assets/fixtures/QueueLine20236/topdown_l.R270.png';
import QueueLine20236TopdownR from '../../../assets/fixtures/QueueLine20236/topdown_r.png';
import QueueLine20236TopdownRR90 from '../../../assets/fixtures/QueueLine20236/topdown_r.R90.png';
import QueueLine20236TopdownRR180 from '../../../assets/fixtures/QueueLine20236/topdown_r.R180.png';
import QueueLine20236TopdownRR270 from '../../../assets/fixtures/QueueLine20236/topdown_r.R270.png';

// QueueLine20237

import QueueLine20237Front from '../../../assets/fixtures/QueueLine20237/front.png';
import QueueLine20237TopdownL from '../../../assets/fixtures/QueueLine20237/topdown_l.png';
import QueueLine20237TopdownLR90 from '../../../assets/fixtures/QueueLine20237/topdown_l.R90.png';
import QueueLine20237TopdownLR180 from '../../../assets/fixtures/QueueLine20237/topdown_l.R180.png';
import QueueLine20237TopdownLR270 from '../../../assets/fixtures/QueueLine20237/topdown_l.R270.png';
import QueueLine20237TopdownR from '../../../assets/fixtures/QueueLine20237/topdown_r.png';
import QueueLine20237TopdownRR90 from '../../../assets/fixtures/QueueLine20237/topdown_r.R90.png';
import QueueLine20237TopdownRR180 from '../../../assets/fixtures/QueueLine20237/topdown_r.R180.png';
import QueueLine20237TopdownRR270 from '../../../assets/fixtures/QueueLine20237/topdown_r.R270.png';

// QueueLine20238

import QueueLine20238Front from '../../../assets/fixtures/QueueLine20238/front.png';
import QueueLine20238TopdownL from '../../../assets/fixtures/QueueLine20238/topdown_l.png';
import QueueLine20238TopdownLR90 from '../../../assets/fixtures/QueueLine20238/topdown_l.R90.png';
import QueueLine20238TopdownLR180 from '../../../assets/fixtures/QueueLine20238/topdown_l.R180.png';
import QueueLine20238TopdownLR270 from '../../../assets/fixtures/QueueLine20238/topdown_l.R270.png';
import QueueLine20238TopdownR from '../../../assets/fixtures/QueueLine20238/topdown_r.png';
import QueueLine20238TopdownRR90 from '../../../assets/fixtures/QueueLine20238/topdown_r.R90.png';
import QueueLine20238TopdownRR180 from '../../../assets/fixtures/QueueLine20238/topdown_r.R180.png';
import QueueLine20238TopdownRR270 from '../../../assets/fixtures/QueueLine20238/topdown_r.R270.png';

// QueueLine20239

import QueueLine20239Front from '../../../assets/fixtures/QueueLine20239/front.png';
import QueueLine20239TopdownL from '../../../assets/fixtures/QueueLine20239/topdown_l.png';
import QueueLine20239TopdownLR90 from '../../../assets/fixtures/QueueLine20239/topdown_l.R90.png';
import QueueLine20239TopdownLR180 from '../../../assets/fixtures/QueueLine20239/topdown_l.R180.png';
import QueueLine20239TopdownLR270 from '../../../assets/fixtures/QueueLine20239/topdown_l.R270.png';
import QueueLine20239TopdownR from '../../../assets/fixtures/QueueLine20239/topdown_r.png';
import QueueLine20239TopdownRR90 from '../../../assets/fixtures/QueueLine20239/topdown_r.R90.png';
import QueueLine20239TopdownRR180 from '../../../assets/fixtures/QueueLine20239/topdown_r.R180.png';
import QueueLine20239TopdownRR270 from '../../../assets/fixtures/QueueLine20239/topdown_r.R270.png';

// QueueLine20240

import QueueLine20240Front from '../../../assets/fixtures/QueueLine20240/front.png';
import QueueLine20240TopdownL from '../../../assets/fixtures/QueueLine20240/topdown_l.png';
import QueueLine20240TopdownLR90 from '../../../assets/fixtures/QueueLine20240/topdown_l.R90.png';
import QueueLine20240TopdownLR180 from '../../../assets/fixtures/QueueLine20240/topdown_l.R180.png';
import QueueLine20240TopdownLR270 from '../../../assets/fixtures/QueueLine20240/topdown_l.R270.png';
import QueueLine20240TopdownR from '../../../assets/fixtures/QueueLine20240/topdown_r.png';
import QueueLine20240TopdownRR90 from '../../../assets/fixtures/QueueLine20240/topdown_r.R90.png';
import QueueLine20240TopdownRR180 from '../../../assets/fixtures/QueueLine20240/topdown_r.R180.png';
import QueueLine20240TopdownRR270 from '../../../assets/fixtures/QueueLine20240/topdown_r.R270.png';

// QueueLine20241

import QueueLine20241Front from '../../../assets/fixtures/QueueLine20241/front.png';
import QueueLine20241TopdownL from '../../../assets/fixtures/QueueLine20241/topdown_l.png';
import QueueLine20241TopdownLR90 from '../../../assets/fixtures/QueueLine20241/topdown_l.R90.png';
import QueueLine20241TopdownLR180 from '../../../assets/fixtures/QueueLine20241/topdown_l.R180.png';
import QueueLine20241TopdownLR270 from '../../../assets/fixtures/QueueLine20241/topdown_l.R270.png';
import QueueLine20241TopdownR from '../../../assets/fixtures/QueueLine20241/topdown_r.png';
import QueueLine20241TopdownRR90 from '../../../assets/fixtures/QueueLine20241/topdown_r.R90.png';
import QueueLine20241TopdownRR180 from '../../../assets/fixtures/QueueLine20241/topdown_r.R180.png';
import QueueLine20241TopdownRR270 from '../../../assets/fixtures/QueueLine20241/topdown_r.R270.png';

// QueueLine20242

import QueueLine20242Front from '../../../assets/fixtures/QueueLine20242/front.png';
import QueueLine20242TopdownL from '../../../assets/fixtures/QueueLine20242/topdown_l.png';
import QueueLine20242TopdownLR90 from '../../../assets/fixtures/QueueLine20242/topdown_l.R90.png';
import QueueLine20242TopdownLR180 from '../../../assets/fixtures/QueueLine20242/topdown_l.R180.png';
import QueueLine20242TopdownLR270 from '../../../assets/fixtures/QueueLine20242/topdown_l.R270.png';
import QueueLine20242TopdownR from '../../../assets/fixtures/QueueLine20242/topdown_r.png';
import QueueLine20242TopdownRR90 from '../../../assets/fixtures/QueueLine20242/topdown_r.R90.png';
import QueueLine20242TopdownRR180 from '../../../assets/fixtures/QueueLine20242/topdown_r.R180.png';
import QueueLine20242TopdownRR270 from '../../../assets/fixtures/QueueLine20242/topdown_r.R270.png';

// QueueLine20243

import QueueLine20243Front from '../../../assets/fixtures/QueueLine20243/front.png';
import QueueLine20243TopdownL from '../../../assets/fixtures/QueueLine20243/topdown_l.png';
import QueueLine20243TopdownLR90 from '../../../assets/fixtures/QueueLine20243/topdown_l.R90.png';
import QueueLine20243TopdownLR180 from '../../../assets/fixtures/QueueLine20243/topdown_l.R180.png';
import QueueLine20243TopdownLR270 from '../../../assets/fixtures/QueueLine20243/topdown_l.R270.png';
import QueueLine20243TopdownR from '../../../assets/fixtures/QueueLine20243/topdown_r.png';
import QueueLine20243TopdownRR90 from '../../../assets/fixtures/QueueLine20243/topdown_r.R90.png';
import QueueLine20243TopdownRR180 from '../../../assets/fixtures/QueueLine20243/topdown_r.R180.png';
import QueueLine20243TopdownRR270 from '../../../assets/fixtures/QueueLine20243/topdown_r.R270.png';

// SCONCR6NoBagging

import SCONCR6NoBaggingFront from '../../../assets/fixtures/SCONCR6NoBagging/front.png';
import SCONCR6NoBaggingTopdownL from '../../../assets/fixtures/SCONCR6NoBagging/topdown_l.png';
import SCONCR6NoBaggingTopdownLR90 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_l.R90.png';
import SCONCR6NoBaggingTopdownLR180 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_l.R180.png';
import SCONCR6NoBaggingTopdownLR270 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_l.R270.png';
import SCONCR6NoBaggingTopdownR from '../../../assets/fixtures/SCONCR6NoBagging/topdown_r.png';
import SCONCR6NoBaggingTopdownRR90 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_r.R90.png';
import SCONCR6NoBaggingTopdownRR180 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_r.R180.png';
import SCONCR6NoBaggingTopdownRR270 from '../../../assets/fixtures/SCONCR6NoBagging/topdown_r.R270.png';

// SCONCRSelfServ90

import SCONCRSelfServ90Front from '../../../assets/fixtures/SCONCRSelfServ90/front.png';
import SCONCRSelfServ90TopdownL from '../../../assets/fixtures/SCONCRSelfServ90/topdown_l.png';
import SCONCRSelfServ90TopdownLR90 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_l.R90.png';
import SCONCRSelfServ90TopdownLR180 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_l.R180.png';
import SCONCRSelfServ90TopdownLR270 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_l.R270.png';
import SCONCRSelfServ90TopdownR from '../../../assets/fixtures/SCONCRSelfServ90/topdown_r.png';
import SCONCRSelfServ90TopdownRR90 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_r.R90.png';
import SCONCRSelfServ90TopdownRR180 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_r.R180.png';
import SCONCRSelfServ90TopdownRR270 from '../../../assets/fixtures/SCONCRSelfServ90/topdown_r.R270.png';
// CStoreNoSelfCheckoutCounterCounterDefault
import CStoreNoSelfCheckoutCounterDefaultFront from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterDefault/front.png';
import CStoreNoSelfCheckoutCounterDefaultTopdownL from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterDefault/topdown_l.png';
import CStoreNoSelfCheckoutCounterDefaultTopdownR from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterDefault/topdown_r.png';

// CStoreNoSelfCheckoutCounterCounterPreset1
import CStoreNoSelfCheckoutCounterPreset1Front from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset1/front.png';
import CStoreNoSelfCheckoutCounterPreset1TopdownL from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset1/topdown_l.png';
import CStoreNoSelfCheckoutCounterPreset1TopdownR from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset1/topdown_r.png';

// CStoreNoSelfCheckoutCounterCounterPreset2
import CStoreNoSelfCheckoutCounterPreset2Front from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset2/front.png';
import CStoreNoSelfCheckoutCounterPreset2TopdownL from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset2/topdown_l.png';
import CStoreNoSelfCheckoutCounterPreset2TopdownR from '../../../assets/fixtures/CStoreNoSelfCheckoutCounterPreset2/topdown_r.png';

// CStoreCounterDefault
import CStoreCounterDefaultFront from '../../../assets/fixtures/CStoreCounterDefault/front.png';
import CStoreCounterDefaultTopdownL from '../../../assets/fixtures/CStoreCounterDefault/topdown_l.png';
import CStoreCounterDefaultTopdownR from '../../../assets/fixtures/CStoreCounterDefault/topdown_r.png';

// CStoreCounterPreset1
import CStoreCounterPreset1Front from '../../../assets/fixtures/CStoreCounterPreset1/front.png';
import CStoreCounterPreset1TopdownL from '../../../assets/fixtures/CStoreCounterPreset1/topdown_l.png';
import CStoreCounterPreset1TopdownR from '../../../assets/fixtures/CStoreCounterPreset1/topdown_r.png';

// CStoreCounterPreset2
import CStoreCounterPreset2Front from '../../../assets/fixtures/CStoreCounterPreset2/front.png';
import CStoreCounterPreset2TopdownL from '../../../assets/fixtures/CStoreCounterPreset2/topdown_l.png';
import CStoreCounterPreset2TopdownR from '../../../assets/fixtures/CStoreCounterPreset2/topdown_r.png';

// CStoreLCounterDefault
import CStoreLCounterDefaultFront from '../../../assets/fixtures/CStoreLCounterDefault/front.png';
import CStoreLCounterDefaultTopdownL from '../../../assets/fixtures/CStoreLCounterDefault/topdown_l.png';
import CStoreLCounterDefaultTopdownR from '../../../assets/fixtures/CStoreLCounterDefault/topdown_r.png';

// CStoreLCounterPreset3
import CStoreLCounterPreset3Front from '../../../assets/fixtures/CStoreLCounterPreset3/front.png';
import CStoreLCounterPreset3TopdownL from '../../../assets/fixtures/CStoreLCounterPreset3/topdown_l.png';
import CStoreLCounterPreset3TopdownR from '../../../assets/fixtures/CStoreLCounterPreset3/topdown_r.png';

// DisplayShelvingWithSides
// Shelving24
import DisplayShelvingWithSidesShelving24Front from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/front.png';
import DisplayShelvingWithSidesShelving24TopDownL from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_l.png';
import DisplayShelvingWithSidesShelving24TopDownR from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_r.png';
import DisplayShelvingWithSidesShelving24TopDownLR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_l.R90.png';
import DisplayShelvingWithSidesShelving24TopDownLR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_l.R180.png';
import DisplayShelvingWithSidesShelving24TopDownLR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_l.R270.png';
import DisplayShelvingWithSidesShelving24TopDownRR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_r.R90.png';
import DisplayShelvingWithSidesShelving24TopDownRR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_r.R180.png';
import DisplayShelvingWithSidesShelving24TopDownRR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving24/topdown_r.R270.png';

// Shelving36

import DisplayShelvingWithSidesShelving36Front from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/front.png';
import DisplayShelvingWithSidesShelving36TopDownL from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_l.png';
import DisplayShelvingWithSidesShelving36TopDownR from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_r.png';
import DisplayShelvingWithSidesShelving36TopDownLR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_l.R90.png';
import DisplayShelvingWithSidesShelving36TopDownLR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_l.R180.png';
import DisplayShelvingWithSidesShelving36TopDownLR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_l.R270.png';
import DisplayShelvingWithSidesShelving36TopDownRR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_r.R90.png';
import DisplayShelvingWithSidesShelving36TopDownRR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_r.R180.png';
import DisplayShelvingWithSidesShelving36TopDownRR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving36/topdown_r.R270.png';

// Shelving48

import DisplayShelvingWithSidesShelving48Front from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/front.png';
import DisplayShelvingWithSidesShelving48TopDownL from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_l.png';
import DisplayShelvingWithSidesShelving48TopDownR from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_r.png';
import DisplayShelvingWithSidesShelving48TopDownLR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_l.R90.png';
import DisplayShelvingWithSidesShelving48TopDownLR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_l.R180.png';
import DisplayShelvingWithSidesShelving48TopDownLR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_l.R270.png';
import DisplayShelvingWithSidesShelving48TopDownRR90 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_r.R90.png';
import DisplayShelvingWithSidesShelving48TopDownRR180 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_r.R180.png';
import DisplayShelvingWithSidesShelving48TopDownRR270 from '../../../assets/fixtures/DisplayShelvingWithSides/Shelving48/topdown_r.R270.png';

// FloorDisplays
// CellphoneCables

import FloorDisplaysCellphoneCablesFront from '../../../assets/fixtures/FloorDisplays/CellphoneCables/front.png';
import FloorDisplaysCellphoneCablesTopDownL from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_l.png';
import FloorDisplaysCellphoneCablesTopDownR from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_r.png';
import FloorDisplaysCellphoneCablesTopDownLR90 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_l.R90.png';
import FloorDisplaysCellphoneCablesTopDownLR180 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_l.R180.png';
import FloorDisplaysCellphoneCablesTopDownLR270 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_l.R270.png';
import FloorDisplaysCellphoneCablesTopDownRR90 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_r.R90.png';
import FloorDisplaysCellphoneCablesTopDownRR180 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_r.R180.png';
import FloorDisplaysCellphoneCablesTopDownRR270 from '../../../assets/fixtures/FloorDisplays/CellphoneCables/topdown_r.R270.png';

// MerchandisingTower

import FloorDisplaysMerchandisingTowerFront from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/front.png';
import FloorDisplaysMerchandisingTowerTopDownL from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_l.png';
import FloorDisplaysMerchandisingTowerTopDownR from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_r.png';
import FloorDisplaysMerchandisingTowerTopDownLR90 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_l.R90.png';
import FloorDisplaysMerchandisingTowerTopDownLR180 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_l.R180.png';
import FloorDisplaysMerchandisingTowerTopDownLR270 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_l.R270.png';
import FloorDisplaysMerchandisingTowerTopDownRR90 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_r.R90.png';
import FloorDisplaysMerchandisingTowerTopDownRR180 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_r.R180.png';
import FloorDisplaysMerchandisingTowerTopDownRR270 from '../../../assets/fixtures/FloorDisplays/MerchandisingTower/topdown_r.R270.png';

// MerchandisingTowerNonShoppable

import FloorDisplaysMerchandisingTowerNonShoppableFront from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/front.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownL from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_l.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownR from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_r.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownLR90 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_l.R90.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownLR180 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_l.R180.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownLR270 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_l.R270.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownRR90 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_r.R90.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownRR180 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_r.R180.png';
import FloorDisplaysMerchandisingTowerNonShoppableTopDownRR270 from '../../../assets/fixtures/FloorDisplays/MerchandisingTowerNonShoppable/topdown_r.R270.png';

import LowesApplianceDividerFront from '../../../assets/fixtures/LowesApplianceDivider/front.png';
import LowesApplianceDividerTopDownL from '../../../assets/fixtures/LowesApplianceDivider/topdown_l.png';
import LowesApplianceDividerTopDownR from '../../../assets/fixtures/LowesApplianceDivider/topdown_r.png';
import LowesApplianceDividerTopDownLR90 from '../../../assets/fixtures/LowesApplianceDivider/topdown_l.R90.png';
import LowesApplianceDividerTopDownLR180 from '../../../assets/fixtures/LowesApplianceDivider/topdown_l.R180.png';
import LowesApplianceDividerTopDownLR270 from '../../../assets/fixtures/LowesApplianceDivider/topdown_l.R270.png';
import LowesApplianceDividerTopDownRR90 from '../../../assets/fixtures/LowesApplianceDivider/topdown_r.R90.png';
import LowesApplianceDividerTopDownRR180 from '../../../assets/fixtures/LowesApplianceDivider/topdown_r.R180.png';
import LowesApplianceDividerTopDownRR270 from '../../../assets/fixtures/LowesApplianceDivider/topdown_r.R270.png';

import LowesApplianceEndPanelFront from '../../../assets/fixtures/LowesApplianceEndPanel/front.png';
import LowesApplianceEndPanelTopDownL from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_l.png';
import LowesApplianceEndPanelTopDownR from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_r.png';
import LowesApplianceEndPanelTopDownLR90 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_l.R90.png';
import LowesApplianceEndPanelTopDownLR180 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_l.R180.png';
import LowesApplianceEndPanelTopDownLR270 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_l.R270.png';
import LowesApplianceEndPanelTopDownRR90 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_r.R90.png';
import LowesApplianceEndPanelTopDownRR180 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_r.R180.png';
import LowesApplianceEndPanelTopDownRR270 from '../../../assets/fixtures/LowesApplianceEndPanel/topdown_r.R270.png';

import LowesApplianceEndPanelSamsungFront from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/front.png';
import LowesApplianceEndPanelSamsungTopDownL from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_l.png';
import LowesApplianceEndPanelSamsungTopDownR from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_r.png';
import LowesApplianceEndPanelSamsungTopDownLR90 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_l.R90.png';
import LowesApplianceEndPanelSamsungTopDownLR180 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_l.R180.png';
import LowesApplianceEndPanelSamsungTopDownLR270 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_l.R270.png';
import LowesApplianceEndPanelSamsungTopDownRR90 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_r.R90.png';
import LowesApplianceEndPanelSamsungTopDownRR180 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_r.R180.png';
import LowesApplianceEndPanelSamsungTopDownRR270 from '../../../assets/fixtures/LowesApplianceEndPanelSamsung/topdown_r.R270.png';

import LowesAppliancePOSShelvingFront from '../../../assets/fixtures/LowesAppliancePOSShelving/front.png';
import LowesAppliancePOSShelvingTopDownL from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_l.png';
import LowesAppliancePOSShelvingTopDownR from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_r.png';
import LowesAppliancePOSShelvingTopDownLR90 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_l.R90.png';
import LowesAppliancePOSShelvingTopDownLR180 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_l.R180.png';
import LowesAppliancePOSShelvingTopDownLR270 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_l.R270.png';
import LowesAppliancePOSShelvingTopDownRR90 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_r.R90.png';
import LowesAppliancePOSShelvingTopDownRR180 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_r.R180.png';
import LowesAppliancePOSShelvingTopDownRR270 from '../../../assets/fixtures/LowesAppliancePOSShelving/topdown_r.R270.png';

import LargeBasket60DegreeLeftFront from '../../../assets/fixtures/LargeBasket60DegreeLeft/front.png';
import LargeBasket60DegreeLeftTopDownL from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_l.png';
import LargeBasket60DegreeLeftTopDownR from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_r.png';
import LargeBasket60DegreeLeftTopDownLR90 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_l.R90.png';
import LargeBasket60DegreeLeftTopDownLR180 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_l.R180.png';
import LargeBasket60DegreeLeftTopDownLR270 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_l.R270.png';
import LargeBasket60DegreeLeftTopDownRR90 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_r.R90.png';
import LargeBasket60DegreeLeftTopDownRR180 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_r.R180.png';
import LargeBasket60DegreeLeftTopDownRR270 from '../../../assets/fixtures/LargeBasket60DegreeLeft/topdown_r.R270.png';

import LargeBasket60DegreeRightFront from '../../../assets/fixtures/LargeBasket60DegreeRight/front.png';
import LargeBasket60DegreeRightTopDownL from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_l.png';
import LargeBasket60DegreeRightTopDownR from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_r.png';
import LargeBasket60DegreeRightTopDownLR90 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_l.R90.png';
import LargeBasket60DegreeRightTopDownLR180 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_l.R180.png';
import LargeBasket60DegreeRightTopDownLR270 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_l.R270.png';
import LargeBasket60DegreeRightTopDownRR90 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_r.R90.png';
import LargeBasket60DegreeRightTopDownRR180 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_r.R180.png';
import LargeBasket60DegreeRightTopDownRR270 from '../../../assets/fixtures/LargeBasket60DegreeRight/topdown_r.R270.png';

import RackingOTB30LeftFront from '../../../assets/fixtures/RackingOTB30Left/front.png';
import RackingOTB30LeftTopDownL from '../../../assets/fixtures/RackingOTB30Left/topdown_l.png';
import RackingOTB30LeftTopDownR from '../../../assets/fixtures/RackingOTB30Left/topdown_r.png';
import RackingOTB30LeftTopDownLR90 from '../../../assets/fixtures/RackingOTB30Left/topdown_l.R90.png';
import RackingOTB30LeftTopDownLR180 from '../../../assets/fixtures/RackingOTB30Left/topdown_l.R180.png';
import RackingOTB30LeftTopDownLR270 from '../../../assets/fixtures/RackingOTB30Left/topdown_l.R270.png';
import RackingOTB30LeftTopDownRR90 from '../../../assets/fixtures/RackingOTB30Left/topdown_r.R90.png';
import RackingOTB30LeftTopDownRR180 from '../../../assets/fixtures/RackingOTB30Left/topdown_r.R180.png';
import RackingOTB30LeftTopDownRR270 from '../../../assets/fixtures/RackingOTB30Left/topdown_r.R270.png';

import RackingOTB30RightFront from '../../../assets/fixtures/RackingOTB30Right/front.png';
import RackingOTB30RightTopDownL from '../../../assets/fixtures/RackingOTB30Right/topdown_l.png';
import RackingOTB30RightTopDownR from '../../../assets/fixtures/RackingOTB30Right/topdown_r.png';
import RackingOTB30RightTopDownLR90 from '../../../assets/fixtures/RackingOTB30Right/topdown_l.R90.png';
import RackingOTB30RightTopDownLR180 from '../../../assets/fixtures/RackingOTB30Right/topdown_l.R180.png';
import RackingOTB30RightTopDownLR270 from '../../../assets/fixtures/RackingOTB30Right/topdown_l.R270.png';
import RackingOTB30RightTopDownRR90 from '../../../assets/fixtures/RackingOTB30Right/topdown_r.R90.png';
import RackingOTB30RightTopDownRR180 from '../../../assets/fixtures/RackingOTB30Right/topdown_r.R180.png';
import RackingOTB30RightTopDownRR270 from '../../../assets/fixtures/RackingOTB30Right/topdown_r.R270.png';

import WingedEndcap30Front from '../../../assets/fixtures/WingedEndcap30/front.png';
import WingedEndcap30TopDownL from '../../../assets/fixtures/WingedEndcap30/topdown_l.png';
import WingedEndcap30TopDownR from '../../../assets/fixtures/WingedEndcap30/topdown_r.png';
import WingedEndcap30TopDownLR90 from '../../../assets/fixtures/WingedEndcap30/topdown_l.R90.png';
import WingedEndcap30TopDownLR180 from '../../../assets/fixtures/WingedEndcap30/topdown_l.R180.png';
import WingedEndcap30TopDownLR270 from '../../../assets/fixtures/WingedEndcap30/topdown_l.R270.png';
import WingedEndcap30TopDownRR90 from '../../../assets/fixtures/WingedEndcap30/topdown_r.R90.png';
import WingedEndcap30TopDownRR180 from '../../../assets/fixtures/WingedEndcap30/topdown_r.R180.png';
import WingedEndcap30TopDownRR270 from '../../../assets/fixtures/WingedEndcap30/topdown_r.R270.png';

import Endcap30Front from '../../../assets/fixtures/Endcap30/front.png';
import Endcap30TopDownL from '../../../assets/fixtures/Endcap30/topdown_l.png';
import Endcap30TopDownR from '../../../assets/fixtures/Endcap30/topdown_r.png';
import Endcap30TopDownLR90 from '../../../assets/fixtures/Endcap30/topdown_l.R90.png';
import Endcap30TopDownLR180 from '../../../assets/fixtures/Endcap30/topdown_l.R180.png';
import Endcap30TopDownLR270 from '../../../assets/fixtures/Endcap30/topdown_l.R270.png';
import Endcap30TopDownRR90 from '../../../assets/fixtures/Endcap30/topdown_r.R90.png';
import Endcap30TopDownRR180 from '../../../assets/fixtures/Endcap30/topdown_r.R180.png';
import Endcap30TopDownRR270 from '../../../assets/fixtures/Endcap30/topdown_r.R270.png';

import Racking30Front from '../../../assets/fixtures/Racking30/front.png';
import Racking30TopDownL from '../../../assets/fixtures/Racking30/topdown_l.png';
import Racking30TopDownR from '../../../assets/fixtures/Racking30/topdown_r.png';
import Racking30TopDownLR90 from '../../../assets/fixtures/Racking30/topdown_l.R90.png';
import Racking30TopDownLR180 from '../../../assets/fixtures/Racking30/topdown_l.R180.png';
import Racking30TopDownLR270 from '../../../assets/fixtures/Racking30/topdown_l.R270.png';
import Racking30TopDownRR90 from '../../../assets/fixtures/Racking30/topdown_r.R90.png';
import Racking30TopDownRR180 from '../../../assets/fixtures/Racking30/topdown_r.R180.png';
import Racking30TopDownRR270 from '../../../assets/fixtures/Racking30/topdown_r.R270.png';

// FrameStyleEndcap

import FrameStyleEndcapFront from '../../../assets/fixtures/FrameStyleEndcap/front.png';
import FrameStyleEndcapTopDownL from '../../../assets/fixtures/FrameStyleEndcap/topdown_l.png';
import FrameStyleEndcapTopDownR from '../../../assets/fixtures/FrameStyleEndcap/topdown_r.png';
import FrameStyleEndcapTopDownLR90 from '../../../assets/fixtures/FrameStyleEndcap/topdown_l.R90.png';
import FrameStyleEndcapTopDownLR180 from '../../../assets/fixtures/FrameStyleEndcap/topdown_l.R180.png';
import FrameStyleEndcapTopDownLR270 from '../../../assets/fixtures/FrameStyleEndcap/topdown_l.R270.png';
import FrameStyleEndcapTopDownRR90 from '../../../assets/fixtures/FrameStyleEndcap/topdown_r.R90.png';
import FrameStyleEndcapTopDownRR180 from '../../../assets/fixtures/FrameStyleEndcap/topdown_r.R180.png';
import FrameStyleEndcapTopDownRR270 from '../../../assets/fixtures/FrameStyleEndcap/topdown_r.R270.png';

import Panel15Front from '../../../assets/fixtures/Panel15/front.png';
import Panel15TopDownL from '../../../assets/fixtures/Panel15/topdown_l.png';
import Panel15TopDownR from '../../../assets/fixtures/Panel15/topdown_r.png';
import Panel15TopDownLR90 from '../../../assets/fixtures/Panel15/topdown_l.R90.png';
import Panel15TopDownLR180 from '../../../assets/fixtures/Panel15/topdown_l.R180.png';
import Panel15TopDownLR270 from '../../../assets/fixtures/Panel15/topdown_l.R270.png';
import Panel15TopDownRR90 from '../../../assets/fixtures/Panel15/topdown_r.R90.png';
import Panel15TopDownRR180 from '../../../assets/fixtures/Panel15/topdown_r.R180.png';
import Panel15TopDownRR270 from '../../../assets/fixtures/Panel15/topdown_r.R270.png';

import Panel30Front from '../../../assets/fixtures/Panel30/front.png';
import Panel30TopDownL from '../../../assets/fixtures/Panel30/topdown_l.png';
import Panel30TopDownR from '../../../assets/fixtures/Panel30/topdown_r.png';
import Panel30TopDownLR90 from '../../../assets/fixtures/Panel30/topdown_l.R90.png';
import Panel30TopDownLR180 from '../../../assets/fixtures/Panel30/topdown_l.R180.png';
import Panel30TopDownLR270 from '../../../assets/fixtures/Panel30/topdown_l.R270.png';
import Panel30TopDownRR90 from '../../../assets/fixtures/Panel30/topdown_r.R90.png';
import Panel30TopDownRR180 from '../../../assets/fixtures/Panel30/topdown_r.R180.png';
import Panel30TopDownRR270 from '../../../assets/fixtures/Panel30/topdown_r.R270.png';

import Gondola30Front from '../../../assets/fixtures/Gondola30/front.png';
import Gondola30TopDownL from '../../../assets/fixtures/Gondola30/topdown_l.png';
import Gondola30TopDownR from '../../../assets/fixtures/Gondola30/topdown_r.png';
import Gondola30TopDownLR90 from '../../../assets/fixtures/Gondola30/topdown_l.R90.png';
import Gondola30TopDownLR180 from '../../../assets/fixtures/Gondola30/topdown_l.R180.png';
import Gondola30TopDownLR270 from '../../../assets/fixtures/Gondola30/topdown_l.R270.png';
import Gondola30TopDownRR90 from '../../../assets/fixtures/Gondola30/topdown_r.R90.png';
import Gondola30TopDownRR180 from '../../../assets/fixtures/Gondola30/topdown_r.R180.png';
import Gondola30TopDownRR270 from '../../../assets/fixtures/Gondola30/topdown_r.R270.png';

export type FigureType =
  | 'fixture'
  | 'immobile-fixture' // cannot be moved but can be edited
  | 'static-fixture' // cannot be moved nor edited
  | 'shopper'
  | 'text'
  | 'measure-tool';

export interface FigureModel {
  key: string;
  id: number;
  name: string;
  som: string;
  w: number;
  d: number;
  h: number;
  type: FigureType;
  category: string;
  renders: {
    front: string;
    topdown_l: string;
    topdown_lR90: string;
    topdown_lR180: string;
    topdown_lR270: string;
    topdown_r: string;
    topdown_rR90: string;
    topdown_rR180: string;
    topdown_rR270: string;
  };
}

export const shopperByIdMap: { [id: number]: FigureModel } = {
  22: {
    key: 'BasketShopper',
    id: 22,
    name: 'Basket Shopper',
    som: 'imperial',
    w: 26.794,
    d: 17.023,
    h: 66.117,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: BasketShopperTopdownR,
      topdown_lR90: BasketShopperTopdownRR90,
      topdown_lR180: BasketShopperTopdownRR180,
      topdown_lR270: BasketShopperTopdownRR270,
      topdown_r: BasketShopperTopdownR,
      topdown_rR90: BasketShopperTopdownRR90,
      topdown_rR180: BasketShopperTopdownRR180,
      topdown_rR270: BasketShopperTopdownRR270,
      front: BasketShopperFront,
    },
  },
  23: {
    key: 'CartShopper',
    id: 23,
    name: 'Cart Shopper',
    som: 'imperial',
    w: 20.13,
    d: 53.57,
    h: 66.055,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: CartShopperTopdownR,
      topdown_lR90: CartShopperTopdownRR90,
      topdown_lR180: CartShopperTopdownRR180,
      topdown_lR270: CartShopperTopdownRR270,
      topdown_r: CartShopperTopdownR,
      topdown_rR90: CartShopperTopdownRR90,
      topdown_rR180: CartShopperTopdownRR180,
      topdown_rR270: CartShopperTopdownRR270,
      front: CartShopperFront,
    },
  },
  99924: {
    key: 'NoBasketFemaleSmShopper',
    id: 99924,
    name: 'Small Woman Without Basket',
    som: 'imperial',
    w: 15.65,
    d: 11.882,
    h: 60.09,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: NoBasketFemaleSmShopperTopdownR,
      topdown_lR90: NoBasketFemaleSmShopperTopdownRR90,
      topdown_lR180: NoBasketFemaleSmShopperTopdownRR180,
      topdown_lR270: NoBasketFemaleSmShopperTopdownRR270,
      topdown_r: NoBasketFemaleSmShopperTopdownR,
      topdown_rR90: NoBasketFemaleSmShopperTopdownRR90,
      topdown_rR180: NoBasketFemaleSmShopperTopdownRR180,
      topdown_rR270: NoBasketFemaleSmShopperTopdownRR270,
      front: NoBasketFemaleSmShopperFront,
    },
  },
  99925: {
    key: 'BasketMaleSmShopper',
    id: 99925,
    name: 'Small Man With Basket',
    som: 'imperial',
    w: 30.1,
    d: 22,
    h: 71.126,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: BasketMaleSmShopperTopdownR,
      topdown_lR90: BasketMaleSmShopperTopdownRR90,
      topdown_lR180: BasketMaleSmShopperTopdownRR180,
      topdown_lR270: BasketMaleSmShopperTopdownRR270,
      topdown_r: BasketMaleSmShopperTopdownR,
      topdown_rR90: BasketMaleSmShopperTopdownRR90,
      topdown_rR180: BasketMaleSmShopperTopdownRR180,
      topdown_rR270: BasketMaleSmShopperTopdownRR270,
      front: BasketMaleSmShopperFront,
    },
  },
  99926: {
    key: 'NoBasketMaleSmShopper',
    id: 99926,
    name: 'Small Man Without Basket',
    som: 'imperial',
    w: 21.762,
    d: 17.668,
    h: 71.6,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: NoBasketMaleSmShopperTopdownR,
      topdown_lR90: NoBasketMaleSmShopperTopdownRR90,
      topdown_lR180: NoBasketMaleSmShopperTopdownRR180,
      topdown_lR270: NoBasketMaleSmShopperTopdownRR270,
      topdown_r: NoBasketMaleSmShopperTopdownR,
      topdown_rR90: NoBasketMaleSmShopperTopdownRR90,
      topdown_rR180: NoBasketMaleSmShopperTopdownRR180,
      topdown_rR270: NoBasketMaleSmShopperTopdownRR270,
      front: NoBasketMaleSmShopperFront,
    },
  },
  99927: {
    key: 'BasketMaleLgShopper',
    id: 99927,
    name: 'Large Man With Basket',
    som: 'imperial',
    w: 38.088,
    d: 23.44,
    h: 68.88,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: BasketMaleLgShopperTopdownR,
      topdown_lR90: BasketMaleLgShopperTopdownRR90,
      topdown_lR180: BasketMaleLgShopperTopdownRR180,
      topdown_lR270: BasketMaleLgShopperTopdownRR270,
      topdown_r: BasketMaleLgShopperTopdownR,
      topdown_rR90: BasketMaleLgShopperTopdownRR90,
      topdown_rR180: BasketMaleLgShopperTopdownRR180,
      topdown_rR270: BasketMaleLgShopperTopdownRR270,
      front: BasketMaleLgShopperFront,
    },
  },
  99928: {
    key: 'NoBasketMaleLgShopper',
    id: 99928,
    name: 'Large Man With Basket',
    som: 'imperial',
    w: 30.378,
    d: 19.67,
    h: 68.886,
    category: 'shoppers',
    type: 'shopper',
    renders: {
      topdown_l: NoBasketMaleLgShopperTopdownR,
      topdown_lR90: NoBasketMaleLgShopperTopdownRR90,
      topdown_lR180: NoBasketMaleLgShopperTopdownRR180,
      topdown_lR270: NoBasketMaleLgShopperTopdownRR270,
      topdown_r: NoBasketMaleLgShopperTopdownR,
      topdown_rR90: NoBasketMaleLgShopperTopdownRR90,
      topdown_rR180: NoBasketMaleLgShopperTopdownRR180,
      topdown_rR270: NoBasketMaleLgShopperTopdownRR270,
      front: NoBasketMaleLgShopperFront,
    },
  },
};

export const helperByIdMap = {
  20: {
    key: 'Text',
    id: 20,
    name: 'Text',
    type: 'text',
  },
  21: {
    key: 'RectangleMeasureTool',
    id: 21,
    name: 'Rectangle Measure Tool',
    type: 'measure-tool',
  },
  24: {
    key: 'EllipsisMeasureTool',
    id: 24,
    name: 'Ellipsis Measure Tool',
    type: 'measure-tool',
  },
};

export const staticFixtureByIdMap = {
  101: {
    key: '09c6f791-d49c-42c0-9b50-b11ea9dca29f',
    id: 101,
    name: 'C-Store Counter Preset 1',
    manufacturer: '',
    som: 'imperial',
    w: 309.349,
    d: 45.212,
    h: 94.213,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreCounterPreset1TopdownL,
      topdown_r: CStoreCounterPreset1TopdownR,
      front: CStoreCounterPreset1Front,
    },
  },
  102: {
    key: '61941b3c-519a-4581-8d6a-a9c1d96c46bd',
    id: 102,
    name: 'C-Store Counter Preset 2',
    manufacturer: '',
    som: 'imperial',
    w: 314.013,
    d: 42.791,
    h: 68.968,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreCounterPreset2TopdownL,
      topdown_r: CStoreCounterPreset2TopdownR,
      front: CStoreCounterPreset2Front,
    },
  },
  103: {
    key: '4db45e91-7979-4b38-bb31-a57e221740a2',
    id: 103,
    name: 'C-Store Counter Default',
    manufacturer: '',
    som: 'imperial',
    w: 314.013,
    d: 40.07,
    h: 68.968,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreCounterDefaultTopdownL,
      topdown_r: CStoreCounterDefaultTopdownR,
      front: CStoreCounterDefaultFront,
    },
  },
  104: {
    key: '4e2f575e-ab13-4bf3-a3db-9f30992bde35',
    id: 104,
    name: 'C-Store L Counter Default',
    manufacturer: '',
    som: 'imperial',
    w: 232.637,
    d: 129.852,
    h: 68.968,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreLCounterDefaultTopdownL,
      topdown_r: CStoreLCounterDefaultTopdownR,
      front: CStoreLCounterDefaultFront,
    },
  },
  105: {
    key: '75791e26-ffbc-4f2d-a439-ef9cd7ea482e',
    id: 105,
    name: 'C-Store L Counter Preset 3',
    manufacturer: '',
    som: 'imperial',
    w: 232.637,
    d: 134.76,
    h: 67.968,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreLCounterPreset3TopdownL,
      topdown_r: CStoreLCounterPreset3TopdownR,
      front: CStoreLCounterPreset3Front,
    },
  },
  999106: {
    key: '20211',
    id: 999106,
    name: 'C-Store No Self Check Counter Default',
    manufacturer: '',
    som: 'imperial',
    w: 201.542,
    d: 40.07,
    h: 56.96,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreNoSelfCheckoutCounterDefaultTopdownL,
      topdown_r: CStoreNoSelfCheckoutCounterDefaultTopdownR,
      front: CStoreNoSelfCheckoutCounterDefaultFront,
    },
  },
  999107: {
    key: '20212',
    id: 999107,
    name: 'C-Store No Self Check Counter Preset 1',
    manufacturer: '',
    som: 'imperial',
    w: 201.542,
    d: 39.773,
    h: 38.51,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreNoSelfCheckoutCounterPreset1TopdownL,
      topdown_r: CStoreNoSelfCheckoutCounterPreset1TopdownR,
      front: CStoreNoSelfCheckoutCounterPreset1Front,
    },
  },
  999108: {
    key: '20213',
    id: 999108,
    name: 'C-Store No Self Check Counter Preset 2',
    manufacturer: '',
    som: 'imperial',
    w: 202.542,
    d: 39.773,
    h: 54.087,
    category: '',
    options: {},
    renders: {
      topdown_l: CStoreNoSelfCheckoutCounterPreset2TopdownL,
      topdown_r: CStoreNoSelfCheckoutCounterPreset2TopdownR,
      front: CStoreNoSelfCheckoutCounterPreset2Front,
    },
  },
};

export const fixtureByIdMap: { [id: number]: FigureModel } = {
  5: {
    key: 'FrontEndQueueGondola48x60Leading',
    id: 5,
    name: 'Gondola 48"',
    som: 'imperial',
    w: 48,
    d: 3,
    h: 60,
    category: 'gondolas',
    type: 'fixture',
    renders: {
      topdown_l: Gondola48x60TopdownL,
      topdown_lR90: Gondola48x60TopdownLR90,
      topdown_lR180: Gondola48x60TopdownLR180,
      topdown_lR270: Gondola48x60TopdownLR270,
      topdown_r: Gondola48x60TopdownR,
      topdown_rR90: Gondola48x60TopdownRR90,
      topdown_rR180: Gondola48x60TopdownRR180,
      topdown_rR270: Gondola48x60TopdownRR270,
      front: Gondola48x60Front,
    },
  },
  146: {
    key: 'FrontEndQueueGondola24x60Leading',
    id: 146,
    name: 'Gondola 24"',
    som: 'imperial',
    w: 24,
    d: 3,
    h: 60,
    category: 'gondolas',
    type: 'fixture',
    renders: {
      topdown_l: Gondola24x60TopdownL,
      topdown_lR90: Gondola24x60TopdownLR90,
      topdown_lR180: Gondola24x60TopdownLR180,
      topdown_lR270: Gondola24x60TopdownLR270,
      topdown_r: Gondola24x60TopdownR,
      topdown_rR90: Gondola24x60TopdownRR90,
      topdown_rR180: Gondola24x60TopdownRR180,
      topdown_rR270: Gondola24x60TopdownRR270,
      front: Gondola24x60Front,
    },
  },
  6: {
    key: 'FrontEndQueueGondola36x60Leading',
    id: 6,
    name: 'Gondola 36"',
    som: 'imperial',
    w: 36,
    d: 3,
    h: 60,
    category: 'gondolas',
    type: 'fixture',
    renders: {
      topdown_l: Gondola36x60TopdownL,
      topdown_lR90: Gondola36x60TopdownLR90,
      topdown_lR180: Gondola36x60TopdownLR180,
      topdown_lR270: Gondola36x60TopdownLR270,
      topdown_r: Gondola36x60TopdownR,
      topdown_rR90: Gondola36x60TopdownRR90,
      topdown_rR180: Gondola36x60TopdownRR180,
      topdown_rR270: Gondola36x60TopdownRR270,
      front: Gondola36x60Front,
    },
  },
  1: {
    key: 'FrontEndCooler48DoubleSlide',
    id: 1,
    name: 'Cooler 48"',
    som: 'imperial',
    w: 48,
    d: 21,
    h: 60,
    category: 'coolers',
    type: 'fixture',
    renders: {
      topdown_l: Cooler48DoubleSlideTopdownL,
      topdown_lR90: Cooler48DoubleSlideTopdownLR90,
      topdown_lR180: Cooler48DoubleSlideTopdownLR180,
      topdown_lR270: Cooler48DoubleSlideTopdownLR270,
      topdown_r: Cooler48DoubleSlideTopdownR,
      topdown_rR90: Cooler48DoubleSlideTopdownRR90,
      topdown_rR180: Cooler48DoubleSlideTopdownRR180,
      topdown_rR270: Cooler48DoubleSlideTopdownRR270,
      front: Cooler48DoubleSlideFront,
    },
  },
  7: {
    key: 'FrontEndCooler36DoubleSwing',
    id: 7,
    name: 'Cooler 36"',
    som: 'imperial',
    w: 36,
    d: 21,
    h: 60,
    category: 'coolers',
    type: 'fixture',
    renders: {
      topdown_l: Cooler36DoubleSwingTopdownL,
      topdown_lR90: Cooler36DoubleSwingTopdownLR90,
      topdown_lR180: Cooler36DoubleSwingTopdownLR180,
      topdown_lR270: Cooler36DoubleSwingTopdownLR270,
      topdown_r: Cooler36DoubleSwingTopdownR,
      topdown_rR90: Cooler36DoubleSwingTopdownRR90,
      topdown_rR180: Cooler36DoubleSwingTopdownRR180,
      topdown_rR270: Cooler36DoubleSwingTopdownRR270,
      front: Cooler36DoubleSwingFront,
    },
  },
  8: {
    key: 'FrontEndCooler24SingleSwing',
    id: 8,
    name: 'Cooler 24"',
    som: 'imperial',
    w: 24,
    d: 24,
    h: 60,
    category: 'coolers',
    type: 'fixture',
    renders: {
      topdown_l: Cooler24SingleSwingTopdownL,
      topdown_lR90: Cooler24SingleSwingTopdownLR90,
      topdown_lR180: Cooler24SingleSwingTopdownLR180,
      topdown_lR270: Cooler24SingleSwingTopdownLR270,
      topdown_r: Cooler24SingleSwingTopdownR,
      topdown_rR90: Cooler24SingleSwingTopdownRR90,
      topdown_rR180: Cooler24SingleSwingTopdownRR180,
      topdown_rR270: Cooler24SingleSwingTopdownRR270,
      front: Cooler24SingleSwingFront,
    },
  },
  149: {
    key: 'SelfCheckNCR6',
    id: 149,
    name: 'NCRR6C Self-Checkout',
    som: 'imperial',
    w: 49,
    d: 32,
    h: 53,
    category: 'selfCheck',
    type: 'fixture',
    renders: {
      topdown_l: NCR6SelfCheckoutTopdownL,
      topdown_lR90: NCR6SelfCheckoutTopdownLR90,
      topdown_lR180: NCR6SelfCheckoutTopdownLR180,
      topdown_lR270: NCR6SelfCheckoutTopdownLR270,
      topdown_r: NCR6SelfCheckoutTopdownR,
      topdown_rR90: NCR6SelfCheckoutTopdownRR90,
      topdown_rR180: NCR6SelfCheckoutTopdownRR180,
      topdown_rR270: NCR6SelfCheckoutTopdownRR270,
      front: NCR6SelfCheckoutFront,
    },
  },
  12: {
    key: 'FrontEndQueueBelt24Leading',
    id: 12,
    name: 'Belt 24"',
    som: 'imperial',
    w: 39.51,
    d: 13.9,
    h: 40,
    category: 'queueLineBelts',
    type: 'fixture',
    renders: {
      topdown_l: QueueBelt24TopdownL,
      topdown_lR90: QueueBelt24TopdownLR90,
      topdown_lR180: QueueBelt24TopdownLR180,
      topdown_lR270: QueueBelt24TopdownLR270,
      topdown_r: QueueBelt24TopdownR,
      topdown_rR90: QueueBelt24TopdownRR90,
      topdown_rR180: QueueBelt24TopdownRR180,
      topdown_rR270: QueueBelt24TopdownRR270,
      front: QueueBelt24Front,
    },
  },
  132: {
    key: 'FrontEndQueueBelt48Leading',
    id: 132,
    name: 'Belt 48"',
    som: 'imperial',
    w: 62.53,
    d: 13.9,
    h: 40,
    category: 'queueLineBelts',
    type: 'fixture',
    renders: {
      topdown_l: QueueBelt48TopdownL,
      topdown_lR90: QueueBelt48TopdownLR90,
      topdown_lR180: QueueBelt48TopdownLR180,
      topdown_lR270: QueueBelt48TopdownLR270,
      topdown_r: QueueBelt48TopdownR,
      topdown_rR90: QueueBelt48TopdownRR90,
      topdown_rR180: QueueBelt48TopdownRR180,
      topdown_rR270: QueueBelt48TopdownRR270,
      front: QueueBelt48Front,
    },
  },
  89: {
    key: 'FrontEndQueueSlat24x66Leading',
    id: 89,
    name: 'Slat 24x66',
    som: 'imperial',
    w: 39.51,
    d: 13.9,
    h: 66,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat24x66TopdownL,
      topdown_lR90: QueueSlat24x66TopdownLR90,
      topdown_lR180: QueueSlat24x66TopdownLR180,
      topdown_lR270: QueueSlat24x66TopdownLR270,
      topdown_r: QueueSlat24x66TopdownR,
      topdown_rR90: QueueSlat24x66TopdownRR90,
      topdown_rR180: QueueSlat24x66TopdownRR180,
      topdown_rR270: QueueSlat24x66TopdownRR270,
      front: QueueSlat24x66Front,
    },
  },
  25: {
    key: 'FrontEndQueueSlat36x40Leading',
    id: 25,
    name: 'Slat 36x40',
    som: 'imperial',
    w: 51.32,
    d: 13.9,
    h: 40,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat36x40TopdownL,
      topdown_lR90: QueueSlat36x40TopdownLR90,
      topdown_lR180: QueueSlat36x40TopdownLR180,
      topdown_lR270: QueueSlat36x40TopdownLR270,
      topdown_r: QueueSlat36x40TopdownR,
      topdown_rR90: QueueSlat36x40TopdownRR90,
      topdown_rR180: QueueSlat36x40TopdownRR180,
      topdown_rR270: QueueSlat36x40TopdownRR270,
      front: QueueSlat36x40Front,
    },
  },
  13: {
    key: 'FrontEndQueueSlat36x54Leading',
    id: 13,
    name: 'Slat 36x54',
    som: 'imperial',
    w: 51.32,
    d: 13.9,
    h: 54,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat36x54TopdownL,
      topdown_lR90: QueueSlat36x54TopdownLR90,
      topdown_lR180: QueueSlat36x54TopdownLR180,
      topdown_lR270: QueueSlat36x54TopdownLR270,
      topdown_r: QueueSlat36x54TopdownR,
      topdown_rR90: QueueSlat36x54TopdownRR90,
      topdown_rR180: QueueSlat36x54TopdownRR180,
      topdown_rR270: QueueSlat36x54TopdownRR270,
      front: QueueSlat36x54Front,
    },
  },
  14: {
    key: 'FrontEndQueueSlat36x66Leading',
    id: 14,
    name: 'Slat 36x66',
    som: 'imperial',
    w: 51.32,
    d: 13.9,
    h: 66,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat36x66TopdownL,
      topdown_lR90: QueueSlat36x66TopdownLR90,
      topdown_lR180: QueueSlat36x66TopdownLR180,
      topdown_lR270: QueueSlat36x66TopdownLR270,
      topdown_r: QueueSlat36x66TopdownR,
      topdown_rR90: QueueSlat36x66TopdownRR90,
      topdown_rR180: QueueSlat36x66TopdownRR180,
      topdown_rR270: QueueSlat36x66TopdownRR270,
      front: QueueSlat36x66Front,
    },
  },
  15: {
    key: 'FrontEndQueueSlat48x40Leading',
    id: 15,
    name: 'Slat 48x40',
    som: 'imperial',
    w: 62.53,
    d: 13.9,
    h: 40,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat48x40TopdownL,
      topdown_lR90: QueueSlat48x40TopdownLR90,
      topdown_lR180: QueueSlat48x40TopdownLR180,
      topdown_lR270: QueueSlat48x40TopdownLR270,
      topdown_r: QueueSlat48x40TopdownR,
      topdown_rR90: QueueSlat48x40TopdownRR90,
      topdown_rR180: QueueSlat48x40TopdownRR180,
      topdown_rR270: QueueSlat48x40TopdownRR270,
      front: QueueSlat48x40Front,
    },
  },
  16: {
    key: 'FrontEndQueueSlat48x54Leading',
    id: 16,
    name: 'Slat 48x54',
    som: 'imperial',
    w: 62.53,
    d: 13.9,
    h: 54,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat48x54TopdownL,
      topdown_lR90: QueueSlat48x54TopdownLR90,
      topdown_lR180: QueueSlat48x54TopdownLR180,
      topdown_lR270: QueueSlat48x54TopdownLR270,
      topdown_r: QueueSlat48x54TopdownR,
      topdown_rR90: QueueSlat48x54TopdownRR90,
      topdown_rR180: QueueSlat48x54TopdownRR180,
      topdown_rR270: QueueSlat48x54TopdownRR270,
      front: QueueSlat48x54Front,
    },
  },
  17: {
    key: 'FrontEndQueueSlat48x66Leading',
    id: 17,
    name: 'Slat 48x66',
    som: 'imperial',
    w: 62.53,
    d: 13.9,
    h: 66,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat48x66TopdownL,
      topdown_lR90: QueueSlat48x66TopdownLR90,
      topdown_lR180: QueueSlat48x66TopdownLR180,
      topdown_lR270: QueueSlat48x66TopdownLR270,
      topdown_r: QueueSlat48x66TopdownR,
      topdown_rR90: QueueSlat48x66TopdownRR90,
      topdown_rR180: QueueSlat48x66TopdownRR180,
      topdown_rR270: QueueSlat48x66TopdownRR270,
      front: QueueSlat48x66Front,
    },
  },
  18: {
    key: 'FrontEndQueueSlat24x40Leading',
    id: 18,
    name: 'Slat 24x40',
    som: 'imperial',
    w: 39.51,
    d: 13.9,
    h: 40,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat24x40TopdownL,
      topdown_lR90: QueueSlat24x40TopdownLR90,
      topdown_lR180: QueueSlat24x40TopdownLR180,
      topdown_lR270: QueueSlat24x40TopdownLR270,
      topdown_r: QueueSlat24x40TopdownR,
      topdown_rR90: QueueSlat24x40TopdownRR90,
      topdown_rR180: QueueSlat24x40TopdownRR180,
      topdown_rR270: QueueSlat24x40TopdownRR270,
      front: QueueSlat24x40Front,
    },
  },
  19: {
    key: 'FrontEndQueueSlat24x54Leading',
    id: 19,
    name: 'Slat 24x54',
    som: 'imperial',
    w: 39.51,
    d: 13.9,
    h: 54,
    category: 'queueLineMerchandisers',
    type: 'fixture',
    renders: {
      topdown_l: QueueSlat24x54TopdownL,
      topdown_lR90: QueueSlat24x54TopdownLR90,
      topdown_lR180: QueueSlat24x54TopdownLR180,
      topdown_lR270: QueueSlat24x54TopdownLR270,
      topdown_r: QueueSlat24x54TopdownR,
      topdown_rR90: QueueSlat24x54TopdownRR90,
      topdown_rR180: QueueSlat24x54TopdownRR180,
      topdown_rR270: QueueSlat24x54TopdownRR270,
      front: QueueSlat24x54Front,
    },
  },
  47: {
    key: 'FrontEndTraditionalCheckLeft24',
    id: 47,
    name: 'Belt 24" Left',
    som: 'imperial',
    w: 50.16,
    d: 116.26,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckLeft24TopdownL,
      topdown_lR90: FrontEndTraditionalCheckLeft24TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckLeft24TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckLeft24TopdownLR270,
      topdown_r: FrontEndTraditionalCheckLeft24TopdownR,
      topdown_rR90: FrontEndTraditionalCheckLeft24TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckLeft24TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckLeft24TopdownRR270,
      front: FrontEndTraditionalCheckLeft24Front,
    },
  },
  48: {
    key: 'FrontEndTraditionalCheckRight24',
    id: 48,
    name: 'Belt 24" Right',
    som: 'imperial',
    w: 50.16,
    d: 116.26,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckRight24TopdownL,
      topdown_lR90: FrontEndTraditionalCheckRight24TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckRight24TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckRight24TopdownLR270,
      topdown_r: FrontEndTraditionalCheckRight24TopdownR,
      topdown_rR90: FrontEndTraditionalCheckRight24TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckRight24TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckRight24TopdownRR270,
      front: FrontEndTraditionalCheckRight24Front,
    },
  },
  49: {
    key: 'FrontEndTraditionalCheckLeft36',
    id: 49,
    name: 'Belt 36" Left',
    som: 'imperial',
    w: 50.16,
    d: 128.357,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckLeft36TopdownL,
      topdown_lR90: FrontEndTraditionalCheckLeft36TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckLeft36TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckLeft36TopdownLR270,
      topdown_r: FrontEndTraditionalCheckLeft36TopdownR,
      topdown_rR90: FrontEndTraditionalCheckLeft36TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckLeft36TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckLeft36TopdownRR270,
      front: FrontEndTraditionalCheckLeft36Front,
    },
  },
  50: {
    key: 'FrontEndTraditionalCheckRight36',
    id: 50,
    name: 'Belt 36" Right',
    som: 'imperial',
    w: 50.16,
    d: 128.357,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckRight36TopdownL,
      topdown_lR90: FrontEndTraditionalCheckRight36TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckRight36TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckRight36TopdownLR270,
      topdown_r: FrontEndTraditionalCheckRight36TopdownR,
      topdown_rR90: FrontEndTraditionalCheckRight36TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckRight36TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckRight36TopdownRR270,
      front: FrontEndTraditionalCheckRight36Front,
    },
  },
  51: {
    key: 'FrontEndTraditionalCheckLeft48',
    id: 51,
    name: 'Belt 48" Left',
    som: 'imperial',
    w: 50.16,
    d: 140.407,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckLeft48TopdownL,
      topdown_lR90: FrontEndTraditionalCheckLeft48TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckLeft48TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckLeft48TopdownLR270,
      topdown_r: FrontEndTraditionalCheckLeft48TopdownR,
      topdown_rR90: FrontEndTraditionalCheckLeft48TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckLeft48TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckLeft48TopdownRR270,
      front: FrontEndTraditionalCheckLeft48Front,
    },
  },
  46: {
    key: 'FrontEndTraditionalCheckRight48',
    id: 46,
    name: 'Belt 48" Right',
    som: 'imperial',
    w: 50.16,
    d: 140.407,
    h: 88.52,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      topdown_l: FrontEndTraditionalCheckRight48TopdownL,
      topdown_lR90: FrontEndTraditionalCheckRight48TopdownLR90,
      topdown_lR180: FrontEndTraditionalCheckRight48TopdownLR180,
      topdown_lR270: FrontEndTraditionalCheckRight48TopdownLR270,
      topdown_r: FrontEndTraditionalCheckRight48TopdownR,
      topdown_rR90: FrontEndTraditionalCheckRight48TopdownRR90,
      topdown_rR180: FrontEndTraditionalCheckRight48TopdownRR180,
      topdown_rR270: FrontEndTraditionalCheckRight48TopdownRR270,
      front: FrontEndTraditionalCheckRight48Front,
    },
  },
  27: {
    key: 'FrontEndTraditionalRack24',
    id: 27,
    name: 'Racking 24"',
    som: 'imperial',
    w: 24,
    d: 1,
    h: 60,
    category: 'racking',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalRack24Front,
      topdown_l: FrontEndTraditionalRack24TopdownL,
      topdown_lR90: FrontEndTraditionalRack24TopdownLR90,
      topdown_lR180: FrontEndTraditionalRack24TopdownLR180,
      topdown_lR270: FrontEndTraditionalRack24TopdownLR270,
      topdown_r: FrontEndTraditionalRack24TopdownR,
      topdown_rR90: FrontEndTraditionalRack24TopdownRR90,
      topdown_rR180: FrontEndTraditionalRack24TopdownRR180,
      topdown_rR270: FrontEndTraditionalRack24TopdownRR270,
    },
  },
  32: {
    key: 'FrontEndTraditionalRack36',
    id: 32,
    name: 'Racking 36"',
    som: 'imperial',
    w: 36,
    d: 1,
    h: 60,
    category: 'racking',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalRack36Front,
      topdown_l: FrontEndTraditionalRack36TopdownL,
      topdown_lR90: FrontEndTraditionalRack36TopdownLR90,
      topdown_lR180: FrontEndTraditionalRack36TopdownLR180,
      topdown_lR270: FrontEndTraditionalRack36TopdownLR270,
      topdown_r: FrontEndTraditionalRack36TopdownR,
      topdown_rR90: FrontEndTraditionalRack36TopdownRR90,
      topdown_rR180: FrontEndTraditionalRack36TopdownRR180,
      topdown_rR270: FrontEndTraditionalRack36TopdownRR270,
    },
  },
  33: {
    key: 'FrontEndTraditionalRack48',
    id: 33,
    name: 'Racking 48"',
    som: 'imperial',
    w: 48,
    d: 1,
    h: 60,
    category: 'racking',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalRack48Front,
      topdown_l: FrontEndTraditionalRack48TopdownL,
      topdown_lR90: FrontEndTraditionalRack48TopdownLR90,
      topdown_lR180: FrontEndTraditionalRack48TopdownLR180,
      topdown_lR270: FrontEndTraditionalRack48TopdownLR270,
      topdown_r: FrontEndTraditionalRack48TopdownR,
      topdown_rR90: FrontEndTraditionalRack48TopdownRR90,
      topdown_rR180: FrontEndTraditionalRack48TopdownRR180,
      topdown_rR270: FrontEndTraditionalRack48TopdownRR270,
    },
  },
  31: {
    key: 'FrontEndTraditionalEndcap24',
    id: 31,
    name: 'Endcap 24"',
    som: 'imperial',
    w: 24,
    d: 1,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcap24Front,
      topdown_l: FrontEndTraditionalEndcap24TopdownL,
      topdown_lR90: FrontEndTraditionalEndcap24TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcap24TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcap24TopdownLR270,
      topdown_r: FrontEndTraditionalEndcap24TopdownR,
      topdown_rR90: FrontEndTraditionalEndcap24TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcap24TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcap24TopdownRR270,
    },
  },
  35: {
    key: 'FrontEndTraditionalEndcap36',
    id: 35,
    name: 'Endcap 36"',
    som: 'imperial',
    w: 36,
    d: 1,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcap36Front,
      topdown_l: FrontEndTraditionalEndcap36TopdownL,
      topdown_lR90: FrontEndTraditionalEndcap36TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcap36TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcap36TopdownLR270,
      topdown_r: FrontEndTraditionalEndcap36TopdownR,
      topdown_rR90: FrontEndTraditionalEndcap36TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcap36TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcap36TopdownRR270,
    },
  },
  36: {
    key: 'FrontEndTraditionalEndcap48',
    id: 36,
    name: 'Endcap 48"',
    som: 'imperial',
    w: 48,
    d: 1,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcap48Front,
      topdown_l: FrontEndTraditionalEndcap48TopdownL,
      topdown_lR90: FrontEndTraditionalEndcap48TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcap48TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcap48TopdownLR270,
      topdown_r: FrontEndTraditionalEndcap48TopdownR,
      topdown_rR90: FrontEndTraditionalEndcap48TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcap48TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcap48TopdownRR270,
    },
  },
  37: {
    key: 'FrontEndTraditionalEndcapSides24',
    id: 37,
    name: 'Winged Endcap 24"',
    som: 'imperial',
    w: 24,
    d: 13,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcapSides24Front,
      topdown_l: FrontEndTraditionalEndcapSides24TopdownL,
      topdown_lR90: FrontEndTraditionalEndcapSides24TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcapSides24TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcapSides24TopdownLR270,
      topdown_r: FrontEndTraditionalEndcapSides24TopdownR,
      topdown_rR90: FrontEndTraditionalEndcapSides24TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcapSides24TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcapSides24TopdownRR270,
    },
  },
  38: {
    key: 'FrontEndTraditionalEndcapSides36',
    id: 38,
    name: 'Winged Endcap 36"',
    som: 'imperial',
    w: 36,
    d: 13,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcapSides36Front,
      topdown_l: FrontEndTraditionalEndcapSides36TopdownL,
      topdown_lR90: FrontEndTraditionalEndcapSides36TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcapSides36TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcapSides36TopdownLR270,
      topdown_r: FrontEndTraditionalEndcapSides36TopdownR,
      topdown_rR90: FrontEndTraditionalEndcapSides36TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcapSides36TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcapSides36TopdownRR270,
    },
  },
  39: {
    key: 'FrontEndTraditionalEndcapSides48',
    id: 39,
    name: 'Winged Endcap 48"',
    som: 'imperial',
    w: 48,
    d: 13,
    h: 60,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalEndcapSides48Front,
      topdown_l: FrontEndTraditionalEndcapSides48TopdownL,
      topdown_lR90: FrontEndTraditionalEndcapSides48TopdownLR90,
      topdown_lR180: FrontEndTraditionalEndcapSides48TopdownLR180,
      topdown_lR270: FrontEndTraditionalEndcapSides48TopdownLR270,
      topdown_r: FrontEndTraditionalEndcapSides48TopdownR,
      topdown_rR90: FrontEndTraditionalEndcapSides48TopdownRR90,
      topdown_rR180: FrontEndTraditionalEndcapSides48TopdownRR180,
      topdown_rR270: FrontEndTraditionalEndcapSides48TopdownRR270,
    },
  },
  34: {
    key: 'FrontEndTraditionalOTBLeft24',
    id: 34,
    name: 'Racking OTB 24" Left',
    som: 'imperial',
    d: 26.248,
    w: 25.532,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBLeft24Front,
      topdown_l: FrontEndTraditionalOTBLeft24TopdownL,
      topdown_lR90: FrontEndTraditionalOTBLeft24TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBLeft24TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBLeft24TopdownLR270,
      topdown_r: FrontEndTraditionalOTBLeft24TopdownR,
      topdown_rR90: FrontEndTraditionalOTBLeft24TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBLeft24TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBLeft24TopdownRR270,
    },
  },
  41: {
    key: 'FrontEndTraditionalOTBRight24',
    id: 41,
    name: 'Racking OTB 24" Right',
    som: 'imperial',
    d: 26.248,
    w: 25.532,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBRight24Front,
      topdown_l: FrontEndTraditionalOTBRight24TopdownL,
      topdown_lR90: FrontEndTraditionalOTBRight24TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBRight24TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBRight24TopdownLR270,
      topdown_r: FrontEndTraditionalOTBRight24TopdownR,
      topdown_rR90: FrontEndTraditionalOTBRight24TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBRight24TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBRight24TopdownRR270,
    },
  },
  42: {
    key: 'FrontEndTraditionalOTBLeft36',
    id: 42,
    name: 'Racking OTB 36" Left',
    som: 'imperial',
    d: 26.248,
    w: 37.532,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBLeft36Front,
      topdown_l: FrontEndTraditionalOTBLeft36TopdownL,
      topdown_lR90: FrontEndTraditionalOTBLeft36TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBLeft36TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBLeft36TopdownLR270,
      topdown_r: FrontEndTraditionalOTBLeft36TopdownR,
      topdown_rR90: FrontEndTraditionalOTBLeft36TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBLeft36TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBLeft36TopdownRR270,
    },
  },
  43: {
    key: 'FrontEndTraditionalOTBRight36',
    id: 43,
    name: 'Racking OTB 36" Right',
    som: 'imperial',
    d: 26.248,
    w: 37.532,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBRight36Front,
      topdown_l: FrontEndTraditionalOTBRight36TopdownL,
      topdown_lR90: FrontEndTraditionalOTBRight36TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBRight36TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBRight36TopdownLR270,
      topdown_r: FrontEndTraditionalOTBRight36TopdownR,
      topdown_rR90: FrontEndTraditionalOTBRight36TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBRight36TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBRight36TopdownRR270,
    },
  },
  44: {
    key: 'FrontEndTraditionalOTBLeft48',
    id: 44,
    name: 'Racking OTB 48" Left',
    som: 'imperial',
    w: 49.532,
    d: 26.248,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBLeft48Front,
      topdown_l: FrontEndTraditionalOTBLeft48TopdownL,
      topdown_lR90: FrontEndTraditionalOTBLeft48TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBLeft48TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBLeft48TopdownLR270,
      topdown_r: FrontEndTraditionalOTBLeft48TopdownR,
      topdown_rR90: FrontEndTraditionalOTBLeft48TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBLeft48TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBLeft48TopdownRR270,
    },
  },
  45: {
    key: 'FrontEndTraditionalOTBRight48',
    id: 45,
    name: 'Racking OTB 48" Right',
    som: 'imperial',
    d: 26.248,
    w: 49.532,
    h: 60,
    category: 'rackingOverTheBelt',
    type: 'fixture',
    renders: {
      front: FrontEndTraditionalOTBRight48Front,
      topdown_l: FrontEndTraditionalOTBRight48TopdownL,
      topdown_lR90: FrontEndTraditionalOTBRight48TopdownLR90,
      topdown_lR180: FrontEndTraditionalOTBRight48TopdownLR180,
      topdown_lR270: FrontEndTraditionalOTBRight48TopdownLR270,
      topdown_r: FrontEndTraditionalOTBRight48TopdownR,
      topdown_rR90: FrontEndTraditionalOTBRight48TopdownRR90,
      topdown_rR180: FrontEndTraditionalOTBRight48TopdownRR180,
      topdown_rR270: FrontEndTraditionalOTBRight48TopdownRR270,
    },
  },
  99: {
    key: '68d26c99-b2ea-42f5-9619-c7cea51aa11d',
    id: 99,
    name: 'ATM',
    som: 'imperial',
    w: 29.51,
    d: 34.8,
    h: 61.88,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: ATMFront,
      topdown_l: ATMTopdownL,
      topdown_lR90: ATMTopdownLR90,
      topdown_lR180: ATMTopdownLR180,
      topdown_lR270: ATMTopdownLR270,
      topdown_r: ATMTopdownR,
      topdown_rR90: ATMTopdownRR90,
      topdown_rR180: ATMTopdownRR180,
      topdown_rR270: ATMTopdownRR270,
    },
  },
  155: {
    key: 'f4d3aa76-c4fa-4cdf-902e-6fea1eefd324',
    id: 155,
    name: 'Bakery Rack 47"',
    som: 'imperial',
    w: 47,
    d: 30.14,
    h: 44.1,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: BakeryRackFront,
      topdown_l: BakeryRackTopdownL,
      topdown_lR90: BakeryRackTopdownLR90,
      topdown_lR180: BakeryRackTopdownLR180,
      topdown_lR270: BakeryRackTopdownLR270,
      topdown_r: BakeryRackTopdownR,
      topdown_rR90: BakeryRackTopdownRR90,
      topdown_rR180: BakeryRackTopdownRR180,
      topdown_rR270: BakeryRackTopdownRR270,
    },
  },
  141: {
    key: '8daa8243-d873-4c57-bed4-d987e513c030',
    id: 141,
    name: 'Chicken Warmer',
    som: 'imperial',
    w: 72,
    d: 42,
    h: 78,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: ChickenWarmerFront,
      topdown_l: ChickenWarmerTopdownL,
      topdown_lR90: ChickenWarmerTopdownLR90,
      topdown_lR180: ChickenWarmerTopdownLR180,
      topdown_lR270: ChickenWarmerTopdownLR270,
      topdown_r: ChickenWarmerTopdownR,
      topdown_rR90: ChickenWarmerTopdownRR90,
      topdown_rR180: ChickenWarmerTopdownRR180,
      topdown_rR270: ChickenWarmerTopdownRR270,
    },
  },
  154: {
    key: 'b1a6c8cc-b165-49f8-9a15-c78a96427d87',
    id: 154,
    name: 'Coinstar Kiosk',
    som: 'imperial',
    w: 35,
    d: 29,
    h: 76,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: CoinstarKioskFront,
      topdown_l: CoinstarKioskTopdownL,
      topdown_lR90: CoinstarKioskTopdownLR90,
      topdown_lR180: CoinstarKioskTopdownLR180,
      topdown_lR270: CoinstarKioskTopdownLR270,
      topdown_r: CoinstarKioskTopdownR,
      topdown_rR90: CoinstarKioskTopdownRR90,
      topdown_rR180: CoinstarKioskTopdownRR180,
      topdown_rR270: CoinstarKioskTopdownRR270,
    },
  },
  62: {
    key: 'cc152999-a926-4eea-9668-ca384523f376',
    id: 62,
    name: 'Gift Card Center',
    som: 'imperial',
    w: 68.26,
    d: 47.9,
    h: 65,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: GiftCardCenterFront,
      topdown_l: GiftCardCenterTopdownL,
      topdown_lR90: GiftCardCenterTopdownLR90,
      topdown_lR180: GiftCardCenterTopdownLR180,
      topdown_lR270: GiftCardCenterTopdownLR270,
      topdown_r: GiftCardCenterTopdownR,
      topdown_rR90: GiftCardCenterTopdownRR90,
      topdown_rR180: GiftCardCenterTopdownRR180,
      topdown_rR270: GiftCardCenterTopdownRR270,
    },
  },
  61: {
    key: '3b4d5280-ce37-4266-b1e1-9da840d1abce',
    id: 61,
    name: 'Lotto Kiosk',
    som: 'imperial',
    w: 36,
    d: 27,
    h: 78.5,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: LottoKioskFront,
      topdown_l: LottoKioskTopdownL,
      topdown_lR90: LottoKioskTopdownLR90,
      topdown_lR180: LottoKioskTopdownLR180,
      topdown_lR270: LottoKioskTopdownLR270,
      topdown_r: LottoKioskTopdownR,
      topdown_rR90: LottoKioskTopdownRR90,
      topdown_rR180: LottoKioskTopdownRR180,
      topdown_rR270: LottoKioskTopdownRR270,
    },
  },
  86: {
    key: '2da32a43-9111-4ad0-8254-19c897360b6b',
    id: 86,
    name: 'Kiosk',
    som: 'imperial',
    w: 142,
    d: 89,
    h: 120,
    category: 'walmartPickUp',
    type: 'fixture',
    renders: {
      front: PickUpKioskFront,
      topdown_l: PickUpKioskTopdownL,
      topdown_lR90: PickUpKioskTopdownLR90,
      topdown_lR180: PickUpKioskTopdownLR180,
      topdown_lR270: PickUpKioskTopdownLR270,
      topdown_r: PickUpKioskTopdownR,
      topdown_rR90: PickUpKioskTopdownRR90,
      topdown_rR180: PickUpKioskTopdownRR180,
      topdown_rR270: PickUpKioskTopdownRR270,
    },
  },
  73: {
    key: 'd1a04496-55a0-4b77-b9f9-1e0c7caf0731',
    id: 73,
    name: 'Locker Block',
    som: 'imperial',
    w: 42,
    d: 25,
    h: 48,
    category: 'walmartPickUp',
    type: 'fixture',
    renders: {
      front: PickUpLockerBlockFront,
      topdown_l: PickUpLockerBlockTopdownL,
      topdown_lR90: PickUpLockerBlockTopdownLR90,
      topdown_lR180: PickUpLockerBlockTopdownLR180,
      topdown_lR270: PickUpLockerBlockTopdownLR270,
      topdown_r: PickUpLockerBlockTopdownR,
      topdown_rR90: PickUpLockerBlockTopdownRR90,
      topdown_rR180: PickUpLockerBlockTopdownRR180,
      topdown_rR270: PickUpLockerBlockTopdownRR270,
    },
  },
  74: {
    key: '920645fd-0021-4e75-9046-dcb3214a0281',
    id: 74,
    name: 'Locker Single',
    som: 'imperial',
    w: 14,
    d: 25,
    h: 48,
    category: 'walmartPickUp',
    type: 'fixture',
    renders: {
      front: PickUpLockerSingleFront,
      topdown_l: PickUpLockerSingleTopdownL,
      topdown_lR90: PickUpLockerSingleTopdownLR90,
      topdown_lR180: PickUpLockerSingleTopdownLR180,
      topdown_lR270: PickUpLockerSingleTopdownLR270,
      topdown_r: PickUpLockerSingleTopdownR,
      topdown_rR90: PickUpLockerSingleTopdownRR90,
      topdown_rR180: PickUpLockerSingleTopdownRR180,
      topdown_rR270: PickUpLockerSingleTopdownRR270,
    },
  },
  60: {
    key: '8533bd56-ddc8-48bc-9849-f87dff976642',
    id: 60,
    name: 'Tobacco Case',
    som: 'imperial',
    w: 188,
    d: 15,
    h: 54,
    category: 'walmartMisc',
    type: 'fixture',
    renders: {
      front: TobaccoCaseFront,
      topdown_l: TobaccoCaseTopdownL,
      topdown_lR90: TobaccoCaseTopdownLR90,
      topdown_lR180: TobaccoCaseTopdownLR180,
      topdown_lR270: TobaccoCaseTopdownLR270,
      topdown_r: TobaccoCaseTopdownR,
      topdown_rR90: TobaccoCaseTopdownRR90,
      topdown_rR180: TobaccoCaseTopdownRR180,
      topdown_rR270: TobaccoCaseTopdownRR270,
    },
  },
  145: {
    key: '248cccde-7982-4d10-b31f-5bdf35ec529c',
    id: 145,
    name: 'Express Left',
    som: 'imperial',
    w: 52,
    d: 32,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: ExpressLeftFront,
      topdown_l: ExpressLeftTopdownL,
      topdown_lR90: ExpressLeftTopdownLR90,
      topdown_lR180: ExpressLeftTopdownLR180,
      topdown_lR270: ExpressLeftTopdownLR270,
      topdown_r: ExpressLeftTopdownR,
      topdown_rR90: ExpressLeftTopdownRR90,
      topdown_rR180: ExpressLeftTopdownRR180,
      topdown_rR270: ExpressLeftTopdownRR270,
    },
  },
  80: {
    key: '82ee3e6a-1a51-4e1d-ae43-e26bc2d251ee',
    id: 80,
    name: 'Express Right',
    som: 'imperial',
    w: 52,
    d: 32,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: ExpressRightFront,
      topdown_l: ExpressRightTopdownL,
      topdown_lR90: ExpressRightTopdownLR90,
      topdown_lR180: ExpressRightTopdownLR180,
      topdown_lR270: ExpressRightTopdownLR270,
      topdown_r: ExpressRightTopdownR,
      topdown_rR90: ExpressRightTopdownRR90,
      topdown_rR180: ExpressRightTopdownRR180,
      topdown_rR270: ExpressRightTopdownRR270,
    },
  },
  79: {
    key: '35e3945e-ffb5-4a13-a177-38a5366cb155',
    id: 79,
    name: 'Express Plus Right',
    som: 'imperial',
    w: 52,
    d: 32,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: ExpressPlusRightFront,
      topdown_l: ExpressPlusRightTopdownL,
      topdown_lR90: ExpressPlusRightTopdownLR90,
      topdown_lR180: ExpressPlusRightTopdownLR180,
      topdown_lR270: ExpressPlusRightTopdownLR270,
      topdown_r: ExpressPlusRightTopdownR,
      topdown_rR90: ExpressPlusRightTopdownRR90,
      topdown_rR180: ExpressPlusRightTopdownRR180,
      topdown_rR270: ExpressPlusRightTopdownRR270,
    },
  },
  78: {
    key: '6363f7ba-24d9-405c-9d06-c40e59fd3e92',
    id: 78,
    name: 'Express Plus Left',
    som: 'imperial',
    w: 52,
    d: 32,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: ExpressPlusLeftFront,
      topdown_l: ExpressPlusLeftTopdownL,
      topdown_lR90: ExpressPlusLeftTopdownLR90,
      topdown_lR180: ExpressPlusLeftTopdownLR180,
      topdown_lR270: ExpressPlusLeftTopdownLR270,
      topdown_r: ExpressPlusLeftTopdownR,
      topdown_rR90: ExpressPlusLeftTopdownRR90,
      topdown_rR180: ExpressPlusLeftTopdownRR180,
      topdown_rR270: ExpressPlusLeftTopdownRR270,
    },
  },
  63: {
    key: '90f95eb8-5bb5-4cef-8580-a1d584a860e9',
    id: 63,
    name: 'Beverage 148"',
    som: 'imperial',
    w: 147.34,
    d: 36,
    h: 66.53,
    category: 'walmartOpenAirCoolers',
    type: 'fixture',
    renders: {
      front: BeverageCoolerOpenAir148Front,
      topdown_l: BeverageCoolerOpenAir148TopdownL,
      topdown_lR90: BeverageCoolerOpenAir148TopdownLR90,
      topdown_lR180: BeverageCoolerOpenAir148TopdownLR180,
      topdown_lR270: BeverageCoolerOpenAir148TopdownLR270,
      topdown_r: BeverageCoolerOpenAir148TopdownR,
      topdown_rR90: BeverageCoolerOpenAir148TopdownRR90,
      topdown_rR180: BeverageCoolerOpenAir148TopdownRR180,
      topdown_rR270: BeverageCoolerOpenAir148TopdownRR270,
    },
  },
  64: {
    key: '957e91f8-eef7-4f7c-9792-1252428961ac',
    id: 64,
    name: 'Beverage 196"',
    som: 'imperial',
    w: 195.34,
    d: 36,
    h: 66.53,
    category: 'walmartOpenAirCoolers',
    type: 'fixture',
    renders: {
      front: BeverageCoolerOpenAir196Front,
      topdown_l: BeverageCoolerOpenAir196TopdownL,
      topdown_lR90: BeverageCoolerOpenAir196TopdownLR90,
      topdown_lR180: BeverageCoolerOpenAir196TopdownLR180,
      topdown_lR270: BeverageCoolerOpenAir196TopdownLR270,
      topdown_r: BeverageCoolerOpenAir196TopdownR,
      topdown_rR90: BeverageCoolerOpenAir196TopdownRR90,
      topdown_rR180: BeverageCoolerOpenAir196TopdownRR180,
      topdown_rR270: BeverageCoolerOpenAir196TopdownRR270,
    },
  },
  65: {
    key: 'a4ecc03b-f93a-46b6-8b96-eea03bc41936',
    id: 65,
    name: 'Beverage 244"',
    som: 'imperial',
    w: 243.34,
    d: 36,
    h: 66.53,
    category: 'walmartOpenAirCoolers',
    type: 'fixture',
    renders: {
      front: BeverageCoolerOpenAir244Front,
      topdown_l: BeverageCoolerOpenAir244TopdownL,
      topdown_lR90: BeverageCoolerOpenAir244TopdownLR90,
      topdown_lR180: BeverageCoolerOpenAir244TopdownLR180,
      topdown_lR270: BeverageCoolerOpenAir244TopdownLR270,
      topdown_r: BeverageCoolerOpenAir244TopdownR,
      topdown_rR90: BeverageCoolerOpenAir244TopdownRR90,
      topdown_rR180: BeverageCoolerOpenAir244TopdownRR180,
      topdown_rR270: BeverageCoolerOpenAir244TopdownRR270,
    },
  },
  144: {
    key: '31553777-da71-455e-9fc7-c64046106cda',
    id: 144,
    name: 'Panel 24"',
    som: 'imperial',
    w: 24,
    d: 1.1,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterPanel24Front,
      topdown_l: BullPenPerimiterPanel24TopdownL,
      topdown_lR90: BullPenPerimiterPanel24TopdownLR90,
      topdown_lR180: BullPenPerimiterPanel24TopdownLR180,
      topdown_lR270: BullPenPerimiterPanel24TopdownLR270,
      topdown_r: BullPenPerimiterPanel24TopdownR,
      topdown_rR90: BullPenPerimiterPanel24TopdownRR90,
      topdown_rR180: BullPenPerimiterPanel24TopdownRR180,
      topdown_rR270: BullPenPerimiterPanel24TopdownRR270,
    },
  },
  130: {
    key: '8024dffa-79d7-44d4-9f5c-d79c9f9457e2',
    id: 130,
    name: 'Panel 36"',
    som: 'imperial',
    w: 36,
    d: 1.1,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterPanel36Front,
      topdown_l: BullPenPerimiterPanel36TopdownL,
      topdown_lR90: BullPenPerimiterPanel36TopdownLR90,
      topdown_lR180: BullPenPerimiterPanel36TopdownLR180,
      topdown_lR270: BullPenPerimiterPanel36TopdownLR270,
      topdown_r: BullPenPerimiterPanel36TopdownR,
      topdown_rR90: BullPenPerimiterPanel36TopdownRR90,
      topdown_rR180: BullPenPerimiterPanel36TopdownRR180,
      topdown_rR270: BullPenPerimiterPanel36TopdownRR270,
    },
  },
  140: {
    key: '51cdedfe-58dd-457c-8b57-7756b0958718',
    id: 140,
    name: 'Panel 48"',
    som: 'imperial',
    w: 48,
    d: 1.1,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterPanel48Front,
      topdown_l: BullPenPerimiterPanel48TopdownL,
      topdown_lR90: BullPenPerimiterPanel48TopdownLR90,
      topdown_lR180: BullPenPerimiterPanel48TopdownLR180,
      topdown_lR270: BullPenPerimiterPanel48TopdownLR270,
      topdown_r: BullPenPerimiterPanel48TopdownR,
      topdown_rR90: BullPenPerimiterPanel48TopdownRR90,
      topdown_rR180: BullPenPerimiterPanel48TopdownRR180,
      topdown_rR270: BullPenPerimiterPanel48TopdownRR270,
    },
  },
  66: {
    key: '26103923-ca1c-4d2f-bb17-ffac42c11ca0',
    id: 66,
    name: 'Wire Chase 24"',
    som: 'imperial',
    w: 24,
    d: 6.8,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterWireChase24Front,
      topdown_l: BullPenPerimiterWireChase24TopdownL,
      topdown_lR90: BullPenPerimiterWireChase24TopdownLR90,
      topdown_lR180: BullPenPerimiterWireChase24TopdownLR180,
      topdown_lR270: BullPenPerimiterWireChase24TopdownLR270,
      topdown_r: BullPenPerimiterWireChase24TopdownR,
      topdown_rR90: BullPenPerimiterWireChase24TopdownRR90,
      topdown_rR180: BullPenPerimiterWireChase24TopdownRR180,
      topdown_rR270: BullPenPerimiterWireChase24TopdownRR270,
    },
  },
  67: {
    key: 'eae07ce8-f815-4943-8543-287d561586be',
    id: 67,
    name: 'Wire Chase 36"',
    som: 'imperial',
    w: 36,
    d: 6.8,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterWireChase36Front,
      topdown_l: BullPenPerimiterWireChase36TopdownL,
      topdown_lR90: BullPenPerimiterWireChase36TopdownLR90,
      topdown_lR180: BullPenPerimiterWireChase36TopdownLR180,
      topdown_lR270: BullPenPerimiterWireChase36TopdownLR270,
      topdown_r: BullPenPerimiterWireChase36TopdownR,
      topdown_rR90: BullPenPerimiterWireChase36TopdownRR90,
      topdown_rR180: BullPenPerimiterWireChase36TopdownRR180,
      topdown_rR270: BullPenPerimiterWireChase36TopdownRR270,
    },
  },
  68: {
    key: 'e26a0f5b-57c3-479e-9a32-d38e816f4385',
    id: 68,
    name: 'Wire Chase 48"',
    som: 'imperial',
    w: 48,
    d: 6.8,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: BullPenPerimiterWireChase48Front,
      topdown_l: BullPenPerimiterWireChase48TopdownL,
      topdown_lR90: BullPenPerimiterWireChase48TopdownLR90,
      topdown_lR180: BullPenPerimiterWireChase48TopdownLR180,
      topdown_lR270: BullPenPerimiterWireChase48TopdownLR270,
      topdown_r: BullPenPerimiterWireChase48TopdownR,
      topdown_rR90: BullPenPerimiterWireChase48TopdownRR90,
      topdown_rR180: BullPenPerimiterWireChase48TopdownRR180,
      topdown_rR270: BullPenPerimiterWireChase48TopdownRR270,
    },
  },
  56: {
    key: '732b4253-e433-43d4-b33b-700fb3d3b2ef',
    id: 56,
    name: '36"',
    som: 'imperial',
    w: 37,
    d: 37,
    h: 52.9,
    category: 'walmartFourway',
    type: 'fixture',
    renders: {
      front: Fourway36Front,
      topdown_l: Fourway36TopdownL,
      topdown_lR90: Fourway36TopdownLR90,
      topdown_lR180: Fourway36TopdownLR180,
      topdown_lR270: Fourway36TopdownLR270,
      topdown_r: Fourway36TopdownR,
      topdown_rR90: Fourway36TopdownRR90,
      topdown_rR180: Fourway36TopdownRR180,
      topdown_rR270: Fourway36TopdownRR270,
    },
  },
  57: {
    key: '2c52d472-a6d6-4327-bef6-cc7b97deed9d',
    id: 57,
    name: '48"',
    som: 'imperial',
    w: 49,
    d: 49,
    h: 52.9,
    category: 'walmartFourway',
    type: 'fixture',
    renders: {
      front: Fourway48Front,
      topdown_l: Fourway48TopdownL,
      topdown_lR90: Fourway48TopdownLR90,
      topdown_lR180: Fourway48TopdownLR180,
      topdown_lR270: Fourway48TopdownLR270,
      topdown_r: Fourway48TopdownR,
      topdown_rR90: Fourway48TopdownRR90,
      topdown_rR180: Fourway48TopdownRR180,
      topdown_rR270: Fourway48TopdownRR270,
    },
  },
  77: {
    key: 'fb79f3c8-7164-4e5f-b470-1c295825e839',
    id: 77,
    name: 'Large Basket Left',
    som: 'imperial',
    w: 73.92,
    d: 90.44,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: LargeBasketLeftFront,
      topdown_l: LargeBasketLeftTopdownL,
      topdown_lR90: LargeBasketLeftTopdownLR90,
      topdown_lR180: LargeBasketLeftTopdownLR180,
      topdown_lR270: LargeBasketLeftTopdownLR270,
      topdown_r: LargeBasketLeftTopdownR,
      topdown_rR90: LargeBasketLeftTopdownRR90,
      topdown_rR180: LargeBasketLeftTopdownRR180,
      topdown_rR270: LargeBasketLeftTopdownRR270,
    },
  },
  76: {
    key: 'b9d41915-3f9d-4926-89f4-1587e6d6fdf2',
    id: 76,
    name: 'Large Basket Right',
    som: 'imperial',
    w: 73.92,
    d: 90.44,
    h: 52.75,
    category: 'walmartSelfCheckout',
    type: 'fixture',
    renders: {
      front: LargeBasketRightFront,
      topdown_l: LargeBasketRightTopdownL,
      topdown_lR90: LargeBasketRightTopdownLR90,
      topdown_lR180: LargeBasketRightTopdownLR180,
      topdown_lR270: LargeBasketRightTopdownLR270,
      topdown_r: LargeBasketRightTopdownR,
      topdown_rR90: LargeBasketRightTopdownRR90,
      topdown_rR180: LargeBasketRightTopdownRR180,
      topdown_rR270: LargeBasketRightTopdownRR270,
    },
  },
  87: {
    key: 'e58071b1-5bcb-44c8-8c3e-03dbd23d2ea6',
    id: 87,
    name: 'Register Left',
    som: 'imperial',
    w: 50.25,
    d: 129,
    h: 60.76,
    category: 'walmartTraditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      front: TraditionalRegisterLeftFront,
      topdown_l: TraditionalRegisterLeftTopdownL,
      topdown_lR90: TraditionalRegisterLeftTopdownLR90,
      topdown_lR180: TraditionalRegisterLeftTopdownLR180,
      topdown_lR270: TraditionalRegisterLeftTopdownLR270,
      topdown_r: TraditionalRegisterLeftTopdownR,
      topdown_rR90: TraditionalRegisterLeftTopdownRR90,
      topdown_rR180: TraditionalRegisterLeftTopdownRR180,
      topdown_rR270: TraditionalRegisterLeftTopdownRR270,
    },
  },
  88: {
    key: '4da87b3c-3f26-48d5-aab3-7452e830d513',
    id: 88,
    name: 'Register Right',
    som: 'imperial',
    w: 50.25,
    d: 129,
    h: 60.76,
    category: 'walmartTraditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      front: TraditionalRegisterRightFront,
      topdown_l: TraditionalRegisterRightTopdownL,
      topdown_lR90: TraditionalRegisterRightTopdownLR90,
      topdown_lR180: TraditionalRegisterRightTopdownLR180,
      topdown_lR270: TraditionalRegisterRightTopdownLR270,
      topdown_r: TraditionalRegisterRightTopdownR,
      topdown_rR90: TraditionalRegisterRightTopdownRR90,
      topdown_rR180: TraditionalRegisterRightTopdownRR180,
      topdown_rR270: TraditionalRegisterRightTopdownRR270,
    },
  },
  75: {
    key: 'b658935f-879f-4f9c-a3cf-1b0f24f34331',
    id: 75,
    name: 'Gondola 24x48',
    som: 'imperial',
    w: 24,
    d: 2,
    h: 48,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW24H48Front,
      topdown_l: GondolaEndW24H48TopdownL,
      topdown_lR90: GondolaEndW24H48TopdownLR90,
      topdown_lR180: GondolaEndW24H48TopdownLR180,
      topdown_lR270: GondolaEndW24H48TopdownLR270,
      topdown_r: GondolaEndW24H48TopdownR,
      topdown_rR90: GondolaEndW24H48TopdownRR90,
      topdown_rR180: GondolaEndW24H48TopdownRR180,
      topdown_rR270: GondolaEndW24H48TopdownRR270,
    },
  },
  72: {
    key: '27efe7e4-717b-4a8d-8835-05b0e519fdff',
    id: 72,
    name: 'Gondola 36x48',
    som: 'imperial',
    w: 36,
    d: 2,
    h: 48,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW36H48Front,
      topdown_l: GondolaEndW36H48TopdownL,
      topdown_lR90: GondolaEndW36H48TopdownLR90,
      topdown_lR180: GondolaEndW36H48TopdownLR180,
      topdown_lR270: GondolaEndW36H48TopdownLR270,
      topdown_r: GondolaEndW36H48TopdownR,
      topdown_rR90: GondolaEndW36H48TopdownRR90,
      topdown_rR180: GondolaEndW36H48TopdownRR180,
      topdown_rR270: GondolaEndW36H48TopdownRR270,
    },
  },
  71: {
    key: '1b2f107c-5a61-43ec-bbfd-e0c5e3097f21',
    id: 71,
    name: 'Gondola 48x48',
    som: 'imperial',
    w: 48,
    d: 2,
    h: 48,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW48H48Front,
      topdown_l: GondolaEndW48H48TopdownL,
      topdown_lR90: GondolaEndW48H48TopdownLR90,
      topdown_lR180: GondolaEndW48H48TopdownLR180,
      topdown_lR270: GondolaEndW48H48TopdownLR270,
      topdown_r: GondolaEndW48H48TopdownR,
      topdown_rR90: GondolaEndW48H48TopdownRR90,
      topdown_rR180: GondolaEndW48H48TopdownRR180,
      topdown_rR270: GondolaEndW48H48TopdownRR270,
    },
  },
  59: {
    key: '4e33685a-5f5d-4ba7-9689-f6c3cb0e0d14',
    id: 59,
    name: 'Gondola 24x60',
    som: 'imperial',
    w: 24,
    d: 2,
    h: 60,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW24H60Front,
      topdown_l: GondolaEndW24H60TopdownL,
      topdown_lR90: GondolaEndW24H60TopdownLR90,
      topdown_lR180: GondolaEndW24H60TopdownLR180,
      topdown_lR270: GondolaEndW24H60TopdownLR270,
      topdown_r: GondolaEndW24H60TopdownR,
      topdown_rR90: GondolaEndW24H60TopdownRR90,
      topdown_rR180: GondolaEndW24H60TopdownRR180,
      topdown_rR270: GondolaEndW24H60TopdownRR270,
    },
  },
  58: {
    key: '2d310ad1-59d9-4bdb-a0fb-551f1cbd19c2',
    id: 58,
    name: 'Gondola 36x60',
    som: 'imperial',
    w: 36,
    d: 2,
    h: 60,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW36H60Front,
      topdown_l: GondolaEndW36H60TopdownL,
      topdown_lR90: GondolaEndW36H60TopdownLR90,
      topdown_lR180: GondolaEndW36H60TopdownLR180,
      topdown_lR270: GondolaEndW36H60TopdownLR270,
      topdown_r: GondolaEndW36H60TopdownR,
      topdown_rR90: GondolaEndW36H60TopdownRR90,
      topdown_rR180: GondolaEndW36H60TopdownRR180,
      topdown_rR270: GondolaEndW36H60TopdownRR270,
    },
  },
  70: {
    key: '05c3a949-806e-4e00-bc55-7226b83492e2',
    id: 70,
    name: 'Gondola 48x60',
    som: 'imperial',
    w: 48,
    d: 2,
    h: 60,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: GondolaEndW48H60Front,
      topdown_l: GondolaEndW48H60TopdownL,
      topdown_lR90: GondolaEndW48H60TopdownLR90,
      topdown_lR180: GondolaEndW48H60TopdownLR180,
      topdown_lR270: GondolaEndW48H60TopdownLR270,
      topdown_r: GondolaEndW48H60TopdownR,
      topdown_rR90: GondolaEndW48H60TopdownRR90,
      topdown_rR180: GondolaEndW48H60TopdownRR180,
      topdown_rR270: GondolaEndW48H60TopdownRR270,
    },
  },
  52: {
    key: '2a081f81-52bd-417c-aa66-e992cc6f7948',
    id: 52,
    name: 'Double Door 31"',
    som: 'imperial',
    w: 30.875,
    d: 26.804,
    h: 54.875,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor31x55x27Front,
      topdown_l: CoolerDoubleDoor31x55x27TopdownL,
      topdown_lR90: CoolerDoubleDoor31x55x27TopdownLR90,
      topdown_lR180: CoolerDoubleDoor31x55x27TopdownLR180,
      topdown_lR270: CoolerDoubleDoor31x55x27TopdownLR270,
      topdown_r: CoolerDoubleDoor31x55x27TopdownR,
      topdown_rR90: CoolerDoubleDoor31x55x27TopdownRR90,
      topdown_rR180: CoolerDoubleDoor31x55x27TopdownRR180,
      topdown_rR270: CoolerDoubleDoor31x55x27TopdownRR270,
    },
  },
  53: {
    key: 'fa403198-3325-4639-abc5-63c1d03cc993',
    id: 53,
    name: 'Double Door 36"',
    som: 'imperial',
    w: 36,
    d: 22.45,
    h: 55,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor36x55x22Front,
      topdown_l: CoolerDoubleDoor36x55x22TopdownL,
      topdown_lR90: CoolerDoubleDoor36x55x22TopdownLR90,
      topdown_lR180: CoolerDoubleDoor36x55x22TopdownLR180,
      topdown_lR270: CoolerDoubleDoor36x55x22TopdownLR270,
      topdown_r: CoolerDoubleDoor36x55x22TopdownR,
      topdown_rR90: CoolerDoubleDoor36x55x22TopdownRR90,
      topdown_rR180: CoolerDoubleDoor36x55x22TopdownRR180,
      topdown_rR270: CoolerDoubleDoor36x55x22TopdownRR270,
    },
  },
  54: {
    key: '0389e262-dc3a-4be8-95f8-d5a84e67087a',
    id: 54,
    name: 'Double Door 37"',
    som: 'imperial',
    w: 37,
    d: 22.297,
    h: 60,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor37x60x23Front,
      topdown_l: CoolerDoubleDoor37x60x23TopdownL,
      topdown_lR90: CoolerDoubleDoor37x60x23TopdownLR90,
      topdown_lR180: CoolerDoubleDoor37x60x23TopdownLR180,
      topdown_lR270: CoolerDoubleDoor37x60x23TopdownLR270,
      topdown_r: CoolerDoubleDoor37x60x23TopdownR,
      topdown_rR90: CoolerDoubleDoor37x60x23TopdownRR90,
      topdown_rR180: CoolerDoubleDoor37x60x23TopdownRR180,
      topdown_rR270: CoolerDoubleDoor37x60x23TopdownRR270,
    },
  },
  84: {
    key: '357d7fc7-8af9-42ae-ad1e-2309f281c2e6',
    id: 84,
    name: 'Double Door 47"',
    som: 'imperial',
    w: 47,
    d: 21.44,
    h: 48,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor47x48x21Front,
      topdown_l: CoolerDoubleDoor47x48x21TopdownL,
      topdown_lR90: CoolerDoubleDoor47x48x21TopdownLR90,
      topdown_lR180: CoolerDoubleDoor47x48x21TopdownLR180,
      topdown_lR270: CoolerDoubleDoor47x48x21TopdownLR270,
      topdown_r: CoolerDoubleDoor47x48x21TopdownR,
      topdown_rR90: CoolerDoubleDoor47x48x21TopdownRR90,
      topdown_rR180: CoolerDoubleDoor47x48x21TopdownRR180,
      topdown_rR270: CoolerDoubleDoor47x48x21TopdownRR270,
    },
  },
  85: {
    key: 'd14bd560-be3d-4d8b-8d35-5766bb31508a',
    id: 85,
    name: 'Double Door 48"',
    som: 'imperial',
    w: 48,
    d: 24.44,
    h: 48,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor48x48x24Front,
      topdown_l: CoolerDoubleDoor48x48x24TopdownL,
      topdown_lR90: CoolerDoubleDoor48x48x24TopdownLR90,
      topdown_lR180: CoolerDoubleDoor48x48x24TopdownLR180,
      topdown_lR270: CoolerDoubleDoor48x48x24TopdownLR270,
      topdown_r: CoolerDoubleDoor48x48x24TopdownR,
      topdown_rR90: CoolerDoubleDoor48x48x24TopdownRR90,
      topdown_rR180: CoolerDoubleDoor48x48x24TopdownRR180,
      topdown_rR270: CoolerDoubleDoor48x48x24TopdownRR270,
    },
  },
  81: {
    key: '57eba2c0-a8da-4229-9661-4255c4538dbe',
    id: 81,
    name: 'Double Door 49"',
    som: 'imperial',
    w: 49,
    d: 21.53,
    h: 59.9,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerDoubleDoor49x60x22Front,
      topdown_l: CoolerDoubleDoor49x60x22TopdownL,
      topdown_lR90: CoolerDoubleDoor49x60x22TopdownLR90,
      topdown_lR180: CoolerDoubleDoor49x60x22TopdownLR180,
      topdown_lR270: CoolerDoubleDoor49x60x22TopdownLR270,
      topdown_r: CoolerDoubleDoor49x60x22TopdownR,
      topdown_rR90: CoolerDoubleDoor49x60x22TopdownRR90,
      topdown_rR180: CoolerDoubleDoor49x60x22TopdownRR180,
      topdown_rR270: CoolerDoubleDoor49x60x22TopdownRR270,
    },
  },
  82: {
    key: '27fdcb85-54fd-4c58-94a8-0f781c98f9bb',
    id: 82,
    name: 'Single Door 24"',
    som: 'imperial',
    w: 23.5,
    d: 24.356,
    h: 48,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerSingleDoor24x48x24Front,
      topdown_l: CoolerSingleDoor24x48x24TopdownL,
      topdown_lR90: CoolerSingleDoor24x48x24TopdownLR90,
      topdown_lR180: CoolerSingleDoor24x48x24TopdownLR180,
      topdown_lR270: CoolerSingleDoor24x48x24TopdownLR270,
      topdown_r: CoolerSingleDoor24x48x24TopdownR,
      topdown_rR90: CoolerSingleDoor24x48x24TopdownRR90,
      topdown_rR180: CoolerSingleDoor24x48x24TopdownRR180,
      topdown_rR270: CoolerSingleDoor24x48x24TopdownRR270,
    },
  },
  83: {
    key: 'e05370e6-c549-4757-afda-e77c42dd80c0',
    id: 83,
    name: 'Single Door 25"',
    som: 'imperial',
    w: 25,
    d: 24.536,
    h: 58.176,
    category: 'walmartCoolers',
    type: 'fixture',
    renders: {
      front: CoolerSingleDoor25x58x24Front,
      topdown_l: CoolerSingleDoor25x58x24TopdownL,
      topdown_lR90: CoolerSingleDoor25x58x24TopdownLR90,
      topdown_lR180: CoolerSingleDoor25x58x24TopdownLR180,
      topdown_lR270: CoolerSingleDoor25x58x24TopdownLR270,
      topdown_r: CoolerSingleDoor25x58x24TopdownR,
      topdown_rR90: CoolerSingleDoor25x58x24TopdownRR90,
      topdown_rR180: CoolerSingleDoor25x58x24TopdownRR180,
      topdown_rR270: CoolerSingleDoor25x58x24TopdownRR270,
    },
  },
  26: {
    key: '6339d0c7-7665-45b9-a035-44bf28257cde',
    id: 26,
    name: '36" Racking',
    type: 'fixture',
    som: 'imperial',
    w: 37,
    d: 14,
    h: 36,
    category: 'queueWireRack',
    renders: {
      front: QueueSlimDS36Front,
      topdown_l: QueueSlimDS36TopdownL,
      topdown_lR90: QueueSlimDS36TopdownLR90,
      topdown_lR180: QueueSlimDS36TopdownLR180,
      topdown_lR270: QueueSlimDS36TopdownLR270,
      topdown_r: QueueSlimDS36TopdownR,
      topdown_rR90: QueueSlimDS36TopdownRR90,
      topdown_rR180: QueueSlimDS36TopdownRR180,
      topdown_rR270: QueueSlimDS36TopdownRR270,
    },
  },
  90: {
    key: 'b693a815-bbcd-4e6f-b8df-993c49f6cf6b',
    id: 90,
    name: '72" Racking',
    type: 'fixture',
    som: 'imperial',
    w: 73,
    d: 14,
    h: 36,
    category: 'queueWireRack',
    renders: {
      front: QueueSlimDS72Front,
      topdown_l: QueueSlimDS72TopdownL,
      topdown_lR90: QueueSlimDS72TopdownLR90,
      topdown_lR180: QueueSlimDS72TopdownLR180,
      topdown_lR270: QueueSlimDS72TopdownLR270,
      topdown_r: QueueSlimDS72TopdownR,
      topdown_rR90: QueueSlimDS72TopdownRR90,
      topdown_rR180: QueueSlimDS72TopdownRR180,
      topdown_rR270: QueueSlimDS72TopdownRR270,
    },
  },
  91: {
    key: 'd27e6a3a-9036-4306-a4d5-31c326931472',
    id: 91,
    name: '36" Racking + Basket',
    type: 'fixture',
    som: 'imperial',
    w: 45.221,
    d: 14,
    h: 43.063,
    category: 'queueWireRack',
    renders: {
      front: QueueSlimDSWithBasket36Front,
      topdown_l: QueueSlimDSWithBasket36TopdownL,
      topdown_lR90: QueueSlimDSWithBasket36TopdownLR90,
      topdown_lR180: QueueSlimDSWithBasket36TopdownLR180,
      topdown_lR270: QueueSlimDSWithBasket36TopdownLR270,
      topdown_r: QueueSlimDSWithBasket36TopdownR,
      topdown_rR90: QueueSlimDSWithBasket36TopdownRR90,
      topdown_rR180: QueueSlimDSWithBasket36TopdownRR180,
      topdown_rR270: QueueSlimDSWithBasket36TopdownRR270,
    },
  },
  92: {
    key: '1342e333-5b69-4f76-9b8b-d2d7dd1a4b45',
    id: 92,
    name: '72" Racking + Basket',
    type: 'fixture',
    som: 'imperial',
    w: 81.221,
    d: 14,
    h: 43.063,
    category: 'queueWireRack',
    renders: {
      front: QueueSlimDSWithBasket72Front,
      topdown_l: QueueSlimDSWithBasket72TopdownL,
      topdown_lR90: QueueSlimDSWithBasket72TopdownLR90,
      topdown_lR180: QueueSlimDSWithBasket72TopdownLR180,
      topdown_lR270: QueueSlimDSWithBasket72TopdownLR270,
      topdown_r: QueueSlimDSWithBasket72TopdownR,
      topdown_rR90: QueueSlimDSWithBasket72TopdownRR90,
      topdown_rR180: QueueSlimDSWithBasket72TopdownRR180,
      topdown_rR270: QueueSlimDSWithBasket72TopdownRR270,
    },
  },
  10: {
    key: 'b79f9bd6-9449-4114-8d05-a74f43d25e98',
    id: 10,
    name: '36" Racking',
    type: 'fixture',
    som: 'imperial',
    w: 51.223,
    d: 51.223,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL136Front,
      topdown_l: QueueSlimDSL136TopdownL,
      topdown_lR90: QueueSlimDSL136TopdownLR90,
      topdown_lR180: QueueSlimDSL136TopdownLR180,
      topdown_lR270: QueueSlimDSL136TopdownLR270,
      topdown_r: QueueSlimDSL136TopdownR,
      topdown_rR90: QueueSlimDSL136TopdownRR90,
      topdown_rR180: QueueSlimDSL136TopdownRR180,
      topdown_rR270: QueueSlimDSL136TopdownRR270,
    },
  },
  94: {
    key: '38a0afaa-fa2c-456d-b76a-23646b6e49aa',
    id: 94,
    name: '36" Racking + Basket',
    type: 'fixture',
    som: 'imperial',
    w: 55.334,
    d: 55.334,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL1WithBasket36Front,
      topdown_l: QueueSlimDSL1WithBasket36TopdownL,
      topdown_lR90: QueueSlimDSL1WithBasket36TopdownLR90,
      topdown_lR180: QueueSlimDSL1WithBasket36TopdownLR180,
      topdown_lR270: QueueSlimDSL1WithBasket36TopdownLR270,
      topdown_r: QueueSlimDSL1WithBasket36TopdownR,
      topdown_rR90: QueueSlimDSL1WithBasket36TopdownRR90,
      topdown_rR180: QueueSlimDSL1WithBasket36TopdownRR180,
      topdown_rR270: QueueSlimDSL1WithBasket36TopdownRR270,
    },
  },
  95: {
    key: '75d864b8-2ecf-4942-a532-f02be82db5be',
    id: 95,
    name: '72" Racking',
    type: 'fixture',
    som: 'imperial',
    w: 51.223,
    d: 87.223,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL172Front,
      topdown_l: QueueSlimDSL172TopdownL,
      topdown_lR90: QueueSlimDSL172TopdownLR90,
      topdown_lR180: QueueSlimDSL172TopdownLR180,
      topdown_lR270: QueueSlimDSL172TopdownLR270,
      topdown_r: QueueSlimDSL172TopdownR,
      topdown_rR90: QueueSlimDSL172TopdownRR90,
      topdown_rR180: QueueSlimDSL172TopdownRR180,
      topdown_rR270: QueueSlimDSL172TopdownRR270,
    },
  },
  96: {
    key: '3ef35c4e-614d-4566-8ce4-433f4e2036cb',
    id: 96,
    name: '72" Racking + Basket',
    type: 'fixture',
    som: 'imperial',
    w: 55.334,
    d: 91.334,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL1WithBasket72Front,
      topdown_l: QueueSlimDSL1WithBasket72TopdownL,
      topdown_lR90: QueueSlimDSL1WithBasket72TopdownLR90,
      topdown_lR180: QueueSlimDSL1WithBasket72TopdownLR180,
      topdown_lR270: QueueSlimDSL1WithBasket72TopdownLR270,
      topdown_r: QueueSlimDSL1WithBasket72TopdownR,
      topdown_rR90: QueueSlimDSL1WithBasket72TopdownRR90,
      topdown_rR180: QueueSlimDSL1WithBasket72TopdownRR180,
      topdown_rR270: QueueSlimDSL1WithBasket72TopdownRR270,
    },
  },
  97: {
    key: '78ed93aa-2713-4316-92e2-38b07b51b78b',
    id: 97,
    name: 'L2 Racking 72"',
    type: 'fixture',
    som: 'imperial',
    w: 51.223,
    d: 87.223,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL272Front,
      topdown_l: QueueSlimDSL272TopdownL,
      topdown_lR90: QueueSlimDSL272TopdownLR90,
      topdown_lR180: QueueSlimDSL272TopdownLR180,
      topdown_lR270: QueueSlimDSL272TopdownLR270,
      topdown_r: QueueSlimDSL272TopdownR,
      topdown_rR90: QueueSlimDSL272TopdownRR90,
      topdown_rR180: QueueSlimDSL272TopdownRR180,
      topdown_rR270: QueueSlimDSL272TopdownRR270,
    },
  },
  98: {
    key: 'b23437ff-64e0-4090-8c99-72df3fd06152',
    id: 98,
    name: 'L2 Racking + Basket 72"',
    type: 'fixture',
    som: 'imperial',
    w: 55.334,
    d: 91.334,
    h: 43.063,
    category: 'queueCornerWireRack',
    renders: {
      front: QueueSlimDSL2WithBasket72Front,
      topdown_l: QueueSlimDSL2WithBasket72TopdownL,
      topdown_lR90: QueueSlimDSL2WithBasket72TopdownLR90,
      topdown_lR180: QueueSlimDSL2WithBasket72TopdownLR180,
      topdown_lR270: QueueSlimDSL2WithBasket72TopdownLR270,
      topdown_r: QueueSlimDSL2WithBasket72TopdownR,
      topdown_rR90: QueueSlimDSL2WithBasket72TopdownRR90,
      topdown_rR180: QueueSlimDSL2WithBasket72TopdownRR180,
      topdown_rR270: QueueSlimDSL2WithBasket72TopdownRR270,
    },
  },
  40: {
    key: '692d7073-b72f-4818-b42b-360dde37af8c',
    id: 40,
    name: 'NCRR6C Self-Checkout No Bag',
    type: 'fixture',
    som: 'imperial',
    w: 20.501,
    d: 32.11,
    h: 94.213,
    category: 'selfCheck',
    renders: {
      front: SCONCR6NoBaggingFront,
      topdown_l: SCONCR6NoBaggingTopdownL,
      topdown_lR90: SCONCR6NoBaggingTopdownLR90,
      topdown_lR180: SCONCR6NoBaggingTopdownLR180,
      topdown_lR270: SCONCR6NoBaggingTopdownLR270,
      topdown_r: SCONCR6NoBaggingTopdownR,
      topdown_rR90: SCONCR6NoBaggingTopdownRR90,
      topdown_rR180: SCONCR6NoBaggingTopdownRR180,
      topdown_rR270: SCONCR6NoBaggingTopdownRR270,
    },
  },
  100: {
    key: 'd538b3b9-c65d-46c0-86f0-630cb9c14426',
    id: 100,
    name: 'NCRSS90 Self-Checkout',
    type: 'fixture',
    som: 'imperial',
    w: 21.26,
    d: 13.267,
    h: 56.378,
    category: 'selfCheck',
    renders: {
      front: SCONCRSelfServ90Front,
      topdown_l: SCONCRSelfServ90TopdownL,
      topdown_lR90: SCONCRSelfServ90TopdownLR90,
      topdown_lR180: SCONCRSelfServ90TopdownLR180,
      topdown_lR270: SCONCRSelfServ90TopdownLR270,
      topdown_r: SCONCRSelfServ90TopdownR,
      topdown_rR90: SCONCRSelfServ90TopdownRR90,
      topdown_rR180: SCONCRSelfServ90TopdownRR180,
      topdown_rR270: SCONCRSelfServ90TopdownRR270,
    },
  },
  106: {
    key: '20099',
    id: 106,
    name: 'NCRR6C Self-Checkout No Pole',
    type: 'fixture',
    som: 'imperial',
    w: 49.06,
    d: 32.11,
    h: 52.79,
    category: 'selfCheck',
    renders: {
      front: WMExpressSCORegisterFront,
      topdown_l: WMExpressSCORegisterTopdownL,
      topdown_lR90: WMExpressSCORegisterTopdownLR90,
      topdown_lR180: WMExpressSCORegisterTopdownLR180,
      topdown_lR270: WMExpressSCORegisterTopdownLR270,
      topdown_r: WMExpressSCORegisterTopdownR,
      topdown_rR90: WMExpressSCORegisterTopdownRR90,
      topdown_rR180: WMExpressSCORegisterTopdownRR180,
      topdown_rR270: WMExpressSCORegisterTopdownRR270,
    },
  },
  107: {
    key: '20112',
    id: 107,
    name: 'Double Door Top Rack 30"W',
    type: 'fixture',
    som: 'imperial',
    w: 30.8,
    d: 25.28,
    h: 57.34,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTopRackTVM30HCFront,
      topdown_l: CoolerDoubleDoorTopRackTVM30HCTopdownL,
      topdown_lR90: CoolerDoubleDoorTopRackTVM30HCTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTopRackTVM30HCTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTopRackTVM30HCTopdownLR270,
      topdown_r: CoolerDoubleDoorTopRackTVM30HCTopdownR,
      topdown_rR90: CoolerDoubleDoorTopRackTVM30HCTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTopRackTVM30HCTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTopRackTVM30HCTopdownRR270,
    },
  },
  108: {
    key: '20111',
    id: 108,
    name: 'Double Door Top Rack 36"W',
    type: 'fixture',
    som: 'imperial',
    w: 36,
    d: 21,
    h: 57.48,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTopRackTVM36SLFront,
      topdown_l: CoolerDoubleDoorTopRackTVM36SLTopdownL,
      topdown_lR90: CoolerDoubleDoorTopRackTVM36SLTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTopRackTVM36SLTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTopRackTVM36SLTopdownLR270,
      topdown_r: CoolerDoubleDoorTopRackTVM36SLTopdownR,
      topdown_rR90: CoolerDoubleDoorTopRackTVM36SLTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTopRackTVM36SLTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTopRackTVM36SLTopdownRR270,
    },
  },
  109: {
    key: '20110',
    id: 109,
    name: 'Double Door Top Rack 48"W',
    type: 'fixture',
    som: 'imperial',
    w: 46.875,
    d: 22.8,
    h: 50.43,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTopRackTVM48SLFront,
      topdown_l: CoolerDoubleDoorTopRackTVM48SLTopdownL,
      topdown_lR90: CoolerDoubleDoorTopRackTVM48SLTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTopRackTVM48SLTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTopRackTVM48SLTopdownLR270,
      topdown_r: CoolerDoubleDoorTopRackTVM48SLTopdownR,
      topdown_rR90: CoolerDoubleDoorTopRackTVM48SLTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTopRackTVM48SLTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTopRackTVM48SLTopdownRR270,
    },
  },
  110: {
    key: '20075',
    id: 110,
    name: 'Double Door 31"W',
    type: 'fixture',
    som: 'imperial',
    w: 30.8,
    d: 25.28,
    h: 54.85,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTVM30HCFront,
      topdown_l: CoolerDoubleDoorTVM30HCTopdownL,
      topdown_lR90: CoolerDoubleDoorTVM30HCTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTVM30HCTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTVM30HCTopdownLR270,
      topdown_r: CoolerDoubleDoorTVM30HCTopdownR,
      topdown_rR90: CoolerDoubleDoorTVM30HCTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTVM30HCTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTVM30HCTopdownRR270,
    },
  },
  111: {
    key: '20074',
    id: 111,
    name: 'Double Door 36"W',
    type: 'fixture',
    som: 'imperial',
    w: 36,
    d: 21,
    h: 55,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTVM36SLFront,
      topdown_l: CoolerDoubleDoorTVM36SLTopdownL,
      topdown_lR90: CoolerDoubleDoorTVM36SLTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTVM36SLTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTVM36SLTopdownLR270,
      topdown_r: CoolerDoubleDoorTVM36SLTopdownR,
      topdown_rR90: CoolerDoubleDoorTVM36SLTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTVM36SLTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTVM36SLTopdownRR270,
    },
  },
  112: {
    key: '20095',
    id: 112,
    name: 'Single Door Coke Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorCokeHeaderFront,
      topdown_l: CoolerSingleDoorCokeHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorCokeHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorCokeHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorCokeHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorCokeHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorCokeHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorCokeHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorCokeHeaderTopdownRR270,
    },
  },
  113: {
    key: '20094',
    id: 113,
    name: 'Single Door Coke Wrap',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorCokeWrapFront,
      topdown_l: CoolerSingleDoorCokeWrapTopdownL,
      topdown_lR90: CoolerSingleDoorCokeWrapTopdownLR90,
      topdown_lR180: CoolerSingleDoorCokeWrapTopdownLR180,
      topdown_lR270: CoolerSingleDoorCokeWrapTopdownLR270,
      topdown_r: CoolerSingleDoorCokeWrapTopdownR,
      topdown_rR90: CoolerSingleDoorCokeWrapTopdownRR90,
      topdown_rR180: CoolerSingleDoorCokeWrapTopdownRR180,
      topdown_rR270: CoolerSingleDoorCokeWrapTopdownRR270,
    },
  },
  114: {
    key: '20076',
    id: 114,
    name: 'Single Door 25"Wx54"H',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 19.45,
    h: 53.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorGDM10SSLFront,
      topdown_l: CoolerSingleDoorGDM10SSLTopdownL,
      topdown_lR90: CoolerSingleDoorGDM10SSLTopdownLR90,
      topdown_lR180: CoolerSingleDoorGDM10SSLTopdownLR180,
      topdown_lR270: CoolerSingleDoorGDM10SSLTopdownLR270,
      topdown_r: CoolerSingleDoorGDM10SSLTopdownR,
      topdown_rR90: CoolerSingleDoorGDM10SSLTopdownRR90,
      topdown_rR180: CoolerSingleDoorGDM10SSLTopdownRR180,
      topdown_rR270: CoolerSingleDoorGDM10SSLTopdownRR270,
    },
  },
  115: {
    key: '20100',
    id: 115,
    name: 'Single Door 25"Wx62"H',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorGDM12HCFront,
      topdown_l: CoolerSingleDoorGDM12HCTopdownL,
      topdown_lR90: CoolerSingleDoorGDM12HCTopdownLR90,
      topdown_lR180: CoolerSingleDoorGDM12HCTopdownLR180,
      topdown_lR270: CoolerSingleDoorGDM12HCTopdownLR270,
      topdown_r: CoolerSingleDoorGDM12HCTopdownR,
      topdown_rR90: CoolerSingleDoorGDM12HCTopdownRR90,
      topdown_rR180: CoolerSingleDoorGDM12HCTopdownRR180,
      topdown_rR270: CoolerSingleDoorGDM12HCTopdownRR270,
    },
  },
  116: {
    key: '20077',
    id: 116,
    name: 'Single Door 21"W',
    type: 'fixture',
    som: 'imperial',
    w: 20.66,
    d: 29.37,
    h: 33.52,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorGDM0634EXTFront,
      topdown_l: CoolerSingleDoorGDM0634EXTTopdownL,
      topdown_lR90: CoolerSingleDoorGDM0634EXTTopdownLR90,
      topdown_lR180: CoolerSingleDoorGDM0634EXTTopdownLR180,
      topdown_lR270: CoolerSingleDoorGDM0634EXTTopdownLR270,
      topdown_r: CoolerSingleDoorGDM0634EXTTopdownR,
      topdown_rR90: CoolerSingleDoorGDM0634EXTTopdownRR90,
      topdown_rR180: CoolerSingleDoorGDM0634EXTTopdownRR180,
      topdown_rR270: CoolerSingleDoorGDM0634EXTTopdownRR270,
    },
  },
  117: {
    key: '20097',
    id: 117,
    name: 'Single Door Monster Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorMonsterHeaderFront,
      topdown_l: CoolerSingleDoorMonsterHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorMonsterHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorMonsterHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorMonsterHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorMonsterHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorMonsterHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorMonsterHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorMonsterHeaderTopdownRR270,
    },
  },
  118: {
    key: '20103',
    id: 118,
    name: 'Single Door Monster Wrap',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorMonsterWrapFront,
      topdown_l: CoolerSingleDoorMonsterWrapTopdownL,
      topdown_lR90: CoolerSingleDoorMonsterWrapTopdownLR90,
      topdown_lR180: CoolerSingleDoorMonsterWrapTopdownLR180,
      topdown_lR270: CoolerSingleDoorMonsterWrapTopdownLR270,
      topdown_r: CoolerSingleDoorMonsterWrapTopdownR,
      topdown_rR90: CoolerSingleDoorMonsterWrapTopdownRR90,
      topdown_rR180: CoolerSingleDoorMonsterWrapTopdownRR180,
      topdown_rR270: CoolerSingleDoorMonsterWrapTopdownRR270,
    },
  },
  119: {
    key: '20096',
    id: 119,
    name: 'Single Door Pepsi Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorPepsiHeaderFront,
      topdown_l: CoolerSingleDoorPepsiHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorPepsiHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorPepsiHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorPepsiHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorPepsiHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorPepsiHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorPepsiHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorPepsiHeaderTopdownRR270,
    },
  },
  120: {
    key: '20102',
    id: 120,
    name: 'Single Door Pepsi Wrap',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 62.3,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorPepsiWrapFront,
      topdown_l: CoolerSingleDoorPepsiWrapTopdownL,
      topdown_lR90: CoolerSingleDoorPepsiWrapTopdownLR90,
      topdown_lR180: CoolerSingleDoorPepsiWrapTopdownLR180,
      topdown_lR270: CoolerSingleDoorPepsiWrapTopdownLR270,
      topdown_r: CoolerSingleDoorPepsiWrapTopdownR,
      topdown_rR90: CoolerSingleDoorPepsiWrapTopdownRR90,
      topdown_rR180: CoolerSingleDoorPepsiWrapTopdownRR180,
      topdown_rR270: CoolerSingleDoorPepsiWrapTopdownRR270,
    },
  },
  121: {
    key: '20121',
    id: 121,
    name: 'Single Door Saddle',
    type: 'fixture',
    som: 'imperial',
    w: 38.81,
    d: 20.94,
    h: 60,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorSaddleGDM10SSLFront,
      topdown_l: CoolerSingleDoorSaddleGDM10SSLTopdownL,
      topdown_lR90: CoolerSingleDoorSaddleGDM10SSLTopdownLR90,
      topdown_lR180: CoolerSingleDoorSaddleGDM10SSLTopdownLR180,
      topdown_lR270: CoolerSingleDoorSaddleGDM10SSLTopdownLR270,
      topdown_r: CoolerSingleDoorSaddleGDM10SSLTopdownR,
      topdown_rR90: CoolerSingleDoorSaddleGDM10SSLTopdownRR90,
      topdown_rR180: CoolerSingleDoorSaddleGDM10SSLTopdownRR180,
      topdown_rR270: CoolerSingleDoorSaddleGDM10SSLTopdownRR270,
    },
  },
  122: {
    key: '20117',
    id: 122,
    name: 'Single Door Top Rack Coke Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 65.46,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorTopRackCokeHeaderFront,
      topdown_l: CoolerSingleDoorTopRackCokeHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorTopRackCokeHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorTopRackCokeHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorTopRackCokeHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorTopRackCokeHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorTopRackCokeHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorTopRackCokeHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorTopRackCokeHeaderTopdownRR270,
    },
  },
  123: {
    key: '20113',
    id: 123,
    name: 'Single Door Top Rack 54"H',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 19.45,
    h: 55.64,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorTopRackGDM10SSLFront,
      topdown_l: CoolerSingleDoorTopRackGDM10SSLTopdownL,
      topdown_lR90: CoolerSingleDoorTopRackGDM10SSLTopdownLR90,
      topdown_lR180: CoolerSingleDoorTopRackGDM10SSLTopdownLR180,
      topdown_lR270: CoolerSingleDoorTopRackGDM10SSLTopdownLR270,
      topdown_r: CoolerSingleDoorTopRackGDM10SSLTopdownR,
      topdown_rR90: CoolerSingleDoorTopRackGDM10SSLTopdownRR90,
      topdown_rR180: CoolerSingleDoorTopRackGDM10SSLTopdownRR180,
      topdown_rR270: CoolerSingleDoorTopRackGDM10SSLTopdownRR270,
    },
  },
  124: {
    key: '20114',
    id: 124,
    name: 'Single Door Top Rack 62"H',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 64.46,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorTopRackGDM12HCFront,
      topdown_l: CoolerSingleDoorTopRackGDM12HCTopdownL,
      topdown_lR90: CoolerSingleDoorTopRackGDM12HCTopdownLR90,
      topdown_lR180: CoolerSingleDoorTopRackGDM12HCTopdownLR180,
      topdown_lR270: CoolerSingleDoorTopRackGDM12HCTopdownLR270,
      topdown_r: CoolerSingleDoorTopRackGDM12HCTopdownR,
      topdown_rR90: CoolerSingleDoorTopRackGDM12HCTopdownRR90,
      topdown_rR180: CoolerSingleDoorTopRackGDM12HCTopdownRR180,
      topdown_rR270: CoolerSingleDoorTopRackGDM12HCTopdownRR270,
    },
  },
  125: {
    key: '20115',
    id: 125,
    name: 'Single Door Top Rack Monster Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 64.46,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorTopRackMonsterHeaderFront,
      topdown_l: CoolerSingleDoorTopRackMonsterHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorTopRackMonsterHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorTopRackMonsterHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorTopRackMonsterHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorTopRackMonsterHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorTopRackMonsterHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorTopRackMonsterHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorTopRackMonsterHeaderTopdownRR270,
    },
  },
  126: {
    key: '20116',
    id: 126,
    name: 'Single Door Top Rack Pepsi Header',
    type: 'fixture',
    som: 'imperial',
    w: 24.8,
    d: 23.264,
    h: 64.46,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorTopRackPepsiHeaderFront,
      topdown_l: CoolerSingleDoorTopRackPepsiHeaderTopdownL,
      topdown_lR90: CoolerSingleDoorTopRackPepsiHeaderTopdownLR90,
      topdown_lR180: CoolerSingleDoorTopRackPepsiHeaderTopdownLR180,
      topdown_lR270: CoolerSingleDoorTopRackPepsiHeaderTopdownLR270,
      topdown_r: CoolerSingleDoorTopRackPepsiHeaderTopdownR,
      topdown_rR90: CoolerSingleDoorTopRackPepsiHeaderTopdownRR90,
      topdown_rR180: CoolerSingleDoorTopRackPepsiHeaderTopdownRR180,
      topdown_rR270: CoolerSingleDoorTopRackPepsiHeaderTopdownRR270,
    },
  },
  127: {
    key: '20106',
    id: 127,
    name: 'Double Door 48"W',
    type: 'fixture',
    som: 'imperial',
    w: 46.875,
    d: 22.8,
    h: 48,
    category: 'coolers',
    renders: {
      front: CoolerDoubleDoorTVM48SLFront,
      topdown_l: CoolerDoubleDoorTVM48SLTopdownL,
      topdown_lR90: CoolerDoubleDoorTVM48SLTopdownLR90,
      topdown_lR180: CoolerDoubleDoorTVM48SLTopdownLR180,
      topdown_lR270: CoolerDoubleDoorTVM48SLTopdownLR270,
      topdown_r: CoolerDoubleDoorTVM48SLTopdownR,
      topdown_rR90: CoolerDoubleDoorTVM48SLTopdownRR90,
      topdown_rR180: CoolerDoubleDoorTVM48SLTopdownRR180,
      topdown_rR270: CoolerDoubleDoorTVM48SLTopdownRR270,
    },
  },
  128: {
    key: '20122',
    id: 128,
    name: 'Single Door Saddle 2',
    type: 'fixture',
    som: 'imperial',
    w: 35.66,
    d: 19.87,
    h: 55.05,
    category: 'coolers',
    renders: {
      front: CoolerSingleDoorSaddle2GDM10SSLFront,
      topdown_l: CoolerSingleDoorSaddle2GDM10SSLTopdownL,
      topdown_lR90: CoolerSingleDoorSaddle2GDM10SSLTopdownLR90,
      topdown_lR180: CoolerSingleDoorSaddle2GDM10SSLTopdownLR180,
      topdown_lR270: CoolerSingleDoorSaddle2GDM10SSLTopdownLR270,
      topdown_r: CoolerSingleDoorSaddle2GDM10SSLTopdownR,
      topdown_rR90: CoolerSingleDoorSaddle2GDM10SSLTopdownRR90,
      topdown_rR180: CoolerSingleDoorSaddle2GDM10SSLTopdownRR180,
      topdown_rR270: CoolerSingleDoorSaddle2GDM10SSLTopdownRR270,
    },
  },
  129: {
    key: '20123',
    id: 129,
    name: 'Racking 24"Wx60"H',
    type: 'fixture',
    som: 'imperial',
    w: 25,
    d: 5.37,
    h: 60,
    category: 'racking',
    renders: {
      front: RackEndPanelWithSides24x60Front,
      topdown_l: RackEndPanelWithSides24x60TopdownL,
      topdown_lR90: RackEndPanelWithSides24x60TopdownLR90,
      topdown_lR180: RackEndPanelWithSides24x60TopdownLR180,
      topdown_lR270: RackEndPanelWithSides24x60TopdownLR270,
      topdown_r: RackEndPanelWithSides24x60TopdownR,
      topdown_rR90: RackEndPanelWithSides24x60TopdownRR90,
      topdown_rR180: RackEndPanelWithSides24x60TopdownRR180,
      topdown_rR270: RackEndPanelWithSides24x60TopdownRR270,
    },
  },
  30: {
    key: '20124',
    id: 30,
    name: 'Winged Endcap 38"Wx60"H',
    type: 'fixture',
    som: 'imperial',
    w: 38.81,
    d: 19.52,
    h: 60,
    category: 'rackingEndcap',
    renders: {
      front: RackEndcapWithSidesWingsFront,
      topdown_l: RackEndcapWithSidesWingsTopdownL,
      topdown_lR90: RackEndcapWithSidesWingsTopdownLR90,
      topdown_lR180: RackEndcapWithSidesWingsTopdownLR180,
      topdown_lR270: RackEndcapWithSidesWingsTopdownLR270,
      topdown_r: RackEndcapWithSidesWingsTopdownR,
      topdown_rR90: RackEndcapWithSidesWingsTopdownRR90,
      topdown_rR180: RackEndcapWithSidesWingsTopdownRR180,
      topdown_rR270: RackEndcapWithSidesWingsTopdownRR270,
    },
  },
  131: {
    key: '20125',
    id: 131,
    name: 'L2 NCRR6C Self-Checkout No Pole',
    type: 'fixture',
    som: 'imperial',
    w: 49.06,
    d: 32.11,
    h: 52.79,
    category: 'selfCheck',
    renders: {
      front: L2NCRR6CSelfCheckoutNoPoleFront,
      topdown_l: L2NCRR6CSelfCheckoutNoPoleTopdownL,
      topdown_lR90: L2NCRR6CSelfCheckoutNoPoleTopdownLR90,
      topdown_lR180: L2NCRR6CSelfCheckoutNoPoleTopdownLR180,
      topdown_lR270: L2NCRR6CSelfCheckoutNoPoleTopdownLR270,
      topdown_r: L2NCRR6CSelfCheckoutNoPoleTopdownR,
      topdown_rR90: L2NCRR6CSelfCheckoutNoPoleTopdownRR90,
      topdown_rR180: L2NCRR6CSelfCheckoutNoPoleTopdownRR180,
      topdown_rR270: L2NCRR6CSelfCheckoutNoPoleTopdownRR270,
    },
  },
  9: {
    key: '20127',
    id: 9,
    name: 'Lowes Appliance Divider',
    type: 'fixture',
    som: 'imperial',
    w: 28.125,
    d: 2.5,
    h: 50.5,
    category: 'lowes',
    renders: {
      topdown_l: LowesApplianceDividerTopDownL,
      topdown_r: LowesApplianceDividerTopDownR,
      front: LowesApplianceDividerFront,
      topdown_lR90: LowesApplianceDividerTopDownLR90,
      topdown_lR180: LowesApplianceDividerTopDownLR180,
      topdown_lR270: LowesApplianceDividerTopDownLR270,
      topdown_rR90: LowesApplianceDividerTopDownRR90,
      topdown_rR180: LowesApplianceDividerTopDownRR180,
      topdown_rR270: LowesApplianceDividerTopDownRR270,
    },
  },
  133: {
    key: '20128',
    id: 133,
    name: 'Lowes Appliance EndPanel',
    type: 'fixture',
    som: 'imperial',
    w: 60.5,
    d: 2.316,
    h: 54,
    category: 'lowes',
    renders: {
      topdown_l: LowesApplianceEndPanelTopDownL,
      topdown_r: LowesApplianceEndPanelTopDownR,
      front: LowesApplianceEndPanelFront,
      topdown_lR90: LowesApplianceEndPanelTopDownLR90,
      topdown_lR180: LowesApplianceEndPanelTopDownLR180,
      topdown_lR270: LowesApplianceEndPanelTopDownLR270,
      topdown_rR90: LowesApplianceEndPanelTopDownRR90,
      topdown_rR180: LowesApplianceEndPanelTopDownRR180,
      topdown_rR270: LowesApplianceEndPanelTopDownRR270,
    },
  },
  134: {
    key: '20129',
    id: 134,
    name: 'Lowes Appliance EndPanel Samsung',
    type: 'fixture',
    som: 'imperial',
    w: 59,
    d: 3.024,
    h: 67.683,
    category: 'lowes',
    renders: {
      topdown_l: LowesApplianceEndPanelSamsungTopDownL,
      topdown_r: LowesApplianceEndPanelSamsungTopDownR,
      front: LowesApplianceEndPanelSamsungFront,
      topdown_lR90: LowesApplianceEndPanelSamsungTopDownLR90,
      topdown_lR180: LowesApplianceEndPanelSamsungTopDownLR180,
      topdown_lR270: LowesApplianceEndPanelSamsungTopDownLR270,
      topdown_rR90: LowesApplianceEndPanelSamsungTopDownRR90,
      topdown_rR180: LowesApplianceEndPanelSamsungTopDownRR180,
      topdown_rR270: LowesApplianceEndPanelSamsungTopDownRR270,
    },
  },
  135: {
    key: '20130',
    id: 135,
    name: 'Lowes Appliance POS Shelving',
    type: 'fixture',
    som: 'imperial',
    w: 24.234,
    d: 18.418,
    h: 66.487,
    category: 'lowes',
    renders: {
      topdown_l: LowesAppliancePOSShelvingTopDownL,
      topdown_r: LowesAppliancePOSShelvingTopDownR,
      front: LowesAppliancePOSShelvingFront,
      topdown_lR90: LowesAppliancePOSShelvingTopDownLR90,
      topdown_lR180: LowesAppliancePOSShelvingTopDownLR180,
      topdown_lR270: LowesAppliancePOSShelvingTopDownLR270,
      topdown_rR90: LowesAppliancePOSShelvingTopDownRR90,
      topdown_rR180: LowesAppliancePOSShelvingTopDownRR180,
      topdown_rR270: LowesAppliancePOSShelvingTopDownRR270,
    },
  },
  136: {
    key: '20138',
    id: 136,
    name: 'Large Basket 60 Degree Left',
    type: 'fixture',
    som: 'imperial',
    w: 87.375,
    d: 55.205,
    h: 94.213,
    category: 'walmartSelfCheckout',
    renders: {
      topdown_l: LargeBasket60DegreeLeftTopDownL,
      topdown_r: LargeBasket60DegreeLeftTopDownR,
      front: LargeBasket60DegreeLeftFront,
      topdown_lR90: LargeBasket60DegreeLeftTopDownLR90,
      topdown_lR180: LargeBasket60DegreeLeftTopDownLR180,
      topdown_lR270: LargeBasket60DegreeLeftTopDownLR270,
      topdown_rR90: LargeBasket60DegreeLeftTopDownRR90,
      topdown_rR180: LargeBasket60DegreeLeftTopDownRR180,
      topdown_rR270: LargeBasket60DegreeLeftTopDownRR270,
    },
  },
  137: {
    key: '20139',
    id: 137,
    name: 'Large Basket 60 Degree Right',
    type: 'fixture',
    som: 'imperial',
    w: 87.375,
    d: 55.205,
    h: 94.213,
    category: 'walmartSelfCheckout',
    renders: {
      topdown_l: LargeBasket60DegreeRightTopDownL,
      topdown_r: LargeBasket60DegreeRightTopDownR,
      front: LargeBasket60DegreeRightFront,
      topdown_lR90: LargeBasket60DegreeRightTopDownLR90,
      topdown_lR180: LargeBasket60DegreeRightTopDownLR180,
      topdown_lR270: LargeBasket60DegreeRightTopDownLR270,
      topdown_rR90: LargeBasket60DegreeRightTopDownRR90,
      topdown_rR180: LargeBasket60DegreeRightTopDownRR180,
      topdown_rR270: LargeBasket60DegreeRightTopDownRR270,
    },
  },
  138: {
    key: '20142',
    id: 138,
    name: 'Racking OTB 30" Left',
    type: 'fixture',
    som: 'imperial',
    w: 32,
    d: 26.248,
    h: 60,
    category: 'rackingOverTheBelt',
    renders: {
      topdown_l: RackingOTB30LeftTopDownL,
      topdown_r: RackingOTB30LeftTopDownR,
      front: RackingOTB30LeftFront,
      topdown_lR90: RackingOTB30LeftTopDownLR90,
      topdown_lR180: RackingOTB30LeftTopDownLR180,
      topdown_lR270: RackingOTB30LeftTopDownLR270,
      topdown_rR90: RackingOTB30LeftTopDownRR90,
      topdown_rR180: RackingOTB30LeftTopDownRR180,
      topdown_rR270: RackingOTB30LeftTopDownRR270,
    },
  },
  139: {
    key: '20143',
    id: 139,
    name: 'Racking OTB 30" Right',
    type: 'fixture',
    som: 'imperial',
    w: 32,
    d: 26.248,
    h: 60,
    category: 'rackingOverTheBelt',
    renders: {
      topdown_l: RackingOTB30RightTopDownL,
      topdown_r: RackingOTB30RightTopDownR,
      front: RackingOTB30RightFront,
      topdown_lR90: RackingOTB30RightTopDownLR90,
      topdown_lR180: RackingOTB30RightTopDownLR180,
      topdown_lR270: RackingOTB30RightTopDownLR270,
      topdown_rR90: RackingOTB30RightTopDownRR90,
      topdown_rR180: RackingOTB30RightTopDownRR180,
      topdown_rR270: RackingOTB30RightTopDownRR270,
    },
  },
  29: {
    key: '20149',
    id: 29,
    name: 'Winged Endcap 30"',
    som: 'imperial',
    w: 32.5,
    d: 13,
    h: 58.3,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: WingedEndcap30Front,
      topdown_l: WingedEndcap30TopDownL,
      topdown_lR90: WingedEndcap30TopDownLR90,
      topdown_lR180: WingedEndcap30TopDownLR180,
      topdown_lR270: WingedEndcap30TopDownLR270,
      topdown_r: WingedEndcap30TopDownR,
      topdown_rR90: WingedEndcap30TopDownRR90,
      topdown_rR180: WingedEndcap30TopDownRR180,
      topdown_rR270: WingedEndcap30TopDownRR270,
    },
  },
  28: {
    key: '20153',
    id: 28,
    name: 'Endcap 30"',
    som: 'imperial',
    w: 30.5,
    d: 1,
    h: 58.3,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: Endcap30Front,
      topdown_l: Endcap30TopDownL,
      topdown_lR90: Endcap30TopDownLR90,
      topdown_lR180: Endcap30TopDownLR180,
      topdown_lR270: Endcap30TopDownLR270,
      topdown_r: Endcap30TopDownR,
      topdown_rR90: Endcap30TopDownRR90,
      topdown_rR180: Endcap30TopDownRR180,
      topdown_rR270: Endcap30TopDownRR270,
    },
  },
  142: {
    key: '20157',
    id: 142,
    name: 'Racking 30"',
    som: 'imperial',
    w: 30.5,
    d: 1,
    h: 58.3,
    category: 'racking',
    type: 'fixture',
    renders: {
      front: Racking30Front,
      topdown_l: Racking30TopDownL,
      topdown_lR90: Racking30TopDownLR90,
      topdown_lR180: Racking30TopDownLR180,
      topdown_lR270: Racking30TopDownLR270,
      topdown_r: Racking30TopDownR,
      topdown_rR90: Racking30TopDownRR90,
      topdown_rR180: Racking30TopDownRR180,
      topdown_rR270: Racking30TopDownRR270,
    },
  },
  143: {
    key: '20160',
    id: 143,
    name: 'Panel 15"',
    som: 'imperial',
    w: 15,
    d: 1.1,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: Panel15Front,
      topdown_l: Panel15TopDownL,
      topdown_lR90: Panel15TopDownLR90,
      topdown_lR180: Panel15TopDownLR180,
      topdown_lR270: Panel15TopDownLR270,
      topdown_r: Panel15TopDownR,
      topdown_rR90: Panel15TopDownRR90,
      topdown_rR180: Panel15TopDownRR180,
      topdown_rR270: Panel15TopDownRR270,
    },
  },
  161: {
    key: '20161',
    id: 161,
    name: 'Panel 30"',
    som: 'imperial',
    w: 30,
    d: 1.1,
    h: 48,
    category: 'walmartPerimiter',
    type: 'fixture',
    renders: {
      front: Panel30Front,
      topdown_l: Panel30TopDownL,
      topdown_lR90: Panel30TopDownLR90,
      topdown_lR180: Panel30TopDownLR180,
      topdown_lR270: Panel30TopDownLR270,
      topdown_r: Panel30TopDownR,
      topdown_rR90: Panel30TopDownRR90,
      topdown_rR180: Panel30TopDownRR180,
      topdown_rR270: Panel30TopDownRR270,
    },
  },
  69: {
    key: '20162',
    id: 69,
    name: 'Gondola 30x48',
    som: 'imperial',
    w: 30,
    d: 2,
    h: 48,
    category: 'walmartGondola',
    type: 'fixture',
    renders: {
      front: Gondola30Front,
      topdown_l: Gondola30TopDownL,
      topdown_lR90: Gondola30TopDownLR90,
      topdown_lR180: Gondola30TopDownLR180,
      topdown_lR270: Gondola30TopDownLR270,
      topdown_r: Gondola30TopDownR,
      topdown_rR90: Gondola30TopDownRR90,
      topdown_rR180: Gondola30TopDownRR180,
      topdown_rR270: Gondola30TopDownRR270,
    },
  },
  2: {
    key: '20195',
    id: 2,
    name: 'Shelving 24',
    som: 'imperial',
    w: 25,
    d: 14,
    h: 54,
    category: 'displayShelvingWithSides',
    type: 'fixture',
    renders: {
      front: DisplayShelvingWithSidesShelving24Front,
      topdown_l: DisplayShelvingWithSidesShelving24TopDownL,
      topdown_lR90: DisplayShelvingWithSidesShelving24TopDownLR90,
      topdown_lR180: DisplayShelvingWithSidesShelving24TopDownLR180,
      topdown_lR270: DisplayShelvingWithSidesShelving24TopDownLR270,
      topdown_r: DisplayShelvingWithSidesShelving24TopDownR,
      topdown_rR90: DisplayShelvingWithSidesShelving24TopDownRR90,
      topdown_rR180: DisplayShelvingWithSidesShelving24TopDownRR180,
      topdown_rR270: DisplayShelvingWithSidesShelving24TopDownRR270,
    },
  },
  147: {
    key: '20196',
    id: 147,
    name: 'Shelving 36',
    som: 'imperial',
    w: 37,
    d: 14,
    h: 54,
    category: 'displayShelvingWithSides',
    type: 'fixture',
    renders: {
      front: DisplayShelvingWithSidesShelving36Front,
      topdown_l: DisplayShelvingWithSidesShelving36TopDownL,
      topdown_lR90: DisplayShelvingWithSidesShelving36TopDownLR90,
      topdown_lR180: DisplayShelvingWithSidesShelving36TopDownLR180,
      topdown_lR270: DisplayShelvingWithSidesShelving36TopDownLR270,
      topdown_r: DisplayShelvingWithSidesShelving36TopDownR,
      topdown_rR90: DisplayShelvingWithSidesShelving36TopDownRR90,
      topdown_rR180: DisplayShelvingWithSidesShelving36TopDownRR180,
      topdown_rR270: DisplayShelvingWithSidesShelving36TopDownRR270,
    },
  },
  148: {
    key: '20197',
    id: 148,
    name: 'Shelving 48',
    som: 'imperial',
    w: 49,
    d: 14,
    h: 54,
    category: 'displayShelvingWithSides',
    type: 'fixture',
    renders: {
      front: DisplayShelvingWithSidesShelving48Front,
      topdown_l: DisplayShelvingWithSidesShelving48TopDownL,
      topdown_lR90: DisplayShelvingWithSidesShelving48TopDownLR90,
      topdown_lR180: DisplayShelvingWithSidesShelving48TopDownLR180,
      topdown_lR270: DisplayShelvingWithSidesShelving48TopDownLR270,
      topdown_r: DisplayShelvingWithSidesShelving48TopDownR,
      topdown_rR90: DisplayShelvingWithSidesShelving48TopDownRR90,
      topdown_rR180: DisplayShelvingWithSidesShelving48TopDownRR180,
      topdown_rR270: DisplayShelvingWithSidesShelving48TopDownRR270,
    },
  },
  4: {
    key: '20188',
    id: 4,
    name: 'Cellphone Cables',
    som: 'imperial',
    w: 16.353,
    d: 14.874,
    h: 68.734,
    category: 'floorDisplays',
    type: 'fixture',
    renders: {
      front: FloorDisplaysCellphoneCablesFront,
      topdown_l: FloorDisplaysCellphoneCablesTopDownL,
      topdown_lR90: FloorDisplaysCellphoneCablesTopDownLR90,
      topdown_lR180: FloorDisplaysCellphoneCablesTopDownLR180,
      topdown_lR270: FloorDisplaysCellphoneCablesTopDownLR270,
      topdown_r: FloorDisplaysCellphoneCablesTopDownR,
      topdown_rR90: FloorDisplaysCellphoneCablesTopDownRR90,
      topdown_rR180: FloorDisplaysCellphoneCablesTopDownRR180,
      topdown_rR270: FloorDisplaysCellphoneCablesTopDownRR270,
    },
  },
  150: {
    key: '20207',
    id: 150,
    name: 'Merchandising Tower',
    som: 'imperial',
    w: 20.67,
    d: 22.859,
    h: 68,
    category: 'floorDisplays',
    type: 'fixture',
    renders: {
      front: FloorDisplaysMerchandisingTowerFront,
      topdown_l: FloorDisplaysMerchandisingTowerTopDownL,
      topdown_lR90: FloorDisplaysMerchandisingTowerTopDownLR90,
      topdown_lR180: FloorDisplaysMerchandisingTowerTopDownLR180,
      topdown_lR270: FloorDisplaysMerchandisingTowerTopDownLR270,
      topdown_r: FloorDisplaysMerchandisingTowerTopDownR,
      topdown_rR90: FloorDisplaysMerchandisingTowerTopDownRR90,
      topdown_rR180: FloorDisplaysMerchandisingTowerTopDownRR180,
      topdown_rR270: FloorDisplaysMerchandisingTowerTopDownRR270,
    },
  },
  151: {
    key: '20210',
    id: 151,
    name: 'Merchandising Tower non-Shoppable',
    som: 'imperial',
    w: 20.67,
    d: 22.859,
    h: 68,
    category: 'floorDisplays',
    type: 'fixture',
    renders: {
      front: FloorDisplaysMerchandisingTowerNonShoppableFront,
      topdown_l: FloorDisplaysMerchandisingTowerNonShoppableTopDownL,
      topdown_lR90: FloorDisplaysMerchandisingTowerNonShoppableTopDownLR90,
      topdown_lR180: FloorDisplaysMerchandisingTowerNonShoppableTopDownLR180,
      topdown_lR270: FloorDisplaysMerchandisingTowerNonShoppableTopDownLR270,
      topdown_r: FloorDisplaysMerchandisingTowerNonShoppableTopDownR,
      topdown_rR90: FloorDisplaysMerchandisingTowerNonShoppableTopDownRR90,
      topdown_rR180: FloorDisplaysMerchandisingTowerNonShoppableTopDownRR180,
      topdown_rR270: FloorDisplaysMerchandisingTowerNonShoppableTopDownRR270,
    },
  },
  152: {
    key: '20221',
    id: 152,
    name: 'NCRR6C Self-Checkout FastLane',
    som: 'imperial',
    w: 74.008,
    d: 32.11,
    h: 92.213,
    category: 'selfCheck',
    type: 'fixture',
    renders: {
      front: SelfCheckFastLaneNCRR6CFront,
      topdown_l: SelfCheckFastLaneNCRR6CTopdownL,
      topdown_lR90: SelfCheckFastLaneNCRR6CTopdownLR90,
      topdown_lR180: SelfCheckFastLaneNCRR6CTopdownLR180,
      topdown_lR270: SelfCheckFastLaneNCRR6CTopdownLR270,
      topdown_r: SelfCheckFastLaneNCRR6CTopdownR,
      topdown_rR90: SelfCheckFastLaneNCRR6CTopdownRR90,
      topdown_rR180: SelfCheckFastLaneNCRR6CTopdownRR180,
      topdown_rR270: SelfCheckFastLaneNCRR6CTopdownRR270,
    },
  },
  153: {
    key: '20222',
    id: 153,
    name: 'L2 NCRR6C Self-Checkout FastLane',
    som: 'imperial',
    w: 74.008,
    d: 32.11,
    h: 92.213,
    category: 'selfCheck',
    type: 'fixture',
    renders: {
      front: SelfCheckFastLaneL2NCRR6CFront,
      topdown_l: SelfCheckFastLaneL2NCRR6CTopdownL,
      topdown_lR90: SelfCheckFastLaneL2NCRR6CTopdownLR90,
      topdown_lR180: SelfCheckFastLaneL2NCRR6CTopdownLR180,
      topdown_lR270: SelfCheckFastLaneL2NCRR6CTopdownLR270,
      topdown_r: SelfCheckFastLaneL2NCRR6CTopdownR,
      topdown_rR90: SelfCheckFastLaneL2NCRR6CTopdownRR90,
      topdown_rR180: SelfCheckFastLaneL2NCRR6CTopdownRR180,
      topdown_rR270: SelfCheckFastLaneL2NCRR6CTopdownRR270,
    },
  },
  93: {
    key: '20222',
    id: 93,
    name: 'Frame Style Endcap',
    som: 'imperial',
    w: 37.839,
    d: 25.468,
    h: 59.465,
    category: 'rackingEndcap',
    type: 'fixture',
    renders: {
      front: FrameStyleEndcapFront,
      topdown_l: FrameStyleEndcapTopDownL,
      topdown_lR90: FrameStyleEndcapTopDownLR90,
      topdown_lR180: FrameStyleEndcapTopDownLR180,
      topdown_lR270: FrameStyleEndcapTopDownLR270,
      topdown_r: FrameStyleEndcapTopDownR,
      topdown_rR90: FrameStyleEndcapTopDownRR90,
      topdown_rR180: FrameStyleEndcapTopDownRR180,
      topdown_rR270: FrameStyleEndcapTopDownRR270,
    },
  },
  11: {
    key: '20223',
    id: 11,
    name: 'Queue 24"Wx48"H',
    som: 'imperial',
    w: 24,
    d: 3,
    h: 48,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLineDoubleSided24Front,
      topdown_l: QueueLineDoubleSided24TopdownL,
      topdown_lR90: QueueLineDoubleSided24TopdownLR90,
      topdown_lR180: QueueLineDoubleSided24TopdownLR180,
      topdown_lR270: QueueLineDoubleSided24TopdownLR270,
      topdown_r: QueueLineDoubleSided24TopdownR,
      topdown_rR90: QueueLineDoubleSided24TopdownRR90,
      topdown_rR180: QueueLineDoubleSided24TopdownRR180,
      topdown_rR270: QueueLineDoubleSided24TopdownRR270,
    },
  },
  156: {
    key: '20224',
    id: 156,
    name: 'Queue 30"Wx48"H',
    som: 'imperial',
    w: 30,
    d: 3,
    h: 48,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLineDoubleSided30Front,
      topdown_l: QueueLineDoubleSided30TopdownL,
      topdown_lR90: QueueLineDoubleSided30TopdownLR90,
      topdown_lR180: QueueLineDoubleSided30TopdownLR180,
      topdown_lR270: QueueLineDoubleSided30TopdownLR270,
      topdown_r: QueueLineDoubleSided30TopdownR,
      topdown_rR90: QueueLineDoubleSided30TopdownRR90,
      topdown_rR180: QueueLineDoubleSided30TopdownRR180,
      topdown_rR270: QueueLineDoubleSided30TopdownRR270,
    },
  },
  157: {
    key: '20225',
    id: 157,
    name: 'Queue 36"Wx48"H',
    som: 'imperial',
    w: 36,
    d: 3,
    h: 48,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLineDoubleSided36Front,
      topdown_l: QueueLineDoubleSided36TopdownL,
      topdown_lR90: QueueLineDoubleSided36TopdownLR90,
      topdown_lR180: QueueLineDoubleSided36TopdownLR180,
      topdown_lR270: QueueLineDoubleSided36TopdownLR270,
      topdown_r: QueueLineDoubleSided36TopdownR,
      topdown_rR90: QueueLineDoubleSided36TopdownRR90,
      topdown_rR180: QueueLineDoubleSided36TopdownRR180,
      topdown_rR270: QueueLineDoubleSided36TopdownRR270,
    },
  },
  158: {
    key: '20226',
    id: 158,
    name: 'Queue 48"Wx48"H',
    som: 'imperial',
    w: 48,
    d: 3,
    h: 48,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLineDoubleSided48Front,
      topdown_l: QueueLineDoubleSided48TopdownL,
      topdown_lR90: QueueLineDoubleSided48TopdownLR90,
      topdown_lR180: QueueLineDoubleSided48TopdownLR180,
      topdown_lR270: QueueLineDoubleSided48TopdownLR270,
      topdown_r: QueueLineDoubleSided48TopdownR,
      topdown_rR90: QueueLineDoubleSided48TopdownRR90,
      topdown_rR180: QueueLineDoubleSided48TopdownRR180,
      topdown_rR270: QueueLineDoubleSided48TopdownRR270,
    },
  },
  159: {
    key: '20227',
    id: 159,
    name: 'Toshiba Self-Checkout',
    som: 'imperial',
    w: 61.89,
    d: 26.56,
    h: 51.87,
    category: 'selfCheck',
    type: 'fixture',
    renders: {
      front: SelfCheckToshiba20227Front,
      topdown_l: SelfCheckToshiba20227TopdownL,
      topdown_lR90: SelfCheckToshiba20227TopdownLR90,
      topdown_lR180: SelfCheckToshiba20227TopdownLR180,
      topdown_lR270: SelfCheckToshiba20227TopdownLR270,
      topdown_r: SelfCheckToshiba20227TopdownR,
      topdown_rR90: SelfCheckToshiba20227TopdownRR90,
      topdown_rR180: SelfCheckToshiba20227TopdownRR180,
      topdown_rR270: SelfCheckToshiba20227TopdownRR270,
    },
  },
  160: {
    key: '20228',
    id: 160,
    name: 'L2 Toshiba Self-Checkout',
    som: 'imperial',
    w: 61.89,
    d: 26.56,
    h: 51.87,
    category: 'selfCheck',
    type: 'fixture',
    renders: {
      front: SelfCheckToshibaL220228Front,
      topdown_l: SelfCheckToshibaL220228TopdownL,
      topdown_lR90: SelfCheckToshibaL220228TopdownLR90,
      topdown_lR180: SelfCheckToshibaL220228TopdownLR180,
      topdown_lR270: SelfCheckToshibaL220228TopdownLR270,
      topdown_r: SelfCheckToshibaL220228TopdownR,
      topdown_rR90: SelfCheckToshibaL220228TopdownRR90,
      topdown_rR180: SelfCheckToshibaL220228TopdownRR180,
      topdown_rR270: SelfCheckToshibaL220228TopdownRR270,
    },
  },
  55: {
    key: '20219',
    id: 55,
    name: 'Checklane with Cooler',
    som: 'imperial',
    w: 232.539,
    d: 56.389,
    h: 88.521,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      front: TraditionalChecklaneWithCoolerEndNonShopable20219Front,
      topdown_l: TraditionalChecklaneWithCoolerEndNonShopable20219TopdownL,
      topdown_lR90:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR90,
      topdown_lR180:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR180,
      topdown_lR270:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownLR270,
      topdown_r: TraditionalChecklaneWithCoolerEndNonShopable20219TopdownR,
      topdown_rR90:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR90,
      topdown_rR180:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR180,
      topdown_rR270:
        TraditionalChecklaneWithCoolerEndNonShopable20219TopdownRR270,
    },
  },
  162: {
    key: '20220',
    id: 162,
    name: 'Checklane with Gift Card',
    som: 'imperial',
    w: 226.188,
    d: 53.574,
    h: 88.521,
    category: 'traditionalBeltCheckouts',
    type: 'fixture',
    renders: {
      front: TraditionalChecklaneWithCoolerEndNonShopable20220Front,
      topdown_l: TraditionalChecklaneWithCoolerEndNonShopable20220TopdownL,
      topdown_lR90:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR90,
      topdown_lR180:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR180,
      topdown_lR270:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownLR270,
      topdown_r: TraditionalChecklaneWithCoolerEndNonShopable20220TopdownR,
      topdown_rR90:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR90,
      topdown_rR180:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR180,
      topdown_rR270:
        TraditionalChecklaneWithCoolerEndNonShopable20220TopdownRR270,
    },
  },
  163: {
    key: '20236',
    id: 163,
    name: 'Queue 24"Wx54"H',
    som: 'imperial',
    w: 24,
    d: 3,
    h: 54,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20236Front,
      topdown_l: QueueLine20236TopdownL,
      topdown_lR90: QueueLine20236TopdownLR90,
      topdown_lR180: QueueLine20236TopdownLR180,
      topdown_lR270: QueueLine20236TopdownLR270,
      topdown_r: QueueLine20236TopdownR,
      topdown_rR90: QueueLine20236TopdownRR90,
      topdown_rR180: QueueLine20236TopdownRR180,
      topdown_rR270: QueueLine20236TopdownRR270,
    },
  },
  164: {
    key: '20237',
    id: 164,
    name: 'Queue 30"Wx54"H',
    som: 'imperial',
    w: 30,
    d: 3,
    h: 54,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20237Front,
      topdown_l: QueueLine20237TopdownL,
      topdown_lR90: QueueLine20237TopdownLR90,
      topdown_lR180: QueueLine20237TopdownLR180,
      topdown_lR270: QueueLine20237TopdownLR270,
      topdown_r: QueueLine20237TopdownR,
      topdown_rR90: QueueLine20237TopdownRR90,
      topdown_rR180: QueueLine20237TopdownRR180,
      topdown_rR270: QueueLine20237TopdownRR270,
    },
  },
  165: {
    key: '20238',
    id: 165,
    name: 'Queue 36"Wx54"H',
    som: 'imperial',
    w: 36,
    d: 3,
    h: 54,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20238Front,
      topdown_l: QueueLine20238TopdownL,
      topdown_lR90: QueueLine20238TopdownLR90,
      topdown_lR180: QueueLine20238TopdownLR180,
      topdown_lR270: QueueLine20238TopdownLR270,
      topdown_r: QueueLine20238TopdownR,
      topdown_rR90: QueueLine20238TopdownRR90,
      topdown_rR180: QueueLine20238TopdownRR180,
      topdown_rR270: QueueLine20238TopdownRR270,
    },
  },
  166: {
    key: '20239',
    id: 166,
    name: 'Queue 48"Wx54"H',
    som: 'imperial',
    w: 48,
    d: 3,
    h: 54,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20239Front,
      topdown_l: QueueLine20239TopdownL,
      topdown_lR90: QueueLine20239TopdownLR90,
      topdown_lR180: QueueLine20239TopdownLR180,
      topdown_lR270: QueueLine20239TopdownLR270,
      topdown_r: QueueLine20239TopdownR,
      topdown_rR90: QueueLine20239TopdownRR90,
      topdown_rR180: QueueLine20239TopdownRR180,
      topdown_rR270: QueueLine20239TopdownRR270,
    },
  },
  167: {
    key: '20240',
    id: 167,
    name: 'Queue 24"Wx60"H',
    som: 'imperial',
    w: 24,
    d: 3,
    h: 60,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20240Front,
      topdown_l: QueueLine20240TopdownL,
      topdown_lR90: QueueLine20240TopdownLR90,
      topdown_lR180: QueueLine20240TopdownLR180,
      topdown_lR270: QueueLine20240TopdownLR270,
      topdown_r: QueueLine20240TopdownR,
      topdown_rR90: QueueLine20240TopdownRR90,
      topdown_rR180: QueueLine20240TopdownRR180,
      topdown_rR270: QueueLine20240TopdownRR270,
    },
  },
  168: {
    key: '20241',
    id: 168,
    name: 'Queue 30"Wx60"H',
    som: 'imperial',
    w: 30,
    d: 3,
    h: 60,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20241Front,
      topdown_l: QueueLine20241TopdownL,
      topdown_lR90: QueueLine20241TopdownLR90,
      topdown_lR180: QueueLine20241TopdownLR180,
      topdown_lR270: QueueLine20241TopdownLR270,
      topdown_r: QueueLine20241TopdownR,
      topdown_rR90: QueueLine20241TopdownRR90,
      topdown_rR180: QueueLine20241TopdownRR180,
      topdown_rR270: QueueLine20241TopdownRR270,
    },
  },
  169: {
    key: '20242',
    id: 169,
    name: 'Queue 36"Wx60"H',
    som: 'imperial',
    w: 36,
    d: 3,
    h: 60,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20242Front,
      topdown_l: QueueLine20242TopdownL,
      topdown_lR90: QueueLine20242TopdownLR90,
      topdown_lR180: QueueLine20242TopdownLR180,
      topdown_lR270: QueueLine20242TopdownLR270,
      topdown_r: QueueLine20242TopdownR,
      topdown_rR90: QueueLine20242TopdownRR90,
      topdown_rR180: QueueLine20242TopdownRR180,
      topdown_rR270: QueueLine20242TopdownRR270,
    },
  },
  170: {
    key: '20243',
    id: 170,
    name: 'Queue 48"Wx60"H',
    som: 'imperial',
    w: 48,
    d: 3,
    h: 60,
    category: 'queueLine',
    type: 'fixture',
    renders: {
      front: QueueLine20243Front,
      topdown_l: QueueLine20243TopdownL,
      topdown_lR90: QueueLine20243TopdownLR90,
      topdown_lR180: QueueLine20243TopdownLR180,
      topdown_lR270: QueueLine20243TopdownLR270,
      topdown_r: QueueLine20243TopdownR,
      topdown_rR90: QueueLine20243TopdownRR90,
      topdown_rR180: QueueLine20243TopdownRR180,
      topdown_rR270: QueueLine20243TopdownRR270,
    },
  },
};
