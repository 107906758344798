import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, handleError } from '../../../api/base';
import ProductLibraryService, {
  ModelDownloadCronJobsResponse,
} from '../../../api/product-library';
import { CronJobModel, CronJobSummaryModel } from '../../../models/cron-job';

interface CreateCronJobParams {
  fileType: 'obj' | 'c4d' | 'png' | 'front_png';
  products: string[];
}

export interface ModelDownloadState {
  cronJobSummary: CronJobSummaryModel | null;
  cronJobList: { cronJobs: CronJobModel[] | null; hasMetaData: boolean } | null;
  loading: boolean;
  error: any;
}

const initialState: ModelDownloadState = {
  cronJobSummary: null,
  cronJobList: null,
  loading: false,
  error: null,
};

export const createCronJob = createAsyncThunk(
  'modelDownload/createCronJob',
  async (
    { fileType, products }: CreateCronJobParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ProductLibraryService().createModelDownloadCronJob({
        fileType,
        req: products,
      });

      dispatch(fetchCronJobs());
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cancelOrDeleteDownload = createAsyncThunk(
  'modelDownload/cancelOrDeleteDownload',
  async (
    { cronJobId }: { cronJobId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await ProductLibraryService().cancelModelDownloadCronJob(
        cronJobId
      );

      dispatch(fetchCronJobs());

      return resp.notifications;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCronJobs = createAsyncThunk(
  'modelDownload/fetchCronJobs',
  async (_, { rejectWithValue }) => {
    try {
      let cronJobs: CronJobModel[] = [];
      let hasMetaData = false;
      const cronJobResponse: ModelDownloadCronJobsResponse =
        await ProductLibraryService().listModelDownloadCronJobs();
      if (cronJobResponse.meta) {
        cronJobs = cronJobResponse.meta.jobs;
        hasMetaData = true;
      } else {
        cronJobs = cronJobResponse.data;
      }

      return { cronJobs, hasMetaData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const modelDownloadSlice = createSlice({
  name: 'modelDownload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCronJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCronJob.fulfilled, (state, action) => {
        state.cronJobSummary = action.payload;
        state.loading = false;
      })
      .addCase(createCronJob.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.loading = false;
      });

    builder
      .addCase(cancelOrDeleteDownload.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelOrDeleteDownload.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(cancelOrDeleteDownload.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.loading = false;
      });

    builder
      .addCase(fetchCronJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCronJobs.fulfilled, (state, action) => {
        state.cronJobList = action.payload;
        state.loading = false;
      })
      .addCase(fetchCronJobs.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.loading = false;
      });
  },
});

export default modelDownloadSlice.reducer;
