import { Result } from 'antd';
import { Link } from 'react-router-dom';

import Button from '../../elements/Button';
import ViewWrapper from '../../elements/ViewWrapper';

const PageNotFoundView = () => {
  return (
    <ViewWrapper>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back To Home</Button>
          </Link>
        }
      />
    </ViewWrapper>
  );
};

export default PageNotFoundView;
