import { BASE_API_URL } from '../../config';
import { ListApiResponse, del, get, patch, post } from '../base';
import {
  CreateCustomFieldOptionRequest,
  CustomFieldFormOptionsResponse,
  CustomFieldOptionResponse,
  CustomFieldOptionsResponse,
  CustomFieldResponse,
  CustomFieldsResponse,
  UpdateCustomFieldOptionRequest,
} from './reqres';

export default function MetaDataService() {
  return Object.freeze({
    listCustomFields(query?: string): Promise<CustomFieldsResponse> {
      return get(`${BASE_API_URL()}/field${query}`) as Promise<ListApiResponse>;
    },
    getCustomField(customFieldKey: string): Promise<CustomFieldResponse> {
      return get(`${BASE_API_URL()}/field/${customFieldKey}`);
    },
    listCustomFieldOptionsByKey(
      customFieldKey: string,
      query?: string
    ): Promise<CustomFieldOptionsResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option${query}`
      ) as Promise<ListApiResponse>;
    },
    getCustomFieldOption(
      customFieldKey: string,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`
      );
    },
    createCustomFieldOption(
      customFieldKey: string,
      req: CreateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return post(`${BASE_API_URL()}/field/${customFieldKey}/option`, req);
    },
    updateCustomFieldOption(
      customFieldKey: string,
      optionId: number,
      req: UpdateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return patch(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`,
        req
      );
    },
    deleteCustomFieldOption(
      customFieldKey: string,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return del(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`
      );
    },
    getCustomFieldFormOptions(): Promise<CustomFieldFormOptionsResponse> {
      return get(
        `${BASE_API_URL()}/values/custom_field_value_type,custom_field_type`
      );
    },
  });
}
