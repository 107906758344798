import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, MenuProps, Space } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BASE_API_URL } from '../../../config';
import { ListProductLibraryModel } from '../../../models/product-library/index';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  createCronJob,
  fetchCronJobs,
} from '../../../store/features/modelDownload/modelDownloadSlice';
import {
  convertParamsToQuery,
  getAuthToken,
  getMePermissions,
  UserPermissions,
} from '../../../util';
import Button from '../../elements/Button';
import Spinner from '../../elements/Spinner/Spinner';
import DownloadItem from '../DownloadItem';
import './DropdownMenu.less';

interface DropdownMenuProps {
  requireSelectedProduct: boolean;
  productItem?: ListProductLibraryModel;
}

const DropdownMenu = ({
  requireSelectedProduct,
  productItem,
}: DropdownMenuProps) => {
  const dispatch = useAppDispatch();
  const { selectedProducts } = useAppSelector((state) => state.productList);
  const { cronJobList } = useAppSelector((state) => state.modelDownload);
  const [disabled, setDisabled] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const loadingRef = useRef(false);

  const currentUserPermissions = useMemo(() => getMePermissions(), []);

  useEffect(() => {
    setDisabled(selectedProducts.length === 0);
  }, [selectedProducts]);

  const startCronJobFetching = useCallback(() => {
    dispatch(fetchCronJobs());
  }, [dispatch]);

  useEffect(() => {
    if (dropdownVisible) {
      startCronJobFetching();
    }
  }, [dropdownVisible, startCronJobFetching]);

  useEffect(() => {
    const allJobsCompleted = cronJobList?.cronJobs?.every(
      (cronJob) => cronJob?.status !== 'processing'
    );

    if (allJobsCompleted) {
      loadingRef.current = false;
    }
  }, [cronJobList]);

  const handleMenuClick: MenuProps['onClick'] = useMemo(
    () => (e: any) => {
      loadingRef.current = true;
      const queryStr = convertParamsToQuery({ _token: getAuthToken() });
      const products = productItem
        ? [productItem.uuid]
        : selectedProducts.map((product) => product.uuid);
      const downloadLinkEl = document.createElement('a');

      switch (e.key) {
        case '1':
          downloadLinkEl.href = `${BASE_API_URL()}/product-list/download/csv${queryStr}`;
          downloadLinkEl.click();
          downloadLinkEl.remove();
          break;
        case '2':
          dispatch(createCronJob({ fileType: 'obj', products }));
          break;
        case '3':
          dispatch(createCronJob({ fileType: 'c4d', products }));
          break;
        case '4':
          dispatch(createCronJob({ fileType: 'png', products }));
          break;
        case '5':
          dispatch(createCronJob({ fileType: 'front_png', products }));
          break;
        default:
          break;
      }
    },
    [dispatch, productItem, selectedProducts]
  );

  const renderMenuItems = useCallback(() => {
    const items = [];

    if (currentUserPermissions.includes(UserPermissions.ModelDownloadCsv)) {
      items.push(<Menu.Item key="1">Download CSV</Menu.Item>);
    }
    if (currentUserPermissions.includes(UserPermissions.ModelDownloadRsg)) {
      items.push(
        <Menu.Item key="2" disabled={disabled && requireSelectedProduct}>
          Download RSG
        </Menu.Item>
      );
    }
    if (currentUserPermissions.includes(UserPermissions.ModelDownloadC4d)) {
      items.push(
        <Menu.Item key="3" disabled={disabled && requireSelectedProduct}>
          Download C4D
        </Menu.Item>
      );
    }
    if (currentUserPermissions.includes(UserPermissions.ModelDownloadAllPng)) {
      items.push(
        <Menu.Item key="4" disabled={disabled && requireSelectedProduct}>
          Download All PNG
        </Menu.Item>
      );
    }
    if (
      currentUserPermissions.includes(UserPermissions.ModelDownloadFrontPng)
    ) {
      items.push(
        <Menu.Item
          key="5"
          disabled={disabled && requireSelectedProduct}
          style={{
            paddingBottom: '1rem',
            borderBottom: `${cronJobList} ? 1px solid #333 : null`,
          }}
        >
          Download Front PNG
        </Menu.Item>
      );
    }

    items.push(
      <Menu.Item className="downloads-dropdown-menu-item" key="cron-jobs">
        {cronJobList?.cronJobs
          ?.filter((cronJob) => cronJob.status !== 'processing')
          .map((cronJob) => (
            <DownloadItem key={cronJob.job_id} cronJob={cronJob} />
          ))}
        {loadingRef.current && <Spinner />}
      </Menu.Item>
    );

    return items;
  }, [currentUserPermissions, disabled, requireSelectedProduct, cronJobList]);

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick} className="downloads-dropdown-menu">
        {renderMenuItems()}
      </Menu>
    ),
    [handleMenuClick, renderMenuItems]
  );

  return (
    <Dropdown
      overlay={menu}
      overlayClassName="downloads-dropdown"
      trigger={['click']}
      onVisibleChange={setDropdownVisible}
    >
      <Button type="primary">
        <Space>
          Download
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default DropdownMenu;
