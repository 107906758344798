import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError } from '../../../api/base';
import OnlineVirtualResearchService from '../../../api/online-virtual-research';
import {
  CellGroupModel,
  CellSide,
  OvrProjectModel,
  OVRProjectStatsModel,
  ProjectCellModel,
} from '../../../models/online-virtual-research';
import {
  convertNewLinesToHtml,
  convertParamsToQuery,
} from '../../../util/converter';

export interface OvrProjectDetailsState {
  ovrProject: OvrProjectModel | undefined;
  ovrProjectStats: OVRProjectStatsModel | undefined;
  projectCellMedia: any;
  ovrProjectCellGroups: CellGroupModel[] | undefined;
  fetchingOvrProject: boolean;
  fetchingOvrProjectStats: boolean;
  updatingOvrProject: boolean;
  deletingCellGroup: boolean;
  deletingProjectCell: boolean;
  error: { code: number; details?: string; message: string } | null;
  isUpdatingProjectCell: boolean;
  isAddingProjectCell: boolean;
  selectedProjectCellId: string | undefined;
}

interface OvrProjectParams {
  _columns?: string;
  startDate?: string;
  endDate?: string;
}

const initialState: OvrProjectDetailsState = {
  ovrProject: undefined,
  ovrProjectStats: undefined,
  ovrProjectCellGroups: undefined,
  projectCellMedia: undefined,
  fetchingOvrProject: false,
  fetchingOvrProjectStats: false,
  updatingOvrProject: false,
  deletingCellGroup: false,
  deletingProjectCell: false,
  isUpdatingProjectCell: false,
  isAddingProjectCell: false,
  error: null,
  selectedProjectCellId: undefined,
};

const ovrProjectParams = {
  _columns: '*,client.*,createdByUser.*,cellGroups.*,cellGroups.cell.*',
};

const sortProjectCellGroups = (p: OvrProjectModel): OvrProjectModel => {
  const sortedCellGroups = p.cellGroups.sort((a, b) => {
    if (a.group_id > b.group_id) return 1;
    if (a.group_id < b.group_id) return -1;
    return 0;
  });

  return {
    ...p,
    cellGroups: sortedCellGroups,
  };
};

export const updateOvrProject = createAsyncThunk(
  'ovrProjectDetails/updateOvrProject',
  async (
    {
      projectId,
      updateData,
      params,
    }: {
      projectId: string;
      updateData: Partial<OvrProjectModel>;
      params?: OvrProjectParams;
    },
    { rejectWithValue }
  ) => {
    try {
      let combinedParams = ovrProjectParams;

      if (params) {
        combinedParams = {
          ...combinedParams,
          ...params,
        };
      }

      const queryString = convertParamsToQuery(combinedParams);

      const resp = await OnlineVirtualResearchService().updateOvrProject(
        projectId,
        updateData,
        queryString
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOvrProjectStats = createAsyncThunk(
  'ovrProjectDetails/fetchOvrProjectStats',
  async (
    {
      projectId,
      params,
    }: {
      projectId: string;
      params?: OvrProjectParams;
    },
    { rejectWithValue }
  ) => {
    try {
      const combinedParams = {
        ...params,
      };

      const queryString = convertParamsToQuery(combinedParams);

      const resp = await OnlineVirtualResearchService().getOVRProjectStats(
        projectId,
        queryString
      );

      return resp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOvrProject = createAsyncThunk(
  'ovrProjectDetails/fetchOvrProject',
  async (
    {
      projectId,
      params,
    }: {
      projectId: string;
      params?: OvrProjectParams;
    },
    { rejectWithValue }
  ) => {
    try {
      let combinedParams = ovrProjectParams;

      if (params) {
        combinedParams = {
          ...combinedParams,
          ...params,
        };
      }

      const queryString = convertParamsToQuery(combinedParams);

      const resp = await OnlineVirtualResearchService().getOVRProject(
        projectId,
        queryString
      );

      // Convert newlines to HTML in project cell messages
      const projectWithFormattedMessages = {
        ...resp.data,
        cellGroups: resp.data.cellGroups.map((group: CellGroupModel) => ({
          ...group,
          projectCells: group.projectCells.map((cell: ProjectCellModel) => ({
            ...cell,
            message: convertNewLinesToHtml(cell.message),
          })),
        })),
      };

      return sortProjectCellGroups(projectWithFormattedMessages);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProjectCell = createAsyncThunk(
  'ovrProjectDetails/addProjectCell',
  async (
    {
      projectId,
      projectCellIndex,
      projectCell,
    }: {
      projectId: string;
      projectCellIndex: number;
      projectCell: {
        group_id: number;
        message: string;
        cell: string;
        startLocation: CellSide;
      };
    },
    { rejectWithValue }
  ) => {
    const queryParams = convertParamsToQuery({
      _columns: 'cell.*',
    });

    try {
      const resp = await OnlineVirtualResearchService().addProjectCell(
        projectId,
        projectCell,
        queryParams
      );

      return { data: resp.data, projectCellIndex };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectCell = createAsyncThunk(
  'ovrProjectDetails/deleteProjectCell',
  async (
    {
      projectId,
      projectCellId,
      projectCellIndex,
      cellGroupId,
    }: {
      projectId: string;
      projectCellId: string;
      projectCellIndex: number;
      cellGroupId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      await OnlineVirtualResearchService().deleteProjectCell(
        projectId,
        projectCellId
      );

      return { cellGroupId, projectCellIndex };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProjectCell = createAsyncThunk(
  'ovrProjectDetails/updateProjectCell',
  async (
    {
      projectId,
      projectCellId,
      projectCellIndex,
      updatedProjectCell,
    }: {
      projectId: string;
      projectCellIndex: number;
      projectCellId: string;
      updatedProjectCell: {
        group_id: number;
        message: string;
        ovr_cell_id: string;
        startLocation: CellSide;
      };
    },
    { rejectWithValue }
  ) => {
    const queryParams = convertParamsToQuery({
      _columns: 'cell.*',
    });

    try {
      const resp = await OnlineVirtualResearchService().updateProjectCell(
        projectId,
        projectCellId,
        updatedProjectCell,
        queryParams
      );

      return { data: resp.data, projectCellIndex };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCellGroup = createAsyncThunk(
  'ovrProjectDetails/deleteCellGroup',
  async (
    {
      projectId,
      cellGroupId,
    }: {
      projectId: string;
      cellGroupId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await OnlineVirtualResearchService().deleteCellGroup(
        projectId,
        cellGroupId
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOvrProjectCellGroups = createAsyncThunk(
  'ovrProjectDetails/getOvrProjectCellGroups',
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp =
        await OnlineVirtualResearchService().listOvrProjectCellGroups(
          projectId
        );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectCell = createAsyncThunk(
  'ovrProjectDetails/fetchProjectCell',
  async (
    {
      projectId,
      projectCellId,
    }: {
      projectId: string;
      projectCellId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const resp = await OnlineVirtualResearchService().getProjectCell(
        projectId,
        projectCellId
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ovrProjectDetailsSlice = createSlice({
  name: 'ovrProjectDetails',
  initialState,
  reducers: {
    setProjectCellId(state, { payload }: { payload: string }) {
      state.selectedProjectCellId = payload;
    },
    updateOvrProjectLocally(state, { payload }: { payload: OvrProjectModel }) {
      state.ovrProject = {
        ...state.ovrProject,
        ...payload,
      };
    },
    updateCellGroupsLocally(state, { payload }: { payload: CellGroupModel[] }) {
      state.ovrProject = {
        ...state.ovrProject,
        cellGroups: payload,
      } as OvrProjectModel;
    },
    updateProjectCellsLocally(
      state,
      {
        payload,
      }: {
        payload: { groupId: number; updatedProjectCells: ProjectCellModel[] };
      }
    ) {
      const { groupId, updatedProjectCells } = payload;
      // find the cell group to update
      const updatedCellGroups: CellGroupModel[] | undefined =
        state.ovrProject?.cellGroups.map((group) => {
          if (group.group_id === groupId) {
            return {
              ...group,
              projectCells: updatedProjectCells,
            } as CellGroupModel;
          }
          return group;
        });

      state.ovrProject = {
        ...state.ovrProject,
        cellGroups: updatedCellGroups,
      } as OvrProjectModel;
    },

    clearOvrProjectDetails(state) {
      state.ovrProject = undefined;
    },
    removeLastCell(state, { payload: { cellGroupId } }) {
      const updatedCellGroups = state.ovrProject?.cellGroups.reduce(
        (acc: any, group) => {
          if (group.group_id === cellGroupId) {
            const updatedProjectCells = group.projectCells.slice(0, -1);
            if (updatedProjectCells.length > 0) {
              acc.push({
                ...(group as CellGroupModel),
                projectCells: updatedProjectCells as ProjectCellModel[],
              } as CellGroupModel);
            }
          } else {
            acc.push(group);
          }
          return acc;
        },
        []
      );

      state.ovrProject = {
        ...state.ovrProject,
        cellGroups: updatedCellGroups as CellGroupModel[] | [],
      } as OvrProjectModel;
    },
    removeCellGroup(state, { payload: { cellGroupId } }) {
      const updatedCellGroups = state.ovrProject?.cellGroups.filter(
        (group) => group.group_id !== cellGroupId
      );

      state.ovrProject = {
        ...state.ovrProject,
        cellGroups: updatedCellGroups as CellGroupModel[] | [],
      } as OvrProjectModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOvrProjectStats.pending, (state) => {
        state.fetchingOvrProjectStats = true;
        state.error = null;
      })
      .addCase(fetchOvrProjectStats.fulfilled, (state, action) => {
        state.fetchingOvrProjectStats = false;
        state.error = null;
        state.ovrProjectStats = action.payload;
      })
      .addCase(fetchOvrProjectStats.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }

        state.fetchingOvrProjectStats = false;
      })

      .addCase(fetchOvrProject.pending, (state) => {
        state.fetchingOvrProject = true;
        state.error = null;
      })
      .addCase(fetchOvrProject.fulfilled, (state, action) => {
        state.fetchingOvrProject = false;
        state.error = null;
        state.ovrProject = action.payload;
      })
      .addCase(fetchOvrProject.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }

        state.fetchingOvrProject = false;
      })
      .addCase(updateOvrProject.pending, (state) => {
        state.updatingOvrProject = true;
        state.error = null;
      })
      .addCase(updateOvrProject.fulfilled, (state, action) => {
        state.ovrProject = action.payload;
        state.updatingOvrProject = false;
        state.error = null;
      })
      .addCase(updateOvrProject.rejected, (state, action) => {
        state.updatingOvrProject = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })

      .addCase(addProjectCell.pending, (state) => {
        state.isAddingProjectCell = true;
        state.error = null;
      })
      .addCase(addProjectCell.fulfilled, (state, action) => {
        const { data: addedProjectCell, projectCellIndex } = action.payload;
        state.isAddingProjectCell = false;
        state.error = null;

        const updatedCellGroups = state.ovrProject?.cellGroups.map((group) => {
          if (group.group_id === addedProjectCell.group_id) {
            const newProjectCells = [...group.projectCells];

            if (
              projectCellIndex >= 0 &&
              projectCellIndex < newProjectCells.length
            ) {
              newProjectCells[projectCellIndex] = addedProjectCell;
            } else {
              newProjectCells.push(addedProjectCell);
            }

            return {
              ...group,
              projectCells: newProjectCells,
            };
          }
          return group;
        });

        state.ovrProject = {
          ...state.ovrProject,
          cellGroups: updatedCellGroups,
        } as OvrProjectModel;
      })
      .addCase(addProjectCell.rejected, (state, action) => {
        state.isAddingProjectCell = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })

      .addCase(deleteProjectCell.fulfilled, (state, action) => {
        state.deletingProjectCell = false;
        state.error = null;
        const { cellGroupId, projectCellIndex } = action.payload;

        if (!state.ovrProject || !state.ovrProject.cellGroups) {
          console.error(
            'state.ovrProject or state.ovrProject.cellGroups is undefined'
          );
          return;
        }

        const updatedCellGroups = state.ovrProject.cellGroups.map(
          (cellGroup) => {
            if (cellGroup.group_id === cellGroupId) {
              if (
                projectCellIndex >= 0 &&
                projectCellIndex < cellGroup.projectCells.length
              ) {
                const newProjectCells = cellGroup.projectCells.filter(
                  (_, i) => i !== projectCellIndex
                );

                return {
                  ...cellGroup,
                  projectCells: newProjectCells,
                };
              } else {
                console.error('projectCellIndex out of range');
              }
            }
            return cellGroup;
          }
        );

        state.ovrProject = {
          ...state.ovrProject,
          cellGroups: updatedCellGroups,
        } as OvrProjectModel;
      })

      .addCase(deleteProjectCell.rejected, (state, action) => {
        state.deletingProjectCell = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })
      .addCase(deleteProjectCell.pending, (state) => {
        state.deletingProjectCell = true;
        state.error = null;
      })

      .addCase(updateProjectCell.pending, (state) => {
        state.isUpdatingProjectCell = true;
        state.error = null;
      })
      .addCase(updateProjectCell.fulfilled, (state, action) => {
        const { data: updatedProjectCell, projectCellIndex } = action.payload;

        state.isUpdatingProjectCell = false;
        state.error = null;

        const updatedCellGroups = state.ovrProject?.cellGroups.map((group) => {
          if (group.group_id === updatedProjectCell.group_id) {
            if (
              projectCellIndex >= 0 &&
              projectCellIndex < group.projectCells.length
            ) {
              const newProjectCells = [...group.projectCells];
              newProjectCells[projectCellIndex] = updatedProjectCell;

              return {
                ...group,
                projectCells: newProjectCells,
              };
            }
          }
          return group;
        });

        state.ovrProject = {
          ...state.ovrProject,
          cellGroups: updatedCellGroups,
        } as OvrProjectModel;
      })
      .addCase(updateProjectCell.rejected, (state, action) => {
        state.isUpdatingProjectCell = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })
      .addCase(deleteCellGroup.fulfilled, (state) => {
        state.deletingCellGroup = false;
        state.error = null;
      })
      .addCase(deleteCellGroup.pending, (state) => {
        state.deletingCellGroup = true;
        state.error = null;
      })
      .addCase(deleteCellGroup.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.deletingCellGroup = false;
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })

      .addCase(fetchProjectCell.pending, (state) => {
        state.fetchingOvrProject = true;
        state.error = null;
      })
      .addCase(fetchProjectCell.fulfilled, (state, action) => {
        state.fetchingOvrProject = false;
        state.error = null;
        if (action.payload) {
          state.projectCellMedia = action.payload;
        }
      })
      .addCase(fetchProjectCell.rejected, (state, action) => {
        state.fetchingOvrProject = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      });
  },
});

// Selector to get media files by project cell ID
export const selectMediaFilesByCellId = (state: RootState, cellId: string) => {
  if (!state.ovrProjectDetails.ovrProject) return [];

  // Flattening all cell groups to get individual cells
  const allCells = state.ovrProjectDetails.ovrProject.cellGroups.flatMap(
    (group) => group.projectCells
  );

  // Finding the specific cell by ID
  const cell = allCells.find((c) => c.uuid === cellId);

  // Returning the media files if available
  return cell?.media_files || [];
};

export const {
  clearOvrProjectDetails,
  updateOvrProjectLocally,
  updateCellGroupsLocally,
  updateProjectCellsLocally,
  setProjectCellId,
  removeLastCell,
  removeCellGroup,
} = ovrProjectDetailsSlice.actions;
