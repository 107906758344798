import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiError, handleError } from '../../../api/base';
import CustomFieldService from '../../../api/custom-field';
import ProductItemService, {
  UpdateProductItemRequest,
} from '../../../api/product-item';
import ProductLibraryService from '../../../api/product-library';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import {
  ListProductLibraryModel,
  ProductBrandModel,
  ProductCategoryModel,
  ProductMediaFileModel,
} from '../../../models/product-library';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

interface ProductItemError {
  code: number | null;
  message: string | null;
}

export interface ProductItemState {
  value: ListProductLibraryModel | null;
  mediaFiles: ProductMediaFileModel[];
  imageUrl: string | undefined;
  categories: ProductCategoryModel[];
  fetchingProductItem: boolean;
  updatingProductId: string | null;
  error: ProductItemError;
  brands: ProductBrandModel[] | null;
}

const initialState: ProductItemState = {
  value: null,
  mediaFiles: [],
  imageUrl: undefined,
  categories: [],
  fetchingProductItem: false,
  updatingProductId: null,
  error: { code: null, message: null },
  brands: null,
};

interface UpdateProductItemProps {
  productId: string;
  product: UpdateProductItemRequest;
}

export const fetchProductItem = createAsyncThunk(
  'productItem/fetchProductItem',
  async (productId: string, { rejectWithValue }) => {
    try {
      const params: ListProductLibraryParams = {
        _limit: 30,
        _offset: 0,
        _columns:
          'customValues.*,categories.*,drc.*,brand.*,brand.manufacturers.*,identifiers.*,media_files.*',
      };
      const queryString = productId + convertParamsToQuery(params);
      const resp = await ProductItemService().getProductItem(queryString);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProductItemCategories = createAsyncThunk(
  'productItem/fetchProductItemCategories',
  async (productId: string, { rejectWithValue }) => {
    try {
      const params: ListProductLibraryParams = {
        _limit: 30,
        _offset: 0,
        _columns:
          'customValues.*,categories.*,drc.*,brand.*,brand.manufacturers.*,identifiers.*,media_files.*',
      };
      const queryString = productId + convertParamsToQuery(params);
      const resp = await ProductItemService().getProductItem(queryString);
      return resp.data.categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProductItemMediaFilesAndImageUrl = createAsyncThunk(
  'productItem/fetchProductItemMediaFilesAndImageUrl',
  async (productId: string, { rejectWithValue }) => {
    try {
      const params: ListProductLibraryParams = {
        _limit: 30,
        _offset: 0,
        _columns:
          'customValues.*,categories.*,drc.*,brand.*,brand.manufacturers.*,identifiers.*,media_files.*',
      };
      const queryString = productId + convertParamsToQuery(params);
      const resp = await ProductItemService().getProductItem(queryString);
      const mediaFiles = resp.data.media_files;
      const imageUrl = resp.data.image_url;

      return { mediaFiles, imageUrl };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductItem = createAsyncThunk(
  'productItem/deleteProductItem',
  async (productId: string, { rejectWithValue }) => {
    try {
      const resp = await ProductItemService().deleteProductItem(productId);
      return resp.notifications;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductItem = createAsyncThunk(
  'productItem/updateProductItem',
  async (
    { productId, product }: UpdateProductItemProps,
    { rejectWithValue }
  ) => {
    try {
      const resp = await ProductItemService().updateProductItem(
        productId,
        product
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBrands = createAsyncThunk(
  'productItem/fetchBrands',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await ProductItemService().getBrands();
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMarkets = createAsyncThunk(
  'productItem/fetchMarkets',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await CustomFieldService().getCustomField('market');
      return resp?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeDrcFromProduct = createAsyncThunk(
  'productItem/removeDrcFromProduct',
  async (
    { drcId, productId }: { drcId: string; productId: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await ProductLibraryService().updateProduct(productId, {
        drc_delete: [drcId],
      });
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeCategoryFromProduct = createAsyncThunk(
  'productItem/removeCategoryFromProduct',
  async (
    { categoryId, productId }: { categoryId: string; productId: string },
    { dispatch, rejectWithValue }
  ) => {
    const params: ListProductLibraryParams = {
      _limit: 30,
      _offset: 0,
      _columns:
        'customValues.*,categories.*,drc.*,brand.*,brand.manufacturers.*,identifiers.*,media_files.*',
    };

    const queryString = productId + convertParamsToQuery(params);

    try {
      const resp = await ProductLibraryService().updateProduct(queryString, {
        categories_delete: [categoryId],
      });
      await dispatch(fetchProductItemCategories(productId));
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const productItemSlice = createSlice({
  name: 'productItem',
  initialState,
  reducers: {
    setUpdatingProductId: (state, action) => {
      state.updatingProductId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductItem.pending, (state) => {
        state.fetchingProductItem = true;
      })
      .addCase(fetchProductItem.fulfilled, (state, action) => {
        state.fetchingProductItem = false;
        state.value = action.payload;
      })
      .addCase(fetchProductItem.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductItem = false;
      });

    builder
      .addCase(fetchProductItemCategories.pending, () => {
        // state.fetchingProductItem = true;
      })
      .addCase(fetchProductItemCategories.fulfilled, (state, action) => {
        if (action.payload) {
          state.categories = action.payload;
        }
      })
      .addCase(fetchProductItemCategories.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        // state.fetchingProductItem = false;
      });

    builder
      .addCase(fetchProductItemMediaFilesAndImageUrl.pending, () => {
        // state.fetchingProductItem = true;
      })
      .addCase(
        fetchProductItemMediaFilesAndImageUrl.fulfilled,
        (state, action) => {
          // state.fetchingProductItem = false;
          if (action.payload.mediaFiles && action.payload.imageUrl) {
            state.mediaFiles = action.payload.mediaFiles;
            state.imageUrl = action.payload.imageUrl;
          }
        }
      )
      .addCase(
        fetchProductItemMediaFilesAndImageUrl.rejected,
        (state, action) => {
          if (action.error.message) {
            if ((action.payload as unknown as ApiError).error) {
              state.error = (action.payload as unknown as ApiError).error;
              handleError(action.payload as unknown as ApiError);
            }
          }
          // state.fetchingProductItem = false;
        }
      );

    builder
      .addCase(deleteProductItem.pending, (state) => {
        state.fetchingProductItem = true;
      })
      .addCase(deleteProductItem.fulfilled, (state) => {
        state.fetchingProductItem = false;
      })
      .addCase(deleteProductItem.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductItem = false;
      });

    builder
      .addCase(fetchBrands.pending, (state) => {
        state.fetchingProductItem = true;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.fetchingProductItem = false;
        state.brands = action.payload;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductItem = false;
      });

    builder
      .addCase(removeDrcFromProduct.pending, () => {})
      .addCase(removeDrcFromProduct.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(removeDrcFromProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
      });

    builder
      .addCase(removeCategoryFromProduct.pending, () => {})
      .addCase(removeCategoryFromProduct.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(removeCategoryFromProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
      });
  },
});

export const getProductItem = (state: RootState) => state.productItem.value;
export const getBrands = (state: RootState) => state.productItem.brands;

export const getFetchingProductItem = (state: RootState) =>
  state.productItem.fetchingProductItem;
export const getFetchingProductLibraryError = (state: RootState) =>
  state.productItem.error;

export const { setUpdatingProductId } = productItemSlice.actions;
export default productItemSlice.reducer;
