import { UploadFile } from 'antd';
import Papa from 'papaparse';
import { uploadFile } from '../../../store/features/uploadModel/uploadModel';

export const getDirPath = (fileUpload: UploadFile): string =>
  fileUpload.originFileObj?.webkitRelativePath.replace(
    `/${fileUpload.name}`,
    ''
  ) || '';

// Function to extract the quality value from a CSV file
export const extractQualityFromCsv = (
  csvFile: UploadFile
): Promise<string | null> => {
  return new Promise<string | null>((resolve, reject) => {
    const originFileObj = csvFile?.originFileObj;
    if (!originFileObj) return resolve(null);

    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target?.result as string;

      Papa.parse(csvData, {
        header: true,
        complete: (results) => {
          const data = results.data as any[];
          if (data.length > 0) {
            resolve(data[0]['quality'] || null); // Extract the quality field
          } else {
            resolve(null);
          }
        },
        error: (error: any) => {
          reject(error);
        },
      });
    };

    reader.onerror = (error) => reject(error);

    reader.readAsText(originFileObj);
  });
};

export const pairJsonAndC4dFiles = async (
  infoJsonFiles: UploadFile[],
  c4dFiles: UploadFile[]
): Promise<FormData> => {
  const formData = new FormData();

  for (const infoJsonFile of infoJsonFiles) {
    const originFileObj = infoJsonFile?.originFileObj;
    if (!originFileObj) continue;

    const dirPath = getDirPath(infoJsonFile);
    const matchedC4DFile = c4dFiles.find(
      (c4dFile) => getDirPath(c4dFile) === dirPath
    );

    if (matchedC4DFile) {
      // Append the JSON file to the FormData
      formData.append(`json[${matchedC4DFile.name}]`, originFileObj);
    }
  }

  return formData;
};

// Updated function to pair CSV and C4D files and include quality in FormData
export const pairCsvAndC4dFiles = async (
  infoCsvFiles: UploadFile[],
  c4dFiles: UploadFile[]
): Promise<FormData> => {
  const formData = new FormData();

  for (const infoCsvFile of infoCsvFiles) {
    const originFileObj = infoCsvFile?.originFileObj;
    if (!originFileObj) continue;

    const dirPath = getDirPath(infoCsvFile);
    const matchedC4DFile = c4dFiles.find(
      (c4dFile) => getDirPath(c4dFile) === dirPath
    );

    if (matchedC4DFile) {
      // Extract the quality value from the CSV file
      const quality = await extractQualityFromCsv(infoCsvFile);

      // Append the CSV file and its quality to the FormData
      formData.append(`csv[${matchedC4DFile.name}]`, originFileObj);
      if (quality) {
        formData.append(`quality[${matchedC4DFile.name}]`, quality);
      }
    }
  }

  return formData;
};

export const uploadFilesToS3 = async (
  uuid: string,
  targetDirectory: string,
  modelsFilesList: UploadFile[],
  dispatch: any,
  uploadType: 'replaced' | 'both' | 'added' = 'added',
  setCurrentFileIndex: React.Dispatch<React.SetStateAction<number>>,
  setCurrentReplaceFileIndex: React.Dispatch<React.SetStateAction<number>>
): Promise<{ fileName: string; url: string }[]> => {
  let mediaVersionId = '';
  const previewImageUrls = [];

  for (let index = 0; index < modelsFilesList.length; index++) {
    const file = modelsFilesList[index];

    if (
      !getDirPath(file).includes(targetDirectory) ||
      file.name.includes('.DS_Store')
    ) {
      continue;
    }

    let fileName = file.name;
    if (file.originFileObj?.webkitRelativePath.includes('tex/')) {
      fileName = `tex/${file.name}`;
    }
    if (file.originFileObj?.webkitRelativePath.includes('JDA/')) {
      fileName = `JDA/${file.name}`;
    }

    const mediaUploadResponse = await dispatch(
      uploadFile({ fileName, uuid, mediaVersionId, file })
    ).unwrap();

    if (file.name.includes('.png')) {
      if (mediaUploadResponse?.data?.media_url) {
        previewImageUrls.push({
          fileName: file.name,
          url: mediaUploadResponse?.data.media_url,
        });
      } else {
        previewImageUrls.push({
          fileName: file.name,
          url: '',
        });
      }
    }

    if (!mediaVersionId && mediaUploadResponse?.data.media_version_id) {
      mediaVersionId = mediaUploadResponse?.data.media_version_id;
    }

    setCurrentFileIndex(index + 1);
    if (uploadType === 'replaced') {
      setCurrentReplaceFileIndex(index + 1);
    }
  }

  return previewImageUrls;
};
