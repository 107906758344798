import { Button, Image, Typography } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchProductsByName } from '../../../../../store/features/productList/productListSlice';
import './ProductLibraryItem.less';

import imagePlaceholder from '../../../../../assets/images/product-image-placeholder.png';
import { ListProductLibraryModel } from '../../../../../models/product-library';
import {
  addProductItemToList,
  finishAddingProduct,
  finishRemovingProduct,
  removeProductItemFromList,
  startAddingProduct,
  startRemovingProduct,
} from '../../../../../store/features/productList/productListSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/index';
import { propsAreEqual } from '../../../../../util';
const { Text, Title } = Typography;

interface ProductLibraryItemProps {
  product: ListProductLibraryModel;
}

const ProductLibraryItem = ({ product }: ProductLibraryItemProps) => {
  const [inList, setInList] = useState(false);
  const isAdding = useAppSelector((state) =>
    state.productList.addingProducts.includes(product.uuid)
  );
  const isRemoving = useAppSelector((state) =>
    state.productList.removingProducts.includes(product.uuid)
  );

  const dispatch = useAppDispatch();

  const { image_url, uuid, upc, size, count } = product;

  const handleAddToList = useCallback(async () => {
    if (isAdding) return;
    try {
      setInList(true);
      dispatch(startAddingProduct(product.uuid));

      await dispatch(addProductItemToList(product)).unwrap();

      dispatch(finishAddingProduct(product.uuid));
      dispatch(searchProductsByName('')); // Refresh the product count
    } catch (error) {
      setInList(false);
      console.error('Failed to add product to list:', error);
    }
  }, [dispatch, product, isAdding]);

  const handleRemoveFromList = useCallback(async () => {
    if (isRemoving) return;
    try {
      setInList(false);
      dispatch(startRemovingProduct(product.uuid));

      await dispatch(removeProductItemFromList(product.uuid)).unwrap();

      dispatch(finishRemovingProduct(product.uuid));
      dispatch(searchProductsByName('')); // Refresh the product count
    } catch (error) {
      setInList(true);
      console.error('Failed to remove product from list:', error);
    }
  }, [dispatch, product.uuid, isRemoving]);

  useEffect(() => {
    setInList(product.in_list === 1);
  }, [product.in_list]);

  return (
    <div className="product-library-grid-item">
      <Link to={`product-library/${uuid}`}>
        <div className="product-library-grid-item-section-top">
          <Image
            className=" product-library-grid-item-thumbnail"
            src={image_url ? image_url : imagePlaceholder}
            preview={false}
          />
          <Text className="product-library-item-upc">
            {upc ? 'UPC: ' + upc : 'UPC: N/A'}
          </Text>
          <Title className="product-library-item-title" level={5}>
            {product?.name}
          </Title>
          <Text className="product-library-item-size">
            {size ? 'Size: ' + size : 'Size: N/A'}
          </Text>
          <Text className="product-library-item-count">
            {count ? 'Count: ' + count : 'Count: N/A'}
          </Text>
        </div>
      </Link>
      <div className="product-library-grid-item-section-bottom">
        <Button
          type="primary"
          onClick={inList ? handleRemoveFromList : handleAddToList}
          disabled={isAdding || isRemoving}
        >
          {isAdding
            ? 'Adding...'
            : isRemoving
            ? 'Removing...'
            : inList
            ? 'Remove from List'
            : 'Add to List'}
        </Button>
      </div>
    </div>
  );
};

export default memo(ProductLibraryItem, propsAreEqual);
