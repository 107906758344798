import { MouseEvent, memo, useCallback, useMemo, useState } from 'react';

import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Modal, Space, Table, Tooltip, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { History } from 'history';
import { Link, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../../../../api/base';
import { ListOvrProjectModel } from '../../../../../../../models/online-virtual-research';
import { UserModel } from '../../../../../../../models/user';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import {
  deleteOvrProject,
  paginateOvrProjects,
} from '../../../../../../../store/features/ovrProjects/ovrProjectsSlice';
import {
  UserPermissions,
  formatDate,
  getMePermissions,
  propsAreEqual,
} from '../../../../../../../util';
import { DrawerHashRoute } from '../../../../../../containers/Drawers/types';
import ExportRespondentDataModal from '../../../ExportRespondentDataModal';

const { Text } = Typography;

interface OvrTableListProps {
  projects: ListOvrProjectModel[];
  loading: boolean;
  history: History;
}

const OVRTableList = ({ projects, loading, history }: OvrTableListProps) => {
  const [exportRespondentDataModal, setExportRespondentDataModal] = useState<{
    visible: boolean;
    project?: ListOvrProjectModel;
  }>({ visible: false, project: undefined });
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector((state) => state.ovrProjects);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const handleExportData = useCallback(
    (e: MouseEvent, project: ListOvrProjectModel) => {
      e.preventDefault();
      setExportRespondentDataModal({ visible: true, project });
    },
    []
  );

  const onSuccess = useCallback((project: ListOvrProjectModel) => {
    message.destroy('deleting-project-message');
    message.success(`Project '${project?.name}' deleted.`);
  }, []);

  const onError = useCallback((err: ApiError) => {
    message.destroy('deleting-project-message');
    handleError(err);
  }, []);

  const handleDeleteProject = useCallback(
    async (project: ListOvrProjectModel) => {
      try {
        message.loading(
          { content: 'Deleting project...', key: 'deleting-project-message' },
          0
        );

        await dispatch(deleteOvrProject(project.uuid));
        onSuccess(project);
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [dispatch, onError, onSuccess]
  );

  const handleConfirmDeleteProject = useCallback(
    (e: MouseEvent, project: ListOvrProjectModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Delete project '${project?.name}'?`,
        icon: <ExclamationCircleOutlined />,
        content: 'You will not be able to recover it.',
        okText: 'Delete',
        onOk: () => handleDeleteProject(project),
      });
    },
    [handleDeleteProject]
  );

  const columns: ColumnsType<ListOvrProjectModel> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, project) => {
          return (
            <Link to={`/online-virtual-research/${project.uuid}`}>
              <Text style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                {name}
              </Text>
            </Link>
          );
        },
        sorter: (a, b) => a?.name.localeCompare(b?.name),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (description: string) =>
          !!description ? (
            <Tooltip title={description} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {description}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No description.
            </Text>
          ),
      },
      {
        title: 'Last updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (date: string) => {
          return <div>{date ? formatDate(date) : null}</div>;
        },
        sorter: (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      },
      {
        title: 'Created by',
        dataIndex: 'createdByUser',
        key: 'createdByUser',
        render: (user: UserModel) => user?.name,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (project) => (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(
              UserPermissions.OvrProjectsDataExport
            ) ? (
              <Tooltip title="Export respondent data">
                <ExportOutlined
                  onClick={(e) => handleExportData(e, project)}
                  className="ovr-project-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
            {currentUserPermissions.includes(
              UserPermissions.OvrProjectsEdit
            ) ? (
              <Tooltip title="Edit">
                <EditFilled
                  onClick={() =>
                    history.push({
                      hash: DrawerHashRoute.OVRForm,
                      state: { data: project },
                    })
                  }
                  className="ovr-project-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
            {currentUserPermissions.includes(
              UserPermissions.OvrProjectsDelete
            ) ? (
              <Tooltip title="Delete">
                <DeleteFilled
                  onClick={(e) => handleConfirmDeleteProject(e, project)}
                  className="ovr-project-table-list-action-icon"
                />
              </Tooltip>
            ) : null}
          </Space>
        ),
      },
    ],
    [
      handleConfirmDeleteProject,
      handleExportData,
      history,
      currentUserPermissions,
    ]
  );

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(paginateOvrProjects({ page, pageSize })),
    [dispatch]
  );

  return (
    <>
      <Table
        loading={loading}
        locale={{ emptyText: <></> }}
        rowKey={(project) => `${project.uuid}-row-key`}
        sortDirections={['ascend', 'descend', 'ascend']}
        className="ovr-projects-table-list"
        pagination={{
          defaultPageSize: 20,
          total: totalCount,
          onChange: paginate,
        }}
        columns={columns}
        dataSource={projects}
      />

      <ExportRespondentDataModal
        visible={exportRespondentDataModal.visible}
        project={exportRespondentDataModal.project!}
        onClose={() => setExportRespondentDataModal({ visible: false })}
      />
    </>
  );
};

export default withRouter<any, any>(memo(OVRTableList, propsAreEqual));
