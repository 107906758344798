import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const Spinner = () => {
  return (
    <div className="spin-container">
      <Spin indicator={antSpinIcon} />
    </div>
  );
};

export default Spinner;
