import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { PaginationModel } from '../../../api/base';
import SystemConfigurationService from '../../../api/system-configuration';
import { ListSystemConfigurationsParams } from '../../../hooks/system-configuration';
import { SystemConfigurationModel } from '../../../models/system-configuration';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

export interface SystemConfigurationsState {
  value: SystemConfigurationModel[];
  allSystemConfigurations: SystemConfigurationModel[];
  fetchingSystemConfigurations: boolean;
  searchingSystemConfigurations: boolean;
  savingSystemConfiguration: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  totalCount: number;
}
interface CreateSystemConfigurationProps {
  systemConfiguration: SystemConfigurationModel;
}

interface UpdateSystemConfigurationProps {
  systemConfigurationId: number;
  systemConfiguration: SystemConfigurationModel;
}

const initialState: SystemConfigurationsState = {
  value: [],
  allSystemConfigurations: [],
  fetchingSystemConfigurations: false,
  searchingSystemConfigurations: false,
  savingSystemConfiguration: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
};

const systemConfigurationsParams = {
  params: {
    _limit: 20,
    _order_by: 'updated_at:desc',
    _offset: 0,
    _batch: 1,
  },
};

export const createSystemConfiguration = createAsyncThunk(
  'systemConfigurations/createSystemConfiguration',
  async (
    { systemConfiguration }: CreateSystemConfigurationProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const request = {
        ...systemConfiguration,
        value: systemConfiguration.value_string,
      };
      const resp = await SystemConfigurationService().createSystemConfiguration(
        request
      );

      await dispatch(fetchSystemConfigurations(systemConfigurationsParams));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSystemConfiguration = createAsyncThunk(
  'systemConfigurations/deleteSystemConfiguration',
  async (systemConfigurationId: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await SystemConfigurationService().deleteSystemConfiguration(
        systemConfigurationId
      );

      await dispatch(fetchSystemConfigurations(systemConfigurationsParams));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSystemConfiguration = createAsyncThunk(
  'systemConfigurations/updateSystemConfiguration',
  async (
    {
      systemConfigurationId,
      systemConfiguration,
    }: UpdateSystemConfigurationProps,
    { dispatch }
  ) => {
    const request = {
      ...systemConfiguration,
      value: systemConfiguration.value_string,
    };
    const resp = await SystemConfigurationService().updateSystemConfiguration(
      systemConfigurationId,
      request
    );

    dispatch(fetchSystemConfigurations(systemConfigurationsParams));
    return resp.data;
  }
);

export const fetchSystemConfigurations = createAsyncThunk(
  'systemConfigurations/fetchSystemConfigurations',
  async ({
    params,
    searching = false,
    fetchingSystemConfigurationsMore = false,
  }: {
    params: ListSystemConfigurationsParams;
    searching?: boolean;
    fetchingSystemConfigurationsMore?: boolean;
  }) => {
    const queryString = convertParamsToQuery(params);
    const resp = await SystemConfigurationService().listSystemConfigurations(
      queryString
    );
    return {
      data: resp.data,
      pagination: resp.pagination,
      searching,
      fetchingSystemConfigurationsMore,
    };
  }
);

export const searchSystemConfigurationsByName = createAsyncThunk(
  'systemConfigurations/searchSystemConfigurationsByName',
  async (query: string) => {
    let params: ListSystemConfigurationsParams = {
      _limit: 20,
      _order_by: 'updated_at:desc',
      _offset: 0,
      _batch: 1,
    };

    if (query && query.length) {
      params = {
        ...params,
        name: `*${query.toLowerCase()}*`,
      };
    }

    const queryString = convertParamsToQuery(params);

    const resp = await SystemConfigurationService().listSystemConfigurations(
      queryString
    );

    return {
      data: resp.data,
      pagination: resp.pagination,
      searching: false,
      fetchingSystemConfigurationsMore: false,
    };
  }
);

export const loadMoreSystemConfigurations = createAsyncThunk(
  'systemConfigurations/loadMoreSystemConfigurations',
  async (_, { getState }) => {
    const {
      systemConfigurations: { pagination },
    } = getState() as RootState;

    const limit = pagination?.limit!;
    const offset = pagination?.offset!;
    const nextOffset = limit + offset;

    const nextParams = {
      _limit: limit,
      _order_by: 'updated_at:desc',
      _offset: nextOffset,
      _batch: 1,
    };

    const query = convertParamsToQuery(nextParams);
    const resp = await SystemConfigurationService().listSystemConfigurations(
      query
    );

    return {
      data: resp.data,
      pagination: resp.pagination,
    };
  }
);

export const systemConfigurationsSlice = createSlice({
  name: 'systemConfigurations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemConfigurations.pending, (state) => {
        state.fetchingSystemConfigurations = true;
      })
      .addCase(fetchSystemConfigurations.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingSystemConfigurations = false;
        state.value = action.payload.data;
        state.allSystemConfigurations = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchSystemConfigurations.rejected, (state) => {
        state.fetchingSystemConfigurations = false;
      });
    builder
      .addCase(createSystemConfiguration.pending, (state) => {
        state.savingSystemConfiguration = true;
      })
      .addCase(createSystemConfiguration.fulfilled, (state) => {
        state.savingSystemConfiguration = false;
      })
      .addCase(createSystemConfiguration.rejected, (state) => {
        state.savingSystemConfiguration = false;
      });
    builder
      .addCase(updateSystemConfiguration.pending, (state) => {
        state.savingSystemConfiguration = true;
      })
      .addCase(updateSystemConfiguration.fulfilled, (state) => {
        state.savingSystemConfiguration = false;
      })
      .addCase(updateSystemConfiguration.rejected, (state) => {
        state.savingSystemConfiguration = false;
      });
    builder
      .addCase(deleteSystemConfiguration.pending, (state) => {
        state.savingSystemConfiguration = true;
      })
      .addCase(deleteSystemConfiguration.fulfilled, (state) => {
        state.savingSystemConfiguration = false;
      })
      .addCase(deleteSystemConfiguration.rejected, (state) => {
        state.savingSystemConfiguration = false;
      });
    builder
      .addCase(searchSystemConfigurationsByName.pending, (state) => {
        state.searchingSystemConfigurations = true;
      })
      .addCase(searchSystemConfigurationsByName.fulfilled, (state, action) => {
        state.searchingSystemConfigurations = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchSystemConfigurationsByName.rejected, (state) => {
        state.searchingSystemConfigurations = false;
      });
    builder.addCase(loadMoreSystemConfigurations.fulfilled, (state, action) => {
      state.fetchingSystemConfigurations = false;
      state.pagination = action.payload.pagination;
      state.value = [...state.value, ...action.payload.data];
    });
  },
});

export const getSystemConfigurations = (state: RootState) =>
  state.systemConfigurations.value;
export const getFetchingSystemConfigurations = (state: RootState) =>
  state.systemConfigurations.fetchingSystemConfigurations;
export const getFetchingSystemConfigurationsError = (state: RootState) =>
  state.systemConfigurations.error;
export const getTotalCount = (state: RootState) =>
  state.systemConfigurations.totalCount;
export const getPagination = (state: RootState) =>
  state.systemConfigurations.pagination;
export const getSearchingSystemConfigurationsByName = (state: RootState) =>
  state.systemConfigurations.searchingSystemConfigurations;
export const getSavingSystemConfiguration = (state: RootState) =>
  state.systemConfigurations.savingSystemConfiguration;
export const getAllSystemsConfigurations = (state: RootState) =>
  state.systemConfigurations.allSystemConfigurations;

export default systemConfigurationsSlice.reducer;
