export const useLockBodyScroll = () => {
  const lockBodyScroll = () => {
    document.body.classList.add('block-scroll');
  };

  const unlockBodyScroll = () => {
    document.body.classList.remove('block-scroll');
  };

  return { lockBodyScroll, unlockBodyScroll };
};
