import {
  ParentCompaniesResponse,
  ParentCompaniesListResponse,
  UpdateParentCompanyRequest,
  CreateParentCompanyRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { del, get, ListApiResponse, patch, post } from '../base';

export default function ParentCompanyService() {
  return Object.freeze({
    listParentCompanies(query?: string): Promise<ParentCompaniesListResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/parent-company${query}`
      ) as Promise<ListApiResponse>;
    },
    createParentCompany(
      req: CreateParentCompanyRequest
    ): Promise<ParentCompaniesResponse> {
      return post(`${BASE_API_URL()}/client/`, req);
    },
    updateParentCompany(
      parentCompanyId: string,
      req: UpdateParentCompanyRequest
    ): Promise<ParentCompaniesResponse> {
      return patch(`${BASE_API_URL()}/client/${parentCompanyId}`, req);
    },
    deleteParentCompany(
      parentCompanyId: string
    ): Promise<ParentCompaniesResponse> {
      return del(`${BASE_API_URL()}/client/${parentCompanyId}/`);
    },
  });
}
