import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import useDebounce from '../../../../hooks/useDebounce';
import { LicenseModel } from '../../../../models/license';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  paginateClientLicenses,
  searchClientLicensesByName,
} from '../../../../store/features/clients/clientsSlice';
import { getMePermissions, UserPermissions } from '../../../../util';
import LicenseForm from '../../../containers/forms/LicenseForm';
import NoResultsFound from '../../../elements/NoResultsFound';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const { Search } = Input;
const { Title, Text } = Typography;

type LicenseTableProps = {
  clientId: string;
};

const LicenseTable = ({ clientId }: LicenseTableProps) => {
  const dispatch = useAppDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const [licenseData, setLicenseData] = useState<LicenseModel | null>(null);

  const {
    licenses,
    fetchingLicenses,
    searchingLicenses,
    licensesCount,
    licensesCurrent,
  } = useAppSelector((state) => state.clients);

  const hasNoSearchResultsFound = useMemo(
    () => !fetchingLicenses && !!searchQuery && licenses.length === 0,
    [licenses.length, fetchingLicenses, searchQuery]
  );

  const hasLicenses = !(!fetchingLicenses && licenses?.length === 0);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const columns: ColumnsType<LicenseModel> = [
    {
      title: 'License Name',
      dataIndex: 'name',
      width: '20%',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      width: '20%',
      render: (date: string) => (
        <div>{date ? moment(date).format('YYYY-MM-DD') : ''}</div>
      ),
      sorter: (a, b) =>
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      width: '20%',
      render: (date: string) => (
        <div>{date ? moment(date).format('YYYY-MM-DD') : ''}</div>
      ),
      sorter: (a, b) =>
        new Date(a.end_date).getTime() - new Date(b.end_date).getTime(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      sorter: (a, b) => (a.status > b.status ? 1 : -1),
    },
    {
      title: '# of Seats',
      dataIndex: 'quantity',
      width: '15%',
      sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1),
    },
    {
      title: 'Action',
      width: '10%',
      render: (data: LicenseModel) => {
        return (
          <Space direction="horizontal" size="middle">
            {currentUserPermissions.includes(UserPermissions.ClientsEdit) ? (
              <Button
                type="link"
                className="link-button"
                onClick={() => handleOpenModal(data)}
              >
                Edit
              </Button>
            ) : null}
          </Space>
        );
      },
    },
  ];

  const handleOpenModal = (rowData: LicenseModel) => {
    setLicenseData(rowData);
    setLicenseModalOpen(true);
  };

  const handleCloseModal = () => {
    setLicenseData(null);
    setLicenseModalOpen(false);
  };

  useEffect(() => {
    dispatch(
      searchClientLicensesByName({ id: clientId, query: debouncedSearchQuery })
    );
  }, [clientId, debouncedSearchQuery, dispatch]);

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const paginate = useCallback(
    (page: number, pageSize: number) =>
      dispatch(
        paginateClientLicenses({
          clientId,
          page,
          pageSize,
          queryStr: debouncedSearchQuery,
        })
      ),
    [clientId, debouncedSearchQuery, dispatch]
  );

  const paginationSetting = {
    hideOnSinglePage: false,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    onChange: paginate,
    onShowSizeChange: paginate,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    style: {
      background: 'white',
      borderRadius: '0 0 4px 4px',
    },
    total: licensesCount,
    current: licensesCurrent,
  };

  return (
    <div>
      <Title level={5}>License & Seats</Title>
      <Text>Contact info@readysetvr.com to add more seats</Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          margin: '1rem 0',
        }}
      >
        <Search
          style={{ width: '60%' }}
          allowClear={!searchingLicenses}
          value={searchQuery}
          onChange={(e) => updateSearchQuery(e.target.value)}
          enterButton
          placeholder="Search"
          suffix={searchingLicenses && <Spin indicator={antSpinIcon} />}
        />
        {currentUserPermissions.includes(UserPermissions.ClientsCreate) ? (
          <Button type="primary" onClick={() => setLicenseModalOpen(true)}>
            Add License
          </Button>
        ) : null}
      </div>
      {hasLicenses && hasNoSearchResultsFound ? (
        <NoResultsFound
          searchQuery={searchQuery}
          onClear={() => updateSearchQuery('')}
        />
      ) : (
        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          rowKey={(license: LicenseModel) => `${license.uuid}-row-key`}
          dataSource={licenses}
          pagination={paginationSetting}
        />
      )}
      <Modal
        key={licenseData?.uuid}
        open={licenseModalOpen}
        title={licenseData ? 'Edit License' : 'Add License'}
        footer={null}
        onCancel={handleCloseModal}
      >
        <LicenseForm
          clientId={clientId}
          data={licenseData || undefined}
          closeModal={() => setLicenseModalOpen(false)}
          setLicenseData={setLicenseData}
        />
      </Modal>
    </div>
  );
};

export default LicenseTable;
