import {
  CreateRoleRequest,
  RoleResponse,
  RolesResponse,
  UpdateRoleRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { ApiResponse, del, get, patch, post } from '../base';

export default function RolesService() {
  return Object.freeze({
    listRoles(query?: string): Promise<RolesResponse> {
      return get(
        `${BASE_API_URL()}/acl/role${query}`
      ) as Promise<RolesResponse>;
    },
    createRole(req: CreateRoleRequest): Promise<RoleResponse> {
      return post(`${BASE_API_URL()}/acl/role`, req);
    },
    updateRole(
      roleId: string,
      req: UpdateRoleRequest,
      query = ''
    ): Promise<RoleResponse> {
      return patch(`${BASE_API_URL()}/acl/role/${roleId}${query}`, req);
    },
    deleteRole(roleId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/acl/role/${roleId}`);
    },
  });
}
