import { Typography } from 'antd';

import { ListProductLibraryModel } from '../../../../../models/product-library';
import { renderField } from '../Row';
import './NotEditableInfo.less';

const { Title } = Typography;

interface NotEditableInfoProps {
  productItem: ListProductLibraryModel | undefined;
}

const NotEditableInfo = ({ productItem }: NotEditableInfoProps) => {
  return (
    <div className="not-editable-content">
      <Title className="title" level={4}>
        Not Editable Information
      </Title>
      {productItem && renderField(productItem, 'upc', 'property')}
      {productItem && renderField(productItem, 'modelname')}
      {productItem && renderField(productItem, 'old_width')}
      {productItem && renderField(productItem, 'old_height')}
      {productItem && renderField(productItem, 'old_depth')}
    </div>
  );
};

export default NotEditableInfo;
