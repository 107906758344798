import { useMemo, useCallback, FC, memo } from 'react';

import './SystemConfigurationForm.less';
import { Form, Input, message, Space, Radio } from 'antd';
import { History, Location } from 'history';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SystemConfigurationFormLocationState } from './types';
import { ApiError, handleError } from '../../../../api/base';
import {
  initialNewSystemConfigurationModel,
  SystemConfigurationModel,
  SystemConfigurationScope,
} from '../../../../models/system-configuration';
import { RootState, useAppDispatch } from '../../../../store';
import {
  createSystemConfiguration,
  getSavingSystemConfiguration,
  updateSystemConfiguration,
} from '../../../../store/features/systemConfigurations/systemConfigurationsSlice';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';

interface SystemConfigurationFormProps {
  history: History;
  location: Location<SystemConfigurationFormLocationState>;
}

const SystemConfigurationForm: FC<SystemConfigurationFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const savingSystemConfiguration = useSelector<RootState>(
    getSavingSystemConfiguration
  ) as boolean;

  const isNewSystemConfiguration = useMemo(
    () => !location.state,
    [location.state]
  );
  const fields = useMemo(
    () =>
      Object.keys(
        isNewSystemConfiguration
          ? initialNewSystemConfigurationModel
          : location.state?.data
      ),
    [isNewSystemConfiguration, location.state?.data]
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveSystemConfiguration = useCallback(
    async (systemConfiguration: SystemConfigurationModel) => {
      const res: any = isNewSystemConfiguration
        ? await dispatch(
            createSystemConfiguration({
              systemConfiguration,
            })
          )
        : await dispatch(
            updateSystemConfiguration({
              systemConfigurationId: location.state?.data?.id!,
              systemConfiguration,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('System configuration saved.');
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewSystemConfiguration,
      location.state?.data?.id,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: SystemConfigurationModel) => {
      form.validateFields(fields).then(() => saveSystemConfiguration(values));
    },
    [fields, form, saveSystemConfiguration]
  );

  return (
    <FormWrapper
      title={`${
        isNewSystemConfiguration ? 'Create new' : 'Edit'
      } system configuration`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewSystemConfiguration
            ? initialNewSystemConfigurationModel
            : location.state.data
        }
        onFinish={onSubmit}
      >
        <Form.Item
          label="System Configuration Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'System configuration name is required.',
            },
          ]}
        >
          <Input
            disabled={!isNewSystemConfiguration}
            data-cy="system-configuration-name-input"
            placeholder="System configuration name"
          />
        </Form.Item>
        <Form.Item
          label="Value"
          name="value_string"
          rules={[{ required: true, message: 'Value is required.' }]}
        >
          <Input
            data-cy="system-configuration-value-input"
            placeholder="Value"
          />
        </Form.Item>
        <Form.Item
          label="Scope"
          name="scope"
          rules={[{ required: true, message: 'Scope is required.' }]}
        >
          <Radio.Group disabled={!isNewSystemConfiguration}>
            <Radio value={SystemConfigurationScope.Default}>Default</Radio>
            <Radio value={SystemConfigurationScope.System}>System</Radio>
            <Radio value={SystemConfigurationScope.User}>User</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              data-cy="system-configuration-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              loading={savingSystemConfiguration}
            >
              {`${
                isNewSystemConfiguration ? 'Create' : 'Save'
              } System Configuration`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(
  memo(SystemConfigurationForm, propsAreEqual)
);
