import { FC, memo, useCallback, useMemo } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Switch,
  Typography,
} from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ParentCompanyFormLocationState } from './types';
import { ApiError, handleError } from '../../../../api/base';
import {
  ParentCompanyModel,
  initialNewParentCompanyModel,
} from '../../../../models/parent-company';
import { ProductStatusEnum } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createParentCompany,
  deleteParentCompany,
  updateParentCompany,
} from '../../../../store/features/parentCompany/parentCompanySlice';
import {
  getMePermissions,
  propsAreEqual,
  UserPermissions,
} from '../../../../util';
import FormWrapper from '../../../elements/FormWrapper';
import { RoutePath } from '../../../views/AppRoot/types';
import './ParentCompanyForm.less';

const { Text } = Typography;

interface ParentCompanyFormProps {
  history: History;
  location: Location<ParentCompanyFormLocationState>;
}

interface ParentCompanyProps {
  name: string;
  status: string;
  short_name?: string;
  client_type: string;
  uuid: string;
  in_library: number;
  in_processing: number;
}

const ParentCompanyForm: FC<ParentCompanyFormProps> = (props) => {
  const { history, location } = props;
  const dispatch = useAppDispatch();
  const { deletingParentCompany, savingParentCompany } = useAppSelector(
    (state) => state.parentCompany
  );
  const [form] = Form.useForm();
  const isNewParentCompany = useMemo(() => !location.state, [location.state]);

  const { in_library, in_processing, in_use } = {
    ...location?.state?.data,
  };
  const { data: parentCompany } = { ...location?.state };

  const fields = useMemo(
    () =>
      Object.keys(
        isNewParentCompany ? initialNewParentCompanyModel : location.state?.data
      ),
    [isNewParentCompany, location.state?.data]
  );

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleDeleteParentCompany = useCallback(
    async (parentCompany: ParentCompanyModel) => {
      message.loading(
        {
          content: 'Deleting parent company...',
          key: 'deleting-parent-company-message',
        },
        0
      );

      const res: any = await dispatch(
        deleteParentCompany({
          parentCompanyId: parentCompany.uuid,
        })
      );
      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.destroy('deleting-parent-company-message');
        message.success(`Parent company '${parentCompany.name}' deleted.`);
        history.goBack();
      }
    },
    [dispatch, onError, history]
  );

  const handleConfirmDeleteParentCompany = useCallback(
    (e: MouseEvent, parentCompany: ParentCompanyModel) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete the parent company "${parentCompany.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the Parent Company "${parentCompany.name}" after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteParentCompany(parentCompany),
      });
    },
    [handleDeleteParentCompany]
  );

  const saveParentCompany = useCallback(
    async (parentCompany: ParentCompanyModel) => {
      const { name, statusCheckValue } = parentCompany;
      let status: string;
      let newParentCompany: ParentCompanyProps = {
        name: '',
        status: '',
        short_name: '',
        client_type: '',
        uuid: '',
        in_library: 0,
        in_processing: 0,
      };

      if (statusCheckValue) {
        status = ProductStatusEnum.approved;
      } else {
        status = ProductStatusEnum.needs_approval;
      }

      newParentCompany = {
        name,
        status,
        short_name: name,
        client_type: 'parent_company',
        uuid: location.state?.data.uuid,
        in_library: location.state?.data.in_library,
        in_processing: location.state?.data.in_processing,
      };

      const res: any = isNewParentCompany
        ? await dispatch(
            createParentCompany({ parentCompany: newParentCompany })
          )
        : await dispatch(
            updateParentCompany({
              parentCompanyId: location.state?.data.uuid,
              parentCompany: newParentCompany,
            })
          );

      if (res.error) {
        onError({ error: res.payload.error });
      } else {
        message.success('Parent Company saved.');
        history.goBack();
      }
    },
    [
      dispatch,
      history,
      isNewParentCompany,
      location.state?.data.in_library,
      location.state?.data.in_processing,
      location.state?.data.uuid,
      onError,
    ]
  );

  const onSubmit = useCallback(
    (values: ParentCompanyModel) => {
      form.validateFields(fields).then(() => saveParentCompany(values));
    },
    [fields, form, saveParentCompany]
  );

  const getInitialValues = () => {
    if (isNewParentCompany) {
      return { ...initialNewParentCompanyModel };
    }
    return { ...location.state.data };
  };

  return (
    <FormWrapper
      title={`${isNewParentCompany ? 'Create new' : 'Edit'} parent company`}
      onClose={() => history.push(RoutePath.MetaData)}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Parent Company"
          name="name"
          rules={[
            { required: true, message: 'Parent Company name is required.' },
          ]}
        >
          <Input
            data-cy="parent-company-form-input"
            placeholder="Parent Company name"
          />
        </Form.Item>
        <Form.Item label="Status">
          <div className="parent-company-form-status">
            <Typography>Librarian Approved</Typography>
            <Form.Item name="statusCheckValue">
              <Switch
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.MetaDataApprove
                  )
                }
              />
            </Form.Item>
          </div>
        </Form.Item>
        {!isNewParentCompany ? (
          <>
            <Form.Item label="Associated Manufacturers">
              <div>
                {parentCompany.manufacturers &&
                parentCompany.manufacturers?.length > 0 ? (
                  parentCompany.manufacturers?.map((m) => (
                    <Button className="link-button" type="link">
                      {m.name}
                    </Button>
                  ))
                ) : (
                  <Text>There are currently no associated manufacturers</Text>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Details">
              <div className="parent-company-form-details">
                <Typography>Number of models in library</Typography>
                <Typography>{in_library}</Typography>
              </div>
              <div className="parent-company-form-details">
                <Typography>
                  Number of models in processing / master librarian list
                </Typography>
                <Typography>{in_processing}</Typography>
              </div>
              <Button className="link-button" type="link">
                View in Library
              </Button>
            </Form.Item>
          </>
        ) : null}
        <Form.Item>
          {isNewParentCompany ? (
            <Space style={{ float: 'right' }}>
              <Button onClick={() => history.goBack()}>Cancel</Button>
              <Button
                loading={savingParentCompany}
                data-cy="parent-company-form-submit-btn"
                htmlType="submit"
                type="primary"
                disabled={
                  !currentUserPermissions.includes(
                    UserPermissions.ClientsCreate
                  )
                }
              >
                Add
              </Button>
            </Space>
          ) : (
            <Space style={{ float: 'right' }}>
              {!in_use ? (
                <Button
                  danger
                  loading={deletingParentCompany}
                  type="primary"
                  onClick={(e: any) =>
                    handleConfirmDeleteParentCompany(e, parentCompany)
                  }
                  disabled={
                    !currentUserPermissions.includes(
                      UserPermissions.ClientsDelete
                    )
                  }
                >
                  Delete
                </Button>
              ) : null}
              <Button
                loading={savingParentCompany}
                type="primary"
                htmlType="submit"
                disabled={
                  !currentUserPermissions.includes(UserPermissions.ClientsEdit)
                }
              >
                Save
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(ParentCompanyForm, propsAreEqual));
