import { useEffect, useMemo } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Badge, Empty, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { ListStoreBuildsParams } from '../../../../../hooks/store-build';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { searchStoreBuildsByName } from '../../../../../store/features/storeBuilds/storeBuildsSlice';
import { UserPermissions, getMePermissions } from '../../../../../util';
import { DrawerHashRoute } from '../../../../containers/Drawers/types';
import Button from '../../../../elements/Button';
import { RoutePath } from '../../../AppRoot/types';
import StoreBuildItemsList from '../../../StoreBuilderView/components/StoreBuildItemsList';

const { Title } = Typography;

const lastStoreBuildsItemLengthKey = 'last-store-builds-item-length-key';

const RecentStoreBuildsList = () => {
  const listStoreBuildsParams = useMemo(
    (): ListStoreBuildsParams => ({
      _offset: 0,
      _limit: 5,
      _order_by: 'updated_at:desc',
      _columns: 'createdByUser.*',
      status: 'active',
    }),
    []
  );

  const dispatch = useAppDispatch();

  const {
    value: storeBuilds,
    fetchingStoreBuilds,
    totalCount,
    allStoreBuilds,
  } = useAppSelector((state) => state.storeBuilds);

  const hasStoreBuilds = !(!fetchingStoreBuilds && allStoreBuilds.length === 0);

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  useEffect(() => {
    dispatch(
      searchStoreBuildsByName({ query: '', params: listStoreBuildsParams })
    );
  }, [dispatch, listStoreBuildsParams]);

  useEffect(() => {
    localStorage.setItem(lastStoreBuildsItemLengthKey, `${storeBuilds.length}`);
  }, [storeBuilds.length]);

  return (
    <section>
      <header style={{ marginBottom: '1rem' }}>
        <Space direction="horizontal">
          <Title level={4}>Recent Front End Builds</Title>
          <Badge
            size="default"
            count={totalCount}
            className="total-count-badge"
            overflowCount={999}
          />
        </Space>
      </header>
      {hasStoreBuilds ? (
        <>
          <StoreBuildItemsList
            skeletonItemLength={5}
            loading={fetchingStoreBuilds}
            builds={storeBuilds}
            hasMore={false}
          />
          <footer style={{ marginBottom: '1rem' }}>
            <Link to={RoutePath.StoreBuilder} style={{ color: '#8e24aa' }}>
              View All Builds
            </Link>
          </footer>
        </>
      ) : (
        <Empty
          description="You currently have no store buiilds."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          {currentUserPermissions.includes(UserPermissions.FebDesignsCreate) ? (
            <Link to={DrawerHashRoute.BuildForm}>
              <Button type="primary" data-cy="create-new-build-btn">
                Create New Build
                <PlusOutlined />
              </Button>
            </Link>
          ) : null}
        </Empty>
      )}
    </section>
  );
};

export default RecentStoreBuildsList;
