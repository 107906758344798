import { memo, useCallback, useMemo, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, message, Space, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import axios, { CancelTokenSource } from 'axios';
import { ApiError, handleError } from '../../../../api/base';
import { CellModel } from '../../../../models/online-virtual-research';
import { useAppDispatch } from '../../../../store';
import {
  createOvrCell,
  searchOvrCellsByName,
  updateOvrCell,
} from '../../../../store/features/cells/cellsSlice';
import {
  getSignedUrl,
  mediaServiceUploadNotification,
} from '../../../../store/features/media/mediaSlice';
import { getMeRoles, propsAreEqual, UserRoles } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import { CustomFileFormLocationState } from './types';

const { TextArea } = Input;

interface CustomFileFormProps {
  history: History;
  location: Location<CustomFileFormLocationState>;
}

const CustomFileForm = ({ history, location }: CustomFileFormProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [addingFilesToQueue, setAddingFilesToQueue] = useState(false);
  const [jpgFiles, setJpgFiles] = useState<UploadFile<any>[]>([]);
  const [ovrFile, setOvrFile] = useState<UploadFile<any> | null>(null);
  const [rsoFile, setRsoFile] = useState<UploadFile<any> | null>(null);
  const isNewFile = useMemo(() => !location.state, [location.state]);
  const fields = useMemo(
    () =>
      Object.keys(
        isNewFile ? { name: '', description: '' } : location.state?.data
      ),
    [isNewFile, location.state?.data]
  );

  const currentUserRoles = useMemo(() => {
    return getMeRoles();
  }, []);

  const isClient = useMemo(() => {
    return currentUserRoles.includes(UserRoles.client);
  }, [currentUserRoles]);

  const onSuccess = useCallback(async () => {
    await dispatch(searchOvrCellsByName({ query: '' }));
    setAddingFilesToQueue(false);
    history.goBack();
    message.success('Files uploaded.');
  }, [history, dispatch]);

  const onError = useCallback((error: ApiError) => {
    setAddingFilesToQueue(false);
    handleError(error);
  }, []);

  const uploadFileToS3 = useCallback(
    async (signedUrl: string, file: any, source: CancelTokenSource) => {
      try {
        await axios.put(signedUrl, file, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          cancelToken: source.token,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Upload canceled by the user');
          throw new Error('Upload canceled by the user');
        } else {
          onError(error as ApiError);
          throw new Error(`S3 upload error for file: ${file?.name}`);
        }
      }
    },
    [onError]
  );

  const processFileUpload = useCallback(
    async (
      file: UploadFile<any>,
      cellId: string,
      source: CancelTokenSource
    ) => {
      try {
        // get signed url
        const signedUrlResponse = await dispatch(
          getSignedUrl({ fileName: file.name, cellId })
        ).unwrap();

        // upload file to s3
        await uploadFileToS3(signedUrlResponse.signedUrl, file, source);

        // notify media service
        await dispatch(
          mediaServiceUploadNotification({
            objectKey: signedUrlResponse.objectKey,
            name: file.name,
            size: file.size!,
            uuid: cellId,
            media_id: signedUrlResponse.media_id,
            media_version_id: signedUrlResponse.media_version_id,
            obj: { status: 'completed' },
          })
        );
      } catch (error) {
        throw new Error(`Failed to upload file: ${file.name}`);
      }
    },
    [dispatch, uploadFileToS3]
  );

  const uploadRequiredFiles = useCallback(
    async (cellId: string, source: CancelTokenSource) => {
      try {
        await processFileUpload(ovrFile!, cellId, source);
        await processFileUpload(rsoFile!, cellId, source);
      } catch (error) {
        throw new Error('Failed to upload required files');
      }
    },
    [processFileUpload, ovrFile, rsoFile]
  );

  const uploadOptionalJpegFiles = useCallback(
    async (cellId: string, source: CancelTokenSource) => {
      for (const file of jpgFiles) {
        try {
          await processFileUpload(file, cellId, source);
        } catch (error) {
          message.error(`Failed to upload optional file: ${file?.name}`);
          console.error('Optional file upload error:', error);
        }
      }
    },
    [jpgFiles, processFileUpload]
  );

  const onSaveCustomFile = useCallback(
    async (cell: CellModel, source: CancelTokenSource) => {
      if (!ovrFile || !rsoFile) {
        message.error('Both OVR and RSO files are required.');
        return;
      }

      setAddingFilesToQueue(true);
      let cellId = '';
      try {
        if (isNewFile) {
          const cellResponse = await dispatch(createOvrCell(cell)).unwrap();
          cellId = cellResponse.uuid;
        } else {
          cellId = location?.state?.data?.uuid || '';
          if (cellId) {
            await dispatch(updateOvrCell({ cellId, cell })).unwrap();
          }
        }

        setUploadingFile(true);
        await uploadRequiredFiles(cellId, source);
        await uploadOptionalJpegFiles(cellId, source);

        onSuccess();
      } catch (error) {
        message.error('Failed to process cell');
        console.error('Error:', error);
      } finally {
        setUploadingFile(false);
        setAddingFilesToQueue(false);
      }
    },
    [
      ovrFile,
      rsoFile,
      isNewFile,
      uploadRequiredFiles,
      uploadOptionalJpegFiles,
      onSuccess,
      dispatch,
      location?.state?.data?.uuid,
    ]
  );

  const onSubmit = useCallback(
    async (cell: CellModel) => {
      await form.validateFields(fields);
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);
      onSaveCustomFile(cell, source);
    },
    [fields, form, onSaveCustomFile]
  );

  const handleOvrUpload = (ovrFile: UploadFile<any>) => {
    setOvrFile(ovrFile);
    return false;
  };

  const handleRsoUpload = (rsoFile: UploadFile<any>) => {
    setRsoFile(rsoFile);
    return false;
  };

  const handleJpgUpload = (jpgFile: UploadFile<any>) => {
    const isDuplicate = jpgFiles.some((f) => f.name === jpgFile.name);
    if (isDuplicate) {
      message.error('File already exists in the list.');
      return false;
    } else {
      setJpgFiles((prevList) => [...prevList, jpgFile]);
    }
    return false;
  };

  const handleRemove = (file: UploadFile<any>) => {
    if (file.uid === ovrFile?.uid) {
      setOvrFile(null);
    } else if (file.uid === rsoFile?.uid) {
      setRsoFile(null);
    } else {
      setJpgFiles(jpgFiles.filter((f) => f?.uid !== file.uid));
    }
  };

  const handleCancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('User cancelled the upload');
    }
    history.goBack();
  };

  return (
    <FormWrapper title="Custom Files" onClose={() => history.goBack()}>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewFile ? { name: '', description: '' } : location.state?.data
        }
        onFinish={onSubmit}
      >
        <Form.Item label="Cell Name" name="name">
          <Input placeholder="Cell name" />
        </Form.Item>
        <Form.Item label="Cell Description" name="description">
          <TextArea rows={6} placeholder="Cell description" />
        </Form.Item>
        {!isClient && (
          <>
            <Form.Item
              name="webgl_files"
              valuePropName="ovrFiles"
              getValueFromEvent={(e) => e.fileList}
            >
              <Upload
                beforeUpload={handleOvrUpload}
                accept=".ovr"
                maxCount={1}
                multiple={false}
                onRemove={handleRemove}
              >
                <Button
                  disabled={uploadingFile}
                  icon={<UploadOutlined />}
                  style={{ minWidth: '160px' }}
                >
                  Upload OVR
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item
              name="rso_files"
              valuePropName="rsoFiles"
              getValueFromEvent={(e) => e.fileList}
            >
              <Upload
                beforeUpload={handleRsoUpload}
                accept=".rso"
                maxCount={1}
                multiple={false}
                onRemove={handleRemove}
              >
                <Button
                  disabled={uploadingFile}
                  icon={<UploadOutlined />}
                  style={{ minWidth: '160px' }}
                >
                  Upload RSO
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item name="jpeg_files" valuePropName="jpgFiles">
              <Upload
                beforeUpload={handleJpgUpload}
                onRemove={handleRemove}
                fileList={jpgFiles}
                accept=".jpg"
                multiple
                listType="picture"
              >
                <Button
                  disabled={uploadingFile}
                  icon={<UploadOutlined />}
                  style={{ minWidth: '160px' }}
                >
                  Upload JPGs
                </Button>
              </Upload>
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              disabled={uploadingFile || addingFilesToQueue}
              loading={addingFilesToQueue}
            >
              {isNewFile ? 'Add Custom File' : 'Update Custom File'}
            </Button>
            <Button onClick={handleCancelUpload} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(CustomFileForm, propsAreEqual));
