import { BASE_API_URL } from '../../config';
import { ApiResponse, ListApiResponse, del, get, patch, post } from '../base';
import {
  MediaUploadedRequest,
  MediaUploadedResponse,
  SignedUploadUrlResponse,
} from '../media/reqres';
import {
  BulkCreateCustomFieldOptions,
  CreateCustomFieldOptionRequest,
  CreateCustomFieldRequest,
  CustomFieldFormOptionsResponse,
  CustomFieldOptionResponse,
  CustomFieldOptionsResponse,
  CustomFieldResponse,
  CustomFieldsResponse,
  UpdateCustomFieldOptionRequest,
  UpdateCustomFieldRequest,
} from './reqres';

export default function CustomFieldService() {
  return Object.freeze({
    getSignedUploadUrl(
      fileName: string,
      cellUUID: string
    ): Promise<SignedUploadUrlResponse> {
      return get(
        `${BASE_API_URL()}/file-storage/upload-url/custom_field_option?file=${fileName}&uuid=${cellUUID}`
      );
    },
    mediaUploaded(
      mediaData: MediaUploadedRequest
    ): Promise<MediaUploadedResponse> {
      return post(
        `${BASE_API_URL()}/media/custom_field_option/uploaded`,
        mediaData
      );
    },
    listCustomFields(query?: string): Promise<CustomFieldsResponse> {
      return get(`${BASE_API_URL()}/field${query}`) as Promise<ListApiResponse>;
    },
    getCustomField(customFieldKey: string): Promise<CustomFieldResponse> {
      return get(`${BASE_API_URL()}/field/${customFieldKey}`);
    },
    createCustomField(
      req: CreateCustomFieldRequest
    ): Promise<CustomFieldResponse> {
      return post(`${BASE_API_URL()}/field`, req);
    },
    updateCustomField(
      customFieldId: number,
      req: UpdateCustomFieldRequest
    ): Promise<CustomFieldResponse> {
      return patch(`${BASE_API_URL()}/field/${customFieldId}`, req);
    },
    deleteCustomField(customFieldId: number): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/field/${customFieldId}`);
    },
    listCustomFieldOptionsByKey(
      customFieldKey: string,
      query?: string
    ): Promise<CustomFieldOptionsResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option${query}`
      ) as Promise<ListApiResponse>;
    },
    getCustomFieldOption(
      customFieldKey: string,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return get(
        `${BASE_API_URL()}/field/${customFieldKey}/option/${optionId}`
      );
    },
    createCustomFieldOption(
      customFieldId: number,
      req: CreateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return post(`${BASE_API_URL()}/field/${customFieldId}/option`, req);
    },
    bulkCreateCustomFieldOptions(
      customFieldKey: string,
      req: BulkCreateCustomFieldOptions[]
    ): Promise<CustomFieldOptionResponse> {
      return post(`${BASE_API_URL()}/field/${customFieldKey}/option?bulk`, req);
    },
    updateCustomFieldOption(
      customFieldId: number,
      optionId: number,
      req: UpdateCustomFieldOptionRequest
    ): Promise<CustomFieldOptionResponse> {
      return patch(
        `${BASE_API_URL()}/field/${customFieldId}/option/${optionId}`,
        req
      );
    },
    deleteCustomFieldOption(
      customFieldId: number,
      optionId: number
    ): Promise<CustomFieldOptionResponse> {
      return del(`${BASE_API_URL()}/field/${customFieldId}/option/${optionId}`);
    },
    getCustomFieldFormOptions(): Promise<CustomFieldFormOptionsResponse> {
      return get(
        `${BASE_API_URL()}/values/custom_field_value_type,custom_field_type`
      );
    },
  });
}
