import { useState } from 'react';

import { LineOutlined, CheckOutlined } from '@ant-design/icons';
import { Form, message, Input, Button, Space, Typography } from 'antd';

import './ChangePasswordForm.less';

const { Text } = Typography;

interface PasswordRequirement {
  text: string;
  fulfilled: boolean;
}

interface PasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePasswordForm = () => {
  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([
    { text: '8 characters (required)', fulfilled: false },
    { text: 'One special character', fulfilled: false },
    { text: 'One number', fulfilled: false },
    { text: 'One uppercase character', fulfilled: false },
    { text: 'One lowercase character', fulfilled: false },
  ]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fulfilledRequirements = passwordRequirements.map((req) =>
      req.text === '8 characters (required)'
        ? { ...req, fulfilled: value.length >= 8 }
        : req.text === 'One special character'
        ? { ...req, fulfilled: /\W/.test(value) }
        : req.text === 'One number'
        ? { ...req, fulfilled: /\d/.test(value) }
        : req.text === 'One uppercase character'
        ? { ...req, fulfilled: /[A-Z]/.test(value) }
        : req.text === 'One lowercase character'
        ? { ...req, fulfilled: /[a-z]/.test(value) }
        : req
    );
    setPasswordRequirements(fulfilledRequirements);
  };

  const [form] = Form.useForm();

  const handleSubmit = (values: PasswordFormValues) => {
    const numFulfilledRequirements = passwordRequirements.filter(
      (req) => req.fulfilled
    ).length;
    if (numFulfilledRequirements < 3) {
      message.error(
        'Your new password must meet at least 3 of the requirements.'
      );
      return;
    }
    if (values.newPassword !== values.confirmNewPassword) {
      message.error('New password and confirm new password do not match.');
      return;
    }
    console.log('Submitted values:', values);
    // TODO: Implement password change logic here
  };

  return (
    <div>
      <Text>Your password must contain at least 3 of the following:</Text>
      <div>
        <ul className="password-requirements-list">
          {passwordRequirements.map((req, index) => (
            <li
              key={index}
              style={{ color: req.fulfilled ? '#40C976' : 'inherit' }}
            >
              {req.fulfilled ? (
                <CheckOutlined style={{ color: '#40C976', marginRight: 8 }} />
              ) : (
                <LineOutlined style={{ marginRight: 8 }} />
              )}
              {req.text}
            </li>
          ))}
        </ul>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[{ required: true, message: 'Current password is required.' }]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="Current Password"
          />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: 'New password is required.' }]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="New Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            { required: true, message: 'Confirm new password is required.' },
          ]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="Confirm New Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>

        <Form.Item>
          <Space style={{ float: 'right' }}>
            {/* // TODO: figure out the permissions for changing current user */}
            <Button
              data-cy="change-password-form-submit-btn"
              htmlType="submit"
              type="primary"
              // loading={fetchingEmailNotifications}
              // disabled={fetchingEmailNotifications}
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
