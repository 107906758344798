import { isEqual } from 'lodash';

// Maybe create custom solution in the future since lodash methods are known to be kinda heavy
export const propsAreEqual = (
  prevProps: Object,
  nextProps: Object
): boolean => {
  return isEqual(prevProps, nextProps);
};

export const isEmailValid = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isPasswordValid = (
  password: string,
  confirmationPassword: string
): boolean => {
  const strongPasswordRegex =
    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (password !== confirmationPassword) {
    return false;
  }
  return strongPasswordRegex.test(password);
};
