import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CloudUploadOutlined,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  Select,
  Space,
  Tabs,
  Upload,
} from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { CSVLink } from 'react-csv';

import ProductLibraryService from '../../../api/product-library';
import { POGCheckedUPCProduct } from '../../../models/pog-checker/index';
import './POGCheckerView.less';
import POGCheckedUPCTableList from './components/POGCheckedUPCTableList/POGCheckedUPCTableList';

const { Dragger } = Upload;
const { Option } = Select;
const { TabPane } = Tabs;

interface PSAPlanogram {
  id: number;
  name: string;
  found: number;
  missing: number;
  needsProcessing: number;
  products: POGCheckedUPCProduct[];
}

interface SearchProps {
  searchQuery: string;
  selectedProducts?: POGCheckedUPCProduct[];
  csvFileName?: string;
  setSearchQuery: (query: string) => void;
}

const SearchInput: React.FC<SearchProps> = ({
  searchQuery,
  setSearchQuery,
}) => (
  <Input
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search"
    suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
    className="pog-search-input"
  />
);

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  selectedProducts = [],
  csvFileName,
}: SearchProps) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const downloadLinkEl = document.createElement('a');

    switch (e.key) {
      case '1':
        break;

      default:
        break;
    }
    downloadLinkEl.click();
    downloadLinkEl.remove();
  };

  const menu = useMemo(
    (): JSX.Element => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
          <CSVLink
            filename={csvFileName}
            headers={[
              { label: 'UPC', key: 'column1' },
              { label: 'Product Name', key: 'column2' },
              { label: 'Brand', key: 'column3' },
            ]}
            data={selectedProducts?.map(({ column1, column2, column3 }) => {
              return {
                column1,
                column2,
                column3,
              };
            })}
          >
            Download CSV
          </CSVLink>
        </Menu.Item>
        <Menu.Item key="2">Download OBJ</Menu.Item>
        <Menu.Item key="3">Download C4D</Menu.Item>
        <Menu.Item key="4">Download All PNG</Menu.Item>
        <Menu.Item key="5">Download Front PNG</Menu.Item>
      </Menu>
    ),
    [selectedProducts, csvFileName]
  );

  return (
    <div className="pog-search-bar">
      <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Dropdown overlay={menu} placement="bottomRight">
        <Button type="primary">
          <Space>
            Download
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

const POGCheckerView: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [planograms, setPlanograms] = useState<PSAPlanogram[]>([]);
  const [selectedPlanogram, setSelectedPlanogram] = useState<
    POGCheckedUPCProduct[]
  >([]);
  const checkPSAFile = useCallback(async (req: UploadRequestOption<any>) => {
    const fileData = new FormData();
    fileData.append('pog_file', req.file as File);
    const data = await ProductLibraryService().checkPOG(fileData);
    setPlanograms(
      data?.map(({ id, name, found, missing, needsProcessing, rows }: any) => ({
        id,
        name,
        found,
        missing,
        needsProcessing,
        products: rows,
      }))
    );
    setSelectedPlanogram(data[0].rows);
    req.onSuccess!(true);
  }, []);

  const handlePlangoramSelect = useCallback(
    (planogramId: number) => {
      const selection = planograms.find(({ id }) => id === planogramId);
      setSelectedPlanogram(selection?.products || []);
    },
    [planograms]
  );

  const missingItems = useMemo(
    () => selectedPlanogram.filter((product) => !product.found),
    [selectedPlanogram]
  );

  const foundItems = useMemo(
    () =>
      selectedPlanogram.filter(
        (product) => product.found && product.item?.status === 'approved'
      ),
    [selectedPlanogram]
  );

  const comingSoon = useMemo(
    () =>
      selectedPlanogram.filter(
        (product) => product.found && product.item?.status !== 'approved'
      ),
    [selectedPlanogram]
  );

  const [selectedTab, setSelectedTab] = useState<string>('1');
  const [selectedTabData, setSelectedTabData] = useState<
    POGCheckedUPCProduct[]
  >([]);

  const filterProducts = useCallback(
    (productsToFilter: POGCheckedUPCProduct[]) => {
      if (!!searchQuery) {
        setSelectedTabData(
          productsToFilter.filter(
            (item) =>
              item.column1.toLowerCase().match(searchQuery.toLowerCase()) ||
              item.column2.toLowerCase().match(searchQuery.toLowerCase()) ||
              item.column3.toLowerCase().match(searchQuery.toLowerCase())
          )
        );
      } else {
        setSelectedTabData(productsToFilter);
      }
    },
    [searchQuery]
  );

  const getCSVFileName = () => {
    switch (selectedTab) {
      case '1':
        return 'missing-items.csv';
      case '2':
        return 'found-items.csv';
      case '3':
        return 'coming-soon-items.csv';
    }
  };

  useEffect(() => {
    switch (selectedTab) {
      case '1':
        filterProducts(missingItems);
        break;
      case '2':
        filterProducts(foundItems);
        break;
      case '3':
        filterProducts(comingSoon);
        break;
    }
  }, [filterProducts, selectedTab, missingItems, foundItems, comingSoon]);

  console.log('Found => ', selectedTabData);

  return (
    <Card className="checker-wrapper">
      <Form layout="vertical">
        <p className="form-header">Upload File </p>
        <p className="form-desc">
          Check your planogram to see what models we have available in the
          product library
        </p>
        <Form.Item
          name="fileName"
          rules={[{ required: true, message: 'A file is required!' }]}
        >
          <Dragger
            accept=".psa,.csv,.jda"
            method="post"
            customRequest={checkPSAFile}
            progress={{
              strokeColor: '#8E24AA',
              strokeLinecap: 'butt',
              format: (percent) =>
                percent && `${parseFloat(percent.toFixed(2))}%`,
            }}
          >
            <div className="uploader-wrapper">
              <p>
                <CloudUploadOutlined style={{ fontSize: '40px' }} />
              </p>
              <span className="uploader-header">
                Drop folders here or click to select files.
              </span>
              <span className="uploader-info">
                (Files types that are accepted are .csv and .jda)
              </span>
            </div>
          </Dragger>
        </Form.Item>
      </Form>
      {planograms.length > 0 ? (
        <Select
          defaultValue={planograms[0].id}
          onChange={handlePlangoramSelect}
          style={{ width: '100%', marginBottom: 15 }}
        >
          {planograms?.map(({ name, id }) => (
            <Option value={id}>{name}</Option>
          ))}
        </Select>
      ) : null}
      {selectedPlanogram.length > 0 ? (
        <Tabs
          defaultActiveKey="1"
          destroyInactiveTabPane
          onTabClick={setSelectedTab}
        >
          <TabPane
            tab={
              <Space>
                Missing Items
                <Badge
                  count={missingItems.length}
                  showZero
                  style={{ backgroundColor: '#8E25AA' }}
                />
              </Space>
            }
            key="1"
          >
            <div className="pog-result-header">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedProducts={selectedTabData}
                csvFileName={getCSVFileName()}
              />
            </div>
            <POGCheckedUPCTableList
              upcProducts={selectedTabData}
              loading={selectedPlanogram.length === 0}
            />
          </TabPane>
          <TabPane
            tab={
              <Space>
                Found Items
                <Badge
                  count={foundItems.length}
                  showZero
                  style={{ backgroundColor: '#8E25AA' }}
                />
              </Space>
            }
            key="2"
          >
            <div className="pog-result-header">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedProducts={selectedTabData}
                csvFileName={getCSVFileName()}
              />
            </div>
            <POGCheckedUPCTableList
              upcProducts={selectedTabData}
              loading={selectedPlanogram.length === 0}
            />
          </TabPane>
          <TabPane
            tab={
              <Space>
                Coming Soon
                <Badge
                  count={comingSoon.length}
                  showZero
                  style={{ backgroundColor: '#8E25AA' }}
                />
              </Space>
            }
            key="3"
          >
            <div className="pog-result-header">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedProducts={selectedTabData}
                csvFileName={getCSVFileName()}
              />
            </div>
            <POGCheckedUPCTableList
              upcProducts={selectedTabData}
              loading={selectedPlanogram.length === 0}
            />
          </TabPane>
        </Tabs>
      ) : null}
    </Card>
  );
};

export default POGCheckerView;
