import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { ApiError, handleError, PaginationModel } from '../../../api/base';
import OnlineVirtualResearchService from '../../../api/online-virtual-research';
import { ListOvrProjectsParams } from '../../../hooks/online-virtual-research';
import {
  ListOvrProjectModel,
  OvrProjectModel,
} from '../../../models/online-virtual-research';
import { convertParamsToQuery } from '../../../util/converter';
import { fetchOvrProject } from '../ovrProjectDetails/ovrProjectDetailsSlice';

export interface OvrProjectsState {
  cellsInUseByProject: { [cellId: string]: number };
  value: ListOvrProjectModel[];
  allOvrProjects: ListOvrProjectModel[];
  fetchingOvrCellsInUseByProject: boolean;
  fetchingOvrProjects: boolean;
  searchingOvrProjects: boolean;
  error: {
    code: number | null;
    message: string | null;
  };
  totalCount: number;
  pagination: PaginationModel | null;
  count: number;
  current: number;
}

const initialState: OvrProjectsState = {
  cellsInUseByProject: {},
  value: [],
  allOvrProjects: [],
  fetchingOvrCellsInUseByProject: false,
  fetchingOvrProjects: false,
  searchingOvrProjects: false,
  error: { code: null, message: null },
  totalCount: 0,
  count: 0,
  current: 1,
  pagination: null,
};

const projectsParams: ListOvrProjectsParams = {
  _limit: 20,
  _order_by: 'updated_at:desc',
  _columns: '*,client.*,createdByUser.*',
  _offset: 0,
  status: 'active',
};

export const fetchOvrProjects = createAsyncThunk(
  'ovrProjects/fetchOvrProjects',
  async (
    {
      params,
      searching = false,
      fetchingOvrProjectsMore = false,
    }: {
      params: ListOvrProjectsParams;
      searching?: boolean;
      fetchingOvrProjectsMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);

      const resp = await OnlineVirtualResearchService().listOVRProjects(
        queryString
      );
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        fetchingOvrProjectsMore,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchOvrProjectsByName = createAsyncThunk(
  'ovrProjects/searchOvrProjectsByName',
  async (query: string, { rejectWithValue }) => {
    try {
      let params: ListOvrProjectsParams = {
        _limit: 40,
        _order_by: 'updated_at:desc',
        _columns: '*,client.*,createdByUser.*',
        _offset: 0,
        status: 'active',
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await OnlineVirtualResearchService().listOVRProjects(
        queryString
      );

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingOvrProjectsMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreOvrProjects = createAsyncThunk(
  'ovrProjects/loadMoreOvrProjects',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        ovrProjects: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        _limit: limit,
        _order_by: 'updated_at:desc',
        _columns: '*,client.*,createdByUser.*',
        _offset: nextOffset,
        status: 'active',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await OnlineVirtualResearchService().listOVRProjects(query);
      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateOvrProjects = createAsyncThunk(
  'customFields/paginateOvrProjects',
  async (
    { page, pageSize }: { page: number; pageSize: number },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      const nextParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await OnlineVirtualResearchService().listOVRProjects(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveOvrProject = createAsyncThunk(
  'ovrProjects/saveOvrProject',
  async (
    {
      project,
      isNewProject,
      projectId,
    }: {
      project: OvrProjectModel;
      isNewProject: boolean;
      projectId?: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const queryParams = convertParamsToQuery({
        _columns: 'createdByUser.*',
      });

      const resp = isNewProject
        ? await OnlineVirtualResearchService().createOVRProject(project)
        : await OnlineVirtualResearchService().updateOvrProject(
            projectId!,
            project,
            queryParams
          );

      if (isNewProject) {
        dispatch(
          fetchOvrProjects({
            params: projectsParams,
          })
        );
      } else {
        dispatch(
          fetchOvrProject({
            projectId: resp.data.uuid,
            params: {
              _columns:
                '*,client.*,createdByUser.*,cellGroups.*,cellGroups.cell.*',
            },
          })
        );
      }

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOvrProject = createAsyncThunk(
  'ovrProjects/deleteOvrProject',
  async (projectId: string, { dispatch, rejectWithValue }) => {
    try {
      const resp = await OnlineVirtualResearchService().deleteOvrProject(
        projectId
      );

      await dispatch(fetchOvrProjects({ params: projectsParams }));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOvrCellsInProjects = createAsyncThunk(
  'ovrProjects/fetchOvrCellsInProjects',
  async (cellIds: string[], { rejectWithValue }) => {
    try {
      const resp = await OnlineVirtualResearchService().listOVRCellsInProjects(
        cellIds
      );

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ovrProjectsSlice = createSlice({
  name: 'ovrProjects',
  initialState,
  reducers: {
    removeOvrProject(state, { payload: { id } }: { payload: { id: string } }) {
      const updatedProjects = state.value.filter((p) => p.uuid !== id);
      state.value = updatedProjects;
      state.totalCount = state.totalCount - 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOvrCellsInProjects.pending, (state) => {
        state.fetchingOvrCellsInUseByProject = true;
      })
      .addCase(fetchOvrCellsInProjects.fulfilled, (state, action) => {
        state.fetchingOvrCellsInUseByProject = false;
        state.cellsInUseByProject = action.payload;
      })
      .addCase(fetchOvrCellsInProjects.rejected, (state, action) => {
        state.fetchingOvrCellsInUseByProject = false;
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      })

      .addCase(searchOvrProjectsByName.pending, (state) => {
        state.searchingOvrProjects = true;
        state.fetchingOvrProjects = true;
      })
      .addCase(searchOvrProjectsByName.fulfilled, (state, action) => {
        state.searchingOvrProjects = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
        state.totalCount = action.payload.pagination.count;
        state.allOvrProjects = action.payload.data;
        state.fetchingOvrProjects = false;
      })
      .addCase(searchOvrProjectsByName.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.fetchingOvrProjects = false;
        state.searchingOvrProjects = false;
      });

    builder.addCase(loadMoreOvrProjects.fulfilled, (state, action: any) => {
      state.fetchingOvrProjects = false;
      state.pagination = action.payload.pagination;
      state.value = [...state.value, ...action.payload.data];
      state.allOvrProjects = [...state.value, ...action.payload.data];
    });
    builder.addCase(loadMoreOvrProjects.pending, (state) => {
      state.fetchingOvrProjects = true;
    });
    builder.addCase(loadMoreOvrProjects.rejected, (state, action) => {
      if ((action.payload as unknown as ApiError).error) {
        state.error = (action.payload as unknown as ApiError).error;
        handleError(action.payload as unknown as ApiError);
      }
      state.fetchingOvrProjects = false;
    });

    builder.addCase(paginateOvrProjects.fulfilled, (state, action: any) => {
      state.fetchingOvrProjects = false;
      if (action.payload) {
        state.pagination = action.payload.pagination;
        state.value = [...action.payload.data];
      }
    });
    builder.addCase(paginateOvrProjects.pending, (state) => {
      state.fetchingOvrProjects = true;
    });
    builder.addCase(paginateOvrProjects.rejected, (state, action) => {
      if ((action.payload as unknown as ApiError).error) {
        state.error = (action.payload as unknown as ApiError).error;
        handleError(action.payload as unknown as ApiError);
      }
      handleError(action.error as ApiError);
      state.fetchingOvrProjects = false;
    });

    builder
      .addCase(fetchOvrProjects.pending, (state) => {
        state.fetchingOvrProjects = true;
      })
      .addCase(
        fetchOvrProjects.fulfilled,
        (
          state,
          { payload: { data, searching, pagination, fetchingOvrProjectsMore } }
        ) => {
          state.fetchingOvrProjects = false;

          if (!fetchingOvrProjectsMore) {
            state.value = data;
          } else {
            state.value = {
              ...state.value,
              ...data,
            };
          }

          if (!searching || fetchingOvrProjectsMore) {
            state.totalCount = pagination.count;
            state.pagination = pagination;
          }
        }
      )
      .addCase(fetchOvrProjects.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.fetchingOvrProjects = false;
      });

    builder
      .addCase(saveOvrProject.pending, (state) => {
        state.fetchingOvrProjects = true;
      })
      .addCase(saveOvrProject.fulfilled, (state, { payload: project }) => {
        const updatedProjects = state.value.map((p) => {
          if (p.uuid !== project.uuid) return p;
          return project;
        });

        state.value = updatedProjects;
        state.fetchingOvrProjects = false;
      })
      .addCase(saveOvrProject.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.fetchingOvrProjects = false;
      });

    builder
      .addCase(deleteOvrProject.pending, (state) => {
        state.fetchingOvrProjects = false;
      })
      .addCase(deleteOvrProject.fulfilled, (state, { payload }) => {
        const updatedProjects = state.value.filter((p) => p.uuid !== payload);
        state.value = updatedProjects;
      })
      .addCase(deleteOvrProject.rejected, (state, action) => {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
        state.fetchingOvrProjects = false;
      });
  },
});
