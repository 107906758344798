import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { PaginationModel } from '../../../api/base';
import CategoryService from '../../../api/category';
import { CategoryProps } from '../../../components/containers/forms/CategoryForm/CategoryForm';
import { CategoryModel } from '../../../models/category';
import { convertParamsToQuery } from '../../../util';

export interface CategoryState {
  value: CategoryModel[];
  fetchingCategories: boolean;
  savingCategory: boolean;
  deletingCategory: boolean;
  pagination: PaginationModel | null;
  count: number;
  current: number;
}

interface CreateCategoryProps {
  category: CategoryProps;
}

interface UpdateCategoryProps {
  categoryId: string;
  category: CategoryProps;
}

export interface CategoryParams {
  _limit?: number;
  _order_by?: string;
  _offset?: number;
  _columns?: string;
  name?: string;
  q?: string;
}

const initialState: CategoryState = {
  value: [],
  fetchingCategories: false,
  savingCategory: false,
  deletingCategory: false,
  pagination: {
    limit: 10,
    offset: 0,
    count: 0,
  },
  count: 0,
  current: 1,
};

export const fetchCategories = createAsyncThunk(
  'category/fetchCategories',
  async (_arg, { getState }) => {
    const {
      category: { pagination },
    } = getState() as RootState;

    const nextParams: CategoryParams = {
      _limit: pagination?.limit,
      _offset: pagination?.offset,
      _order_by: 'created_at:desc',
    };

    const query = convertParamsToQuery(nextParams);
    let resp = await CategoryService().getCategory(query);

    const { limit, count, offset } = resp.pagination;
    let current = offset / limit + 1;

    if (offset >= count && count > 0) {
      current = current - 1;
      const nextParams = {
        _limit: limit,
        _offset: offset - limit,
        _order_by: 'created_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      resp = await CategoryService().getCategory(query);
    }

    return {
      current,
      data: resp.data,
      pagination: resp.pagination,
    };
  }
);
export const fetchSubCategories = createAsyncThunk(
  'category/fetchSubCategories',
  async (id: string) => {
    const resp = await CategoryService().getSubCategory(id);
    return resp.data;
  }
);

export const searchCategoryByName = createAsyncThunk(
  'category/searchCategoryByName',
  async ({ queryStr }: { queryStr: string }, { getState }) => {
    const {
      category: { pagination },
    } = getState() as RootState;

    let nextParams: CategoryParams = {
      _limit: pagination?.limit,
      _offset: pagination?.offset,
      _order_by: 'created_at:desc',
    };

    if (queryStr && queryStr.length) {
      nextParams = {
        ...nextParams,
        name: `${queryStr.toLowerCase()}`,
      };
    }

    const query = convertParamsToQuery(nextParams);
    const resp = await CategoryService().getCategory(query);

    return {
      data: resp.data,
      pagination: resp.pagination,
    };
  }
);

export const paginateCategory = createAsyncThunk(
  'category/paginateCategory',
  async ({
    page,
    pageSize,
    queryStr,
  }: {
    page: number;
    pageSize: number;
    queryStr: string;
  }) => {
    const limit = pageSize;
    const nextOffset = (page - 1) * pageSize;

    let nextParams: CategoryParams = {
      _limit: limit,
      _offset: nextOffset,
      _order_by: 'updated_at:desc',
    };

    if (queryStr && queryStr.length) {
      nextParams = {
        ...nextParams,
        q: `${queryStr.toLowerCase()}`,
      };
    }

    const query = convertParamsToQuery(nextParams);
    const resp = await CategoryService().getCategory(query);

    return {
      current: page,
      data: resp.data,
      pagination: resp.pagination,
    };
  }
);

export const createCategory = createAsyncThunk(
  'category/createCategory',
  async ({ category }: CreateCategoryProps, { dispatch, rejectWithValue }) => {
    try {
      const resp = await CategoryService().createCategory(category);

      await dispatch(fetchCategories());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async (
    { categoryId, category }: UpdateCategoryProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await CategoryService().updateCategory(categoryId, category);

      await dispatch(fetchCategories());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/deleteCustomField',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await CategoryService().deleteCategory(id);

      await dispatch(fetchCategories());

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const adjustCategory = (c: CategoryModel): CategoryModel => {
  const category = { ...c, key: c.uuid };
  if (c.has_child) {
    return {
      ...category,
      children: [],
    };
  }
  return category;
};

const updateCategoryChildrenByParentID = (
  parent_id: string,
  categories: CategoryModel[],
  value: CategoryModel[]
): CategoryModel[] => {
  return categories.map((data: CategoryModel) => {
    if (data.uuid == parent_id) {
      data.children = value;
    }
    if (data.children !== undefined && data.children.length > 0) {
      data.children = updateCategoryChildrenByParentID(
        parent_id,
        data.children,
        value
      );
    }

    return data;
  });
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // FETCH CATEGORIES
      .addCase(fetchCategories.pending, (state) => {
        state.fetchingCategories = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action: any) => {
        state.value = action.payload.data.map((c: CategoryModel) => {
          return adjustCategory(c);
        });
        state.fetchingCategories = false;
        state.count = action.payload.pagination.count;
        state.current = action.payload.current;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.fetchingCategories = false;
      })

      // CREATE CATEGORY
      .addCase(createCategory.pending, (state) => {
        state.savingCategory = true;
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.savingCategory = false;
      })
      .addCase(createCategory.rejected, (state) => {
        state.savingCategory = false;
      })

      // UPDATE CATEGORY
      .addCase(updateCategory.pending, (state) => {
        state.savingCategory = true;
      })
      .addCase(updateCategory.fulfilled, (state) => {
        state.savingCategory = false;
      })
      .addCase(updateCategory.rejected, (state) => {
        state.savingCategory = false;
      })

      // DELETE CATEGORY
      .addCase(deleteCategory.pending, (state) => {
        state.deletingCategory = true;
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        state.deletingCategory = false;
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.deletingCategory = false;
      })

      // SEARCH CATEGORY BY NAME
      .addCase(searchCategoryByName.pending, (state) => {
        state.fetchingCategories = true;
      })
      .addCase(searchCategoryByName.fulfilled, (state, action: any) => {
        state.value = action.payload.data.map((c: CategoryModel) => {
          return adjustCategory(c);
        });
        state.fetchingCategories = false;
        state.count = action.payload.pagination.count;
        state.pagination = action.payload.pagination;
        state.current = 1;
      })
      .addCase(searchCategoryByName.rejected, (state) => {
        state.fetchingCategories = false;
      })

      // PAGINATE CATEGORY
      .addCase(paginateCategory.pending, (state) => {
        state.fetchingCategories = true;
      })
      .addCase(paginateCategory.fulfilled, (state, action: any) => {
        state.value = action.payload.data.map((c: CategoryModel) => {
          return adjustCategory(c);
        });
        state.fetchingCategories = false;
        state.count = action.payload.pagination.count;
        state.current = action.payload.current;
        state.pagination = action.payload.pagination;
      })
      .addCase(paginateCategory.rejected, (state) => {
        state.fetchingCategories = false;
      })

      // FETCH SUB CATEGORIES
      .addCase(fetchSubCategories.fulfilled, (state, action: any) => {
        const firstChild = action.payload[0] as CategoryModel;
        const adjustedPayload = action.payload.map((c: CategoryModel) =>
          adjustCategory(c)
        );

        state.value = updateCategoryChildrenByParentID(
          firstChild.parent_id,
          state.value,
          adjustedPayload
        );
      });
  },
});
