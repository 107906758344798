import {
  ManufacturersListResponse,
  ManufacturersResponse,
  UpdateManufacturerRequest,
  CreateManufacturerRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { ApiResponse, del, get, ListApiResponse, patch, post } from '../base';

export default function ManufacturerService() {
  return Object.freeze({
    listManufacturers(query?: string): Promise<ManufacturersListResponse> {
      return get(
        `${BASE_API_URL()}/meta-data/manufacturer${query}`
      ) as Promise<ListApiResponse>;
    },
    createManufacturer(
      req: CreateManufacturerRequest
    ): Promise<ManufacturersResponse> {
      return post(`${BASE_API_URL()}/client/`, req);
    },
    updateManufacturer(
      manufacturerId: string,
      req: UpdateManufacturerRequest
    ): Promise<ManufacturersResponse> {
      return patch(`${BASE_API_URL()}/client/${manufacturerId}`, req);
    },
    deleteManufacturer(manufacturerId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/client/${manufacturerId}`);
    },
  });
}
