import { FC, useCallback, useMemo } from 'react';
import './ShopperProperties.less';

import { Select, TimePicker } from 'antd';
import moment from 'moment';

// Shoppers images
import largeManWithBasketShopperImage from '../../../../../../../../../assets/fixtures/BasketMaleLgShopper/front.png';
import smallManWithBasketShopperImage from '../../../../../../../../../assets/fixtures/BasketMaleSmShopper/front.png';
import womanWithBasketShopperImage from '../../../../../../../../../assets/fixtures/BasketShopper/front.png';
import womanWithCartShopperImage from '../../../../../../../../../assets/fixtures/CartShopper/front.png';
import womanWithoutBasketShopperImage from '../../../../../../../../../assets/fixtures/NoBasketFemaleSmShopper/front.png';
import largeManWithoutBasketShopperImage from '../../../../../../../../../assets/fixtures/NoBasketMaleLgShopper/front.png';
import smallManWithoutBasketShopperImage from '../../../../../../../../../assets/fixtures/NoBasketMaleSmShopper/front.png';
import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/svgs/close-icon.svg';
import Tooltip from '../../../../../../../../elements/Tooltip';
import {
  Draw2DFigureModel,
  FigureAttributesModel,
  QueueLineModel,
  ShopperType,
  ShopperUserDataModel,
} from '../../../../../BuilderLayoutEditor/types';

const { Option } = Select;
interface ShopperPropertiesProps {
  shopper: Draw2DFigureModel;
  onUpdateShopper: (
    shopper: ShopperUserDataModel,
    action: 'updateShopperType' | 'removeShopper' | 'updateShopperElaspedTime',
    value?: ShopperType | string
  ) => void;
  selectedShopperQueueLine?: QueueLineModel;
  overlappingShoppers: string[];
}

const ShopperProperties: FC<ShopperPropertiesProps> = (props) => {
  const {
    selectedShopperQueueLine,
    onUpdateShopper,
    shopper,
    overlappingShoppers,
  } = props;

  const getShopperListWrapperHeight = (): string => {
    const propertiesSectionEl = window.document.querySelector(
      '#fixturePropertiesSection'
    );
    return `${
      propertiesSectionEl?.getBoundingClientRect().height! - 54 - 54
    }px`;
  };

  const getQueueLineGroupName = useCallback((lineId: number) => {
    switch (lineId) {
      case 1:
        return 'A';
      case 2:
        return 'B';
      case 3:
        return 'C';
      case 4:
        return 'D';
      case 5:
        return 'E';
    }
  }, []);

  const isOnlyShopper = useMemo((): boolean => {
    return selectedShopperQueueLine?.shoppers.length! === 1;
  }, [selectedShopperQueueLine?.shoppers.length]);

  const isFirstInLine = useCallback(
    (orderInLine: number): boolean => {
      return orderInLine === 1 && !isOnlyShopper;
    },
    [isOnlyShopper]
  );

  const isMiddleInLine = useCallback(
    (orderInLine: number): boolean => {
      return (
        orderInLine > 1 &&
        orderInLine < selectedShopperQueueLine?.shoppers.length!
      );
    },
    [selectedShopperQueueLine?.shoppers.length]
  );

  const isLastInLine = useCallback(
    (orderInLine: number): boolean => {
      return (
        orderInLine === selectedShopperQueueLine?.shoppers.length! &&
        !isOnlyShopper
      );
    },
    [isOnlyShopper, selectedShopperQueueLine?.shoppers.length]
  );

  const sortShoppersFromLeastToGreatest = useCallback(
    (a: FigureAttributesModel, b: FigureAttributesModel) => {
      if (a.userData.shopper?.orderInLine! > b.userData.shopper?.orderInLine!)
        return 1;
      return -1;
    },
    []
  );

  const isSelectedShopper = useCallback(
    (orderInLine: number): boolean => {
      return shopper.userData.shopper?.orderInLine === orderInLine;
    },
    [shopper.userData.shopper?.orderInLine]
  );

  const isOverlapping = useCallback(
    (shopper: FigureAttributesModel): boolean => {
      return overlappingShoppers.some(
        (shopperId: string) => shopperId === shopper.userData.id
      );
    },
    [overlappingShoppers]
  );

  const renderShopperPreviewImage = useCallback(
    (shopper: FigureAttributesModel) => {
      const selectedShopperType = shopper.userData.shopper?.type;

      switch (selectedShopperType) {
        case ShopperType.WomanWithBasket:
          return (
            <img
              src={womanWithBasketShopperImage}
              height={50}
              alt="woman-with-basket-shopper"
            />
          );
        case ShopperType.WomanWithoutBasket:
          return (
            <img
              src={womanWithoutBasketShopperImage}
              height={50}
              alt="woman-without-basket-shopper"
            />
          );
        case ShopperType.WomanWithCart:
          return (
            <img
              src={womanWithCartShopperImage}
              height={50}
              alt="woman-with-cart-shopper"
            />
          );
        case ShopperType.SmallManWithBasket:
          return (
            <img
              src={smallManWithBasketShopperImage}
              height={50}
              alt="small-man-with-basket-shopper"
            />
          );
        case ShopperType.SmallManWithoutBasket:
          return (
            <img
              src={smallManWithoutBasketShopperImage}
              height={50}
              alt="small-man-without-basket-shopper"
            />
          );
        case ShopperType.LargeManWithBasket:
          return (
            <img
              src={largeManWithBasketShopperImage}
              height={50}
              alt="large-man-with-basket-shopper"
            />
          );
        case ShopperType.LargeManWithoutBasket:
          return (
            <img
              src={largeManWithoutBasketShopperImage}
              height={50}
              alt="large-man-without-basket-shopper"
            />
          );
        default:
          return (
            <img
              src={womanWithBasketShopperImage}
              height={50}
              alt="woman-with-basket-shopper"
            />
          );
      }
    },
    []
  );

  return (
    <div
      className="fixture-properties shoppers"
      data-cy="shoppers-fixture-props"
    >
      <div className="shoppers-queue-line-wrapper">
        <div
          className="queue-line-header"
          style={{ background: selectedShopperQueueLine?.color }}
          data-cy="queue-line-header"
        >
          <div>{`Queue line ${getQueueLineGroupName(
            selectedShopperQueueLine?.lineId!
          )} - Wait Time Per Shopper`}</div>
          <TimePicker
            showNow={false}
            style={{ width: '82px' }}
            onChange={(_, timeString) =>
              onUpdateShopper(
                shopper.userData.shopper!,
                'updateShopperElaspedTime',
                timeString
              )
            }
            value={moment(shopper.userData.shopper?.elapsedWaitTime, 'mm:ss')}
            format={'mm:ss'}
          />
          <Tooltip
            borderColor={selectedShopperQueueLine?.color}
            color={selectedShopperQueueLine?.color}
            offsetX={10}
            text="
          The queue line tool allows you to create a queue line by placing shoppers each time you click on the layout. The first shopper model you place will represent the first person in line. The first shopper in line will disappear after the 'wait time' you entered passes. Each shopper model will then move up in line and the timer will restart with the new shopper at the front of the line.
          "
          >
            <div
              className="queue-line-tool-info"
              style={{ color: selectedShopperQueueLine?.color }}
            >
              ?
            </div>
          </Tooltip>
        </div>
        <div
          className="queue-line-shoppers-list-wrapper"
          style={{ height: getShopperListWrapperHeight() }}
          data-cy="queue-line-shoppers-list"
        >
          {selectedShopperQueueLine?.shoppers
            .sort(sortShoppersFromLeastToGreatest)
            .map((shopper: FigureAttributesModel) => {
              return (
                <div
                  key={shopper.userData.id}
                  className="queue-line-shopper-item"
                  style={{ borderColor: selectedShopperQueueLine?.color }}
                >
                  <div
                    className="shopper-order-in-line-wrapper"
                    data-cy="shopper-order-in-line-wrapper"
                  >
                    {(isFirstInLine(shopper.userData.shopper?.orderInLine!) ||
                      isMiddleInLine(
                        shopper.userData.shopper?.orderInLine!
                      )) && (
                      <div
                        style={{
                          height: '12px',
                          width: '3px',
                          background: selectedShopperQueueLine?.color,
                          position: 'absolute',
                          top: '29px',
                        }}
                      />
                    )}
                    <div
                      className="shopper-order-in-line"
                      style={{
                        borderColor: selectedShopperQueueLine?.color,
                        color: isSelectedShopper(
                          shopper.userData.shopper?.orderInLine!
                        )
                          ? '#ffffff'
                          : selectedShopperQueueLine?.color,
                        background: isSelectedShopper(
                          shopper.userData.shopper?.orderInLine!
                        )
                          ? selectedShopperQueueLine?.color
                          : '#ffffff',
                      }}
                      data-cy="shopper-order-in-line"
                    >
                      {shopper.userData.shopper?.orderInLine}
                    </div>
                    {isOverlapping(shopper) && (
                      <Tooltip
                        borderColor={selectedShopperQueueLine?.color}
                        color={selectedShopperQueueLine?.color}
                        text="Shoppers are overlapping and need to be moved."
                      >
                        <div className="alert-overlapping-shopper">!</div>
                      </Tooltip>
                    )}
                    {(isLastInLine(shopper.userData.shopper?.orderInLine!) ||
                      isMiddleInLine(
                        shopper.userData.shopper?.orderInLine!
                      )) && (
                      <div
                        style={{
                          height: '12px',
                          width: '3px',
                          background: selectedShopperQueueLine?.color,
                          position: 'absolute',
                          bottom: '29px',
                        }}
                      />
                    )}
                  </div>
                  <div className="shopper-actions" data-cy="shopper-actions">
                    <div className="shopper-type-image-wrapper">
                      {renderShopperPreviewImage(shopper)}
                    </div>
                    <div
                      className="shopper-dropdown-action-label"
                      data-cy="shopper-dropdown-action-label"
                    >
                      Shopper:
                    </div>
                    <Select
                      size="small"
                      value={shopper.userData.shopper?.type}
                      onChange={(value) =>
                        onUpdateShopper(
                          shopper.userData.shopper!,
                          'updateShopperType',
                          value
                        )
                      }
                      style={{ width: '126px', textAlign: 'center' }}
                    >
                      <Option
                        key={ShopperType.WomanWithBasket}
                        value={ShopperType.WomanWithBasket}
                      >
                        Woman, Basket
                      </Option>
                      <Option
                        key={ShopperType.WomanWithoutBasket}
                        value={ShopperType.WomanWithoutBasket}
                      >
                        Woman, No Basket
                      </Option>
                      <Option
                        key={ShopperType.WomanWithCart}
                        value={ShopperType.WomanWithCart}
                      >
                        Woman, Cart
                      </Option>
                      <Option
                        key={ShopperType.SmallManWithBasket}
                        value={ShopperType.SmallManWithBasket}
                      >
                        Sm Man, Basket
                      </Option>
                      <Option
                        key={ShopperType.SmallManWithoutBasket}
                        value={ShopperType.SmallManWithoutBasket}
                      >
                        Sm Man, No Basket
                      </Option>
                      <Option
                        key={ShopperType.LargeManWithBasket}
                        value={ShopperType.LargeManWithBasket}
                      >
                        Lg Man, Basket
                      </Option>
                      <Option
                        key={ShopperType.LargeManWithoutBasket}
                        value={ShopperType.LargeManWithoutBasket}
                      >
                        Lg Man, No Basket
                      </Option>
                    </Select>
                  </div>
                  <div
                    className="remove-shopper-icon-wrapper"
                    data-cy="remove-shopper-icon"
                    onClick={() =>
                      onUpdateShopper(
                        shopper.userData.shopper!,
                        'removeShopper'
                      )
                    }
                  >
                    <CloseIcon />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ShopperProperties;
