import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { fetchProductItem } from '../../../store/features/productItem/productItemSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import EditableInformationForm from '../../containers/forms/EditableInformationForm';
import ViewWrapper from '../../elements/ViewWrapper';
import BasicInfo from './components/BasicInfo';
import HeaderSectionLeft from './components/HeaderSectionLeft';
import HeaderSectionRight from './components/HeaderSectionRight';
import ImageSelection from './components/ImageSelection';
import NotEditableInfo from './components/NotEditableInfo';
import Notes from './components/Notes';
import PackageInfo from './components/PackageInfo';
import Status from './components/Status';

import './ProductDetailsView.less';

interface ParamsState {
  id: string;
}

const ProductDetailsView = () => {
  const [displayEditableForm, setDisplayEditableForm] = useState(false);
  const { id } = useParams<ParamsState>();
  const dispatch = useAppDispatch();
  const { value: productItem, fetchingProductItem } = useAppSelector(
    (state) => state.productItem
  );

  const getProduct = useCallback(() => {
    dispatch(fetchProductItem(id));
  }, [dispatch, id]);

  useEffect(() => {
    getProduct();
  }, [dispatch, getProduct]);

  console.log('product item ', productItem);

  return (
    <ViewWrapper
      options={{
        goBack: true,
        loading: fetchingProductItem,
      }}
      headerTitle={<HeaderSectionLeft productItem={productItem} />}
      headerSectionRight={
        productItem ? (
          <HeaderSectionRight
            productItem={productItem}
            displayEditableForm={displayEditableForm}
            setDisplayEditableForm={setDisplayEditableForm}
          />
        ) : null
      }
    >
      <div className="product-item-content-container">
        <div className="product-item-details">
          <ImageSelection />

          {productItem ? <Status productItem={productItem} /> : null}
        </div>
        <div className="edit-content-container">
          {displayEditableForm ? (
            <>
              <div className="edit-form-wrapper">
                <EditableInformationForm
                  productItem={productItem}
                  setDisplayEditableForm={setDisplayEditableForm}
                />
              </div>
              <div className="not-editable-info-wrapper">
                {productItem ? (
                  <NotEditableInfo productItem={productItem} />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="basic-info-wrapper">
                {productItem ? <BasicInfo productItem={productItem} /> : null}
              </div>
              <div className="package-info-wrapper">
                {productItem ? <PackageInfo productItem={productItem} /> : null}
              </div>
              <div className="notes-wrapper">
                {productItem ? <Notes productItem={productItem} /> : null}
              </div>
            </>
          )}
        </div>
      </div>
    </ViewWrapper>
  );
};

export default ProductDetailsView;
