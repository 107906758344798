import { useMemo, FC } from 'react';

import './UserProfileDropdown.less';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Typography } from 'antd';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import AuthService from '../../../../../api/auth';
import { getMe } from '../../../../../util';

const { Text } = Typography;
interface UserProfileDropdownProps {
  collapsed: boolean;
}

const UserProfileDropdown: FC<UserProfileDropdownProps> = (props) => {
  const { collapsed } = props;

  const me = useMemo(() => getMe(), []);

  const menu = (
    <Menu className="user-profile-menu">
      <Link to={`/account-settings/${me.uuid}`}>
        <section className="profile-menu-section-top">
          <Avatar
            size={64}
            icon={<UserOutlined />}
            style={{ marginBottom: 18, background: '#8E24AA' }}
          />
          <div className="user-profile-menu__username">{me.name}</div>
          <Text style={{ color: 'white' }}>{me.email}</Text>
        </section>
      </Link>
      <div
        className="user-profile-menu-item"
        onClick={() => AuthService().logout()}
      >
        Logout
      </div>
    </Menu>
  );

  const userProfileDropdownClass = cx('user-profile-dropdown', {
    collapsed: collapsed,
  });

  const userProfileDropdownOverlayClass = cx(
    'user-profile-dropdown-menu-overlay',
    { collapsed: collapsed }
  );

  return (
    <Dropdown
      overlayClassName={userProfileDropdownOverlayClass}
      placement="top"
      overlay={menu}
      trigger={['click']}
      overlayStyle={{ position: 'fixed' }}
    >
      <div className={userProfileDropdownClass}>
        <UserOutlined
          style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }}
        />
        {collapsed ? null : <div className="username-text">{me.name}</div>}
      </div>
    </Dropdown>
  );
};

export default UserProfileDropdown;
