import {
  CreateSystemConfigurationRequest,
  SystemConfigurationResponse,
  SystemConfigurationsResponse,
  UpdateSystemConfigurationRequest,
} from './reqres';
import { BASE_API_URL } from '../../config';
import { get, ListApiResponse, post, patch, del, ApiResponse } from '../base';

export default function SystemConfigurationService() {
  return Object.freeze({
    listSystemConfigurations(
      query?: string
    ): Promise<SystemConfigurationsResponse> {
      return get(
        `${BASE_API_URL()}/system/config${query}`
      ) as Promise<ListApiResponse>;
    },
    getSystemConfiguration(
      systemConfigId: number
    ): Promise<SystemConfigurationResponse> {
      return get(`${BASE_API_URL()}/system/config/${systemConfigId}`);
    },
    createSystemConfiguration(
      req: CreateSystemConfigurationRequest
    ): Promise<SystemConfigurationResponse> {
      return post(`${BASE_API_URL()}/system/config`, req);
    },
    updateSystemConfiguration(
      systemConfigId: number,
      req: UpdateSystemConfigurationRequest
    ): Promise<SystemConfigurationResponse> {
      return patch(`${BASE_API_URL()}/system/config/${systemConfigId}`, req);
    },
    deleteSystemConfiguration(systemConfigId: number): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/system/config/${systemConfigId}`);
    },
  });
}
