import { memo, useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Collapse, Input, Spin, Tag } from 'antd';

import './ProductsFilters.less';
import { FiltersData } from '../../../../../api/filters/reqres';
import { debounce, propsAreEqual } from '../../../../../util';

const { Panel } = Collapse;

export interface FiltersType {
  brands?: FiltersData[];
  tags?: FiltersData[];
  format?: FiltersData[];
  market?: FiltersData[];
  quality?: FiltersData[];
  private?: FiltersData[];
  flex?: FiltersData[];
  drc?: FiltersData[];
  parentco?: FiltersData[];
}

export type SelectedFilters = {
  [filter in keyof FiltersType]: string[];
};

interface ProductsFiltersProps {
  filters: FiltersType;
  loading: boolean;
  onFiltersSelected: (selectedFilters: SelectedFilters) => void;
  selectedFilter?: SelectedFilters;
}

const ProductsFilters: React.FC<ProductsFiltersProps> = ({
  selectedFilter,
  filters,
  loading,
  onFiltersSelected,
}) => {
  const [filterQueries, setFilterQueries] = useState<{
    [filter in keyof FiltersType]: string;
  }>({});
  const [filteredFilters, setFilteredFilters] = useState<FiltersType>(filters);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    selectedFilter ?? {}
  );
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const queryChangeHandler = (filter: keyof FiltersType, query: string) => {
    setIsFiltering(true);
    setFilterQueries({
      ...filterQueries,
      [filter]: query,
    });
  };

  const handleQueryChange = debounce(queryChangeHandler, 300);

  useEffect(() => {
    const filtered = { ...filters };
    Object.keys(filterQueries).forEach((filterGroup) => {
      const filterKey = filterGroup as keyof FiltersType;
      const query = filterQueries[filterKey] || '';
      if (!!query) {
        const filteredGroup = filters[filterKey]?.filter((filter) => {
          const pattern = new RegExp(query.toLowerCase());
          return pattern.test(filter.name.toLowerCase());
        });

        filtered[filterKey] = filteredGroup;
      }
    });

    setFilteredFilters(filtered);
    setIsFiltering(false);
  }, [filterQueries, filters]);

  const removeSelectedFilter = (filterGroup: string, filter: FiltersData) => {
    const filterKey = filterGroup as keyof FiltersType;

    const selected = {
      ...selectedFilters,
      [filterGroup]: selectedFilters[filterKey]?.filter(
        (key) => key !== filter.key
      ),
    };

    setSelectedFilters(selected);
    onFiltersSelected(selected);
  };

  const handleSelectFilters = (filterGroup: string, filter: FiltersData) => {
    const filterKey = filterGroup as keyof FiltersType;
    let selected = { ...selectedFilters };
    if (selectedFilters[filterKey]?.includes(filter.key)) {
      removeSelectedFilter(filterGroup, filter);
      return;
    } else {
      selected = {
        ...selectedFilters,
        [filterKey]: selectedFilters[filterKey]
          ? [...(selectedFilters[filterKey] ?? []), filter.key]
          : [filter.key],
      };
    }

    setSelectedFilters(selected);
    onFiltersSelected(selected);
  };

  useEffect(() => {
    if (!selectedFilter) return;
    onFiltersSelected(selectedFilter);
  }, [onFiltersSelected, selectedFilter]);

  const hasFilters = () => {
    return Object.keys(selectedFilters).some((filterGroup) => {
      const filterKey = filterGroup as keyof FiltersType;

      return (selectedFilters[filterKey] || []).length > 0;
    });
  };

  if (loading) {
    return (
      <div className="filters-wrapper">
        <Card className="filters-spinner-wrapper">
          <Spin tip="Loading filters..." />
        </Card>
      </div>
    );
  }

  return (
    <div className="filters-wrapper">
      <div className="filters-header">
        <span className="filters-title">Filter</span>
        {hasFilters() ? (
          <div className="selected-filters-wrapper">
            <div className="selected-filters-header">
              <span className="selected-filters-title">SELECTED FILTERS</span>
              <Button
                type="link"
                className="clear-btn"
                onClick={() => {
                  setSelectedFilters({});
                  onFiltersSelected({});
                }}
              >
                Clear
              </Button>
            </div>
            <div className="selected-filters">
              {Object.keys(selectedFilters).flatMap((filterGroup) => {
                const filterKey = filterGroup as keyof FiltersType;

                return selectedFilters[filterKey]?.map((filter) => {
                  const filterData = (
                    filters[filterKey] as FiltersData[]
                  )?.find((data) => data.key === filter);
                  if (!filterData) return null;
                  return (
                    <Tag
                      className="filter-tag"
                      key={filter}
                      closable
                      onClose={() =>
                        removeSelectedFilter(
                          filterGroup,
                          filterData as FiltersData
                        )
                      }
                    >
                      {filterData?.name}
                    </Tag>
                  );
                });
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Collapse
        defaultActiveKey={['1']}
        onChange={() => {}}
        expandIconPosition="right"
      >
        {Object.keys(filteredFilters).map((filterGroup, index) => {
          const filterKey = filterGroup as keyof FiltersType;
          return (
            <Panel
              header={
                <span className="panel-header-title">
                  {filterGroup === 'parentco'
                    ? 'PARENT COMPANY'
                    : filterGroup.toUpperCase()}
                </span>
              }
              key={`${index + 1}`}
            >
              <div className="panel-search-wrapper">
                <Input
                  prefix={<SearchOutlined />}
                  onChange={(e) => handleQueryChange(filterKey, e.target.value)}
                />
              </div>
              {isFiltering ? (
                <div className="group-spinner-wrapper">
                  <Spin />
                </div>
              ) : (
                <div className="panel-filters-wrapper">
                  {filteredFilters[filterKey]?.map((filter) => (
                    <Checkbox
                      key={filter.key}
                      checked={selectedFilters[filterKey]?.includes(filter.key)}
                      onChange={() => handleSelectFilters(filterGroup, filter)}
                    >
                      {filter.name}
                    </Checkbox>
                  ))}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default memo(ProductsFilters, propsAreEqual);
