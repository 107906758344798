import {
  fixtureByIdMap,
  helperByIdMap,
  shopperByIdMap,
  staticFixtureByIdMap,
} from '../../figures';

/**
 * the CanvasKey values chosen are arbitary but it's important to update
 * the canvas `key` when the environment setting changes so that
 * the drop area refreshes
 */
export enum CanvasKey {
  WithEnvironment = 1,
  WithoutEnvironment = 2,
}

export type EnvironmentRenderType =
  | 'lined'
  | 'linedWithGrid'
  | 'rendered'
  | 'renderedWithGrid';

export type RenderType =
  | 'topdown_l'
  | 'topdown_lR90'
  | 'topdown_lR180'
  | 'topdown_lR270'
  | 'topdown_r'
  | 'topdown_rR90'
  | 'topdown_rR180'
  | 'topdown_rR270';

export type FixtureId = keyof typeof fixtureByIdMap;

export type ShopperId = keyof typeof shopperByIdMap;

export type HelperId = keyof typeof helperByIdMap;

export type StaticItemId = keyof typeof staticFixtureByIdMap;

export enum ShopperType {
  WomanWithBasket = 22,
  WomanWithCart = 23,
  LargeManWithBasket = 99927,
  LargeManWithoutBasket = 99928,
  SmallManWithBasket = 99925,
  SmallManWithoutBasket = 99926,
  WomanWithoutBasket = 99924,
}

export enum QueueLineColors {
  Purple = '#772B8F',
  Blue = '#2D9CDB',
  Pink = '#DA1884',
  Orange = '#F2994A',
  Green = '#27AE60',
}

export const queueLineColorWithOpacity = {
  Purple: '#762a8d',
  Blue: '#2e9cdc',
  Pink: '#d81881',
  Orange: '#f2984a',
  Green: '#28af60',
};

export const queueLineColorMap = {
  1: QueueLineColors.Purple,
  2: QueueLineColors.Blue,
  3: QueueLineColors.Pink,
  4: QueueLineColors.Orange,
  5: QueueLineColors.Green,
};

export interface QueueLineModel {
  lineId: number;
  color: QueueLineColors;
  shoppers: FigureAttributesModel[];
}

export const initialQueueLineState: QueueLineModel[] = [
  {
    lineId: 1,
    color: queueLineColorMap[1],
    shoppers: [],
  },
  {
    lineId: 2,
    color: queueLineColorMap[2],
    shoppers: [],
  },
  {
    lineId: 3,
    color: queueLineColorMap[3],
    shoppers: [],
  },
  {
    lineId: 4,
    color: queueLineColorMap[4],
    shoppers: [],
  },
  {
    lineId: 5,
    color: queueLineColorMap[5],
    shoppers: [],
  },
];

export interface FigureAttributesModel {
  path: RenderType;
  x: number;
  y: number;
  width: number;
  height: number;
  resizeable: boolean;
  userData: FigureUserDataModel;
}
export interface ShopperUserDataModel {
  orderInLine: number;
  lineId: number;
  color: QueueLineColors;
  type: ShopperType;
  elapsedWaitTime: string;
}
export interface TextAttributesModel {
  rotationAngle: number;
  bold: boolean;
  bgColor: {
    hashString: string;
    red: number;
    green: number;
    blue: number;
  };
  fontColor: {
    red: number;
    green: number;
    blue: number;
  };
  fontSize: string;
  text: string;
}

export interface MeasureToolAttributesModel {
  height: number;
  width: number;
}
export interface RSFFixtureDataModel {
  key: string;
  id: string;
  name: string;
  r: number;
  x: number;
  y: number;
  w: number;
  d: number;
  h: number;
  height?: number;
  width?: number;
  gid?: string;
  orderInLine?: number;
  lineId?: number;
  lineColor?: QueueLineColors;
  shopperId?: string;
  elapsedWaitTime?: string;
  bold?: boolean;
  bgColor?: {
    hashString: string;
    red: number;
    green: number;
    blue: number;
  };
  fontColor?: {
    red: number;
    green: number;
    blue: number;
  };
  fontSize?: string;
  text?: string;
}

export interface FigureUserDataModel {
  fixtureId: ShopperId | FixtureId | HelperId;
  h: number;
  id: string;
  key: string;
  name: string;
  renderType: RenderType;
  rotation?: number;
  shopper?: ShopperUserDataModel;
}

// Does not contain ALL fields of figure model but for
// now this helps to keep track of this type of data
// As more fields are needed keep this up-to-date
export interface Draw2DFigureModel {
  alpha: number;
  cssClass: string;
  deleteable: boolean;
  draggable: boolean;
  height: number;
  id: string;
  isInDragDrop: boolean;
  isResizeHandle: boolean;
  keepAspectRatio: boolean;
  lastAppliedAttributes: {
    height: number;
    opacity: number;
    src: string;
    width: number;
    x: number;
    y: number;
  };
  minHeight: number;
  minWidth: number;
  ox: number;
  oy: number;
  parent: Draw2DFigureModel;
  path: string;
  persistPorts: boolean;
  portRelayoutRequired: boolean;
  repaintBlocked: boolean;
  resizeable: boolean;
  rotationAngle: number;
  selectable: boolean;
  timerId: number;
  timerInterval: number;
  userData: FigureUserDataModel;
  visible: boolean;
  width: number;
  x: number;
  y: number;
  bold?: boolean;
  bgColor?: {
    hashString: string;
    red: number;
    green: number;
    blue: number;
  };
  fontColor?: {
    red: number;
    green: number;
    blue: number;
  };
  fontSize?: string;
  text?: string;
  shape: any;
  attr: any;
  getComposite: () => any;
  getBoundingBox: () => any; // Returns rectangle class
  setUserData: (value: FigureUserDataModel) => void;
  getChildren: () => { data: Draw2DFigureModel[] };
  setBackgroundColor: (color: string) => void;
  setColor: (color: string) => void;
  setFontColor: (color: string) => void;
  setFontSize: (size: string) => void;
  setBold: (value: boolean) => void;
  setHeight: (height: number) => void;
  setWidth: (width: number) => void;
  setCssClass: (className: string) => void;
  getCssClass: () => string;
  getX: () => number;
  getY: () => number;
  getHeight: () => number;
  getWidth: () => number;
  setX: (x: number) => void;
  setY: (y: number) => void;
  setDashArray: (patter: string[]) => void;
  onCatch: (
    droppedFigure: Draw2DFigureModel,
    x: number,
    y: number,
    shiftKey: boolean,
    ctrlKey: boolean
  ) => void;
  svgNodes: any[];
  add: (child: Draw2DFigureModel, locator: any) => void;
  remove: (child: Draw2DFigureModel) => void;
  getAssignedFigures: () => { data: Draw2DFigureModel[] };
  setRotationAngle: (angle: number) => void;
  toFront: () => void;
  toBack: () => void;
  installEditPolicy: (policy: any) => void;
}

export interface RSFQueueLineModel {
  lineId: number;
  color: string;
  shoppers: RSFShopper[];
  positions: RSFShopperPosition[];
}

export interface RSFShopper {
  key: string;
  id: string;
  name: string;
  r: number;
  x: number;
  y: number;
  w: number;
  d: number;
  h: number;
  orderInLine: number;
  lineId: number;
  lineColor: string;
  elapsedWaitTime: string; //seconds
}

export interface RSFShopperPosition {
  id: string;
  r: number;
  x: number;
  y: number;
  orderInLine: number;
  lineId: number;
}
