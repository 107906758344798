import { Typography } from 'antd';

import { ListProductLibraryModel } from '../../../../../models/product-library';
import { renderField } from '../Row';
import './Notes.less';

const { Title } = Typography;

interface NotesProps {
  productItem: ListProductLibraryModel | undefined;
}

const Notes = ({ productItem }: NotesProps) => {
  return (
    <div className="notes">
      <Title className="title" level={4}>
        Notes
      </Title>
      {productItem && renderField(productItem, 'master_librarian_notes')}
    </div>
  );
};

export default Notes;
