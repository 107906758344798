import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Image, Input, message, Select, Space } from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';
import './StoreBuildForm.less';

import { ApiError, handleError } from '../../../../api/base';
import fullSizeGroceryFrontEndSpaceImage from '../../../../assets/images/build-full-grocery-image.png';
import leftWithPharmacySpaceImage from '../../../../assets/images/build-left-side-image.png';
import openSpaceImage from '../../../../assets/images/build-open-image.png';
import openSpaceWithCustomerServiceSpaceImage from '../../../../assets/images/build-open-w-customer-service-image.png';
import rightWithCustomerServiceSpaceImage from '../../../../assets/images/build-right-side-image.png';
import walmartSpaceImage from '../../../../assets/images/build-walmart-image.png';
import cstoreSpaceImage from '../../../../assets/images/c-store-image.png';
import cstoreLcounterSpaceImage from '../../../../assets/images/c-store-l-counter-image.png';
import { StoreBuildFormLocationState } from './types';
//TODO: get the right image
import cStoreNoSelfCheckoutImage from '../../../../assets/images/c-store-l-counter-image.png';
import lowesImage from '../../../../assets/images/lowes-washer-dryer-image.png';
import walmartFrontEndSpaceImage from '../../../../assets/images/walmart-frontend-space-image.png';
import {
  BuildSpace,
  initalNewBuildModel,
  ListStoreBuildModel,
} from '../../../../models/store-build';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  createStoreBuild,
  updateStoreBuild,
} from '../../../../store/features/storeBuilds/storeBuildsSlice';
import { propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import { environmentsByIdMap } from '../../../views/StoreBuilderDetailsView/environments';

const { Option } = Select;
interface StoreBuildFormProps {
  history: History;
  location: Location<StoreBuildFormLocationState>;
}

const StoreBuildForm = ({ history, location }: StoreBuildFormProps) => {
  const [form] = Form.useForm();
  const [selectedSpace, setSelectedSpace] = useState<BuildSpace>(
    BuildSpace.LeftWithPharmacy
  );

  const [isSavingNewStoreBuild, setIsSavingNewStoreBuild] = useState(false);

  const { currentStoreBuild, savingStoreBuild } = useAppSelector(
    (state) => state.storeBuilds
  );
  const dispatch = useAppDispatch();

  const isNewStoreBuild = useMemo(() => !location.state, [location.state]);

  const fields = useMemo(
    () =>
      Object.keys(isNewStoreBuild ? initalNewBuildModel : location.state?.data),
    [isNewStoreBuild, location.state?.data]
  );

  const getSpaceImage = useMemo(() => {
    switch (selectedSpace) {
      case BuildSpace.LeftWithPharmacy:
        return leftWithPharmacySpaceImage;
      case BuildSpace.RightWithCustomerService:
        return rightWithCustomerServiceSpaceImage;
      case BuildSpace.Open:
        return openSpaceImage;
      case BuildSpace.OpenWithCustomerService:
        return openSpaceWithCustomerServiceSpaceImage;
      case BuildSpace.Walmart:
        return walmartSpaceImage;
      case BuildSpace.FullSizeGrocery:
        return fullSizeGroceryFrontEndSpaceImage;
      case BuildSpace.CStore:
        return cstoreSpaceImage;
      case BuildSpace.CStoreLCounter:
        return cstoreLcounterSpaceImage;
      case BuildSpace.CStoreNoSelfCheckout:
        return cStoreNoSelfCheckoutImage;
      case BuildSpace.Lowes:
        return lowesImage;
      case BuildSpace.WalmartFrontEndSpace:
        return walmartFrontEndSpaceImage;
    }
  }, [selectedSpace]);

  const onSuccess = useCallback(() => {
    if (isNewStoreBuild && currentStoreBuild) {
      message.success('Build created.');
      history.push(`/store-builder/${currentStoreBuild?.uuid}`, {
        data: currentStoreBuild,
      });
      return;
    }

    message.success('Build saved.');
    history.goBack();
  }, [currentStoreBuild, history, isNewStoreBuild]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const saveBuild = useCallback(
    async (storeBuild: ListStoreBuildModel) => {
      try {
        const storeBuildId = location.state?.data?.uuid!;

        const action = isNewStoreBuild
          ? createStoreBuild(storeBuild)
          : updateStoreBuild({
              storeBuild,
              storeBuildId,
            });

        if (isNewStoreBuild) {
          setIsSavingNewStoreBuild(true);
        }

        await dispatch(action);
        if (!isNewStoreBuild) {
          message.success('Build saved.');
          history.goBack();
        }
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [dispatch, history, isNewStoreBuild, location.state?.data?.uuid, onError]
  );

  const onSubmit = useCallback(
    (values: ListStoreBuildModel) => {
      form.validateFields(fields).then(() => saveBuild(values));
    },
    [fields, form, saveBuild]
  );

  useEffect(() => {
    if (isSavingNewStoreBuild && currentStoreBuild) {
      onSuccess();
      setIsSavingNewStoreBuild(false);
    }
  }, [isSavingNewStoreBuild, currentStoreBuild, onSuccess]);

  const spaceOptions = useMemo(() => {
    return Object.values(environmentsByIdMap).sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }, []);

  return (
    <FormWrapper
      title={`${isNewStoreBuild ? 'Create new' : 'Edit'} build`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          isNewStoreBuild ? initalNewBuildModel : location.state.data
        }
        onFinish={onSubmit}
      >
        <Form.Item
          label="Build Name"
          name="name"
          rules={[{ required: true, message: 'Build name is required.' }]}
        >
          <Input data-cy="build-name-input" placeholder="Build name" />
        </Form.Item>
        <Form.Item
          label="Build Space"
          name="feb_design_area_id"
          rules={[{ required: true, message: 'Build space is required.' }]}
          data-cy="build-space"
        >
          <Select
            onChange={(value) => setSelectedSpace(value as BuildSpace)}
            data-cy="build-space-list"
            placeholder="Select a space"
            disabled={!isNewStoreBuild}
          >
            {spaceOptions.map(({ id, name }) => (
              <Option value={id}>{name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Image
            placeholder
            src={getSpaceImage}
            alt="Build side"
            className="build-side-image"
          />
          <div className="build-side-image-caption">
            Design space is outlined in red.
          </div>
        </Form.Item>
        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              data-cy="store-build-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
              loading={savingStoreBuild}
            >
              {`${isNewStoreBuild ? 'Create' : 'Save'} Build`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(StoreBuildForm, propsAreEqual));
