import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import './OnlineVirtualResearchDetailsView.less';

import { ApiError, handleError } from '../../../api/base';
import {
  DATConfiguration,
  ProductModelType,
} from '../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setCellsDrawerVisible } from '../../../store/features/cells/cellsSlice';
import {
  fetchOvrProject,
  fetchOvrProjectStats,
  updateOvrProject,
} from '../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import { UserPermissions, getMePermissions } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import Spinner from '../../elements/Spinner/Spinner';
import ViewWrapper from '../../elements/ViewWrapper';
import { RoutePath } from '../AppRoot/types';
import ExportRespondentDataModal from '../OnlineVirtualResearchView/components/ExportRespondentDataModal';
import CellsDrawer from './components/CellsDrawer';
import CellGroupsList from './components2/CellGroupsList';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Option } = Select;

interface ParamsState {
  id: string;
}
const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const OnlineVirtualResearchDetailsView = () => {
  const [hasRespondentData, setHasRespondentData] = useState(true);

  console.log('setHasRespondentData :>> ', setHasRespondentData);

  const { id: projectId } = useParams<ParamsState>();

  const [form] = Form.useForm();

  const [
    exportRespondentDataModalVisible,
    setExportRespondentDataModalVisible,
  ] = useState(false);

  const dispatch = useAppDispatch();

  const {
    ovrProjectStats,
    ovrProject,
    fetchingOvrProject,
    updatingOvrProject,
  } = useAppSelector((state) => state.ovrProjectDetails);

  const { cellsDrawerVisible } = useAppSelector((state) => state.cells);

  const totalCompletes = ovrProjectStats?.stats?.totalCompletes ?? 0;
  const totalRespondents = ovrProjectStats?.stats?.totalRespondents ?? 0;
  const totalCompletesWithDAT =
    ovrProjectStats?.stats?.totalCompletesWithDAT ?? 0;

  const completesWithDATPercentage =
    totalCompletes > 0
      ? ((totalCompletesWithDAT / totalCompletes) * 100).toFixed(1) + '%'
      : '0%';

  const conversionRate =
    totalRespondents > 0
      ? ((totalCompletes / totalRespondents) * 100).toFixed(1) + '%'
      : '0%';

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  const headerSectionRight = (
    <Space direction="horizontal" size="middle">
      {currentUserPermissions.includes(UserPermissions.OvrProjectsEdit) ? (
        <Link
          to={{
            hash: DrawerHashRoute.OVRForm,
            state: { data: ovrProject },
          }}
        >
          <Button type="primary" disabled={fetchingOvrProject}>
            Edit
          </Button>
        </Link>
      ) : null}
    </Space>
  );

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 22 },
  };

  const tailLayout = {
    wrapperCol: {
      xl: { offset: 3 },
      lg: { offset: 4 },
      md: { offset: 5 },
      sm: { offset: 6 },
      xs: { offset: 0 },
    },
  };

  const validateURL = (_: any, value: string | URL) => {
    if (!value) {
      return Promise.resolve();
    }
    try {
      new URL(value);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(
        'Please enter a valid URL e.g. http://www.example.com'
      );
    }
  };

  const onSuccess = useCallback(() => {
    message.destroy();
    message.success('Project saved.');
  }, []);

  const onError = useCallback((err: ApiError) => {
    message.destroy();
    handleError(err);
  }, []);

  const handleUpdateProductModelType = async (modelType: ProductModelType) => {
    try {
      message.loading({
        content: 'Saving project...',
        key: 'saving-model-type-msg',
      });

      await dispatch(
        updateOvrProject({
          projectId,
          updateData: { ...ovrProject!, product_model_type: modelType },
        })
      ).unwrap();

      onSuccess();
    } catch (error) {
      onError(error as ApiError);
    }
  };

  const handleUpdateLanguage = async (languageCode: string) => {
    try {
      message.loading({
        content: 'Saving project...',
        key: 'saving-language-msg',
      });

      let i18n_language: string;
      let i18n_country: string;

      switch (languageCode) {
        case 'zh-cn':
          i18n_language = 'zh';
          i18n_country = 'CN';
          break;
        case 'pt-br':
          i18n_language = 'pt';
          i18n_country = 'BR';
          break;
        default:
          i18n_language = 'en';
          i18n_country = 'US';
      }
      const updateData = {
        i18n_language,
        i18n_country,
      };

      await dispatch(
        updateOvrProject({
          projectId,
          updateData,
        })
      ).unwrap();

      onSuccess();
    } catch (error) {
      onError(error as ApiError);
    }
  };

  const handleUpdateDynamicAttentionTracking = async (
    config: DATConfiguration
  ) => {
    try {
      message.loading({
        content: 'Saving project...',
        key: 'saving-dat-config-msg',
      });

      await dispatch(
        updateOvrProject({
          projectId,
          updateData: { ...ovrProject!, dat_config: config },
        })
      ).unwrap();

      onSuccess();
    } catch (error) {
      onError(error as ApiError);
    }
  };

  const handleSaveRedirectUrl = async (values: {
    complete_redirect: string;
  }) => {
    try {
      message.loading({
        content: 'Saving project...',
        key: 'saving-redirect-url-msg',
      });

      await dispatch(
        updateOvrProject({ projectId, updateData: values })
      ).unwrap();

      onSuccess();
    } catch (error) {
      onError(error as ApiError);
    }
  };

  const handleTestSurveyLink = () => {
    if (ovrProject?.entry_url) {
      const updatedEntryUrl = ovrProject?.entry_url?.replace(
        '%%SUBID%%',
        'test-' + uuidv4()
      );

      window.open(updatedEntryUrl, '_blank');
    }
  };

  const handleDateChange = (dates: any) => {
    if (dates) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();

      dispatch(
        fetchOvrProjectStats({ projectId, params: { startDate, endDate } })
      );
    }
  };

  useEffect(() => {
    dispatch(fetchOvrProject({ projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(fetchOvrProjectStats({ projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    form.setFieldsValue({ complete_redirect: ovrProject?.complete_redirect });
  }, [form, ovrProject?.complete_redirect]);

  console.log('ovrProjectStats :>> ', ovrProjectStats);

  return (
    <ViewWrapper
      options={{ goBack: RoutePath.OVR }}
      headerTitle={
        !fetchingOvrProject ? (
          ovrProject?.name
        ) : (
          <Spin indicator={antSpinIcon} />
        )
      }
      headerSectionRight={headerSectionRight}
    >
      <Space
        size="middle"
        direction="vertical"
        className="ovr-details-content-wrapper"
      >
        <Card
          className="ovr-details-card"
          title={
            <Space direction="vertical">
              <Title level={5}>Respondent Quick Check</Title>
              {!hasRespondentData && (
                <Text type="secondary" style={{ fontSize: '14px' }}>
                  Data Unavailable. This project pre-dates OVR Completion Data
                  Collection
                </Text>
              )}
            </Space>
          }
        >
          <Space
            size="large"
            direction="vertical"
            className="respondant-details"
          >
            <RangePicker onChange={handleDateChange} />
            {fetchingOvrProject ? (
              <Spin indicator={antSpinIcon} />
            ) : (
              <div className="respondant-stats-wrapper">
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">Respondents</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">
                      {ovrProjectStats?.stats?.totalRespondents}
                    </p>
                  </div>
                </div>
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">Conversion Rate %</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">{conversionRate}</p>
                  </div>
                </div>
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">Total Complete</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">
                      {ovrProjectStats?.stats?.totalCompletes}
                    </p>
                  </div>
                </div>
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">Complete W/ Purchase</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">
                      {ovrProjectStats?.stats?.totalCompletesWithPurchase}
                    </p>
                  </div>
                </div>
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">DAT Completes</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">
                      {ovrProjectStats?.stats?.totalCompletesWithDAT}
                    </p>
                  </div>
                </div>
                <div className="respondant-card">
                  <div className="title-container">
                    <h3 className="respondant-title">% Completes with DAT</h3>
                  </div>
                  <div className="text-container">
                    <p className="respondant-text">
                      {completesWithDATPercentage}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {currentUserPermissions.includes(
              UserPermissions.OvrProjectsDataExport
            ) ? (
              <Button
                type="primary"
                disabled={fetchingOvrProject}
                onClick={() => setExportRespondentDataModalVisible(true)}
              >
                Export Respondent Data
              </Button>
            ) : null}
          </Space>
        </Card>
        <Card
          className="ovr-details-card"
          title={<Title level={5}>Description</Title>}
        >
          {fetchingOvrProject ? (
            <Spin indicator={antSpinIcon} />
          ) : (
            <>
              {ovrProject?.description ? (
                <p>{ovrProject.description}</p>
              ) : (
                <p>No description.</p>
              )}
            </>
          )}
        </Card>

        <Card
          className="ovr-details-card"
          title={<Title level={5}>Planogram Product Model Type</Title>}
        >
          <Space direction="vertical" size="large">
            <Space direction="horizontal" size="large">
              <Text style={{ lineHeight: '42px' }}>
                Planogram Product Model Type:
              </Text>
              <Select
                loading={fetchingOvrProject}
                value={
                  fetchingOvrProject
                    ? undefined
                    : ovrProject?.product_model_type
                }
                onChange={handleUpdateProductModelType}
                placeholder="Select product model type"
              >
                <Option key="1" value="3D">
                  3D Product Models
                </Option>
                <Option key="2" value="2D">
                  2D Product Models
                </Option>
              </Select>
            </Space>
            <Space direction="horizontal" size="large">
              <Text style={{ lineHeight: '42px' }}>Language:</Text>
              <Select
                loading={fetchingOvrProject}
                value={
                  fetchingOvrProject
                    ? undefined
                    : ovrProject?.i18n_language && ovrProject?.i18n_country
                    ? `${
                        ovrProject.i18n_language
                      }-${ovrProject.i18n_country.toLowerCase()}`
                    : 'en-us'
                }
                onChange={handleUpdateLanguage}
                placeholder="Select language"
                style={{ width: '170px' }}
              >
                <Option key="1" value="en-us">
                  English (US)
                </Option>
                <Option key="2" value="pt-br">
                  Portuguese
                </Option>
                <Option key="3" value="zh-cn">
                  Chinese (Simplified)
                </Option>
              </Select>
            </Space>
          </Space>
        </Card>

        <Card
          className="ovr-details-card"
          title={
            <Title level={5}>Dynamic Attention Tracking Requirement</Title>
          }
        >
          <Space direction="horizontal" size="large">
            <Text style={{ lineHeight: '42px' }}>
              Dynamic Attention Tracking Requirement:
            </Text>

            <Select
              loading={fetchingOvrProject}
              value={fetchingOvrProject ? undefined : ovrProject?.dat_config}
              onChange={handleUpdateDynamicAttentionTracking}
              placeholder="Select requirement"
            >
              <Option
                key={DATConfiguration.Required}
                value={DATConfiguration.Required}
              >
                Required
              </Option>
              <Option
                key={DATConfiguration.Disabled}
                value={DATConfiguration.Disabled}
              >
                Disabled
              </Option>
              <Option
                key={DATConfiguration.Optional}
                value={DATConfiguration.Optional}
              >
                Optional
              </Option>
            </Select>
          </Space>
        </Card>

        <Card
          className="ovr-details-card"
          title={<Title level={5}>Tracking</Title>}
        >
          <Form
            form={form}
            {...layout}
            requiredMark={false}
            initialValues={ovrProject}
            onFinish={handleSaveRedirectUrl}
          >
            <Form.Item
              label="Entry URL"
              labelCol={{
                xl: { span: 3 },
                lg: { span: 4 },
                md: { span: 5 },
                sm: { span: 6 },
                xs: { span: 2 },
              }}
            >
              <Space direction="vertical">
                <Text style={{ lineHeight: '42px' }} copyable>
                  {ovrProject?.entry_url!}
                </Text>
                {currentUserPermissions.includes(
                  UserPermissions.OvrProjectsTest
                ) && (
                  <Button
                    onClick={handleTestSurveyLink}
                    disabled={fetchingOvrProject}
                    loading={fetchingOvrProject}
                  >
                    Test Survey Link
                  </Button>
                )}
                <Alert
                  message="Any additional parameters can be passed in the query string to the tracking URL above. Upon completion redirect, they can be replaced using %%key%% syntax. So if you passed a parameter like ?subid2=test, using %%subid2%% in the complete URL below would replace that variable."
                  type="info"
                  showIcon
                />
              </Space>
            </Form.Item>

            <Form.Item
              label="Redirect URL"
              name="complete_redirect"
              rules={[
                {
                  required: false,
                  message:
                    'Please enter the redirect URL (http://www.example.com)',
                },
                { validator: validateURL },
              ]}
              labelCol={{
                xl: { span: 3 },
                lg: { span: 4 },
                md: { span: 5 },
                sm: { span: 6 },
                xs: { span: 2 },
              }}
            >
              <Input
                placeholder="Redirect/complete URL"
                disabled={fetchingOvrProject}
              />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={updatingOvrProject}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Card
          className="ovr-details-card"
          title={<Title level={5}>Cell Groups</Title>}
        >
          <div className="cell-groups-content-wrapper">
            <p>
              Cell groups allow you to add cells for which traffic is
              automatically distributed between them, for the purposes of AB
              testing. Traffic will be equally (via randomization) distributed
              to each cell in a cell group. Only cells imported to this project
              will be able to be selected here.
            </p>
            <Button
              type="primary"
              onClick={() => dispatch(setCellsDrawerVisible(true))}
              style={{ width: '100%', marginBottom: '2rem' }}
            >
              Import New Cells
              <PlusOutlined />
            </Button>

            {fetchingOvrProject ? <Spinner /> : <CellGroupsList />}
          </div>
        </Card>
      </Space>

      <ExportRespondentDataModal
        visible={exportRespondentDataModalVisible}
        project={ovrProject!}
        onClose={() => setExportRespondentDataModalVisible(false)}
      />

      {cellsDrawerVisible && <CellsDrawer visible={cellsDrawerVisible} />}
    </ViewWrapper>
  );
};

export default OnlineVirtualResearchDetailsView;
