import { useState, useEffect, useRef } from 'react';

import { Typography, Tabs } from 'antd';
import PDFObject from 'pdfobject';

import ViewWrapper from '../../elements/ViewWrapper';

import './ManualView.less';

const { Title } = Typography;
const { TabPane } = Tabs;

const ManualTab = () => {
  const pdfContainer = useRef(null);
  useEffect(() => {
    PDFObject.embed(
      'https://s3.us-east-2.amazonaws.com/docs.dev.readysetvr.com/ReadySetUserManual.pdf',
      pdfContainer.current
    );
  }, []);

  return (
    <div>
      <Title level={3}>ReadySet User Manual</Title>
      <div ref={pdfContainer} className="pdf-container"></div>
    </div>
  );
};

const VideosTab = () => {
  const videoList = [
    {
      id: 1,
      url: 'https://s3.us-east-2.amazonaws.com/docs.dev.readysetvr.com/HardwareSetupManualViveVersionV2.mp4',
    },
  ];

  return (
    <div className="video-tabs-container">
      <Title level={3}>ReadySet User Manual Videos</Title>
      <div className="video-container">
        {videoList.map((video) => (
          <div key={video.id}>
            <video width="100%" controls>
              <source src={video.url} type="video/mp4" />
            </video>
            <Title level={4}>Hardware Setup Manual Vive Version V2</Title>
          </div>
        ))}
      </div>
    </div>
  );
};

const ManualView = () => {
  const [activeTab, setActiveTab] = useState('manual');

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };
  return (
    <ViewWrapper>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Manual" key="manual">
          <ManualTab />
        </TabPane>
        <TabPane tab="Videos" key="videos">
          <VideosTab />
        </TabPane>
      </Tabs>
    </ViewWrapper>
  );
};

export default ManualView;
