import { useEffect } from 'react';

import { Spin } from 'antd';
import { History } from 'history';
import { match } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store';
import { fetchStoreBuild } from '../../../store/features/storeBuilds/storeBuildsSlice';
import StoreBuilder from './components/StoreBuilder';

import './StoreBuilderDetailsView.less';
interface StoreBuilderDetailsViewProps {
  match: match<any>;
  history: History;
}

const StoreBuilderDetailsView = ({
  history,
  match,
}: StoreBuilderDetailsViewProps) => {
  const { id } = match.params;
  // const [fetchingStoreBuild, build, { updateStoreBuild }] = useStoreBuild(id);

  const dispatch = useAppDispatch();

  const { fetchingStoreBuild, currentStoreBuild } = useAppSelector(
    (state) => state.storeBuilds
  );

  useEffect(() => {
    const getStoreBuild = async () => {
      await dispatch(fetchStoreBuild({ storeBuildId: id }));
    };
    getStoreBuild();
  }, [dispatch, id]);

  if (fetchingStoreBuild) {
    return (
      <div className="spin-wrapper">
        <Spin />
      </div>
    );
  }

  return currentStoreBuild !== null ? (
    <StoreBuilder history={history} build={currentStoreBuild!} />
  ) : (
    <div>No data available for this store build</div>
  );
};

export default StoreBuilderDetailsView;
