export interface CountUnitModel {
  name: string;
  status: string | null;
  statusCheckValue?: boolean | undefined;
  in_processing?: number;
  in_library?: number;
}

export const initialNewCountUnitModel = {
  name: '',
};
