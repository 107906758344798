import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import Search from 'antd/lib/input/Search';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useHistory, useParams } from 'react-router-dom';

import { ApiError, handleError } from '../../../api/base';
import useDebounce from '../../../hooks/useDebounce';
import { ClientModel } from '../../../models/client';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getClient,
  loadMoreClientUsers,
  searchClientUsersByName,
  updateClient,
} from '../../../store/features/clients/clientsSlice';
import {
  getMePermissions,
  isUserAuthorized,
  UserPermissions,
  UserRoles,
} from '../../../util/user';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import ViewWrapper from '../../elements/ViewWrapper';
import UserTableList from '../UsersView/components/UserTableList';
import EmailNotificationTable from './EmailNotificationTable/EmailNotificationTable';
import LicenseTable from './LicenseTable/LicenseTable';
import SeatConcurrentUserTable from './SeatsConcurrentUserTable/SeatsConcurrentUserTable';

import './ClientView.less';

const { Title, Text } = Typography;
const antSpinIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

export interface ClientModelWithExtensions extends ClientModel {
  email_notification: string;
  markets: string;
  notes: string;
  client_type: string;
}

const ClientView = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [editing, setEditing] = useState(false);
  const [notes, setNotes] = useState('');
  const [isLicenseActivationChecked, setIsLicenseActivationChecked] =
    useState(false);

  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id: clientId } = useParams<{ id: string }>();

  const {
    client,
    users,
    fetchingClient,
    searchingUsers,
    updatingClient,
    usersPagination,
    count,
  } = useAppSelector((state) => state.clients);

  const { value: usersState } = useAppSelector((state) => state.users);

  const clientWithExtensions = client as ClientModelWithExtensions;

  const hasUsers = !(!searchingUsers && users.length === 0);

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const hasMoreUsers =
    hasUsers && usersPagination?.offset! + usersPagination?.offset! < count!;

  const isPermittedToLoadMore = !searchingUsers && !searchQuery && hasMoreUsers;

  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );

  useEffect(() => {
    dispatch(getClient({ id: clientId }));
  }, [clientId, dispatch]);

  const onSuccess = useCallback(() => {
    message.success('Notes saved.');
    setEditing(false);
  }, []);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const handleEditNotes = () => {
    setEditing(true);
  };

  const handleSaveNotes = useCallback(
    async (notes: string) => {
      try {
        if (client) {
          const short_name = client?.short_name;
          const updatedClient = {
            short_name: short_name!,
            fields: {
              client_notes: notes,
            },
          };

          await dispatch(
            updateClient({
              clientId,
              client: updatedClient,
            })
          );
          onSuccess();
        }
      } catch (error) {
        onError(error as ApiError);
      }
    },
    [client, clientId, dispatch, onError, onSuccess]
  );

  const handleTextAreaChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setNotes(e.target.value);
  };

  const updateSearchQuery = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  useEffect(() => {
    if (clientId) {
      dispatch(getClient({ id: clientId }));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    const id = clientId || client?.uuid;
    if (id) {
      dispatch(
        searchClientUsersByName({
          id,
          query: debouncedSearchQuery,
        })
      );
    }
  }, [
    usersState,
    client?.uuid,
    clientId,
    debouncedSearchQuery,
    dispatch,
    fetchingClient,
  ]);

  if (fetchingClient)
    return (
      <div className="client-view-container">
        <Spin />
      </div>
    );

  return (
    <ViewWrapper
      headerTitle={<Title level={4}>{clientWithExtensions?.name}</Title>}
      headerSectionRight={
        <Button
          type="primary"
          onClick={() =>
            history.push({
              hash: DrawerHashRoute.ClientForm,
              state: {
                client,
              },
            })
          }
        >
          Edit
        </Button>
      }
      options={{ goBack: true }}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="card">
            <Title level={5}>General Info</Title>
            <Text type="secondary" className="head">
              Name
            </Text>
            <Text className="body">{client?.name}</Text>
            <Row gutter={[16, 16]}>
              <Col span={12} className="flex-column">
                <Text type="secondary" className="head">
                  Date Created
                </Text>
                <Text className="body">{client?.created_at}</Text>
              </Col>
              <Col span={12} className="flex-column">
                <Text type="secondary" className="head">
                  Client Identifier/URL
                </Text>
                <Text className="body">{client?.short_name}</Text>
              </Col>
            </Row>
            <Text type="secondary" className="head">
              Client Type
            </Text>
            <Text className="body">{clientWithExtensions?.client_type}</Text>
            <Text type="secondary" className="head">
              Default Market
            </Text>
            <Text className="body">{clientWithExtensions?.markets}</Text>
          </div>
        </Col>
        <Col span={12}>
          {hasPermission ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="card">
                  <Space direction="vertical">
                    <Title level={5}>License Activation</Title>
                    <Space>
                      <Switch
                        checked={isLicenseActivationChecked}
                        onChange={(checked) =>
                          setIsLicenseActivationChecked(checked)
                        }
                      />
                      <Typography>
                        This client has access to the ReadySet App and Portal
                        Website
                      </Typography>
                    </Space>
                  </Space>
                </div>
              </Col>
              <Col span={24}>
                <div className="card">
                  <Space direction="vertical">
                    <Title level={5}>Notes</Title>
                    {clientWithExtensions?.notes && !editing ? (
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <div className="notes-container">
                          <Text type="secondary">
                            {clientWithExtensions?.notes}
                          </Text>
                        </div>
                        <Button type="primary" onClick={handleEditNotes}>
                          Edit Notes
                        </Button>
                      </Space>
                    ) : (
                      <>
                        <TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder={
                            clientWithExtensions?.notes
                              ? (clientWithExtensions?.notes as string)
                              : 'Add client notes here.'
                          }
                          value={notes}
                          onChange={handleTextAreaChange}
                        />
                        <div className="buttons-container">
                          <Button
                            type="primary"
                            loading={updatingClient}
                            disabled={updatingClient}
                            onClick={() => handleSaveNotes(notes)}
                          >
                            Save Notes
                          </Button>
                          {clientWithExtensions?.notes && (
                            <Button onClick={() => setEditing(false)}>
                              Cancel
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </Space>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
        {client && (
          <Col span={24}>
            <div className="card">
              <LicenseTable clientId={clientWithExtensions.uuid} />
            </div>
          </Col>
        )}
        {client && (
          <Col span={24}>
            <div className="card">
              <SeatConcurrentUserTable clientId={clientWithExtensions.uuid} />
            </div>
          </Col>
        )}
        {client && (
          <Col span={24}>
            <div className="card">
              <EmailNotificationTable clientId={clientWithExtensions.uuid} />
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className="card">
            <Title level={5} className="title">
              {client?.name} Users
            </Title>
            <div className="search-bar">
              <div className="search-bar-left">
                <Search
                  className="search-input"
                  allowClear={!searchingUsers}
                  value={searchQuery}
                  onChange={(e) => updateSearchQuery(e.target.value)}
                  enterButton
                  placeholder="Search"
                  suffix={searchingUsers && <Spin indicator={antSpinIcon} />}
                />
              </div>
              {currentUserPermissions.includes(UserPermissions.UsersCreate) ? (
                <Link to={DrawerHashRoute.UserForm}>
                  <Button type="primary">Add New User</Button>
                </Link>
              ) : (
                <div />
              )}
            </div>
            {client && (
              <UserTableList
                loading={searchingUsers}
                users={users}
                onLoadMore={() =>
                  dispatch(loadMoreClientUsers(clientWithExtensions.uuid))
                }
                hasMore={isPermittedToLoadMore}
              />
            )}
          </div>
        </Col>
      </Row>
    </ViewWrapper>
  );
};

export default ClientView;
